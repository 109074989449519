import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
	Table,
	Button,
	Select,
	Pagination,
  InputNumber,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import TableData from "./table-data.js";
import { connect } from "react-redux";
import _, { filter, isEmpty } from 'lodash';
import "./library.css";
const Option = Select.Option;

const columns = [
  {
    title: 'Team',
    dataIndex: 'team',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    align: 'center'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: '15%',
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: '20%',
    align: 'center'
  },
];

const LibraryPageComponent = (props)=> {
  const [gamesList, setgamesList] = useState([])
  const [gamesListMeta, setgamesListMeta] = useState({})
  const [gamesListLoading, setgamesListLoading] = useState(true)
  useEffect(() => {
		loadgamesList()
	}, [])

	const loadgamesList =(limit=9,page=1)=>{
		setgamesListLoading(true)
		props.list({
			params:{limit,page,expand:"athlete"},
			callback:(res,data)=>{
				if (res==true && _.size(_.get(data,'data.items',[]))>0) {
					setgamesList(_.get(data,'data.items',[]))
					setgamesListMeta(_.get(data,'data.meta',{}))
				}
				setgamesListLoading(false)
			}
		})
	}

const	render =()=> {

		return (
			<div className="HomePageContainer">
				<div className="container">
					<div className="full-width">
          <div className="TableFilter">
            <span className="TableFilterText">Filter</span>
            <Select
              showSearch
              style={{ width: 150 }}
              placeholder="Select a year"
            >
              <Option value="2020">2020-21</Option>
              <Option value="2019">2019-20</Option>
              <Option value="2018">2018-19</Option>
              <Option value="2017">2017-18</Option>
              <Option value="2016">2016-17</Option>
              <Option value="2015">2015-16</Option>
              <Option value="2014">2014-15</Option>
              <Option value="2013">2013-14</Option>
              <Option value="2012">2012-13</Option>
              <Option value="2011">2011-12</Option>
              <Option value="2010">2010-11</Option>
            </Select>
          </div>
						<div className="TableContainer">
							<Table bordered columns={columns} dataSource={TableData} size="middle" pagination={false}/>
						</div>
            <div className="PaginationContainer">
            <span className="PaginationContainerInner">
              <span className="PaginationLabel">Total 15 items</span>
              <Pagination defaultCurrent={1} total={500} />
            </span>
            <span className="PaginationContainerInner">
              <span className="PaginationLabel ml-3">Go to</span>
              <InputNumber min={1} max={10} initialvalues={3} style={{width: '70px'}} />
            </span>
          </div>
					</div>
				</div>
			</div>
		);
	}
  return render()
}
const mapStateToProps = state => (
	{
		

	});

const mapDispatchToProps = dispatch => ({
	list: details => dispatch(global.redux.action.games.list(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(LibraryPageComponent)