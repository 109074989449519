// Alerts.js
import React from "react";
import { Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";

export const FreePlanAlert = ({ onClose }) => (
  <Alert
    // message="Free Plan"
 
     description={
      <div style={{ textAlign: 'center' }}>
        You are currently on a free plan. Upgrade now to enjoy all premium features!{' '}
        <Link to="/subscription">Upgrade Now</Link>
 
      </div>
    }
    type="info"
    closable
    onClose={onClose}
  />
);

const expiredTrialMessage = `
  Your 30-day free trial of all premium features has expired. 🕒💔
  
  Upgrade now to enjoy uninterrupted access to all the premium features! 🚀💳

  Some features may be temporarily unavailable until you make a payment. We appreciate your support and hope you continue to enjoy our premium services.

  Make a payment now and elevate your experience! 💼🌟
`;
const expiredMessage = `
  Your Plan has expired. 🕒💔
  
  Upgrade now to enjoy uninterrupted access to all the premium features! 🚀💳

  Some features may be temporarily unavailable until you make a payment. We appreciate your support and hope you continue to enjoy our premium services.

  Make a payment now and elevate your experience! 💼🌟
`;
export const PlanExpiredAlert = ({ onClose, hasPlanExpired, hasTrialExpired }) => (
  <Alert
    // message="Trial Plan"
    description={
      <div style={{ textAlign: "center" }}>
        {expiredMessage}
        <Link to="/subscription">Upgrade Now</Link>
 
      </div>
    }
    type="warning"
    closable
    onClose={onClose}
  />
);
export const TrialPlanAlert = ({ onClose, hasPlanExpired, hasTrialExpired }) => (
  <Alert
    // message="Trial Plan"
    description={
      <div style={{ textAlign: "center" }}>
        {
          hasTrialExpired ?(expiredTrialMessage):
            ('You are on a 30-day free trial of all premium features. Upgrade before it expires! ') 
        }
        <Link to="/subscription"> Upgrade Now</Link>
 
      </div>
    }
    type="warning"
    closable
    onClose={onClose}
  />
);

export const WarningEmailVerificationAlert = ({ onClose, onLogout }) => (
  <Alert
    // message="Warning"
    description={
      <div
        style={{ textAlign: "center" }}
        className="flex items-center justify-center text-center"
      >
        Please check your email to verify your account.{" "}
        <div
          onClick={onLogout}
          className="cursor-pointer text-[#0F1A24] ml-[0.5%] underline"
        >
          Resend Email
        </div>
      </div>
    }
    type="warning"
    closable
    onClose={onClose}
  />
);
