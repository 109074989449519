import React, { useEffect, useState } from "react";
import {
  Card,
  Dropdown,
  Menu,
  Progress,
  Table,
  Button,
  Select,
  Tooltip,
  Checkbox,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { Gauge } from "../../components/charts/guage";
import TotalNumberBox from "./TotalNumberBox";
import PieChartComponent from "../../components/charts/PieChartTwo";
import "./seasondashboard.css";
import multiplePages from "../../assets/img/multiple-pages.svg";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import game from "../../assets/img/grouppeople.svg";
import stats from "../../assets/img/stats-square-up.svg";
import crystal from "../../assets/img/codepen.svg";
import userSquare from "../../assets/img/user-square-alt.svg";
import clipimg from "../../assets/img/clipboard-check.svg";
import MultiBarChartTwo from "../../components/charts/BarChart2";
import ComingSoon from "../../components/Modals/ComingSoon";
import PlayerAnalyticCell from "../../components/misc/PlayerAnalyticCell";
import PieChartCel from "../../components/misc/PieChartCel";
import KMultiLineChart from "../../components/charts/GameAnalyticsKChart";

const { Option } = Select;
export const TotalNumberCard = ({ title, number, width, flexRow }) => {
  return (
    <div
      className={`${
        width ? `w-[${width}]` : "w-[49%]"
      }  h-[48px] p-[12px] rounded-[5px] ${
        flexRow ? "font-bold flex-row items-center justify-between" : "flex-col"
      } flex`}
      style={{ border: "1px solid #EAEDF0" }}
    >
      <span
        className={` ${
          flexRow ? "text-[12px]" : "text-[10px]"
        }  text-[#526B84]`}
      >
        {title}
      </span>
      <span
        className={`${
          flexRow ? "text-lg" : "text-[10px]"
        } text-[12px] text-[#0F1A24]`}
      >
        {number}
      </span>
    </div>
  );
};

function SeasonAnalyticDashboard(props) {
  const [gamesList, setGamesList] = useState([]);
  const [gamesData, setGamesData] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [seasonsList, setSeasonsList] = useState([]);
  const [gamesIds, setGamesIdsList] = useState([]);
  const [odk, setOdk] = useState("all");
  const [statsnotification, setStatsNotification] = useState(false);
  const [playernotification, setPlayerNotification] = useState(false);
  const [specificnotification, setSpecificNotification] = useState(false);
  const [odata, setOdata] = useState({});
  const [ddata, setDdata] = useState({});
  const [kdata, setKdata] = useState({});
  const [qbdata, setQbdata] = useState({});
  const [oldata, setOldata] = useState({});
  const [wrdata, setWrdata] = useState({});
  const [tedata, setTedata] = useState({});
  const [rbdata, setRbdata] = useState({});
  const [dldata, setDldata] = useState({});
  const [lbdata, setLbdata] = useState({});
  const [sdata, setSdata] = useState({});
  const [cdata, setCdata] = useState({});
  const [kkdata, setKkdata] = useState({});
  const [stdata, setStdata] = useState({});
  const [kchartGameData, setKChartGameData] = useState([]);
  const [kchartPieData, setKChartPieData] = useState([]);
  const instances = [
    "Kicker",
    "Kickoff",
    "Kick Return",
    "Punt",
    "Punt Return",
    "PAT",
    "PAT Block",
    "FG Attempt",
    "FG Blocked",
  ];

  const instanceMappings = {
    Kicker: "Kicker",
    Kickoff: "Kickoff/Return",
    "PAT Block": "PAT/Block",
    Punt: "Punt/Return",
    "FG Attempt": "FG Attempt/Block",
  };

  const fetchMatchAnalyticsByDepthChart = (game, odk, blobName) => {
    props.matchAnalyticsByDepthChart({
      params: { game, odk },
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows);
          const mapInstances = () => {
            return instances.map((instance) => {
              const blobItem = blob[0].find(({ name }) => name === instance);
              const execution = blobItem
                ? formatNum(
                    (blobItem.total_assignment_executed /
                      blobItem.total_count) *
                      100
                  )
                : 0;
              const effort = blobItem
                ? formatNum(
                    (blobItem.total_great_effort / blobItem.total_count) * 100
                  )
                : 0;
              const totalFlags = blobItem ? formatNum(blobItem.total_flags) : 0;

              return {
                instance: instance,
                Execution: execution,
                Effort: effort,
                totalFlags,
              };
            });
          };
          const mappedInstances = mapInstances();
          setKChartGameData(mappedInstances);
          const piechartdata = Object.keys(instanceMappings).map((instance) => {
            const name = instanceMappings[instance];
            const mappedInstance = mappedInstances.find(
              (inst) => inst.instance === instance
            );
            const value = mappedInstance ? mappedInstance.totalFlags : 0;
            return {
              name: name,
              value: value,
            };
          });
          setKChartPieData(piechartdata);
        }
      },
    });
  };

  const history = useNavigate();

  const openStatsNotification = () => {
    setStatsNotification(true);
  };
  const openPlayerNotification = () => {
    setPlayerNotification(true);
  };

  const closeStatsNotification = () => {
    setStatsNotification(false);
  };
  const closePlayerNotification = () => {
    setPlayerNotification(false);
  };
  const closeSpecificNotification = () => {
    setSpecificNotification(false);
  };

  useEffect(() => {
    loadSeasonsList();
  }, []);

  useEffect(() => {
    if (seasonsList[0]) {
      loadGamesList();
    }
  }, [seasonsList]);

  useEffect(() => {
    if (gamesList.length > 0) {
      loadGamesAnalytics();
    }
    
  }, [gamesList]);

  const formatNum = (val) => {
    if (isNaN(val)) return 0;
    return Math.round(val);
  };

  const loadGamesAnalytics = () => {
   
    let games = gamesList?.filter((game) => game.type == "regular season" || game.type == 'playoffs').map(({id}) =>id);
    setGamesIdsList(games);
    games = JSON.stringify(games);
    fetchGamesAnalytics(games);
    fetchGamesAnalytics(games, "o", setOdata);
    fetchGamesAnalytics(games, "d", setDdata);
    fetchGamesAnalytics(games, "k", setKdata);
  };

  const handleGamesAnalytics = () => {
    const games = JSON.stringify(gamesIds);
    fetchGamesAnalytics(games);
    fetchGamesAnalytics(games, "o", setOdata);
    fetchGamesAnalytics(games, "d", setDdata);
    fetchGamesAnalytics(games, "k", setKdata);
  };

  const toggleGameID = (id) => {
    let newList = [];
    const isPresent = gamesIds.find((i) => i == id);
    if (isPresent) {
      newList = gamesIds.filter((i) => i != id);
    } else {
      newList = gamesIds;
      newList.push(id);
    }
    setGamesIdsList(newList);
  };

  const fetchGamesAnalytics = (games, odk = null, setData) => {

    console.log('{games}1---',{games})
    if (odk == "all") odk = null;
    if (games.length) {
      props.gamesAnalytics({
        params: { games, odk },
        callback: (res, data) => {
          if (res === true) {
            const blob = data?.data?.items.map(({ rows }) => rows[0]);
            const result = {
              ...blob[0],
              ...blob[1],
              ...blob[2],
              ...blob[3],
              ...blob[4],
              ...blob[5],
              ...blob[6],
              ...blob[7],
              ...blob[8],
              ...blob[9],
            };
            setData ? setData(result) : setGamesData(result);
          }
        },
      });
    } else {
      setData([]);
    }
  };

  const fetchGamesAnalyticsWithPositions = (
    games,
    odk = null,
    position,
    setData
  ) => {
    console.log('{games}',{games})
    if (games.length) {
     
      props.gamesAnalytics({
        params: { games, position },
        callback: (res, data) => {
          if (res) {
            const blob = data?.data?.items.map(({ rows }) => rows[0]);
            const result = {
              ...blob[0],
              ...blob[1],
              ...blob[2],
              ...blob[3],
              ...blob[4],
              ...blob[5],
              ...blob[6],
              ...blob[7],
              ...blob[8],
              ...blob[9],
            };
            setData(result);
          } else {
            setData([]);
          }
        },
      });
    } else {
      setData([]);
    }
  };

  const fetchOunitsAnalytics = () => {
    const games = JSON.stringify(gamesIds);
    fetchGamesAnalyticsWithPositions(
      games,
      "o",
      JSON.stringify(["QB"]),
      setQbdata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "o",
      JSON.stringify(["OL"]),
      setOldata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "o",
      JSON.stringify(["TE"]),
      setTedata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "o",
      JSON.stringify(["RB"]),
      setRbdata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "o",
      JSON.stringify(["WR"]),
      setWrdata
    );
  };
  const fetchDunitsAnalytics = () => {
    const games = JSON.stringify(gamesIds);
    fetchGamesAnalyticsWithPositions(
      games,
      "d",
      JSON.stringify(["DL"]),
      setDldata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "d",
      JSON.stringify(["LB"]),
      setLbdata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "d",
      JSON.stringify(["S"]),
      setSdata
    );
    fetchGamesAnalyticsWithPositions(
      games,
      "d",
      JSON.stringify(["C"]),
      setCdata
    );
  };
  const fetchKunitsAnalytics = () => {
    fetchMatchAnalyticsByDepthChart(JSON.stringify(gamesIds), "k");
  };

  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));
    if (list) {
      setSeasonsList(list);
    } else {
      props.seasonsList({
        params: { sort: "id.desc", limit: 1000 },
        callback: (res, data) => {
          if (res === true) {
            const result = data.data.items;
            setSeasonsList(result);
            sessionStorage.setItem("seasonsList", JSON.stringify(result));
          }
        },
      });
    }
  };

  const handleSortPlay = (type) => {
    const games = JSON.stringify(gamesIds);
    fetchGamesAnalytics(games, type);
    setOdk(type);
    if (type === "o" || type === "d" || type === "k") {
      fetchGamesAnalytics(games, "d", setDdata);
      fetchGamesAnalytics(games, "o", setOdata);
      fetchGamesAnalytics(games, "k", setKdata);
    } else if (type === "s") {
      fetchGamesAnalytics(games, type);
    }
  };

  const loadGamesList = (seasonID = null) => {
    const season = seasonID ?? seasonsList[0]?.id;

    let params = {
      limit: 995,
      home_team: _.get(props, "coachProfile.school.id"),
      sort: "week.asc",
      season,
    };
    const list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));

    if (list) {
      setGamesList(list);
      setGamesListLoading(false);
    } else {
      setGamesListLoading(true);
    }

    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          sessionStorage.setItem(
            `${seasonID}-gamesList`,
            JSON.stringify(_.get(data, "data.items", []))
          );
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const columns = [
    {
      title: () => <Checkbox disabled />,
      dataIndex: "checked",
      width: "5%",
      align: "center",
      key: "id",
      render: (text, item) => (
        <Checkbox
          onChange={(e) => toggleGameID(item.id)}
          defaultChecked={text}
        />
      ),
    },
    {
      title: "Week #",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      dataIndex: "week",
      align: "center",
      width: "7%",
      key: "week",
    },

    {
      title: "Opponent",
      dataIndex: "title",
      align: "center",
      width: "15%",
      key: "title",
      render: (text, item) => (
        <div className="text-[10px] lg:text-[12px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#a40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "type",
      align: "center",
      width: "12%",
      key: "type",
    },
    {
      title: "Results",
      dataIndex: "start_time",
      align: "center",
      key: "results",
      width: "10%",
      className: "p-0",
      render: (text, item) => (
        <div
          className={
            item.home_team_score - item.away_team_score <= 0
              ? "bg-[#a40E171a] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
              : "bg-[#12CF521a] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
          }
        >
          <span className="font-inter font-bold text-[10px] lg:text-[11.5px]">
            {item.home_team_score - item.away_team_score <= 0 ? "L:" : "W:"}{" "}
          </span>

          <span className="ml-[2%] text-[10px] lg:text-[11.5px]">
            {item.home_team_score}
          </span>
          <span>-</span>

          <span className="text-[10px] lg:text-[11.5px]">
            {item.away_team_score}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "13%",
      align: "center",
      className: "p-0",
      key: "created_at",
      render: (text, item) => {
        return (
          <div className="gameanalytics-table-action w-[90%] mx-auto">
            <Tooltip title="View Game Analytics">
              <Button
                icon={<img src={game} />}
                onClick={() => {
                  history(`/game-analytic-dashboard?id=${_.get(item, "id")}`, {
                    state: { week: item.week },
                  });
                }}
              />
            </Tooltip>

            <Tooltip title="View Player Stats">
              <Button
                icon={
                  <img
                    src={userSquare}
                    onClick={() => {
                      history(
                        `/player-analytic-dashboard?game=${_.get(item, "id")}`
                      );
                    }}
                  />
                }
              />
            </Tooltip>

            <Tooltip title="View Coaches Notes">
              <Button
                onClick={() =>
                  history(
                    `/coaches-note?game_id=${_.get(item, "id")}&opponent=${
                      item.title
                    }&team=${item?.team}&week=${item.week}&t=${
                      item.feedback_type
                    }`
                  )
                }
                icon={<img src={clipimg} />}
              />
            </Tooltip>

            <Tooltip title="View Statistics">
              <Button
                icon={<img src={stats} onClick={openStatsNotification} />}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const totalFlagforodk =
    parseInt(ddata.total_flags) +
    parseInt(kdata.total_flags) +
    parseInt(odata.total_flags);

  const totalFlagsForOunits =
    parseInt(qbdata.total_flags) +
    parseInt(oldata.total_flags) +
    parseInt(wrdata.total_flags) +
    parseInt(tedata.total_flags) +
    parseInt(rbdata.total_flags);

  const totalFlagsForDunits =
    parseInt(qbdata.total_flags) +
    parseInt(oldata.total_flags) +
    parseInt(wrdata.total_flags) +
    parseInt(tedata.total_flags) +
    parseInt(rbdata.total_flags);

  const totalflagdivision =
    (parseInt(gamesData.total_flags_in_lost_games) /
      parseInt(gamesData.total_flags)) *
    100;
  return (
    <div className="flex flex-col w-full">
    

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {/* aLL, O,D,K BUTTONS */}
          <div className="w-[187px] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around mr-[16px]">
            <button
              onClick={() => handleSortPlay("all")}
              className={
                (odk == "all" ? " bg-[#A40E17]" : " ") +
                " w-[43px] h-[31px] rounded-[4px] text-[#FFFFFF]"
              }
            >
              All
            </button>
            <button
              onClick={() => {
                handleSortPlay("o");
                fetchOunitsAnalytics();
              }}
              className={
                (odk == "o" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              O
            </button>
            <button
              onClick={() => {
                handleSortPlay("d");
                fetchDunitsAnalytics();
              }}
              className={
                (odk == "d" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              D
            </button>
            <button
              onClick={() => {
                handleSortPlay("k");
                fetchKunitsAnalytics();
              }}
              className={
                (odk == "k" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              K
            </button>
          </div>
          <div className="w-[200px] h-[43px] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
            <Select
              bordered={false}
              defaultValue={seasonsList[0]?.title}
              placeholder={seasonsList[0]?.title}
              onChange={(value) => {
                loadGamesList(value);
              }}
              className="w-[100%] text-[14px]"
            >
              {_.map(seasonsList, ({ id, title }, index) => {
                return (
                  <Option
                    value={id}
                    key={id}
                    className="text-[14px] font-bold font-inter"
                  >
                    {title}
                  </Option>
                );
              })}
            </Select>
          </div>
          {/* consistent and specific buttons removed frm here */}
        </div>
        {/* print and effort div */}
        <div className="flex items-center">
          {/* win or loss record */}
          <div className="flex items-center font-bold">
            {/* win or loss div */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">Win/Loss Record</span>
              <span className="text-[18px] text-[#1790FF]">
                {formatNum(gamesData.total_wins)} -{" "}
                {formatNum(gamesData.total_loss)}
              </span>
            </div>
            {/* winning percent */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">Winning %</span>
              <span className="text-[18px] text-[#ED780B]">
                {formatNum(
                  (gamesData.total_wins /
                    (Number(gamesData.total_loss) +
                      Number(gamesData.total_wins))) *
                    100
                )}
                %
              </span>
            </div>
            {/* total assignment */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">
                Overall Execution
              </span>
              <span className="text-[18px] text-[#00B21E]">
                {formatNum(
                  (gamesData.total_assignment_executed /
                    gamesData.total_grades) *
                    100
                )}
                %
                <br />
                {/* <small className='text-[10px]'>({gamesData.total_assignment_executed} / {gamesData.total_grades})</small> */}
              </span>
            </div>
            {/* Effort Score */}
            <div className="flex flex-col text-center">
              <span className="text-[8px] text-[#526B84]">Overall Effort</span>{" "}
              <span className="text-[18px] text-[#00B21E]">
                {formatNum(
                  (gamesData.total_great_effort / gamesData.total_grades) * 100
                )}
                %
                <br />
                {/* <small className='text-[10px]'>({gamesData.total_great_effort} / {gamesData.total_grades})</small> */}
              </span>
            </div>
          </div>
          {/* print and share div removed*/}
        </div>
      </div>

      {/* guages div */}
      {/* effort and execution report div */}
      <div className="flex justify-between items-center mt-[20px] w-full">
        {/* exec */}
        <PlayerAnalyticCell
          Title={"Execution Report"}
          total_games={
            parseInt(gamesData.total_wins) + parseInt(gamesData?.total_loss)
          }
          TotalAssignmentsEvaluated={gamesData.total_grades}
          Avg={"Execution"}
          winValue={
            (gamesData.total_assignment_executed_in_won_games /
              gamesData.total_grades_in_won_games) *
            100
          }
          lossValue={
            (gamesData.total_assignment_executed_in_lost_games /
              gamesData.total_grades_in_lost_games) *
            100
          }
          totalPlays={gamesData.total_plays}
          calcParam={"Assignments Failed"}
          calcParamValue={
            gamesData.total_grades - gamesData.total_assignment_executed
          }
          differential={"5%"}
          formatNum={formatNum}
        />
        {/* effort */}
        <PlayerAnalyticCell
          Title={"Effort Report"}
          TotalAssignmentsEvaluated={gamesData.total_grades}
          Avg={"Effort"}
          total_games={
            parseInt(gamesData.total_wins) + parseInt(gamesData?.total_loss)
          }
          winValue={
            (gamesData.total_great_effort_in_won_games /
              gamesData.total_grades_in_won_games) *
            100
          }
          differential={"2%"}
          totalPlays={gamesData.total_plays}
          lossValue={
            (gamesData.total_great_effort_in_lost_games /
              gamesData.total_grades_in_lost_games) *
            100
          }
          calcParam={"Bad Effort Plays"}
          calcParamValue={gamesData.total_grades - gamesData.total_great_effort}
          formatNum={formatNum}
        />
      </div>

      {/* line charts div */}
      {odk !== "k" ? (
        <div className="flex justify-between items-center mt-[20px]">
          {/* exec */}
          <div
            className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
            style={{ border: "1px solid #EaEDF0" }}
          >
            <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
              {" "}
              <span>Execution by Down </span>
            </div>
            <div className="flex flex-col mt-[11px] w-full p-[12px]">
              <div className="flex items-center  w-full font-inter text-[12px]">
                <span className="w-[100%]">
                  <span className=" w-[100%] flex items-center ">
                    <MultiBarChartTwo
                      title="Win"
                      titleTwo="Loss"
                      data={[
                        {
                          instance: "1st down",
                          Executed: formatNum(
                            gamesData.total_assignment_executed_won_dn_one
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_one -
                              gamesData.total_assignment_executed_won_dn_one
                          ),
                        },
                        {
                          instance: "2nd down",
                          Executed: formatNum(
                            gamesData.total_assignment_executed_won_dn_two
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_two -
                              gamesData.total_assignment_executed_won_dn_two
                          ),
                        },
                        {
                          instance: "3rd down",
                          Executed: formatNum(
                            gamesData.total_assignment_executed_won_dn_three
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_three -
                              gamesData.total_assignment_executed_won_dn_three
                          ),
                        },
                        {
                          instance: "4th down",
                          Executed: formatNum(
                            gamesData.total_assignment_executed_won_dn_four
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_four -
                              gamesData.total_assignment_executed_won_dn_four
                          ),
                        },
                      ]}
                      dataTwo={[
                        {
                          instance: "1st down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_one -
                              gamesData.total_assignment_executed_lost_dn_one
                          ),
                          Executed: formatNum(
                            gamesData.total_assignment_executed_lost_dn_one
                          ),
                        },
                        {
                          instance: "2nd down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_two -
                              gamesData.total_assignment_executed_lost_dn_two
                          ),
                          Executed: formatNum(
                            gamesData.total_assignment_executed_lost_dn_two
                          ),
                        },
                        {
                          instance: "3rd down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_three -
                              gamesData.total_assignment_executed_lost_dn_three
                          ),
                          Executed: formatNum(
                            gamesData.total_assignment_executed_lost_dn_three
                          ),
                        },
                        {
                          instance: "4th down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_four -
                              gamesData.total_assignment_executed_lost_dn_four
                          ),
                          Executed: formatNum(
                            gamesData.total_assignment_executed_lost_dn_four
                          ),
                        },
                      ]}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
          {/* effort */}
          <div
            className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
            style={{ border: "1px solid #EaEDF0" }}
          >
            <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
              {" "}
              <span>Effort by Down </span>
            </div>
            <div className="flex flex-col mt-[11px] w-full p-[12px]">
              <div className="flex justify-between items-center  w-full font-inter text-[12px]">
                <span className="w-[100%]">
                  <span className=" w-[100%] flex items-center">
                    <MultiBarChartTwo
                      title="Win"
                      titleTwo="Loss"
                      data={[
                        // total_grades_dn_one
                        {
                          instance: "1st down",
                          Executed: formatNum(
                            gamesData.total_great_effort_won_dn_one
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_one -
                              gamesData.total_great_effort_won_dn_one
                          ),
                        },
                        {
                          instance: "2nd down",
                          Executed: formatNum(
                            gamesData.total_great_effort_won_dn_two
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_two -
                              gamesData.total_great_effort_won_dn_two
                          ),
                        },
                        {
                          instance: "3rd down",
                          Executed: formatNum(
                            gamesData.total_great_effort_won_dn_three
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_three -
                              gamesData.total_great_effort_won_dn_three
                          ),
                        },
                        {
                          instance: "4th down",
                          Executed: formatNum(
                            gamesData.total_great_effort_won_dn_four
                          ),
                          Failed: formatNum(
                            gamesData.total_grades_won_dn_four -
                              gamesData.total_great_effort_won_dn_four
                          ),
                        },
                      ]}
                      dataTwo={[
                        // total_grades_dn_one
                        {
                          instance: "1st down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_one -
                              gamesData.total_great_effort_lost_dn_one
                          ),
                          Executed: formatNum(
                            gamesData.total_great_effort_lost_dn_one
                          ),
                        },
                        {
                          instance: "2nd down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_two -
                              gamesData.total_great_effort_lost_dn_two
                          ),
                          Executed: formatNum(
                            gamesData.total_great_effort_lost_dn_two
                          ),
                        },
                        {
                          instance: "3rd down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_three -
                              gamesData.total_great_effort_lost_dn_three
                          ),
                          Executed: formatNum(
                            gamesData.total_great_effort_lost_dn_three
                          ),
                        },
                        {
                          instance: "4th down",
                          Failed: formatNum(
                            gamesData.total_grades_lost_dn_four -
                              gamesData.total_great_effort_lost_dn_four
                          ),
                          Executed: formatNum(
                            gamesData.total_great_effort_lost_dn_four
                          ),
                        },
                      ]}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-6"
          style={{ border: "1px solid #EaEDF0" }}
        >
          <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
            {" "}
            <span>Special Teams Units</span>
          </div>
          <div className="flex flex-col mt-[11px] w-full p-[12px]">
            <div className="flex justify-between items-center  w-full font-inter text-[12px]">
              <span className="w-[100%]">
                <span className=" w-[100%] flex items-center">
                  <KMultiLineChart
                    title="Execution"
                    titleTwo="Effort"
                    data={kchartGameData}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}

      {/* progress bars */}
      <div className="flex items-center text-[#0F1A24] mt-6 font-bold text-xl">
        <span>Flag Report</span>
      </div>
      {/* flag breakdown */}
      <div className="w-full flex items-center justify-between mt-6">
        {/* total number of plays div */}
        <div
          className="flex flex-col rounded-[8px] font-inter w-[49%] h-[231px] mr-[24px]"
          style={{ border: "1.5px solid #EaEDF0" }}
        >
          <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
            <span>Flag Overview</span>
          </div>
          <div className="h-[184px] w-full justify-between p-[12px] bg-[#FFFFFF] font-poppins flex flex-wrap items-center font-bold text-[12px]">
            <TotalNumberCard
              title="Total Number of Plays"
              number={formatNum(gamesData.total_plays)}
            />
            <TotalNumberCard
              title="Total Number of Flags"
              number={formatNum(gamesData.total_flags)}
            />
            <TotalNumberCard
              title=" Assignments Executed"
              number={formatNum(gamesData.total_assignment_executed)}
            />
            <TotalNumberCard
              title="Flags Per Game"
              number={formatNum(
                parseInt(gamesData.total_flags) /
                  (Number(gamesData.total_wins) + Number(gamesData.total_loss))
              )}
            />
          </div>
        </div>
        {/* flag wig or loss break down */}
        <div
          className="flex flex-col rounded-[8px] font-inter w-[49%] h-[231px] mr-[24px]"
          style={{ border: "1.5px solid #EaEDF0" }}
        >
          <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
            <span>Flag Win/Loss Breakdown</span>
          </div>
          <div className="w-full h-full text-center bg-[#FFFFFF] font-poppins flex flex-col items-center justify-center font-bold text-[#526B84] text-[12px]">
            {/* win progress bar */}
            <div className="flex justify-between items-center mt-[13px] flagWinBreakdown">
              <span className="text-[10px] mr-[12px] mt-[4px]"> Wins</span>
              <div className="progress-container">
                <Progress
                  percent={formatNum(
                    (parseInt(gamesData.total_flags_in_won_games) /
                      parseInt(gamesData.total_flags)) *
                      100
                  )}
                  style={{ width: 358, margin: 0, padding: 0 }}
                  strokeColor="#E2B517"
                  showInfo={false}
                />
                <div className="progress-text1">
                  {formatNum(gamesData.total_flags_in_won_games)}
                </div>
              </div>
            </div>
            {/* losses progress bar */}
            <div className="flex justify-between items-center mt-[16px] flagWinBreakdown">
              <span className="text-[10px] mr-[5px] mt-[4px]"> Losses</span>
              <div className="progress-container">
                <Progress
                  percent={formatNum(
                    (parseInt(gamesData.total_flags_in_lost_games) /
                      parseInt(gamesData.total_flags)) *
                      100
                  )}
                  style={{ width: 358, margin: 0, padding: 0 }}
                  strokeColor="#a40E17"
                  showInfo={false}
                />
                <div className="progress-text">
                  {formatNum(gamesData.total_flags_in_lost_games)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* pie charts */}
      {/* flag down break down */}
      {odk === "all" ? (
        <div className="flex items-center w-full justify-between">
          {/* flag by down */}

          {/* flag breakdown pie chart */}
          {/* Flag Pie */}
          <div
            className="mt-4 flex flex-col rounded-[8px] w-[49%]"
            style={{ border: "1.5px solid #EaEDF0" }}
          >
            <PieChartCel
              title="Flag Down Breakdown"
              data={[
                {
                  name: "1st Down",
                  value: formatNum(parseInt(gamesData.total_flags_dn_one)),
                },
                {
                  name: "2nd Down",
                  value: formatNum(parseInt(gamesData.total_flags_dn_two)),
                },
                {
                  name: "3rd Down",
                  value: formatNum(parseInt(gamesData.total_flags_dn_three)),
                },
                {
                  name: "4th Down",
                  value: formatNum(parseInt(gamesData.total_flags_dn_four)),
                },
              ]}
              width={300}
            />{" "}
          </div>

          {/* todo 
              pass the correct data to the piechart */}
          <div
            className="mt-4 flex flex-col rounded-[8px] w-[49%]"
            style={{ border: "1.5px solid #EaEDF0" }}
          >
            <PieChartCel
              title="Flag Comparison"
              data={[
                {
                  name: "Offense",
                  value: formatNum(parseInt(odata.total_flags)),
                },
                {
                  name: "Defense",
                  value: formatNum(parseInt(ddata.total_flags)),
                },
                {
                  name: "Special Team",
                  value: formatNum(parseInt(kdata.total_flags)),
                },
              ]}
              width={300}
            />{" "}
          </div>
        </div>
      ) : odk == "k" ? (
        <div className="flex items-center w-full justify-between">
          {/* flag by units */}
          <div
            className="mt-4 flex flex-col rounded-[8px] w-[49%]"
            style={{ border: "1.5px solid #EaEDF0" }}
          >
            <PieChartCel
              title="Flags By units"
              data={kchartPieData}
              width={300}
            />{" "}
          </div>
          {/* todo 
              pass the correct data to the piechart */}
          <div
            className="mt-4 flex flex-col rounded-[8px] w-[49%]"
            style={{ border: "1.5px solid #EaEDF0" }}
          >
            <PieChartCel
              title="Flag Comparison"
              data={[
                {
                  name: "Offense/Defense",
                  value: formatNum(
                    parseInt(odata.total_flags) + parseInt(ddata.total_flags)
                  ),
                },
                {
                  name: "Special Team",
                  value: formatNum(parseInt(kdata.total_flags)),
                },
              ]}
              width={300}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="flex items-center w-full justify-between">
          {/* flag by down */}
          {/* oni meta */}
          <div className="flex flex-col mt-[24px] font-inter w-[32%]">
            <div
              className="flex text-center rounded-t-[8px] items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full"
              style={{
                borderTop: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              <span>Flag By Down</span>
            </div>
            <div
              className="w-full text-center font-poppins flex flex-col items-center font-bold text-[#526B84] text-[12px] rounded-b-[8px]  "
              style={{
                borderBottom: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              {/* Flag Pie */}
              <PieChartComponent
                data={[
                  {
                    name: "1st Down",
                    value: formatNum(gamesData.total_flags_dn_one),
                  },
                  {
                    name: "2nd Down",
                    value: formatNum(gamesData.total_flags_dn_two),
                  },
                  {
                    name: "3rd Down",
                    value: formatNum(gamesData.total_flags_dn_three),
                  },
                  {
                    name: "4th Down",
                    value: formatNum(gamesData.total_flags_dn_four),
                  },
                ]}
                width={300}
                col={true}
                type={"flagByDown"}
              />
            </div>
          </div>
          <div className="flex flex-col mt-[24px] font-inter w-[32%]">
            <div
              className="flex text-center rounded-t-[8px] items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full"
              style={{
                borderTop: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              <span>Flag Comparison</span>
            </div>
            <div
              className="w-full text-center font-poppins flex flex-col items-center font-bold text-[#526B84] text-[12px] rounded-b-[8px] "
              style={{
                borderBottom: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              {/* Flag Pie */}

              {odk == "o" ? (
                <PieChartComponent
                  data={[
                    {
                      name: "Offense",
                      value: formatNum(parseInt(odata.total_flags)),
                    },
                    {
                      name: "Defense/Special Teams",
                      value: formatNum(
                        parseInt(ddata.total_flags) +
                          parseInt(kdata.total_flags)
                      ),
                    },
                  ]}
                  width={300}
                  col={true}
                  type={"flagComparison"}
                />
              ) : (
                ""
              )}

              {odk == "d" ? (
                <PieChartComponent
                  data={[
                    {
                      name: "Defense",
                      value: formatNum(parseInt(ddata.total_flags)),
                    },
                    {
                      name: "Offense/Special Teams",
                      value: formatNum(
                        parseInt(odata.total_flags) +
                          parseInt(kdata.total_flags)
                      ),
                    },
                  ]}
                  width={300}
                  col={true}
                  type={"flagComparison"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {/* flag by unit */}
          <div className="flex flex-col mt-[24px] font-inter w-[32%]">
            <div
              className="flex text-center rounded-t-[8px] items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full"
              style={{
                borderTop: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              <span>Flags By Units</span>
            </div>
            <div
              className="w-full text-center font-poppins flex flex-col items-center font-bold text-[#526B84] text-[12px] rounded-b-[8px]"
              style={{
                borderBottom: "1.5px solid #EaEDF0",
                borderLeft: "1.5px solid #EaEDF0",
                borderRight: "1.5px solid #EaEDF0",
              }}
            >
              {/* Flag Pie */}
              <PieChartComponent
                data={
                  odk == "o"
                    ? [
                        {
                          name: "Quarterback",
                          value: formatNum(parseInt(qbdata.total_flags)),
                        },
                        {
                          name: "Offensive lineman",
                          value: formatNum(parseInt(oldata.total_flags)),
                        },
                        {
                          name: "Wide Receiver",
                          value: formatNum(parseInt(wrdata.total_flags)),
                        },
                        {
                          name: "Tight end",
                          value: formatNum(parseInt(tedata.total_flags)),
                        },
                        {
                          name: "Running Back",
                          value: formatNum(parseInt(rbdata.total_flags)),
                        },
                      ]
                    : [
                        {
                          name: "Defensive Lineman",
                          value: formatNum(parseInt(dldata.total_flags)),
                        },
                        {
                          name: "Linebackers",
                          value: formatNum(parseInt(lbdata.total_flags)),
                        },
                        {
                          name: "Safeties",
                          value: formatNum(parseInt(sdata.total_flags)),
                        },
                        {
                          name: "Cornerback",
                          value: formatNum(parseInt(cdata.total_flags)),
                        },
                      ]
                }
                width={300}
                col={true}
                type={"flagByUnits"}
              />
            </div>
          </div>
        </div>
      )}

      {/* generate custom reports */}
      <button
        onClick={() => handleGamesAnalytics()}
        className="mt-[24px] bg-[#E8F4FF] w-[187px] h-[36px] flex items-center text-[#2E4D6B] focus:outline-0 px-[8px] font-normal rounded-[4px] text-[12px]"
      >
        <img src={multiplePages} className="w-[20px] mr-[8px]" />
        <span>Generate Custom Report</span>
      </button>

      {/* table div */}
      <div className="gameanalytictablediv mt-[8px]">
        <Table
          key={1}
          bordered={true}
          loading={gamesListLoading}
          columns={columns}
          dataSource={gamesList?.map((item) => {
            item.checked = true;
            return item;
          })}
          size="middle"
          pagination={false}
          className="w-[100%] h-full border-[1px] border-[#F3F5F7] border-[solid]"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  matchAnalyticsByDepthChart: (details) =>
    dispatch(global.redux.action.grades.matchAnalyticsByDepthChart(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonAnalyticDashboard);
