import "./home-page.css";
import { Avatar } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const DepthChart = ({
  data,
  moveUp,
  moveDown,
  removePlayer,
  pointer,
  depth_chart_id,
  id,
  isShared,
  index
}) => {

  
  return (
    <div className='depth-chart-item'>
      <div className='rank'>{index + 1}.</div>
      <div className='body'>

        {/* <div className='avatar'>
          <Avatar style={{ backgroundColor: 'red' }} size='small'>
            {data.first_name[0].toUpperCase()}
          </Avatar>
        </div> */}
        <div className='name'>
          <p>
            {data.first_name} {data.last_name}
          </p>
        </div>
        {!isShared ?
          <div className='control'>
            <div onClick={() => moveUp(depth_chart_id, pointer)} className='up'>
              <ArrowUpOutlined />
            </div>
            <div
              onClick={() => moveDown(depth_chart_id, pointer)}
              className='down'>
              <ArrowDownOutlined />
            </div>
            <div
              onClick={() => removePlayer(depth_chart_id, data.player_id)}
              className='remove'>
              <CloseOutlined />
            </div>
          </div>
          :  ''}
      </div>
    </div>
  );
};

export default DepthChart;
