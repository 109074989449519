import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
	Modal,
	Input,
	DatePicker,
	Tag,
	Form,
	notification
} from "antd";
import { connect } from "react-redux";
import moment from 'moment'
import _ from 'lodash';
import { BrowserRouter as Router, Link, useHistory, useNavigate } from "react-router-dom";

import "./games.css";
const Option = Select.Option;

const GamesListComponent = (props) => {
	const [form] = Form.useForm();

	const [TableData, setTableData] = useState('');
	const [showModal, setshowModal] = useState(false)
	const [gamesList, setGamesList] = useState([])
	const [gamesListMeta, setGamesListMeta] = useState({})
	const [gamesListLoading, setGamesListLoading] = useState(true)
	const [page,setPage]=useState(1)
	const [visible, setVisible] = useState(false)
	const [gameId, setGameId] = useState('')
	
	
	let history = useNavigate();

	const showModalView = (item) => {
		setshowModal(true)
		setTableData(item)
	}
	const columns = [
		{
			title: 'Team',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Season',
			dataIndex: 'season',
			align: 'center',
			key: 'season',
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			align: 'center',
			key: 'created_at',
		},
		{
			title: 'Week',
			dataIndex: 'week',
			align: 'center',
			key: 'week',
		},
		{
			title: 'Start Time',
			dataIndex: 'start_time',
			key: 'start_time',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		// {
		// 	title: 'End Time',
		// 	dataIndex: 'end_time',
		// 	key: 'end_time',
		// },
		// {
		// 	title: 'Duration(minutes)',
		// 	dataIndex: 'duration',
		// 	key: 'duration',
		// },
		// {
		// 	title: 'Action',
		// 	dataIndex: 'action',
		// 	width: '20%',
		// 	align: 'center',
		// 	key: 'action',
		// 	render: (text, item) => {
		// 	 
		// 	return	<div className="CategoriesViewList">
		// 		<div className="mt-2">
		// 			{_.get(item, 'value.status') === 0 ? (
		// 				<Button
		// 					className="btn-active"
		// 					color="green"
		// 					onClick={() => {setVisible(true);setGameId(_.get(item, 'game_id')); form.setFieldsValue({
		// 						status:1,
		// 						status_glossary:_.get(item,'value.status_glossary','')

		// 					})}}
		// 				>
		// 					Active
		// 				</Button>
		// 			) : (
		// 					<Button
		// 						onClick={() => {setVisible(true);setGameId(_.get(item, 'game_id')); form.setFieldsValue({
		// 							status:0,
		// 							status_glossary:_.get(item,'value.status_glossary','')
		// 						})}}
		// 						className="btn-deactive"
		// 						color="red"
		// 					>
		// 						Deactivate
		// 					</Button>
		// 				)}
		// 		</div>
		// 	</div>
				
		// 					},
		// },
	];
	useEffect(() => {
			loadGamesList()
		
		
	},[])

	const loadGamesList = (limit = 9, page = 1,season="") => {
		let params ={limit, page,expand:"season"}
		setGamesListLoading(true)
		props.gamesList({
			params: params,
			callback: (res, data) => {
				if (res === true) { 
					setGamesListMeta(_.get(data, 'data.meta', {}))
					setAllGamesList(_.get(data, 'data.items', []))
				}
				setGamesListLoading(false)
			}
		})
	}

	const setAllGamesList = (data) => {
		
		let list = []
		if (_.size(data) > 0) {
			_.map(data, (value, index) => {
				list.push({
					key: index,
					title:_.startCase(_.get(value, 'title')),
					season: _.get(value, 'season.title'),
					created_at: _.get(value, 'start_time')? moment(`${_.get(value, 'start_time')}`).format('MM/DD/YYYY'):'---' ,
					week: _.get(value, 'week'),
					start_time: _.get(value, 'start_time')? moment(`${_.get(value, 'start_time')}`).format('h:mm A'):'---',
					// end_time: moment(`${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`).format('h:mm A'),
					// duration: _.get(value, 'duration'),
					game_id:_.get(value, 'id'),
					value,
					status :<div className="TextSemiBold">
					{_.get(value, 'status', '-') === 1 ? (
						<Tag color="green">Active</Tag>
					) : (
							<Tag color="red">In Active</Tag>
						)}
				</div>
				})
			})

			setGamesList(list)
		} else {
			setGamesList([])
		}
	}

	const hideModal = () => {
		setshowModal(false)
	};
	const onChangePage = (page, size) => {
		setPage(page)
		loadGamesList(size, page)
	}
	const getDropList = () => {
		const year = new Date().getFullYear();
		const start_year = year -2
		const end_year = year +8
		const start_year_split =parseInt(`${start_year}`.slice(2))

	  return (
		  Array.from( new Array(30), (v,i) =>
			<Option key={i} value={`${start_year+i}-${start_year_split+i+1}`}>{`${start_year+i}-${start_year_split+i+1}`}</Option>
		)
	  );
	};
	function onChange(value) {
		 
	  }
	  const onchangeFilter =(value,e)=>{
		  
	  }
	  const onselectFilter =(e)=>{
		loadGamesList(9,page,e)
	  }
	const onFinish =(details)=>{
		props.changeStatus({
			body:{...details,id:gameId},
			callback:(res,data)=>{
				if(res==true && _.get(data,'details.id')){
					notification.success({
						message:"Success",
						description:_.get(data,'message')
						
					})
					loadGamesList(9,page)
				}
			}
		})
	}
	return (
		<div className="HomePageContainer">
			<div className="container">
				<div className="full-width">
					<div className="TableFilter">
						<div className="row"><span className="TableFilterText">Filter</span>
							<Select
								showSearch
								style={{ width: 150 }}
								placeholder="Select a year"
								onSelect={(e)=>onselectFilter(e)}
							>
								{getDropList()}
							</Select>
						{/* <div style={{marginLeft:"72%"}} className="plays-list-action-btns">
								<Link to={`/create-games`} className="ant-btn TableActionBtn edit">Create Game</Link>
							</div> */}
							</div>
					</div>
					<div className="TableContainer">
					<Table bordered key={gamesList.id} loading={gamesListLoading} columns={columns} dataSource={gamesList} size="middle" pagination={false} />
					</div>
					{gamesListLoading == false && _.get(gamesListMeta, 'total') > 10 && <div className="PaginationContainer">
							<span className="PaginationContainerInner">
								<span className="PaginationLabel">Total {_.get(gamesListMeta, 'total') > 1 ? `${_.get(gamesListMeta, 'total')} items` : `${_.get(gamesListMeta, 'total')} item`}</span>
								<Pagination defaultCurrent={1}
								current={page}
									hideOnSinglePage={true}
									onChange={(page, size) => onChangePage(page, size)}
									total={_.get(gamesListMeta, 'total')}
								// total={500}
								/>
							</span>
							<span className="PaginationContainerInner">
								<span className="PaginationLabel ml-3">Go to</span>
								<InputNumber min={1} max={_.get(gamesListMeta, 'total') > 10 ? _.get(gamesListMeta, 'total') / 9 : 2} onPressEnter={(e) => onChangePage(_.get(e, 'target.value'), 9)} style={{ width: '70px' }} />
							</span>
						</div>}
				</div>
				<Modal
											visible={visible}
											title="Change Status"
											//   onOk={this.handleOk}
											onCancel={()=>setVisible(false)}
											footer={null}
										>
											<div className="row">
												<div className="col-12">
													<div className="CategoriesViewList">
														<Form
													layout="vertical"
															scrollToFirstError={true}
															onFinish={(e) => onFinish(e)}
															// onFinishFailed={(e) => onFinishFailed(e)}
															form={form}
														>
															<Form.Item
															 className="col-12 col-md-12"
																label="Status"
																name="status"
																rules={[
																	{
																		required: true,
																		message: "This field required!",
																	},
																]}
															>
																<Select >
																	<Option value={1}>Active</Option>
																	<Option value={0}>Inactive</Option>
																</Select>
															</Form.Item>
															<Form.Item
															 className="col-12 col-md-12"
																label="Reason for the change"
																name="status_glossary"
																rules={[
																	{
																		required: true,
																		message: "This field required!",
																	},
																]}
															>
																<Input.TextArea />
															</Form.Item>

															<div
																className="text-align-end"
																style={{ textAlign: "end" }}
															>
																<Button
																	className="mr-2"
																	onClick={()=>setVisible(false)}
																>
																	Cancel
                                  								</Button>
																<Button
																	htmlType="submit"
																	type="primary"
																>
																	Submit
                                 								</Button>
															</div>

														</Form>
													</div>
												</div>
											</div>
										</Modal>
			</div>
			
		</div>
	);
	}
const mapStateToProps = state => {
	 
	return {
userProfile:_.get(state,'app.account.profile.data.details')

	}
};

const mapDispatchToProps = dispatch => ({
	gamesList: details => dispatch(global.redux.action.games.list(details)),
	schoolList: details => dispatch(global.redux.action.schools.list(details)),
	changeStatus: details => dispatch(global.redux.action.games.changeStatus(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(GamesListComponent)