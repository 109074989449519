import {
  Table,
  Card, 
  Result,
  Modal,
  Button,
  Avatar,
  Space,
  Skeleton,
   List,
  Select,
  Collapse
} from "antd";
import DepthChart from "./DepthChart";
import React, { useEffect, useState } from "react";
import "./home-page.css";
import MyDepthChartMain from "./MyDepthChartMain";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import _, { filter, isEmpty } from "lodash";

import {
  MoreOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const capitalizeString = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const MyDepthChart = ({
  coachDepthChart,
  coachDepthChartLoading,
  depth_chart_options = [],
  positions,
  createDepthChart,
  handleAddPlayerDepthChart,
  addToDepthChart,
  handleUpdateDepthChart,
  coachDepthChartId,
  setCoachDepthChartId,
  coachProfile,
  athletesListLoading,
  athletesList,
  allAthletesList,
  loadAthletesList,
  handleDeleteDepthChart,
  coachesListApi,
   belongsToEveryone,
}) => {
  useEffect(() => {
    loadAthletesList(1000, 1, positions);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDepthChart, setSelectedDepthChart] = useState(
    depth_chart_options[0]
  );
  const [coachesList, setCoachesList] = useState([]);
  const [depthChartList, setDepthChartList] = useState({});
  const [coaches, setCoaches] = useState([]);
  const [coachItems, setCoachItems] = useState([]);
  
    
    const loadCoachesList = (limit = 90, page = 1) => { 
     coachesListApi({
      params: { limit, page, school: coachProfile?.school?.id },
       callback: (res, data) => { 
        
         if (res) {
           setCoachesList(data.data.items); 
        }
         
      },
    });
    };
  
   useEffect(() => {
    loadCoachesList();
   
  }, []);
  useEffect(() => {
    const c = {};
    
    coachDepthChart.map((item) => { const name = `${item.first_name} ${item.last_name}`;
                 if(!c[name]){
                     c[name] = [];
                 }
      c[name]?.push(item);
    })
    
  
    const items = []

    Object.keys(c).map((label,key) => {
      items.push({
        key,
        label,
        children: c[label]
        //   (<div>

        //   { c[label]?.map((items,index) => {
        //     return (
        //       <MyDepthChartMain
        //         coachesList={coachesList}
        //         key={index} 
        //         coach={`${items.first_name} ${items.last_name}`}
        //         handleUpdateDepthChart={handleUpdateDepthChart}
        //         showPlayersModal={showModal}
        //         athletesListLoading={athletesListLoading}
        //         athletesList={athletesList}
        //         loadAthletesList={loadAthletesList}
        //         allAthletesList={allAthletesList}
        //         setCoachDepthChartId={setCoachDepthChartId}
        //         data={items}
        //         handleDeleteDepthChart={handleDeleteDepthChart}
        //       />
        //     );
        //   })}
        // </div>)
      })
    })
   
    setCoaches(Object.keys(c))  
    setDepthChartList(c)

    setCoachItems(items)
  }, []);

   
  const showModal = (id = null, name = null, players = [], positions = "") => {
    loadAthletesList(1000, 1, positions);
    setCoachDepthChartId(id);
    if (name) {
      handleChartTypeChange(name);
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setCoachDepthChartId(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCoachDepthChartId(null);
  };
 
  const handleChartTypeChange = (value) => {
    const result = depth_chart_options?.filter(({ code, name }) => {
    
      return `${code} (${name})`.toLowerCase() == value.toLowerCase();
    });
    setSelectedDepthChart(result[0]);
    if (result[0]) loadAthletesList(1000, 1, result[0]?.positions);
  };
  //start
  const categoryMap = {
    Defensive: [],
    Offensive: [],
    "Special Teams": [],
  };
  
  depth_chart_options?.forEach(({ positions, name, code }) => {
    let category = "";
    if (positions.startsWith("LB")) {
      category = "Defensive";
    } else if (positions.startsWith("OL")) {
      category = "Offensive";
    } else if (positions.startsWith("K")) {
      category = "Special Teams";
    }
    if (!coachDepthChart?.some((chart) => chart.name === name)) {
      // Check if the name is not present in coachDepthChart
      categoryMap[category]?.push({
        value: `${code} (${capitalizeString(`${name}`)})`,
        label: (
          <span className="font-inter">
            <span style={{ fontWeight: "bold" }}>{code}</span> (
            {capitalizeString(`${name}`)})
          </span>
        ),
      });
    }
  });
  
  const options = Object.keys(categoryMap)
    .filter((category) => categoryMap[category].length > 0)
    .map((category, index) => ({
      key: `category_${index}`,
      label: (
        <span className="font-inter font-bold text-[#000000] text-[14px]">
          {category} Charts
        </span>
      ),
      options: categoryMap[category],
      groupHeading: (base) => ({
        ...base,
        fontSize: "16px",
        color: "#000000",
      }),
    }));
    const onChange = (key) => {
    
  };
  //stop
  return (
    <div className='depth-charts'>
      <br />
      <div className='row'>
      
        {!coachItems.length ? (
          <>
            
            <div className='col-md-4 mt-3 '>
              {" "}
              <Skeleton active />
            </div>
            <div className='col-md-4 mt-3'>
              {" "}
              <Skeleton active />
            </div>
            <div className='col-md-4 mt-3'>
              {" "}
              <Skeleton active />
            </div>
          </>
        ) : (
          <div style={{width: '100%'}}> 
 
                {coachItems?.map((itemx) =>  
                (
                  <>
                    <br /><h5>   {itemx['label']}</h5>
                  <hr />
                 
                  <div className="row">
              
              { itemx?.children?.map((items,index) => {
                 return (
             
              <MyDepthChartMain
                coachesList={coachesList}
                     key={index} 
                     
                coach={`${items.first_name} ${items.last_name}`}
                handleUpdateDepthChart={handleUpdateDepthChart}
                showPlayersModal={showModal}
                athletesListLoading={athletesListLoading}
                athletesList={athletesList}
                loadAthletesList={loadAthletesList}
                allAthletesList={allAthletesList}
                setCoachDepthChartId={setCoachDepthChartId}
                data={items}
                handleDeleteDepthChart={handleDeleteDepthChart}
              />
            );
          })} </div></>)
              )} 
              {/* <Collapse items={coachItems} accordion /> */}
            </div>

        )}
    
        
      </div>
    </div>
  );
};

export default MyDepthChart;
//  coachDepthChart?.map((items,index) => {
//             return (
//               <MyDepthChartMain
//                 coachesList={coachesList}
//                 key={index} 
//                 coach={`${items.first_name} ${items.last_name}`}
//                 handleUpdateDepthChart={handleUpdateDepthChart}
//                 showPlayersModal={showModal}
//                 athletesListLoading={athletesListLoading}
//                 athletesList={athletesList}
//                 loadAthletesList={loadAthletesList}
//                 allAthletesList={allAthletesList}
//                 setCoachDepthChartId={setCoachDepthChartId}
//                 data={items}
//                 handleDeleteDepthChart={handleDeleteDepthChart}
//               />
//             );
//           })