import React from "react";
import trashicon from "../../../assets/img/trash.png";
import pencil from "../../../assets/img/pencil.svg";

function PlayerInfoModalContent({
  handleEditModal,
  kitNumber,
  classification,
  firstName,
  lastName,
  email,
  height,
  weight,
  benchMax,
  squatMax,
  speed,
}) {
  function convertHeightToFt(cm) {
    const feet = parseInt(cm) / 30.48;
    const parsedFeet = Math.floor(feet);
    const inches = Math.round((feet - parsedFeet) * 12);
    const formattedHeight = inches === 12 ? `${parsedFeet + 1}'0"` : `${parsedFeet}'${inches}"`;
    return formattedHeight;
    }
  return (
    <div className=''>
      <div className='flex justify-between'>
        <img className='w-[210px] h-56 rounded-[4px]' src='' alt='' />
        <div className='w-[210px] h-56 bg-[#A40E17] flex flex-col items-center justify-center rounded-tl-[15px] rounded-br-[15px]'>
          <div className='text-[white] flex flex-col items-center justify-center'>
            <span className='text-[61px] font-poppins font-bold'>
              {kitNumber}
            </span>
            <span className='font-bold font-inter text-[16px]'>
              {classification}
            </span>
          </div>
        </div>
      </div>
      <div className='mt-10'>
        <div className='flex flex-col w-full'>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              First Name:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {firstName}
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Last Name:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {lastName}
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Email:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {email}
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Height:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {convertHeightToFt(height)} <span className='font-normal text-[12px]'>ft</span>
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Weight:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {weight} <span className='font-normal text-[12px]'>lbs</span>
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Classification:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {classification}
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              BenchMax:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {benchMax} <span className='font-normal text-[12px]'>lbs</span>
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              Squat Max:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {squatMax} <span className='font-normal text-[12px]'>lbs</span>
            </span>
          </div>
          <div className='flex items-center justify-between w-full mb-[8px]'>
            <span className='text-[14px] text-[#2E4D6B] font-inter font-normal'>
              40 Speed:
            </span>
            <span className='font-inter font-bold text-[16px] text-[#0F1A24] self-end'>
              {speed} <span>sec</span>
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full justify-end'>
        <div
          className='w-full cursor-pointer border-[2px] border-solid border-[#2E4D6B] flex items-center justify-center h-[43px] mb-[8px] rounded-[4px]'
          onClick={handleEditModal}>
          <div className=' w-[12%] flex items-center justify-between font-bold text-[14px] font-inter text-[#2E4D6B]'>
            <img src={pencil} className='w-[35%]' />
            <span>Edit</span>
          </div>
        </div>
        <div className='w-full cursor-pointer border-[2px] border-solid border-[#A40E17] flex items-center justify-center h-[43px] rounded-[4px]'>
          <div className='w-[28%] flex items-center justify-between font-bold text-[14px] font-inter text-[#A40E17]'>
            <img src={trashicon} />
            <span>Delete Player</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerInfoModalContent;
