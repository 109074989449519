import { UserData } from "./user-data.jsx";
import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Switch,
  notification,
  Modal,
  Tooltip,
  Skeleton,
  Table,
  Popover,
  Card,
} from "antd";
import {
  UserOutlined,
  CloseOutlined,
  CheckOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import CardModal from "./card-modal.js";
import "./home-page.css";
import importImg from "../../../assets/img/evaluation-actions/import.svg";
import DepthChartGrades from "./DepthChartGrades";
import "./spin.css";
import smileyDefault from "../../../assets/img/smiley-default.png";
import smileyactive from "../../../assets/img/smiley-active.png";
import commentIcon from "../../../assets/img/Frame 1295.png";
import footBall from "../../../assets/img/football.png";
import { connect } from "react-redux";
import Collaboration from "./Collaboration.js";
import VerifyStats from "./VerifyStats.js";
import _, { filter, isEmpty } from "lodash";
import { geocodeByAddress } from "react-places-autocomplete";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router";
import bell from "../../../assets/img/bell-notification.svg";
import analytics from "../../../assets/img/analytics.png";
import curveArrowLeft from "../../../assets/img/curve-arrow-left.png";
import chevronLeft from "../../../assets/img/whiteChevronLeft.png";
import chevronRight from "../../../assets/img/whiteChevronRight.png";
import managePlays from "../../../assets/img/managePlay.png";
import pencil from "../../../assets/img/pencil.svg";
import arrowdown from "../../../assets/img/arrowdown.png";
import erase from "../../../assets/img/erase.svg";
import angel from "../../../assets/img/angel 2.svg";
import MessageModal from "./MessageModal.js";
import AngelPopUp from "../../../components/Modals/AngelPopUp.js";
import DeleteModals from "../evaluations/DeletesModal.js";
import NotificationModal from "../../../components/Modals/NotificationModal.js";
import dropDownIcon from "../../../assets/img/page-down.svg";
import dropDownIconTwo from "../../../assets/img/page-up.svg";
import threeDot from "../../../assets/img/Frame 18264.svg";
import settings from "../../../assets/img/settings.svg";
import arrowLeft from "../../../assets/img/arrow-left.svg";

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const HomePageComponent = (props) => {
  const depthChartRef = useRef(null);
  const [disabled, setdisabled] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const [cardData, setcardData] = useState({});
  const [value, setValue] = useState("");
  const [e, setE] = useState("");
  const [athletesList, setAthletesList] = useState([]);
  const [athletesListMeta, setAthletesListMeta] = useState({});
  const [athletesListLoading, setAthletesListLoading] = useState(true);
  const [address_results, setaddress_results] = useState([]);
  const [address_fetching, setaddress_fetching] = useState(false);
  const [page, setPage] = useState(1);
  const [playsList, setPlaysList] = useState([]);
  const [playsListStock, setPlaysListStock] = useState([]);
  const [playsListMeta, setPlaysListMeta] = useState({});
  const [playsListLoading, setPlaysListLoading] = useState(false);
  const [playPage, setPlayPage] = useState(1);
  const [gamesList, setGamesList] = useState([]);
  const [gamesListMeta, setGamesListMeta] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [gamePage, setGamesPage] = useState(1);
  const [cardId, setCardId] = useState("");
  const [siteGridironDetails, setSiteGridironDetails] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [prePopulateGrades, setprePopulateGrades] = useState({
    id: "",
    value: [],
  });

  const [filterPositions, setfilterPositions] = useState("");
  const [filterName, setfilterName] = useState("");
  const [gamesViewDetails, setGamesViewDetails] = useState({});
  const [formPlayNumber, setFormPlayNumber] = useState("");
  const [formPlayId, setFormPlayId] = useState(null);
  const [feedbackList, setFeedbackList] = useState([]);

  const [formPlay, setFormPlay] = useState({});
  const [formChangePlay, setFormChangePlay] = useState("");
  const [form] = Form.useForm();
  const [playNumberForm] = Form.useForm();
  const [viewData, setViewData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalAthleteId, setModalAthleteId] = useState("");
  const [modalAthletePosition, setModalAthletePosition] = useState([]);
  const [formPositionsType, setFormPositionsType] = useState("");
  const [positionsType, setPositionsType] = useState("");
  const [positionValue, setpositionValue] = useState("");
  const [playLists, setPlayLists] = useState([]);
  const [playListsMeta, setPlayListsMeta] = useState({});
  const [playIdNumber, setPlayIdNumber] = useState("");
  const [odk, setOdk] = useState("all");
  const [gradeListsMeta, setGradeListsMeta] = useState({});
  const [mailToAdminMailId, setMailToAdminMailId] = useState("");
  const [changePosValue, setChangePosValue] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [gradesData, setGradesData] = useState([]);
  const [gradesDataLoading, setGradesDataLoading] = useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);

  const [coachDepthChartLoading, setCoachDepthChartLoading] = useState(true);
  const [coachDepthChart, setCoachDepthChart] = useState([]);
  const [coachDepthChartStock, setCoachDepthChartStock] = useState([]);
  const [schoolDepthChart, setSchoolDepthChart] = useState([]);
  const [editorMode, setEditorMode] = useState(false);
  const [playInfoTable, setPlayInfoTable] = useState(false);
  const [modalTitle, setModalTitle] = useState(true);
  let history = useNavigate();

  const toggleEditorMode = () => {
    console.log("editorMode", editorMode);
    if (editorMode) {
      setCoachDepthChart(coachDepthChartStock);
    } else {
      console.log("schoolDepthChart", schoolDepthChart);
      setCoachDepthChart((prev) => {
        return { own: schoolDepthChart, shared: [] };
      });
    }
    setEditorMode(!editorMode);
  };
  // function to open and close clear evaluation modal
  const [clearEvaluation, setClearEvaluation] = useState(false);
  const clearPlayEvaluation = () => {
    setClearEvaluation(true);
  };

  const closeClearPlayEvaluation = () => {
    setClearEvaluation(false);
  };

  const handleChange = (value, e) => {
    setValue(value);
    setE(e);
  };

  function showConfirm() {
    confirm({
      title: "",
      content: (
        <div className=" text-[12px] xl:text-[14px] font-medium">
          For the best functionality and the most accurate analytics please be
          sure to import your play info before evaluating games
        </div>
      ),
      okButtonProps: {
        className: "bg-[#1A91FF] border-[2px] border-[#1A91FF] border-solid",
      },
      onOk() {
        // console.log("OK");
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  const handleMessageView = () => {
    setMessageModal(true);
  };

  const closeMessageModal = () => {
    setMessageModal(false);
  };

  const CardActive = () => {
    setdisabled(!disabled);
  };

  const showModalView = async (e) => {
    // set state to update the UI and show the PersonModal
    setshowModal(true);
    await setcardData(e);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setshowModal(false);
  };

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    // fetchGradesList()
    loadGameView();
    // loadGamesList()
    // gradesList()
    loadPlaysList();

    gridironDetails();

    loadSchoolDepthCharts();
    // fetchSchoolViewData();
    // showConfirm();
  }, []);

  const loadGameView = () => {
    const paramss = new URLSearchParams(window.location.search);
    let game_id = paramss.get("id");
    props.gamesView({
      params: { id: game_id },
      callback: async (res, data) => {
        if (res == true) {
          await setGamesViewDetails(_.get(data, "data.details", {}));
        }
      },
    });
  };

  const loadCoachDepthCharts = (limit = 1000, page = 1) => {
    const list = getFromStore(`CoachDepthCharts`);

    if (list) {
      setCoachDepthChart(list);
      setCoachDepthChartStock(list);
    } else {
      setCoachDepthChartLoading(true);
      props.getDepthCharts({
        params: { limit, page, coach_id: props.coachProfile.id },
        callback: (res, data) => {
          if (res == true && data) {
            setCoachDepthChart(data?.data);
            setCoachDepthChartStock(data?.data);
            sessionStorage.setItem(
              `CoachDepthCharts`,
              JSON.stringify(data?.data)
            );
          }
          setCoachDepthChartLoading(false);
        },
      });
    }
  };

  const loadSchoolDepthCharts = (limit = 1000, page = 1) => {
    props.getSchoolDepthCharts({
      params: { limit, page, school: props?.coachProfile?.school?.id },
      callback: (res, data) => {
        if (res == true && data) {
          setSchoolDepthChart(data?.data);
        }
      },
    });
  };

  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = [];
          setPositionsList(
            _.chain(data)
              .get("data.data.positions")
              .pick(["offense", "defense", "special_teams"])
              .values()
              .flatten()
              .map("code")
              .value()
          );
          if (_.size(_.get(data, "data.data.positions.offense", [])) > 0) {
            _.map(
              _.get(data, "data.data.positions.offense", []),
              (value, index) => {
                final_data.push({ ...value, type: "offense" });
              }
            );
          }
          if (_.size(_.get(data, "data.data.positions.defense", [])) > 0) {
            _.map(
              _.get(data, "data.data.positions.defense", []),
              (value, index) => {
                final_data.push({ ...value, type: "defense" });
              }
            );
          }
          if (
            _.size(_.get(data, "data.data.positions.special_teams", [])) > 0
          ) {
            _.map(
              _.get(data, "data.data.positions.special_teams", []),
              (value, index) => {
                final_data.push({ ...value, type: "special_teams" });
              }
            );
          }
          setSiteGridironDetails(final_data);
        }
      },
    });
  };

  const loadGamesList = (limit = 1, page = 1, season = "") => {
    let params = { limit, page };
    if (season) {
      params.season = season;
    }
    setGamesListLoading(true);
    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setGamesListMeta(_.get(data, "data.meta", {}));
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const gradesList = (athleteID, details, type = false, callback = "") => {
    const paramss = new URLSearchParams(window.location.search);
    let game_id = paramss.get("id");
    let params = {
      athlete: athleteID,
      game: game_id,
      play: formPlayId,
      status: "1,2",
    };
    setPlaysListLoading(true);
    props.gradesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          if (_.get(data, "data.items[0].id", {})) {
            setprePopulateGrades({
              id: new Date().toString(),
              value: _.get(data, "data.items", []),
            });
            type == false && showModalView(details);
            if (_.isFunction(callback)) {
              callback(_.get(data, "data.items", []));
            }
          } else {
            type == false && showModalView(details);
          }
        }
        setPlaysListLoading(false);
      },
    });
  };
  useEffect(() => {
    if (formPlayId) {
      fetchGradesList();
      fetchListFeedback();
    }
  }, [formPlayId]);
  useEffect(() => {
    loadAthletesList();
  }, []);
  const fetchGradesList = () => {
    const paramss = new URLSearchParams(window.location.search);
    let game_id = paramss.get("id");
    let params = {
      game: game_id,
      play: formPlayId,
      // expand: 'athlete',
    };

    setGradesDataLoading(true);
    props.gradesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items);
        }
        setGradesDataLoading(false);
      },
    });
  };
  const fetchListFeedback = () => {
    const paramss = new URLSearchParams(window.location.search);
    let game_id = paramss.get("id");
    let params = {
      game_id: game_id,
      play_id: formPlayId,
    };
    props.listFeedback({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setFeedbackList(data?.data);
        }
      },
    });
  };
  const gradesView = (id, postions) => {
    props.gradesView({
      params: { id: id },
      callback: async (res, data) => {
        if (res === true) {
          if (_.get(data, "data.details.id")) {
            // changingPostions(postions)
            setViewData(_.get(data, "data.details", {}));
            // setFormFieldsDefault(_.get(data, 'data.details', {}))
          }
        }
      },
    });
  };

  const getFromStore = (key) => {
    const val = sessionStorage.getItem(key) || null;

    // is empty
    if (!val) return null;

    if (typeof val === "undefined") return null;

    // empty []
    if ([].length < 1) return null;

    return JSON.parse(val);
  };

  const loadPlaysList = (limit = 300, page = 1, playNumber = "") => {
    const paramss = new URLSearchParams(window.location.search);
    setPlayPage(page);
    let game_id = paramss.get("id");
    let params = {
      limit,
      page,
      game: game_id,
      // expand: "attacking_team,defending_team,game",
      sort: "number.asc",
    };
    if (playNumber) {
      params.number = playNumber;
    }

    const list = getFromStore(`${game_id}-gameplays`);

    if (list) {
      setPlaysListStock(list);
      setPlaysList(list);
      setFormPlayNumber(_.parseInt(list[0].number));
      setFormPlayId(list[0].id);
      setFormPlay(list[0]);
      loadCoachDepthCharts();

      setPlaysListLoading(false);
    } else {
      setPlaysListLoading(true);
    }
    props.playsList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setPlaysListMeta(_.get(data, "data.meta", {}));
          const _plays = _.get(data, "data.items", []);
          setPlaysListStock(_plays);
          sessionStorage.setItem(
            `${game_id}-gameplays`,
            JSON.stringify(_plays)
          );
          setPlaysList(_plays);
          setFormPlayNumber(
            _.parseInt(_.get(data, "data.items[0].number", ""))
          );
          setFormPlayId(_.get(data, "data.items[0].id", ""));
          setFormPlay(_.get(data, "data.items[0]", ""));
          if (_plays.length < 50) {
            showConfirm();
          }
          if (!_plays.length) {
            handleChangePlay(1);
            history(
              `/manage-playlist?id=${paramsss.get(
                "id"
              )}&opponent=${paramsss.get("opponent")}&team=${paramsss.get(
                "team"
              )}&week=${paramsss.get("week")}&t=${paramsss.get("t")}`
            );
          }
          if (!list) {
            loadCoachDepthCharts();
          }
        }
        setPlaysListLoading(false);
      },
    });
  };

  // useEffect(() => {

  //   if (_.get(props, "schoolDetails.details.id")) {
  //   }
  // }, [_.get(props, "profileDetails")]);

  const loadAthletesList = (limit = 1000, page = 1) => {
    setAthletesListLoading(true);
    if (_.get(props, "profileDetails.school.id")) {
      let params = {
        limit,
        page,
        expand: "athlete",
        athlete_profile_school: _.get(props, "profileDetails.school.id"),
      };

      props.list({
        params: params,
        callback: (res, data) => {
          if (res == true && _.size(_.get(data, "data.items", [])) > 0) {
            setAthletesList(_.get(data, "data.items", []));
            setAthletesListMeta(_.get(data, "data.meta", {}));
          }
          setAthletesListLoading(false);
        },
      });
    }
  };

  const onchangeCheckBox = (is_graded) => {
    if (is_graded == cardId) {
      setCardId("");
    } else {
      setCardId(is_graded);
    }
  };

  const onSearchGrades = async (value) => {
    await setcardData({});
    gradesList(_.get(value, "athlete.id"), value);
  };

  const athletePositions = async (value) => {
    if (_.size(_.get(value, "positions", [])) == 1) {
      setModalAthleteId("");
      onFinish(
        _.head(_.get(value, "positions", [])),
        "direct",
        _.get(value, "athlete.id")
      );
    } else {
      setModalAthleteId(_.get(value, "athlete.id"));
      await setModalAthletePosition(_.get(value, "positions"));
      setpositionValue(_.head(_.get(value, "positions")));
      form.setFieldsValue({ positions: _.head(_.get(value, "positions")) });
      gradesList(_.get(value, "athlete.id"), value, true);
      if (_.get(value, "is_graded") == false) {
        await setIsModalVisible(true);
        form.setFieldsValue({ positions: _.head(_.get(value, "positions")) });
        setpositionValue(_.head(_.get(value, "positions")));
      }
    }
  };

  const onFinish = (e, type, id) => {
    const params = new URLSearchParams(window.location.search);
    let game_id = params.get("id");
    let details = {
      position: type == "direct" ? e : formPositionsType,
      assignment_executed: 1,
      great_effort: 1,
      athlete: type == "direct" ? id : modalAthleteId,
      game: parseInt(game_id),
      play: formPlayId,
    };
    if (_.get(viewData, "id")) {
      details.id = _.get(viewData, "id");
    }
    props[_.get(viewData, "id") ? "gradesUpdate" : "gradesCreate"]({
      body: details,
      callback: (res, responseData) => {
        if (res == true) {
          notification.success({
            message: "Success",
            description: _.get(responseData, "message"),
          });
          setIsModalVisible(false);
          loadAthletesList(18, page, filterName, filterPositions, formPlayId);
          // loadAthletesList();
          // history('/coaches-evaluation')
        }
      },
    });
  };

  const fetchSchoolViewData = () => {
    const params = new URLSearchParams(window.location.search);
    let school_id = _.get(props, "profileDetails.school.id");

    props.getSchoolViewData({
      params: { id: school_id, expand: "school_admin" },
      callback: (res, responseData) => {
        setMailToAdminMailId(
          _.get(responseData, "data.details.school_admin.email")
        );
      },
    });
  };

  const deteleGrades = (value) => {
    gradesList(_.get(value, "athlete.id"), value, true, (res) => {
      if (_.isArray(res) && _.size(res) > 0) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: "Do you want to remove the grading?",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            deleteItem(res);
          },
          onCancel() {},
        });
      }
    });
  };
  const deleteItem = (res) => {
    let ids = _.map(res, "id");
    setPlaysListLoading(true);
    _.map(ids, (values, index) => {
      props.changeStatus({
        body: {
          status: 3,
          id: values,
          status_glossary: "Deleted",
        },
        callback: (res, data) => {
          if (res == true && index + 1 == _.size(ids)) {
            setPlaysListLoading(false);
            loadAthletesList(18, page, filterName, filterPositions, formPlayId);
          }
        },
      });
    });
  };

  const handleChangePlay = (playNumber) => {
    const play = playsList.find(({ number }) => number == playNumber);

    if (play) {
      setFormPlayNumber(playNumber);
      setFormPlayId(play.id);
      setFormPlay(play);
    } else {
      if (gamesViewDetails.home_team) {
        handlePlayCreate(playNumber, gamesViewDetails);
      }
    }
  };
  const handleSortPlay = (type) => {
    if (type == "o" || type == "s" || type == "k" || type == "d") {
      setPlaysListLoading(true);

      const plays = playsListStock.filter(({ odk }) => {
        return odk?.toLowerCase() === type.toLowerCase();
      });

      setPlaysList(plays);
      setFormPlayNumber(plays[0]?.number);
      setFormPlayId(plays[0]?.id);
      setFormPlay(plays[0]);

      setPlaysListLoading(false);
    } else {
      if (!formPlayNumber || !formPlayId || !formPlay) {
        setFormPlayNumber(playsListStock[0]?.number);
        setFormPlayId(playsListStock[0]?.id);
        setFormPlay(playsListStock[0]);
      }

      setPlaysList(playsListStock);
      setPlaysListLoading(false);
    }
    setOdk(type);
  };
  const getCurrentPlayIndex = () => {
    const result = playsList.findIndex(({ id }) => id == formPlayId);

    return result;
  };

  const handleChangePlayByIndex = (playIndex) => {
    if (playIndex >= 0) {
      const play = playsList[playIndex];

      if (play) {
        setFormPlayNumber(play.number);
        setFormPlayId(play.id);
        setFormPlay(play);
      }
    }
  };

  const handlePlayCreate = (playNumber, firstDetails, type) => {
    if (playNumber > 0) {
      setPlaysListLoading(true);
      setFormPlayNumber(_.parseInt(playNumber));
      const params = new URLSearchParams(window.location.search);
      let game_id = params.get("id");
      let values = { number: playNumber };
      if (type == "first") {
        values.attacking_team = _.get(firstDetails, "away_team");
        values.defending_team = _.get(firstDetails, "home_team");
      } else {
        values.attacking_team = _.get(gamesViewDetails, "away_team");
        values.defending_team = _.get(gamesViewDetails, "home_team");
      }

      values.game = game_id;
      props.playsCreate({
        body: values,
        callback: (res, responseData) => {
          if (res == true && _.get(responseData, "details.number")) {
            setPlayIdNumber(_.get(responseData, "details.id"));
            setPage(1);
            loadAthletesList(
              18,
              1,
              filterName,
              filterPositions,
              _.get(responseData, "details.id")
            );
            loadPlaysList(200, 1, _.get(responseData, "details.number"));

            setFormPlayId(_.get(responseData, "details.id"));
          }
        },
      });
    }
  };

  const updateInMemoryPlayDetail = (id, info) => {
    let play = { ...formPlay, ...info };

    const plays = playsListStock.map((iplay) => {
      if (iplay.id == play.id) {
        iplay.gn = play.gn;
      }
      return iplay;
    });

    setPlaysList(plays);
    setPlaysListStock(plays);

    setFormPlay(play);
  };

  const handleUpdatePlay = (id, info) => {
    updateInMemoryPlayDetail(id, info);

    props.updatePlay({
      params: {
        id,
      },
      body: {
        id,
        ...info,
      },
      callback: (res, data) => {
        // console.log("res", res);
      },
    });
  };

  const changingPostions = async (postions) => {
    await setFormPositionsType(postions);
    // let siteGridironDetails=_.get(props,'siteGridironDetails')
    _.map(siteGridironDetails, (value, index) => {
      if (_.get(value, "code") == postions) {
        setPositionsType(_.get(value, "type"));
      }
    });
    let prePopulate = prePopulateGrades;
    if (_.size(_.get(prePopulate, "value", [])) > 0) {
      _.map(_.get(prePopulate, "value", []), (value, index) => {
        if (_.get(value, "position") == postions) {
          gradesView(_.get(value, "id"));
        } else {
          setViewData({});
        }
      });
    }
  };

  const playInfoColumns = [
    {
      title: "Play #",
      dataIndex: "number",
      align: "center",
      width: "9%",
      key: "play",
    },
    {
      title: "ODK",
      dataIndex: "odk",
      align: "center",
      width: "5%",
      key: "odk",
      render: (text, row) => {
        if (text) return text;

        return (
          <Select
            bordered={false}
            defaultValue={text?.toLowerCase()}
            onChange={(value) => handleUpdatePlay(row.id, { odk: value })}
            className="w-[100%] mange-listtabledataselect"
          >
            <Option value="o">O</Option>
            <Option value="d">D</Option>
            <Option value="k">K</Option>
          </Select>
        );
      },
    },

    {
      title: "PLAY TYPE",
      dataIndex: "play_type",
      width: "10%",
      align: "center",
      key: "play_type",
      render: (item, row) => {
        if (item) return item;

        return (
          <Select
            bordered={false}
            defaultValue={item}
            onChange={(value) => handleUpdatePlay(row.id, { play_type: value })}
            className="w-full h-full  border-none outline-none manage-listtabledataselect"
            style={{ border: "none" }}
          >
            <Option value="pass">Pass</Option>
            <Option value="run">Run</Option>
          </Select>
        );
      },
    },

    {
      title: "RESULT",
      dataIndex: "result",
      align: "center",
      width: "10%",
      key: "result",
      render: (item, row) => {
        if (item) return item;

        return (
          <Select
            dropdownMatchSelectWidth={true}
            style={{ width: "100%" }}
            bordered={false}
            onChange={(value) => handleUpdatePlay(row.id, { result: value })}
            defaultValue={item}
            className="w-full h-full  border-none outline-none text-[10px] manage-listtabledataselect"
          >
            <Option value="incomplete">incomplete</Option>
            <Option value="rush">rush</Option>
            <Option value="complete">complete</Option>
          </Select>
        );
      },
    },
    {
      title: "DN",
      dataIndex: "dn",
      width: "6%",
      align: "center",
      key: "dn",
      render: (item, row) => item || "--",
    },
    {
      title: "DIS",
      dataIndex: "dist",
      width: "6%",
      align: "center",
      key: "dist",
      render: (item, row) => item || "--",
    },
    {
      title: "Gn/Ls",
      dataIndex: "gn",
      width: "6%",
      align: "center",
      key: "gn",
      render: (item, row) => item || "--",
    },

    {
      title: "DEF FRONT",
      dataIndex: "def_front",
      align: "center",
      width: "10%",
      key: "def_front",
      render: (text, item) => <span>{text ? text : "---"}</span>,
    },
    {
      title: "STUNT",
      dataIndex: "stunt",
      width: "8%",
      align: "center",
      key: "stunt",
      render: (item, row) => item || "--",
    },
    {
      title: "BLITZ",
      dataIndex: "blitz",
      width: "8%",
      align: "center",
      key: "blitz",
      render: (item, row) => item || "--",
    },
    {
      title: "COVERAGE",
      dataIndex: "coverage",
      width: "8%",
      align: "center",
      key: "coverage",
      render: (item, row) => item || "--",
    },

    {
      title: "OFF FORMATION",
      dataIndex: "off_off_format",
      align: "center",
      width: "13%",
      key: "off_off_format",
      render: (item, row) => item || "--",
    },
    {
      title: "OFF PLAY",
      dataIndex: "off_off_play",
      width: "12%",
      align: "center",
      key: "off_off_play",
      render: (item, row) => item || "--",
    },
    {
      title: "QTR",
      dataIndex: "qtr",
      width: "9%",
      align: "center",
      key: "qtr",
      render: (item, row) => item || "--",
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   width: "4%",
    //   align: "center",
    //   key: "id",

    //   render: (text, item) => (
    //     <div className='flex items-center justify-center'>
    //       <img src={pencil} className='w-[15px]' />
    //     </div>
    //   ),
    // },
  ];

  const reloadPlay = () => {
    handleChangePlayByIndex(getCurrentPlayIndex() + 1);
    handleChangePlayByIndex(getCurrentPlayIndex() - 1);
  };
  const DarkBackground = styled.div`
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    ${(props) =>
      props.disappear &&
      css`
        display: block; /* show */
      `}
  `;
  const paramsss = new URLSearchParams(window.location.search);
  let game_id = paramsss.get("id");
  let feedback_type = paramsss.get("t");
  const render = () => {
    return (
      <div
        className={"w-[99%] p-[1%] font-inter"}
        style={{
          border: editorMode ? "10px solid red" : "",
        }}
      >
        <div className="w-full">
          <div className="w-full flex items-center mb-[1%] justify-between">
            <div className="w-[98%] flex items-center">
              <div className="w-[65%] flex items-center justify-between">
                <div className="w-[68%] bg-[black] px-[4px] h-[45px] rounded-[4px] text-[12px] xl:text-[14px] font-poppins flex items-center justify-around">
                  <span className="text-[#FFFFFF] font-bold ">Play #</span>
                  <div
                    onClick={() =>
                      handleChangePlayByIndex(getCurrentPlayIndex() - 1)
                    }
                    className="h-[24px] cursor-pointer  w-[24px] rounded-[4px] flex items-center justify-center chevron"
                  >
                    <img src={chevronLeft} className="" />
                  </div>
                  <div className="bg-[#FFF]  h-[30px] w-[30px] rounded-[4px] cursor-pointer flex items-center justify-center">
                    <span className="text-[#0F1A24]">{formPlayNumber}</span>
                  </div>
                  <div
                    onClick={() =>
                      handleChangePlayByIndex(getCurrentPlayIndex() + 1)
                    }
                    className=" h-[24px] w-[24px] rounded-[4px] cursor-pointer  flex items-center justify-center chevron"
                  >
                    <img src={chevronRight} className="w-[]" />
                  </div>
                  <span className="text-[#FFFFFF] text-[14px] mr-1">
                    of {playsList.length} plays
                  </span>
                  <span className="text-[#FFFFFF] text-[14px]">
                    Go to play #
                  </span>
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      setFormChangePlay(value)
                    }
                    className="w-[43px] h-[35px] bg-[#FFFFFF] border-[1px] border-solid border-[#1790FF] outline-none rounded-[4px] p-[2px]"
                  />
                  <button
                    onClick={() => handleChangePlay(formChangePlay)}
                    className="rounded-[4px] border-solid border-[1px] border-[#E5E5E5] text-[white] px-[8px] h-[35px]"
                  >
                    Go
                  </button>
                </div>
                <div className="w-[30%] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around">
                  <button
                    onClick={() => handleSortPlay("all")}
                    className={
                      (odk == "all" ? " bg-[#A40E17]" : " ") +
                      " w-[43px] h-[31px] rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    All
                  </button>
                  <button
                    onClick={() => handleSortPlay("o")}
                    className={
                      (odk == "o" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    O
                  </button>
                  <button
                    onClick={() => handleSortPlay("d")}
                    className={
                      (odk == "d" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    D
                  </button>
                  <button
                    onClick={() => handleSortPlay("k")}
                    className={
                      (odk == "k" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    K
                  </button>
                </div>
              </div>
              <div className="flex  w-[40%] items-center text-[#0F1A24] text-[12px] xl:text-[14px]">
                <span className="font-poppins font-bold mx-[1%]">
                  {paramsss.get("week")}{" "}
                </span>
                <span className=" font-normal">
                  {" "}
                  {capitalizeString(paramsss.get("team"))}{" "}
                  <span className="text-[#A40E17]"> vs</span>{" "}
                  {capitalizeString(paramsss.get("opponent"))}
                </span>
              </div>

              {editorMode ? (
                <div className="flex  float-right w-[20%] items-center text-[#0F1A24] text-[12px] xl:text-[14px]">
                  <p className="mb-0">Turn Off Editor Mode</p>
                  <div className="ml-[8px] bg-[#dee2e6] rounded-[8px] h-[25px]">
                    <Switch onChange={toggleEditorMode} defaultChecked />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <Popover
              trigger="click"
              showArrow={false}
              placement="bottomLeft"
              content={
                <div className="w-[226px]">
                  <div
                    className="items-center flex text-[#2E4D6B] text-[12px] xl:text-[14px]  font-normal w-[90%] h-[90%] cursor-pointer"
                    onClick={() => history("/game-analytic-dashboard")}
                  >
                    <img src={analytics} />
                    <span className="ml-[3%]">View Game Analytics</span>
                  </div>
                  <div
                    className="items-center flex text-[#2E4D6B] text-[12px] xl:text-[14px]  font-normal w-[90%] h-[90%] cursor-pointer mt-[3%]"
                    onClick={() => {
                      history(
                        `/manage-playlist?id=${paramsss.get(
                          "id"
                        )}&opponent=${paramsss.get(
                          "opponent"
                        )}&team=${paramsss.get("team")}&week=${paramsss.get(
                          "week"
                        )}&t=${paramsss.get("t")}`
                      );
                    }}
                  >
                    <img src={settings} />
                    <span className="ml-[3%]">Manage Plays</span>
                  </div>
                  <div
                    className="items-center flex text-[#2E4D6B] text-[12px] xl:text-[14px]  font-normal w-[90%] h-[90%] cursor-pointer mt-[3%]"
                    onClick={() => history("/coaches-evaluation")}
                  >
                    <img src={arrowLeft} />
                    <span className="ml-[3%]">Back to Evaluations Page</span>
                  </div>
                  {/* <div
                    className="items-center flex text-[#2E4D6B] text-[12px] xl:text-[14px]  font-normal w-[90%] h-[90%] cursor-pointer mt-[3%]"
                    onClick={toggleEditorMode}
                  >
                    <img src={settings} />
                    <span className="ml-[3%]">{editorMode ? 'Turn off' : 'Turn on'} Editor Mode</span>
                  </div> */}
                </div>
              }
            >
              <img src={threeDot} className="cursor-pointer" />
            </Popover>
          </div>
          <div className="w-full border-[1px] border-solid border-[#F3F5F7] mt-[8px] rounded-[10px] px-1 py-2 bg-[#FFFFFF] mb-[1%]">
            <div className="w-full bg-[#FFFFFF] h-[26px] gap-4 font-bold text-[12px] xl:text-[14px] flex items-center px-[2%] rounded-lg">
              <div className="flex w-[140px]">
                {" "}
                <span>Play info</span>
                {!playInfoTable ? (
                  <img
                    src={dropDownIcon}
                    className="ml-[1%]  cursor-pointer"
                    onClick={() => setPlayInfoTable(!playInfoTable)}
                  />
                ) : (
                  <img
                    src={dropDownIconTwo}
                    className="ml-[1%] cursor-pointer"
                    onClick={() => setPlayInfoTable(!playInfoTable)}
                  />
                )}
              </div>

              {formPlay?.id && (
                <Collaboration
                  gradesDataLoading={gradesDataLoading}
                  play={formPlay}
                  game={game_id}
                />
              )}

              <VerifyStats
                playInfoColumns={playInfoColumns}
                athletesList={athletesList}
                play={formPlay}
                grades={gradesData}
                toggleEditorMode={toggleEditorMode}
                editorMode={editorMode}
              />
            </div>

            <div
              className={
                playInfoTable ? "playinfotable block mt-2" : "hidden  mt-2"
              }
            >
              <Table
                pagination={false}
                columns={playInfoColumns}
                dataSource={[formPlay]}
                bordered
              />
            </div>
          </div>
          <Card className="w-full" bodyStyle={{ padding: "12px" }}>
            <div className="w-full">
              <div className="bg-[wine] flex flex-col">
                {/* Play Number and manage play div */}

                {/* <div className='bg-[#F3F5F7] h-[111px] w-full rounded-[4px] mt-[16px] flex items-center justify-center'>
                    <div className='w-[98%] h-[60%] flex flex-col  justify-between'>
                      <div className='flex items-center justify-between  text-[12px] xl:text-[14px] font-bold w-full'>
                        <span>Group Feedback:</span>
                        <img src={pencil} className='w-[15px]' />
                      </div>
                      <div className=' text-[12px] xl:text-[14px] font-normal'>
                        <span>
                          Thank you all for your unwavering dedication and
                          passion for the game. Great Job!
                        </span>
                      </div>
                    </div>
                  </div> */}
                <div className="full-width">
                  {coachDepthChartLoading ? (
                    <div className="col-md-12 px-3 py-2">
                      {" "}
                      <br />
                      <Skeleton active />
                      <br />
                      <Skeleton active />
                      <br />
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (
                    <>
                      {formPlayNumber ? (
                        coachDepthChart?.own?.map((items) => {
                          if (formPlay?.odk) {
                            if (
                              items?.odk?.toLowerCase() !==
                              formPlay?.odk?.toLowerCase()
                            )
                              return <></>;
                          }
                          return (
                            <>
                              <DepthChartGrades
                                ref={depthChartRef}
                                school_id={_.get(
                                  props,
                                  "profileDetails.school.id"
                                )}
                                formPlayNumber={formPlayNumber}
                                game_id={paramsss.get("id")}
                                play_id={formPlayId}
                                feedback={
                                  feedbackList?.find(
                                    ({ coach_depth_chart_id }) =>
                                      coach_depth_chart_id == items.id
                                  )?.feedback
                                }
                                gradesDataLoading={gradesDataLoading}
                                data={items}
                                reloadPlay={reloadPlay}
                                formPlay={formPlay}
                                playsList={playsList}
                                grades={gradesData}
                                handleUpdatePlay={handleUpdatePlay}
                              />
                            </>
                          );
                        })
                      ) : (
                        <div className="flex   font-bold text-[12px] xl:text-[14px] text-[#0F1A24] px-[2%]">
                          No play for this depth chart
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="h-[48px] w-full flex items-center justify-center mt-[8px]">
                  <div className=" w-full flex items-center justify-between">
                    <span className="font-poppins font-bold text-[23px]">
                      Shared Depth Chart(s)
                    </span>
                  </div>
                </div>
                <div className="full-width">
                  {coachDepthChartLoading ? (
                    <div className="col-md-12 px-3 py-2">
                      <br />
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (
                    <>
                      {formPlayNumber ? (
                        coachDepthChart?.shared?.map((items) => {
                          if (formPlay?.odk) {
                            if (
                              items?.odk?.toLowerCase() !==
                              formPlay?.odk?.toLowerCase()
                            )
                              return <></>;
                          }
                          return (
                            <DepthChartGrades
                              handleUpdatePlay={handleUpdatePlay}
                              ref={depthChartRef}
                              school_id={_.get(
                                props,
                                "profileDetails.school.id"
                              )}
                              formPlayNumber={formPlayNumber}
                              game_id={paramsss.get("id")}
                              gradesDataLoading={gradesDataLoading}
                              play_id={formPlayId}
                              feedback={
                                feedbackList?.find(
                                  ({ coach_depth_chart_id }) =>
                                    coach_depth_chart_id == items.id
                                )?.feedback
                              }
                              data={items}
                              reloadPlay={reloadPlay}
                              formPlay={formPlay}
                              playsList={playsList}
                              grades={gradesData}
                              key={items.id}
                              shared
                            />
                          );
                        })
                      ) : (
                        <div className="flex   mb-2 font-bold text-[12px] xl:text-[14px] text-[#0F1A24] px-[2%]">
                          No play available for this depth chart
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* play info div */}
                <div className="w-full border-[1px] border-solid border-[#F3F5F7] mt-[0.5%] rounded-[10px]">
                  <div className="w-full bg-[#EAEDF0] h-[39px]  font-bold text-[12px] xl:text-[14px] flex items-center px-[2%]">
                    <span>Play info</span>
                  </div>
                  <div className="playinfotable">
                    <Table
                      pagination={false}
                      columns={playInfoColumns}
                      dataSource={[formPlay]}
                      bordered
                    />
                  </div>
                </div>
                {/* clear play evaluation div */}
                {/* <div className='mt-[16px] flex flex-col'>
                  <div className='bg-[#E5E5E5] cursor-pointer items-center h-[45px] flex justify-center w-[200px] border-solid border-[2px] border-[#2E4D6B] rounded-[1px] self-end'>
                    <div
                      className='items-center justify-between flex text-[#2E4D6B] text-[12px] xl:text-[14px]  font-bold w-[90%] h-[90%]'
                      onClick={clearPlayEvaluation}>
                      <img src={erase} />
                      <span className=''>Clear Play Evaluation</span>
                    </div>
                  </div>
                </div> */}
                {clearEvaluation && (
                  <DeleteModals
                    onOpen={clearEvaluation}
                    onDelete={() => console.log("Delete")}
                    onCancel={closeClearPlayEvaluation}
                    title="Clear Play Evaluation"
                    text="You are about to clear this play evaluation. This action cannot be undone."
                  />
                )}
              </div>
            </div>
          </Card>

          {/* {athletesListLoading == false &&
              _.get(athletesListMeta, "total") > 18 && (
                <div className='PaginationContainer'>
                  <span className='PaginationContainerInner'>
                    <span className='PaginationLabel'>
                      Total{" "}
                      {_.get(athletesListMeta, "total") > 1
                        ? `${_.get(athletesListMeta, "total")} items`
                        : `${_.get(athletesListMeta, "total")} item`}
                    </span>
                    <Pagination
                      pageSize={18}
                      defaultCurrent={1}
                      hideOnSinglePage={true}
                      onChange={(page, size) => onChangePage(page, 18)}
                      total={_.get(athletesListMeta, "total")}
                      // total={500}
                      current={page}
                    />
                  </span>
                  <span className='PaginationContainerInner'>
                    <span className='PaginationLabel ml-3'>Go to</span>
                    <InputNumber
                      min={1}
                      max={
                        _.get(athletesListMeta, "total") > 18
                          ? _.get(athletesListMeta, "total") / 18
                          : 2
                      }
                      onPressEnter={(e) =>
                        onChangePage(_.get(e, "target.value"), 18)
                      }
                      style={{ width: "70px" }}
                    />
                  </span>
                </div>
              )} */}
        </div>

        {/* {_.get(cardData, 'id') && (
            <CardModal
              photoPath={`${_.get(athletesListMeta, 'photo.path')}/${_.get(
                athletesListMeta,
                'photo.folders.athlete_profile'
              )}/512/`}
              reset={showModal}
              prePopulate={prePopulateGrades}
              onSuccess={e => onSuccessCreate(e)}
              siteGridironDetails={siteGridironDetails}
              isvisible={showModal}
              data={cardData}
              hideModal={hideModal}
            />
 
          )} */}
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {
    schoolDetails: _.get(state, "app.schools.view.data"),
    profileDetails: _.get(state, "app.account.profile.data.details"),
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  playsList: (details) => dispatch(global.redux.action.plays.list(details)),
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesView: (details) => dispatch(global.redux.action.games.view(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesCreate: (details) =>
    dispatch(global.redux.action.grades.create(details)),
  gradesRemove: (details) =>
    dispatch(global.redux.action.grades.remove(details)),
  gradesView: (details) => dispatch(global.redux.action.grades.view(details)),
  gradesUpdate: (details) =>
    dispatch(global.redux.action.grades.update(details)),
  gridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  playsCreate: (details) => dispatch(global.redux.action.plays.create(details)),
  ListPlays: (details) => dispatch(global.redux.action.plays.list(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.grades.changeStatus(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),

  getDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.list(details)),
  getSchoolDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.school(details)),
  listFeedback: (details) =>
    dispatch(global.redux.action.depth_chart.listFeedback(details)),
  updatePlay: (details) => dispatch(global.redux.action.plays.update(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
