import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Button, Input, message, Spin } from "antd";
import registerImage from "../../assets/img/register-image.svg";
import "./register.css";
import "../login/login.css";
import logo from "../../assets/img/logo.png";
import { connect } from "react-redux";
import _ from "lodash";

function ContinueRegistration(props) {
  const location = useLocation();
  const [email, setEmail] = useState(() => {
    const storedEmail = sessionStorage.getItem("registered-email");
    return storedEmail || location?.state?.email || "";
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResendEmail = () => {
    setLoading(true);
    props.requestResetPassword({
      body: {
        email,
      },
      callback: (response, data) => {
        if (response) {
          console.log("response from res", data);
          message.success(
            "  Verification Email resent successfully kindly check your email"
          );
        } else {
          console.log("error", data);
        }
        setLoading(false);
      },
    });
  };

  const handleUpdateEmail = () => {
    setLoading(true);
    props.resetEmailVerification({
      body: {
        newEmail,
        oldEmail: email,
      },
      callback: (response, data) => {
        if (response) {
          console.log("response from res", data);
          message.success(
            `Email sent successfully to the new email (${newEmail}) kindly click the link in your email to verify your account`
          );
          sessionStorage.setItem("registered-email", newEmail);
          setEmail(newEmail);
          // setNewEmail("");
        } else {
          console.log("error", data);
        }
        setLoading(false);
      },
    });
    // // TODO: Add API call to update email
    // console.log("Update email API call");
    // setEmail(newEmail);
    setModalVisible(false);
  };

  // console.log("email", email);

  return (
    <div className="w-full h-screen grid grid-cols-5">
      <div className=" col-span-3 p-4 flex flex-col items-start  xl:h-full">
        <img src={logo} alt="logo" />
        <Spin spinning={loading} size="large">
          <div className="mx-auto font-bold font-poppins text-lg w-[80%] mt-16 xl:mt-[5.5vw]">
            <p className=" mb-4 ">
              We sent an email to {email}. Please click the link in your email
              to verify your account and get started.
            </p>
            <p>Have you seen the email?</p>
            <Button
              type="primary"
              className="mb-2 mr-2"
              onClick={handleResendEmail}
            >
              Resend Email
            </Button>
            <Button
              type="primary"
              className="mb-2 ml-2"
              onClick={() => setModalVisible(true)}
            >
              Update Email
            </Button>
          </div>
        </Spin>
      </div>
      <div className="col-span-2">
        <div className="register-container h-full">
          <img
            src={registerImage}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt=""
            className=" "
          />
        </div>
      </div>
      <Modal
        title="Update Email"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            onClick={() => handleUpdateEmail()}
          >
            Update Email
          </Button>,
        ]}
      >
        <p>Enter a correct email to resend the verification email:</p>
        <Input
          type="text"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetEmailVerification: (details) =>
    dispatch(global.redux.action.account.resetEmailVerification(details)),

  requestResetPassword: (details) =>
    dispatch(global.redux.action.account.requestResetPassword(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContinueRegistration);
