import React, { useState } from "react";
import { Input, Form, message } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import continueArrow from "../../assets/img/arrow-right.svg";
import { LoadingOutlined } from "@ant-design/icons";

function Basicinfo(props) {
  const [loading, setLoading] = useState(false);
  const onRegister = (values) => {
    message.info('We are creating your account, please wait!');
    setLoading(true);
    props.setEmail(values?.email);
    console.log("quinnt", values);
    setLoading(true);
    props.register({
      body: { ...values },
      callback: (response, data) => {
        if (response) {
          console.log("response, data?.data?", response, data?.data);
          console.log(values);
          message.success(data?.data?.message);
          props.setSchool(data?.data?.school);
          props.setUser({...values, ...data?.data?.user});
          props.setPage(props.page + 1);
          sessionStorage.setItem("school", JSON.stringify(data?.data?.school));
          sessionStorage.setItem("user", JSON.stringify({...values, ...data?.data?.user}));
        } else {
          // alert(data?.error)
          console.log("response, data", response, data);
          message.error(data?.error);
          // setInvalidPswd(true);
        }
        setLoading(false);
      },
    });
    // props.setPage(props.page + 1);
  };
  return (
    <div className="w-[90%] mx-auto">
      <span className="text-[18px] font-bold text-center font-poppins text-[#0F1A24]">
        Sign up to our Premium Plan Now. Get charged only after 30 days of free
        trial.
      </span>
      <div className="mt-8">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(e) => onRegister(e)}
        >
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                First Name
              </span>
            }
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" size="large" />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Last Name
              </span>
            }
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" size="large" />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                School Name
              </span>
            }
            name="school"
            rules={[
              {
                required: true,
                message: "Please input your School Name!",
              },
            ]}
          >
            <Input
              //   autoComplete="new-password"
              placeholder="SchoolName"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Email
              </span>
            }
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              //   autoComplete="new-password"
              placeholder="eg: name@example.com"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Password
              </span>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
                            {
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
          >
            <Input.Password
              //   autoComplete="new-password"
              placeholder="password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <button
              className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
              //   onClick={() => {
              //     if (page == 4) {
              //     } else {
              //       setPage(page + 1);
              //     }
              //   }}
              type="submit"
              loading={loading}
              // disabled={page == 1 && regDetails.roles.length == 0}
            >
              {loading && <LoadingOutlined className="animate-spin mr-1" />}
              <span>Continue</span>
              <img src={continueArrow} />
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(global.redux.action.account.login(details)),
  register: (details) =>
    dispatch(global.redux.action.account.register(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Basicinfo);
