import React from "react";
import StepsBar from "../../components/charts/stepsBar";
  const formatNum = (val) => {
    if (isNaN(val)) return 0;
    return Math.round(val);
  };

function RunPassGameComponent({executionPercent, effortPercent, gameScorePercent, color, runGameScore, title}) {
  return (
    <div className="flex flex-col w-[49%]">
      <div
        className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] mr-[24px]"
        style={{ border: "1.5px solid #EaEDF0" }}
      > 
        <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
          <span>{title} Performance</span>
        </div>
        <div className=" w-full justify-between p-3 bg-[#FFFFFF] font-poppins flex flex-wrap items-center font-bold text-[12px]">
        <StepsBar percent={executionPercent || 0} color={color} title='Execution'/>
        <StepsBar percent={effortPercent || 0} color={color} title='Effort'/>
        <StepsBar percent={formatNum(parseFloat(gameScorePercent).toFixed(2) || 0)} color={color} title={title+' Score'}/>
        </div>
      </div>
    </div>
  );
}

export default RunPassGameComponent;
