import React, { useState, useEffect } from "react";
import { Card, Popover } from "antd";
import { Alert, Skeleton, Select, Space } from "antd";
import FormationTripTable from "./FormationTripTable";
import FormationPlayTable2 from "./FormationPlayTable2";
import "./seasondashboard.css";
import FormationPlayTable from "./FormationPlayTable";
import _ from "lodash";
import { connect } from "react-redux";
import AdvantageOnly from "../../components/permissions/AdvantageOnly";
import GotIdea from "./components/got-idea";

function FormationAnalytics(props) {
  const [odk, setOdk] = useState("o");
  const [gamesList, setGamesList] = useState([]);
  const [game, setGame] = useState([]);
  const [gameId, setGameId] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [theirTeam, setTheirTeam] = useState("All");
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [seasonsList, setSeasonsList] = useState([]);
  const [gamesIdsList, setGamesIdsList] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [displayDataLoading, setDisplayDataLoading] = useState(true);
  const [offensive, setOffensive] = useState([]);
  const [defensive, setDefensive] = useState([]);
  const [playAnalyticsData, setPlayAnalyticsData] = useState({});
  const Option = Select.Option;
  const formations = {
    o: offensive,
    d: defensive,
  };
  const formationCol = {
    o: "offs",
    d: "defs",
  };
  const formationColx = {
    o: "offsx",
    d: "defsx",
  };
  const formationEl = {
    o: "off_off_formation",
    d: "def_front",
  };

  const handleSortPlay = (type) => {
    setOdk(type);
  };

  const fetchPlayTypeGradesAnalytics = (game) => {
    if (game.length) {
      setDisplayDataLoading(true);

      console.log("JSON.stringify(game)", JSON.stringify(game));
      props.playTypeGradesAnalytics({
        params: { game: JSON.stringify(game) },
        callback(res, data) {
          if (res === true) {
            const foo = data?.data?.items?.map((item) => item.rows);
            const body = {
              offs: foo[0],
              offsx: foo[1],
              defs: foo[2],
              defsx: foo[3],
              off: foo[4],
              offx: foo[5],
              def: foo[6],
              defx: foo[7],
              totals: foo[8],
            };

            const offensiveBuild = {};
            body?.offs?.map(
              (items) => (offensiveBuild[items.off_off_formation] = 1)
            );

            const defensiveBuild = {};
            body?.defs?.map((items) => (defensiveBuild[items.def_front] = 1));

            setOffensive(Object.keys(offensiveBuild));
            setDefensive(Object.keys(defensiveBuild));
            setPlayAnalyticsData(body);
          }
        },
      });
    } else {
      console.log("No games");
    }
  };

  useEffect(() => {
    loadSeasonsList();
  }, []);

  useEffect(() => {
    loadGamesAnalytics();
  }, [seasonsList, gamesList]);

  useEffect(() => {
    if (seasonsList[0]) {
      loadGamesList();
    }
  }, [seasonsList]);

  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));

    if (list) {
      setSeasonsList(list);
    } else {
      props.seasonsList({
        params: { sort: "id.desc", limit: 1000 },
        callback: (res, data) => {
          if (res === true) {
            const result = data.data.items;
            setSeasonsList(result);
            sessionStorage.setItem("seasonsList", JSON.stringify(result));
          }
        },
      });
    }
  };
  const loadGamesList = (seasonID = null) => {
    const season = seasonID ?? seasonsList[0]?.id;

    let params = {
      limit: 995,
      home_team: _.get(props, "coachProfile.school.id"),
      sort: "week.asc",
      season,
    };
    const list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));

    if (list) {
      setGamesList(list);
      setGamesListLoading(false);
    } else {
      setGamesListLoading(true);
    }

    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          sessionStorage.setItem(
            `${seasonID}-gamesList`,
            JSON.stringify(_.get(data, "data.items", []))
          );
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const loadGamesAnalytics = () => {
    let games = gamesList
      .filter(
        (game) => game.type == "regular season" || game.type == "playoffs"
      )
      .map(({ id }) => id);
    setGamesIdsList(games);
    fetchPlayTypeGradesAnalytics(games);
  };

  useEffect(() => {
    console.log("game ------ 4");
    fetchPlayTypeGradesAnalytics(game);
  }, [game]);

  useEffect(() => {
    handleCreateData();
  }, [playAnalyticsData]);

  useEffect(() => {
    handleCreateData();
  }, [odk]);

  const handleCreateData = () => {
    setDisplayDataLoading(true);

    let _displayData = formations[odk]?.map((formation) => {
      const portion = playAnalyticsData[formationCol[odk]]?.filter(
        (data) => data[formationEl[odk]] == formation
      );
      const portionx = playAnalyticsData[formationColx[odk]]?.filter(
        (data) => data[formationEl[odk]] == formation
      );
      const portionPlay = playAnalyticsData[
        { o: "off", d: "def" }[odk]
      ]?.filter((data) => data[formationEl[odk]] == formation);
      const portionPlayx = playAnalyticsData[
        { o: "offx", d: "defx" }[odk]
      ]?.filter((data) => data[formationEl[odk]] == formation);

      const total = playAnalyticsData?.totals
        ? playAnalyticsData?.totals[0][
            { o: "total_off_play", d: "total_def_play" }[odk]
          ]
        : 0;
      const count = portion?.reduce((total, num) => {
        return total + parseInt(num?.count);
      }, 0);

      return {
        portion,
        portionx,
        portionPlay,
        portionPlayx,
        total,
        count,
        formation,
      };
    });

    setDisplayData(_displayData);

    setTimeout(() => {
      setDisplayDataLoading(false);
    }, 500);
  };

  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%] formation-analytics">
      {" "}
      <div className="w-full flex justify-between items-center  ">
        <span className="text-[23px] xl:text-[1.7vw] font-bold">
          Self Scout Analytics (Beta)
        </span>

        <span className="text-[23px] xl:text-[1.7vw] font-bold"></span>
      </div>
      <div className="w-full mt-[10px]">
        <Card
          className="w-[100%] flex mb-3 flex-col"
          bodyStyle={{ padding: "24px" }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* aLL, O,D,K BUTTONS */}
              <div className="w-[96px] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around mr-[16px]">
                <button
                  onClick={() => {
                    handleSortPlay("o");
                  }}
                  className={
                    (odk == "o" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                  }
                >
                  O
                </button>
                <button
                  onClick={() => {
                    handleSortPlay("d");
                  }}
                  className={
                    (odk == "d" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                  }
                >
                  D
                </button>
              </div>
              <div className="w-[200px] h-[43px] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
                <Select
                  bordered={false}
                  defaultValue={seasonsList[0]?.title}
                  placeholder={seasonsList[0]?.title}
                  onChange={(value) => {
                    loadGamesList(value);
                  }}
                  className="w-[100%] text-[14px]"
                >
                  {_.map(seasonsList, ({ id, title }, index) => {
                    return (
                      <Option
                        value={id}
                        key={id}
                        className="text-[14px] font-bold font-inter"
                      >
                        {title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="w-[200px]  mx-3 h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
                <Select
                  bordered={false}
                  placeholder={"All Games"}
                  className="w-[100%] text-[14px]"
                  onChange={(id) => {
                    if (id === "all") {
                      const game = gamesList.filter(
                        (game) =>
                          game.type == "regular season" ||
                          game.type == "playoffs"
                      );
                      setGame(game.map(({ id }) => id));
                      setTheirTeam("Season Stats");
                    } else if (id === "scrimmages") {
                      const game = gamesList.filter(
                        (game) => game.type == "scrimmage"
                      );
                      setGame(game.map(({ id }) => id));

                      setTheirTeam("Scrimmages stats");
                    } else if (id === "practice") {
                      const game = gamesList.filter(
                        (game) => game.type == "practice"
                      );
                      setGame(game.map(({ id }) => id));

                      setTheirTeam("Practices stats ");
                    } else {
                      const game = gamesList.filter((game) => game.id == id)[0];
                      setGame([id]);

                      setTheirTeam(game.title);
                    }
                  }}
                >
                  <Option
                    value={"all"}
                    key={"all"}
                    className="text-[14px] font-bold font-inter"
                  >
                    All Games
                  </Option>
                  <Option
                    value={"practice"}
                    key={"practice"}
                    className="text-[14px] font-bold font-inter"
                  >
                    Practices
                  </Option>

                  <Option
                    value={"scrimmages"}
                    key={"scrimmages"}
                    className="text-[14px] font-bold font-inter"
                  >
                    Scrimmages
                  </Option>

                  {gamesList?.map(({ id, title, week, type }, i) => {
                    if (type == "regular season" || type == "playoffs")
                      return (
                        <Option
                          value={id}
                          key={title}
                          className="text-[14px] font-bold font-inter"
                        >
                          Week {week}
                        </Option>
                      );
                  })}
                </Select>
              </div>
              <span className="text-[14px] font-normal mx-2">
                <span className="text-[#A40E17]"> vs</span> {theirTeam}
              </span>
            </div>
            <div>
              <GotIdea />
            </div>
          </div>
        </Card>
        {displayDataLoading ? (
          <Card
            className="w-[100%] flex flex-col"
            bodyStyle={{ padding: "24px" }}
          >
            <Skeleton active />
            <Skeleton active />
          </Card>
        ) : (
          displayData
            ?.sort(function (a, b) {
              return b.count - a.count;
            })
            ?.map(
              (
                {
                  portion,
                  portionx,
                  portionPlay,
                  portionPlayx,
                  total,
                  count,
                  formation,
                },
                key
              ) => {
                return (
                  <Card
                    className="w-[100%] flex flex-col"
                    key={key}
                    bodyStyle={{ padding: "24px" }}
                  >
                    <div className="flex mt-[24px] justify-between">
                      <div className="w-[49%]">
                        <FormationTripTable
                          datax={portionx}
                          total={total}
                          count={count}
                          formation={formation}
                          data={portion}
                        />
                      </div>
                      <div className="w-[49%]">
                        <FormationPlayTable
                          portion={portion}
                          formation={formation}
                          total={count}
                          which={{ o: "play", d: "blitz" }[odk]}
                          data={portionPlay}
                          datax={portionPlayx}
                          path={{ o: "off_off_play", d: "blitz" }[odk]}
                        />
                      </div>
                    </div>
                  </Card>
                );
              }
            )
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  playTypeGradesAnalytics: (details) =>
    dispatch(global.redux.action.grades.playTypeGradesAnalytics(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormationAnalytics);
