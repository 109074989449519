import React, { useState, useEffect } from "react";
import { Modal, Input, Switch } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import Title from './components/title.js'

function StatsModal({ state, dispatch, close, update, item, school_id, loadAllPlayerList,  play, handleUpdatePlay, updateInputGrade, updateGrade  }) {
  const [modalWidth, setModalWidth] = useState(352);
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(200);
    } else {
      setModalWidth(352);
    }
  }, [window.innerWidth]);

  return state.qbcarry && (
    <Modal
      open={state.qbcarry}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
      title={<><Title title={'Offensive Stats'} player={`${item?.first_name} ${item?.last_name}`} /></>}
      
    >
      <div
        style={{ border: "1px solid #F3F5F7" }}
        className="mt-4 text-[12px] text-[#0F1A24]"
      >
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>GN/LS</span>
          </div>{" "}
          <div className="p-3 w-[30%]">
            <Input onChange={({target: { value }}) => handleUpdatePlay(play.id, { gn: value})} defaultValue={play.gn} size="small" className="w-full p-1" />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Contact</span>
          </div>{" "}
          <div className="p-3 w-[30%]">
            <Input defaultValue={item.yards_after_contact} onChange={(e)=>updateInputGrade('yards_after_contact',e )} size="small" className="w-full p-1" />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>TD</span>
          </div>{" "}
          <div className="p-3 w-[30%] turn-over actor flex">
            <Switch  defaultChecked={item.td} onChange={(e) => updateGrade('td', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Fumble</span>
          </div>{" "}
          <div className="p-3 w-[30%] fumble">
            <Switch  defaultChecked={item.caused_fumble} onChange={(e) => updateGrade('caused_fumble', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div className="flex items-center justify-between text-md mt-4">
          <button  onClick={close} 
            className="text-[#1790FF] w-[49%] flex items-center justify-center h-11  focus:outline-0 rounded-[4px]"
            style={{ border: "1px solid #1790FF" }}
          >
            Cancel
          </button>
          <button  onClick={close}  className="flex items-center focus:outline-0 justify-center w-[49%] bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default StatsModal;
