import React, { useState, useEffect } from "react";
import { Select, Card, message, Table, Spin } from "antd";
import _ from "lodash";
import SeasonsListSelect from "../../../components/seasonsListSelect";
import threeDot from "../../../assets/img/Frame 18264.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import WardboardScreen from "../../Wardbord/WardboardScreen";

import {
  generateSpecialTeamsLeaderboard,
  generateDefenseLeaderboard,
  generateOffenseLeaderboard,
  generateTeamLeaderboard,
} from "./leaderboardFunctions";

const { Option } = Select;

function LeaderboardPrint(props) {
  const location = useLocation();
  const history = useNavigate();
  const {
    dataSource = [],
    seasonID = "",
    odk = "",
    theirTeam = "",
    game = "",
    pageLoading,
    rawDataSource = [],
  } = location.state;

  return (
    <>
      <div
        className="print-modal w-full h-screen absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 z-50"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
      >
        <div className=" lg:w-[50%] xl:w-[30%] bg-[white] p-4 rounded-lg shadow-2xl">
          <div>
            <div className="font-medium text-lg">Continue Printing?</div>
            <div className="flex items-center justify-between w-full mt-3">
              <button
                onClick={() => {
                  window.print();
                  setTimeout(() => {
                    window?.location.reload();
                  }, 1000);
                }}
                className="bg-[#1A91FF] w-[40%] text-[#FFFFFF] p-2 rounded-[4px]"
              >
                Yes
              </button>{" "}
              <button
                onClick={() => window?.location.reload()}
                className="bg-[#A40E17] w-[40%] text-[#FFFFFF] p-2 rounded-[4px]"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-inter w-full lg:w-[98%] lg:p-[1.5%] stats-table-con">
        <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
          Leaderboard
        </h2>
        <Card className="w-full mt-3" bodyStyle={{ padding: 16 }}>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center w-[90%]">
              <div className="px-2 bg-[black] w-[225px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
                <button
                  disabled={true}
                  className={
                    (odk == "team" ? " bg-[#A40E17]" : " ") +
                    " w-[70px] h-[31px] cursor-pointer mr-[10px] rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  Team
                </button>
                <button
                  disabled={true}
                  className={
                    (odk == "o" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer rounded-[4px] mr-[10px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  O
                </button>
                <button
                  disabled={true}
                  className={
                    (odk == "d" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer mr-[10px] rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  D
                </button>
                <button
                  disabled={true}
                  className={
                    (odk == "kicker" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer mr-[10px]  rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  K
                </button>
              </div>
              <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
                <Select
                  bordered={false}
                  placeholder={"All Games"}
                  className="w-[100%] text-[14px]"
                ></Select>
              </div>
              <SeasonsListSelect defaultValue={seasonID} />
              <span className="text-[14px] font-normal mx-2">
                <span className="text-[#A40E17]"> vs</span> {theirTeam}
              </span>
            </div>
          </div>
          {odk === "team" && (
            <div className="w-full flex flex-col gap-4 mt-4">
              {generateTeamLeaderboard(dataSource)}

              <h2 className="mt-[4px]  mb-[1px] ml-[5px] text-[18px]  font-bold">
                Weekly Award Winners
              </h2>
              <WardboardScreen
                seasonID={seasonID}
                pageLoading={pageLoading}
                games={game}
              />
            </div>
          )}

          <div className="mt-4 flex gap-4 flex-wrap">
            {odk === "o" &&
              generateOffenseLeaderboard(dataSource, rawDataSource)}
            {odk === "d" && generateDefenseLeaderboard(dataSource)}
            {odk === "kicker" && generateSpecialTeamsLeaderboard(dataSource)}
          </div>
        </Card>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardPrint);
