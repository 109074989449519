import React, { useState, useEffect } from "react";
import SeasonsListSelect from "../../components/seasonsListSelect";
import { connect } from "react-redux";
import _ from "lodash";
import WeekListSelect from "../../components/WeekListSelect";
import SearchInput from "../../components/SearchInput";
import { Card, Table, Input, Select, Button } from "antd";
import { Tab } from "./GameAnalyticsDashboard";
import pageStar from "./assets/page-star.svg";
import clipboard from "./assets/clipboard-check.svg";
import emojiSad from "./assets/emoji-sad.svg";
import emojiSatisfied from "./assets/emoji-satisfied.svg";
import thumbsDown from "./assets/thumbs-down.svg";
import triangleFlag from "./assets/triangle-flag-full.svg";
import caution from "../../assets/img/info-empty.svg";
import { useNavigate, useLocation } from "react-router-dom";
import "./seasondashboard.css";
import { StatsData } from "./StatsData";

const { Option } = Select;
function PlayerStats(props) {
  let positionsMap = {
    d: props?.positionsData?.defense?.map((value) => value?.code),
    o: props.positionsData?.offense?.map((value) => value?.code),
    kicker: ["K"],
    st: ["ST"],
  };
  const [player, setPlayer] = useState(null);
  const [allAthletesList, setAllAthletesList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonID, setSeasonID] = useState(null);
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [game, setGame] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [theirTeam, setTheirTeam] = useState("All");
  const [dataSource, setDataSource] = useState([{}]);
  const [rawdDataSource, setRawDataSource] = useState([]);
  const [gamesData, setGamesData] = useState({});
  const history = useNavigate();
  const handlePlayerSelection = (e) => {
    setPlayer(e);
  };
  useEffect(() => {
    loadAllAthletesList();
  }, []);

  const loadAllAthletesList = () => {
    props.list({
      params: {
        limit: 1000,
        page: 1,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res) {
          setAllAthletesList(data?.data?.items);
        }
      },
    });
  };
  const formatNum = (val) => {
    if (isNaN(val)) return 0;
    return Math.round(val);
  };
  useEffect(() => {
    if (props?.coachProfile?.school?.id) {
      loadGamesList();
    }
  }, [seasonsList, seasonID]);

  const loadGamesList = () => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;

      if (season) {
        setSeasonID(season);

        let params = {
          limit: 995,
          home_team: props?.coachProfile?.school?.id,
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(
          sessionStorage.getItem(`${seasonID}-gamesList`)
        );

        if (list) {
          setAllGamesList(list);
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }

        props?.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              setAllGamesList(data?.data?.items);
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setAllGamesList = (data) => {
    const list = [];

    if (data?.length > 0) {
      data?.map((value, index) => {
        list.push({
          key: index,
          id: parseInt(value?.id),
          title: value.title,
          week: `Week ${value.week}`,
          season: value?.season,

          game_id: value?.id,
          opponent_logo: value?.opponent_logo,
          away_team_score: value.away_team_score ?? 0,
          home_team_score: value.home_team_score ?? 0,
          team: props.coachProfile?.school?.name,
          school: props.coachProfile?.school?.id,
          type: value?.type,
          feedback_type: value?.feedback_type,
        });
      });

      setGamesList(list);
      const game = list.filter(
        (game) => game.type == "regular season" || game.type == "playoffs"
      );
      setGame(game.map(({ id }) => id));
      setTheirTeam("Season Stats");
    } else {
      setGamesList([]);
    }
  };
  useEffect(() => {
    loadGradesStats(JSON.stringify(game), player);
    fetchGamesAnalytics(JSON.stringify(game), player);
  }, [game, player]);

  useEffect(() => {
    loadGradesStats(JSON.stringify(game), player);
  }, []);

  const loadGradesStats = (game, athlete) => {
    try {
      if (game && athlete) {
        setPageLoading(true);
        props.gradesStats({
          params: {
            game,
            athlete,
          },
          callback: (res, data) => {
            if (res) {
              setDataSource(data?.data?.items?.rows || []);

              setRawDataSource(data?.data?.items?.rows);
            }
            setPageLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePrint = () => {
    window.print();
  };

  const fetchGamesAnalytics = (games, athlete) => {
    setPageLoading(true);
    props.gamesAnalytics({
      params: { games, athlete },
      callback: (res, data) => {
        setPageLoading(false);
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows[0]);
          const result = {
            ...blob[0],
            ...blob[1],
            ...blob[2],
            ...blob[3],
            ...blob[4],
            ...blob[5],
            ...blob[6],
            ...blob[7],
            ...blob[8],
            ...blob[9],
          };
          setGamesData(result);
        }
      },
    });
  };

  const updateStatsData = (item, positionCategory) => {
    const offensiveTitleToPropertyMap = {
      "Offensive Plays": (item) => Number(item?.plays) || 0,
      "Rushing Yards": "total_gn_carries_yds",
      "Passing Yards": "total_completions_carries_yds",
      "Receiving Yards": "total_complete_receptions_yds",
      "Total Offensive Yards": (item) =>
        Number(item?.total_gn_carries_yds) +
        Number(item?.total_completions_carries_yds) +
        Number(item?.total_complete_receptions_yds), // I don't know the value of this
      "Yards After Contact": "total_yards_after_contact",
      "Yards After Catch": "total_yards_after_catch",
      "Receiving Targets": (item) => Number(item?.total_completions), // I don't know the value of this
      Fumbles: "total_caused_fumble",
      "Passing TD’s": (item) => Number(item?.total_qbtd), // not sure of this
      "Rushing/Receiving TD’s": (item) =>
        Number(item?.total_rbtd) + Number(item?.total_wrtd), // not sure of this
      "Offensive TD’s": (item) =>
        Number(item?.total_wrtd) +
        Number(item?.total_qbtd) +
        Number(item?.total_rbtd), // not sure of this
    };

    const defensiveTitleToPropertyMap = {
      "Defensive Plays": (item) => Number(item?.plays) || 0,
      "Total TFL": (item) => Number(item?.total_tackles_tfl),
      "Yardage from TFL’s": (item) => Number(item?.total_tackles_yds),
      "INT’s": (item) => Number(item?.total_int),
      "Total Tackles": (item) =>
        Number(item?.total_tackles_solo) +
        Number(item?.total_tackles_ast_halved),
      "Total Sacks": (item) => Number(item?.total_tackles_sak),
      "Yardage from Sacks": (item) => Number(item?.total_sack_yds),
      "Yardage from INT": (item) => Number(item?.total_tackles_int_yds),
      "Missed Tackles": (item) => Number(item?.total_missed_tackles),
      PBU: (item) => Number(item?.total_pd),
      "Turnovers Created": (item) =>
        Number(item?.total_int) + Number(item?.total_recovered_fumble),
      "Defensive TD’s": (item) => Number(item?.total_td),
    };

    const specialTeamTitleToPropertyMap = {
      "Special Teams Plays": (item) => Number(item?.plays),
      "Kickoff Return Yards": (item) => Number(item?.total_kickoff_return_yds),
      "Kickoff Return Avg": (item) =>
        Math.round(
          Number(item?.total_kickoff_return_yds) /
            Number(item?.total_kickoff_return) || 0
        ),
      "Kickoff Return TD’s": (item) => Number(item?.total_kickoff_return_td),
      "Punt Return Yards": (item) => Number(item?.total_punt_return_yds),
      "Punt Return Avg": (item) => 
        Number(
          Number(item?.total_punt_return_yds) /
            Number(item?.total_punt_return) || 0
        )
      ,
      "Punt Return TD’s": (item) => Number(item?.total_punt_return_td),
      "ST Tackles": (item) =>
        Number(item?.total_tackles_solo) +
        Number(item?.total_tackles_ast_halved),
      "ST Caused Fumbles": (item) => Number(item?.total_caused_fumble),
      "ST Blocks": (item) =>
        Number(item?.total_punt_blocks) + Number(item?.total_fg_blocks), // not sure of this
      "Total Return Yards": (item) =>
        Number(item?.total_kickoff_return_yds) +
        Number(item?.total_punt_return_yds),
      "ST TD’s": (item) =>
        Number(item?.total_st_td) +
        Number(item.total_punt_return_td) +
        Number(item.total_kickoff_return_td),
    };

    const kickerTitleToPropertyMap = {
      "Special Teams Plays": (item) => Number(item?.plays),
      "FG’s Made": (item) => Number(item?.total_field_goals),
      "FGM %": (item) =>
        Math.round(
          (Number(item.total_field_goals) /
            (Number(item.total_field_goals) +
              Number(item.total_block_field_goals) +
              Number(item.total_missed_field_goals))) *
            100
        ) || 0,
      "Avg FGM Dist": (item) =>
        Math.round(Number(item?.avg_field_goal_distance)),
      "FGM Long": (item) => Math.round(Number(item?.max_field_goal_distance)),
      Punts: (item) =>
        Number(item?.total_punts) + Number(item?.total_blocked_punts),
      "Avg Punt Dist": (item) =>
        Math.round(
          Number(item?.total_punts_distance) / Number(item?.total_punts)
        ),
      "Punt Long": (item) => Number(item?.max_punts_distance),
      "PAT Made": (item) => Number(item?.total_pat),
      Kickoffs: (item) => Number(item?.total_kickoff),
      "KO Touchbacks": (item) => Number(item?.total_kickoff_touchback),
      "Onside Kicks Recovered": (item) => Number(item?.total_recovered_fumble),
    };

    const category = StatsData.find(
      (category) => category.title === positionCategory
    );
    if (category) {
      category.data.forEach((dataItem) => {
        let propertyOrExpression;
        switch (positionCategory) {
          case "Offensive":
            propertyOrExpression = offensiveTitleToPropertyMap[dataItem?.title];
            break;

          case "Defensive":
            propertyOrExpression = defensiveTitleToPropertyMap[dataItem?.title];
            break;

          case "Special Teams":
            propertyOrExpression =
              specialTeamTitleToPropertyMap[dataItem?.title];
            break;

          case "Kicker":
            propertyOrExpression = kickerTitleToPropertyMap[dataItem?.title];
            break;
        }
        if (typeof propertyOrExpression === "function") {
          dataItem.value = propertyOrExpression(item) || 0;
        } else {
          dataItem.value = Number(item[propertyOrExpression]) || 0;
        }
      });
    }
  };
  function combineObjects(objArray) {
    const combined = {};
    objArray.forEach((obj) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const val = Number(obj[key]);
          if (!isNaN(val) && key != 'kit_number') {
            combined[key] = (combined[key] || 0) + val;
          } else {
            if (!combined.hasOwnProperty(key)) {
              combined[key] = obj[key];
            }
          }
        }
      }
    });

    return combined;
  }

  const sections = {
    Offensive: [],
    Defensive: [],
    Kicker: [],
    "Special Teams": [],
  };
  const odks = {
    WR: "Offensive",
    QB: "Offensive",
    OL: "Offensive",
    TE: "Offensive",
    RB: "Offensive",
    DL: "Defensive",
    S: "Defensive",
    C: "Defensive",
    LB: "Defensive",
    ST: "Special Teams",
    K: "Kicker",
  };

  rawdDataSource?.map((item) => {
    sections[odks[item.position]].push(item);
  });

  Object.keys(sections).map((section) => {
    if (sections[section].length == 1) {
      updateStatsData(sections[section][0], section);
    } else {
      updateStatsData(combineObjects(sections[section]), section);
    }
  });

  return (
    <div className="flex flex-col font-inter w-[99%] p-[1%]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
            <Select
              bordered={false}
              placeholder={"All Games"}
              className="w-[100%] text-[14px]"
              onChange={(id) => {
                if (id === "all") {
                  const game = gamesList.filter(
                    (game) =>
                      game.type == "regular season" || game.type == "playoffs"
                  );
                  setGame(game.map(({ id }) => id));
                  setTheirTeam("Season Stats");
                } else if (id === "scrimmages") {
                  const game = gamesList.filter(
                    (game) => game.type == "scrimmage"
                  );
                  setGame(game.map(({ id }) => id));
                  setTheirTeam("Scrimmages stats");
                } else if (id === "practice") {
                  const game = gamesList.filter(
                    (game) => game.type == "practice"
                  );

                  setGame(game.map(({ id }) => id));

                  setTheirTeam("Practices stats ");
                } else {
                  const game = gamesList.filter((game) => game.id == id)[0];
                  setGame([id]);

                  setTheirTeam(game.title);
                }
              }}
            >
              <Option
                value={"all"}
                key={"all"}
                className="text-[14px] font-bold font-inter"
              >
                All Games
              </Option>
              <Option
                value={"practice"}
                key={"practice"}
                className="text-[14px] font-bold font-inter"
              >
                Practices
              </Option>

              <Option
                value={"scrimmages"}
                key={"scrimmages"}
                className="text-[14px] font-bold font-inter"
              >
                Scrimmages
              </Option>

              {gamesList?.map(({ id, title, week, type }, i) => {
                if (type == "regular season" || type == "playoffs")
                  return (
                    <Option
                      value={id}
                      key={title}
                      className="text-[14px] font-bold font-inter"
                    >
                    {week}
                    </Option>
                  );
              })}
            </Select>
          </div>
          <SeasonsListSelect
            setSeasonsList={setSeasonsList}
            onChange={(e) => {
              setSeasonID(e);
            }}
          />
          <span className="text-[14px] font-normal mx-2">
            <span className="text-[#A40E17]"> vs</span> {theirTeam}
          </span>
        </div>
        <div className=" overflow-y-scroll md:w-[200px] xl:w-[25%]">
          <div className="border border-solid border-[#dedede] rounded-lg   ">
            <Select
              style={{ width: "100%" }}
              bordered={false}
              // value={playerList?.map(
              //   (player) => `${player.first_name} ${player.last_name}`
              // )}
              // onDeselect={(deselectedValue) => {
              //   const deselectedObject = playerList?.find(
              //     (option) =>
              //       `${option.first_name} ${option?.last_name}` ===
              //       deselectedValue
              //   );
              //   if (deselectedObject) {
              //     removePlayer(data.id, deselectedObject.player_id);
              //   }
              // }}
              onChange={handlePlayerSelection}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              showSearch
              className="multi"
            >
              {allAthletesList?.map((player) => {
                return (
                  <Option
                    key={player?.athlete?.id}
                    value={player?.athlete?.id}
                    label={`${player?.athlete?.first_name} ${player?.athlete?.last_name}`}
                  >
                    <div>
                      {player?.athlete?.first_name} {player?.athlete?.last_name}{" "}
                      ({player?.positions?.toString()})
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <div
        className="mt-3 w-full bg-[#0F1A24] text-[white] py-2 px-3  font-bold text-lg  3xl:text-[1.25vw]"
        key={"index"}
      >
        <span>Performance Overview</span>
        <button
          className="btn btn-primary m-auto btn-sm text-[10px]"
          style={{ float: "right" }}
          onClick={() => history("/season-analytic-dashboard")}
        >
          View Player Performance Totals
        </button>
      </div>
      <Card className="w-full  " bodyStyle={{ padding: 12 }}>
        <div className="w-full flex flex-wrap justify-between mt-[32px]">
          {[
            {
              icon: pageStar,
              value:
                dataSource?.reduce((a, b) => Number(a) + Number(b.plays), 0) ||
                0,
              title: "Assignments Graded",
              color: "#1790FF",
            },
            {
              icon: clipboard,
              value:
                formatNum(
                  (dataSource?.reduce(
                    (a, b) => Number(a) + Number(b.total_assignment_executed),
                    0
                  ) /
                    dataSource?.reduce(
                      (a, b) => Number(a) + Number(b.plays),
                      0
                    )) *
                    100
                ) + "%",
              title: "Execution Score",
              color: "#12CF52",
            },
            {
              icon: emojiSatisfied,
              value:
                formatNum(
                  (dataSource?.reduce(
                    (a, b) => Number(a) + Number(b.total_great_effort),
                    0
                  ) /
                    dataSource?.reduce(
                      (a, b) => Number(a) + Number(b.plays),
                      0
                    )) *
                    100
                ) + "%",
              title: "Effort Score",
              color: "#F96B04",
            },
            {
              icon: triangleFlag,
              value:
                dataSource?.reduce(
                  (a, b) => Number(a) + Number(b.total_penalties),
                  0
                ) || 0,
              title: "Penalty Flags",
              color: "#F0BB00",
            },
            {
              icon: thumbsDown,
              value: formatNum(
                dataSource?.reduce((a, b) => Number(a) + Number(b.plays), 0) -
                  dataSource?.reduce(
                    (a, b) => Number(a) + Number(b.total_assignment_executed),
                    0
                  )
              ),
              title: "Assignments Failed",
              color: "#A40E17",
            },
            {
              icon: emojiSad,
              value: formatNum(
                dataSource?.reduce((a, b) => Number(a) + Number(b.plays), 0) -
                  dataSource?.reduce(
                    (a, b) => Number(a) + Number(b.total_great_effort),
                    0
                  )
              ),
              title: "Poor Effort Plays",
              color: "#A40E17",
            },
          ].map((item) => {
            return <Tab key={item?.title} {...item} />;
          })}
        </div>
      </Card>

      {StatsData?.map((item, index) => {
        return (
          <>
            <div
              className="mt-3 w-full bg-[#0F1A24] text-[white] py-2 px-3  font-bold text-lg  3xl:text-[1.25vw]"
              key={index}
            >
              <span>{item?.title} Stats</span>
            </div>
            <Card className="w-full player-stats" bodyStyle={{ padding: 12 }}>
              <div className="bg-[#F3F5F7] flex items-center justify-between gap-y-3 p-3 flex-wrap w-full">
                {item?.data?.map((stats, index) => {
                  return (
                    <div
                      className="md:w-[209px] xl:w-[24%] flex flex-col gap-1 text-[#0F1A24] text-sm"
                      key={index}
                    >
                      <span>{stats.title}</span>
                      <Input
                        size="large"
                        width={`100%`}
                        className="bg-[#FFFFFF]"
                        value={stats.value}
                      />
                    </div>
                  );
                })}
              </div>
            </Card>
          </>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};
const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  gamesAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStats);
