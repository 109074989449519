let host = window.location.host
let hostName = host.split(':')

//https://api.wardbord.com
const baseUrl =  `${window.location.protocol}//${hostName[0]}`
const MODES = {
  DEVELOPMENT: {
    API: {
      BASE_URL: `${baseUrl}`,
      CLIENT_ID: 'J0SOKSPD',
      CLIENT_SECRET: 'tXQd8QthlfFTd29FQFzlMLEK1KxlJh0'
    },
    FACEBOOK: {},
    GOOGLE: {},
    GOOGLEMAP: {}
  }
}

const getEnvVariables = () => {
  return MODES.DEVELOPMENT
}

export default {
  ...getEnvVariables()
}
