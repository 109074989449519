import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select, Tooltip, Radio, Checkbox } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import puzzle from "../../../../assets/img/bl.svg";
import { ReceptionRadioButtons } from "../buttons/reception-button.js";
import Title from "./components/title.js";
import { set } from "lodash";

function KickerModal({
  state,
  dispatch,
  close,
  update,
  item,
  school_id,
  loadAllPlayerList,
  play,
  handleUpdatePlay,
  updateInputGrade,
  updateGrade,
}) {
  const [modalWidth, setModalWidth] = useState(550);
  const [showMoreOnside, setShowMoreOnside] = useState(false);
  const [showMoreKickoff, setShowMoreKickoff] = useState(false);

  useEffect(() => {
    if (showMoreKickoff || showMoreOnside) {
      setModalWidth(752);
    }
  }, [showMoreKickoff, showMoreOnside]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(552);
    } else {
      setModalWidth(525);
    }
  }, [window.innerWidth]);

  return (
    state.kicker && (
      <Modal
        open={state.kicker}
        onOk={close}
        onCancel={close}
        closable={true}
        footer={null}
        width={modalWidth}
        title={
          <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
            <span className=" font-bold">Kickoff</span>
            <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
            <span className="font-[400]">
              {item?.first_name} {item?.last_name}
            </span>
          </div>
        }
      >
        <div
          style={{ border: "1px solid #F3F5F7" }}
          className="mt-4 text-[12px] text-[#0F1A24]"
        >
          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[25%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>Kickoff</span>
            </div>{" "}
            <div className="p-3 w-[15%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.kickoff}
                onChange={(e) => {
                  setShowMoreKickoff(e); 
                  updateGrade("kickoff", e ? 1 : 0);
                }}
              />{" "}
            </div>
            {showMoreKickoff || item.kickoff ? (
              <>
                <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
                  <Radio.Group
                    name="radiogroup"
                    onChange={({ target: { value } }) => {
                      updateGrade("kickoff_touchback", value === 1 ? 0 : 1) 
                      updateGrade("kickoff_fair_catch", value)
                    }}
                    defaultValue={item.kickoff_fair_catch}
                  >
                    <Radio value={0}>
                      {" "}
                      <span className="text-[#2E4D6B]">Touchback</span>
                    </Radio>
                    <Radio value={1}>
                      {" "}
                      <span className="text-[#2E4D6B]">Fair Catch </span>
                    </Radio>
                  </Radio.Group>
                </div>{" "}
              </>
            ) : null}
          </div>

          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[25%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>Onside Kick</span>
            </div>{" "}
            <div className="p-3 w-[15%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.onsidekick}
                onChange={(e) => {
                  setShowMoreOnside(e); 
                  updateGrade("onsidekick", e ? 1 : 0);
                }}
              />{" "}
            </div>
            {showMoreOnside || item.onsidekick ? (
              <>
                {" "}
                <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
                <Checkbox
                defaultChecked={Number(item.recovered_fumble)}
                onChange={({ target: { checked: e }}) => updateGrade("recovered_fumble", e ? 1 : 0)}
              >
                <span className="text-[#2E4D6B]">Recovered</span>
              </Checkbox>
                
                </div>{" "}
              </>
            ) : null}
          </div>

          <br />
          <br />

          <div className="flex items-center justify-between mt-2">
            <button
              onClick={close}
              className="flex w-[30%] items-center focus:outline-0 justify-center bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]"
            >
              <img src={floppyDiskImg} className="w-6" />
              <span className="ml-2">Save</span>
            </button>
          </div>
        </div>
      </Modal>
    )
  );
}

export default KickerModal;
