import React, { useState, useEffect } from "react";
import { Input, Form, message } from "antd";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import continueArrow from "../../assets/img/arrow-right.svg";
import check from "../../assets/img/circled check mark.png";

function PlayerSignup(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [schoolId, setSchoolId] = useState(null); // State to store school ID
  const [step, setStep] = useState(1);

  useEffect(() => {
    // Function to parse URL parameters and extract school ID
    const getSchoolIdFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const schoolIdParam = params.get("school");
      if (schoolIdParam) {
        setSchoolId(schoolIdParam);
      }
    };
    getSchoolIdFromURL();
  }, []);

  const onRegister = async (values) => {
    setLoading(true);
    try {
      const formData = { ...values, school: schoolId };
      const response = await axios.post(
        "https://api.wardbord.com/athletes/self-invite",
        formData
      );
      console.log("response ", response);
      if (response?.data?.error) {
        message.error(response?.data?.error);
        setShow(true);
      } else {
        setStep(2);
        setShow(false);
      }
    } catch (error) {
      console.error("Error registering player:", error);
      message.error("Registration failed. Please try again.");
    } finally {
      setShow(true);
      setLoading(false);
    }
  };

  return (
    <>
      {step == 1 && (
        <div className="w-[90%] mx-auto pt-20">
          <h3
            style={{
              fontSize: "1.7rem",
              lineHeight: "1.2",
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            Join your teammates!
          </h3>
          <div className="text-[12px] mb-8 text-center font-poppins text-[#0F1A24]">
            Complete the player intake form with your full name, cell number,
            email, and create your password. <br />
            When you're done, you're ready to go download the “Wardbord App”
            from the app store! (Available in both Apple and Android stores)
          </div>
          {show && (
            <div className="mt-8 lg:w-[40%] m-auto">
              <Form
                layout="vertical"
                requiredMark={false}
                onFinish={(e) => onRegister(e)}
              >
                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      First Name
                    </span>
                  }
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input placeholder="First Name" size="large" />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      Last Name
                    </span>
                  }
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" size="large" />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      Email
                    </span>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    //   autoComplete="new-password"
                    placeholder="eg: name@example.com"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      Phone Number
                    </span>
                  }
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your cell phone number!",
                    },
                  ]}
                >
                  <Input
                    //   autoComplete="new-password"
                    placeholder="eg: name@example.com"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      Password
                    </span>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-inter text-[14px] text-[#0F1A24]">
                      Confirm Password
                    </span>
                  }
                  name="confirm_password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" size="large" />
                </Form.Item>
                <Form.Item>
                  <button
                    className={`mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    {loading && (
                      <LoadingOutlined className="animate-spin mr-1" />
                    )}
                    <span>Sign Up</span>
                  </button>
                </Form.Item>
              </Form>
            </div>
          )}
          <div className="flex justify-center mt-4">
            <a
              href="https://apps.apple.com/us/app/wardbord/id1619683329"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <img
                src={
                  "https://wardboard-dev.s3.us-east-2.amazonaws.com/public/images/assets/ios.png"
                }
                alt="IOS"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wardbord.playerapp"
              style={{ cursor: "pointer" }}
            >
              <img
                src={
                  "https://wardboard-dev.s3.us-east-2.amazonaws.com/public/images/assets/android.png"
                }
                alt="Android"
              />
            </a>
          </div>
        </div>
      )}
      {step == 2 && (
        <div className="w-full flex justify-center pt-20">
          <div className="w-[80%] gap-4 flex text-center flex-col items-center justify-center">
            <img src={check} className="w-[25%]" />
            <span className="text-[#0F1A24] font-bold text-[20px]">
              Registration Successful! <br /> You are ready to go, download the
              'Wardbord App' from the app store!
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default PlayerSignup;
