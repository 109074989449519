import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
    notification,
	Modal,
    Form,
    Input
} from "antd";
import { connect } from "react-redux";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import "./schools.css";

const { TextArea } = Input;

const SchoolsUpdatePhoneComponent = (props) => {

    const [form] = Form.useForm();

    useEffect(() => {
    form.setFieldsValue({
        phone: props.phonedata
    });
   }, [props.phonedata]);

   const handleSubmit = () => {
    const params = new URLSearchParams(window.location.search);
    let school_id = params.get('id');
    let details = {
        id: school_id,
        phone: form.getFieldValue('phone'),
    }
    console.log('details', details);

    props.UpdatePhoneData({
        body: details,
        callback: (res, responseData) => {
            if(res==true){
                notification.success(
                    {message:'Success',description: _.get(responseData, 'message')}
                );
                props.setIsModalVisible(false);
            }
            console.log('UpdatePhoneData: ', res, responseData);
        }
    });
};
 
    const render= () => {
		return (
			<div className="SchoolsViewModalContainer">
				<Modal className="SchoolsViewModal" visible={props.isModalVisible} 
                    onOk={() => {
                        handleSubmit();
                    }}
                    onCancel={() => {
                    form.setFieldsValue({
                        phone: props.phonedata
                    });
                        props.setIsModalVisible(false);
                    }} 
                    okText = 'Update'
                >
                    <Form
                        form={form}>
                        <Form.Item
						    className="col-12 col-md-6"
							label="Update Phone"
							name="phone"
                            rules={[{ required: true, message: 'Please input your phone!' }]}
                        >
							<Input style={{width: 200}} />
						</Form.Item>
                    </Form>
				</Modal>
			</div>
		)
	}
    return render();
}

const mapDispatchToProps = dispatch => ({
    UpdatePhoneData: details => dispatch(global.redux.action.schools.updatephone(details))
 });
  
 export default connect(null, mapDispatchToProps)(SchoolsUpdatePhoneComponent);