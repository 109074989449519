import React, { useState } from "react";
import { Select, Form, Input } from "antd";

function HowDoYouHearAboutUs() {
  const [source, setSource] = useState("");
  return (
    <div className="w-[100%] h-[70vh]">
      <h6 className="font-bold font-poppins text-lg">
        How did you hear about us?
      </h6>
      <div className="mt-6 w-[60%] text-sm font-normal">
        <span>How did you hear about us?</span>
        <div className="w-full border-[1px] py-2 rounded-lg bg-white">
          <Select
            bordered={false}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            style={{ border: "none", width: "100%" }}
            placeholder="Select source"
            onChange={(value) => setSource(value)}
          >
            {[
              "Twitter",
              "Instagram",
              "Facebook",
              "Coaching Clinic",
              "Referred by another coach",
            ].map((state, index) => (
              <Select.Option key={index} value={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </div>
        {source === "Referred by another coach" && (
          <div className="mt-6">
            <Form.Item
              name="coachName"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the coach",
                },
              ]}
            >
              <div>
                <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
                  Who Referred You
                </span>
                <Input className="py-2" placeholder="Type here" />
              </div>
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  );
}

export default HowDoYouHearAboutUs;
