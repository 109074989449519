import React, { useState, useEffect } from "react";
import { Select, Card, message, Table, Spin } from "antd";
import _ from "lodash";
import SeasonsListSelect from "../../../components/seasonsListSelect";
import threeDot from "../../../assets/img/Frame 18264.svg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { leaderboardColumn } from "./leaderboardColumn";
import LeaderboardComponent from "./LeaderboardComponent";
import WardboardScreen from "../../Wardbord/WardboardScreen";
import PageHeader from "../../../components/Header";
import PageSidebar from "../../../components/Sidebar";
import GotIdea from "../components/got-idea";
import {
  generateSpecialTeamsLeaderboard,
  generateDefenseLeaderboard,
  generateOffenseLeaderboard,
  generateTeamLeaderboard,
} from "./leaderboardFunctions";

const { Option } = Select;
function combineObjects(objArray) {
  const combined = {};
  objArray.forEach((obj) => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const val = Number(obj[key]);
        if (!isNaN(val) && key != 'kit_number') {
          combined[key] = (combined[key] || 0) + val;
        } else {
          if (!combined.hasOwnProperty(key)) {
            combined[key] = obj[key];
          }
        }
      }
    }
  });

  return { ...combined, athlete: combined.athlete / objArray.length };
}
function Leaderboard(props) {
  let positionsMap = {
    d: ["DL", "LB", "S", "C"],
    o: ["OL", "QB", "RB", "TE", "WR"],
    kicker: ["K", "ST"],
  };
  let positionsArrays = [
    "DL",
    "LB",
    "S",
    "C",
    "OL",
    "QB",
    "RB",
    "TE",
    "WR",
    "K",
    "ST",
  ];
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonID, setSeasonID] = useState(null);
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [game, setGame] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [theirTeam, setTheirTeam] = useState("All");
  const [odk, setOdk] = useState("team");
  const [dataSource, setDataSource] = useState([]);
  const [positionsArray, setPositionsArray] = useState(positionsArrays);
  const [kickerDatasource, setKickerDataSource] = useState([]);
  const [rawDataSource, setRawDataSource] = useState([]);

  const history = useNavigate();
  const handleSortPlay = (type) => {
    if (type !== "team") {
      setOdk(type);
      setPositionsArray(positionsMap[type]);
    } else {
      setOdk(type);
      setPositionsArray(positionsArrays);
    }
  };

  useEffect(() => {
    if (props?.coachProfile?.school?.id && seasonsList.length > 0) {
      loadGamesList();
    }
  }, [seasonsList, seasonID]);

  const loadGamesList = () => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;

      if (season) {
        setSeasonID(season);

        let params = {
          limit: 995,
          home_team: props?.coachProfile?.school?.id,
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(sessionStorage.getItem(`${season}-gamesList`));

        if (list) {
          setGamesList(list);
          setGame(list.map(({ id }) => id));
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }

        props?.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              setGamesList(data?.data?.items);
              // setAllGamesList(data?.data?.items);
              const game = data?.data?.items.filter(
                (game) =>
                  game.type == "regular season" || game.type == "playoffs"
              );
              setGame(game.map(({ id }) => id));
              setTheirTeam("Season Stats");
            } else {
              setGamesList([]);
              setGame([]);
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (game.length > 0 && positionsArray) {
      loadGradesStats(JSON.stringify(game), JSON.stringify(positionsArray));
    }
  }, [game, positionsArray, odk]);

  const loadGradesStats = (game, position) => {
    try {
      if (game && position) {
        setPageLoading(true);
        props.gradesStats({
          params: {
            game,
            position,
          },
          callback: (res, data) => {
            if (res) {
              const dupChecker = {};
              const duppies = new Set();
              let theDataStore = [];
              const theData = data?.data?.items?.rows;
              theData?.map((data, index) => {
                let athlete = data.athlete;
                if (!dupChecker[athlete]) {
                  dupChecker[athlete] = 1;
                } else {
                  dupChecker[athlete] += 1;
                  duppies.add(athlete);
                }
              });
              if (duppies.size) {
                duppies?.forEach((duppy) => {
                  const duplicates = theData?.filter((d) => d.athlete == duppy);
                  theDataStore.push(combineObjects(duplicates));
                });
                theData.map((d) => {
                  if (!duppies.has(d.athlete)) {
                    theDataStore.push(d);
                  }
                });
              } else {
                theDataStore = theData;
              }
              setDataSource(theDataStore || []);
              setRawDataSource(data?.data?.items?.rows || []);
              console.log("theDataStore", data?.data?.items?.rows);
              if (odk == "kicker") {
                setKickerDataSource(theDataStore || []);
                setDataSource(theDataStore || []);
                setRawDataSource(data?.data?.items?.rows || []);
                console.log("theDataStore for kicker", data?.data?.items?.rows);
              }
            }
            setPageLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col font-inter w-full lg:w-[98%] lg:p-[1.5%] stats-table-con">
      <Spin spinning={pageLoading}>
        <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
          Leaderboard
        </h2>
        <Card className="w-full mt-3" bodyStyle={{ padding: 16 }}>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center w-[90%]">
              <div className="px-2 bg-[black] w-[225px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
                <button
                  onClick={() => {
                    handleSortPlay("team");
                  }}
                  className={
                    (odk == "team" ? " bg-[#A40E17]" : " ") +
                    " w-[70px] h-[31px] cursor-pointer mr-[10px] rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  Team
                </button>
                <button
                  onClick={() => {
                    handleSortPlay("o");
                  }}
                  className={
                    (odk == "o" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer rounded-[4px] mr-[10px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  O
                </button>
                <button
                  onClick={() => {
                    handleSortPlay("d");
                  }}
                  className={
                    (odk == "d" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer mr-[10px] rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  D
                </button>
                <button
                  onClick={() => {
                    handleSortPlay("kicker");
                  }}
                  className={
                    (odk == "kicker" ? " bg-[#A40E17]" : " ") +
                    " w-[32px] h-[31px] cursor-pointer mr-[10px]  rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17] font-semibold"
                  }
                >
                  K
                </button>
              </div>
              <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
                <Select
                  bordered={false}
                  placeholder={"All Games"}
                  className="w-[100%] text-[14px]"
                  onChange={(id) => {
                    if (id === "all") {
                      const game = gamesList.filter(
                        (game) =>
                          game.type == "regular season" ||
                          game.type == "playoffs"
                      );
                      setGame(game.map(({ id }) => id));
                      setTheirTeam("Season Stats");
                    } else if (id === "scrimmages") {
                      const game = gamesList.filter(
                        (game) => game.type == "scrimmage"
                      );
                      setGame(game.map(({ id }) => id));
                      setTheirTeam("Scrimmages stats");
                    } else if (id === "practice") {
                      const game = gamesList.filter(
                        (game) => game.type == "practice"
                      );
                      setGame(game.map(({ id }) => id));
                      setTheirTeam("Practices stats ");
                    } else {
                      const game = gamesList.filter((game) => game.id == id)[0];
                      setGame([id]);
                      setTheirTeam(game.title);
                    }
                  }}
                >
                  <Option
                    value={"all"}
                    key={"all"}
                    className="text-[14px] font-bold font-inter"
                  >
                    All Games
                  </Option>
                  <Option
                    value={"practice"}
                    key={"practice"}
                    className="text-[14px] font-bold font-inter"
                  >
                    Practices
                  </Option>

                  <Option
                    value={"scrimmages"}
                    key={"scrimmages"}
                    className="text-[14px] font-bold font-inter"
                  >
                    Scrimmages
                  </Option>

                  {gamesList?.map(({ id, title, week, type }, i) => {
                    if (type == "regular season" || type == "playoffs")
                      return (
                        <Option
                          value={id}
                          key={title}
                          className="text-[14px] font-bold font-inter"
                        >
                          Week {week}
                        </Option>
                      );
                  })}
                </Select>
              </div>
              <SeasonsListSelect
                setSeasonsList={setSeasonsList}
                seasonsLists={seasonsList}
                onChange={(e) => {
                  setSeasonID(e);
                }}
              />
              <span className="text-[14px] font-normal mx-2">
                <span className="text-[#A40E17]"> vs</span> {theirTeam}
              </span>
            </div>
            {/* <img
                  src={threeDot}
                  className="bg-[#E8F4FF] rounded-lg py-2 px-3 mx-auto cursor-pointer"
                /> */}
            <div>
              <GotIdea />
            </div>
          </div>
          <span
            className="text-right flex items-center cursor-pointer mt-4 hide-in-print"
            style={{ fontSize: "14px" }}
            onClick={() => {
              history("/leaderboard-print", { state:{
                game: game,
                dataSource: dataSource,
                rawDataSource: rawDataSource,
                seasonID: seasonID,
                theirTeam: theirTeam,
                pageLoading: pageLoading,
                odk:odk
              }
               
              });
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.73584 11.7188H15.2358M3.98584 6.46875V1.66875C3.98584 1.42022 4.18731 1.21875 4.43584 1.21875H12.5358C12.7844 1.21875 12.9858 1.42022 12.9858 1.66875V6.46875M15.2358 14.1188V10.4688C15.2358 8.25961 13.445 6.46875 11.2358 6.46875H5.73584C3.5267 6.46875 1.73584 8.25961 1.73584 10.4688V14.1187C1.73584 14.4501 2.00447 14.7188 2.33584 14.7188H14.6358C14.9672 14.7188 15.2358 14.4501 15.2358 14.1188Z"
                stroke="#0F1A24"
                strokeWidth="1.5"
              />
            </svg>
            <span>&nbsp;&nbsp;Print Leaderboard</span>
          </span>
          {odk === "team" && (
            <div className="w-full flex flex-col gap-4 mt-4">
              {generateTeamLeaderboard(dataSource)}

              <h2 className="mt-[4px]  mb-[1px] ml-[5px] text-[18px]  font-bold">
                Weekly Award Winners
              </h2>
              <WardboardScreen
                seasonID={seasonID}
                pageLoading={pageLoading}
                games={game}
              />
            </div>
          )}

          <div className="mt-4 flex gap-4 flex-wrap">
            {odk === "o" &&
              generateOffenseLeaderboard(dataSource, rawDataSource)}
            {odk === "d" && generateDefenseLeaderboard(dataSource)}
            {odk === "kicker" && generateSpecialTeamsLeaderboard(dataSource)}
          </div>
        </Card>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
