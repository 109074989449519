import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
import { connect } from "react-redux";

const { Option } = Select;

const ManageSharingModal = ({
  visible,
  onClose,
  coachesList,
    onShareChart,
    title,
  getPreviouslySharedCoaches,
    chartId,
  share,
}) => {
  const [selectedCoaches, setSelectedCoaches] = useState([]);
    const [previouslySharedCoaches, setPreviouslySharedCoaches] = useState([]);
    
  const fetchPreviouslySharedCoaches = () => {
   getPreviouslySharedCoaches({
      params: { depth_chart_id: chartId },
      callback: (res, data) => {
        if (res) {
            console.log('fetchPreviouslySharedCoaches',data?.data)
              const result = data?.data?.map(({ coach_id }) => coach_id)
              
            setSelectedCoaches(result);
            console.log('prev', result)
        }
        
      },
    });
  };
    
  useEffect(() => {
    fetchPreviouslySharedCoaches()
  }, [chartId]); 
    
    
    const handleShareChart = (coach_id) => {
      console.log('coach_id',coach_id)

        const id = coach_id[coach_id.length - 1];
        if (!selectedCoaches.includes(id)) {
            selectedCoaches.push(id)
            setSelectedCoaches(selectedCoaches);
        
            share({
                params: {
                    coach_id: id,
                    depth_chart_id: chartId,
                },
                callback: (res, data) => {
                    if (res) {
                        console.log(data?.data)
                    }
                },
            });
        }
    // onShareChart(selectedCoaches);
    // onClose();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
    
      onOk={onClose}
         footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={onClose} 
        >
          Save
        </Button>,
      ]}
      >
          <br />
          <b>Shared with:</b> 
      <Select
        mode="multiple"
        placeholder="Select coaches to share with"
              style={{ width: "100%" }}
              defaultValue={selectedCoaches}
        onChange={(value) => handleShareChart(value)}
      >
        {coachesList.map((coach) => (
          <Option key={coach.id} value={coach.id}>
            {coach.first_name} {coach.last_name}
          </Option>
        ))}
      </Select>

      <hr />
    </Modal>
  );
};

const mapStateToProps = (state) => ({ 
});

const mapDispatchToProps = (dispatch) => ({
 getPreviouslySharedCoaches: (details) =>
        dispatch(global.redux.action.depth_chart.shared(details)), 
     share: (details) =>
        dispatch(global.redux.action.depth_chart.share(details)), 
 getDepthChartPlayers: (details) =>
    dispatch(global.redux.action.depth_chart.players(details)), 
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSharingModal); 
