import React from "react";
import { Space, Table, Tag } from "antd";
const { Column, ColumnGroup } = Table;

const DefensiveStatsTable = ({ dataSource }) => (
  <Table dataSource={dataSource}>
    <Column title="First name" dataIndex="first_name" key="first_name" />
    <Column title="Last name" dataIndex="last_name" key="last_name" />
    <Column title="Plays" dataIndex="plays" key="plays" />
    <ColumnGroup
      title="Pstv Impct
 Plays"
    >
      <Column
        title="Tot"
        dataIndex="total_positive_impact"
        key="total_positive_impact"
      />
    </ColumnGroup>

    <ColumnGroup title="Tackles">
      <Column
        title="Tot"
        dataIndex="total_tackles_solo"
        key="total_tackles_solo"
      />
      <Column
        title="TFL"
        dataIndex="total_tackles_tfl"
        key="total_tackles_tfl"
      />
      <Column
        title="Ast"
        dataIndex="total_tackles_ast"
        key="total_tackles_ast"
      />
      <Column
        title="Tot"
        dataIndex="total_tackles_tfl"
        key="total_tackles_tot"
        render={(value, row) =>
          Number(row.total_tackles_ast_halved) +
          Number(row.total_tackles_solo) +
          Number(row.total_missed_tackles)
        }
      />
      <Column
        title="Net 
Tackles"
        dataIndex="total_tackles_solo"
        key="net_tackles"
        render={(value, row) =>
          Number(row.total_tackles_ast_halved) +
          Number(row.total_tackles_solo) -
          Number(row.total_missed_tackles)
        }
      />
      <Column
        title="TFL"
        dataIndex="total_tackles_tfl"
        key="total_tackles_tfl"
      />
      <Column
        title="Yds"
        dataIndex="total_tackles_yds"
        key="total_tackles_yds"
      />
    </ColumnGroup>

    <ColumnGroup title="Sacks">
      <Column
        title="Sak"
        dataIndex="total_tackles_sak"
        key="total_tackles_sak"
      />
      <Column
        title="Yds"
        dataIndex="total_tackles_yds"
        key="total_tackles_yds"
      />
      <Column title="Press" dataIndex="total_hurries" key="total_hurries" />
      <Column
        title="Yds"
        dataIndex="total_hurries_yds"
        key="total_hurries_yds"
      />
    </ColumnGroup>
    <ColumnGroup title="Pass Defense">
      <Column title="Int" dataIndex="total_int" key="total_int" />
      <Column
        title="Yds"
        dataIndex="total_yards_after_interception"
        key="total_yards_after_interception"
      />
      <Column title="PBU" dataIndex="total_pd" key="total_pd" />
    </ColumnGroup>
    <ColumnGroup title="Fumbles">
      <Column
        title="Caus"
        dataIndex="total_caused_fumble"
        key="total_caused_fumble"
      />
      {/* <Column title="Tot" dataIndex="total_positive_impact" key="total_positive_impact" /> 
    <Column title="Caus" dataIndex="total_positive_impact" key="total_positive_impact" />  */}
    </ColumnGroup>
    <ColumnGroup
      title="Turnovers 
Created"
    >
      <Column title="Tot" dataIndex="total_turnovers" key="total_turnovers" />
      <Column
        title="Yds"
        dataIndex="total_positive_impact"
        key="total_positive_impact"
        render={(value, row) =>
          Number(row.total_yards_after_interception) +
          Number(row.total_yards_after_recovery)
        }
      />
      <Column title="TD" dataIndex="total_td" key="total_td" />
    </ColumnGroup>
  </Table>
);
export default DefensiveStatsTable;
