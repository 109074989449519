import {
  Button
} from "antd";
// import { Link } from "react-router-dom";
// import shadow from "../../assets/img/shadow.png";
// import kc from "../../assets/img/kc.png";
// import king from "../../assets/img/king.png";
// import manvel from "../../assets/img/manvel.png";
// import wb from "../../assets/img/wb.png";
// import westlake from "../../assets/img/westlake.png";
// import willis from "../../assets/img/willis.png";
import "./coaches.css";

const TableData = [
  {
    key: '1',
    first_name: 'Joyce',
    last_name: 'Diaz',
    email: 'joyce.diaz@domain.tld',
    location: 'California',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },
  {
    key: '2',
    first_name: 'Arthur',
    last_name: 'Perkins',
    email: 'arthur_95@domain.tld',
    location: 'Alaska',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },
  {
    key: '3',
    first_name: 'Emily',
    last_name: 'Rivera',
    email: 'emilyrivera@domain.tld',
    location: 'Arizona',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },
  {
    key: '4',
    first_name: 'Frank',
    last_name: 'Young',
    email: 'frank_young@domain.tld',
    location: 'Missouri',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },
  {
    key: '5',
    first_name: 'Phillip',
    last_name: 'Gordon',
    email: 'phillip_95@domain.tld',
    location: 'New York',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },
  {
    key: '6',
    first_name: 'Eric',
    last_name: 'Jones',
    email: 'ericjones@domain.tld',
    location: 'Pennsylvania',
    action: <div className="coaches-list-action-btns">
      <Button className="TableActionBtn mr-2 edit">Edit</Button>
      <Button className="TableActionBtn">Delete</Button>
    </div>,
  },


];

export default TableData