import React from "react";
import { Table, Typography } from "antd";

const { Text } = Typography;
function ScoringTable({ columns, dataSource, loading, print, printColumns }) {
  const getRowClassName = (record) => {
    return record.highlight ? "highlight-row" : "";
  };
  return (
    <div className="stats-table scoring-table print:landscape">
      <Table
        columns={print ? printColumns : columns}
        dataSource={dataSource}
        rowClassName={getRowClassName}
        pagination={false}
        loading={loading}
        summary={(pageData) => {
          let total_assignment_executed = 0;
          let total_great_effort = 0;
          let total_grade = 0;
          let total_rbtd = 0;
          let total_wrtd = 0;
          let total_fum_ret_td = 0;
          let total_int_ret_td = 0;
          let total_punt_return_td = 0;
          let total_kickoff_return_td = 0;
          let total_td = 0;
          let total_td_points = 0;
          let total_pat_kicks_made = 0;
          let total_pat_kicks_att = 0;
          let total_pat = 0;
          let total_two_part_conversion = 0;
          let total_two_part_conversion_points = 0;
          let total_field_goals_made = 0;
          let total_field_goals_att = 0;
          let max_field_goal_distance = 0;
          let total_field_goal_points = 0;
          let total_safety = 0;
          let total_safety_points = 0;
          let total_points = 0;

          pageData?.forEach((data) => {
            total_assignment_executed += Number(
              data?.total_assignment_executed
            );
            total_great_effort += Number(data?.total_great_effort);
            total_grade +=
              (Number(data?.total_assignment_executed) +
                Number(data?.total_great_effort)) /
              2;
            total_rbtd += Number(data?.total_rbtd);
            total_wrtd += Number(data?.total_wrtd);
            total_fum_ret_td += Number(data?.total_fum_ret_td);
            total_int_ret_td += Number(data?.total_int_ret_td);
            total_punt_return_td += Number(data?.total_punt_return_td);
            total_kickoff_return_td += Number(data?.total_kickoff_return_td);
            total_td +=
              Number(data?.total_rbtd) +
              Number(data?.total_wrtd) +
              Number(data?.total_fum_ret_td) +
              Number(data?.total_int_ret_td) +
              Number(data?.total_punt_return_td) +
              Number(data?.total_kickoff_return_td);
            total_td_points +=
              (Number(data?.total_rbtd) +
                Number(data?.total_wrtd) +
                Number(data?.total_fum_ret_td) +
                Number(data?.total_int_ret_td) +
                Number(data?.total_punt_return_td) +
                Number(data?.total_kickoff_return_td)) *
              6;
            total_pat_kicks_made += Number(data?.total_pat);
            total_pat_kicks_att +=
              Number(data?.total_pat) +
              Number(data?.total_missed_pat) +
              Number(data?.total_block_pat);
            total_pat += Math.round(Number(data?.total_pat) * 1);
            total_two_part_conversion += Number(
              data?.total_two_part_conversion
            );
            total_two_part_conversion_points +=
              Number(data?.total_two_part_conversion) * 2;
            total_field_goals_made += Number(data?.total_field_goals);
            total_field_goals_att +=
              Number(data?.total_block_field_goals) +
              Number(data?.total_missed_field_goals) +
              Number(data?.total_field_goals);
            max_field_goal_distance += Number(data?.max_field_goal_distance);
            total_field_goal_points += Number(data?.total_field_goals) * 3;
            total_safety += Number(data?.total_safety);
            total_safety_points += Number(data?.total_safety) * 2;
            total_points +=
            (Number(data?.total_rbtd) +
            Number(data?.total_wrtd) +
            Number(data?.total_fum_ret_td) +
            Number(data?.total_int_ret_td) +
            Number(data?.total_punt_return_td) +
            Number(data?.total_kickoff_return_td)) *
            6 +
            Number(data?.total_pat) * 1 +
            Number(data?.total_safety) * 2 +
            Number(data?.total_two_part_conversion) * 2 +
            Number(data?.total_field_goals) * 3;

            //   if (
            //     !isNaN(data?.total_gn_carries_yds) &&
            //     !isNaN(data?.total_carries) &&
            //     Number(data?.total_carries) !== 0
            //   ) {
            //     total_gn_carries_yds_avg +=
            //       Number(data?.total_gn_carries_yds) /
            //       Number(data?.total_carries);
            //   }
          });
          return (
            <>
              <Table.Summary.Row
                align="center"
                style={{
                  background: "#F3F5F7",
                  fontWeight: "bold",
                }}
              >
                <Table.Summary.Cell index={0} colSpan={print ? 1 : 3}>
                  <Text> Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_assignment_executed}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_great_effort}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_grade?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_rbtd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_wrtd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fum_ret_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_int_ret_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_return_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_td_points}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_pat_kicks_made}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_pat_kicks_att}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_pat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_two_part_conversion}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_two_part_conversion_points}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_field_goals_made}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_field_goals_att}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {max_field_goal_distance}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_field_goal_points}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_safety}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_safety_points}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_points}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default ScoringTable;
