import React, { useState } from "react";

import { Card, Dropdown, Menu } from "antd";
import share from "../../assets/img/share-android.svg";
import download from "../../assets/img/download.svg";
import mail from "../../assets/img/mail-out.svg";
import printer from "../../assets/img/printer.svg";
import ConsistentScreen from "./SeasonAnalyticDashboard";
import SpecificScreen from "./SpecificScreen";
import { useNavigate } from "react-router-dom";
import PlayerAnalyticsDashboard from "./PlayerAnalyticsDashboard";
import AdvantageOnly from "../../components/permissions/AdvantageOnly";

function Index() {
  const [playernotification, setPlayerNotification] = useState(false);
  const history = useNavigate();
  const [structure, setStructure] = useState("Team");

  const openPlayerNotification = () => {
    setPlayerNotification(true);
  };

  const shareMenu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<img src={mail} alt="image" />}
        style={{ color: "#8698a9", fontSize: "14px" }}
      >
        Send via email
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<img src={download} alt="image" />}
        style={{ color: "#8698a9", fontSize: "14px" }}
      >
        Download
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <div className="w-full flex justify-between items-center  ">
        <div className="flex items-center">
          <span className="text-[23px] xl:text-[1.7vw] font-bold">
            Season Analytics
          </span>
          <div className="flex items-center ml-2">
            {/* consistent button */}
            <button
              className={` px-4 py-[0.65rem] ${
                structure == "Team"
                  ? " text-[#ffffff] bg-[#2E4D6B]"
                  : " text-[#2E4D6B]  bg-[#FFFFFF]"
              }  font-bold text-[14px] cursor-pointer focus:outline-0 rounded-l-[8px]`}
              onClick={() => setStructure("Team")}
            >
              Team
            </button>
            <button
              className={`px-4 py-[0.65rem] ${
                structure == "Group"
                  ? "text-[#ffffff] bg-[#2E4D6B]"
                  : "  text-[#2E4D6B] bg-[#FFFFFF]"
              }   font-bold text-[14px] cursor-pointer focus:outline-0 rounded-r-[8px]`}
              onClick={() => setStructure("Group")}
            >
              {" "}
              Group
            </button>
          </div>
        </div>
       
        {/* <button
          className="text-[14px] font-[700] text-[#1a91FF] w-[200px] h-[43px] rounded-[4px] focus:outline-0"
          style={{ border: "1px solid #1a91FF" }}
          onClick={() => history("/player-analytic-dashboard")}
        >
          View Player Stats
        </button> */}
      </div>
      <div className="w-full mt-[10px]">
        <AdvantageOnly >
        <Card
          className="w-[100%] flex flex-col"
          bodyStyle={{ padding: "16px" }}
        >
          {structure == "Team" ? (
            <ConsistentScreen />
          ) : (
            <PlayerAnalyticsDashboard />
          )}
        </Card>
      </AdvantageOnly>
      </div>
    </div>
  );
}
 

export default Index