import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Select,
  Spin,
  notification,
  Upload,
  Avatar,
  Button,
  DatePicker,
  Radio,
  Input,
  Form,
  message,
} from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import ButtonUI from "../../components/ButtonUI";

const { TextArea } = Input;

function Profile({ gridironDetails, coachProfile, updateCoach, getProfile }) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(coachProfile?.photo);
  const [formValues, setFormValues] = useState({
    first_name: coachProfile?.first_name || "",
    last_name: coachProfile?.last_name || "",
    email: coachProfile?.email || "",
    phone: coachProfile?.phone || "",
    date_of_birth: coachProfile?.date_of_birth
      ? moment(coachProfile?.date_of_birth)
      : null,
    address: coachProfile?.address || "",
    city: coachProfile?.city || "",
    state: coachProfile?.state || "",
    country: coachProfile?.country || "",
    zip_code: coachProfile?.zip_code || "",
    bio: coachProfile?.bio || "",
    positions: coachProfile?.positions || [],
  });

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      date_of_birth: date,
    }));
  };

  const handlePositionsChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      positions: value,
    }));
  };

  const handleUpdateCoach = () => {
    setLoading(true);
    console.log(formValues);
    let body = {
      ...formValues,
      positions: formValues?.positions?.toString(),
    };
    updateCoach({
      body,
      callback: (res, responseData) => {
        if (res == true) {
          message.success("Profile updated successfully");
          setEditMode(false);
          getProfile();
        }
        console.log(res);
        setLoading(false);
      },
    });
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setAvatarUrl(info.file.response.url);
      setLoading(false);
      notification.success({ message: "Avatar updated successfully!" });
    }
  };

  return (
    <Form className="w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] pl-4">
      <div className="flex items-center justify-between mt-[4px] xl:mt-[0.5vw]">
        {" "}
        <span className=" text-[23px] xl:text-[1.7vw] font-bold">
          My Profile
        </span>
        {!editMode && (
          <span
            className="cursor-pointer text-[#1A91FF] text-[18px] 2xl:text-[1.25vw] font-inter font-bold"
            onClick={() => setEditMode(true)}
          >
            Edit
          </span>
        )}
      </div>

      <div className="mt-[10px] flex flex-col">
        <div className="flex items-center mb-4">
          <Avatar size={64} src={avatarUrl} />
          <Upload
            name="avatar"
            showUploadList={false}
            action="/upload"
            onChange={handleUpload}
          >
            <Button icon={<UploadOutlined />} className="ml-4">
              Change Avatar
            </Button>
          </Upload>
        </div>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              First Name
            </span>
          }
          tooltip="Enter your first name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">First Name:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.first_name}
            onChange={handleInputChange}
            name="first_name"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Last Name
            </span>
          }
          tooltip="Enter your last name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Last Name:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.last_name}
            onChange={handleInputChange}
            name="last_name"
            readOnly={!editMode}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Email
            </span>
          }
          tooltip="Enter your email address"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Email Address:</label> */}
          <Input
            type="email"
            className="w-full mt-2"
            value={formValues.email}
            onChange={handleInputChange}
            name="email"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Phone
            </span>
          }
          tooltip="Enter your phone number"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Phone Number:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.phone}
            onChange={handleInputChange}
            name="phone"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Date of Birth
            </span>
          }
          tooltip="Select your date of birth"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Date of Birth:</label> */}
          <DatePicker
            className="w-full mt-2"
            value={formValues.date_of_birth}
            onChange={handleDateChange}
            name="date_of_birth"
            size="large"
            disabled={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Address
            </span>
          }
          tooltip="Enter your address"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Address:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.address}
            onChange={handleInputChange}
            name="address"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              City
            </span>
          }
          tooltip="Enter your city"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">City:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.city}
            onChange={handleInputChange}
            name="city"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              State
            </span>
          }
          tooltip="Enter your state"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">State:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.state}
            onChange={handleInputChange}
            name="state"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Country
            </span>
          }
          tooltip="Enter your country"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Country:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.country}
            onChange={handleInputChange}
            name="country"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Zip Code
            </span>
          }
          tooltip="Enter your zip code"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Zip Code:</label> */}
          <Input
            className="w-full mt-2"
            value={formValues.zip_code}
            onChange={handleInputChange}
            name="zip_code"
            size="large"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Bio
            </span>
          }
          tooltip="Enter a brief bio"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Bio:</label> */}
          <TextArea
            className="w-full mt-2"
            rows={4}
            value={formValues.bio}
            onChange={handleInputChange}
            name="bio"
            readOnly={!editMode}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-inter !text-black text-[14px] font-normal">
              Positions
            </span>
          }
          tooltip="Select your positions"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {/* <label className="font-inter !text-black text-[14px] font-normal">Positions:</label> */}
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            disabled={!editMode}
            value={formValues.positions}
            onChange={handlePositionsChange}
            placeholder="Select Position(s)"
            className="mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px]"
            optionLabelProp="label"
            options={[
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Defensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.defense[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Offensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.offense[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Special Teams
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
            ]}
          />
        </Form.Item>
        <br />
        <hr />
        {editMode && (
          <Form.Item>
            <div>
              <div>
                <ButtonUI
                  text="Discard"
                  textColor="#000000"
                  background="transparent"
                  buttonFunction={() => {
                    setFormValues({
                      ...coachProfile,
                      date_of_birth: coachProfile?.date_of_birth
                        ? moment(coachProfile?.date_of_birth)
                        : null,
                    });
                    setEditMode(false);
                  }}
                  type={"button"}
                />
              </div>
              <div className="mt-2">
                <ButtonUI
                  text="Save"
                  textColor="#FFFFFF"
                  background="#1A91FF"
                  buttonFunction={() => handleUpdateCoach()}
                  loading={loading}
                  type={"submit"}
                />
              </div>
            </div>
          </Form.Item>
        )}
      </div>
    </Form>
  );
}

export default Profile;
