import React from "react";
import { Modal } from "antd";

function CoachInfoModal({ visible, setVisible, coachInfo }) {
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Coach Info"
      onOk={() => setVisible(false)}
    >
      <div>
        <p>Login details:</p>
        <p>
          <span className="font-bold">Email:</span> {coachInfo?.admin_email}
        </p>
        <p>
          <span className="font-bold">Password:</span> wardbord23
        </p>
      </div>
    </Modal>
  );
}

export default CoachInfoModal;
