import React, { useState } from "react";
import { Modal, Input, Table, Checkbox, Button } from "antd";
import { CheckCircleOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const { TextArea } = Input;

const CheckboxWithBackground = ({ value }) => (
  <div className={value == 1  ? 'bluebackgroundchecks' : 'redBackgroundchecks'}>
    
    <Checkbox checked disabled />
  </div>
);

function checkCarryYards(play_type, total_carries, gn) {
  if (
    (play_type === "Run" || play_type === null || play_type === "") &&
    total_carries === "1" &&
    gn !== null &&
    gn !== ""
  ) {
    return true;
  } else {
    return false;
  }
}
function checkCompletionYdsConditions(play_type, completions, gn) {
  // Check all the conditions
  return (
    (play_type === "Pass" || play_type === null || play_type === "") && // Check play_type conditions
    ["4", "5", "6", "7", "8", "9", "11"].includes(completions) && // Check completions
    gn !== null &&
    gn !== "" // Check gn condition
  );
}

function areCompletionsConditionsMet(play_type, completions, td) {
  return (
    (play_type === "Pass" || play_type === null || play_type === "") &&
    ["4", "5", "6", "7"].includes(completions)
  );
}

function hasQBTouchdown(play_type, qb_td) {
  return (
    (play_type === "Pass" || play_type === null || play_type === "") &&
    qb_td === "1"
  );
}

const shouldDisplay = (value) => {
  
  if (value) { 
    return true;
  } else {
    return false;
    
  }
  
};

const shouldDisplayWithTitle = (value, title) => {
  return (
    <>{shouldDisplay(value) && (
      <span>
      <b>{title}:</b> {value !== "0" ? "✔️" : "❌"}
    </span>
  )} </>)
};
const shouldDisplayWithTitleWithResult = (value, title, result) => {
  return (
    <>{shouldDisplay(value) && (
      <span>
      <b>{title}:</b> {result}
    </span>
  )} </>)
};

const renderBooleanResult = (value) => ({
  children:   <CheckboxWithBackground value={value} />  ,
  props: {},
});

const columns = [
  {
    title: "Execution",
    dataIndex: "assignment_executed",
    width: "6%",
    align: "center",
    render: (value, row) => renderBooleanResult(value),
  },
  {
    title: "Effort",
    dataIndex: "great_effort",
    width: "6%",
    align: "center",
    render: (value, row) => renderBooleanResult(value),
  },
];

export const columnsO = (play) => [
  {
    title: "Player Name",
    dataIndex: "first_name",
    width: "16%",
    align: "center",
    render: (value, row) => `${row.first_name} ${row.last_name}`,
  },
  {
    title: "Position",
    dataIndex: "positions",
    width: "10%",
    align: "center",
    render: (value) => value?.toString(),
  },
  {
    title: "Stats",
    dataIndex: "positions",
    width: "50%",
    align: "center",
    render: (value, row) => {
      return (
        <div>
          
          {shouldDisplayWithTitle(row.total_carries, 'Carries')}
          {shouldDisplayWithTitleWithResult(row.total_carries, 'Carries yds', play?.gn)}
           

          {shouldDisplayWithTitleWithResult([1,3,5,7,11,9].includes(row.completions) , 'reception', 'Drop')}
          {shouldDisplayWithTitleWithResult([2,3,6,7,11,9].includes(row.completions), 'reception', 'Incomplete')}
          {shouldDisplayWithTitleWithResult([4,5,6,7,8,9, 11].includes(row.completions) , 'reception', 'complete')}

          {shouldDisplayWithTitleWithResult(row.completions, 'Reception Yds', play?.gn)}


          {shouldDisplayWithTitleWithResult([4,12,6,7,5].includes(row.receptions) , 'Pass Complete', 'Bad ball')}
          {shouldDisplayWithTitleWithResult([1,3,15,7,11,9,5].includes(row.receptions) , 'Pass Complete', 'Dropped')} 
          {shouldDisplayWithTitleWithResult([8,9,12,13,14,15,11,10].includes(row.receptions) , 'Pass Complete', 'complete')}

          {shouldDisplayWithTitleWithResult(row.receptions, 'Pass Complete Yds', play?.gn)}

          {shouldDisplayWithTitleWithResult(row.yards_after_contact,'YACO',row.yards_after_contact)}

          {shouldDisplayWithTitle(row.qb_td, 'QB TD')}
          {shouldDisplayWithTitle([4,5,6,7].includes(row.completions) && row.td, 'WR TD')}
          {shouldDisplayWithTitle(row.total_carries,'RB TD')}


          {shouldDisplayWithTitle(row.interception, 'Int')}

          {shouldDisplayWithTitle(row.caused_fumble, 'Fb')}
          {shouldDisplayWithTitle(row.fumble_lost, 'FbL')}
          {shouldDisplayWithTitle(row.two_part_conversion, '2Pt')}
          
        </div>
      );
    },
  },
  ...columns,
];
export const columnsK = (play) => [
  {
    title: "Player Name",
    dataIndex: "first_name",
    width: "16%",
    align: "center",
    render: (value, row) => `${row.first_name} ${row.last_name}`,
  },
  {
    title: "Position",
    dataIndex: "positions",
    width: "10%",
    align: "center",
    render: (value) => value?.toString(),
  },
  {
    title: "Stats",
    dataIndex: "positions",
    width: "50%",
    align: "center",
    render: (value, row) => {
      return (
        <div>
          {shouldDisplay(row.field_goals) && (
            <span>
              <b>FGMade:</b> {row.field_goals == "4" ? "✔️" : "❌"}
            </span>
          )} 
          {shouldDisplay(row.field_goals) && (
            <span>
              <b>Blocked FG:</b> {row.field_goals == "2" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.field_goals) && (
            <span>
              <b>Missed FG:</b> {row.field_goals == "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplayWithTitleWithResult(row.field_goal_distance, 'FG Distance', row.field_goal_distance)}
          
          
          {shouldDisplayWithTitle(row.punts, 'Punts')}
          {shouldDisplayWithTitleWithResult(row.punt_distance, 'Punt Distance', row.punt_distance)}
          
          {shouldDisplay(row.punt_fair_catch) && (
            <span>
              <b>Punt Fair C:</b> {row.punt_fair_catch !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.punt_inside_20) && (
            <span>
              <b>Ins 20:</b> {row.punt_inside_20 !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.pat) && (
            <span>
              <b>Missed PAT:</b> {row.pat == "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.pat) && (
            <span>
              <b>Blocked PAT:</b> {row.pat == "2" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.pat) && (
            <span>
              <b>PAT Kick Made:</b> {row.pat == "4" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff) && (
            <span>
              <b>Kickoff:</b> {row.kickoff !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_touchback) && (
            <span>
              <b>Tch B:</b> {row.kickoff_touchback !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_fair_catch) && (
            <span>
              <b>Kickoff Fair C:</b>{" "}
              {row.kickoff_fair_catch !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.onsidekick) && (
            <span>
              <b>Onside Kicks:</b> {row.onsidekick !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.recovered_fumble) && (
            <span>
              <b>Rcv:</b> {row.recovered_fumble !== "0" ? "✔️" : "❌"}
            </span>
          )}
        </div>
      );
    },
  },
  ...columns,
];
export const columnsD = (play) => [
  {
    title: "Player Name",
    dataIndex: "first_name",
    width: "16%",
    align: "center",
    render: (value, row) => `${row.first_name} ${row.last_name}`,
  },
  {
    title: "Stats",
    dataIndex: "positions",
    width: "50%",
    align: "center",
    render: (value, row) => {
      return (
        <div onClick={() => console.log(row)}>
         
             {row.tackles == "1"? (   <span>
                <b>Solo:</b> {row.tackles == "1" ? "✔️" : "❌"}
              </span>) : '' }
              {row.tackles == "3"? ( <span>
                <b>Ast:</b> {row.tackles == "3" ? "✔️" : "❌"}
              </span>) : '' }
             
              {row.tackles == 0 ? (  <span>
                <b>Missed Tackles:</b> {row.tackles == "0" ? "✔️" : "❌"}
              </span>) : '' }
           
      
          { row.sacks ===  0 ? (
            <span>
              <b>TFL:{play.gn}</b> 
            </span>
          ) : ''}
          

{shouldDisplayWithTitleWithResult(row.sacks, 'Tackle Yds', play.gn)}

          {shouldDisplay(row.sacks) && (
            <span>
              <b>Saks:</b> {row.sacks == "1" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.sacks) && (
            <span>
              <b>Sacks Yds:</b>{" "}
              {row.sacks == "1" && play.gn !== null && play.gn !== ""
                ? "✔️"
                : "❌"}
            </span>
          )}
          {shouldDisplay(row.sacks) && (
            <span>
              <b>Press:</b> {row.sacks == "2" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.sacks) && (
            <span>
              <b>Hurry Yards:</b>{" "}
              {row.sacks == "2" && play.gn !== null && play.gn !== ""
                ? "✔️"
                : "❌"}
            </span>
          )}
          {shouldDisplay(row.interception) && (
            <span>
              <b>Int:</b> {row.interception == "1" ? "✔️" : "❌"}
            </span>
          )}
          
          {shouldDisplayWithTitleWithResult(row.interception, 'Int Yds', row.yards_after_interception )}

          {shouldDisplay(row.pbu_pass_breakup) && (
            <span>
              <b>PBU:</b> {row.pbu_pass_breakup == "1" ? "✔️" : "❌"}
            </span>
          )}
          {/* {shouldDisplay(row.td) && (
            <span>
              <b>INT RET TD:</b> {row.td == "1" ? "✔️" : "❌"}
            </span>
          )}
           */}
{shouldDisplayWithTitle(row.caused_fumble, 'Caused Fumble' )}
{shouldDisplayWithTitle(row.recovered_fumble, 'Recovered Fumble' )}
         
          {/* {shouldDisplay(row.yards_after_recovery) && (
            <span>
              <b>Fumble Recovery Yds:</b>{" "}
              {row.yards_after_recovery == "1" ? "✔️" : "❌"}
            </span>
          )} */}
         
          {shouldDisplay(row.safety) && (
            <span>
              <b>Safety:</b> {row.safety == "1" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplayWithTitle(row.td, 'TD' )}
          
        </div>
      );
    },
  },
  ...columns,
];
export const columnsST = (play) => [
  {
    title: "Player Name",
    dataIndex: "first_name",
    width: "16%",
    align: "center",
    render: (value, row) => `${row.first_name} ${row.last_name}`,
  },
  {
    title: "Position",
    dataIndex: "positions",
    width: "10%",
    align: "center",
    render: (value) => value?.toString(),
  },
  {
    title: "Stats",
    dataIndex: "positions",
    width: "50%",
    align: "center",
    render: (value, row) => {
      return (
        <div>

{shouldDisplayWithTitle(row.punt_return, 'Punt Return' )}
{shouldDisplayWithTitle(row.punt_return, 'Punt Return Yds', play.gn )}
         
          
          {shouldDisplay(row.punt_return_fair_catch) && (
            <span>
              <b>Punt Return Fair C:</b> {row.punt_return !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.punt_return_fair_catch) && (
            <span>
              <b>No Catch:</b> {row.punt_return_fair_catch == "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.punt_return) && (
            <span>
              <b>Punt Return TD:</b>{" "}
              {row.punt_return == "1" && row.return_td !== null ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_return) && (
            <span>
              <b>Kickoff Returns:</b>{" "}
              {row.kickoff_return == "1" && row.kickoff_fair_catch == "0"
                ? "✔️"
                : "❌"}
            </span>
          )}

          {shouldDisplay(row.kickoff_return) && (
            <span>
              <b>Kickoff Return Yds:</b>{" "}
              {row.kickoff_return == "1" && play.gn !== null && play.gn !== ""
                ? "✔️"
                : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_return) && (
            <span>
              <b>Kickoff Return Lng:</b>{" "}
              {row.kickoff_return == "1" && play.gn !== null && play.gn !== ""
                ? "✔️"
                : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_return) && (
            <span>
              <b>Kickoff TB Fair C:</b>{" "}
              {row.kickoff_return == "1" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.kickoff_return) && (
            <span>
              <b>Kickoff TD:</b> {row.kickoff_return == "1" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.tackles) && (
            <span>
              <b>Solo Tackles:</b> {row.tackles !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.tackles) && (
            <span>
              <b>Ast:</b> {row.tackles == "3" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.tackles) && (
            <span>
              <b>Missed Tackles:</b> {row.tackles == "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.punt_blocks) && (
            <span>
              <b>Punt Block:</b> {row.punt_blocks !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.fg_blocks) && (
            <span>
              <b>FG Block:</b> {row.fg_blocks !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.caused_fumble) && (
            <span>
              <b>Caused Fumble:</b> {row.caused_fumble !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.recovered_fumble) && (
            <span>
              <b>F-Rcv:</b> {row.recovered_fumble !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.yards_after_recovery) && (
            <span>
              <b>YAR:</b> {row.yards_after_recovery !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.onsidekick_recovered) && (
            <span>
              <b>O-Rcv:</b> {row.onsidekick_recovered !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.st_td) && (
            <span>
              <b>ST TD:</b> {row.st_td !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.fumble) && (
            <span>
              <b>Fb:</b> {row.fumble !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.fumble_lost) && (
            <span>
              <b>FbL:</b> {row.fumble_lost !== "0" ? "✔️" : "❌"}
            </span>
          )}
          {shouldDisplay(row.two_part_conversion) && (
            <span>
              <b>2Pt:</b> {row.two_part_conversion !== "0" ? "✔️" : "❌"}
            </span>
          )}
        </div>
      );
    },
  },
  ...columns,
];
