const { default: coreUnitsRedux } = require("../../redux");

const { default: coreUnitsAPI } = require("../../api");

const { default: coreUnitsUtils } = require("../../utils");

const { default: coreUnitsRegisterApi} = require("../../api/register");

global.redux = {
    ...coreUnitsRedux
};

global.api = {
    ...coreUnitsAPI
};

global.registerApi = {
    ...coreUnitsRegisterApi
}

global.utils = {
    ...coreUnitsUtils
};



export default global