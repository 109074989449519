import React, { useState, useEffect } from "react";
import { Card, Select, Tooltip, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import trashIcon from "../../../assets/img/trash.png";
import { connect } from "react-redux";
import DeleteModals from "./DeletesModal";

const { Option } = Select;

function ScoutingReport(props) {
  const history = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  console.log("item", item);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    if (currentIndex < filteredScoutingReportData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const paramsPosition = "";
  const [odk, setOdk] = useState("o");
  const [gradesData, setGradesData] = useState([]);
  const [positionsArray, setPositionsArray] = useState(
    paramsPosition !== "" ? [paramsPosition] : ["QB"]
  );
  const [position, setPosition] = useState("QB");
  const [scoutingReportData, setScoutingReportData] = useState([]);
  const [gameLoading, setGameLoading] = useState(true);
  const [filteredScoutingReportData, setFilteredScoutingReportData] = useState(
    []
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const ToglePositionsArray = (odk, positionsArray, position) => {
    setOdk(odk);
    setPositionsArray([positionsArray]);
    setPosition(position);
  };

  const handleFetchScoutingReport = async () => {
    setGameLoading(true);
    try {
      await props.fetchScoutingReport({
        params: {
          game: item?.id,
        },
        callback: (res, data) => {
          if (res) {
            setScoutingReportData(data?.data || []);
            console.log("scouting report data", data?.data);
            const filteredData = data?.data?.filter((item) =>
              item?.positions?.split(",").includes(position)
            );

            console.log("filtered data", filteredData);
            setFilteredScoutingReportData(filteredData || []);
          }
          setGameLoading(false);
        },
      });
      setGameLoading(false);
    } catch (error) {
      console.log("error from scouting report", error);
      setGameLoading(false);
    }
  };
  useEffect(() => {
    if (item?.id) {
      handleFetchScoutingReport();
    }
  }, [item?.id, position]);

  return (
    <Spin size="large" spinning={gameLoading}>
      <div className="p-[1%] bg-white w-[98%]">
        <h6 className="text-[16px] md:text-[24px] lg:text-[28px] font-poppins font-bold h-[10%] cursor-pointer">
          Scouting Report
        </h6>
        <div className="flex items-center py-4">
          {/* aLL, O, D, K BUTTONS */}
          <div className="w-[139px] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around mr-[16px]">
            <button
              onClick={() => ToglePositionsArray("o", ["QB"], "QB")}
              className={
                (odk == "o" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              O
            </button>
            <button
              onClick={() => ToglePositionsArray("d", ["DL"], "DL")}
              className={
                (odk == "d" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              D
            </button>
            <button
              onClick={() => ToglePositionsArray("k", ["K"], "K")}
              className={
                (odk == "k" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              K
            </button>
          </div>
          {odk == "o" && (
            <div className="w-[215px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => ToglePositionsArray("o", ["QB"], "QB")}
                className={
                  (position == "QB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
                }
              >
                QB
              </button>
              <button
                onClick={() => ToglePositionsArray("o", ["OL"], "OL")}
                className={
                  (position == "OL"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                OL
              </button>
              <button
                onClick={() => ToglePositionsArray("o", ["WR"], "WR")}
                className={
                  (position == "WR"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                WR
              </button>
              <button
                onClick={() => ToglePositionsArray("o", ["TE"], "TE")}
                className={
                  (position == "TE"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                TE
              </button>
              <button
                onClick={() => ToglePositionsArray("o", ["RB"], "RB")}
                className={
                  (position == "RB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                RB
              </button>
            </div>
          )}
          {odk == "d" && (
            <div className="w-[172px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => ToglePositionsArray("d", ["DL"], "DL")}
                className={
                  (position == "DL"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
                }
              >
                DL
              </button>
              <button
                onClick={() => ToglePositionsArray("d", ["LB"], "LB")}
                className={
                  (position == "LB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                LB
              </button>
              <button
                onClick={() => ToglePositionsArray("d", ["S"], "S")}
                className={
                  (position == "S"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                S
              </button>
              <button
                onClick={() => ToglePositionsArray("d", ["C"], "C")}
                className={
                  (position == "C"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                C
              </button>
            </div>
          )}
          {odk == "k" && (
            <div className="w-[86px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => ToglePositionsArray("k", ["ST"], "ST")}
                className={
                  (position == "ST"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                ST
              </button>
              <button
                onClick={() => ToglePositionsArray("k", ["K"], "K")}
                className={
                  (position == "K"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                K
              </button>
            </div>
          )}
        </div>
        <div className="w-full">
      {filteredScoutingReportData &&
      filteredScoutingReportData.length > 0 &&
      filteredScoutingReportData.some((item) => item !== undefined) ? (
        <div>
          {/* Navigation Arrows */}
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className={`p-2 ${
                currentIndex === 0
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-600"
              }`}
            >
              ← Previous
            </button>
            <span className="font-bold">
              {currentIndex + 1} of {filteredScoutingReportData.length}
            </span>
            <button
              onClick={handleNext}
              disabled={currentIndex === filteredScoutingReportData.length - 1}
              className={`p-2 ${
                currentIndex === filteredScoutingReportData.length - 1
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-600"
              }`}
            >
              Next →
            </button>
          </div>

          {/* Current Item */}
          {filteredScoutingReportData[currentIndex] && (
            <div className="w-full">
              <div className="flex items-center justify-between w-full">
                <h5>{filteredScoutingReportData[currentIndex]?.description}</h5>
                <Tooltip
                  title="Delete"
                  placement="top"
                  onClick={() => {
                    if (!filteredScoutingReportData[currentIndex]?.url) {
                      return;
                    }
                    setShowDeleteModal(true);
                    console.log(filteredScoutingReportData[currentIndex]?.url);
                  }}
                >
                  <img
                    src={trashIcon}
                    className="w-[15px]"
                    alt="Trash Icon"
                  />
                </Tooltip>
              </div>
              <Card bodyStyle={{ padding: "12px" }} className="mt-8">
                <div className="font-bold">
                  {filteredScoutingReportData[currentIndex] &&
                    position &&
                    `${position} Scouting Report (Uploaded on ${formatDate(
                      filteredScoutingReportData[currentIndex]?.created_at
                    )})`}
                </div>
                {filteredScoutingReportData[currentIndex]?.url ? (
                  <div className="iframe-loading mt-8">
                    <iframe
                      src={`http://docs.google.com/gview?url=${filteredScoutingReportData[currentIndex]?.url}&embedded=true`}
                      height="800px"
                      width="100%"
                    ></iframe>
                  </div>
                ) : (
                  <div>No URL available for this report.</div>
                )}
              </Card>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center flex items-center justify-center h-[500px]">
          No data available
        </div>
      )}
    </div>

        <DeleteModals
          onOpen={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onDelete={() => {}}
          text={"Are you sure you want to delete this report?"}
        />
      </div>
    </Spin>
  );
}
const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
    gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchScoutingReport: (details) =>
    dispatch(global.redux.action.games.fetchScoutingReport(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoutingReport);
