import React, { useEffect, useState } from "react";
import { Card, Select, message, Table } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import searchicon from "../../assets/img/search.svg";
import ButtonUI from "../../components/ButtonUI";
import SmallButtonUI from "../../components/SmallButton";

const { Option } = Select;
function Coach(props) {
  const [coachesList, setCoachesList] = useState([]);
  const [coachesListLoading, setCoachesListLoading] = useState(false);
  const [coachesStockList, setCoachesStockList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [positionscharts, setPositionscharts] = useState([]);
  const [affiliateCreating, setAffiliateCreating] = useState({});

  const filterCoachsByPosition = (position) => {
    if (position === "All Positions") {
      setCoachesList(coachesStockList);
      return;
    }
    const search = coachesStockList.filter((item) => {
      return item.positions.includes(position);
    });
    setCoachesList(search);
  };

  function filterByCoachName(array, searchText) {
    const regex = new RegExp(searchText, "i"); // 'i' flag makes the search case-insensitive
    return array.filter((obj) => regex.test(obj.first_name) || regex.test(obj.last_name));
  }
  const filterCoach = (text) => {
    const search = filterByCoachName(coachesStockList, text);
    console.log(search.length, text, search);
    setCoachesList(search);
  };

  const getCoachesList = () => {
    setCoachesListLoading(true);
    message.info("Fetching Coach list please wait");
    props.coachesList({
      params: { limit: 1000, page: 1 },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from coachesList", data?.data);
          setCoachesList(data?.data || []);
          setCoachesStockList(data?.data || []);
        }
        setCoachesListLoading(false);
      },
    });
  };

  const handleCreateAffiliate = (id, first_name, last_name, email) => {
    setAffiliateCreating((prevMap) => ({
      ...prevMap,
      [id]: true,
    }));
    props.createAffiliate({
      body: {
        first_name,
        last_name,
        email,
      },
      callback: (res, data) => {
        if (res === true) {
          message.success("Affiliate created successfully");
          getCoachesList();
        } else {
          message.error(data.message);
        }
        setAffiliateCreating((prevMap) => ({
          ...prevMap,
          [id]: false,
        }));
      },
    });
  };

  useEffect(() => {
    gridironDetails();
  }, []);

  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = [];

          let stock = data.data.data.positions;
          setPositionscharts(data?.data?.data);
          console.log("stock", stock);

          let offense = stock.offense.map(({ code }) => code);
          let defense = stock.defense.map(({ code }) => code);
          let special_teams = stock.special_teams.map(({ code }) => code);

          final_data = [
            {
              value: special_teams.toString(),
              title: "Special Unit (" + special_teams.toString() + ")",
            },
            {
              value: defense.toString(),
              title: "Defense Unit (" + defense.toString() + ")",
            },
            {
              value: offense.toString(),
              title: "Offense Unit (" + offense.toString() + ")",
            },
          ];

          _.chain(data)
            .get("data.data.positions")
            .pick(["offense", "defense", "special_teams"])
            .values()
            .flatten()
            .map("code")
            .value()
            .map((val) => {
              final_data.push({
                value: val,
                title: val,
              });
            });
          console.log("final_data", final_data);
          setPositionsList(final_data);
        }
      },
    });
  };

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const deleteCoach = (id, status) => {
    setCoachesListLoading(true);
    console.log("status", status);
    message.info("Deleting Coach.....");
    props.changeStatus({
      params: {
        id: id,
        status: status ? status : 3,
        status_glossary: "Deleted",
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("Deleted", data);
          message.success("Coach deleted successfully");
          getCoachesList();
        }
        setCoachesListLoading(false);
      },
    });
    setCoachesListLoading(false);
  };
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",
      className: "font-medium",
      width: "15%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      align: "center",
      className: "font-medium",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "15%",
    },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      align: "center",
      width: "15%",
      render: (text, row) =>
        text ? `coach.wardbord.com/register?via=${text}` : "---",
    },

    // {
    //   title: "Plan",
    //   dataIndex: "plan",
    //   key: "plan",
    //   align: "center",
    //   render: (text) => (
    //     <div
    //       className={
    //         text === "Free"
    //           ? "text-[#00B21E] bg-[#00B21E] py-2 w-[64px] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold"
    //           : text === "Mid tier"
    //           ? "text-[#1A91FF] bg-[#1A91FF] bg-opacity-10  py-2 w-[64px] mx-auto rounded-lg text-[10px] font-bold "
    //           : "text-[#A40E17] bg-[#A40E17]  py-2 w-[64px] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold "
    //       }
    //     >
    //       {text}
    //     </div>
    //   ),
    // },
    {
      title: "School Name",
      dataIndex: "school",
      key: "school",
      align: "center",
      className: "font-[400]",
      render: (text, item) => <div>{item?.school?.name}</div>,
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "15%",
      render: (text, row) => (
        <div className="flex gap-1 flex-col items-center justify-center">
          <SmallButtonUI
            text="Delete"
            background={"#2E4D6B"}
            buttonFunction={() => deleteCoach(text)}
          />
          {!row?.referral_code && (
            <SmallButtonUI
              text="Add as Affiliate"
              background={"#2E4D6B"}
              buttonFunction={() =>
                handleCreateAffiliate(
                  text,
                  row?.first_name,
                  row?.last_name,
                  row?.email
                )
              }
              width="95%"
              xlWidth={'90%'}
              loading={affiliateCreating[text]}
            />
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCoachesList();
  }, []);

  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Coaches
      </h2>
      <Card bodyStyle={{ padding: "24px" }}>
        <div className="flex items-center">
          <div
            className="px-2 py-2 items-center justify-between flex w-[30%] md:w-[200px] mr-[4px] xl:w-[20%] rounded-md"
            style={{ border: "1px solid #EAEDF0" }}
          >
            <input
              type="text"
              placeholder="Search Coach Name"
              className="outline-0 w-[85%]"
              // value={searchText}
              onChange={(e) => filterCoach(e.target.value)}
            />
            <img src={searchicon} className=" w-[10%]" />
          </div>
          <div className="w-[200px] h-[43px] ml-[4px] border-[1px] border-solid border-[#EAEDF0] font-inter flex items-center justify-center xl:w-[20%] rounded-md">
            <Select
              bordered={false}
              onChange={(value) => {
                // setSelectedPosition(value);
                filterCoachsByPosition(value);
              }}
              // optionLabelProp="label"
              className="w-[100%] text-[14px]"
              options={[
                {
                  label: "All Positions",
                  value: "All Positions",
                },
                ...(positionscharts?.player_positions?.offense[0]?.variables?.map(
                  ({ code, name }) => ({
                    label: (
                      <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                        <span className="font-bold">{code}</span>{" "}
                        {`(${capitalizeString(name)})`}
                      </span>
                    ),
                    value: code,
                  })
                ) || []),
                ...(positionscharts?.player_positions?.defense[0]?.variables?.map(
                  ({ code, name }) => ({
                    label: (
                      <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                        <span className="font-bold">{code}</span>{" "}
                        {`(${capitalizeString(name)})`}
                      </span>
                    ),
                    value: code,
                  })
                ) || []),
                ...(positionscharts?.player_positions?.special_teams[0]?.variables?.map(
                  ({ code, name }) => ({
                    label: (
                      <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                        <span className="font-bold">{code}</span>{" "}
                        {`(${capitalizeString(name)})`}
                      </span>
                    ),
                    value: code,
                  })
                ) || []),
              ]}
            ></Select>
          </div>
        </div>
        <div className="mt-[20px]">
          <Table
            columns={columns}
            dataSource={coachesList}
            bordered
            style={{ fontSize: "10px", color: "#0F1A24" }}
            loading={coachesListLoading}
          />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  coachesList: (details) =>
    dispatch(global.redux.action.super_admin.coachesList(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.coaches.changeStatus(details)),
  gridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  createAffiliate: (details) =>
    dispatch(global.redux.action.super_admin.createAffiliate(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coach);
