
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Form, Input, Button, Checkbox, AutoComplete, Select, notification, Spin } from 'antd';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { geocodeByAddress } from 'react-places-autocomplete';
import _ from 'lodash';
import "../school-admin/coaches/coaches.css";
const axios = require("axios");
const { Option } = Select;

const options = [
	{ value: 'Burns Bay Road' },
	{ value: 'Downing Street' },
	{ value: 'Wall Street' },
];

const Step2 = (props) => {
	const coachesId = props.coachDetails.details.id 
	let history = useNavigate();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [address_results, setaddress_results] = useState([])
	const [address_fetching, setaddress_fetching] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false);
	const [positionsList, setPositionsList] = useState([])

	useEffect(() => {
		getGridironDetails()
	}, []);
 
	const lowercaseFirstLetter = (str) =>{

		// converting first letter to lowerCase
		const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());
	
		return lowerCase;
	}
	const onFinish = e => {

		const params = new URLSearchParams(window.location.search);

		let address_parse = JSON.parse(_.get(e, 'location', {}))
		let details = {
			school_admin: coachesId,
			email:lowercaseFirstLetter(_.get(e, 'email')),
			name: _.get(e, 'name'),
			city: _.get(e, 'city'),
			state: _.get(e, 'state'),
			zip_code: _.get(e, 'zip_code'),
			location: `${_.get(address_parse, 'lat')}, ${_.get(address_parse, 'lng')}`,
			address: _.get(address_parse, 'text')
		} 
		setButtonLoading(true)
		props.schoolCreate({
			body: details,
			callback: (res, responseData) => {
				setButtonLoading(false)
				if (res == true) {
					notification.success(
						{ message: 'Success', description: _.get(responseData, 'message') }
					)
					history('/manage-school')
				}
			}
		});
	};
	//Address search
	const fetchAddress = value => {
		setaddress_results([])
		setaddress_fetching(true);
		geocodeByAddress(value).then(body => {
			const address_results = _.map(body, location => ({
				text: location.formatted_address,
				value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
			}));

			setaddress_results(address_results)
			setaddress_fetching(false);

		}).catch(error => {
			setaddress_results([])
			setaddress_fetching(true);
		});
	};
	const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);
	const fetchPostalCode = address_components => {
		let postal_code = null;
		address_components.forEach(value => {
			value.types.forEach(type => {
				if (type === 'postal_code') {
					postal_code = value.long_name
				}
			})
		})
		return postal_code;
	};

	const getGridironDetails = () => {
		props.getGridironDetails({
			callback: (res, data) => {
				if (res === true) {
					let final_data = [] 

					setPositionsList(_.chain(data).get('data.data.positions').pick(['offense', 'defense', 'special_teams']).values().flatten().map('code').value())
				}
			}
		})
	};

	return (
		<div className="CreateCoachForm">
			<div className="container">
				<div className="PlayerprimaryCard">
					<Form
						name="basic"
						initialValues={{ remember: true }}
						autoComplete="off"
						layout="vertical"
						className="row"
						onFinish={onFinish}
					>
						<Form.Item
							className="col-12 col-md-6"
							label="Name"
							name="name"
							rules={[{ required: true, message: 'Please input your name!' }]}

						>
							<Input />
						</Form.Item>
						<Form.Item
							className="col-12 col-md-6"
							label="Email"
							name="email"
							rules={[{ required: true, message: 'Please input your email!' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							className="col-12 col-md-6"
							label="City"
							name="city"
							rules={[{ required: true, message: 'Please input your city!' }]}

						>
							<Input />
						</Form.Item>
						<Form.Item
							className="col-12 col-md-6"
							label="State"
							name="state"
							rules={[{ required: true, message: 'Please input your state!' }]}

						>
							<Input />
						</Form.Item>
						<Form.Item
							className="col-12 col-md-6"
							label="Location"
							name="location"
							rules={[{ required: true, message: 'Please input your location!' }]}
						>
							<Select getPopupContainer={trigger => trigger.parentNode}
								showSearch
								defaultActiveFirstOption={false}
								filterOption={false}
								onSearch={search => search ? addressDelayedQuery(search) : ''}
								//   onChange={(e) => onChangeForm(e, 'address')}
								placeholder={'Search address...'}
								notFoundContent={
									address_fetching ? (
										<Spin size="small" />
									) : null
								}
							>
								{address_results && address_results.length > 0 && address_results.map(d => (
									<Select.Option key={d.value}>{d.text}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							className="col-12 col-md-6"
							label="Zip code"
							name="zip_code"
							rules={[{ required: true, message: 'Please input your Zip code!' }]}

						>
							<Input />
						</Form.Item>
						<Form.Item className="col-12 col-md-12 text-right">
							<Button loading={buttonLoading}
								className='px-4 h-[2.5rem] btn-primary bg-[#1A91FF] text-white'
								style={{ marginRight: "21px" }} type="primary" htmlType="submit">
								Create School
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);

};

const mapStateToProps = state => {
	return {
		coachProfile: _.get(state, 'app.account.profile.data.details'),
	}
};

const mapDispatchToProps = dispatch => ({
	schoolCreate: details => dispatch(global.redux.action.schools.create(details)),
	schoolsList: details => dispatch(global.redux.action.schools.list(details)),
	getGridironDetails: details => dispatch(global.redux.action.grades.gridironDetails(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);