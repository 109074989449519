import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Input,
  Collapse,
  notification,
  Modal,
  Checkbox,
  Form,
} from 'antd';
import { UserOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import wardbordData from '../report-view/wardbord-data.js';
import './report-view.css';

const Option = Select.Option;
const { Panel } = Collapse;

const ReportViewScreen = (props) => {
  console.log('report checking', props);
  const [TableData, setTableData] = useState('');
  const [showModal, setshowModal] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [gamesListMeta, setGamesListMeta] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [seasonsList, setSeasonsList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [currentSeasonID, setCurrentSeasonID] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [match_results, setmatch_results] = useState([
    { name: 'Home Team Win', value: 0 },
    { name: 'Away Team Win', value: 1 },
    { name: 'Tie', value: 2 },
    { name: 'N/A', value: 3 },
  ]);
  const [typeData, setTypeData] = useState([
    { name: 'Scrimmage', value: 'scrimmage' },
    { name: 'Regular Season', value: 'regular season' },
    { name: 'Playoffs', value: 'playoffs' },
  ]);
  const [agree, setAgree] = useState(true);
  const [form] = Form.useForm();
  const [gameViewDetails, setGameViewDetails] = useState({});
  const [gameDetail, setGameDetail] = useState({});
  const [seasonId, setSeasonId] = useState('');

  let history = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const columns = [
    {
      title: 'Team',
      dataIndex: 'title',
      align: 'center',
      key: 'title',
      // render: (title, record) => title + " " + record.game_id
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      align: 'center',
      key: 'created_at',
    },
    {
      title: 'Week',
      dataIndex: 'week',
      align: 'center',
      key: 'week',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      align: 'center',
      key: 'start_time',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
      align: 'center',
      key: 'action',
      render: (text, record) => (
        <div className="games-list-action-btns">
          <Button
            className="TableActionBtn"
            onClick={() => updateModal(record)}
          >
            Update Game Result
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (_.get(props, 'coachProfile.id')) {
      loadGamesList();
      loadGameView();
    }
    loadSeasonsList();
    getGridironDetails();
  }, []);

  const loadSeasonsList = () => {
    let params = { limit: 100, sort: 'title.desc' };
    props.seasonsList({
      params,
      callback: (res, data) => {
        if (res === true) { 
          setSeasonsList(_.get(data, 'data.items', []));
        }
      },
    });
  };

  const loadGamesList = (seasonID) => {
    let params = {
      limit: 999999,
      home_team: _.get(props, 'coachProfile.school.id'),
      sort: 'week.asc,created_at.desc',
      season: seasonID,
    };
    setGamesListLoading(true);
    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) { 
          setAllGamesList(_.get(data, 'data.items', []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const setAllGamesList = (data) => {
    let weekFormat = 'Week';
    let list = [];
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        console.log('valuevaluevaluevalue', value);
        list.push({
          key: index,
          title: _.startCase(_.get(value, 'title')),
          season: _.get(value, 'season'),
          created_at: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('MM/DD/YYYY')
            : '---',
          week: weekFormat.concat(' ', _.get(value, 'week')),
          start_time: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('h:mm A')
            : '---',
          end_time: moment(
            `${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`
          ).format('h:mm A'),
          duration: _.get(value, 'duration'),
          game_id: _.get(value, 'id'),
          description: _.get(value, 'description'),
          type: _.get(value, 'type'),
          home_team_score: _.get(value, 'home_team_score'),
          away_team_score: _.get(value, 'away_team_score'),
          match_result: _.get(value, 'match_result'),
          home_team: _.get(value, 'home_team'),
          away_team: _.get(value, 'away_team'),
          week1: _.get(value, 'week'),
        });
      });
      setGamesList(list);
    } else {
      setGamesList([]);
    }
  };

  const getGridironDetails = () => {
    props.getGridironDetails({
      callback: (res, data) => {
        if (res === true) {
          setPositionsList(
            _.chain(data)
              .get('data.data.positions')
              .pick(['offense', 'defense', 'special_teams'])
              .values()
              .flatten()
              .value()
          );
        }
      },
    });
  };

  const onCollapseChange = (value) => {
    if (!_.isEmpty(value)) {
      loadGamesList(value);
      setCurrentSeasonID(value);
    } else {
      setAllGamesList([]);
    }
  };

  const updateModal = (data) => {
    console.log('single data view', data);
    setGameDetail(data);
    form.setFieldsValue({
      ..._.pick(data, ['home_team_score', 'away_team_score']),
      match_result: _.chain(match_results)
        .find({ value: data.match_result })
        .thru((matchData) => {
          return {
            label: _.get(matchData, 'name', ''),
            value: _.get(matchData, 'value', null),
          };
        })
        .value(),
    });
    setShowUpdateModal(true);
  };

  const updateModalCancel = () => {
    setShowUpdateModal(false);
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const loadGameView = () => {
    props.gamesView({
      params: { id: gameDetail },
      callback: (res, responseData) => {
        console.log('games view data', responseData);
        setGameViewDetails(_.get(responseData, 'data.details', {}));
      },
    });
  };

  const handleSubmit = (e) => {
    console.log('report view', e);
    let details = {
      ..._.omit(e, ['update_Check']),
      ..._.pick(gameDetail, [
        'type',
        'title',
        'description',
        'season',
        'week',
        'home_team',
        'away_team',
      ]),
      id: _.get(gameDetail, 'game_id'),
      week: _.get(gameDetail, 'week1'),
    };
    console.log('detailsdetails', details, gameDetail);
    details.match_result = _.get(details, 'match_result.value')
      ? _.get(details, 'match_result.value')
      : _.get(details, 'match_result.value') == 0
      ? _.get(details, 'match_result.value')
      : _.get(details, 'match_result');
    props.gamesUpdate({
      body: details,
      params: { id: _.get(gameDetail, 'game_id') },
      callback: (res, data) => {
        // props.gamesView({
        // 	params: { id:gameDetail },
        // 	callback: (res, responseData) => { }})
        props.approveGameGrading({
          body: {
            status: 1,
            id: _.get(gameDetail, 'game_id'),
          },
          callback: (res, data) => {
            if (res) {
              notification.success({
                message: 'Success',
                description: _.get(data, 'message'),
              });
              setShowUpdateModal(false);
            }
          },
        });
        if (res) {
          notification.success({
            message: 'Success',
            description: _.get(data, 'message'),
          });
          setShowUpdateModal(false);
        }
      },
    });
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const render = () => {
    const modalUpdate = showUpdateModal;
    return (
      <div className="HomePageContainer evaluvationPage">
        <div className="container">
          <div className="pageTitle">Evaluation</div>
          <div className="full-width">
            <Collapse accordion onChange={(value) => onCollapseChange(value)}>
              {_.map(seasonsList, (seasonData) => (
                <Panel
                  header={_.get(seasonData, 'title')}
                  key={_.get(seasonData, 'id')}
                >
                  <div className="pageTitle">Reports</div>
                  <div className="TableContainer">
                    <Table
                      bordered
                      key={_.get(seasonData, 'id')}
                      loading={gamesListLoading}
                      columns={columns}
                      dataSource={gamesList}
                      size="middle"
                      pagination={false}
                      expandable={{
                        expandedRowRender: (gameDetails) => (
                          <div className="gameListTagContainer">
                            {' '}
                            <p style={{ margin: 0 }}>
                              {_.map(positionsList, (positionData) => (
                                <Button
                                  key={positionData.code}
                                  onClick={() =>
                                    history(
                                      `/evaluvation-report?position_code=${
                                        positionData.code
                                      }&position_name=${_.chain(positionData)
                                        .get('name')
                                        .startCase()
                                        .value()}&game_id=${
                                        gameDetails.game_id
                                      }`
                                    )
                                  }
                                  className="gameListTag"
                                >
                                  {_.chain(positionData)
                                    .get('name')
                                    .startCase()
                                    .value() == 'Quaterback'
                                    ? 'Quarterback'
                                    : _.chain(positionData)
                                        .get('name')
                                        .startCase()
                                        .value()}{' '}
                                  Evaluation
                                </Button>
                              ))}
                            </p>
                          </div>
                        ),
                        rowExpandable: (wardbordData) =>
                          wardbordData.name !== 'Not Expandable',
                      }}
                    />
                  </div>
                  <Modal
                    title="Basic Modal"
                    key={_.get(seasonData, 'id')}
                    className="UpdateGameResultModal"
                    header="false"
                    visible={modalUpdate}
                    onCancel={updateModalCancel}
                    width={350}
                  >
                    <div className="full-width">
                      <div className="row">
                        <Form
                          layout="vertical"
                          className="PlayerFilterForm"
                          initialValues={{ remember: true }}
                          onFinish={(e) => handleSubmit(e)}
                          form={form}
                        >
                          <div className="col-12 BoldLabel">
                            Update Game Result
                          </div>
                          <Form.Item
                            className="col-12 col-sm-12 col-lg-12"
                            label=""
                            name="match_result"
                          >
                            <Select
                              initialvalues="Line"
                              className="full-width"
                              showSearch={true}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {match_results.map((item, index) => {
                                return (
                                  <Option key={index} value={item.value}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <div className="col-12 BoldLabel">Final Score</div>
                          <Form.Item
                            className="col-12 col-sm-4 col-lg-6 mb-3"
                            label="Home Team"
                            name="home_team_score"
                          >
                            <InputNumber className="full-width" maxLength={3} />
                          </Form.Item>
                          <Form.Item
                            className="col-12 col-sm-4 col-lg-6"
                            label="Away Team"
                            name="away_team_score"
                          >
                            <InputNumber className="full-width" maxLength={3} />
                          </Form.Item>
                          <Form.Item
                            className="col-12"
                            label=""
                            name="update_Check"
                          >
                            <Checkbox id="agree" onChange={checkboxHandler}>
                              Have you assigned MVP's?
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            className="col-12 mb-0"
                            label=""
                            name="update_Check"
                          >
                            <Button
                              htmlType="submit"
                              className="PushBtn"
                              disabled={agree}
                            >
                              Send Push Notification
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </Modal>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, 'app.account.profile.data.details'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  approveGameGrading: (details) =>
    dispatch(global.redux.action.grades.approve(details)),
  gamesUpdate: (details) => dispatch(global.redux.action.games.update(details)),
  gamesView: (details) => dispatch(global.redux.action.games.view(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewScreen);
