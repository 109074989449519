import React, { useEffect, useState } from "react";
import {
  Form,

  Input,
  Button,

  Tag,
  Modal,
  Select,
  notification,
  Image
} from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import "./coaches.css";

const { Option } = Select;

const ChangeStatus = (props) => {

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);
	const [statusType, setStatusType] = useState('');
	const [statusValue, setStatusValue] = useState(0);

    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        fetchCoachesViewData();
    }, []);

    const fetchCoachesViewData = () => {
        const params = new URLSearchParams(window.location.search);
        let coaches_id = params.get('id');

        props.getCoachesViewData({
            params: { id: coaches_id },
            callback: (res, responseData) => { 

                setFormFieldsData(_.chain(responseData).get('data.details', {}).pick(['first_name', 'last_name', 'email', 'phone', 'city', 'zip_code']).value());
                if (_.get(responseData, 'data.details.address') && _.get(responseData, 'data.details.location')) {
                    let split_location = _.split(_.get(responseData, 'data.details.location.lat'), ', ') 
                    const address_results = [{
                        text: _.get(responseData, 'data.details.address'),
                        value: JSON.stringify({ text: _.get(responseData, 'data.details.address'), lat: _.get(responseData, 'data.details.location.x'), lng: _.get(responseData, 'data.details.location.y'), postal_code: '' }),
                    }]
                    form.setFieldsValue({
                        address: JSON.stringify({ text: _.get(responseData, 'data.details.address'), lat: _.get(responseData, 'data.details.location.x'), lng: _.get(responseData, 'data.details.location.y'), postal_code: '' }),
                    })
                    // setaddress_results(address_results)
                }
            }
        });
    };

    const setFormFieldsData = (formFieldsData) => {

        form.setFieldsValue(formFieldsData);
    };
	
    const  onFinish = (e) => {
        setButtonLoading(true)
        const params = new URLSearchParams(window.location.search);
        let school_id = params.get('id');
        let details = {
            id: school_id,
            change_status: _.get(e, 'status'),
            status_glossary: _.get(e, 'status_glossary')
        } 

        props.changeStatus({
            body: details,
            callback: (res, responseData) => {
                if (res == true) {
                    setButtonLoading(false)
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    );
                    props.setVisible(true);
                } 
            }
        });
    };

    const showModal = (type, status) => {
        setVisible(true);
        setStatusType(type);
        setStatusValue(status);
        
        setTimeout(() => {
            form.current.setFieldsValue({ status: "1" });
        }, 1000);
      };

    const  onFinishFailed = (e) => {

    };

   const handleOk = () => {
    setVisible(false);
      };
    
    const  handleCancel = () => {
        setVisible(false);
      };

    return (
        <div className="HomePageContainer">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                <div className="CategoriesViewList">
                    <div className="TextRegular mb-2">Status</div>
                    <div className="TextSemiBold">
                        {props.status === 1 ? (
                            <Tag color="green">Active</Tag>
                        ) : (
                            <Tag color="red">In Active</Tag>
                        )}
                        </div>
                </div>
                <div>
                    <div className="CategoriesViewList">
                        <div className="mt-2">
                            {props.status === 0 ? (
                                <Button
                                    type="primary"
                                    color="green"
                                    onClick={() => showModal("status", 1)}
                                >
                                    Active
                                </Button>
                            ) : (
                                <Button
                                    type="primary" danger onClick={() => showModal("status", 0)}
                                >
                                    Deactivate
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
                <Modal
                    visible={visible}
                    title="Change Status"
                    //   onOk={this.handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className="row">
                        <div className="col-12">
                            <div className="CategoriesViewList">
                                <Form
                                    scrollToFirstError={true}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    form={form}
                                >
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        rules={[
                                            {
                                            required: true,
                                            message: "This field required!",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select">
                                            <Option value="1">Active</Option>
                                            <Option value="0">Deactivate</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Reason for changing status"
                                        name="status_glossary"
                                        rules={[
                                            {
                                            required: true,
                                            message: "This field required!",
                                            },
                                        ]}
                                        >
                                            <Input.TextArea />
                                    </Form.Item>

                                    <div
                                        className="text-align-end"
                                        style={{ textAlign: "end" }}
                                    >
                                        <Button
                                            className="mr-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            htmlType="submit"
                                            type="primary"

                                        // onClick={this.handleOk}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
        </div>

    );
};

const mapStateToProps = state => (
    {
        CoachesViewData: state

    });

const mapDispatchToProps = dispatch => ({
    getCoachesViewData: details => dispatch(global.redux.action.coaches.view(details)),
    changeStatus: details => dispatch(global.redux.action.coaches.changeStatus(details))
 });
  
 export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);