import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { Modal, Checkbox, Switch, Select } from "antd";
import medal from "../../assets/medalblue.svg";
import brainWhite from "../../assets/img/brain-electricitywhite.svg";
import brainGrey from "../../assets/img/brain-electricitygrey.svg";
import "./modal.css";
import SwitchWithText from "./SwitchWithText";
import { LoadingOutlined } from "@ant-design/icons";

function PushNotificationContent({
  onCancel,
  open,
  onOk,
  selectedGame = {},
  allAthletesList,
  createAnMvp,
  removeAnMvp,
  mvpList,
  handleGamesGrading,
  buttonText,
  columnTitles,
}) {
  const [isChecked, setIsChecked] = useState(true);
  const [noaward, setNoaward] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  const handleCreateAnMvp = (id, label) => {
    createAnMvp(
      label,
      parseInt(selectedGame?.week?.substr(5, selectedGame?.week?.length - 5)),
      id,
      selectedGame.game_id
    );
  };
  const handleRemoveAnMvp = (id) => {
    removeAnMvp(id);
  };
  const handleSave = () => {
    // handleGamesGrading()
    setLoading(true);
    setTimeout(() => {
      onOk();
      setLoading(false);
    }, 2000);
    onCancel();
  };

  return (
    <Modal
      open={open}
      closable={true}
      onCancel={onCancel}
      footer={null}
      style={{ top: 20 }}
    >
      <div className="w-full flex flex-col font-inter pushnotificationmodal">
        <img src={logo} className="w-[40%] self-center" />
        <div className="w-full flex items-center justify-between  bg-[#0F1A24] text-sm text-[#FFFFFF] rounded-md p-3 mt-3">
          <span>{selectedGame.week}</span>
          <span>
            <span className="text-[#A40E17]">vs</span> {selectedGame.title}
          </span>
          <span>{selectedGame.start_time}</span>
        </div>
        <div className="flex items-center rounded-md text-md mt-2 text-[#1A91FF] bg-[#E8F4FF] p-3 justify-center">
          <img src={medal} />
          <span>Time to announce your Wardbord players of the week!</span>
        </div>
        <div className="flex items-center text-xs text-[#2E4D6B] mt-2">
          <Checkbox onChange={() => setNoaward(!noaward)} />
          <span className="ml-2">
            I'd rather not award weekly winners for this game
          </span>
        </div>
        {noaward == false ? (
          <>
        
            <SwitchWithText
              label= {columnTitles["offensive_skills_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              name="Offensive Skills Player of the week"
              isChecked={isChecked}
              allAthletesList={allAthletesList.filter((athlete) => {
                return athlete?.positions?.some(
                  (position) =>
                    position?.includes("RB") ||
                    position?.includes("QB") ||
                    position?.includes("WR") ||
                    position?.includes("TE")
                );
              })}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Offensive_Skills_Player_of_the_week}
            />
            <SwitchWithText
            name="Offensive Lineman of the week"
              label={columnTitles["offensive_lineman_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              isChecked={isChecked}
              allAthletesList={allAthletesList.filter((athlete) => {
                return athlete?.positions?.some((position) =>
                  position?.includes("OL")
                );
              })}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Offensive_Lineman_of_the_week}
            />
            <SwitchWithText
            name="Defensive Skills Player of the week"
              label={columnTitles["defensive_skills_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              isChecked={isChecked}
              allAthletesList={allAthletesList.filter((athlete) => {
                return athlete?.positions?.some(
                  (position) =>
                    position?.includes("LB") ||
                    position?.includes("C") ||
                    position?.includes("S")
                );
              })}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Defensive_Skills_Player_of_the_week}
            />
            <SwitchWithText
            name="Defensive Lineman of the week"
              label={columnTitles["defensive_lineman_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              isChecked={isChecked}
              allAthletesList={allAthletesList.filter((athlete) => {
                return athlete?.positions?.some((position) =>
                  position?.includes("DL")
                );
              })}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Defensive_Lineman_of_the_week}
            />
            <SwitchWithText
            name="Special Teams Player of the week"
              label={columnTitles["special_teams_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              isChecked={isChecked}
              allAthletesList={allAthletesList.filter((athlete) => {
                return athlete?.positions?.some(
                  (position) =>
                    position?.includes("ST") || position?.includes("K")
                );
              })}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Special_Teams_Player_of_the_week}
            />
            <SwitchWithText
            name="Hit Man of the week"
              label={columnTitles["hit_man_of_the_week"]}
              defaultColor="#A9B6C2"
              activeColor="#0F1A24"
              isChecked={isChecked}
              allAthletesList={allAthletesList}
              handleCreateAnMvp={handleCreateAnMvp}
              handleRemoveAnMvp={handleRemoveAnMvp}
              selectedValues={mvpList?.Hit_Man_of_the_week}
            />
            <div className="flex items-center justify-between text-md mt-3">
              <button
                className="text-[#1790FF] w-[49%] flex items-center justify-center h-11 rounded-sm focus:outline-0"
                style={{ border: "1px solid #1790FF" }}
                onClick={onCancel}
              >
                Cancel
              </button>
              {isChecked ? (
                <button
                  className="flex items-center justify-center w-[49%] bg-[#1A91FF] text-[#ffffff] focus:outline-0 h-11 rounded-sm"
                  onClick={handleSave}
                >
                  {loading && <LoadingOutlined />}
                  {buttonText ? (
                    buttonText
                  ) : (
                    <>
                      <img src={brainWhite} className="w-6" />
                      <span className="ml-2">Push Notification</span>
                    </>
                  )}
                </button>
              ) : (
                <button
                  className="flex items-center focus:outline-0 justify-center w-[49%] bg-[#EAEDF0] text-[#8698A9] h-11 rounded-sm"
                  disabled
                >
                  {buttonText ? (
                    buttonText
                  ) : (
                    <>
                      <img src={brainGrey} className="w-6" />
                      <span className="ml-2">Push Notification</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-between text-md mt-3">
            <button
              className="text-[#1790FF] w-[49%] flex items-center justify-center h-11 rounded-sm focus:outline-0"
              style={{ border: "1px solid #1790FF" }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="flex items-center focus:outline-0 justify-center w-[49%] bg-[#1A91FF] text-[#ffffff] h-11 rounded-sm"
              onClick={handleSave}
            >
              {loading && <LoadingOutlined />}
              {buttonText ? (
                buttonText
              ) : (
                <>
                  <img src={brainWhite} className="w-6" />
                  <span className="ml-2">Push Notification</span>
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default PushNotificationContent;
