import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

function PieChartComponent({ data, width, col, type }) {
  const COLORS = ["#A40E17", "#1A91FF", "#00B21E", "#ED780B", "#0F1A24"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='#FFFFFF'
        style={{ fontSize: "10px", fontWeight: 700 }}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline='central'>
        {percent !== 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "row",
       
      }}>
      {!col && (
        <div style={{ flex: "1" }} className='ml-3'>
          <Legend
            payload={data.map((entry, index) => ({
              id: entry.name,
              value: `${entry.name}`,
              type: "circle",
              color: COLORS[index % COLORS.length],
            }))}
            layout={col ? "horizontal" : "vertical"}
            verticalAlign='middle'
            align='left'
            wrapperStyle={{
              position: "relative",
              top: "30%",
              fontSize: "18px",
            }}
            formatter={(value, entry) => (
              <span
                style={{ color: "#526B84" }}
                className='text-[0.6rem]'
                // className={'ml-1 text-[10px] font-bold'}
              >
                {value}
              </span>
            )}
          />
        </div>
      )}
      <div
        style={{ flex: "2", minHeight: "300px" }}
        className=' flex items-center justify-center flex-col'>
        <PieChart width={width ? width : 400} height={300}>
          <Pie
            data={data}
            // cx={120}
            cy={150}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={60}
            outerRadius={!col ? 140 : 120}
            fill='#8884d8'
            dataKey='value'
            stroke='none'>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
        {col &&  (
          <div
            className={`{w-full ${type === "flagComparism" && "mb-[1.8rem]"} ${type === "flagByUnits" && "px-3 mb-1"}  ${
              type === "flagByDown" && "px-[20%] mb-1"
            } `}>
            <Legend
              payload={data.map((entry, index) => ({
                id: entry.name,
                value: `${entry.name}`,
                type: "circle",
                color: COLORS[index % COLORS.length],
              }))}
              layout={"horizontal"}
              verticalAlign='middle'
              align='center'
              wrapperStyle={{
                position: "relative",
                top: "30%",
                fontSize: "18px",
                display: "flex",
                flexDirection: "row-reverse",
              }}
              formatter={(value, entry) => (
                <span
                  style={{ color: "#526B84" }}
                  className='text-[0.6rem]'
                  // className={'ml-1 text-[10px] font-bold'}
                >
                  {value}
                </span>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PieChartComponent;
