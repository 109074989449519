import { message } from "antd";
import React, { useEffect, useState } from "react";
import { ProductFruits } from "react-product-fruits";
import footballImg from "../../assets/img/football.svg";
import logoutimg from "../../assets/img/navbar/logout.svg";
import hat from "../../assets/img/hat.svg";
import evalimg from "../../assets/img/clipboard-check.svg";
import analiticsimg from "../../assets/img/navbar/analitics.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./header.css";
import { connect } from "react-redux";
import _ from "lodash";
import people from "../../assets/img/group.svg";
import coin from "../../assets/img/coin.svg";
import dashbordIcon from "../../assets/img/dashboard-dots.svg";
import list from "../../assets/img/list.svg";
import trophy from "../../assets/img/Nav.svg";
import { routes, adminRoute } from "./route";

const PageSidebar = (props) => {
  let history = useNavigate();
  const location = useLocation();

  const [menuVisible, setmenuVisible] = useState(false);
  const [userProfile, setuserProfile] = useState({});
  const [schoolDetailsView, setschoolDetailsView] = useState({});
  const [userProfileMeta, setuserProfileMeta] = useState({});
  const [school_photo, setschool_photo] = useState(null);
  const [open, setOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  // When your custom element is rendered in your application.
  // If you use React, get a "ref" is the launcher element
  const customLauncher = document.getElementById("newsfeed-launcher");

  // If you want to render a badge with number of unread items...
  //
  // If you want to get the initial number of unread items,
  // attach this event BEFORE the attachNewsWidgetToElement method call.
  window?.productFruits?.api?.announcementsV2?.listen(
    "newsfeed-unread-count-changed",
    (data) => {
      const unreadCount = data.count;
      return <p>hiii {unreadCount}!</p>;
      // Render the count in your UI. We don't render badges automatically, it is up to you.
    }
  );

  // // Later, when the PF JS API is available, call the following API method and pass the element instance.
  window?.productFruits?.api?.announcementsV2?.attachNewsWidgetToElement(
    customLauncher
  );

  const isRouteActive = (path) => {
    if (!Array.isArray(path)) {
      path = [path];
    }
    return location.pathname.includes(path);
  };

  const logout = () => {
    props.logout({
      callback: (response, data) => {
        setmenuVisible(!menuVisible);
        history("/login");
        window.location.reload(false);
      },
    });
  };

  useEffect(() => {
    if (_.get(props, "checkUpdate") == true) {
      if (_.get(props, "islogindata.isLoggedIn") === true) {
        setuserProfile({});
        setschoolDetailsView({});
        if (_.includes(_.get(props, "islogindata.types"), 2) == true) {
          adminProfile();
        } else {
          getProfile();
        }
      }
    }
  }, [_.get(props, "checkUpdate")]);

  useEffect(() => {
    if (userProfile?.id) {
      fetchSchoolSubscription(userProfile?.school?.id);
    }
  }, [userProfile]);

  const fetchSchoolSubscription = (id) => {
    props.getSchoolSubscription({
      params: { id },
      callback: (res, responseData) => {},
    });
  };
  const adminProfile = () => {
    props.adminProfileData({
      // callback: (res, data) => {

      // if (res == true && _.get(data, 'data.details.id')) {
      //    navigate(_.get(data, 'data.details.type') == 0 ? '/coaches-evaluation' : _.get(data, 'data.details.type') == 2 ? "/manage-school" : "/schools-list")
      //    _.get(data, 'data.details.type') == 0 ? setAppRouteConfigs(coachRouteConfigs) : _.get(data, 'data.details.type') == 2 ? setAppRouteConfigs(adminRouteConfigs) : setAppRouteConfigs(routeConfigs)
      //    fetchSchoolViewData(_.get(data, 'data.details.school'))
      // }
      // }
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          await setuserProfile(_.get(data, "data.details"));
          await setuserProfileMeta(_.get(data, "data.meta"));
          setmenuVisible(true);
        }
      },
    });
  };

  useEffect(() => {
    if (_.get(props, "schoolDetails")) {
      setschoolDetailsView(_.get(props, "schoolDetails"));
      setschool_photo(
        `${_.get(props, "schoolDetails.meta.photo.path")}/${_.get(
          props,
          "schoolDetails.meta.photo.folders.school"
        )}/512/${_.get(props, "schoolDetails.details.photo")}?${Math.random()}`
      );
    }
  }, [_.get(props, "viewLoadingInProgress")]);

  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          setuserProfile(_.get(data, "data.details"));
          setuserProfileMeta(_.get(data, "data.meta"));
          setmenuVisible(true);
        } else {
          if (data?.error?.statusCode == 403) {
            message.error(
              <div style={{ width: "400px" }}>
                <p className="text-base">
                  It appears you cannot login using the web application as you
                  do not have permission to do so. Kindly download the official
                  Wardbord app from Google Play Store (for Android) or the App
                  store (for iOS). Open the app and log in with your
                  credentials.
                </p>
              </div>,
              8,
              () => {
                logout();
              }
            );
          } else {
            message.error(
              "No Coach found with the given email address",
              5,
              () => {
                // logout();
              }
            );
          }
        }
      },
    });
  };

  const render = () => {
    const userInfo = {
      email: userProfile?.email,
      firstname: userProfile?.first_name,
      username: userProfile?.email,
      lastname: userProfile?.last_name,
      signUpAt: userProfile?.created_at,
      role: userProfile?.type,
      // coachType
      props: {
        userProfile,
        plan: props?.schoolSubscriptionDetails?.plan,
        subscription: props?.schoolSubscriptionDetails?.subscription,
      },
    };

    return (
      <>
        {userProfile?.email ? (
          <ProductFruits
            workspaceCode="7gsR9Nd7CzJjQvgZ"
            language="en"
            user={userInfo}
          />
        ) : (
          ""
        )}
        <div className="w-[95%] side bg-[#ffffff] h-[100%] font-inter min-h-screen justify-center hidden md:flex">
          <div className="h-[95%] w-[85%] bg-[#ffffff] flex flex-col justify-between ">
            <div className="w-[100%] flex flex-col gap-4 mt-4"> 

              {userProfile.type == 3 ||
              userProfile?.email == "admin@wardbord.com" ? (
                adminRoute.map((route) => {
                  const isActive =
                    route.activepaths?.some((path) => isRouteActive(path)) ||
                    isRouteActive(route.url);
                  return (
                    <Link
                      key={route.title}
                      to={route.url}
                      className={`hover:w-full Link py-3 text-[#1A1C20] hover:bg-[#1A91FF] hover:no-underline hover:text-[#ffffff] font-medium w-full flex items-center px-4 rounded-[0.2vw] ${
                        isActive ? "active-link" : "bg-[#F3F5F7]"
                      }`}
                    >
                      <img
                        src={route.img}
                        className={`w-[15%] md:w-[20px] lg:w-[30px] xl:w-[25px] lg:self-center xl:justify-self-start ${
                          isActive ? "active-image" : ""
                        }`}
                      />
                      <span className="ml-[4%] text-[14px] md:hidden xl:flex">
                        {route.title}
                      </span>
                    </Link>
                  );
                })
              ) : (
                <>
                  {routes
                    .filter((route) =>
                      route.types?.includes(userProfile?.type || 0)
                    )
                    .map((route) => {
                      const isActive =
                        route.activepaths?.some((path) =>
                          isRouteActive(path)
                        ) || isRouteActive(route.url) ;
                      if (route.subRoutes.length > 0) {
                        if (route.title === "Analytics") {
                          route.isActive = open;
                          route.setIsActive = setOpen;
                          if (
                            isRouteActive("/season-analytic-dashboard") ||
                            isRouteActive("/game-analytic-dashboard") ||
                            isRouteActive("/formation-analytic-dashboard")
                          ) {
                            route.isActive = true;
                          }
                        } else {
                          route.isActive = statsOpen;
                          route.setIsActive = setStatsOpen;
                          if (
                            isRouteActive("/stats-view") ||
                            isRouteActive("/player-stats-view") ||
                            isRouteActive("/leaderboard") ||
                            isRouteActive("/scoring")
                          ) {
                            route.isActive = true;
                          }
                        }
                        return (
                          <div key={route.title} className="w-full">
                            <div
                              key={route.title}
                              className={`bg-[#F3F5F7]
                        hover:w-full Link py-3 text-[#1A1C20] hover:bg-[#1A91FF] hover:no-underline justify-between hover:text-[#ffffff] font-medium w-full flex items-center px-4 rounded-[0.2vw] mb-[5%]`}
                            >
                              <div className="flex items-center">
                                <img
                                  src={route.img}
                                  className={`w-[15%] md:w-[20px] lg:w-[30px] xl:w-[25px] lg:self-center xl:justify-self-start`}
                                />
                                <span className="ml-[4%] text-[14px] md:hidden xl:flex">
                                  {route.title}
                                </span>
                              </div>
                              <i
                                className={`fas text-[12px] ${
                                  !route.isActive
                                    ? "fa-chevron-down"
                                    : "fa-chevron-up"
                                } cursor-pointer`}
                                onClick={() =>
                                  route.setIsActive(!route.isActive)
                                }
                              ></i>
                            </div>
                            {route.isActive && (
                              <div>
                                {route.subRoutes.map((subRoute) => {
                                  return (
                                    <Link
                                      to={subRoute.url}
                                      className={`hover:w-full Link py-2 justify-center text-center bg-[#d4d4d4db] text-[#1A1C20] mt-[3%] hover:bg-[#1A91FF] hover:no-underline hover:text-[#ffffff] font-medium w-full flex items-center px-4  rounded-[0.2vw] mb-[5%] ${
                                        isRouteActive(subRoute.url)
                                          ? "active-link"
                                          : "bg-[#F3F5F7]"
                                      }`}
                                    >
                                      <span className="text-[14px] md:hidden xl:flex">
                                        {subRoute.title}
                                      </span>
                                    </Link>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <Link
                            key={route.title}
                            to={route.url}
                            className={`hover:w-full Link py-3 text-[#1A1C20] hover:bg-[#1A91FF] hover:no-underline hover:text-[#ffffff] font-medium w-full flex items-center px-4 rounded-[0.2vw] ${
                              isActive ? "active-link" : "bg-[#F3F5F7]"
                            }`}
                          >
                            <img
                              src={route.img}
                              className={`w-[15%] md:w-[20px] lg:w-[30px] xl:w-[25px] lg:self-center xl:justify-self-start ${
                                isActive ? "active-image" : ""
                              }`}
                            />
                            <span className="ml-[4%] text-[14px] md:hidden xl:flex">
                              {route.title}
                            </span>
                          </Link>
                        );
                      }
                    })}
                </>
              )}
            </div>

            <Link
              to="/logout"
              onClick={() => logout()}
              style={{
                position: "sticky",
                bottom: "10px",
              }}
              className={`hover:w-full Link  py-3 text-[#1A1C20] hover:bg-[#1A91FF] hover:no-underline hover:text-[#ffffff] font-medium w-full flex items-center px-4  rounded-[0.2vw] ${
                isRouteActive("/logout") ? "active-link" : "bg-[#F3F5F7]"
              }`}
            >
              <img
                src={logoutimg}
                className={`w-[15%] md:w-[20px] lg:w-[30px] xl:w-[25px] lg:self-center xl:justify-self-start ${
                  isRouteActive("/logout") ? "active-image" : ""
                }`}
              />
              <span className="text-[14px] md:hidden xl:flex ml-[4%]">
                Log Out
              </span>
            </Link>
          </div>
        </div>
      </>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {
    checkUpdate: _.get(state, "app.account.isLogin.isLoggedIn"),
    islogindata: _.get(state, "app.account.isLogin"),
    schoolDetails: _.get(state, "app.schools.view.data"),
    viewLoadingInProgress: _.get(state, "app.schools.viewLoadingInProgress"),
    schoolSubscriptionDetails: state.app.schools?.subscription?.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLogin: (details) => dispatch(global.redux.action.account.isLogin(details)),
  adminProfileData: (details) =>
    dispatch(global.redux.action.account.adminProfile(details)),
  logout: (details) => dispatch(global.redux.action.account.logout(details)),
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  getSchoolSubscription: (details) =>
    dispatch(global.redux.action.schools.subscription(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSidebar);
