import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./schools.css";
import _ from 'lodash';
import SchoolsViewModalComponent from "./schools-modal.js";

const Option = Select.Option;

const SchoolsListComponent = (props) => {
	console.log(props)
	const [TableData, setTableData] = useState('');
	const [showModal, setshowModal] = useState(false)
	const [schoolsList, setSchoolsList] = useState([])
	const [schoolsListMeta, setSchoolsListMeta] = useState({})
	const [schoolsListLoading, setSchoolsListLoading] = useState(false)

	const showModalView = (item) => {
		// set state to update the UI and show the PersonModal
		console.log('TableData 1', item)
		setshowModal(true)
		setTableData(item)
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'City',
			dataIndex: 'city',
		},
		{
			title: 'State',
			dataIndex: 'state',
		},
		// {
		// 	title: 'Country',
		// 	dataIndex: 'country',
		// },
		{
			title: 'Zip-Code',
			dataIndex: 'zip_code',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: '20%',
			align: 'center',
			render: (text, item) => (
				<div className="schools-list-action-btns">
					<Button onClick={() => showModalView(item)} className="ant-btn TableActionBtn edit">View</Button>


					{_.get(props,'coachProfile.type')!=0 &&<Link to= {`/schools-update?id=${_.get(item, 'id')}`} className="ant-btn TableActionBtn edit">Edit</Link> }
				</div>
			),
		},
	];

	useEffect(() => { 
		//  loadSchoolsList();
		if (_.get(props,'coachProfile.id')) {
			// if (_.get(props,'schoolDetails.details.id')) {
			// 	setSchoolsListMeta(_.get(props, 'schoolDetails.meta', {}))
			// 		setAllSchoolsList([_.get(props, 'schoolDetails.details',{})])
			// }else{
				fetchSchoolViewData(_.get(props,'coachProfile.school.id'))
			// }
			
		}
	}, [_.get(props,'coachProfile')]);
	
	const fetchSchoolViewData = (id) => {
		console.log('coach school id', id);
		setSchoolsListLoading(true)
		props.getSchoolViewData({
			params: {id: id},
			callback: (res, responseData) => {
				setSchoolsListLoading(false)
				if (res === true) {
					setSchoolsListMeta(_.get(responseData, 'data.meta', {}))
					setAllSchoolsList(_.get(responseData, 'data',{}))
				}
				
			 }
		});
	};
	const loadSchoolsList = (limit = 10000, page = 1) => {
		setSchoolsListLoading(true)
		props.schoolsList({ 
			callback: (res, data) => {
				if (res === true) { 
					setSchoolsListMeta(_.get(data, 'data.meta', {}))
					setAllSchoolsList(_.get(data, 'data', {}))
				}
				setSchoolsListLoading(false)
			}
		})
	};

	const setAllSchoolsList = (data) => {
		console.log("set all school list", data);
		let items = [_.get(data, 'details', {})];
		let meta = _.get(data, 'meta', {});
		let list = []
		if (_.size(items) > 0) {
			_.map(items, (value, index) => {
				let photo = `${_.get(meta,'photo.path')}/${_.get(meta,'photo.folders.school')}/512/${_.get(value,'photo')}`
				list.push({
					key: index,
					name: _.startCase(_.get(value, 'name')),
					email: _.get(value, 'email'),
					city: _.startCase(_.get(value, 'city')),
					state: _.startCase(_.get(value, 'state')),
					country: _.startCase(_.get(value, 'country')),
					zip_code: _.get(value, 'zip_code'),
					id: _.get(value, 'id'),
					address: _.get(value, 'address'),
					photo:photo})
			})

			setSchoolsList(list)
		} else {
			setSchoolsList([])
		}
	};

	const hideModal = () => {
		setshowModal(false)
	};

	const onChangePage = (page, size) => {
		loadSchoolsList(size, page)
	};

	const render = () => {

		return (
			<div className="HomePageContainer">
				<div className="container">
					<div className="full-width">
						<div className="SchoolsListTitleContainer">
							<span className="SchoolsListTitle">Team Profile</span>
						</div>
						<div className="TableContainer">
							<Table loading={schoolsListLoading} bordered columns={columns} dataSource={schoolsList} size="middle" pagination={false} />
						</div>
						{/* {schoolsListLoading == false && _.get(schoolsListMeta, 'total') > 10 && <div className="PaginationContainer">
							<span className="PaginationContainerInner">
								<span className="PaginationLabel">Total {_.get(schoolsListMeta, 'total') > 1 ? `${_.get(schoolsListMeta, 'total')} items` : `${_.get(schoolsListMeta, 'total')} item`}</span>
								<Pagination defaultCurrent={1}
									hideOnSinglePage={true}
									onChange={(page, size) => onChangePage(page, size)}
									total={_.get(schoolsListMeta, 'total')}
								// total={500}
								/>
							</span>
							<span className="PaginationContainerInner">
								<span className="PaginationLabel ml-3">Go to</span>
								<InputNumber min={1} max={_.get(schoolsListMeta, 'total') > 10 ? _.get(schoolsListMeta, 'total') / 9 : 2} onPressEnter={(e) => onChangePage(_.get(e, 'target.value'), 9)} style={{ width: '70px' }} />
							</span>
						</div>} */}
					</div>
					<SchoolsViewModalComponent isvisible={showModal} data={TableData} hideModal={hideModal} />
				</div>
			</div>
		);
	}
	return render();
};
const mapStateToProps = state => {
    console.log('mapStateToProps', _.get(state, 'app.account.profile.data.details'));
    return {
        checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
        islogindata: _.get(state, 'app.account.isLogin'),
        coachProfile:_.get(state, 'app.account.profile.data.details'),
		schoolDetails:_.get(state, 'app.schools.view.data'),
    }
}
const mapDispatchToProps = dispatch => ({
	schoolsList: details => dispatch(global.redux.action.schools.list(details)),
	getSchoolViewData: details => dispatch(global.redux.action.schools.view(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsListComponent)