import {
  Button
} from "antd";
import { Link } from "react-router-dom";
import shadow from "../../../assets/img/shadow.png";
import kc from "../../../assets/img/kc.png";
import king from "../../../assets/img/king.png";
import manvel from "../../../assets/img/manvel.png";
import wb from "../../../assets/img/wb.png";
import westlake from "../../../assets/img/westlake.png";
import willis from "../../../assets/img/willis.png";
import "./library.css";

const TableData = [
  {
    key: '1',
    team:
      <div className="TableTeam">
        <img
          src={shadow}
          className="TableTeamImg mr-1"
        />
        <span>Shadow Creek</span>
      </div>,
    status: '38-21',
    date: '9/25',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,

  },
  {
    key: '2',
    team:
      <div className="TableTeam">
        <img
          src={kc}
          className="TableTeamImg mr-1"
        />
        <span>Klein Collins</span>
      </div>,
    status: '30-23',
    date: '10/1',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,
  },
  {
    key: '3',
    team:
      <div className="TableTeam">
        <img
          src={willis}
          className="TableTeamImg mr-1"
        />
        <span>Willis</span>
      </div>,
    status: '48-21',
    date: '10/8',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,
  },
  {
    key: '4',
    team:
      <div className="TableTeam">
        <img
          src={manvel}
          className="TableTeamImg mr-1"
        />
        <span>Manvel</span>
      </div>,
    status: '49-14',
    date: '10/16',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,
  },
  {
    key: '5',
    team:
      <div className="TableTeam">
        <img
          src={wb}
          className="TableTeamImg mr-1"
        />
        <span>West Brook</span>
      </div>,
    status: '55-14',
    date: '10/23',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>
  },
  {
    key: '6',
    team:
      <div className="TableTeam">
        <img
          src={westlake}
          className="TableTeamImg mr-1"
        />
        <span>Westlake</span>
      </div>,
    status: '24-21',
    date: '1/9',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,
  },
  {
    key: '7',
    team:
      <div className="TableTeam">
        <img
          src={king}
          className="TableTeamImg mr-1"
        />
        <span>King</span>
      </div>,
    status: '47-21',
    date: '1/6',
    action: <Link to="/dashboard"><Button className="TableActionBtn">Grade Now</Button></Link>,
  },

];

export default TableData