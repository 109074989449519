import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import PlaysListViewModalComponent from "./plays-list-view.js";
import { connect } from "react-redux";
import moment from 'moment'
import "./plays.css";
import _, { filter, isEmpty } from 'lodash';
const Option = Select.Option;


const PlaysListComponent = (props) => {
	const [TableData, setTableData] = useState('');
	const [showModal, setshowModal] = useState(false)
	const [playsList, setPlaysList] = useState([])
	const [playsListMeta, setPlaysListMeta] = useState({})
	const [playsListLoading, setPlaysListLoading] = useState(true)
	const [page,setPage]=useState(1)

	const showModalView = (item) => {
		setshowModal(true)
		setTableData(item)
	}
	const columns = [
		{
			title: 'Start Time',
			dataIndex: 'start_time',
			key: 'start_time',
		},
		{
			title: 'End Time',
			dataIndex: 'end_time',
			key: 'end_time',
		},
		{
			title: 'Duration',
			dataIndex: 'duration',
			key: 'duration',
		},
		{
			title: 'Home team score',
			dataIndex: 'home_team_score',
			key: 'home_team_score',
		},
		{
			title: 'Away team score',
			dataIndex: 'away_team_score',
			key: 'away_team_score',
		},
		// {
		// 	title: 'Action',
		// 	dataIndex: 'action',
		// 	key: 'action',
		// 	width: '20%',
		// 	align: 'center',
		// 	render: (text, item) => (
		// 		<div className="plays-list-action-btns">
		// 			<Button className="TableActionBtn edit" onClick={() => showModalView(item)}>View</Button>
		// 		</div>
		// 	),
		// },
	];

	useEffect(() => {
		loadPlaysList()
	}, [])

	const loadPlaysList = (limit = 9, page = 1) => {
		const params = new URLSearchParams(window.location.search);
        let game_id = params.get('id');
		setPlaysListLoading(true)
		props.playsList({
			params: { limit, page,game:game_id },
			callback: (res, data) => {
				if (res === true) { 
					setPlaysListMeta(_.get(data, 'data.meta', {}))
					setAllPlaysList(_.get(data, 'data.items', []))
				}
				setPlaysListLoading(false)
			}
		})
	}

	const setAllPlaysList = (data) => {
		
		let list = []
		if (_.size(data) > 0) {
			_.map(data, (value, index) => {
				list.push({
					key: index,
					start_time: moment(`${moment().format('YYYY-MM-DD')} ${_.get(value, 'start_time')}`).format('h:mm A'),
					end_time: moment(`${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`).format('h:mm A'),
					duration: _.get(value, 'duration'),
					home_team_score: _.get(value, 'home_team_score'),
					away_team_score: _.get(value, 'away_team_score'),
				})
			})

			setPlaysList(list)
		} else {
			setPlaysList([])
		}
	}

	const hideModal = () => {
		setshowModal(false)
	};
	const onChangePage = (page, size) => {
		setPage(page)
		loadPlaysList(size, page)
	}
	const render = () => {
		const params = new URLSearchParams(window.location.search);
        let game_id = params.get('id');
		return (
			<div className="HomePageContainer">
				<div className="container">
					<div className="full-width">
						<div className="CoachListTitleContainer">
							<span className="CoachListTitle">Plays</span>
							<div className="plays-list-action-btns">
								<Link to={`/create-plays?id=${game_id}`} className="ant-btn TableActionBtn edit">Create Play</Link>
							</div>
						</div>
						<div className="TableContainer">
							<Table bordered key={playsList.id} loading={playsListLoading} columns={columns} dataSource={playsList} size="middle" pagination={false} />
						</div>

						{playsListLoading == false && _.get(playsListMeta, 'total') > 10 && <div className="PaginationContainer">
							<span className="PaginationContainerInner">
								<span className="PaginationLabel">Total {_.get(playsListMeta, 'count') > 1 ? `${_.get(playsListMeta, 'count')} items` : `${_.get(playsListMeta, 'count')} item`}</span>
								<Pagination defaultCurrent={1}
								current={page}

									hideOnSinglePage={true}
									onChange={(page, size) => onChangePage(page, size)}
									total={_.get(playsListMeta, 'total')}
								// total={500}
								/>
							</span>
							<span className="PaginationContainerInner">
								<span className="PaginationLabel ml-3">Go to</span>
								<InputNumber min={1} max={_.get(playsListMeta, 'count') > 10 ? _.get(playsListMeta, 'count') / 9 : 2} onPressEnter={(e) => onChangePage(_.get(e, 'target.value'), 9)} style={{ width: '70px' }} />
							</span>
						</div>}
					</div>
				</div>
				<PlaysListViewModalComponent isvisible={showModal} data={TableData} hideModal={hideModal} />
			</div>
		);
	}
	return render()
}

const mapStateToProps = state => (
	{


	});

const mapDispatchToProps = dispatch => ({
	playsList: details => dispatch(global.redux.action.plays.list(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaysListComponent)