import React from "react";
import { Table } from "antd";
import { dColumns } from "./statsColumns";
import { dColumnsPrint } from "./StatsColumnPrint";

function DStatsTable({ dataSource, pageLoading, print }) {
  return (
    <div className="stats-table">
      <Table
        dataSource={dataSource}
        columns={print ? dColumnsPrint : dColumns}
        bordered
        className="w-full"
        loading={pageLoading}
        pagination={false}
        summary={(pageData) => {
          let plays = 0;
          let total_positive_impact = 0;
          let total_tackles_solo = 0;

          let total_tackles_ast = 0;
          let total_tackles_tot = 0;
          let total_missed_tackles = 0;
          let net_Tackles = 0;
          let total_tackles_tfl = 0;
          let total_tackles_yds = 0;
          let total_tackles_sak = 0;
          let total_sack_yds = 0;
          let total_hurries = 0;
          let total_hurries_yds = 0;
          let total_int = 0;

          let total_tackles_int_yds = 0;
          let total_pd = 0;
          let total_caused_fumble = 0;
          let total_recovered_fumble = 0;
          let total_yards_after_recovery = 0;
          let total_safety = 0;
          let total_td = 0;
          let total_turnovers = 0;
          let _total_tackles_int_yds = 0;

          let total_int_ret_td = 0;
          let total_fum_ret_td = 0;

          pageData.forEach((data) => {
            plays += Number(data.plays);
            total_fum_ret_td += Number(data.total_fum_ret_td);
            total_int_ret_td += Number(data.total_int_ret_td);
            total_positive_impact += Number(data.total_positive_impact);
            total_tackles_solo += Number(data.total_tackles_solo);
            total_tackles_ast += Number(data.total_tackles_ast);
            total_tackles_tot +=
              Number(data.total_tackles_solo) +
              Number(data.total_tackles_ast_halved);
            total_missed_tackles += Number(data.total_missed_tackles);
            net_Tackles +=
              Number(data.total_tackles_solo) +
              Number(data.total_tackles_ast_halved) -
              Number(data.total_missed_tackles);
            total_tackles_tfl += Number(data.total_tackles_tfl);
            total_tackles_yds += Number(data.total_tackles_yds);
            total_tackles_sak += Number(data.total_tackles_sak);
            total_sack_yds += Number(data.total_sack_yds);
            total_hurries += Number(data.total_hurries);
            total_hurries_yds += Number(data.total_hurries_yds);
            total_int += Number(data.total_int);
            total_tackles_int_yds += Number(data.total_tackles_int_yds);
            total_pd += Number(data.total_pd);
            total_recovered_fumble += Number(data.total_recovered_fumble);
            total_yards_after_recovery += Number(
              data.total_yards_after_recovery
            );
            total_safety += Number(data.total_safety);
            total_td += Number(data.total_td);
            _total_tackles_int_yds +=
              Number(data.total_tackles_int_yds) +
              Number(data.total_yards_after_recovery);
            total_turnovers +=
              Number(data.total_int) + Number(data.total_recovered_fumble);
            total_caused_fumble += Number(data.total_caused_fumble);
          });
          return (
            <>
              <Table.Summary.Row
                align="center"
                style={{
                  background: "#F3F5F7",
                  fontWeight: "bold",
                }}
              >
                <Table.Summary.Cell index={0} colSpan={print ? 1 : 3}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {plays}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_positive_impact}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_solo}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_ast}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_tot}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_missed_tackles}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {net_Tackles}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_tfl}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_yds}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_sak}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_sack_yds}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_hurries}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_hurries_yds}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_int}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_int_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_pd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_int_ret_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_caused_fumble}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_recovered_fumble}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_yards_after_recovery}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fum_ret_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_turnovers}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {_total_tackles_int_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_safety}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_td}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default DStatsTable;
