import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    // Fetch client secret from the server
    if (props.coachProfile?.email) {
      const referred_by = localStorage.getItem("rewardful");
      const res = await axios.post(
        "https://api.wardbord.com/stripe/create-intent",
        {
          metadata: {
            school_id: props.coachProfile.school.id,
            school_name: props.coachProfile.school.name,
            email: props.coachProfile.email,
            first_name: props.coachProfile.first_name,
            last_name: props.coachProfile.last_name,
            referral: referred_by,
          },
          receipt_email: props.coachProfile?.email,
          amount: props?.amount || 0,
        }
      );

      if (res.status === 200) {
        const { client_secret: clientSecret } = res.data;

        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: "https://your-actual-return-url.com", // Replace with your actual return URL
          },
        });

        if (error) {
          setErrorMessage(error.message);
        } else {
          // Handle successful payment
          window?.rewardful("convert", { email: props.coachProfile.email });
        }
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          className="btn btn-primary mt-3 px-4 h-[auto] py-2"
          type="submit"
          disabled={!stripe || !elements}
        >
          Complete 30-day Trial Sign up
        </button>
        <p>Payment is be made securely and encrypted via stripe</p>
        <p className="mt-1">You will be charged {props?.amount} after 30 days</p>
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};

const StripePayment = ({ coachProfile, subscription }) => {
  const stripePromise = loadStripe(
    "pk_live_51K1ukJJlrM6wBFlOye8rJG7ObZDcD6KJhBturlqKoVsbV8tc5t4opIrwbW2h8vRdXYJvJAoBmcrqW40V2j5SeHHq00r2qDWfXu"
  );
  const options = {
    mode: "payment",
    currency: "usd",
    amount: subscription?.price || 10,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        coachProfile={coachProfile}
        amount={subscription?.price || 10}
      />
    </Elements>
  );
};

export default StripePayment;
