import React from "react";
import { Checkbox, Form } from "antd";
import backArrow from "../../assets/img/arrow-leftblue.svg";
import continueArrow from "../../assets/img/arrow-right.svg";

const solutions = [
  "Increasing productivity on weekends for coaches.",
  "Prioritizing performance and effort over statistics with players.",
  "Developing new coacnes and supporting all coaches with consistent systems.",
  "Increasing player engagement through instantly communicatingcoaches feedbackbto players via the Free player mobile app.",
  "All of This",
];

function SolutionsSeeked(props) {
  const [solutionsSelected, setSolutionsSelected] = React.useState([]);

  const handleCheck = (e) => {
    if (solutionsSelected.includes(e.target.value)) {
      setSolutionsSelected(
        solutionsSelected.filter((item) => item !== e.target.value)
      );
    } else {
      setSolutionsSelected([...solutionsSelected, e.target.value]);
      console.log(solutionsSelected);
    }
  };
  return (
    <div className="w-[100%] relative">
      <p className="text-[#0F1A24] text-lg xl:text-[18px] 2xl:text-[1.25vw] font-bold">
        Please select all of the following that you'd like to address by
        bringing Wardbord to your team?
      </p>
      <div className="space-y-2">
        {solutions.map((solution, index) => {
          return (
            <div key={index} className="flex gap-2 items-start">
              <Checkbox
                value={solution}
                onChange={(e) => handleCheck(e)}
                className="mt-1"
              />
              <span className="ml-2 text-[#2E4D6B] text-[14px] 2xl:text-[0.97vw] font-normal">
                {solution}
              </span>
            </div>
          );
        })}
      </div>
      {/* <Form.Item className="xl:absolute xl:right-0 xl:left-0 xl:bottom-2">
        <div className={`flex justify-between items-center w-full`}>
          <button
            className="px-6 py-2 flex  items-center justify-center gap-2 text-[#1A91FF] text-[14px] bg-white border disabled:opacity-50"
            onClick={() => {
              props.setPage(props.page - 1);
            }}
            style={{
              backgroundColor: "transparent",
              border: "3px solid #1A91FF",
            }}
          >
            <img src={backArrow} />
            <span>Back</span>
          </button>
          <button
            className={`px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white `}
            onClick={() => {
              props.setPage(props.page + 1);
            }}
            type="submit"
          >
            <span>Continue</span>
            <img src={continueArrow} />
          </button>
        </div>
      </Form.Item> */}
    </div>
  );
}

export default SolutionsSeeked;
