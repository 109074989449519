import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
	Modal
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import "./plays.css";
// import "./coaches.css";

class PlaysListViewModalComponent extends React.Component {
	// handleOk (...)
	// handleCancel (...)
	state = {
		disabled: true,
		showModal: true,
	};
	constructor(props) {
		super(props);
		this.state = { value: '' };
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(value) {
		this.setState({ value: value });
	}
	CardActive = () => {
		this.setState({
			disabled: !this.state.disabled,
		});
	};

	handleCancel = () => {

		// because the client controls the visivlity state of the component
		this.props.hideModal();
	}

	render() {
		/// this.props.isvisible is required, and it will be a boolean true or false.
		const shouldBeVisible = this.props.isvisible;
		const TableData = _.get(this, 'props.data', {});

		return (
			<div className="CoachViewModalContainer">
				<Modal className="CoachViewModal" title={[TableData.first_name, TableData.last_name]} visible={shouldBeVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					{/*<div className="CoachViewModal-header">
						<img src={TableData.photo} alt={TableData.photo} />
						<div className="CoachViewModal-name-bio">
							{<div className="coach-name">{[TableData.first_name,' ', TableData.last_name]}</div>}
							<div className="coach-name">{TableData.first_name}</div>
							<div className="coach-name">{TableData.last_name}</div>
						</div>
					</div>*/}
					<div className="full-width">
						<div className="row">
							<div className="col-12 col-md-6 CoachViewInfoGroup">
								<div className="infoLabel">Start time</div>
								<div className="infoLabelValue">{TableData.start_time}</div>
							</div>
							<div className="col-12 col-md-6 CoachViewInfoGroup">
								<div className="infoLabel">End time</div>
								<div className="infoLabelValue">{TableData.end_time}</div>
							</div>
							<div className="col-12 col-md-6 CoachViewInfoGroup">
								<div className="infoLabel">Duration</div>
								<div className="infoLabelValue">{TableData.duration}</div>
							</div>
							<div className="col-12 col-md-6 CoachViewInfoGroup">
								<div className="infoLabel">Home team score</div>
								<div className="infoLabelValue">{TableData.home_team_score}</div>
							</div>
							<div className="col-12 col-md-6 CoachViewInfoGroup">
								<div className="infoLabel">Away team score</div>
								<div className="infoLabelValue">{TableData.away_team_score}</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

export default PlaysListViewModalComponent;