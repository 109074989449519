// Alerts.js
import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { Link, useNavigate } from "react-router-dom";
 import { connect } from "react-redux";



const AdvantageOnly = ({ schoolSubscriptionDetails,profileData, coachProfile, getSchoolSubscription, children }) => {
     
      useEffect(() => {
    
          getProfile();
    
  }, []);

    useEffect(() => { 
        if (coachProfile?.id) {
            fetchSchoolSubscription(coachProfile?.school?.id)
        }
    }, [coachProfile])
  
      const getProfile = async () => {
    profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        
      },
    });
  };
    const fetchSchoolSubscription = (id) => {
        getSchoolSubscription({
            params: { id },
            callback: (res, responseData) => {
            },
        });
    };
 
 
   return (<> 
       {schoolSubscriptionDetails?.subscription?.plan != 1 && !schoolSubscriptionDetails?.subscription?.hasTrialExpired && !schoolSubscriptionDetails?.subscription?.hasPlanExpired ?  children  : <Alert
        // message="Free Plan"
        description={
            <div style={{ textAlign: 'center' }}>
                You are currently on a free plan. Upgrade now to enjoy all premium features!{' '}
                <Link to="/subscription">Upgrade Now</Link>
            </div>
        }
           className='mt-4'
        type="info"
        closable 
   />
        }
       </>
    )
};
const mapStateToProps = (state) => {
    return { 
      coachProfile: state?.app?.account?.profile?.data?.details,
    schoolSubscriptionDetails: state.app.schools?.subscription?.data,
  };
};

const mapDispatchToProps = (dispatch) => ({ 
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)), 
  getSchoolSubscription: (details) =>
    dispatch(global.redux.action.schools.subscription(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvantageOnly);
