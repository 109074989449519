import _ from "lodash";
import actionTypes from "./actionTypes";

const initialState = {
  // account state
  [actionTypes.account.name]: {
    [actionTypes.account.isLogin]: {},
    [actionTypes.account.signin]: {},
    [actionTypes.account.profile]: {},
  },

  // schools state
  [actionTypes.schools.name]: {
    [actionTypes.schools.list]: {},
    [actionTypes.schools.subscription]: {},
  },
  // Coaches state
  [actionTypes.coaches.name]: {
    [actionTypes.coaches.list]: {},
    
  },

  // site state
  [actionTypes.site.name]: {
    [actionTypes.site.gridironDetails]: {},
  },

  // Seasons state
  [actionTypes.seasons.name]: {
    [actionTypes.seasons.list]: {},
  },

  [actionTypes.super_admin.name]: {
    [actionTypes.super_admin.coachesList]: [],
    [actionTypes.super_admin.teamList]: [],
    [actionTypes.super_admin.plansList]: [],
    // Add other super admin properties if needed
  },

};

const reduxUpdateAccountState = (state, action) => {
  return {
    ...state,
    [actionTypes.account.name]: {
      ...state[actionTypes.account.name],
      ..._.get(action, "data", null),
    },
  };
};

const reduxUpdateSchoolsState = (state, action) => {
  return {
    ...state,
    [actionTypes.schools.name]: {
      ...state[actionTypes.schools.name],
      ..._.get(action, "data", null),
    },
  };
};

const reduxUpdateCoachesState = (state, action) => {
  return {
    ...state,
    [actionTypes.coaches.name]: {
      ...state[actionTypes.coaches.name],
      ..._.get(action, "data", null),
    },
  };
};

const reduxUpdateSiteState = (state, action) => {
  return {
    ...state,
    [actionTypes.site.name]: {
      ...state[actionTypes.site.name],
      ..._.get(action, "data", null),
    },
  };
};

const reduxUpdateSeasonsState = (state, action) => {
  return {
    ...state,
    [actionTypes.seasons.name]: {
      ...state[actionTypes.seasons.name],
      ..._.get(action, "data", null),
    },
  };
};

const reduxUpdateSuperAdminState = (state, action) => {
  return {
    ...state,
    [actionTypes.super_admin.name]: {
      ...state[actionTypes.super_admin.name],
      ..._.get(action, "data", null),
    },
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reduxUpdateAccountState:
      return reduxUpdateAccountState(state, action);
    case actionTypes.reduxUpdateSchoolsState:
      return reduxUpdateSchoolsState(state, action);
    case actionTypes.reduxUpdateCoachesState:
      return reduxUpdateCoachesState(state, action);
    case actionTypes.reduxUpdateSiteState:
      return reduxUpdateSiteState(state, action);
    case actionTypes.reduxUpdateSeasonsState:
      return reduxUpdateSeasonsState(state, action);
    case actionTypes.reduxUpdateSuperAdminState:
      return reduxUpdateSuperAdminState(state, action);
    default:
      return state;
  }
};

export default reducer;
