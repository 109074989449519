import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Collapse,
  Checkbox,
  Row,
  Col,
  Form,
  Progress,
  Card
} from 'antd'
import { UserOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import _ from 'lodash'

import './report-view.css'

const Option = Select.Option
const { Panel } = Collapse

const renderContent = (value, row, column_name = '') => {
  const obj = {
    children: value,
    props: {}
  }
  if (_.get(row, 'partition', false)) {
    if (_.eq(column_name, 'name')) {
      obj.props.colSpan = 10
      obj.children = <div className='PlaysNameTitle'>{value} - heh</div>
    } else {
      obj.props.colSpan = 0
    }
  }
  return obj
}

const EvaluvationReportViewScreen = props => {
  const [title, setTitle] = useState('')
  const [gradesListLoading, setGradesListLoading] = useState(false)
  const [gradesList, setGradesList] = useState([])
  const [positionsList, setPositionsList] = useState([])
  const [gradesData, setGradesData] = useState([])

  const params = new URLSearchParams(window.location.search)

  const columns = [
    {
      title: 'Play',
      dataIndex: 'name',
      align: 'center',
      render: (value, row) => renderContent(value, row, 'name')
    },

    {
      title: 'Position',
      dataIndex: 'position',
      align: 'center'
    },
    {
      title: 'Assignments Execution',
      dataIndex: 'assignment_executed',
      align: 'center',
      render: (value, row) =>
        renderContent(
          _.chain(value)
            .toInteger()
            .eq(1)
            .value() ? (
            'Y'
          ) : (
            <div className='text-danger'>N</div>
          ),
          row
        )
    },
    {
      title: 'Great Effort',
      dataIndex: 'great_effort',
      align: 'center',
      render: (value, row) =>
        renderContent(
          _.chain(value)
            .toInteger()
            .eq(1)
            .value() ? (
            'Y'
          ) : (
            <div className='text-danger'>N</div>
          ),
          row
        )
    },
    {
      title: 'Negative Play',
      dataIndex: 'negative',
      align: 'center',
      render: (value, row) =>
        renderContent(
          _.chain(value)
            .toInteger()
            .gt(0)
            .value() ? (
            <Checkbox defaultChecked={false} disabled />
          ) : (
            <Checkbox defaultChecked disabled />
          ),
          row
        )
    },
    {
      title: 'Flaged',
      dataIndex: 'penalties',
      align: 'center',
      render: (value, row) =>
        renderContent(
          _.chain(value)
            .toInteger()
            .gt(0)
            .value() ? (
            <Checkbox defaultChecked={false} disabled />
          ) : (
            <Checkbox defaultChecked disabled />
          ),
          row
        )
    },
    {
      title: 'Notes To Player',
      dataIndex: 'comments',
      align: 'center',
      render: (value, row) =>
        renderContent(
          _.chain(row)
            .pick(['positive_feedback', 'negative_feedback'])
            .values()
            .compact()
            .startCase()
            .value(),
          row
        )
    }
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ]

  useEffect(() => {
    setPageTitle()
    fetchGradesList()
    gridironDetails()
  }, [])
  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = []

          let stock = data.data.data.positions

          let offense = stock.offense.map(({ code }) => code)
          let defense = stock.defense.map(({ code }) => code)
          let special_teams = stock.special_teams.map(({ code }) => code)

          final_data = [
            {
              value: special_teams.toString(),
              title: 'Special Unit (' + special_teams.toString() + ')'
            },
            {
              value: defense.toString(),
              title: 'Defense Unit (' + defense.toString() + ')'
            },
            {
              value: offense.toString(),
              title: 'Offense Unit (' + offense.toString() + ')'
            }
          ]

          _.chain(data)
            .get('data.data.positions')
            .pick(['offense', 'defense', 'special_teams'])
            .values()
            .flatten()
            .map('code')
            .value()
            .map(val => {
              final_data.push({
                value: val,
                title: val
              })
            })

          console.log('final_data', final_data)

          setPositionsList(final_data)
        }
      }
    })
  }

  const loadGameView = (params, callback) => {
    props.gamesView({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          callback(_.get(data, 'data.details', {}))
        }
      }
    })
  }

  const fetchGradesList = () => {
    let queryParams = {
      positions: params.get('position_code'),
      game: params.get('game_id'),
      expand: 'athlete,play',
      athlete: params.get('athlete'),
      // sort: 'play.asc',
      limit: 2000,
      status: '1,2'
    }
    setGradesListLoading(true)
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items)
          processGradeListItems(
            _.chain(data)
              .get('data.items', [])
              .sortBy(['play.number'])
              .value()
          )
        }
        setGradesListLoading(false)
      }
    })
  }

  const processGradeListItems = items => {
    let finalGradeListItems = []
    _.forEach(items, (item, index) => {
      // console.log('check_boolean',!_.chain(item).get('play.id').eq(_.get(items[index-1], 'play.id')).value());
      if (
        !_.chain(item)
          .get('play.id')
          .eq(_.get(items[index - 1], 'play.id'))
          .value()
      ) {
        finalGradeListItems.push({
          name: `Play ${_.get(item, 'play.number')}`,
          partition: true
        })
      }
      item.name = _.chain(item)
        .get('athlete')
        .pick(['first_name', 'last_name'])
        .values()
        .compact()
        .map(_.capitalize)
        .join(' ')
        .startCase()
        .value()
      finalGradeListItems.push(item)
    })

    console.log('finalGradeListItems', finalGradeListItems)
    setGradesList(finalGradeListItems)
  }
  const refreshGradesList = queryParams => {
    setGradesListLoading(true)
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items)
          processGradeListItems(
            _.chain(data)
              .get('data.items', [])

              .sortBy(['play.number'])
              .value()
          )
        }
        setGradesListLoading(false)
      }
    })
  }

  const setPageTitle = () => {
    let id = params.get('game_id')
    loadGameView({ id, expand: 'home_team,away_team' }, gameDetails => {
      setTitle(
        `${_.startCase(_.get(gameDetails, 'home_team.name'))} vs ${_.startCase(
          _.get(gameDetails, 'away_team.name')
        )}`
      )
    })
  }

  const render = () => {
    return (
      <Card>
        <div className='HomePageContainer evaluvationPage'>
          <div className='container'>
            <div className='full-width'>
              <div className='TableContainer evaluvationReportTable'>
                <div>
                  <Form.Item
                    className='col-12 col-sm-4 col-lg-4 mt-1 pt-1 '
                    label='Position'
                    name='Position'
                  >
                    <Select
                      onChange={value => {
                        refreshGradesList({
                          positions: value.toString(),
                          game: params.get('game_id'),
                          expand: 'athlete,play',
                          athlete: params.get('athlete'),
                          // sort: 'play.asc',
                          limit: 2000,
                          status: '1,2'
                        })
                        // onChangePostions(value);
                        // playNumberForm.resetFields();
                      }}
                      mode='multiple'
                      className='full-width'
                    >
                      {_.map(positionsList, ({ value, title }, index) => {
                        return (
                          <Option value={value} key={value}>
                            {title}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
                {params.get('athlete') ? (
                  <PlayerData data={gradesData} />
                ) : (
                  <></>
                )}
                <br />
                <div className='evaluvationTableTitle'>
                  <b>
                    {params.get('athlete')
                      ? gradesData[0]?.athlete.last_name +
                        ' ' +
                        gradesData[0]?.athlete.first_name +
                        ' '
                      : ''}
                    {gradesData[0]?.position} - Coaches Game Notes
                  </b>
                  <span style={{ marginLeft: 5 }}>{title}</span>
                </div>
                <Table
                  key={gradesList.game_id}
                  columns={columns}
                  dataSource={gradesList}
                  loading={gradesListLoading}
                  size='middle'
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }
  return render()
}
function PlayerData ({ data }) {
  const plays = data.length
  const assignment = data.filter(a => a.assignment_executed).length
  const effort = data.filter(a => a.great_effort).length

  const kicks = data.filter(a => a.kicks).length
  const receptions = data.filter(a => a.receptions).length
  const total_carries = data.filter(a => a.total_carries).length
  const turnovers = data.filter(a => a.turnovers).length
  const tackles = data.filter(a => a.tackles).length
  const pancakes = data.filter(a => a.pancakes).length
  const cut_blocks = data.filter(a => a.cut_blocks).length
  const punts = data.filter(a => a.punts).length

  const td = data.filter(a => a.td).length
  const sacks = data.filter(a => a.sacks).length
  const penalties = data.filter(a => a.penalties).length
  const flags = data.filter(a => a.flags).length

  return (
    <Row gutter={24}>
      <Col span={8}>
        <Metric2 progress={plays} title='Total plays graded' />
      </Col>
      <Col span={8}>
        <Metric
          progress={(assignment / plays) * 100}
          title='Assignments Score'
        />
      </Col>
      <Col span={8}>
        <Metric
          progress={(effort / plays) * 100}
          up={true}
          title='Effort Score'
        />
      </Col>
      <Col span={8}>
        <Metric2 progress={penalties} up={true} title='Penalty Flags' />
      </Col>

      <Col span={8}>
        <Metric2
          progress={plays - assignment}
          title='Total Assignments Failed'
        />
      </Col>
      <Col span={8}>
        <Metric2 progress={plays - effort} title='Total Poor Effort Plays' />
      </Col>

      {pancakes ? (
        <Col span={8}>
          <Metric2 progress={pancakes} title='Total Pancakes' />
        </Col>
      ) : (
        ''
      )}

      {cut_blocks ? (
        <Col span={8}>
          <Metric2 progress={cut_blocks} up={true} title='Total Cut Blocks' />
        </Col>
      ) : (
        ''
      )}

      {punts ? (
        <Col span={8}>
          <Metric2 progress={punts} title='Total Punts' />
        </Col>
      ) : (
        ''
      )}

      {tackles ? (
        <Col span={8}>
          <Metric2 progress={tackles} title='Total Tackles' />
        </Col>
      ) : (
        ''
      )}

      {turnovers ? (
        <Col span={8}>
          <Metric2 progress={turnovers} title='Total Turnovers' />
        </Col>
      ) : (
        ''
      )}

      {total_carries ? (
        <Col span={8}>
          <Metric2 progress={total_carries} title='Total Carries' />
        </Col>
      ) : (
        ''
      )}

      {receptions ? (
        <Col span={8}>
          <Metric2 progress={receptions} up={true} title='Receptions' />
        </Col>
      ) : (
        ''
      )}

      {td ? (
        <Col span={8}>
          <Metric2 progress={td} title='Total TD' />
        </Col>
      ) : (
        ''
      )}

      {sacks ? (
        <Col span={8}>
          <Metric2 progress={sacks} up={true} title='sacks' />
        </Col>
      ) : (
        ''
      )}
    </Row>
  )
}
function Metric ({ progress = 0, up = false, title, subtitle }) {
  return (
    <Card title={null} className='metric'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <div className='title'>
          <span className='text'>
            <p style={{ fontSize: '15px' }}>{title}</p>
            <small>{subtitle}</small>
          </span>
        </div>
        <Progress
          width={70}
          strokeColor='#c12730f0'
          format={() => `${Math.round(progress)}%`}
          type='circle'
          percent={Math.round(progress)}
        />
      </div>
    </Card>
  )
}
function Metric2 ({ progress = 0, up = false, title, subtitle }) {
  return (
    <Card title={null} className='metric'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <div className='title'>
          <span className='text'>
            <p style={{ fontSize: '15px' }}>{title}</p>
            <small>{subtitle}</small>
          </span>
        </div>
        <div>
          <h2 style={{ fontSize: '50px' }}>{progress}</h2>
        </div>
      </div>
    </Card>
  )
}
const mapStateToProps = state => {
  return {
    profileDetails: _.get(state, 'app.account.profile.data.details')
  }
}

const mapDispatchToProps = dispatch => ({
  gamesView: details => dispatch(global.redux.action.games.view(details)),
  gradesList: details => dispatch(global.redux.action.grades.list(details)),
  gridironDetails: details =>
    dispatch(global.redux.action.grades.gridironDetails(details))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluvationReportViewScreen)
