import React from "react";
import { Modal } from "antd";

function LoginModal({ onOpen, onCancel, title }) {
  return (
    <Modal
      onCancel={onCancel}
      closable={true}
      open={onOpen}
      title={
        <span className='text-[#0F1A24] text-[14px] font-bold font-inter'>
          {title}
        </span>
      }
      width={441}
      footer={null}
      style={{
        borderRadius: "8px",
        top: "30%",
      }}>
      <div className='flex flex-col mt-[36.5px]'>
        <button className='flex items-center justify-center font-inter font-bold text-[#ffffff] text-[14px] bg-[#1A91FF] h-[43px] w-[69px] self-center rounded-[4px]' onClick={onCancel}>
          OK
        </button>
      </div>
    </Modal>
  );
}

export default LoginModal;
