import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, Spin, notification, Upload, Tag, Modal } from 'antd';
import axios from 'axios';
import { geocodeByAddress } from 'react-places-autocomplete';
import { EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input/input';

const { Option } = Select;

const CoachesUpdateComponent = (props) => {

    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);

    const [visible, setVisible] = useState(false);
    const [statusType, setStatusType] = useState('');
	const [statusValue, setStatusValue] = useState(0);
    const [photo_display, setphoto_display] = useState(null);
    const [loading, setLoading] = useState(false);
    const [changeStatusButtonFlag, setChangeStatusButtonFlag] = useState(0);
    const [coachDetails, setCoachDetails] = useState({});
    const [positionsList, setPositionsList] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);

    let history = useNavigate();

    const [form] = Form.useForm();
    useEffect(() => {
        fetchCoachesViewData();
    }, []);

     useEffect(() => {
        setChangeStatusButtonFlag(coachDetails.status);
    }, [coachDetails.status]);

    useEffect(() => {
        gridironDetails()
    }, []);

 
    const gridironDetails = () => {
		props.gridironDetails({
			callback: (res, data) => {
				if (res === true) {
					let final_data =[]
					 
					setPositionsList(_.chain(data) .get('data.data.positions') .pick(['offense', 'defense', 'special_teams']) .values().flatten() .map('code') .value())
                }
            }
		})
	};

    function normalize(phone) {
        //normalize string and remove all unnecessary characters
        phone = phone.replace(/[^\d]/g, "");

        //check if number length equals to 10
        if (phone.length == 10) {
            //reformat and return phone number
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }

        return null;
    };

    const fetchCoachesViewData = () => {
        const params = new URLSearchParams(window.location.search);
        let coaches_id = params.get('id');

        props.getCoachesViewData({
            params: { id: coaches_id },
            callback: (res, responseData) => { 
                setCoachDetails(_.get(responseData,'data.details', {}));

                setFormFieldsData(_.chain(responseData).get('data.details', {}).pick([ 'email', 'phone', 'zip_code','positions']).value());
                if (_.get(responseData, 'data.details.address') && _.get(responseData, 'data.details.location')) {
                    let split_location = _.split(_.get(responseData, 'data.details.location.lat'), ', ') 
                    const address_results = [{
                        text: _.get(responseData, 'data.details.address'),
                        value: JSON.stringify({ text: _.get(responseData, 'data.details.address'), lat: _.get(responseData, 'data.details.location.x'), lng: _.get(responseData, 'data.details.location.y'), postal_code: '' }),
                    }]
                    form.setFieldsValue({
                        location: JSON.stringify({ text: _.get(responseData, 'data.details.address'), lat: _.get(responseData, 'data.details.location.x'), lng: _.get(responseData, 'data.details.location.y'), postal_code: '' }),
                    })
                    setaddress_results(address_results)
                    
                }
                form.setFieldsValue({
                    first_name:  _.startCase(_.get(responseData,'data.details.first_name')),
                })
                form.setFieldsValue({
                    last_name:  _.startCase(_.get(responseData,'data.details.last_name')),
                })
                form.setFieldsValue({
                    city:  _.startCase(_.get(responseData,'data.details.city')),
                })
                let coach_profile_photo_filename = _.chain(responseData).get('data.details.photo', '').trim().value();
                if(!_.isEmpty(coach_profile_photo_filename)){
                    setphoto_display(`${_.get(responseData, 'data.meta.photo.path')}/${_.get(responseData, 'data.meta.photo.folder')}/512/${coach_profile_photo_filename}`)
                }
            }
        });
    };

    const setFormFieldsData = (formFieldsData) => {

        form.setFieldsValue(formFieldsData);
    };
    const lowercaseFirstLetter = (str) =>{

		// converting first letter to lowerCase
		const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());
	
		return lowerCase;
	}
    const handleSubmit = e => {
        setButtonLoading(true)
        const params = new URLSearchParams(window.location.search);
        let coaches_id = params.get('id');
        let address_parse = _.get(e, 'location')? JSON.parse(_.get(e, 'location', {})):{}
        let details = {
            id: coaches_id,
            email:lowercaseFirstLetter(_.get(e, 'email')),
            first_name: _.get(e, 'first_name'),
            last_name: _.get(e, 'last_name'),
            city: _.get(e, 'city'),
            // zip_code: _.get(e, 'zip_code'),
            positions: _.join(_.get(e, 'positions'),','),
            // location:_.get(e, 'location') && `${_.get(address_parse, 'lat')}, ${_.get(address_parse, 'lng')}`,
            // address:_.get(address_parse,'text')
        };

        props.getCoachesUpdateData({
            body: details,
            callback: (res, responseData) => {
                if (res == true) {
                    if (_.get(e,'phone')) {
                        updatePhone(e, coaches_id, _.get(responseData, 'message'))
                    }else{
                        notification.success(
                            { message: 'Success', description: _.get(responseData, 'message') }
                        )
                        history('/coach-list')
                    }
                   

                    // Coaches has been updated successfully.
                    // history('/coaches-list')
                }else{
                    setButtonLoading(false)
                } 
            }
        });


    };
    const updatePhone = (e, coaches_id, message) => {
        let details_phone = {
            id: coaches_id,
            phone: _.get(e, 'phone'),
            // normalize(_.get(e, 'phone')),

        }
        props.UpdatePhoneData({
            body: details_phone,
            callback: (res, responseData) => {
                if (res == true) {
                    setButtonLoading(false)
                    notification.success(
                        { message: 'Success', description: message }
                    )
                    history('/coach-list')
                    updatePhoto(e, coaches_id)

                }else{
                    setButtonLoading(false)
                }

            }
        });
    }

    const updatePhoto = (data, coaches_id) => { 
        if (_.get(data, 'photo.file.originFileObj')) {


            let formData = new FormData()
            formData.append('id', coaches_id)
            formData.append('photo', _.get(data, 'photo.file.originFileObj'))
            props.UpdatePhotoData({
                body: formData,
                callback: (res, responseData) => {
                    props.getCoachesViewData({
                        params: { id: coaches_id },
                        callback: (res, responseData) => { }})
                    if (res == true) {
                    }

                }
            });
        }
    };

    const  onFinish = (e, coaches_id) => {
        const params = new URLSearchParams(window.location.search);
        let school_id = params.get('id');
        let details = {
            // id: school_id,
            status: _.get(e, 'status'),
            status_glossary: _.get(e, 'status_glossary')
        } 

        props.changeStatus({
            body: details,
            params: { id: 4 },
            callback: (res, responseData) => {
                if (res == true) {
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    );
                    setChangeStatusButtonFlag(details.status);
                    setVisible(false)
                    fetchCoachesViewData()
                } 
            }
        });
    };

    const showModal = (type, status) => {
        setVisible(true);
        setStatusType(type);
        setStatusValue(status);
        
        setTimeout(() => {
          let changeStatusFormData = _.pick(coachDetails, ['status', 'status_glossary']);
          changeStatusFormData.status = { key: changeStatusFormData.status, label: changeStatusFormData.status == 1 ? "Active": "Deactivate"};
          form.setFieldsValue(changeStatusFormData);
        }, 1000);
      };

    const  onFinishFailed = (e) => {

    };

   const handleOk = () => {
    setVisible(false);
      };
    
    const  handleCancel = () => {
        setVisible(false);
      };

    //Address search
    const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => { 
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));

            setaddress_results(address_results)
            setaddress_fetching(false);

        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);

    const fetchPostalCode = address_components => {


        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const removeImage = info => {
    };

    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onChangePhoto = info => { 
        getBase64(info.file.originFileObj, (imageUrl) => {
            setphoto_display(imageUrl);
        });
    };

    const render = () => {
        const { editMode, post } = props;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div className="UpdateCoachesForm">
                <div className="container">
                    <div className="PlayerprimaryCard">
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            layout="vertical"
                            className="row"
                            onFinish={handleSubmit}
                            form={form}
                        >
                            <Form.Item
                                className="col-12 col-md-12"
                                label="Coaches Profile Picture"
                                name="photo"
                                rules={[{ required:photo_display?false: true, message: 'Please input your photo!' }]}

                            >
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onRemove={removeImage}
                                    onChange={onChangePhoto}
                                    customRequest={dummyRequest}
                                >
                                    {photo_display ? <img src={photo_display} alt="avatar" style={{ width: '100%', borderRadius: '100px' }} /> : uploadButton}

                                </Upload>
                            </Form.Item>
                            <Form.Item
                                className="col-12 col-md-6"
                                label="First name"
                                name="first_name"
                                rules={[{ required: true, message: 'Please input your First name!' }]}>
                                <Input style={{width: 250}}/>
                            </Form.Item>
                            <Form.Item
                                className="col-12 col-md-6"
                                label="Last name"
                                name="last_name"
                                rules={[{ required: true, message: 'Please input your last name!' }]}

                            >
                                <Input style={{width: 250}} />
                            </Form.Item>
                            <Form.Item
                                className="col-12 col-md-6"
                                label="Email"
                                name="email" 
                                rules={[{ required: true, message: 'Please input your email!' }]}>
                                <Input type="email" style={{width: 250}}/>
                            </Form.Item>
                            <Form.Item
                                className="col-12 col-md-6"
                                label="Phone"
                                name="phone" 
                                rules={[{ required: true, message: 'Please input your phone number!' }]}>
                                <Input  style={{width: 250}}/>
                            </Form.Item>
                            <Form.Item
                                className="col-12 col-md-6"
                                label="City"
                                name="city"
                                rules={[{ required: true, message: 'Please input your city!' }]}>
                                <Input style={{width: 250}}/>
                            </Form.Item>
                            {/* <Form.Item
                                className="col-12 col-md-6"
                                label="Location"
                                name="location"
                                rules={[{ required: true, message: 'Please input your location!' }]}
                            >
                                <Select getPopupContainer={trigger => trigger.parentNode}
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={search => search ? addressDelayedQuery(search) : ''}
                                    //   onChange={(e) => onChangeForm(e, 'address')}
                                    placeholder={'Search address...'}
                                    notFoundContent={
                                        address_fetching ? (
                                            <Spin size="small" />
                                        ) : null
                                    }
                                >
                                    {address_results && address_results.length > 0 && address_results.map(d => (
                                        <Select.Option key={d.value}>{d.text}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                            {/* <Form.Item
                                className="col-12 col-md-6"
                                label="Zip-Code"
                                name="zip_code"
                                rules={[{ required: true, message: 'Please input your zip code!' }]}>
                                <Input style={{width: 250}}/>
                            </Form.Item> */}
                            <Form.Item
                            className="col-12 col-md-6"
                            label="Positions"
                            name="positions"
                            rules={[{ required: true, message: 'Please input your position!' }]}
                        >
                            <Select
                            mode="multiple"
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{width: 250}}
                            >
                                {_.map(positionsList,(value,index)=>{
														return <Option value={value} key={value}>
														 {value=="WR"?"WR/TE":value}
														</Option>
													})}
                            </Select>
                            </Form.Item>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                                <div className="CategoriesViewList">
                                    <div className="TextRegular mb-2">Status</div>
                                    <div className="TextSemiBold">
                                        { changeStatusButtonFlag == 1 ? (
                                            <Tag color="green">Active</Tag>
                                        ) : (
                                            <Tag color="red">In Active</Tag>
                                        )}
                                        </div>
                                </div>
                                <div>
                                    <div className="CategoriesViewList">
                                        <div className="mt-2">
                                            {changeStatusButtonFlag == 0 ? (
                                                <Button style={{ backgroundColor: 'green'}}
                                                    onClick={() => showModal("status", 1)}
                                                >
                                                    Active
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary" danger onClick={() => showModal("status", 0)}
                                                >
                                                    Deactivate
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal
                                    visible={visible}
                                    title="Change Status"
                                    //   onOk={this.handleOk}
                                    onCancel={handleCancel}
                                    footer={null}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="CategoriesViewList">
                                                <Form
                                                    scrollToFirstError={true}
                                                    onFinish={onFinish}
                                                    onFinishFailed={onFinishFailed}
                                                    form={form}
                                                >
                                                    <Form.Item
                                                        label="Status"
                                                        name="status"
                                                        rules={[
                                                            {
                                                            required: true,
                                                            message: "This field required!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select">
                                                            <Option value="1">Active</Option>
                                                            <Option value="0">Deactivate</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Reason for changing status"
                                                        name="status_glossary"
                                                        rules={[
                                                            {
                                                            required: true,
                                                            message: "This field required!",
                                                            },
                                                        ]}
                                                        >
                                                            <Input.TextArea />
                                                    </Form.Item>

                                                    <div
                                                        className="text-align-end"
                                                        style={{ textAlign: "end" }}
                                                    >
                                                        <Button
                                                            className="mr-2"
                                                            onClick={handleCancel}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            htmlType="submit"
                                                            type="primary"

                                                        // onClick={this.handleOk}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            <Form.Item className="col-12 col-md-12 text-right">
                                <Button type="primary" style={{marginRight:"15px"}} htmlType="submit" loading={buttonLoading} >
                                    Update
                                </Button>
                                <Button disabled={buttonLoading} className="ant-btn " type="ghost" onClick={()=>{history('/coach-list')}}>
									Cancel
								</Button>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </div>
        );

    };
    return render();
};

const mapStateToProps = state => (
    {
        CoachesViewData: state

    });

const mapDispatchToProps = dispatch => ({
    getCoachesViewData: details => dispatch(global.redux.action.coaches.view(details)),
    getCoachesUpdateData: details => dispatch(global.redux.action.coaches.update(details)),
    UpdatePhoneData: details => dispatch(global.redux.action.coaches.updatephone(details)),
    UpdatePhotoData: details => dispatch(global.redux.action.coaches.updatephoto(details)),
    changeStatus: details => dispatch(global.redux.action.coaches.changeStatus(details)),
    gridironDetails: details => dispatch(global.redux.action.grades.gridironDetails(details))

});

export default connect(mapStateToProps, mapDispatchToProps)(CoachesUpdateComponent);
