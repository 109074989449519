import React, { useState } from "react";
import save from "../../../assets/img/save.png";
import trashicon from "../../../assets/img/trash.png";
import PositionDropdown from "../home-page/PositionDropdown";
import { Select, Dropdown, Switch, Button, Spin, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function EditCoachModal({
  onClose,
  email,
  positions,
  firstName,
  lastName,
  type,
  phone,
  gridironDetails,
  updateCoach,
  id,
  loadCoachesList,
  deleteCoach,
}) {
  const [selectedText, setSelectedText] = useState(positions);
  const [formValues, setFormValues] = useState({
    firstName,
    lastName,
    email,
    phone,
    type,
    positions,
    id,
  });
  const [loading, setLoading] = useState(false);
  const [deleteCoachLoading, setCoachLoading] = useState(false);
  const [coachType, setCoachType] = useState(2);
  const handleChange = (e) => {
    let { name, value } = e.target;
    console.log({ name, value }, formValues);
    if (name == "type") {
      value = formValues.type == 0 ? 1 : 0;
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleUpdateCoach = () => {
    setLoading(true);
    console.log(formValues);
    let body = {
      first_name: formValues.firstName,
      last_Name: formValues.lastName,
      positions: formValues?.positions.toString(),
      email: formValues.email,
      phone: formValues.phone,
      id: formValues.id,
      type: formValues.type,
    };
    console.log("edit coach", body);
    updateCoach({
      body,
      callback: (res, responseData) => {
        console.log(res, responseData);
        if (res == true) {
          onClose();
          loadCoachesList();
          console.log("res from update coach is", res);
        }
        console.log(res);
        setLoading(false);
      },
    });
  };

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleDeleteCoach = async () => {
    try {
      setCoachLoading(true);
      await deleteCoach(id);
      onClose();
    } catch (error) {
      console.error("Error while deleting coach:", error);
    } finally {
      setCoachLoading(false);
    }
  };

  const handleSwitchChange = (checked) => {
    if (checked) {
      deleteCoach(id, 1);
    } else {
      deleteCoach(id, 0);
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="mt-[10px] flex flex-col">
        <span className="font-inter text-[#2E4D6B] text-[14px] font-normal">
          Position{" "}
        </span>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          defaultValue={formValues.positions || []}
          placeholder="Select Position(s)"
          className=" mt-[5px] text-[#0F1A24] w-full rounded-[4px]  "
          onChange={(value) =>
            handleChange({
              target: {
                name: "positions",
                value,
              },
            })
          }
          optionLabelProp="label"
          options={[
            {
              label: (
                <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                  Defensive unit
                </span>
              ),
              options:
                gridironDetails?.player_positions?.defense[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },
            {
              label: (
                <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                  Offensive unit
                </span>
              ),
              options:
                gridironDetails?.player_positions?.offense[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },

            {
              label: (
                <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                  Special Teams
                </span>
              ),
              options:
                gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },
          ]}
        />
        <div className="flex items-center w-full justify-between mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
          <span>Status</span>
          {/* <div className="coachstatusswitchdiv">
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
              onChange={handleSwitchChange}
            />
          </div> */}
        </div>
        <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
          First Name:
        </span>
        <input
          CoachType="text"
          className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
          value={formValues.firstName}
          onChange={handleChange}
          name="firstName"
        />
        <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
          Last Name:
        </span>
        <input
          CoachType="text"
          className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
          value={formValues.lastName}
          onChange={handleChange}
          name="lastName"
        />
        {/* <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
          Email:
        </span>
        <input
          CoachType="text"
          className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
          value={formValues.email}
          onChange={handleChange}
          name="email"
        /> */}
        {/* <span className="mt-[10px] font-inter text-[#0F1A24] text-[14px] font-bold">
          Cell Number:
        </span>
        <input
 
          CoachType="text"
 
           value={formValues.phone}
          onChange={handleChange}
          name="phone"
          type="text"
 
          className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px]  rounded-[4px] border border-[#EAEDF0]"
          placeholder="+1 214-417-3035"
        /> */}
        {/* <div className='w-full cursor-pointer border-[2px] border-solid bg-[#1A91FF] flex items-center justify-center h-[43px] mb-[8px] rounded-[4px] mt-[10px]'>
          <div
            className='w-[13%] flex items-center justify-between font-bold text-[14px] font-inter text-[white]'
            onClick={handleUpdateCoach}>
            <img src={save} />
            <span>Save</span>
          </div>
        </div> */}
        <div className="text-[#2E4D6B] my-3 text-[14px] flex items-center">
          <Checkbox
            onChange={handleChange}
            defaultChecked={formValues.type}
            value={formValues.type}
            name="type"
          />
          <span className="ml-[8px]">
            Do you want this coach to have admin rights to your account? Ex:
            Off/Def Coordinator, Special Teams
          </span>
        </div>
        <div className="w-full mb-[8px] mt-[10px]">
          <button
            onClick={handleUpdateCoach}
            className="w-full h-[43px] rounded-[4px] bg-[#1A91FF] border-1 border-solid border-[#1A91FF]"
          >
            <div className="flex items-center justify-center">
              {loading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} />{" "}
                  <span className="text-[#FFFFFF] ml-[2%]">Save</span>
                </>
              ) : (
                <>
                  <img src={save} className="mr-[2%]" alt="Save Icon" />
                  <span className="text-[#FFFFFF]">Save</span>
                </>
              )}
            </div>
          </button>
        </div>

        <div
          className="w-full cursor-pointer border-[2px] border-[#A40E17] border-solid flex items-center justify-center h-[43px] mb-[8px] rounded-[4px]"
          onClick={handleDeleteCoach}
        >
          <div className=" flex items-center justify-center font-bold text-[14px] font-inter text-[#A40E17]">
            {deleteCoachLoading ? (
              <>
                <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} />{" "}
                <span className="text-[#A40E17] ml-[2%]">Delete</span>
              </>
            ) : (
              <>
                <img src={trashicon} className="mr-[2%]" />
                <span>Delete</span>
              </>
            )}
          </div>
        </div>
        <div
          className="w-full cursor-pointer border-[2px] border-solid border-[#A40E17] flex items-center justify-center h-[43px] rounded-[4px]"
          onClick={onClose}
        >
          <div className="flex items-center justify-between font-bold text-[14px] font-inter text-[#A40E17]">
            <i className="fas fa-xmark mr-[2%]"></i>
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCoachModal;
