import React, { useEffect, useState } from "react";
import {
  message,
  Card,
} from "antd";
import PlayerList from "./PlayerList";
import "./home-page.css";
import "./spin.css";
import { connect } from "react-redux";
import _ from "lodash";
import AddPlayer from "./AddPlayer.js";
import { useNavigate } from "react-router-dom";


const HomePageComponent = (props) => {
  const history = useNavigate();
  const [athletesList, setAthletesList] = useState([]);
  const [allAthletesList, setAllAthletesList] = useState([]);
  const [athletesListMeta, setAthletesListMeta] = useState({});
  const [athletesListLoading, setAthletesListLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      loadAthletesList();
      getGridironDetails();
    }
  }, []);

  const getGridironDetails = () => {
    props.getGridironDetails({});
  };

  const searchAthletesList = (search) => {
    setAthletesListLoading(true);
    props.list({
      params: {
        limit: 10,
        search_type: 0,
        search,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res == true && _.size(_.get(data, "data.items", [])) > 0) {
          setAthletesList(_.get(data, "data.items", []));
          setAthletesListMeta(_.get(data, "data.meta", {}));
        }
        setAthletesListLoading(false);
      },
    });
  };

  const loadAthletesList = (limit = 555, page = 1, positions = null) => {
    setAthletesListLoading(true);
    const params = {
      limit,
      page,
      expand: "athlete",
      athlete_profile_school: _.get(props, "coachProfile.school.id"),
    };
    if (positions) {
      params["positions"] = positions;
    }
    props.list({
      params,

      callback: (res, data) => {
        if (res) {
          setAthletesList(
            data?.data?.items?.sort((a, b) =>
              a?.athlete?.last_name?.localeCompare(b?.athlete?.last_name)
            )
          );
          setAthletesListMeta(_.get(data, "data.meta", {}));
          console.log("athletes list meta", _.get(data, "data.meta", {}));
          if(_.size(_.get(data, "data.items", [])) > 0){
            setAllAthletesList(_.get(data, "data.items", []));
            console.log("all athletes list", _.get(data, "data.items", []));
          }
        }
        setAthletesListLoading(false);
      },
    });
  };

  const render = () => {
    return (
      <div className="w-[98%] p-[2%]">
        {contextHolder}
        <div>
          {" "}
          <div>
            {/* <div className='back'> <p>Back to Week #1 Evaluation </p> </div> */}

            <div className="title">
              <span className="text-[24px] xl:text-[28px] text-[#0F1A24] font-bold font-poppins">
                Players
              </span>
              <AddPlayer
                onClick={() => {
                  history("/player-invite");
                }}
              />
            </div>
            <Card>
              <div className="w-[100%]">
                <PlayerList
                  athletesListLoading={athletesListLoading}
                  loadAthletesList={loadAthletesList}
                  allAthletesList={allAthletesList}
                  searchAthletesList={searchAthletesList}
                  athletesList={athletesList}
                  position={props.coachProfile?.positions?.toString()}
                  athletesListMeta={athletesListMeta}
                  depth_chart_options={props?.gridironDetails?.depth_chart?.all}
                  gridironDetails={props.gridironDetails}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({
  coachProfile: _.get(state, "app.account.profile.data.details"),
  gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  getDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.list(details)),
  createDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.creates(details)),
  updatePlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.updatePlayer(details)),
  addPlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.addPlayer(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.site.gridironDetails(details)),
  deleteDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.delete(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
