import React from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Divider } from "antd";

const formatNum = (val) => {
  if (isNaN(val)) return 0;
  return Math.round(val);
};

function findMinMax(array) {
  let min = Infinity;
  let max = -Infinity;

  array?.forEach((obj) => {
    const combinedValue = obj.Execution;

    if (combinedValue < min) {
      min = combinedValue;
    }
    if (combinedValue > max) {
      max = combinedValue;
    }
  });

  return { min, max };
}

const Legend = ({
  fill,
  title,
  Kicker,
  kickOff,
  kickReturn,
  punt,
  PuntReturn,
  FGBlock,
  FGAttempt,
  PAT,
  PATBlock,
  name,
}) => {
  return (
    <div
      className={`w-full h-[44px rounded-[5px] p-[12px] flex justify-between items-center text-[12px] font-bold text-[${fill}]`}
      style={{ border: "1px solid #EAEDF0" }}>
      <div className='flex items-center w-full'>
        <div className='flex items-center w-[13%] justify-between'>
          <div
            className={`w-[12px] h-[12px] rounded-full bg-[${fill}] mr-[4px]`}></div>
          <span className='mt-[3px] w-[75%] mr-[4px]'>{title}</span>
          <Divider type='vertical' style={{ height: "30px" }} />
        </div>
        <div className='w-[87%] flex items-center justify-around'>
          <div className='flex flex-col'>
            <span className='text-[8px] text-[#526B84]'>Kicker</span>
            <span>{isNaN(Kicker) ? 0 : Kicker}%</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-[8px] text-[#526B84]'>Kickoff</span>
            <span>{isNaN(kickOff) ? 0 : kickOff}%</span>
          </div>
          
          <div className='flex flex-col'>
            <span className='text-[8px] text-[#526B84]'>Punt</span>
            <span>{isNaN(punt) ? 0 : punt}%</span>
          </div>
         
          <div className='flex flex-col'>
            <span className='text-[8px] text-[#526B84]'>PAT</span>
            <span>{isNaN(PAT) ? 0 : PAT}%</span>
          </div>
          
          <div className='flex flex-col'>
            <span className='text-[8px] text-[#526B84]'>FG Attempt</span>
            <span>{isNaN(FGAttempt) ? 0 : FGAttempt}%</span>
          </div>
        
        </div>
      </div>
    </div>
  );
};

function KickingLineChartComponent({ data, title, titleTwo }) {
  const data2 = data?.map((d) => {
    return {
      name: d.instance,
      Execution: Math.round(d.Execution),
      Effort: Math.round(d.Effort),
    };
  });

  const { min: minY, max: maxY } = findMinMax(data2);

  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={10}
        fontWeight='700'
        fill='#526B84'
        fontSize='10px'
        textAnchor='middle'>
        {payload.value}
      </text>
    );
  };

  return (
    <div className='w-[100%] px-[10px] mx-auto flex font-inter flex-col justify-between items-center p-0 pb-[24px]'>
         
      {data?.length > 0 && (
        <div class='flex flex-col w-[100%] mx-auto mb-[24px] text-[12px] font-inter'>
          <Legend
            title={title}
            Kicker={Math.round(data[0].Execution)}
            kickOff={Math.round(data[1].Execution)}
            punt={Math.round(data[2].Execution)}
            PAT={Math.round(data[3].Execution)}
            FGAttempt={Math.round(data[4].Execution)}
            fill='#A40E17'
          />
          <div className='mt-[8px] mb-[16px]'>
            <Legend
              title={titleTwo}
              Kicker={Math.round(data[0].Effort)}
              kickOff={Math.round(data[1].Effort)}
              punt={Math.round(data[2].Effort)}
              PAT={Math.round(data[3].Effort)}
              FGAttempt={Math.round(data[4].Effort)}
              fill='#1A91FF'
            />
          </div>
        </div>
      )}
      <ResponsiveContainer width='100%' height={300}>
        <LineChart
          data={data2}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            padding={{ left: 30, right: 30 }}
            tick={renderXAxisTick}
          />
          <YAxis domain={[minY - 5, maxY + 5]} />
          <Tooltip />
          <Line
            type='linear'
            dataKey='Execution'
            stroke='#A40E17'
            activeDot={{ r: 4 }}
            strokeWidth={4}
          />
          <Line
            type='linear'
            dataKey='Effort'
            stroke='#1A91FF'
            activeDot={{ r: 4 }}
            strokeWidth={4}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default KickingLineChartComponent;
