import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import "./express.css";
import redLine from "../../assets/img/redLine.png";
import Basicinfo from "./basic-info";
import continueArrow from "../../assets/img/arrow-right.svg";
import Plan from "./plan";
import TrustingWardbord from "./trusting-wardbord";
import PaymentMethod from "./payment-method";
import cardSecurity from "../../assets/img/card-security.svg";
import InvoiceRequestSent from "./Invoice-request-sent";
import backArrow from "../../assets/img/arrow-leftblue.svg";
import { connect } from "react-redux";
import _ from "lodash";
import { message } from "antd";

function ExpressCheckout(props) {
  const [selectedSize, setSelectedSize] = useState(null);
  const [email, setEmail] = useState("");
  const [plansList, setPlansList] = useState(() => {
    const storedPlansList = sessionStorage.getItem("plansList") || null;

    return storedPlansList ? JSON.parse(storedPlansList) : [];
  });
  const [plan, setPlan] = useState(() => {
    const storedPlan = sessionStorage.getItem("plan") || null;
    return storedPlan ? JSON.parse(storedPlan) : {};
  });
  const [school, setSchool] = useState(() => {
    const storedSchool = sessionStorage.getItem("school") || null;
    console.log({ storedSchool })
    if (storedSchool  != 'undefined' ) {
      return storedSchool ? JSON.parse(storedSchool) : null; 
    } else {
        return  null
    }
     
  });

  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem("user") || null;
   
   
     if (storedUser  != 'undefined' ) {
       return storedUser ? JSON.parse(storedUser) : null;
    } else {
        return  null
    }
   
  });

  const [page, setPage] = useState(() => {
    const storedPage = sessionStorage.getItem("checkoutPage") || null;
    return storedPage ? JSON.parse(storedPage) : 1;
  });

  const fetchPlansList = () => {
    props.plansList({
      params: {},
      callback: (res, data) => {
        if (res === true) {
          console.log("data from plansList", data?.data);
          const paidPlans = data?.data?.pop();
          console.log("paidPlains", data?.data);
          setPlansList(data?.data || []);
          setPlan(data?.data[1]);
          sessionStorage.setItem("plansList", JSON.stringify(data?.data));
        }
      },
    });
  };

  useEffect(() => {
    fetchPlansList();
  }, [page]);

  const handleSendInvoice = () => {
    console.log(school?.id, plan?.id, selectedSize?.price, school?.email);
    props.sendInvoice({
      body: {
        school: school?.id,
        plan: plan?.id,
        amount: parseInt(selectedSize?.price),
        email: school?.email,
        others: {
          currency: "usd",
          payment_terms: "Billed Via Purchase Order",
          items: [
            {
              name: `Subscription to ${plan?.name}`,
              quantity: 1,
              unit_cost: selectedSize?.price,
            },
          ],
          notes: "Thanks for being an awesome customer!",
          terms: "You will be billed for this invoice once a year.",
        },
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from send invoice", data);
          // message.success(data?.message || '')
          setPage(5);
          const keysToRemove = [
            "newPaymentMethod",
            "plansList",
            "school",
            "planType",
            "selectedSize",
          ];
          keysToRemove.forEach((key) => {
            sessionStorage.removeItem(key);
          });
        }
      },
    }); 
  };

  useEffect(() => {
    sessionStorage.setItem("checkoutPage", page.toString());
    const storedPage = sessionStorage.getItem("checkoutPage");
    storedPage && setPage(Number(storedPage));
  }, [page]);

  return (
    <div className=" xl:h-screen grid grid-cols-5">
      <div
        className={`col-span-5 xl:col-span-3 flex flex-col items-start h-full overflow-y-scroll py-3`}
      >
        <div className="w-[70%] mx-auto ">
          <div className="w-[80%] flex xl:w-[90%] mx-auto py-4">
            {page > 1 && page !== 4 && page !== 5 && page !== 2 && (
              <button
                className=" px-4 py-2 flex  mx-auto items-center justify-center gap-2 text-[#1A91FF] text-[14px] bg-white disabled:opacity-50 absolute left-[1%] top-[1.5%] rounded-md"
                onClick={() => {
                  setPage(page - 1);
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "2px solid #1A91FF",
                }}
              >
                <img src={backArrow} />
                <span>Back</span>
              </button>
            )}
            <div className="text-[#2E4D6B] flex justify-center w-[100%]">
              <img src={logo} alt="logo" className="w-[45%]" />
              <div className=" flex items-center ml-[2%] w-[50%]">
                <img src={redLine} alt="line" className="mr-[2%]" />
                <span className="text-[#A40E17] font-poppins font-bold text-[18px]">
                  EXPRESS CHECKOUT
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] xl:w-[75%] mx-auto mt-12">
            {page == 1 && (
              <Basicinfo
                page={page}
                setPage={setPage}
                setEmail={setEmail}
                setSchool={setSchool}
                setUser={setUser}
              />
            )}
            {page == 2 && (
              <Plan
                page={page}
                setPage={setPage}
                plansList={plansList}
                setPlan={setPlan}
                setSelectedSize={setSelectedSize}
                selectedSize={selectedSize}
              />
            )}
            {page == 3 && (
              <PaymentMethod
                page={page} 
                plan={plan}
                school={school}
                user={user}
                selectedSize={selectedSize}
                setPage={setPage}
                email={email || ""}
                handleSendInvoice={handleSendInvoice}
              />
            )}
            {page == 4 && <TrustingWardbord page={page} setPage={setPage} />}
            {page == 5 && <InvoiceRequestSent />}
          </div>
        </div>
      </div>
      <div className="hidden xl:flex xl:col-span-2 h-full image-container"></div>
    </div>
  );
}
const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(global.redux.action.account.login(details)),
  register: (details) =>
    dispatch(global.redux.action.account.register(details)),
  plansList: (details) =>
    dispatch(global.redux.action.super_admin.plansList(details)),
  sendInvoice: (details) =>
    dispatch(global.redux.action.schools.send_invoice(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpressCheckout); // ExpressCheckout;
