import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
	DatePicker,
} from "antd";
import { connect } from "react-redux";
import _ from 'lodash';
import { BrowserRouter as Router, Link, useHistory, useNavigate } from "react-router-dom";

import MvpsViewModalComponent from "./mvps-view";
import "./mvps.css";

const Option = Select.Option;

const MvpsList = (props) => {
	const [TableData, setTableData] = useState('');
	const [showModal, setshowModal] = useState(false);
	const [mvpsList, setMvpsList] = useState([]);
	const [mvpsListMeta, setMvpsListMeta] = useState({});
	const [mvpsListLoading, setMvpsListLoading] = useState(true);
	const [page,setPage]=useState(1);
	
	let history = useNavigate();

	const showModalView = (item) => {
		setshowModal(true)
		setTableData(item)
	};

	const columns = [
        {
			title: 'Athlete',
			dataIndex: 'athlete',
            align: 'center',
			key: 'athlete',
		},
		{
			title: 'Title',
			dataIndex: 'title',
            align: 'center',
			key: 'title',
			render: (value) => getMVPTitle(value)
		},
        {
			title: 'Coach',
			dataIndex: 'coach',
            align: 'center',
			key: 'coach',
		},
		{
			title: 'Season',
			dataIndex: 'season',
			align: 'center',
			key: 'season',
		},
		{
			title: 'Week',
			dataIndex: 'week',
			align: 'center',
			key: 'week',
		},
		// {
		// 	title: 'Action',
		// 	dataIndex: 'action',
		// 	width: '20%',
		// 	align: 'center',
		// 	key: 'action',
		// 	render: (text, item) => (
		// 		<div className="games-list-action-btns">
		// 			<Button className="TableActionBtn edit" style={{marginRight:"8px"}} onClick={() => showModalView(item)}>View</Button>
		// 		</div>
		// 	),
		// },
  	];

	useEffect(() => {
		if (_.get(props,'profileDetails.id')) {
			loadMvpsList()
		}
		getGridironDetails();
	},[]);

 
	const getMVPTitle = (title_no) => {
		return _.chain(props).get("gridironDetails.mvps", [])
		.find({id: title_no}).get("name", "").value();
	};

	const getGridironDetails = () => {
		props.getGridironDetails({})
	};

	const loadMvpsList = (limit = 9, page = 1,season="", expand="athlete,coach") => {
		let params ={limit, page, expand, coach: _.get(props,'profileDetails.id'), status: '0,1'}
		if (season) {
			params.season=season
		}
		
		setMvpsListLoading(true)
		props.mvpsList({
			params: params,
			callback: (res, data) => {
				if (res === true) { 
					setMvpsListMeta(_.get(data, 'data.meta', {}))
					setAllMvpsList(_.get(data, 'data.items', []))
				}
				setMvpsListLoading(false)
			}
		});
	};

	const setAllMvpsList = (data) => { 
		let list = []
		if (_.size(data) > 0) {
			_.map(data, (value, index) => {
				list.push({
					key: index,
                    athlete: _.get(value, 'athlete.first_name'),
                    coach: _.get(value, 'coach.first_name'),
					title: _.get(value, 'title'),
					season: _.get(value, 'season'),
					week: _.get(value, 'week'),
					id: _.get(value, 'id'),
					status: _.get(value, 'status'),
					status_glossary: _.get(value, 'status_glossary')
				})
			})

			setMvpsList(list)
		} else {
			setMvpsList([])
		}
	};

	const hideModal = () => {
		setshowModal(false)
	};

	const onChangePage = (page, size) => {
		setPage(page)
		loadMvpsList(size, page)
	};

	const getDropList = () => {
		const year = new Date().getFullYear();
		const start_year = year -2
		const end_year = year +8
		const start_year_split =parseInt(`${start_year}`)

	  return (
		  Array.from( new Array(30), (v,i) =>
			<Option key={i} value={`${start_year+i}-${start_year_split+i+1}`}>{`${start_year+i}-${start_year_split+i+1}`}</Option>
		)
	  );
	};

	function onChange(value) {
		console.log(`selected ${value}`);
	};

	const onchangeFilter =(value,e)=>{
		  console.log('date month',value,e);
	};

	const onselectFilter =(e)=>{
		loadMvpsList(9,page,e)
	};
	
const render = () => {
		return (
			<div className="HomePageContainer">
				<div className="container">
					<div className="full-width">
						<div className="TableFilter">
							<div className="row"><span className="TableFilterText">Filter</span>
								<Select
									showSearch
									style={{ width: 150 }}
									placeholder="Select a year"
									onSelect={(e)=>onselectFilter(e)}
								>
									{getDropList()}
								</Select>
								{/* <div style={{marginLeft:"72%"}} className="plays-list-action-btns">
									<Link to={`/create-mvps`} className="ant-btn TableActionBtn edit">Create MVP</Link>
								</div> */}
							</div>
						</div>
						<div className="TableContainer">
							<Table bordered key={mvpsList.id} loading={mvpsListLoading} columns={columns} dataSource={mvpsList} size="middle" pagination={false} />
						</div>
						{mvpsListLoading == false && _.get(mvpsListMeta, 'total') > 10 && <div className="PaginationContainer">
								<span className="PaginationContainerInner">
									<span className="PaginationLabel">Total {_.get(mvpsListMeta, 'total') > 1 ? `${_.get(mvpsListMeta, 'total')} items` : `${_.get(mvpsListMeta, 'total')} item`}</span>
									<Pagination defaultCurrent={1}
									current={page}
										hideOnSinglePage={true}
										onChange={(page, size) => onChangePage(page, size)}
										total={_.get(mvpsListMeta, 'total')}
									// total={500}
									/>
								</span>
								<span className="PaginationContainerInner">
									<span className="PaginationLabel ml-3">Go to</span>
									<InputNumber min={1} max={_.get(mvpsListMeta, 'total') > 10 ? _.get(mvpsListMeta, 'total') / 9 : 2} onPressEnter={(e) => onChangePage(_.get(e, 'target.value'), 9)} style={{ width: '70px' }} />
								</span>
							</div>}
					</div>
				</div>
				<MvpsViewModalComponent isvisible={showModal} data={TableData} hideModal={hideModal} />
			</div>
		);
	};
	return render();
};

const mapStateToProps = state => {
	return	{
		profileDetails:_.get(state, 'app.account.profile.data.details'),
		gridironDetails:_.get(state, 'app.site.gridironDetails.data.data', {})
}   };

const mapDispatchToProps = dispatch => ({
	
	mvpsList: details => dispatch(global.redux.action.mvps.list(details)),
	getGridironDetails: details => dispatch(global.redux.action.site.gridironDetails(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(MvpsList)