import React from "react";
import { Table } from "antd";
import { stColumns } from "./statsColumns";
import { stColumnsPrint } from "./StatsColumnPrint";
function findHighestValue(arr) {
  if (arr.length === 0) {
      return undefined; // Return undefined for empty array
  }
  
  let max = arr[0]; // Assume first element as maximum
  
  for (let i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
          max = arr[i]; // Update max if current element is greater
      }
  }
  
  return max;
}
function StStats({ dataSource, pageLoading, print }) {
  return (
    <div className="stats-table">
      <Table
        dataSource={dataSource}
        columns={print ? stColumnsPrint : stColumns}
        bordered
        className="w-full"
        pagination={false}
        loading={pageLoading}
        summary={(pageData) => {
          let plays = 0;
          let total_positive_impact = 0;
          let total_punt_return = 0;
          let total_punt_return_yds = 0;
          let total_punt_return_yds_avg = 0;
          let total_max_punt_return_yds = [];
          let total_punt_return_fair_catch = 0;
          let total_punt_return_fair_catch_touchback = 0;
          let total_punt_return_td = 0;
          let total_kickoff_return = 0;
          let total_kickoff_return_yds = 0;
          let total_kickoff_return_yds_avg = 0;
          let total_max_kickoff_return_yds = [];
          let total_kickoff_touchback_fair_catch = 0;
          let total_kickoff_return_td = 0;
          let total_tackles_solo = 0;
          let total_tackles_ast = 0;
          let total_tackles_ast_halved = 0;
          let total_missed_tackles = 0;
          let total_net_tackles = 0;
          let total_punt_blocks = 0;
          let total_fg_blocks = 0;
          let total_caused_fumble = 0;
          let total_recovered_fumble = 0;
          let total_onsidekick_recovered = 0;
          let total_yards_after_recovery = 0;
          let total_st_td = 0;
          let total_fumble = 0;
          let total_fumble_lost = 0;
          let total_total_st_td = 0;
          let  total_two_part_conversion = 0;
          let total_yds = 0;

          pageData?.forEach((data) => {
            plays += Number(data?.plays);
            total_positive_impact += Number(data?.total_positive_impact);
            total_punt_return +=
              Number(data?.total_punt_return) +
              Number(data?.total_punt_return_fair_catch);
            total_punt_return_yds += Number(data?.total_punt_return_yds);
            if (
              !isNaN(data?.total_punt_return_yds) &&
              !isNaN(data?.total_punt_return) &&
              Number(data?.total_punt_return) !== 0 &&
              Number(data?.total_punt_return_yds) !== 0
            ) {
              total_punt_return_yds_avg +=
                Number(data?.total_punt_return_yds) /
                Number(data?.total_punt_return);
 
                 total_punt_return_yds_avg += Number(total_punt_return_yds_avg)?.toFixed(2);
 
            }
            total_max_punt_return_yds.push( Number(data?.max_punt_return_yds) || 0);
            total_punt_return_fair_catch += Number(
              data?.total_punt_return_fair_catch
            );
            total_punt_return_fair_catch_touchback += Number(
              data?.total_punt_return_fair_catch_touchback
            );
            total_punt_return_td += Number(data?.total_punt_return_td);
            total_kickoff_return += Number(data?.total_kickoff_return);
            total_kickoff_return_yds += Number(data?.total_kickoff_return_yds);
            if (
              !isNaN(data?.total_kickoff_return) &&
              !isNaN(data?.total_kickoff_return_yds) &&
              Number(data?.total_kickoff_return_yds) !== 0 &&
              Number(data?.total_punt_return_yds) !== 0
            ) {
              total_kickoff_return_yds_avg +=
                Number(data?.total_kickoff_return_yds) /
                Number(data?.total_kickoff_return);
 
           
            }
            total_max_kickoff_return_yds.push( Number(
              data?.max_kickoff_return_yds
            ));
            total_kickoff_touchback_fair_catch += Number(
              data?.total_kickoff_touchback_fair_catch
            );
            total_tackles_solo += Number(data?.total_tackles_solo);
            total_tackles_ast += Number(data?.total_tackles_ast);
            total_tackles_ast_halved +=
              Number(data?.total_tackles_ast_halved) +
              Number(data?.total_tackles_solo);
            total_missed_tackles += Number(data?.total_missed_tackles);
            total_net_tackles +=
              Number(data?.total_tackles_solo) +
              Number(data?.total_tackles_ast_halved) -
              Number(data?.total_missed_tackles);
            total_punt_blocks += Number(data?.total_punt_blocks);
            total_fg_blocks += Number(data?.total_fg_blocks);
            total_caused_fumble += Number(data?.total_caused_fumble);
            total_recovered_fumble += Number(data?.total_recovered_fumble);
            total_onsidekick_recovered += Number(
              data?.total_onsidekick_recovered
            );
            total_yards_after_recovery += Number(
              data?.total_yards_after_recovery
            );
            total_st_td += Number(data?.total_st_td); 
            total_fumble += Number(data?.total_fumble);
            total_fumble_lost += Number(data?.total_fumble_lost);
            total_two_part_conversion += Number(data?.total_two_part_conversion)
            total_total_st_td +=
              Number(data?.total_st_td) +
              Number(data?.total_punt_return_td) +
              Number(data?.total_kickoff_return_td);
              total_yds += Number(data?.total_punt_return_yds) + Number(data?.total_kickoff_return_yds);
          });
          return (
            <>
              <Table.Summary.Row
                align="center"
                style={{
                  background: "#F3F5F7",
                  fontWeight: "bold",
                }}
              >
                <Table.Summary.Cell index={0} colSpan={print ? 1 : 3}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {plays}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_positive_impact}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(Number(total_punt_return_yds) / Number(total_punt_return)).toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {findHighestValue(total_max_punt_return_yds)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return_fair_catch}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return_fair_catch_touchback}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_return_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_return}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_return_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(Number(total_kickoff_return_yds) / Number(total_kickoff_return))?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {findHighestValue(total_max_kickoff_return_yds)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_touchback_fair_catch}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_return_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_solo}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_ast}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_tackles_ast_halved}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_missed_tackles}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_net_tackles}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punt_blocks}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fg_blocks}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_caused_fumble}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_recovered_fumble}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_onsidekick_recovered}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_yards_after_recovery}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_st_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fumble}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fumble_lost}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_two_part_conversion}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_total_st_td}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_yds}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default StStats;
