import React from "react";
import { Popover } from "antd";
import scan from "../../../assets/img/scan.png";

function GotIdea() {
  return (
    <Popover
      content={
        <div className="w-[250px]">
          <img src={scan} className="w-full" />
        </div>
      }
      showArrow={false}
      placement="topLeft"
      trigger={"click"}
    >
      <span className="text-[14px] font-bold 2xl:text-[1vw] cursor-pointer">
        Got an idea?
      </span>
    </Popover>
  );
}

export default GotIdea;
