import React, { useState, useEffect, useRef } from "react";
import { Tooltip, Checkbox } from "antd";

const CheckboxGroup = Checkbox.Group;
export const ReceptionRadioButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  item,
  img,
  tooltipTitle,
  tooltipTitleThree,
  tooltipTitleTwo,
  tooltipTitleFour,
  imageTwo,
  imageThree,
  imageFour,
  value,
}) => {
  let parts = [];
  const options = [
    {
      label: (
        <Tooltip title={tooltipTitle}>
          <img src={img} />
        </Tooltip>
      ),
      value: 1,
    },
    {
      label: (
        <Tooltip title={tooltipTitleTwo}>
        <span className="text-[#A9B6C2] text-[14px] text-bold"> x </span>
        </Tooltip>
      ),
      value: 2,
    },
    {
      label: (
        <Tooltip title={tooltipTitleFour}>
          <span className="  text-[#A9B6C2] text-[14px] text-bold "> 1 </span>
        </Tooltip>
      ),
      value: 4,
    },
  ];

  const [checkedValues, setCheckedValues] = useState(false);
  const onChange = (checkedValues) => {
    setCheckedValues(checkedValues);
    let value = 0;
    if (checkedValues.length) {
      value = checkedValues?.reduce((a, b) => a + b);
    }
    handleQuickUpdate(item.id, item.athlete, title, value);
  };

  const handleButtonClick = (value) => {
    handleQuickUpdate(item.id, item.athlete, title, value);
  };
  useEffect(() => {
    processDefault();
  }, [defaultState]);

  const processDefault = () => {
    let _sum = defaultState;

    _sum = _valueBreakDown(_sum, 4);
    _sum = _valueBreakDown(_sum, 2);
    _sum = _valueBreakDown(_sum, 1);
    setCheckedValues(parts);
  };

  const _valueBreakDown = (_sum, part) => {
    if (_sum >= part) {
      parts.push(part);
      _sum = _sum - part;
    }
    return _sum;
  };

  return (
    <div className="discipline disciplinesradiodiv reception  actor flex w-[100%] items-center justify-center ">
      <div className="button-group flex w-[100%] items-center justify-center ">
        <CheckboxGroup
          options={options}
          value={checkedValues}
          onChange={onChange}
        />
      </div>
    </div>
  );
};