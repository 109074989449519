import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select, Checkbox } from "antd";
import Title from "./components/title.js";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";

function PuntModal({
  state,
  dispatch,
  close,
  update,
  getStats,
  school_id,
  item,
  updateGrade,
  updateInputGrade,
}) {
  const [modalWidth, setModalWidth] = useState(580);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(500);
    } else {
      setModalWidth(580);
    }
  }, [window.innerWidth]);

  return (
    <Modal
      open={state.punt}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
      title={
        <>
          
          <Title player={`${item?.first_name} ${item?.last_name}`} title='Punt'/>
        </>
      }
    >
      <div className="flex flex-col w-full mt-4">
        <div
          style={{ border: "1px solid #F3F5F7)" }}
          className="w-full flex flex-col text-sm font-bold text-[#20364B]"
        >
          <div className="w-full flex">
            <div
              style={{ border: "1px solid #F3F5F7)" }}
              className="w-[25%] flex items-center p-3 justify-center bg-[#F3F5F7]"
            >
              <span>Punt</span>
            </div>
            <div
              style={{ border: "1px solid #F3F5F7)" }}
              className="w-[20%] flex items-center p-3 justify-center turn-over actor"
            >
              <Input className="w-[70%]" size="large"/>
            </div>
            <div style={{ border: "1px solid #F3F5F7)" }} className="w-[25%] flex items-center p-3 justify-center">
              <Checkbox />
              <span className="font-normal ml-2">Inside 20</span>
            </div>
            <div style={{ border: "1px solid #F3F5F7)" }} className="w-[30%] flex items-center p-3">
              <Checkbox />
              <span className="font-normal ml-2">Fair Catch</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between text-md mt-4  w-full flex-col">
          <button
            onClick={close}
            className="flex items-center focus:outline-0 self-end justify-center w-[30%] bg-[#1A91FF] text-[#ffffff] h-8 rounded-[4px]"
          >
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PuntModal;