import React, { useEffect, useState } from "react";
import { Card, Select, Table, Popover, Checkbox, Input, message } from "antd";
import searchicon from "../../assets/img/search.svg";
import threeDot from "../../assets/img/Frame 18264.svg";
import glasses from "../../assets/img/glasses.svg";
import pencil from "../../assets/img/pencil.svg";
import eye from "../../assets/img/eye-empty.svg";
import "./super-admin.css";
import _ from "lodash";
import { connect } from "react-redux";
import CoachInfoModal from "./CoachInfoModal";

const { Option } = Select;
function Teams(props) {
  const [teamList, setTeamList] = useState([]);
  const [teamListStock, setTeamListStock] = useState([]);
  const [teamListLoading, setTeamListLoading] = useState(false);
  const [plansList, setPlansList] = useState([]);
  const [plansListLoading, setPlansListLoading] = useState(false);
  const [plan, setPlan] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [coachInfo, setCoachInfo] = useState({});
  const [player_size, setPlayerSize] = useState(null);
  const [isTrial, setIsTrial] = useState(false);
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [schoolInfo, setSchoolInfo] = useState({});
  const [price, setPrice] = useState(null);
  const [planName, setPlanName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [eventType, setEventType] = useState("");

  useEffect(() => {
    const plan_name = plansList?.find((item) => {
      return item?.id === plan;
    })?.name;
    setPlanName(plan_name);
  }, [plan]);

  const getPlansList = () => {
    setPlansListLoading(true);
    props.plansList({
      params: {},
      callback: (res, data) => {
        if (res === true) {
          console.log("data from plansList", data?.data);
          setPlansList(data?.data || []);
        }
        setPlansListLoading(false);
      },
    });
  };

  useEffect(() => {
    getPlansList();
  }, []);

  const createSubscription = () => {
    props.createSubscription({
      body: {
        isTrial: isTrial,
        isPaidPlan: isPaidPlan,
        isFreePlan: isFreePlan,
        school_id: schoolInfo?.school_id,
        roster_size: player_size,
        plan_id: plan,
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from createSubscription", data?.data);
          message.success("Subscription Created Successfully");
          getTeamList();
          // setPlansList(data?.data || []);
        }
        // setPlansListLoading(false);
      },
    });
  };

  const handleSendInvoice = () => {
    const bodyObject = {
      school: schoolInfo?.school_id,
      plan: schoolInfo?.plan,
      amount: price,
      email: newEmail,
      others: {
        currency: "usd",
        payment_terms: "Billed Via Purchase Order",
        items: [
          {
            name: `Subscription to ${planName}`,
            quantity: 1,
            unit_cost: price,
          },
        ],
        notes: "Thanks for being an awesome customer!",
        terms: "You will be billed for this invoice once a year.",
      },
    };

    console.log("Body Object:", bodyObject);
    console.log("school info", schoolInfo?.school_id, plan);
    props.sendInvoice({
      body: bodyObject,
      callback: (res, data) => {
        if (res === true) {
          console.log("data from send invoice", data);
          message.success("Invoice sent successfully");
          // setPlansList(data?.data || []);
        }
        // setPlansListLoading(false);
      },
    });
  };

  const handleUpgradeSubscription = () => {
    console.log("school info", schoolInfo?.id);
    props.upgradeSubscription({
      body: {
        current_subscription_id: schoolInfo?.id,
        new_plan_id: plan,
        isFreePlan: false,
        isTrial: false,
        isPaidPlan: true,
        roster_size: player_size.toString(),
        amount_paid: price,
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from upgrade subscription", data?.data);
          message.success("Subscription Upgraded Successfully");
          getTeamList();
        } else {
          console.log("data from upgrade subscription", data?.data);
        }
      },
    });
  };

  
  const planPriceList = {
    0: {},
    1: {
      20: 290,
      25: 370,
      35: 520,
      45: 670,
      55: 820,
      65: 970,
    },
    2: {
      20: 547,
      25: 687,
      35: 957,
      45: 1237,
      55: 1507,
      65: 1787,
    },
  };

  const calculatePrice = (selectedPlan, selectedSize) => {
    console.log({ selectedPlan, selectedSize }, planPriceList[selectedPlan]);
    const path = planPriceList[selectedPlan] || {};
    console.log("path", path[selectedSize], selectedPlan, selectedSize);
    setPrice(path[selectedSize] || 0);
    return path[selectedSize] || 0;
  };

  useEffect(() => {
    calculatePrice(plan - 1, player_size);
  }, [plan, player_size]);

  const columns = [
    {
      title: "School Name",
      dataIndex: "school_name",
      key: "school_name",
      align: "center",
      className: "font-medium",
      width: "20%",
    },
    {
      title: "Admin Listed",
      dataIndex: "admins",
      key: "admins",
      align: "center",
      className: "font-medium",
      width: "20%",
      render: (_, record) => {
        const admins = record.admins;
        const displayAdmins = admins.slice(0, 1);
        return (
          <div className="text flex flex-col">
            {displayAdmins.map((admin, index) => (
              <span key={index}>{admin?.admin_name}</span>
            ))}
            {admins.length > 1 && (
              <Popover
                trigger={"click"}
                placement="bottomLeft"
                arrow={false}
                content={
                  <div className="flex flex-col px-2">
                    {admins.slice(1).map((admin, index) => {
                      return admin.coach_type == 1 ? (
                        <span key={index} className="mb-2">
                          {admin?.admin_name}
                        </span>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                }
              >
                <button className="text-[#1A91FF] underline font-medium focus:outline-none">
                  See More
                </button>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "Plan",
      dataIndex: "plan_name",
      key: "plan_name",
      align: "center",
      width: "17%",
      render: (text) => (
        <div
          className={
            text?.toLowerCase() === "free plan" ||
            text?.toLowerCase() === "starter plan"
              ? "text-[#00B21E] bg-[#00B21E] p-2 w-[80%] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold"
              : text?.toLowerCase() === "advantage plan"
              ? "text-[#1A91FF] bg-[#1A91FF] bg-opacity-10 p-2 w-[80%] mx-auto rounded-lg text-[10px] font-bold"
              : text?.toLowerCase() === "engagement plan"
              ? "text-[#A40E17] bg-[#A40E17]  p-2 w-[80%] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold"
              : ""
          }
        >
          {text}
        </div>
      ),
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: "15%",
      render: (text) => {
        return (
          <div>{text?.split("T")[0]?.split("-")?.reverse()?.join("-")}</div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "18%",
      render: (text) => (
        <div
          className={
            text === 1
              ? "text-[#00B21E] bg-[#00B21E] w-[60%] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold p-2"
              : "text-[#2E4D6B] bg-[#2E4D6B] w-[60%] bg-opacity-10 mx-auto rounded-lg text-[10px] font-bold p-2"
          }
        >
          {text === 1 ? "Active" : "Cancel"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Popover
          arrow={false}
          trigger="click"
          placement="bottomRight"
          content={
            <div className="flex flex-col text-[#2E4D6B] text-md">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  console.log("record", record);
                  setShowModal(true);
                  setCoachInfo(record?.admins[0]);
                }}
              >
                {/* <img
                  src={glasses}
                  className="w-6 mr-3"
                 
                /> */}
                <span>Impersonate</span>
              </div>
              {/* <div className="flex items-center my-4 cursor-pointer">
                <img src={eye} className="w-6 mr-1" />
                <span>View Account</span>
              </div> */}
              <Popover
                arrow={false}
                trigger={"click"}
                placement="topRight"
                content={
                  <div
                    className={` ${
                      eventType === "sendInvoice" ? "w-[400px]" : "w-[300px]"
                    } flex fex-col`}
                  >
                    {eventType !== "sendInvoice" && (
                      <div className="flex flex-col w-full">
                        <div
                          style={{ border: "1px solid #EAEDF0" }}
                          className="rounded-md"
                        >
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Plan"
                            defaultValue={record?.plan}
                            bordered={false}
                            onChange={(value) => {
                              console.log("value", value);
                              setPlan(value);
                              if (value == 1) {
                                setIsFreePlan(true);
                                setIsPaidPlan(false);
                              } else {
                                setIsFreePlan(false);
                                setIsPaidPlan(true);
                              }
                            }}
                          >
                            {plansList?.map((plan) => (
                              <Option
                                className="text-[10px] xl:text-[0.97vw] mb-2 cursor-pointer"
                                value={plan?.id}
                                key={plan?.id}
                              >
                                {plan?.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <h2 className="text-[14px] font-semibold text-[#2E4D6B] mt-2">
                          Select Number Of Plyers:
                        </h2>
                        <div className="px-2 flex flex-col mt-1">
                          {[20, 25, 35, 45, 55, 65].map((value) => (
                            <div key={value} className="mb-2 text-[#2E4D6B]">
                              <Checkbox
                                onChange={() => setPlayerSize(value)}
                                checked={value === player_size}
                              >
                                <span className="text-black text-sm">
                                  {value}
                                </span>
                              </Checkbox>
                            </div>
                          ))}
                        </div>
                        {eventType === "add" && (
                          <div className="flex flex-col mt-2 px-2">
                            <h2 className="text-[14px] font-semibold text-[#2E4D6B] mb-1">
                              Check the box below if this is a trial
                            </h2>
                            <Checkbox
                              onChange={() => setIsTrial(!isTrial)}
                              checked={isTrial}
                            >
                              <span className="text-black text-sm">
                                Is It a Trial?
                              </span>
                            </Checkbox>
                          </div>
                        )}
                        {eventType === "add" && (
                          <button
                            className="bg-[#1A91FF] text-[#ffffff] rounded-md p-1 w-[70%] mx-auto font-medium mt-2 self-center"
                            onClick={() => createSubscription()}
                            disabled={plan === "" || player_size === ""}
                          >
                            Add Subscription
                          </button>
                        )}
                        {eventType === "upgrade" && (
                          <button
                            className="bg-[#1A91FF] text-[#ffffff] rounded-md p-1 w-[70%] mx-auto font-medium mt-2 self-center"
                            onClick={() => handleUpgradeSubscription()}
                            disabled={plan === "" || player_size === ""}
                          >
                            Upgrade Subscription
                          </button>
                        )}
                      </div>
                    )}
                    {eventType === "sendInvoice" && (
                      <div className="flex flex-col">
                        <span className="mb-3">
                          Please confirm the email you will like the invoice to
                          be sent to
                        </span>
                        <Input
                          type="text"
                          value={newEmail}
                          onChange={(e) => setNewEmail(e.target.value)}
                        />

                        <button
                          className="bg-[#1A91FF] text-[#ffffff] rounded-md p-1 w-[40%] mx-auto font-medium mt-3 self-center"
                          onClick={() => handleSendInvoice()}
                        >
                          Send Invoice
                        </button>
                      </div>
                    )}
                  </div>
                }
              >
                {!record?.plan && (
                  <div
                    className="flex items-center cursor-pointer mt-2"
                    onClick={() => {
                      setEventType("add");
                    }}
                  >
                    {/* <img src={pencil} className="w-4 mr-3" /> */}
                    <span>Add Subscription</span>
                  </div>
                )}
                {record?.plan && (
                  <div
                    className="flex items-center cursor-pointer mt-2"
                    onClick={() => {
                      console.log("record", record);
                      setEventType("upgrade");
                    }}
                  >
                    {/* <img src={pencil} className="w-4 mr-3" /> */}
                    <span>Upgrade Plan</span>
                  </div>
                )}
                {record?.plan !== null ||
                  (record?.plan_name?.toLowerCase() == "starter plan" && (
                    <div
                      className="flex items-center cursor-pointer mt-2"
                      onClick={() => {
                        console.log(
                          "record info",
                          record?.plan,
                          record?.roaster_size
                        );
                        console.log("record", record);
                        setPlanName(record?.plan?.name);
                        setPlan(record?.plan);
                        setPlayerSize(record?.roster_size);
                        setEventType("sendInvoice");
                      }}
                    >
                      {/* <img src={pencil} className="w-4 mr-3" /> */}
                      <span>Send Invoice</span>
                    </div>
                  ))}
              </Popover>
            </div>
          }
        >
          <img
            src={threeDot}
            className="bg-[#E8F4FF] rounded-lg py-2 px-3 mx-auto cursor-pointer"
            onClick={() => {
              setSchoolInfo(record);
              setNewEmail(record?.admins[0]?.admin_email);
            }}
          />
        </Popover>
      ),
    },
  ];

  const getTeamList = () => {
    setTeamListLoading(true);
    props.teamsList({
      params: {},
      callback: (res, data) => {
        if (res === true) {
          console.log("data from teamList", data?.data);
          setTeamList(data?.data || []);
          setTeamListStock(data?.data || []);
        }
        setTeamListLoading(false);
      },
    });
  };

  useEffect(() => {
    getTeamList();
  }, []);

  function filterBySchoolName(array, searchText) {
    const regex = new RegExp(searchText, "i"); // 'i' flag makes the search case-insensitive
    return array.filter((obj) => regex.test(obj.school_name));
  }
  const filterSchools = (text) => {
    const search = filterBySchoolName(teamListStock, text);
    console.log(search.length, text);
    setTeamList(search);
  };
  const filterSchoolsByStatus = (id) => {
    const search = teamListStock.filter(({ status }) => status == id);
    setTeamList(search);
  };
  const filterSchoolsByPlan = (id) => {
    const search = teamListStock.filter(({ plan }) => plan == id);
    setTeamList(search);
  };
  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Teams
      </h2>
      <Card bodyStyle={{ padding: "24px" }}>
        <div className="flex items-center">
          <div
            className="px-2 py-2 items-center justify-between flex w-[30%] md:w-[200px] mr-[4px] xl:w-[20%] rounded-md"
            style={{ border: "1px solid #EAEDF0" }}
          >
            <input
              type="text"
              placeholder="Search Teams"
              className="outline-0 w-[85%]"
              // value={searchText}
              onChange={(e) => filterSchools(e.target.value)}
            />
            <img src={searchicon} className=" w-[10%]" />
          </div>
          <div className="w-[200px] h-[43px] ml-[4px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center xl:w-[20%] rounded-md">
            <Select
              bordered={false}
              placeholder={"filter by subscription"}
              onChange={(value) => filterSchoolsByPlan(value)}
              className="w-[100%] text-[14px]"
            >
              {_.map(plansList, ({ id, name }, index) => {
                return (
                  <Option
                    value={id}
                    key={id}
                    className="text-[14px] font-bold font-inter"
                  >
                    {name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="w-[200px] h-[43px] ml-[4px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center xl:w-[20%] rounded-md">
            <Select
              bordered={false}
              placeholder={"Sort by Status"}
              onChange={(value) => filterSchoolsByStatus(value)}
              className="w-[100%] text-[14px]"
            >
              {_.map(
                [{ title: "Active", id: 1 }, { title: "Canceled" }],
                ({ id, title }, index) => {
                  return (
                    <Option
                      value={id}
                      key={id}
                      className="text-[14px] font-bold font-inter"
                    >
                      {title}
                    </Option>
                  );
                }
              )}
            </Select>
          </div>
        </div>
        <div className="mt-[20px]">
          <Table
            columns={columns}
            dataSource={teamList}
            bordered
            style={{ fontSize: "10px", color: "#0F1A24" }}
            loading={teamListLoading}
          />
        </div>
        {showModal && (
          <CoachInfoModal
            visible={showModal}
            setVisible={setShowModal}
            coachInfo={coachInfo}
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  teamsList: (details) =>
    dispatch(global.redux.action.super_admin.teamList(details)),
  plansList: (details) =>
    dispatch(global.redux.action.super_admin.plansList(details)),
  createSubscription: (componentRequest) =>
    dispatch(
      global.redux.action.super_admin.createSubscription(componentRequest)
    ),
  sendInvoice: (details) => {
    dispatch(global.redux.action.schools.send_invoice(details));
  },
  upgradeSubscription: (details) =>
    dispatch(global.redux.action.super_admin.upgradeSubscription(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
