import { UserData } from "./user-data.jsx";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Tooltip,
  Space,
  Table,
  message,
  InputNumber,
  Select,
  Switch,
  Checkbox,
  Pagination,
  Spin,
  Card,
  Modal,
  Empty,
} from "antd";
import MyDepthChart from "./MyDepthChart";
import SchoolDepthChart from "./SchoolDepthChart";
import PlayerList from "./PlayerList";

import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import CardModal from "./card-modal.js";
import "./home-page.css";
import "./spin.css";
import smileyDefault from "../../../assets/img/smiley-default.png";
import smileyactive from "../../../assets/img/smiley-active.png";
import footBall from "../../../assets/img/football.png";
import { connect } from "react-redux";
import _, { filter, isEmpty } from "lodash";
import { geocodeByAddress } from "react-places-autocomplete";
import AddPlayer from "./AddPlayer.js";
import addplayer from "../../../assets/img/addplayer.png";
import InvitePlayerModalContent from "./InvitePlayerModalContent.js";

const { Option } = Select;
const { TextArea } = Input;

const HomePageComponent = (props) => {
  const [showModal, setshowModal] = useState(false);
  const [cardData, setcardData] = useState({});
  const [athletesList, setAthletesList] = useState([]);
  const [allAthletesList, setAllAthletesList] = useState([]);
  const [athletesListMeta, setAthletesListMeta] = useState({});
  const [athletesListLoading, setAthletesListLoading] = useState(true);
  const [coachDepthChartLoading, setCoachDepthChartLoading] = useState(true);
  const [coachDepthChart, setCoachDepthChart] = useState([]);
  const [schoolDepthChart, setSchoolDepthChart] = useState([]);
  const [coachDepthChartId, setCoachDepthChartId] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [mode, setMode] = useState("My Depth Chart");
  const [whichDepthChart, setWhichDepthChart] = useState("mine"); // mine || others

  const handleModeChange = (e) => {
    setMode(e.target.value);
    console.log(mode);
  };

  const handleShowModal = () => {
    setshowModal(true);
    if (_.get(props, "coachProfile.id")) {
      loadAthletesList(555, 1, props.coachProfile?.positions?.toString());
    }
  };

  const handleOk = () => {
    setshowModal(false);
    loadAllAthletesList();
  };

  const handleCancel = () => {
    setshowModal(false);
    // loadAthletesList(555, 1, props.coachProfile?.positions?.toString());
  };

  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      loadAthletesList(555, 1);
      loadAllAthletesList();
      loadCoachDepthCharts();
      loadSchoolDepthCharts();
      getGridironDetails();
    }
  }, []);

  const getGridironDetails = () => {
    props.getGridironDetails({});
  };

  // const addToDepthChart = (id) => {
  // 	if (coachDepthChartId) {
  // 		createDepthChart
  // 		addPlayerDepthChart
  // 	} else {
  //          addPlayerDepthChart
  // 	}
  // }

  const handleAddPlayerDepthChart = (
    id,
    rank,
    depth_chart_position,
    athlete_position
  ) => {
    if (
      athlete_position.includes(depth_chart_position) ||
      depth_chart_position == "ST"
    ) {
      const depth_chart_id = coachDepthChartId;
      const coach_id = props.coachProfile.id;

      const player_id = id;
      const school_id = props.coachProfile.school.id;

      handlePopMessage("loading", "Adding player to depth chart...");

      props.addPlayerDepthChart({
        body: {
          depth_chart_id,
          coach_id,
          rank,
          player_id,
          school_id,
        },
        callback: (res, data) => {
          if (res == true) {
            sessionStorage.removeItem(`${depth_chart_id}-DepthChartPlayers`);
            handlePopMessage("success", "Player added...");
            // loadCoachDepthCharts();
          }
        },
      });
    } else {
      handlePopMessage(
        "error",
        "You can not add this player to this depth chart, the player plays a different position!"
      );
    }
  };
  const handleCreateDepthChart = (name, code, odk) => {
    const coach_id = props.coachProfile.id;
    const school = props.coachProfile.school.id;

    handlePopMessage("loading", "Adding depth chart to depth chart...");

    props.createDepthChart({
      body: {
        coach_id,
        name,
        code,
        odk,
        school,
      },
      callback: (res, data) => {
        console.log("data", res, "rs", data);
        if (res == true) {
          sessionStorage.removeItem(`CoachDepthCharts`);
          loadCoachDepthCharts();
          loadSchoolDepthCharts();
          handlePopMessage("success", "Depth chart added...");
        } else {
          console.log("data", res, "rs", data);
          handlePopMessage(
            "error",
            "Error creating Depth chart! Coach already has a depth chart with the same position"
          );
        }
      },
    });
  };

  const handleUpdateDepthChart = (
    depth_chart_id,
    player_id_1,
    player_id_2,
    rank_1,
    rank_2
  ) => {
    const coach_id = props.coachProfile.id;
    const school = props.coachProfile.school.id;

    handlePopMessage("loading", "Updating depth chart player ranks...");

    props.updatePlayerDepthChart({
      body: {
        depth_chart_id,
        player_id_1,
        player_id_2,
        rank_1,
        rank_2,
      },
      callback: (res, data) => {
        if (res == true) {
          sessionStorage.removeItem(`${depth_chart_id}-DepthChartPlayers`);
          handlePopMessage("success", "Depth chart updated...");
        }
      },
    });
  };

  const handlePopMessage = (
    type = "loading",
    content = "loading...",
    duration = 2
  ) =>
    messageApi.open({
      key: "updatable",
      type,
      content,
      duration,
    });

  const searchAthletesList = (search) => {
    setAthletesListLoading(true);
    props.list({
      params: {
        limit: 10,
        search_type: 0,
        search,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res) {
          setAthletesList(data?.data?.items);
        }
        setAthletesListLoading(false);
      },
    });
  };
  const loadAllAthletesList = () => {
    props.list({
      params: {
        limit: 1000,
        page: 1,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res) {
          setAllAthletesList(data?.data?.items);
        }
      },
    });
  };
  const loadAthletesList = (limit = 25, page = 1, positions = null) => {
    setAthletesListLoading(true);
    const params = {
      limit,
      page,
      expand: "athlete",

      athlete_profile_school: _.get(props, "coachProfile.school.id"),
    };
    if (positions) {
      params["positions"] = positions;
    }
    props.list({
      params,

      callback: (res, data) => {
        if (res) {
          setAthletesList(data?.data?.items);
        }
        setAthletesListLoading(false);
      },
    });
  };
  const loadCoachDepthCharts = (limit = 10000, page = 1) => {
    console.log("props.coachProfile", props.coachProfile);
    setCoachDepthChartLoading(true);
    props.getDepthCharts({
      params: { limit, page, coach_id: props.coachProfile.id },
      callback: (res, data) => {
        if (res == true && data) {
          setCoachDepthChart(data.data);
        }
        setCoachDepthChartLoading(false);
      },
    });
  };
  const loadSchoolDepthCharts = (limit = 10000, page = 1) => {
    props.getSchoolDepthCharts({
      params: { school: props?.coachProfile?.school?.id },
      callback: (res, data) => {
        if (res == true && data) {
          setSchoolDepthChart(data.data);
        }
      },
    });
  };
  const handleDeleteDepthChart = (depth_chart_id) => {
    try {
      handlePopMessage("loading", "Deleting depth chart...");
      props.deleteDepthChart({
        params: { id: depth_chart_id },
        callback: (res, data) => {
          console.log("response from deleting depth chart is", res);
          console.log("recieved for delete depth chart", data);
          if (res == true) {
            loadCoachDepthCharts();
            loadSchoolDepthCharts();
          }
        },
      });
    } catch (error) {
      console.error(error.message);
      handlePopMessage("error", error.message);
    }
  };

  const render = () => {
    return (
      <div className="w-[98%] p-[2%]">
        {contextHolder}
        <div>
          {" "}
          <div>
            {/* <div className='back'> <p>Back to Week #1 Evaluation </p> </div> */}

            <div className="title">
              <span className="text-[24px] xl:text-[28px] text-[#0F1A24] font-bold font-poppins">
                Depth Chart(s)
              </span>
            </div>
            <Card>
              <div>
                {props?.coachProfile?.type ? (
                  <div>
                    <Radio.Group
                      className="myswitchbtn"
                      type="primary"
                      value={whichDepthChart}
                      onChange={(e) => setWhichDepthChart(e.target.value)}
                    >
                      <Radio.Button value="mine">My Depth Charts</Radio.Button>
                      <Radio.Button value="others">
                        My Team's Depth Charts
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                ) : (
                  ""
                )}
                {whichDepthChart == "mine" ? (
                  <MyDepthChart
                    coachesListApi={props.coachesListApi}
                    handleUpdateDepthChart={handleUpdateDepthChart}
                    athletesListLoading={athletesListLoading}
                    loadAthletesList={loadAthletesList}
                    createDepthChart={handleCreateDepthChart}
                    athletesList={athletesList}
                    allAthletesList={allAthletesList}
                    depth_chart_options={
                      props?.gridironDetails?.depth_chart?.all
                    }
                    positions={props.gridironDetails.positions}
                    addToDepthChart={handleAddPlayerDepthChart}
                    coachDepthChartId={coachDepthChartId}
                    coachDepthChart={coachDepthChart}
                    coachDepthChartLoading={coachDepthChartLoading}
                    coachProfile={props.coachProfile}
                    setCoachDepthChartId={setCoachDepthChartId}
                    handleDeleteDepthChart={handleDeleteDepthChart}
                  />
                ) : (
                  <SchoolDepthChart
                    coachesListApi={props.coachesListApi}
                    handleUpdateDepthChart={handleUpdateDepthChart}
                    athletesListLoading={athletesListLoading}
                    loadAthletesList={loadAthletesList}
                    createDepthChart={handleCreateDepthChart}
                    athletesList={athletesList}
                    allAthletesList={allAthletesList}
                    depth_chart_options={
                      props?.gridironDetails?.depth_chart?.all
                    }
                    positions={props.gridironDetails.positions}
                    addToDepthChart={handleAddPlayerDepthChart}
                    coachDepthChartId={coachDepthChartId}
                    coachDepthChart={schoolDepthChart}
                    coachDepthChartLoading={coachDepthChartLoading}
                    coachProfile={props.coachProfile}
                    setCoachDepthChartId={setCoachDepthChartId}
                    handleDeleteDepthChart={handleDeleteDepthChart}
                  />
                )}
              </div>
            </Card>

            {/* this is the invite player modal */}
            {showModal === true && (
              <Modal
                
                closable={true}
                open={showModal}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                width={500}
                className="w-[485px] rounded-[8px]"
              >
                <InvitePlayerModalContent
                  onClick={handleOk}
                  gridironDetails={props.gridironDetails}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({
  coachProfile: _.get(state, "app.account.profile.data.details"),
  gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  getDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.list(details)),
  getSchoolDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.school(details)),
  createDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.creates(details)),
  updatePlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.updatePlayer(details)),
  addPlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.addPlayer(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.site.gridironDetails(details)),
  deleteDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.delete(details)),
  coachesListApi: (details) =>
    dispatch(global.redux.action.coaches.list(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
