import React from "react";
import { BrowserRouter as Switch, Route, router } from "react-router-dom";
import HomePageComponent from "../../containers/school-admin/home-page/index";
import LoginPageComponent from "../../containers/login/login";
import ContinueRegistration from "../../containers/register/continue-registration";
import ForgotPasswordComponent from "../../containers/login/forgot-password";
import SchoolsListComponent from "../../containers/school-admin/schools/schools-list";
import SchoolsViewComponent from "../../containers/school-admin/schools/schools-view";
import SchoolUpdateComponent from "../../containers/school-admin/schools/schools-update";
import SchoolsUpdatePhoneComponent from "../../containers/school-admin/schools/schools-update-phone";
import SchoolsUpdatePhotoModal from "../../containers/school-admin/schools/schools-update-photo";
import PlaysCreateComponent from "../../containers/school-admin/plays/create-plays";
import GamesCreateComponent from "../../containers/school-admin/games/create-games";
import LibraryPageComponent from "../../containers/school-admin/library/library";
import CreateCoachComponent from "../../containers/school-admin/coaches/create-coach";
import CoachesListComponent from "../../containers/school-admin/coaches/coaches-list";
import PlaysListComponent from "../../containers/school-admin/plays/plays-list";
import GamesListComponent from "../../containers/school-admin/games/games-list";
import CoachesUpdateComponent from "../../containers/school-admin/coaches/coach-update";
import CoachesUpdatePhoneComponent from "../../containers/school-admin/coaches/coach-update-phone";
import CoachesUpdatePhotoComponent from "../../containers/school-admin/coaches/coach-update-photo";
import ResetPasswordComponent from "../../containers/login/password-verification";
import ScheduleScreen from "../../containers/school-admin/schedule/schedule";
import ReportViewScreen from "../../containers/school-admin/report-view/report-view";
import EvaluvationReportViewScreen from "../../containers/school-admin/report-view/evaluvation-report";
import OverallGradeViewScreen from "../../containers/school-admin/report-view/overall-grade";
//Coach Games List
import CoachGamesListComponent from "../../containers/coach/games/games-list";
import MvpsList from "../../containers/coach/mvps/mvps-list";
import MvpsCreate from "../../containers/coach/mvps/create-mvps";
import MvpsViewModalComponent from "../../containers/coach/mvps/mvps-view";
import CreateInvitePlayers from "../../containers/coach/invite-players/create-invite-players";
import CoachEvaluation from "../../containers/coach/evaluations/coaches-list";
import ManagePlaylist from "../../containers/coach/evaluations/manage-playlist";
import CoachesNoteScreen from "../../containers/coach/evaluations/coaches-note";
import WardBordScreen from "../../containers/school-admin/wardbord/wardboad";
import CoachSchedule from "../../containers/coach/coach-schedule/coach-schedule";
import CoachWardbord from "../../containers/coach/coach-wardbord/coach-wardbord";
import ManageSchoolComponent from "../../containers/admin/manage-school";
import SchoolAdminComponent from "../../containers/admin/create-school-admin";
import PlayerListIndex from "../../containers/school-admin/home-page/PlayerIndex";
import PlayerInvite from "../../containers/school-admin/home-page/PlayerInvite";

import AllGamesList from "../../containers/admin/games/games-list";

// dashboard route
import SeasonAnalyticDashboard from "../../containers/Dashboard/Index";
import GameAnalyticsDashboard from "../../containers/Dashboard/GameAnalyticsDashboard";
import PlayerAnalyticsDashboard from "../../containers/Dashboard/PlayerAnalyticsDashboard";
import FormationAnalytics from "../../containers/Dashboard/FormationAnalytics";

//super admin screens
import Teams from "../../containers/super-admin/Teams";
import Coach from "../../containers/super-admin/Coach";
import Plans from "../../containers/super-admin/Plans";
import Dashboard from "../../containers/super-admin/Dashboard";

class LayoutContent extends React.Component {
  render() {
    return (
      <>
        <Route exact path="/" component={LoginPageComponent} />
        <Route path="/forgot-password" component={ForgotPasswordComponent} />
        <Route
          path="/password-verification"
          component={ResetPasswordComponent}
        />
        <Route
          path="/continue-registration"
          component={ContinueRegistration}
        />
        <Route path="/home" component={HomePageComponent} />
        <Route path="/schools-list" component={SchoolsListComponent} />
        <Route path="/schools-view" component={SchoolsViewComponent} />
        <Route path="/schools-update" component={SchoolUpdateComponent} />
        <Route
          path="/schools-update-phone"
          component={SchoolsUpdatePhoneComponent}
        />
        <Route
          path="/schools-update-photo"
          component={SchoolsUpdatePhotoModal}
        />
        <Route path="/create-plays" component={PlaysCreateComponent} />
        <Route path="/create-games" component={GamesCreateComponent} />
        <Route path="/library" component={LibraryPageComponent} />
        <Route exact path="/create-coach" component={CreateCoachComponent} />
        <Route exact path="/coach-list" component={CoachesListComponent} />
        <Route exact path="/plays-list" component={PlaysListComponent} />
        <Route exact path="/games-list" component={GamesListComponent} />
        <Route
          exact
          path="/season-games-list"
          component={CoachGamesListComponent}
        />
        <Route path="/coaches-update" component={CoachesUpdateComponent} />
        <Route
          path="/coaches-update-phone"
          component={CoachesUpdatePhoneComponent}
        />
        <Route
          path="/coaches-update-photo"
          component={CoachesUpdatePhotoComponent}
        />
        <Route path="/mvps-list" component={MvpsList} />
        <Route path="/create-mvps" component={MvpsCreate} />
        <Route path="/mvps-view" component={MvpsViewModalComponent} />
        <Route path="/create-invite-players" component={CreateInvitePlayers} />
        <Route exact path="/evaluation" component={CoachEvaluation} />
        <Route exact path="/manage-playlist" component={ManagePlaylist} />
        <Route exact path="/coaches-note" component={CoachesNoteScreen} />
        <Route exact path="/schedule" component={ScheduleScreen} />
        <Route exact path="/wardbord" component={WardBordScreen} />
        <Route exact path="/report-view" component={ReportViewScreen} />
        <Route
          exact
          path="/evaluvation-report"
          component={EvaluvationReportViewScreen}
        />
        <Route exact path="/overall-grade" component={OverallGradeViewScreen} />
        <Route exact path="/coach-schedule" component={CoachSchedule} />
        <Route exact path="/coach-wardbord" component={CoachWardbord} />
        <Route path="/manage-school" component={ManageSchoolComponent} />
        <Route
          exact
          path="/create-school-admin"
          component={SchoolAdminComponent}
        />
        <Route exact path="/all-games-list" component={AllGamesList} />

        <Route
          exact
          path="/season-analytic-dashboard"
          component={SeasonAnalyticDashboard}
        />
        <Route
          exact
          path="/game-analytic-dashboard"
          component={GameAnalyticsDashboard}
        />
        {/* <Route
          exact
          path="/player-analytic-dashboard"
          component={PlayerAnalyticsDashboard}
        /> */}
        <Route
          exact
          path="/formation-analytic-dashboard"
          component={FormationAnalytics}
        />
        <Route exact path="/player-list" component={PlayerListIndex} />
        <Route exact path="/player-invite" component={PlayerInvite} />
        {/* super admin */}
        <Route exact path="/teams" component={Teams} />
        <Route exact path="/coaches-view" component={Coach} />
        <Route exact path="/plans" component={Plans} />
        <Route exact path="/dashboard-view" component={Dashboard} />
      </>
    );
  }
}

export default LayoutContent;
