import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Input, Tooltip, Button } from "antd";
import { SaveOutlined, LoadingOutlined, FieldNumberOutlined, SwitcherOutlined, FootballOutlined } from '@ant-design/icons';
import floppyDiskImg from "../../../assets/img/floppy-disk.svg";
import moment from 'moment';

const { Option } = Select;

function AddStats({
  visible,
  setVisible,
  title,
  buttonText,
  stats,
  setStats,
  onFinish,
  odk,
  odkValue,
  loading,
}) {
  const [modalWidth, setModalWidth] = useState(485);
  const [form] = Form.useForm();
  
  useEffect(() => {
    console.log('stats---', stats)
    if (odk === 'o' && odkValue) {
      const offensiveIndexes = odkValue.filter((value) => value.value === 'o')[0]?.customStats.map((cs) => cs.index) || [];

     

      form.setFieldsValue({
        label: stats?.label || "",
        input_type: stats?.input_type || "",
        index_key: stats?.index_key || stats.index || "",
        offensiveIndexes
      });
    }else{

      form.setFieldsValue({
        label: stats?.label || "",
        input_type: stats?.input_type || ""
      });
    }
  }, [stats, odk, odkValue]);
  

  const handleInputChange = (name, value) => {
    setStats((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    form.setFieldsValue({ [name]: value });
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(300);
    } else {
      setModalWidth(485);
    }
  }, [window.innerWidth]);

  const resetForm = () => {
    setVisible(false);
    setStats({});
    form.resetFields();
  };

  return (
    <Modal
      open={visible}
      onCancel={resetForm}
      width={modalWidth}
      title={<Tooltip title="Add or Edit Custom Stat"><span>{title}</span></Tooltip>}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        name="stats-form"
        onFinish={(values) => onFinish(values.label, values.input_type, values.index_key)}
      >
           {odk === 'o' && (
          <Form.Item name="index_key" label={<Tooltip title="Select the offensive position"><span className="!text-black">Offensive Position 🏈</span></Tooltip>}>
            <Select
              size="large"
              placeholder="Select an offensive position"
              onChange={(value) => handleInputChange("index_key", value)}
            >
              <Option value={1} disabled={form.getFieldValue('offensiveIndexes')?.includes(1)}>QB</Option>
              <Option value={2} disabled={form.getFieldValue('offensiveIndexes')?.includes(2)}>OL</Option>
              <Option value={3} disabled={form.getFieldValue('offensiveIndexes')?.includes(3)}>WR, TE...</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={<Tooltip title="Enter a name for the custom stat"><span className="!text-black">Name for the Custom Stat 🏷️</span></Tooltip>}
          name="label"
          rules={[{ required: true, message: "Please input your Custom stat name!" }]}
        >
          <Input
            size="large"
            placeholder="e.g., Total Yards"
            // prefix={<Tooltip title="Stat Name"><FieldNumberOutlined /></Tooltip>}
            onChange={(e) => handleInputChange("label", e.target.value)}
          />
        </Form.Item>
        <Form.Item name="input_type" label={<Tooltip title="Choose the input type"><span className="!text-black">Input Type 📊</span></Tooltip>}>
          <Select
            size="large"
            placeholder="Select an input type"
            prefix={<Tooltip title="Input Type"><SwitcherOutlined /></Tooltip>}
            onChange={(value) => handleInputChange("input_type", value)}
          >
            <Option value="numeric">Numeric Input Field</Option>
            <Option value="toggle">Toggle</Option>
          </Select>
        </Form.Item>
     
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={loading ? <LoadingOutlined /> : <SaveOutlined />}
            className="w-full flex items-center justify-center"
          >
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddStats;
