import React from "react";

const numberOfPlayers = [
  {
    id: 1,
    name: 20,
  },
  {
    id: 2,
    name: 25,
  },
  {
    id: 3,
    name: 30,
  },
  {
    id: 4,
    name: 35,
  },
  {
    id: 5,
    name: 40,
  },
  {
    id: 6,
    name: 45,
  },
  {
    id: 7,
    name: 50,
  },
  {
    id: 8,
    name: 55,
  },
  {
    id: 9,
    name: 60,
  },
  {
    id: 10,
    name: 65,
  },
];

function PlayersDetails(props) {
  const [selectedNumber, setSelectedNumber] = React.useState(20);

  const handleSelectnumber = (number) => {
    setSelectedNumber(number.name);
    props.setRegDetails((prev) => {
      const updatedDetails = {
        ...prev,
        roaster_size: number.name,
      };
      sessionStorage.setItem("regDetails", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  return (
    <div className="w-[100%] h-[65vh]">
      <p className="text-[#0F1A24] text-lg xl:text-[18px] 2xl:text-[1.25vw] font-bold">
        Approximately how many players do you have on your varsity roster?
      </p>
      <div className="flex  flex-wrap gap-4">
        {numberOfPlayers.map((number) => {
          const regDetails = sessionStorage.getItem("regDetails");
          const parsedRegDetails = regDetails ? JSON.parse(regDetails) : {};
          const savedSelectedNumber = parsedRegDetails?.roaster_size;
          return (
            <div
              className="px-8 2xl:px-[2.22vw] py-3 2xl:py-[0.83vw] bg-[#EAEDF0] text-lg xl:text-[18px]  2xl:text-[1.25vw] font-bold  rounded-full"
              key={number.id}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedNumber === number.name ||
                  savedSelectedNumber === number.name
                    ? "#A40E17"
                    : "#EAEDF0",
                color:
                  selectedNumber === number.name ||
                  savedSelectedNumber === number.name
                    ? "#fff"
                    : "#8698A9",
              }}
              onClick={() => handleSelectnumber(number)}
            >
              {number.name === 65 ? "65+" : number.name} players
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlayersDetails;
