import actionType from "../actionTypes";
const reducerName = actionType.reduxUpdateAccountState;

// account actions

export default {
  // get profile data of the current logged in account
  isLogin: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/isLoggedIn`,
        },
        actionType: actionType.account.isLogin,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  login: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/login`,
        },
        actionType: actionType.account.login,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  register: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/register`,
        },
        actionType: actionType.account.register,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.registerApi.registerRequestAPI(requestOptions);
    };
  },

  resetEmailVerification: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/accounts/reset-email-verification`,
        },
        actionType: actionType.account.resetEmailVerification,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.registerApi.registerRequestAPI(requestOptions);
    };
  },
  
  coacheprofile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/coaches/profile`,
        },
        actionType: actionType.account.profile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  adminProfile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/admins/profile`,
        },
        actionType: actionType.account.profile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  logout: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/logout`,
        },
        actionType: actionType.account.logout,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  requestResetPassword: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/accounts/request-reset-password`,
        },
        actionType: actionType.account.requestResetPassword,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  changePassword: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/accounts/update-password`,
        },
        actionType: actionType.account.changePassword,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  // Reset password process

  resetPassword: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/resetPassword`,
        },
        actionType: actionType.account.resetPassword,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
};
