import React from "react";
import { Table } from "antd";
import { kickerColumns } from "./statsColumns";
import { kickerColumnsPrint } from "./StatsColumnPrint";

function findHighestValue(arr) {
  if (arr.length === 0) {
    return undefined; // Return undefined for empty array
  }

  let max = arr[0]; // Assume first element as maximum

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]; // Update max if current element is greater
    }
  }

  return max;
}
function KickerStatsTable({ dataSource, pageLoading, print }) {
  return (
    <div className="stats-table">
      <Table
        dataSource={dataSource}
        columns={print ? kickerColumnsPrint : kickerColumns}
        bordered
        className="w-full"
        pagination={false}
        loading={pageLoading}
        summary={(pageData) => {
          let plays = 0;
          let total_positive_impact = 0;
          let total_field_goals = 0;
          let total_fgm = 0;
          let total_fgm_percent = 0;
          let total_avg_field_goal_distance = 0;
          let total_max_field_goal_distance = [];
          let total_block_field_goals = 0;
          let total_missed_field_goals = 0;
          let total_avg_missed_field_goal_distance = 0;
          let total_field_goals_made = 0;
          let total_missed_field_goals_avg = 0;
          let total_punts = 0;
          let total_blocked_punts = 0;
          let total_avg_punts_distance = 0;
          let total_max_punts_distance = [];
          let total_fair_c = 0;
          let total_ins_20 = 0;
          let total_pat = 0;
          let total_missed_pat = 0;
          let total_block_pat = 0;
          let total_made_pat = 0;
          let total_kickoff = 0;
          let total_kickoff_touchback = 0;
          let total_kickoff_fair_catch = 0;
          let total_onsidekick = 0;
          let total_recovered_fumble = 0;
          let total_recovered_fumble_difference = 0;

          pageData?.forEach((data) => {
            plays += Number(data?.plays);
            total_positive_impact += Number(data.total_positive_impact);
            total_field_goals +=
              Number(data?.total_field_goals) +
              Number(data?.total_block_field_goals) +
              Number(data?.total_missed_field_goals);
            total_fgm += Number(data?.total_field_goals);
            total_fgm_percent +=
              (Number(data?.total_field_goals) /
                (Number(data?.total_field_goals) +
                  Number(data?.total_block_field_goals) +
                  Number(data?.total_missed_field_goals))) *
                100 || 0;
            total_avg_field_goal_distance += Number(
              data?.avg_field_goal_distance
            );
            total_block_field_goals += Number(data?.total_block_field_goals);
            total_missed_field_goals += Number(data?.total_missed_field_goals);
            if (
              !isNaN(data?.total_field_goals) &&
              !isNaN(data?.total_missed_field_goals) &&
              !isNaN(data?.total_block_field_goals)
            ) {
              total_missed_field_goals_avg +=
                (Number(data?.total_block_field_goals) +
                  Number(data?.total_missed_field_goals)) /
                Number(data?.total_field_goals);
            }
            total_avg_missed_field_goal_distance += Number(
              data?.avg_missed_field_goal_distance
            );
            total_max_field_goal_distance.push(
              Number(data?.max_field_goal_distance) || 0
            );
            total_punts +=
              Number(data?.total_punts) + Number(data?.total_blocked_punts);
            total_blocked_punts += Number(data?.total_blocked_punts);
            total_avg_punts_distance +=
              Number(data?.total_punts_distance) / Number(data?.total_punts) ||
              0;
            total_max_punts_distance.push(Number(data?.max_punts_distance));
            total_fair_c += Number(data?.total_fair_c);
            total_ins_20 += Number(data?.total_ins_20);

            total_pat +=
              Number(data?.total_block_pat) +
              Number(data?.total_pat) +
              Number(data?.total_missed_pat);
            total_missed_pat += Number(data?.total_missed_pat);
            total_block_pat += Number(data?.total_block_pat);
            total_made_pat += Number(data?.total_pat);
            total_kickoff += Number(data?.total_kickoff);
            total_kickoff_touchback += Number(data?.total_kickoff_touchback);
            total_kickoff_fair_catch += Number(data?.total_kickoff_fair_catch);
            total_onsidekick += Number(data?.total_onsidekick);
            total_recovered_fumble += Number(data?.total_recovered_fumble);
            total_recovered_fumble_difference +=
              Number(data?.total_onsidekick) -
              Number(data?.total_recovered_fumble);
          });

          return (
            <>
              <Table.Summary.Row
                align="center"
                style={{
                  background: "#F3F5F7",
                  fontWeight: "bold",
                }}
              >
                <Table.Summary.Cell index={0} colSpan={print ? 1 : 3}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {plays}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_positive_impact}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_field_goals}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fgm}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {Number(total_fgm_percent)?.toFixed(2) || 0}%
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {Number(total_avg_field_goal_distance)?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {findHighestValue(total_max_field_goal_distance)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_block_field_goals}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_missed_field_goals}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {Number(total_avg_missed_field_goal_distance)?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_punts}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_blocked_punts}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_avg_punts_distance?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {findHighestValue(total_max_punts_distance)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fair_c}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_ins_20}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_pat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_missed_pat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_block_pat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_made_pat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_touchback}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_kickoff_fair_catch}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_onsidekick}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_recovered_fumble}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_recovered_fumble_difference}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default KickerStatsTable;
