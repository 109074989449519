import actionType from '../actionTypes';
import _ from "lodash";
const reducerName = actionType.reduxUpdateCoachesState;

export default {

    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/coaches/list`
                },
                actionType: actionType.coaches.list,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    create: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/coaches/create`
                },
                actionType: actionType.coaches.create,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/coaches/view/${_.get(componentRequest, 'params.id')}`
                },
                actionType: actionType.coaches.view,
                reducerName,
                dispatch,
                componentRequest
            };
            global.api.requestAPI(requestOptions);
        }
    },
   

    getCurrentLocation: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/getcurrentLocation`
                },
                actionType: actionType.coaches.location,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    update: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/coaches/update`
                },
                actionType: actionType.coaches.update,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    updatephone: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/coaches/update-phone`
                },
                actionType: actionType.coaches.updatephone,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    updatephoto: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/coaches/update-photo`
                },
                actionType: actionType.coaches.updatephoto,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    changeStatus: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/coaches/change-status/${_.get(componentRequest, 'params.id')}`
                },
                actionType: actionType.coaches.changeStatus,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

}
