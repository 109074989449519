import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

function ButtonUI({
  text,
  disabled,
  textColor,
  outlinedColor,
  leftIcon,
  rightIcon,
  buttonFunction,
  loading,
  background,
  width,
  xlWidth,
  type,
}) {
  return (
    <button
      className={`${
        width ? `w-[${width}] xl:w-[${xlWidth}]` : "w-[100%]"
      } py-2 2xl:py-[0.67vw] px-2 2xl:px-[0.67vw]  bg-[${
        background ? background : "transparent"
      }] text-[${
        textColor ? textColor : "#ffffff"
      }] text-sm xl:text-[0.97vw] rounded-md flex items-center justify-center focus:outline-none font-inter font-bold`}
      onClick={buttonFunction}
      style={
        background
          ? null
          : {
              border: `1px solid ${outlinedColor ? outlinedColor : "black"}`,
            }
      }
      disabled={disabled ? disabled : loading}
      type={type ? type : "button"}
    >
      {loading && <LoadingOutlined className="mr-2" />}
      {leftIcon && <img src={leftIcon} className="w-[20px] mr-2" />}
      {text}
      {rightIcon && <img src={rightIcon} className="w-[20px] ml-2" />}
    </button>
  );
}

export default ButtonUI;
