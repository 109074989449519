import React, { useState } from "react";
import { Modal, Input, Button } from "antd";

const ChangePasswordModal = ({
  visible,
  setVisible,
  onCancel,
  onSave,
  newPassword,
  currentPassword,
  setCurrentPassword,
  setNewPassword,
  passwordResetLoading,
}) => {
  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    console.log(e.target.value);
  };

  const handleSave = () => {
    onSave();
    setVisible(false);
  };

  return (
    <Modal
      title="Change Password"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Input.Password
        placeholder="New Password"
        value={newPassword}
        onChange={handleNewPasswordChange}
        style={{ marginTop: "10px" }}
      />
      <div className="flex items-center mt-[10px]">
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="save"
          className="ml-1 bg-[#1A91FF] text-[#ffffff]"
          onClick={handleSave}
          loading={passwordResetLoading}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
