import React, { useState } from "react";
import { Modal, Input } from "antd";

function NewPlanModal({ visible, setVisible, handleCreatePlan, title, plan }) {
  const [planObject, setPlanObject] = useState({
    name: plan?.name || "",
    description: plan?.description || "",
    features: plan?.features || [],
    price: plan?.price || null,
    planId: plan?.id || null,
  });
  const handleChange = (e) => {
    setPlanObject({
      ...planObject,
      [e.target.name]: e.target.value,
    });
  };
  
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title={title ? title : "Create New Plan"}
      closable={true}
      width={400}
      onOk={() => {
        handleCreatePlan(
          planObject.name,
          planObject.description,
          planObject.price,
          planObject.features,
          planObject.planId
        );
        // setVisible(false);
      }}
    >
      <div>
        <div className="my-4">
          <Input
            placeholder="Plan Name"
            value={planObject.name}
            onChange={handleChange}
            name="name"
          />
        </div>
        <div className="mb-4">
          <Input
            placeholder="Plan Description"
            value={planObject.description}
            onChange={handleChange}
            name="description"
          />
        </div>
        <div className="mb-4">
          <Input
            placeholder="Plan Features"
            value={planObject.features}
            onChange={handleChange}
            name="features"
          />
        </div>
        <div className="mb-4">
          <Input
            placeholder="Plan Price"
            value={planObject.price}
            onChange={handleChange}
            name="price"
            type="number"
          />
        </div>
      </div>
    </Modal>
  );
}

export default NewPlanModal;
