import React from "react";
import searchicon from "../assets/img/search.svg";

function SearchInput({ onChange, value, placeholder }) {
  return (

      <div
        className="px-2 py-2 items-center justify-between flex w-[30%] md:w-[200px] xl:w-[25%]  rounded-md bg-[white]"
        style={{ border: "1px solid #EAEDF0" }}
      >
        <img src={searchicon} />
        <input
          type="text"
          placeholder={placeholder}
          className="outline-0 w-[85%] bg-[white]"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
  );
}

export default SearchInput;
