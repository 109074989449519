import { Progress } from "antd";

export const Gauge = ({ value, color, text }) => {
  return (
    <div className=' w-[60%] flex items-center justify-center mx-auto pt-[2%] overflow-hidden'>
      <Progress
        type='circle'
        gapDegree={100}
        percent={value}
        format={() => (
          <>
            <div className="font-[700] " style={{ fontSize: '24px', marginBottom: 10,color:{color} }}>{value}%</div>
            <div className="font-[700] text-[#526B84]" style={{ fontSize: '10px'}}>{text}</div>
          </>
        )}
        strokeColor={color}
        trailColor='#f5f5f5'
        strokeWidth={10}
        // style={{ width: "100%" }}
      />
    </div>
  );
};

export const GaugeType2 = ({ value, color, text,desc }) => {
  return (
    <div className=' w-[70%] flex flex-col items-center justify-center mx-auto pt-[2%] overflow-hidden'>
      <Progress
        type='circle'
        gapDegree={100}
        percent={value}
        format={() => (
          <>
            <div
              className='font-[700] '
              style={{ fontSize: "12px", marginBottom: 4, color: { color } }}>
              {value}%
            </div>
            <div
              className='font-[700] text-[#526B84]'
              style={{ fontSize: "8px" }}>
              {text}
            </div>
          </>
        )}
        strokeColor={color}
        trailColor='#f5f5f5'
        strokeWidth={10}
        // style={{ width: "100%" }}
      />
      <div
        className='text-[12px] text-[#526B84] font-[700]'
        style={{ textAlign: "center", marginTop: 10 }}>
        {desc}
      </div>
    </div>
  );
};
