import React, { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  Menu,
  Progress,
  Spin,
  Table,
  Button,
  Tooltip,
  Checkbox,
  Breadcrumb,
  Select,
  Steps,
  Divider,
} from "antd";
import PlayerAnalyticCell from "../../components/misc/PlayerAnalyticCell";
import { connect } from "react-redux";
import GamePerformanceCell from "../../components/misc/GamePerformanceCell";
import StepsBar from "../../components/charts/stepsBar";
import arrowLeft from "../../assets/img/long-arrow-up-left.svg";
import "./seasondashboard.css";
import people from "../../assets/img/group.svg";
import group from "../../assets/img/grouppeople.svg";
import stats from "../../assets/img/stats-square-up.svg";
import crystal from "../../assets/img/codepen.svg";
import userSquare from "../../assets/img/user-square-alt.svg";
import clipimg from "../../assets/img/clipboard-check.svg";
import multiplePages from "../../assets/img/multiple-pages.svg";
import { TotalNumberCard } from "./SeasonAnalyticDashboard";
import PieChartComponent from "../../components/charts/PieChartTwo";
import MultiBarChartTwo from "../../components/charts/BarChart2";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import PieChartCel from "../../components/misc/PieChartCel";
import KMultiLineChart from "../../components/charts/GameAnalyticsKChart";
import RunPassGameComponent from "./RunPassGameComponent";
import RunPassComponent from "./RunPassComponent";
import KickingLineChartComponent from "../../components/charts/GameAnalyticsKickingChart";
import AggregatedResults from "./AggregatedResults";
const { Option } = Select;

function PlayerAnalyticsDashboard(props) {
  const history = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  let game_id = params.get("game");
  const game = [game_id];

  const paramsPosition = location?.state?.position || "";

  const [odk, setOdk] = useState("o");
  const [gradesData, setGradesData] = useState([]);
  const [positionsArray, setPositionsArray] = useState(
    paramsPosition !== "" ? [paramsPosition] : ["QB"]
  );
  const [position, setPosition] = useState("QB");
  const [pageLoading, setPageLoading] = useState(false);
  const [gamesListId, setGamesListId] = useState([]);
  const [gamesData, setGamesData] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [seasonsList, setSeasonsList] = useState([]);
  const [gamesIds, setGamesIdsList] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  const [playerInfo, setPlayerInfo] = useState([{}]);
  const [playTypeData, setPlayTypeData] = useState({});
  const [passData, setPassData] = useState([]);
  const [kchartlineData, setKchartlineData] = useState([]);
  const [theirTeam, setTheirTeam] = useState("All");

  const instances = [
    "Kicker",
    "Kickoff",
    "Kick Return",
    "Punt",
    "Punt Return",
    "PAT",
    "PAT Block",
    "FG Attempt",
    "FG Blocked",
  ];

  const [kickingChartLineData, setKickingChartLineData] = useState([]);
  const instancesTwo = ["Kicker", "Kickoff", "Punt", "PAT Kick", "FG Attempt"];

  const Option = Select.Option;

  useEffect(() => {
    loadSeasonsList();
  }, []);

  useEffect(() => {
    if (seasonsList[0]) {
      loadGamesList();
    }
  }, [seasonsList]);

  useEffect(() => {
    if (gamesList[0] && !game_id) {
      loadGradesList();
    }
  }, [gamesList]);

  useEffect(() => {
    fetchPlaysTypeGrades(JSON.stringify(game), JSON.stringify(positionsArray));
  }, []);

  useEffect(() => {
    fetchGamesAnalytics(
      JSON.stringify(game),
      odk,
      JSON.stringify(positionsArray)
    );
  }, []);

  useEffect(() => {
    if (game) {
      fetchGradesList(JSON.stringify(game), JSON.stringify(positionsArray));
    }
  }, []);

  const formatNum = (val) => {
    if (isNaN(val)) return 0;
    return Math.round(val);
  };

  const loadGradesList = () => {
    let games = gamesList.filter(
      (game) => game.type == "regular season" || game.type == "playoffs"
    );

    console.log({ games });
    setGamesIdsList(games.map((g) => g.id));
    games = JSON.stringify(games);
    fetchGradesList(games, JSON.stringify(positionsArray));
  };

  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));

    if (list) {
      setSeasonsList(list);
    } else {
      props.seasonsList({
        params: { limit: 1000, sort: "id.desc" },
        callback: (res, data) => {
          if (res === true) {
            const result = data.data.items;
            setSeasonsList(result);
            sessionStorage.setItem("seasonsList", JSON.stringify(result));
          }
        },
      });
    }
  };

  const loadGamesList = (seasonID = null) => {
    const season = seasonID ?? seasonsList[0]?.id;

    let params = {
      limit: 995,
      home_team: _.get(props, "coachProfile.school.id"),
      sort: "week.asc",
      season,
    };
    const list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));

    if (list) {
      setGamesList(list);
      let games = list.filter(
        (game) => game.type == "regular season" || game.type == "playoffs"
      );
      setGamesIdsList(games.map((g) => g.id));
      setGamesListLoading(false);
    } else {
      setGamesListLoading(false);
    }

    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          sessionStorage.setItem(
            `${seasonID}-gamesList`,
            JSON.stringify(_.get(data, "data.items", []))
          );
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const fetchGradesList = (game, position) => {
    if (JSON.parse(game)[0]) {
      setPageLoading(true);
      props.gradesList({
        params: {
          position,
          game,
        },
        callback: (res, data) => {
          if (res === true) {
            setPlayerInfo(data?.data?.items[0]?.rows || []);
            console.log("fetch for player table", data?.data?.items[0]?.rows);
          }
          setPageLoading(false);
        },
      });
    }
  };

  const fetchPlaysTypeGrades = (game, position) => {
    if (JSON.parse(game)[0]) {
      setPageLoading(true);

      console.log("fetchPlaysTypeGrades", game);
      props.playTypeGrades({
        params: {
          game,
          position,
        },
        callback: (res, data) => {
          if (res === true) {
            setPageLoading(false);
            console.log("jdkjdfkdf", data?.data?.items[0]?.rows[0]);
            setPlayTypeData(data?.data?.items[0]?.rows[0]);
          }
          setPageLoading(false);
        },
      });
    }
  };

  const fetchMatchAnalyticsByDepthChart = (game, odk) => {
    if (odk === "k") {
      props.matchAnalyticsByDepthChart({
        params: { game, odk },
        callback: (res, data) => {
          if (res === true) {
            const blob = data?.data?.items.map(({ rows }) => rows);
            const mapInstances = () => {
              return instances.map((instance) => {
                const blobItem = blob[0]?.find(({ name }) => name === instance);
                const execution = blobItem
                  ? formatNum(
                      (blobItem.total_assignment_executed /
                        blobItem.total_count) *
                        100
                    )
                  : 0;
                const effort = blobItem
                  ? formatNum(
                      (blobItem.total_great_effort / blobItem.total_count) * 100
                    )
                  : 0;

                return {
                  instance: instance,
                  Execution: execution,
                  Effort: effort,
                };
              });
            };
            const mapInstancesTwo = () => {
              return instancesTwo.map((instance) => {
                const instanceName = instance === "PAT Kick" ? "PAT" : instance;
                const blobItem = blob[0]?.find(({ name }) => name === instance);
                const execution = blobItem
                  ? formatNum(
                      (blobItem.total_assignment_executed /
                        blobItem.total_count) *
                        100
                    )
                  : 0;
                const effort = blobItem
                  ? formatNum(
                      (blobItem.total_great_effort / blobItem.total_count) * 100
                    )
                  : 0;

                return {
                  instance: instanceName,
                  Execution: execution,
                  Effort: effort,
                };
              });
            };
            const mappedInstances = mapInstances();
            const mappedInstancesTwo = mapInstancesTwo();
            setKchartlineData(mappedInstances);
            setKickingChartLineData(mappedInstancesTwo);
          }
        },
      });
    }
  };

  const fetchGamesAnalytics = (games, odk, position) => {
    console.log("fetching", { games, odk, position });
    if (gamesList.length > 0) {
      if (odk !== "k") {
        setPageLoading(true);
    
        props.gamesAnalytics({
          params: { games, odk, position },
          callback: (res, data) => {
            if (res === true) {
              const blob = data?.data?.items.map(({ rows }) => rows[0]);
              const result = {
                ...blob[0],
                ...blob[1],
                ...blob[2],
                ...blob[3],
                ...blob[4],
                ...blob[5],
                ...blob[6],
                ...blob[7],
                ...blob[8],
                ...blob[9],
              };
              setGamesData(result);
              setPageLoading(false);
            }
            setPageLoading(false);
          },
        });
      }
    }
  };

  const loadGamesAnalytics = () => {
    // setGamesIdsList(games);
    console.log("loadGamesAnalytics", gamesIds, { gamesList });
    const games = JSON.stringify(gamesIds);
    fetchGamesAnalytics(games, odk, JSON.stringify(positionsArray));
  };

 
  useEffect(() => {
    if (gamesList.length > 0 && !game_id) {
      loadGamesAnalytics();
    }
  }, [odk, positionsArray, gamesIds]);

  useEffect(() => {
    if (gamesList[0] && !game_id) {
      fetchPlaysTypeGrades(
        JSON.stringify(gamesIds),
        JSON.stringify(positionsArray)
      );
      fetchMatchAnalyticsByDepthChart(JSON.stringify(gamesIds), "k");
    }
  }, [gamesList]);

  const toggleGameID = (id) => {
    let newList = [];
    const isPresent = gamesIds.find((i) => i == id);
    if (isPresent) {
      newList = gamesIds.filter((i) => i != id);
    } else {
      newList = gamesIds;
      newList.push(id);
    }
    setGamesIdsList(newList);
  };

  const generateCustomReport = () => {
    fetchGradesList(JSON.stringify(gamesIds), JSON.stringify(positionsArray));
    fetchGamesAnalytics(
      JSON.stringify(gamesIds),
      odk,
      JSON.stringify(positionsArray)
    );
    fetchPlaysTypeGrades(
      JSON.stringify(gamesIds),
      JSON.stringify(positionsArray)
    );
    fetchMatchAnalyticsByDepthChart(JSON.stringify(gamesIds), "k");
  };
  const preCol = [
    {
      title: "Player Name",
      width: "15%",
      align: "center",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, item) => (
        <span className="text-[10px] md:text-[10.5px] tabledata font-bold text-[#0F1A24]">
          {`${item?.first_name} ${item?.last_name}`}
        </span>
      ),
    },
    {
      title: "Total Assignments",
      align: "center",
      width: "13%",

      render: (item, row) => item || "--",
      dataIndex: "total_grades",
      key: "total_grades",
    },
    {
      title: "Execution",
      width: "6%",
      align: "center",
      dataIndex: "total_assignment_executed",
      key: "total_assignment_executed",
      render: (text, item) => (
        <span className="text-[10px] md:text-[10.5px] tabledata font-normal text-[#0F1A24]">
          {Math.round(
            (
              (item?.total_assignment_executed / item?.total_grades) *
              100
            ).toFixed(2)
          ) || 0}
          %
        </span>
      ),
    },
    {
      title: "Assignments Executed",
      align: "center",
      width: "13%",

      render: (item, row) => item || "--",
      dataIndex: "total_assignment_executed",
      key: "total_assignment_executed",
    },
    {
      title: "Effort",
      width: "6%",
      align: "center",
      dataIndex: "total_great_effort",
      key: "total_great_effort",
      render: (text, item) => (
        <span className="text-[10px] md:text-[10.5px] tabledata font-normal text-[#0F1A24]">
          {Math.round(
            ((item?.total_great_effort / item?.total_grades) * 100).toFixed(2)
          )}
          %
        </span>
      ),
    },
    {
      title: "Great Effort Plays",

      render: (item, row) => item || "--",
      dataIndex: "total_great_effort",
      key: "total_great_effort",
      align: "center",
      width: "12%",
    },
  ];

  const postCol = [
    {
      title: "TD",
      dataIndex: "total_td",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_td",
    },
    {
      title: "Custom Stats",
      dataIndex: "custom_stats_array",
      width: "6%",
      className : 'p-0',
      render: (item, row) => {
        return item ? <AggregatedResults jsonStrings={item || []} /> : "--";
      },
      align: "center",
      key: "total_td",
    },
  ];

  const qbColumn = [
    ...preCol,
    {
      title: "Forced Missed Tackles",
      dataIndex: "total_forced_missed_tackles",
      key: "total_forced_missed_tackles",
      align: "center",
      width: "6%",
    },
    {
      title: "Flags",
      dataIndex: "total_penalties",
      key: "total_penalties",

      render: (item, row) => item || "--",

      align: "center",
      width: "6%",
    },
    {
      title: "Completions",

      render: (item, row) => item || "--",
      dataIndex: "total_completions",
      key: "total_completions",
      width: "6%",
      align: "center",
    },
    {
      title: "WR Dropped Balls",

      render: (item, row) => item || "--",
      dataIndex: "total_dropped_balls",
      key: "total_dropped_balls",
      width: "10%",
      align: "center",
    },
    {
      title: "Bad Balls",

      render: (item, row) => item || "--",
      dataIndex: "total_bad_balls",
      key: "total_bad_balls",
      width: "10%",
      align: "center",
    },
    {
      title: "Carries",

      render: (item, row) => item || "--",
      dataIndex: "total_carries",
      key: "total_carries",
      width: "6%",
      align: "center",
    },
    {
      title: "Fumbles",
      dataIndex: "total_fumbles",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_fumbles",
    },
    ...postCol,
  ];

  const olCol = [
    ...preCol,
    {
      title: "Pancakes",
      render: (item, row) => item || "--",
      dataIndex: "total_pancakes",
      key: "total_pancakes",
      align: "center",
      width: "6%",
    },
    // {
    //   title: "Big Hits",

    //   render: (item, row) => item || "--",
    //   dataIndex: "total_catchable_balls",
    //   key: "total_catchable_balls",
    //   width: "6%",
    //   align: "center",
    // },
    // {
    //   title: "Knockdowns",

    //   render: (item, row) => item || "--",
    //   dataIndex: "total_knockdowns",
    //   key: "total_knockdowns",
    //   width: "8%",
    //   align: "center",
    // },
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      width: "6%",
      align: "center",
    },
    {
      title: "Pass Pro Win %",
      dataIndex: "total_pass_pro_sacks",
      width: "8%",
      render: (item, row) =>
        (
          <span>
            {formatNum(
              (parseInt(row?.total_pass_pro_sacks_won) /
                (parseInt(row?.total_pass_pro_sacks_won) +
                  parseInt(row?.total_pass_pro_sacks_lost))) *
                100
            ) + "%" || "--"}
          </span>
        ) || "--",

      align: "center",
      key: "total_pass_pro_sacks_won",
    },
  ];

  const teCol = [
    ...preCol,
    {
      title: "Forced Missed Tackles",
      dataIndex: "total_forced_missed_tackles",
      key: "total_forced_missed_tackles",
      align: "center",
      width: "6%",
    },
    {
      title: "Flags",

      dataIndex: "total_penalties",
      key: "total_penalties",

      render: (item, row) => item || "--",

      align: "center",
      width: "6%",
    },
    {
      title: "Carries",

      dataIndex: "total_carries",
      key: "total_carries",
      render: (item, row) => item || "--",

      width: "6%",
      align: "center",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "10%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_completions",
      key: "total_completions",
      width: "6%",
      align: "center",
    },
    {
      title: "Drops",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
    },
    {
      title: "Pancakes",
      dataIndex: "total_pancakes",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_pancakes",
    },
    ...postCol,
  ];

  const wrCol = [
    ...preCol,
    {
      title: "Forced Missed Tackles",
      dataIndex: "total_forced_missed_tackles",
      key: "total_forced_missed_tackles",
      align: "center",
      width: "6%",
    },
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "5%",
    },
    {
      title: "Carries",

      render: (item, row) => item || "--",
      dataIndex: "total_carries",
      key: "total_carries",
      width: "4%",
      align: "center",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "4%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_receptions",
      key: "total_receptions",
      width: "5%",
      align: "center",
    },
    {
      title: "Drops",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "4%",

      render: (item, row) => item || "--",
      align: "center",
    },
    {
      title: "Pancakes",

      render: (item, row) => item || "--",

      dataIndex: "total_pancakes",
      key: "total_pancakes",
      align: "center",
      width: "4%",
    },
    ...postCol,
  ];

  const DCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_tackles",
      key: "total_tackles",
      width: "6%",
      align: "center",
    },
    {
      title: "Missed Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_missed_tackles",
      key: "total_missed_tackles",
      width: "10%",
      align: "center",
    },
    {
      title: "Pressure",

      render: (item, row) => item || "--",
      dataIndex: "total_hurries",
      key: "total_hurries",
      width: "6%",
      align: "center",
    },
    {
      title: "Sacks",
      dataIndex: "total_sacks",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_sacks",
    },
    {
      title: "Big Hit",
      dataIndex: "total_big_hit",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_big_hit",
    },
    {
      title: "Turnovers Created",
      dataIndex: "total_turnovers",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_turnovers",
    },
    ...postCol,
  ];

  const kCol = [
    ...preCol,
    {
      title: "Flags",
      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Total Kicks",

      render: (item, row) =>
        Number(item) +
          Number(row?.total_punts) +
          Number(row?.total_pat) +
          Number(row?.total_missed_pat) +
          Number(row?.total_missed_field_goals) || "--",
      dataIndex: "total_field_goals",
      key: "total_field_goals",
      align: "center",
      width: "6%",
    },
    {
      title: "KO",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },

    {
      title: "TOUCH BACK",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "PAT Kicks",
      render: (item, row) =>
        Number(item) + Number(row?.total_missed_pat) || "--",
      dataIndex: "total_pat",
      key: "total_pat",
      width: "6%",
      align: "center",
    },
    {
      title: "Missed PAT",
      render: (item, row) => item || "--",
      dataIndex: "total_missed_pat",
      key: "total_missed_pat",
      width: "6%",
      align: "center",
    },

    {
      title: "FGK",
      render: (item, row) =>
        Number(row.total_field_goals) +
          Number(row.total_blocked_field_goals) +
          Number(row.total_missed_field_goals) || "--",
      dataIndex: "total_field_goals",
      key: "total_field_goals",
      width: "10%",
      align: "center",
    },
    {
      title: "FGM",
      render: (item, row) => item || "--",
      dataIndex: "total_field_goals",
      key: "total_field_goals",
      width: "10%",
      align: "center",
    },
    {
      title: "FGM Avg",

      render: (item, row) => item / row?.total_field_goals || "--",
      dataIndex: "total_field_goal_distance",
      key: "total_field_goals",
      width: "10%",
      align: "center",
    },

    {
      title: "Avg Missed FG",
      render: (item, row) => Number(item).toFixed(1) || "--",
      dataIndex: "avg_missed_field_goal_distance",
      key: "avg_missed_field_goal_distance",
      width: "10%",
      align: "center",
    },
    {
      title: "FGM Long",

      render: (item, row) => item || "--",
      dataIndex: "highest_field_goal_distance",
      key: "highest_field_goal_distance",
      width: "10%",
      align: "center",
    },
    {
      title: "Punt",
      render: (item, row) =>
        Number(item) + Number(row.total_punts_blocked) || "--",
      dataIndex: "total_punts",
      key: "total_punts",
      width: "6%",
      align: "center",
    },
    {
      title: "Punt Avg",

      render: (item, row) => formatNum(item / row?.total_punts) || "--",

      dataIndex: "total_punt_distance",
      key: "total_punts",
      width: "6%",
      align: "center",
    },
    {
      title: "Punt Long",

      render: (item, row) => item || "--",
      dataIndex: "highest_punt_distance",
      key: "highest_punt_distance",
      width: "6%",
      align: "center",
    },
    ...postCol,
  ];

  const stCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_tackles",
      key: "total_tackles",
      width: "6%",
      align: "center",
    },
    {
      title: "Missed Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_missed_tackles",
      key: "total_missed_tackles",
      width: "10%",
      align: "center",
    },
    {
      title: "Unselfish Blocks",

      render: (item, row) => item || "--",
      dataIndex: "total_catchable_balls",
      key: "total_catchable_balls",
      width: "10%",
      align: "center",
    },
    {
      title: "Punt/FG Blocks",
      dataIndex: "total_punt_blocks",
      width: "13%",

      render: (text, row) => (
        <div>{Number(text) + Number(row.total_fg_blocks) || "--"}</div>
      ),
      align: "center",
      key: "total_turnovers",
    },
    {
      title: "ST TD",
      dataIndex: "total_st_td",
      width: "6%",
      render: (text, row) => (
        <div>
          {Number(text) +
            Number(row.total_punt_return_td) +
            Number(row.total_kickoff_return_td) || "--"}
        </div>
      ),
      align: "center",
      key: "total_td",
    },
    {
      title: "Custom Stats",
      dataIndex: "custom_stats_array",
      width: "6%",
      className : 'p-0',
      render: (item, row) => {
        return item ? <AggregatedResults jsonStrings={item || []} /> : "--";
      },
      align: "center",
      key: "total_td",
    },
  ];

  const rbCol = [
    ...preCol,
    {
      title: "Forced Missed Tackles",
      dataIndex: "total_forced_missed_tackles",
      key: "total_forced_missed_tackles",
      align: "center",
      width: "6%",
    },
    {
      title: "Flags",

      render: (item, row) => item || "--",

      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Carries",
      dataIndex: "total_carries",
      key: "total_carries",
      align: "center",
      width: "6%",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "6%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_receptions",
      key: "total_receptions",
      width: "8%",
      align: "center",
    },
    {
      title: "Drops",

      render: (item, row) => item || "--",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "6%",
      align: "center",
    },
    {
      title: "Pass Pro Win %",
      dataIndex: "total_pass_pro_sacks",
      width: "8%",
      render: (item, row) =>
        (
          <span>
            {formatNum(
              (parseInt(row?.total_pass_pro_sacks_won) /
                (parseInt(row?.total_pass_pro_sacks_won) +
                  parseInt(row?.total_pass_pro_sacks_lost))) *
                100
            ) + "%" || "--"}
          </span>
        ) || "--",

      align: "center",
      key: "total_pass_pro_sacks_won",
    },
    ...postCol,
  ];

  const [col, setCol] = useState(qbColumn);
  useEffect(() => {
    ToglePositionsArray(odk, positionsArray, position, col);
  }, [gamesIds]);

  function ToglePositionsArray(
    odkValue,
    positionsListValue,
    position,
    colname
  ) {
    setOdk(odkValue);
    setPositionsArray(positionsListValue);
    setCol(colname);
    position ? setPosition(position) : setPosition("");

    console.log("clicked ToglePositionsArray");

    if (game[0]) {
      console.log("game", { game });
      fetchGradesList(JSON.stringify(game), JSON.stringify(positionsListValue));
      fetchGamesAnalytics(
        JSON.stringify(game),
        odkValue,
        JSON.stringify(positionsListValue)
      );
      fetchMatchAnalyticsByDepthChart(JSON.stringify(game), "k");
    } else {
      console.log({ gamesIds });
      fetchGradesList(
        JSON.stringify(gamesIds),
        JSON.stringify(positionsListValue)
      );
      fetchGamesAnalytics(
        JSON.stringify(gamesIds),
        odkValue,
        JSON.stringify(positionsListValue)
      );

      fetchPlaysTypeGrades(
        JSON.stringify(gamesIds),
        JSON.stringify(positionsListValue)
      );
      fetchMatchAnalyticsByDepthChart(JSON.stringify(gamesIds), "k");
    }
  }

  const customColumn = [
    {
      title: () => <Checkbox disabled />,
      dataIndex: "checked",
      width: "5%",
      align: "center",
      key: "id",
      render: (text, item) => (
        <Checkbox
          onChange={(e) => toggleGameID(item.id)}
          defaultChecked={text}
        />
      ),
    },
    {
      title: "Week #",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      dataIndex: "week",
      align: "center",
      width: "10%",
      key: "week",
    },
    {
      title: "Opponent",
      dataIndex: "title",
      align: "center",
      width: "20%",
      key: "title",
      render: (text, item) => (
        <div className="text-[10px] lg:text-[12px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#a40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "type",
      align: "center",
      width: "15%",
      key: "type",
    },
    {
      title: "Evaluation Type",
      dataIndex: "feedback_type",
      align: "center",
      key: "feedback_type",
      width: "13%",
    },
    {
      title: "Date",
      dataIndex: "start_time",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      key: "start_time",
      width: "11%",
      render: (startTime) => {
        const formattedDate = new Date(startTime).toLocaleDateString();
        return formattedDate;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "15%",
      align: "center",
      key: "created_at",
      render: (text, item) => {
        return (
          <div className="playeranalytics-table-action w-[98%] mx-auto">
            <Tooltip title="View Game Analytics">
              <Button
                icon={<img src={group} />}
                onClick={() => {
                  history(`/game-analytic-dashboard`, {
                    state: { week: item.week },
                  });
                }}
              />
            </Tooltip>

            <Tooltip title="View Player Stats">
              <Button
                icon={<img src={userSquare} />}
                onClick={() => {
                  history(`/player-stats-view`, {
                    state: { week: item.week },
                  });
                }}
              />
            </Tooltip>

            <Tooltip title="View Coaches Notes.">
              <Button
                icon={<img src={clipimg} />}
                onClick={() => {
                  history(
                    `/coaches-note?game_id=${_.get(item, "game_id")}&opponent=${
                      item.title
                    }&team=${item.team}&week=${item.week}&date=${
                      item.created_at
                    }&t=${item.feedback_type || "analytical"}`,
                    {
                      state: { week: item.week },
                    }
                  );
                }}
              />
            </Tooltip>

            <Tooltip title="View Statistics">
              <Button
                icon={<img src={stats} />}
                onClick={() => {
                  history(`/stats-view`, {
                    state: { week: item.week },
                  });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        {/* aLL, O,D,K BUTTONS */}
        <div className="w-[139px] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around mr-[16px]">
          <button
            onClick={() => ToglePositionsArray("o", ["QB"], "QB", qbColumn)}
            className={
              (odk == "o" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
              " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
            }
          >
            O
          </button>
          <button
            onClick={() => ToglePositionsArray("d", ["DL"], "DL", DCol)}
            className={
              (odk == "d" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
              " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
            }
          >
            D
          </button>
          <button
            onClick={() => ToglePositionsArray("k", ["K"], "K", kCol)}
            className={
              (odk == "k" ? " bg-[#A40E17]" : " bg-[#FFFFFF1A] ") +
              " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
            }
          >
            K
          </button>
        </div>

        {/* positions list */}
        {odk == "o" && (
          <div className="w-[215px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
            {" "}
            <button
              onClick={() => ToglePositionsArray("o", ["QB"], "QB", qbColumn)}
              className={
                (position == "QB"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
              }
            >
              QB
            </button>
            <button
              onClick={() => ToglePositionsArray("o", ["OL"], "OL", olCol)}
              className={
                (position == "OL"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
              }
            >
              OL
            </button>
            <button
              onClick={() => ToglePositionsArray("o", ["WR"], "WR", wrCol)}
              className={
                (position == "WR"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
              }
            >
              WR
            </button>
            <button
              onClick={() => ToglePositionsArray("o", ["TE"], "TE", teCol)}
              className={
                (position == "TE"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
              }
            >
              TE
            </button>
            <button
              onClick={() => ToglePositionsArray("o", ["RB"], "RB", rbCol)}
              className={
                (position == "RB"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
              }
            >
              RB
            </button>
          </div>
        )}
        {odk == "d" && (
          <div className="w-[172px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
            {" "}
            <button
              onClick={() => ToglePositionsArray("d", ["DL"], "DL", DCol)}
              className={
                (position == "DL"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
              }
            >
              DL
            </button>
            <button
              onClick={() => ToglePositionsArray("d", ["LB"], "LB", DCol)}
              className={
                (position == "LB"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
              }
            >
              LB
            </button>
            <button
              onClick={() => ToglePositionsArray("d", ["S"], "S", DCol)}
              className={
                (position == "S"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
              }
            >
              S
            </button>
            <button
              onClick={() => ToglePositionsArray("d", ["C"], "C", DCol)}
              className={
                (position == "C"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
              }
            >
              C
            </button>
          </div>
        )}
        {odk == "k" && (
          <div className="w-[86px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
            {" "}
            <button
              onClick={() => ToglePositionsArray("k", ["ST"], "ST", stCol)}
              className={
                (position == "ST"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") +
                " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
              }
            >
              ST
            </button>
            <button
              onClick={() => ToglePositionsArray("k", ["K"], "K", kCol)}
              className={
                (position == "K"
                  ? "bg-[#1A91FF] text-[#ffffff]"
                  : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
              }
            >
              K
            </button>
          </div>
        )}
        <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] mr-[10px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
          <Select
            bordered={false}
            placeholder={"All Games"}
            className="w-[100%] text-[14px]"
            onChange={(id) => {
              if (id === "all") {
                const game = gamesList?.filter(
                  (game) =>
                    game.type == "regular season" || game.type == "playoffs"
                );
                // setGamesIdsList
                setGamesIdsList(game?.map(({ id }) => id));
                setTheirTeam("Season Stats");
              } else if (id === "scrimmages") {
                const game = gamesList?.filter(
                  (game) => game.type == "scrimmage"
                );
                setGamesIdsList(game?.map(({ id }) => id));
                setTheirTeam("Scrimmages stats");
              } else if (id === "practice") {
                const game = gamesList?.filter(
                  (game) => game.type == "practice"
                );

                setGamesIdsList(game?.map(({ id }) => id));

                setTheirTeam("Practices stats ");
              } else {
                const game = gamesList?.filter((game) => game.id == id)[0];
                setGamesIdsList([id]);

                setTheirTeam(game.title);
              }
            }}
          >
            <Option
              value={"all"}
              key={"all"}
              className="text-[14px] font-bold font-inter"
            >
              All Games
            </Option>
            <Option
              value={"practice"}
              key={"practice"}
              className="text-[14px] font-bold font-inter"
            >
              Practices
            </Option>

            <Option
              value={"scrimmages"}
              key={"scrimmages"}
              className="text-[14px] font-bold font-inter"
            >
              Scrimmages
            </Option>

            {gamesList?.map(({ id, title, week, type }, i) => {
              if (type == "regular season" || type == "playoffs")
                return (
                  <Option
                    value={id}
                    key={title}
                    className="text-[14px] font-bold font-inter"
                  >
                    Week {week}
                  </Option>
                );
            })}
          </Select>
        </div>
        <div className="w-[200px] h-[43px] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
          <Select
            bordered={false}
            defaultValue={seasonsList[0]?.title}
            placeholder={seasonsList[0]?.title}
            onChange={(value) => {
              loadGamesList(value);
            }}
            className="w-[100%] text-[14px]"
          >
            {_.map(seasonsList, ({ id, title }, index) => {
              return (
                <Option
                  value={id}
                  key={id}
                  className="text-[14px] font-bold font-inter"
                >
                  {title}
                </Option>
              );
            })}
          </Select>
        </div>

        {/* table div */}
      </div>
      <Spin spinning={pageLoading}>
        <div
          className="w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-[20px]"
          style={{ border: "1px solid #EaEDF0" }}
        >
          <div className="flex items-center justify-center text-[#0F1a24] text-[1rem] h-[47px] p-3 font-[700] bg-[#EaEDF0]">
            {" "}
            <span>Performance Totals</span>
          </div>
          <div className="flex flex-col mt-3 p-2 w-full">
            {/* table */}
            <div className="w-full gameanalyticstablediv">
              <Table
                className="w-full"
                dataSource={playerInfo}
                columns={col}
                bordered
                scroll={{ x: true }}
              />
            </div>
          </div>
        </div>

        {/* {position == "QB" && (
          <div className="flex items-center justify-between mt-[24px]">
            <RunPassComponent title="Run" execution={80} effort={70} />
            <RunPassComponent title="Pass" execution={80} effort={70} />
          </div>
        )} */}

        {/* //performace analytics */}
        <div className="mt-4">
          <h3 className="text-lg font-bold">Performance Analytics</h3>
          <div className="w-[100%] flex flex-wrap justify-between bg-white">
            {/* exec */}
            <PlayerAnalyticCell
              Title={"Execution Report"}
              total_games={
                parseInt(gamesData?.total_wins) +
                  parseInt(gamesData?.total_loss) || 0
              }
              TotalAssignmentsEvaluated={gamesData?.total_grades || 0}
              Avg={"Execution"}
              winValue={
                (gamesData?.total_assignment_executed_in_won_games /
                  gamesData?.total_grades_in_won_games) *
                  100 || 0
              }
              lossValue={
                (gamesData?.total_assignment_executed_in_lost_games /
                  gamesData?.total_grades_in_lost_games) *
                  100 || 0
              }
              totalPlays={gamesData?.total_plays || 0}
              calcParam={"Assignments Failed"}
              calcParamValue={
                gamesData?.total_grades -
                  gamesData?.total_assignment_executed || 0
              }
              differential={"5%"}
              formatNum={formatNum}
            />
            <PlayerAnalyticCell
              Title={"Effort Report"}
              totalPlays={gamesData?.total_plays || 0}
              TotalAssignmentsEvaluated={
                formatNum(gamesData?.total_great_effort) || 0
              }
              Avg={"Effort"}
              total_games={
                parseInt(gamesData?.total_wins) +
                  parseInt(gamesData?.total_loss) || 0
              }
              formatNum={formatNum}
              winValue={
                formatNum(
                  (gamesData?.total_great_effort_in_won_games /
                    gamesData?.total_grades_in_won_games) *
                    100
                ) || 0
              }
              lossValue={
                formatNum(
                  (gamesData?.total_great_effort_in_lost_games /
                    gamesData?.total_grades_in_lost_games) *
                    100
                ) || 0
              }
              calcParam={"Bad Effort Plays"}
              calcParamValue={
                formatNum(
                  gamesData?.total_grades - gamesData?.total_great_effort
                ) || 0
              }
            />
          </div>
        </div>

        {/* steps */}
        {odk !== "k" && (
          <div className="mt-[24px] flex items-center justify-between">
            <RunPassGameComponent
              executionPercent={playTypeData?.run_executed}
              effortPercent={playTypeData?.run_effort}
              gameScorePercent={
                (parseInt(playTypeData?.run_executed) +
                  parseInt(playTypeData?.run_effort)) /
                2
              }
              color="#A40E17"
              title="Run Game"
            />
            <RunPassGameComponent
              executionPercent={playTypeData?.pass_executed}
              effortPercent={playTypeData?.pass_effort}
              gameScorePercent={
                (parseInt(playTypeData?.pass_executed) +
                  parseInt(playTypeData?.pass_effort)) /
                2
              }
              color="#1A91FF"
              title="Pass Game"
            />
          </div>
        )}

        {/* EFFORT AND EXECUTION BY DOWN */}

        {/* score by down div */}
        {odk !== "k" && (
          <>
            <div className="flex justify-between items-center mt-[20px]">
              {/* exec */}
              <div
                className="w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
                style={{ border: "1px solid #EaEDF0" }}
              >
                <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                  <span>Execution by Down</span>
                </div>
                <div className="flex flex-col mt-[11px] w-full p-[12px]">
                  <div className="flex items-center w-full font-inter text-[12px]">
                    <span className="w-[100%]">
                      <span className="w-[100%] flex items-center">
                        <MultiBarChartTwo
                          title="Win"
                          titleTwo="Loss"
                          data={[
                            {
                              instance: "1st down",
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_won_dn_one
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_one -
                                  gamesData?.total_assignment_executed_won_dn_one
                              ),
                            },
                            {
                              instance: "2nd down",
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_won_dn_two
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_two -
                                  gamesData?.total_assignment_executed_won_dn_two
                              ),
                            },
                            {
                              instance: "3rd down",
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_won_dn_three
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_three -
                                  gamesData?.total_assignment_executed_won_dn_three
                              ),
                            },
                            {
                              instance: "4th down",
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_won_dn_four
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_four -
                                  gamesData?.total_assignment_executed_won_dn_four
                              ),
                            },
                          ]}
                          dataTwo={[
                            {
                              instance: "1st down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_one -
                                  gamesData?.total_assignment_executed_lost_dn_one
                              ),
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_lost_dn_one
                              ),
                            },
                            {
                              instance: "2nd down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_two -
                                  gamesData?.total_assignment_executed_lost_dn_two
                              ),
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_lost_dn_two
                              ),
                            },
                            {
                              instance: "3rd down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_three -
                                  gamesData?.total_assignment_executed_lost_dn_three
                              ),
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_lost_dn_three
                              ),
                            },
                            {
                              instance: "4th down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_four -
                                  gamesData?.total_assignment_executed_lost_dn_four
                              ),
                              Executed: formatNum(
                                gamesData?.total_assignment_executed_lost_dn_four
                              ),
                            },
                          ]}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              {/* effort */}
              <div
                className="w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
                style={{ border: "1px solid #EaEDF0" }}
              >
                <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                  <span>Effort by Down</span>
                </div>
                <div className="flex flex-col mt-[11px] w-full p-[12px]">
                  <div className="flex justify-between items-center w-full font-inter text-[12px]">
                    <span className="w-[100%]">
                      <span className="w-[100%] flex items-center">
                        <MultiBarChartTwo
                          title="Win"
                          titleTwo="Loss"
                          data={[
                            // total_grades_dn_one
                            {
                              instance: "1st down",
                              Executed: formatNum(
                                gamesData?.total_great_effort_won_dn_one
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_one -
                                  gamesData?.total_great_effort_won_dn_one
                              ),
                            },
                            {
                              instance: "2nd down",
                              Executed: formatNum(
                                gamesData?.total_great_effort_won_dn_two
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_two -
                                  gamesData?.total_great_effort_won_dn_two
                              ),
                            },
                            {
                              instance: "3rd down",
                              Executed: formatNum(
                                gamesData?.total_great_effort_won_dn_three
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_three -
                                  gamesData?.total_great_effort_won_dn_three
                              ),
                            },
                            {
                              instance: "4th down",
                              Executed: formatNum(
                                gamesData?.total_great_effort_won_dn_four
                              ),
                              Failed: formatNum(
                                gamesData?.total_grades_won_dn_four -
                                  gamesData?.total_great_effort_won_dn_four
                              ),
                            },
                          ]}
                          dataTwo={[
                            // total_grades_dn_one
                            {
                              instance: "1st down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_one -
                                  gamesData?.total_great_effort_lost_dn_one
                              ),
                              Executed: formatNum(
                                gamesData?.total_great_effort_lost_dn_one
                              ),
                            },
                            {
                              instance: "2nd down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_two -
                                  gamesData?.total_great_effort_lost_dn_two
                              ),
                              Executed: formatNum(
                                gamesData?.total_great_effort_lost_dn_two
                              ),
                            },
                            {
                              instance: "3rd down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_three -
                                  gamesData?.total_great_effort_lost_dn_three
                              ),
                              Executed: formatNum(
                                gamesData?.total_great_effort_lost_dn_three
                              ),
                            },
                            {
                              instance: "4th down",
                              Failed: formatNum(
                                gamesData?.total_grades_lost_dn_four -
                                  gamesData?.total_great_effort_lost_dn_four
                              ),
                              Executed: formatNum(
                                gamesData?.total_great_effort_lost_dn_four
                              ),
                            },
                          ]}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* progress bars */}
            <div className="flex flex-col w-full mt-12">
              <div className="flex items-center w-full text-xl font-bold text-[#0F1A24]">
                <span>Flag Report</span>
                <div className="w-[80%] h-[1px] bg-[#EAEDF0] ml-2 mt-1"></div>
              </div>
              <div className="flex w-full justify-between">
                {/* flag over view and flag win/loss breakdown */}
                <div className="flex flex-col w-[49%]">
                  <div
                    className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                    style={{ border: "1.5px solid #EaEDF0" }}
                  >
                    <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                      <span>Flag Overview</span>
                    </div>
                    <div className="h-[184px] w-full justify-between p-3 bg-[#FFFFFF] font-poppins flex flex-wrap items-center font-bold text-[12px]">
                      <TotalNumberCard
                        title="Total Number of Plays"
                        number={formatNum(gamesData?.total_plays)}
                      />
                      <TotalNumberCard
                        title="Total Number of Flags"
                        number={formatNum(gamesData?.total_flags)}
                      />
                      <TotalNumberCard
                        title="Assignments Executed"
                        number={formatNum(gamesData?.total_assignment_executed)}
                      />
                      <TotalNumberCard
                        title="Flags Per Game"
                        number={formatNum(
                          gamesData?.total_flags /
                            (gamesData?.total_wins + gamesData?.total_loss)
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                    style={{ border: "1.5px solid #EaEDF0" }}
                  >
                    <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                      <span>Flag Win/Loss Breakdown</span>
                    </div>
                    <div className="h-[184px] w-full text-center bg-[#FFFFFF] font-poppins flex flex-col items-center justify-center font-bold text-[#526B84] text-[12px]">
                      {/* win progress bar */}
                      <div className="flex justify-between items-center mt-[13px] flagWinBreakdown">
                        <span className="text-[10px] mr-[12px] mt-[4px]">
                          {" "}
                          Wins
                        </span>
                        <div className="progress-container">
                          <Progress
                            percent={formatNum(
                              (gamesData?.total_flags_in_won_games /
                                gamesData?.total_flags) *
                                100
                            )}
                            style={{ width: 358, margin: 0, padding: 0 }}
                            strokeColor="#E2B517"
                            showInfo={false}
                          />
                          <div className="progress-text1">
                            {" "}
                            {formatNum(gamesData?.total_flags_in_won_games)}
                          </div>
                        </div>
                      </div>
                      {/* losses progress bar */}
                      <div className="flex justify-between items-center mt-[16px] flagWinBreakdown">
                        <span className="text-[10px] mr-[5px] mt-[4px]">
                          {" "}
                          Losses
                        </span>
                        <div className="progress-container">
                          <Progress
                            percent={formatNum(
                              (gamesData?.total_flags_in_lost_games /
                                gamesData?.total_flags) *
                                100
                            )}
                            style={{ width: 358, margin: 0, padding: 0 }}
                            strokeColor="#a40E17"
                            showInfo={false}
                          />
                          <div className="progress-text">
                            {formatNum(gamesData?.total_flags_in_lost_games)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* flag breakdown pie chart */}
                {/* Flag Pie */}
                <div
                  className="mt-4 flex flex-col rounded-[8px] w-[49%]"
                  style={{ border: "1.5px solid #EaEDF0" }}
                >
                  <PieChartCel
                    title="Flag Down Breakdown"
                    data={[
                      {
                        name: "1st Down",
                        value: formatNum(gamesData?.total_flags_dn_one),
                      },
                      {
                        name: "2nd Down",
                        value: formatNum(gamesData?.total_flags_dn_two),
                      },
                      {
                        name: "3rd Down",
                        value: formatNum(gamesData?.total_flags_dn_three),
                      },
                      {
                        name: "4th Down",
                        value: formatNum(gamesData?.total_flags_dn_four),
                      },
                    ]}
                    width={300}
                  />{" "}
                </div>
              </div>
            </div>
          </>
        )}

        {odk == "k" && (
          <Kicker
            kchartlineData={kchartlineData}
            playerInfo={playerInfo}
            gamesData={gamesData}
            formatNum={formatNum}
            position={position}
            kickingChartLineData={kickingChartLineData}
          />
        )}

        {/* statistics section */}

        <button
          className="mt-6 bg-[#E8F4FF] flex items-center text-[#2E4D6B] focus:outline-0 p-2 font-normal rounded-[4px] text-[12px]"
          onClick={() => generateCustomReport()}
        >
          <img src={multiplePages} className="w-6 mr-2" />
          <span>Generate Custom Report</span>
        </button>
        {/* second table */}
        <div className="w-full mt-2 gameanalytictablediv"></div>
      </Spin>
      {/* end */}
      <Table
        columns={customColumn}
        dataSource={gamesList.map((item) => {
          if (game_id) {
            const singleGame = gamesList.find((game) => game_id === game.id);
            if (singleGame && item.id === singleGame.id) {
              item.checked = true;
            }
            return item;
          } else {
            item.checked = true;
            return item;
          }
        })}
        className="w-full"
        bordered
        pagination={false}
      />
    </div>
  );
}

const Kicker = ({
  kchartlineData,
  playerInfo,
  gamesData,
  formatNum,
  position,
  kickingChartLineData,
}) => {
  const total_grades = playerInfo?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total_grades,
    0
  );
  const total_punt_distance = playerInfo?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.total_punt_distance,
    0
  );
  const total_punts = playerInfo?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total_punts,
    0
  );
  const total_field_goal_distance = playerInfo?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.total_field_goal_distance,
    0
  );

  // console.log("playerInfo", playerInfo);
  // console.log("kchartlineData", kchartlineData);
  return (
    <>
      <div className="w-full mt-[20px] mb-4 flex flex-col font-inter">
        {/* <span className="text-[23px] font-bold">Special Teams </span> */}
        {position === "K" && (
          <div className="flex items-center w-full text-xl font-bold text-[#0F1A24]">
            <span>Kick Report</span>
          </div>
        )}
        <div
          className=" w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-[12px]"
          style={{ border: "1px solid #EaEDF0" }}
        >
          <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
            {" "}
            <span>
              {position == "K" ? "Kicking Units" : "Special Teams Units"}
            </span>
          </div>
          <div className="flex flex-col mt-[11px] w-full p-[12px]">
            <div className="flex justify-between items-center  w-full font-inter text-[12px]">
              <span className="w-[100%]">
                <span className=" w-[100%] flex items-center">
                  {position == "K" ? (
                    <KickingLineChartComponent
                      data={kickingChartLineData}
                      title="Execution"
                      titleTwo="Effort"
                    />
                  ) : (
                    <KMultiLineChart
                      title="Execution"
                      titleTwo="Effort"
                      data={kchartlineData}
                    />
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mt-12">
        <div className="flex w-full justify-between">
          {/* flag over view and flag win/loss breakdown */}
          {position == "K" ? (
            <div className="flex flex-col w-[49%]">
              {/* <div
                className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] mr-[24px]"
                style={{ border: "1.5px solid #EaEDF0" }}
              >
                <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                  <span>Kick Overview</span>
                </div>
                <div className="w-full justify-between p-3 bg-[#FFFFFF] gap-y-5 flex-grow font-poppins flex flex-wrap items-center font-bold text-[12px]">
                  <TotalNumberCard
                    title="Total Number of Kicks"
                    number={formatNum(gamesData?.total_plays)}
                  />
                  <TotalNumberCard
                    title="Kickoffs Kicked"
                    number={formatNum(gamesData?.total_flags)}
                  />
                  <TotalNumberCard
                    title="Field Goals Made"
                    number={formatNum(gamesData?.total_assignment_executed)}
                  />
                  <TotalNumberCard
                    title="Avg FG Distance"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                  <TotalNumberCard
                    title="Longest FG Distance"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                  <TotalNumberCard
                    title="Punts Kicked"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                  <TotalNumberCard
                    title="Avg Punt Distance"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                  <TotalNumberCard
                    title="Longest Punt Distance"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                </div>
              </div> */}
            </div>
          ) : (
            <div className="flex flex-col w-[49%]">
              <div
                className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                style={{ border: "1.5px solid #EaEDF0" }}
              >
                <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                  <span>Flag Overview</span>
                </div>
                <div className="h-[184px] w-full justify-between p-3 bg-[#FFFFFF] font-poppins flex flex-wrap items-center font-bold text-[12px]">
                  <TotalNumberCard
                    title="Total Number of Plays"
                    number={formatNum(gamesData?.total_plays)}
                  />
                  <TotalNumberCard
                    title="Total Number of Flags"
                    number={formatNum(gamesData?.total_flags)}
                  />
                  <TotalNumberCard
                    title="Assignments Executed"
                    number={formatNum(gamesData?.total_assignment_executed)}
                  />
                  <TotalNumberCard
                    title="Flags Per Game"
                    number={formatNum(
                      gamesData?.total_flags /
                        (Number(gamesData?.total_wins) +
                          Number(gamesData?.total_loss))
                    )}
                  />
                </div>
              </div>
              <div
                className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                style={{ border: "1.5px solid #EaEDF0" }}
              >
                <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                  <span>Flag Win/Loss Breakdown</span>
                </div>
                <div className="h-[184px] w-full text-center bg-[#FFFFFF] font-poppins flex flex-col items-center justify-center font-bold text-[#526B84] text-[12px]">
                  {/* win progress bar */}
                  <div className="flex justify-between items-center mt-[13px] flagWinBreakdown">
                    <span className="text-[10px] mr-[12px] mt-[4px]">
                      {" "}
                      Wins
                    </span>
                    <div className="progress-container">
                      <Progress
                        percent={formatNum(
                          (gamesData?.total_flags_in_won_games /
                            gamesData?.total_flags) *
                            100
                        )}
                        style={{ width: 358, margin: 0, padding: 0 }}
                        strokeColor="#E2B517"
                        showInfo={false}
                      />
                      <div className="progress-text1">
                        {formatNum(gamesData?.total_flags_in_won_games)}
                      </div>
                    </div>
                  </div>
                  {/* losses progress bar */}
                  <div className="flex justify-between items-center mt-[16px] flagWinBreakdown">
                    <span className="text-[10px] mr-[5px] mt-[4px]">
                      {" "}
                      Losses
                    </span>
                    <div className="progress-container">
                      <Progress
                        percent={formatNum(
                          (gamesData?.total_flags_in_lost_games /
                            gamesData?.total_flags) *
                            100
                        )}
                        style={{ width: 358, margin: 0, padding: 0 }}
                        strokeColor="#a40E17"
                        showInfo={false}
                      />
                      <div className="progress-text">
                        {formatNum(gamesData?.total_flags_in_lost_games)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {position !== "K" && (
            <div
              className="mt-4 flex flex-col rounded-[8px] w-[49%]"
              style={{ border: "1.5px solid #EaEDF0" }}
            >
              <PieChartCel
                title={"Flags By Unit"}
                data={[
                  {
                    name: "1st Down",
                    value: formatNum(gamesData?.total_flags_dn_one),
                  },
                  {
                    name: "2nd Down",
                    value: formatNum(gamesData?.total_flags_dn_two),
                  },
                  {
                    name: "3rd Down",
                    value: formatNum(gamesData?.total_flags_dn_three),
                  },
                  {
                    name: "4th Down",
                    value: formatNum(gamesData?.total_flags_dn_four),
                  },
                ]}
                width={300}
              />{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
  gradesList: (details) =>
    dispatch(global.redux.action.grades.playerAnalytics(details)),
  playTypeGrades: (details) =>
    dispatch(global.redux.action.grades.playTypeGrades(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  matchAnalyticsByDepthChart: (details) =>
    dispatch(global.redux.action.grades.matchAnalyticsByDepthChart(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerAnalyticsDashboard);
