import React, { useState, useEffect } from "react";
import { Popover, Tooltip, Badge } from "antd";
import wing from "../../assets/img/angel 2w.svg";
import angel from "../../assets/img/Angel.svg";

function AngelPopUp() {
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(true);
  const [dot, setDot] = useState(true);

  useEffect(() => {
    const storedAngelDot = localStorage.getItem("angelnotificationDot");
    if (storedAngelDot !== null) {
      setDot(JSON.parse(storedAngelDot));
    }
    setTimeout(() => {
    localStorage.removeItem("angelnotificationDot");
  }, 24 * 60 * 60 * 1000);
  }, []);
  const handleClose = () => {
    setMessage(false);
  };
  const openMessage = () => {
    setMessage(true);
    setDot(false);
    localStorage.setItem("angelnotificationDot", JSON.stringify(false));
  };
  

  return (
    <Popover
      title={
        <span className='text-[14px] font-bold text-[#0F1A24] font-inter'>
          God loves you:
        </span>
      }
      content={
        <div className='flex bg-[#EAEDF0] w-[290px] h-[100px] justify-between font-inter text-[12px] font-bold text-[#2E4D6B] rounded-[4px] px-[15px] py-[10px]'>
          <div className='flex'>
            <img
              src={wing}
              className='w-[24px] h-[24px] justify-self-start mr-[8px]'
            />
            <span className='w-[191px]'>
              God loves you. Make sure your players know that. Email us at Execute@wardbord.com *
              if you want to see more development in this area.
            </span>
          </div>
          <Tooltip title='Close'>
            <i
              className='fas fa-xmark cursor-pointer'
              onClick={handleClose}></i>
          </Tooltip>
        </div>
      }
      trigger={["hover", "click"]}
      open={message}
      onCancel={handleClose}
      placement='bottomLeft'>
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          src={wing}
          onClick={openMessage}
          className='w-[24px] cursor-pointer'
        />
        {dot && (
          <div
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              backgroundColor: "#A40E17",
              borderRadius: "50%",
              width: "8px",
              height: "8px",
            }}></div>
        )}
      </div>
    </Popover>
  );
}

export default AngelPopUp;
