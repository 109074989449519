export const StatsData = [
  {
    title: "Offensive",
    data: [
      {
        title: "Offensive Plays",
        value: 0,
      },
      {
        title: "Rushing Yards",
        value: 0,
      },
      {
        title: "Passing Yards",
        value: 0,
      },
      {
        title: "Receiving Yards",
        value: 0,
      },
      {
        title: "Total Offensive Yards",
        value: 0,
      },
      {
        title: "Yards After Contact",
        value: 0,
      },
      {
        title: "Yards After Catch",
        value: 0,
      },
      {
        title: "Receiving Targets",
        value: 0,
      },
      {
        title: "Fumbles",
        value: 0,
      },
      {
        title: "Passing TD’s",
        value: 0,
      },
      {
        title: "Rushing/Receiving TD’s",
        value: 0,
      },
      {
        title: "Offensive TD’s",
        value: 0,
      },
    ],
  },
  {
    title: "Defensive",
    data: [
      {
        title: "Defensive Plays",
        value: 0,
      },
      {
        title: "Total TFL",
        value: 0,
      },
      {
        title: "Yardage from TFL’s",
        value: 0,
      },
      {
        title: "INT’s",
        value: 0,
      },
      {
         title: "Total Tackles",
        value: 0,
      },
      {
        title: "Total Sacks",
        value: 0,
      },
      {
        title: "Yardage from Sacks",
        value: 0,
      },
      {
        title: "Yardage from INT",
        value: 0,
      },
      {
        title: "Missed Tackles",
        value: 0,
      },
      {
        title: "PBU",
        value: 0,
      },
      {
        title: "Turnovers Created",
        value: 0,
      },
      {
        title: "Defensive TD’s",
        value: 0,
      },
    ],
  },
  {
    title: "Special Teams",
    data: [
      {
        title: "Special Teams Plays",
        value: 0,
      },
      {
        title: "Kickoff Return Yards",
        value: 0,
      },
      {
        title: "Kickoff Return Avg",
        value: 0,
      },
      {
        title: "Kickoff Return TD’s",
        value: 0,
      },
      {
        title: "Punt Return Yards",
        value: 0,
      },
      {
        title: "Punt Return Avg",
        value: 0,
      },
      {
        title: "Punt Return TD’s",
        value: 0,
      },
      {
        title: "ST Tackles",
        value: 0,
      },
      {
        title: "ST Caused Fumbles",
        value: 0,
      },
      {
        title: "ST Blocks",
        value: 0,
      },
      {
        title: "Total Return Yards",
        value: 0,
      },
      {
        title: "ST TD’s",
        value: 0,
      },
    ],
  },
  {
    title:"Kicker",
    data: [
      {
        title: "Special Teams Plays",
        value: 0,
      },
      {
        title: "FG’s Made",
        value: 0,
      },
      {
        title: "FGM %",
        value: 0,
      },
      {
        title: "Avg FGM Dist",
        value: 0,
      },
      {
        title: "FGM Long",
        value: 0,
      },
      {
        title: "Punts",
        value: 0,
      },
      {
        title: "Avg Punt Dist",
        value: 0,
      },
      {
        title: "Punt Long",
        value: 0,
      },
      {
        title: "PAT Made",
        value: 0,
      },
      {
        title: "Kickoffs",
        value: 0,
      },
      {
        title: "KO Touchbacks",
        value: 0,
      },
      {
        title: "Onside Kicks Recovered",
        value: 0,
      },
    ],
  }
];
