import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  InputNumber,
  Select,
  Switch,
  Checkbox,
  Modal,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import _ from 'lodash';
import "./home-page.css";
// import UserImg from "../../../assets/img/Profile-Pic.png";
import footBall from "../../../assets/img/football.png";
import smileyDefault from "../../../assets/img/smiley-default.png";
import smileyactive from "../../../assets/img/smiley-active.png";
import { connect } from 'react-redux';
const { Option } = Select;
const { TextArea } = Input;
const CardModal =(props)=> { 
    const [disabled1, setdisabled1] = useState(false);
    const [showModal, setshowModal] = useState(true);
    const [positionsType, setPositionsType] = useState('')
    const [form] = Form.useForm();
    const [formPositionsType, setFormPositionsType] = useState('');
    const [feedBackType, setFeedBackType] = useState('');
    const [assignmentExecuted, setassignmentExecuted] = useState(true);
    const [greatEffort, setGreatEffort] = useState(true);
    const [viewData, setViewData] = useState({});
    const [value, setValue] = useState(true);
    const [penalties, setPenalties] = useState(true);
    const [puntDistanceValidate, setPuntDistanceValidate] = useState(false);
    const [fieldGoalDistanceValidate, setFieldGoalDistanceValidate] = useState(false);  
    
    useEffect(() => {
      const cardData = _.get(props,'data', {});
      let prePopulate =_.get(props,'prePopulate')
         
      if (_.size(_.get(prePopulate,'value',[]))>0 && _.get(prePopulate,'value[0].id')) {
        // formPositionsType()
        changingPostions(_.head(_.get(cardData,'positions',[]))) 
        
        
      }else{
        setViewData({})
        changingPostions(_.head(_.get(cardData,'positions',[]))) 
        
      }
     
    }, [_.get(props,'prePopulate.id')])
    
    useEffect(() => {
      if (_.get(props,'isvisible')==false) {
        form.resetFields()
        
      }
     
    }, [_.get(props,'isvisible')])
    const gradesView =(id,postions)=>{
      props.gradesView({
        params: {id:id},
        callback: async (res, data) => {
          if (res === true) {
            
            if (_.get(data, 'data.details.id')) {
              // changingPostions(postions)
              setViewData(_.get(data, 'data.details', {}))
        setFormFieldsDefault(_.get(data, 'data.details', {}))
            
            
          }
        }
      
        }
      })
    }
    const setFormFieldsDefault =(e)=>{ 
  form.setFieldsValue({
  ...e,
  feedBack:_.get(e,'positive_feedback')
  })
    setFieldGoalDistanceValidate(_.get(e,'field_goals')==null?false:true)

    setPuntDistanceValidate(_.get(e,'punts')==null?false:true)

  setassignmentExecuted(_.get(e,'assignment_executed')==1?true:false);
  setGreatEffort(_.get(e,'great_effort')==1?true:_.get(e,'great_effort')==null?null:false);
  setPenalties(_.get(e,'penalties')==0?true:false);
  }
    const changingPostions =async (postions)=>{
      await setFormPositionsType(postions)
      let siteGridironDetails=_.get(props,'siteGridironDetails') 
      _.map(siteGridironDetails,(value,index)=>{
        if (_.get(value,"code")==postions) {
          setPositionsType(_.get(value,"type"))
        }
      })
      let prePopulate =_.get(props,'prePopulate')
      if(_.size(_.get(prePopulate,'value',[]))>0){
        _.map(_.get(prePopulate,'value',[]),(value,index)=>{
          if (_.get(value,'position')==postions) {
            gradesView(_.get(value,'id'))
          }else{
            setViewData({})
          }
        })
      }
      
    }
    const renameAllFields =(value)=>{
if(value=='Knockdowns'){
  return "Bit Hit"
}else if(value=='Td'){
  return "TD"
}else if(value=='Pancakes'){
  return "Pancake"
}else if(value=='Cut Blocks'){
  return "Cut Block"
}else if(value=='Receptions'){
  return "Reception"
}else if(value=='Completions'){
  return "Completion"
}else if(value=='Total Carries'){
  return "Carry"
}else if(value=='Catchable Balls'){
  return "Catchable Ball"
}

    }

    const fieldsItems = (value)=>{
      let position= {
        QB: ["completions","catchable_balls","td"],
        WR : ["pancakes","receptions","td"],
        RB : ["total_carries","receptions","td"],
        OL:["pancakes","knockdowns","cut_blocks"],

      } 
      if(_.get(position,value) && _.size(_.get(position,value))>0){
return _.map(_.get(position,value),(v,i)=>{
  return <Form.Item 
  name={v}
    className="col-12 col-sm-6 col-lg-4"
    label={renameAllFields(_.startCase(v))}
    
  >
    <InputNumber
      min={1}
      max={10}
      initialvalues={3}
      className="full-width"
       
    />
  </Form.Item>
})
      }else{
        return _.map(_.get(position,'OL'),(v,i)=>{
          return <Form.Item 
          name={v}
            className="col-12 col-sm-6 col-lg-4"
            label={renameAllFields(_.startCase(v))}
            
          >
            <InputNumber
              min={1}
              max={10}
              initialvalues={3}
              className="full-width"
               
            />
          </Form.Item>
      })
    }
      
    }
const offenseFields =()=>{
return  <React.Fragment>
    {fieldsItems(formPositionsType)}
  
  
</React.Fragment>
}
  

const denseFields =()=>{

  return <React.Fragment>
    {/* <Form.Item  name="total_play"
                    className="col-12 col-sm-6 col-lg-4"
                    label="Total Plays"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       
                    />
                  </Form.Item> */}
                  <Form.Item 
                  name="tackles"
                    className="col-12 col-sm-6 col-lg-5"
                    label="Tackle"
                    
                  >
                    <Radio.Group classname='ml-2 d-flex' onChange={onChange} value={value} >
                      <Radio value={1}>0.5</Radio>
                      <Radio value={2}>1</Radio>
                    </Radio.Group>
                  </Form.Item>
                 
                  <Form.Item
                   name="sacks"
                    className="col-12 col-sm-6 col-lg-5"
                    label="Sack"
                    
                  >
                    <Radio.Group classname='ml-2 d-flex' onChange={onChange} value={value} >
                      <Radio value={1}>0.5</Radio>
                      <Radio value={2}>1</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                  name="turnovers"
                    className="col-12 col-sm-6 col-lg-4"
                    label="Turnover Created"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       
                    />
                  </Form.Item>
  </React.Fragment>
}
const kickerFields =()=>{ 
  return <React.Fragment>
    <Form.Item 
                    className="col-12 col-sm-6 col-lg-4"
                    label="PAT Good"
                    name="pat"
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       
                    />
                  </Form.Item>
                  <Form.Item 
                  name="field_goals"
                    className="col-12 col-sm-6 col-lg-4"
                    label="Field Goal Good"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                      onChange={(e)=>setFieldGoalDistanceValidate(e ?true:false)}
                    />
                  </Form.Item>
                 
                  <Form.Item
                  name="field_goal_distance"
                    className="col-12 col-sm-6 col-lg-4"
                    label="Field Goal Distance"
                    rules={[{ required: fieldGoalDistanceValidate==true? true : false, message: 'Please enter field goal distance' }]}
                    
                  >
                    <InputNumber
                      min={1}
                      max={1000}
                      initialvalues={3}
                      className="full-width"
                       
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Punt"
                    name="punts"
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                      onChange={(e)=>setPuntDistanceValidate(e ?true:false)}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Punt Distance"
                    name="punt_distance"
                    rules={[{ required: puntDistanceValidate==true? true : false, message: 'Please enter punt distance' }]}
                  >
                    <InputNumber
                      min={1}
                      max={1000}
                      initialvalues={3}
                      className="full-width"
                    />
                  </Form.Item>
  </React.Fragment>
}

const onFinish =(e)=>{
  const cardData = _.get(props,'data', {});
  const paramss = new URLSearchParams(window.location.search);
        let game_id = paramss.get('id');
  let values ={
    ...e, 
    position:formPositionsType,
    assignment_executed:assignmentExecuted==true?1:0,
    great_effort:positionsType=="special_teams"?null:greatEffort==true?1:0,
    athlete:_.get(cardData, 'athlete.id'),
    game:parseInt(game_id),
    coach: _.get(props,'coachProfile.coaches'),
    penalties:penalties==true?0:1,
    kicks: 1
  }
    values.positive_feedback=_.get(e,'feedBack')
    
 
  if (_.get(viewData,'id')) {
    values.id=_.get(viewData,'id')
  }
  delete values.feedBack 
  props.onSuccess(values)
}

  const onChange = e => { 
    setValue(parseInt(e.target.value));
  };

  const joinPositions =(value)=>{
    if(_.isArray(value)==true && _.size(value)>0){
     let datas =[]
      _.map(value,(v,i)=>{
        datas.push(v=='WR'?"WR/TE":v)
      })
      return _.join(datas,', ')
    }

  }

   const render =()=> {
         /// this.props.isvisible is required, and it will be a boolean true or false.
        const shouldBeVisible = props.isvisible;
        const cardData = _.get(props,'data', {}); 
        let photo_url =`${_.get(props,'photoPath')}${_.get(cardData, 'athlete.photo')}`
        return <Modal
            title="Basic Modal"
            visible={shouldBeVisible}
            onOk={()=>console.log()}
            onCancel={()=>props.hideModal()}
            width={1000}
            className="PlayerCardModal"
        >
            <div className= { !disabled1 ? "primaryCard active" : "primaryCard" } >
            <div className="row">
              <div className="col-12 col-sm-4 col-lg-4">
                <div className="userInfoHeader" style={{marginBottom:"9px"}}>
                  <span className="cardCheckUncheckContainer" onClick={()=>setdisabled1(!disabled1)}>
                    <i className="fas fa-check-square cardCheck"></i>
                    <i className="far fa-square cardUncheck"></i>
                  </span>
                  <img
                    src={photo_url}
                    className="CardUserImg"
                  />
                  <div className="UserNameLine">
                    <div className="CardName">
                      <span>{_.startCase(_.get(cardData, 'athlete.first_name'))}</span>
                      <span className="CardNameBold">
                        {_.startCase(_.get(cardData, 'athlete.last_name'))}
                      </span>
                    </div>
                    <div className="CardNameLine">
                      <img
                        src={footBall}
                        className="mr-1"
                      />
                      <span>{joinPositions(_.get(cardData,'positions'))}</span>
                    </div>
                  </div>
                  <div className="Soph">
                    <span className="SophNo">{_.get(cardData,'kit_number')}</span>
                    <span>soph</span>
                  </div>
                </div>
               {_.size(_.get(cardData,'positions',[]))>0 && <Form.Item
                style={{marginBottom:"0px"}}
                    className="col-12 col-sm-12 col-lg-12"
                    label="Positions"
                    
                  >
                    <Select
                      initialvalues="Unselfish Block"
                      className="full-width"
                       defaultValue={formPositionsType}
                       value={formPositionsType}
                       onChange={(value)=>changingPostions(value)}
                    >
                      {_.map(_.get(cardData,'positions',[]),(value,index)=>{
                        return <Option key={value} value={value}>
                         {value=="WR"?"WR/TE":value}
                       </Option>
                      })}
                      
                    </Select>
                  </Form.Item>}
                <div className="ExecutedSwitchContainer">
              
                  <div className="ExecutedSwitch">
                    <span>Assignment Executed</span>
                    <Switch
                    checked={assignmentExecuted}
                    onChange={(value)=>setassignmentExecuted(value)}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={
                        <span>No</span>
                      }
                       
                    />
                  </div>
                  {positionsType!="special_teams" && <div className="ExecutedSwitch">
                    <span>Great Effort</span>
                    <Switch
                    checked={greatEffort}
                    onChange={(value)=>setGreatEffort(value)}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={
                        <span>No</span>
                      }
                       
                    />
                  </div>}
                </div>
              </div>
              <div className="col-12 col-sm-8 col-lg-8">
                <Form form={form} onFinish={(e)=>onFinish(e)}  className="PrimaryCardForm">
               
                {positionsType=="offense"&& offenseFields()}
                {positionsType=="defense"&&denseFields()}
                {positionsType=="special_teams" && kickerFields()}
                {/* Kenny we worked on this all week my good man! Great job with your feet! ! */}
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-5"
                    label="Negative Play"
                    name="negative"
                  >
                    <Radio.Group classname='ml-2 d-flex' onChange={onChange} value={value} >
                      <Radio value={2}>Yes</Radio>
                      <Radio value={1}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Penalty Flags"
                    name="penalties"
                  >
                    <Checkbox
                      defaultChecked
                      className="PrimaryCardFormEmojiCheck"
                      name="penalties"
                      onChange={()=>setPenalties(!penalties)}
                      checked={penalties}
                    >
                      <img
                        src={smileyDefault}
                        className="SmileEmoji"
                      />
                      <img
                        src={smileyactive}
                        className="CryEmoji"
                      />
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-12 col-lg-12 mb-0"
                    label="Feedback to player"
                    
                  >
                  </Form.Item>
                  {/* <Form.Item
                    className="col-12 col-sm-6 col-lg-5 text-success"
                    label="Positive"
                    
                  >
                    <Select
                      initialvalues="Unselfish Block"
                      className="full-width"
                       onSelect={(value)=>{ form.setFieldsValue({feedBack: 'Unselfish Block'});
                       setFeedBackType('postive')} }
                       
                    >
                      <Option value="Unselfish Block">
                        Unselfish Block
                      </Option>
                      {/* <Option value="Unselfish Block 1">
                        Unselfish Block 1
                      </Option>
                      <Option value="Unselfish Block 2">
                        Unselfish Block 2
                      </Option> 
                    </Select>
                  </Form.Item> */}
                  {/* <Form.Item
                    className="col-12 col-sm-6 col-lg-5 text-danger"
                    label="Negative"
                    
                  >
                    <Select
                      className="full-width"
                      onSelect={(value)=> {form.setFieldsValue({feedBack: `Busted Block`}); setFeedBackType('negative')} }
                    >
                      <Option value="Line">
                        Busted Block
                      </Option>
                      {/* <Option value="Line1">
                        Busted Block
                      </Option>
                      <Option value="Line2">
                        Busted Block
                      </Option> 
                    </Select>
                  </Form.Item> */}
                  <Form.Item 
                  name="feedBack"
                    className="col-12 col-sm-12 col-lg-12 flex-column mb-0">
                    <TextArea rows={2}  />
                  </Form.Item>
                  <Form.Item 
                    className="col-12 col-sm-12 col-lg-12 flex-column mb-0">
                    <div className="SubmitCancelBtnContainer ant-modal-confirm-btns">
                      <Button className="ant-btn ant-btn-primary" htmlType="submit">Submit</Button>
                      <Button className="ant-btn" 
                       htmlType="reset"
                      onClick={()=>props.hideModal()}>Cancel</Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
    }
    return render()
}

const mapStateToProps = state => {
  
  return {
    coachProfile:_.get(state,'app.account.profile.data.details')
  };
};

const mapDispatchToProps = dispatch => ({
  gradesCreate: details => dispatch(global.redux.action.grades.create(details)),
  gradesView: details => dispatch(global.redux.action.grades.view(details)),
  gradesUpdate: details => dispatch(global.redux.action.grades.update(details)),



});
export default connect(mapStateToProps, mapDispatchToProps)(CardModal)
