import React from "react";
import homeIcon from "../../assets/img/home-simple.svg";

function InvoiceRequestSent() {
  return (
    <div className=" w-[80%] mx-auto text-center flex flex-col">
      <span className="text-[18px] font-bold text-center font-poppins text-[#0F1A24]">
        Thank you for your invoice request submission!
      </span>
      <span className="text-[12px] mt-2 mb-7">
        Please allow us few minutes to process your request and email the
        invoice.
      </span>
      <div>
        <button
          className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
        >
          <img src={homeIcon} />
          <span onClick={() => window.location.replace("/login")}>
            Go back to Homepage
          </span>
        </button>
      </div>
    </div>
  );
}

export default InvoiceRequestSent;
