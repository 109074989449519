import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Table, Card } from "antd";
import searchicon from "../../assets/img/search.svg";

function AffiliateReferrals(props) {
  const [affiliatesList, setAffiliatesList] = useState([]);
  const [affiliatesListLoading, setAffiliatesListLoading] = useState(false);
  const [affiliateListStock, setAffiliateListStock] = useState([]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",
      className: "font-medium",
      width: "10%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      align: "center",
      className: "font-medium",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "15%",
      render: (text, row) => <div>{text?.toLowerCase()}</div>,
    },

    {
      title: "Affiliate Name",
      dataIndex: "school",
      key: "school",
      align: "center",
      className: "font-[400]",
      render: (text, item) => (
        <div>
          {item?.affiliate_first_name + " " + item?.affiliate_last_name}
        </div>
      ),
      width: "20%",
    },
    {
      title: "Affiliate Email",
      dataIndex: "affiliate_email",
      key: "affiliate_email",
      align: "center",
      width: "20%",
    },
    {
      title: "URL",
      dataIndex: "referred_by",
      key: "referred_by",
      align: "center",
      width: "10%",
      render: (text, row) => `coach.wardbord.com/register?via=${text}`,
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   width: "15%",
    //   render: (text, row) => (
    //     <div className="flex gap-1 flex-col items-center justify-center">
    //       <button
    //         // onClick={() => deleteCoach(text)}
    //         className="w-[50%] py-2 bg-[#2E4D6B] rounded-lg text-white text-[10px] font-bold"
    //       >
    //         Delete
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const fetcAffiliates = () => {
    props.getAffiliates({
      callback: (res, data) => {
        if (res === true) {
          console.log("data from getAffiliates", data?.data);
          setAffiliatesList(data?.data || []);
          setAffiliateListStock(data?.data || []);
        }
      },
    });
  };

  useEffect(() => {
    fetcAffiliates();
  }, []);

  function filterByAffiliateName(array, searchText) {
    const regex = new RegExp(searchText, "i"); // 'i' flag makes the search case-insensitive
    return array.filter((obj) =>
      regex.test(obj.affiliate_first_name || obj.affiliate_last_name)
    );
  }
  const filterAffiliate = (text) => {
    const search = filterByAffiliateName(affiliateListStock, text);
    console.log(search.length, text);
    setAffiliatesList(search);
  };

  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Affiliate Referrals
      </h2>
      <Card bodyStyle={{ padding: "24px" }}>
        <div
          className="px-2 py-2 items-center justify-between flex w-[30%] md:w-[200px] mr-[4px] xl:w-[20%] rounded-md"
          style={{ border: "1px solid #EAEDF0" }}
        >
          <input
            type="text"
            placeholder="Search Coach Name"
            className="outline-0 w-[85%]"
            // value={searchText}
            onChange={(e) => filterAffiliate(e.target.value)}
          />
          <img src={searchicon} className=" w-[10%]" />
        </div>
        <div className="mt-[20px]">
          <Table
            columns={columns}
            dataSource={affiliatesList}
            bordered
            style={{ fontSize: "10px", color: "#0F1A24" }}
            loading={affiliatesListLoading}
          />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  coachesList: (details) =>
    dispatch(global.redux.action.super_admin.coachesList(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.coaches.changeStatus(details)),
  gridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  getAffiliates: (details) =>
    dispatch(global.redux.action.super_admin.getAffiliates(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateReferrals);
