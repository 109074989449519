import _ from 'lodash';
import axios from './axios-config';
import global from '../config/global';

export const registerWithoutHeaders = async (requestOptions) => {
  const callback = _.get(requestOptions, "componentRequest.callback", () => {});
  const actionType = _.get(requestOptions, 'actionType', '');
  const reducerName = _.get(requestOptions, 'reducerName', '');
  const dispatch = _.get(requestOptions, 'dispatch', () => {});
  let axiosParams = {
    params: _.get(requestOptions, 'componentRequest.params', {}),
    data: _.get(requestOptions, 'componentRequest.body', {}),
    ..._.get(requestOptions, 'axiosParams', {}),
  };

  // Notification function
  const notification = (data, type, method) => {
    let notification_data = { type };
    if (
      _.get(method, 'method') !== 'get' &&
      _.get(method, 'url') !== '/isLoggedIn' &&
      type === 'success'
    ) { 
      notification_data.title = 'Success';
    } else if (type === 'error') {
      if (_.get(data, 'error.errors[0].rules[0].message')) {
        notification_data.title = 'Error';
        if (
          `${_.get(data, 'error.errors[0].rules[0].message')}`.includes('kit_number')
        ) {
          notification_data.message =
            'The value you provided for the Jersey number already exists for another athlete of the specific school ID you provided for the school attribute.';
        } else {
          notification_data.message = _.get(data, 'error.errors[0].rules[0].message');
        }
        global.utils.notification.nativeAlert(notification_data);
      } else if (_.get(data, 'error.message')) {
        notification_data.title = 'Error';
        notification_data.message = _.get(data, 'error.message');
        global.utils.notification.nativeAlert(notification_data);
      }
    }
  };

  // Start loader for the property
  global.redux.utils.startActionLoader(dispatch, reducerName, actionType);

  // Check if the URL includes "register" before including headers
  if (!axiosParams.url.includes("register")) {
    axiosParams.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  // Using axios without headers
  axios(axiosParams)
    .then((response) => {
      dispatch({
        type: reducerName,
        data: { [actionType]: _.get(response, 'data', {}) },
      });
      global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
      notification(_.get(response, 'data', {}), 'success', axiosParams);
      callback(true, _.get(response, 'data', {}));
    })
    .catch((error) => {
      console.error('error api', error);
      notification(_.get(error, 'response.data', {}), 'error');
      callback(false, _.get(error, 'response.data', {}));
      global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
    })
    .finally(() => {
      // Stop loader for the property
    });
};
