import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AdminBarChart = ({ data, xKey, yKey }) => {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={data} layout="horizontal">
        {" "}
        {/* Switch to horizontal layout */}
        <XAxis
          dataKey={xKey}
          type="category"
          interval={0}
          angle={-45}
          textAnchor="end"
          height={100}
          label={{
            value: xKey,
            position: "insideBottom",
            offset: 0,
          }}
        />{" "}
        <YAxis
          dataKey={yKey}
          type="number"
          domain={["auto", "auto"]}
          label={{
            value: yKey,
            angle: -90,
            position: "left",
            offset: -5,
          }}
        />{" "}
        <Tooltip />
        <Bar dataKey={yKey} fill="#A40E17" />{" "}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AdminBarChart;
