import React, { useState } from 'react'

import { Upload, message, Button, Modal } from 'antd'
import { EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import './schools.css'

const SchoolsUpdatePhotoModal = () => {
  const [showModal, setshowModal] = useState(false)
  const [photo_display, setphoto_display] = useState(null)
  const [loading, setLoading] = useState(false)

  const beforeUpload = async file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      this.setState({ errors: { photo: 'You can only upload JPG/PNG file!' } })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      this.setState({ errors: { photo: 'Image must smaller than 2MB!' } })
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const removeImage = info => {}

  const getBase64 = async (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const onChangePhoto = info => {
    console.log('picData', info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl => {
      setphoto_display(imageUrl)
    })
  }

  const render = () => {
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    return (
      <div className='SchoolsViewModalContainer'>
        <Modal
          title='Modal Title'
          visible={showModal}
          onOk={() => {
            setshowModal(false)
          }}
          onCancel={() => {
            setshowModal(false)
          }}
        >
          <Upload
            name='avatar'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            onRemove={removeImage}
            onChange={onChangePhoto}
            customRequest={dummyRequest}
          >
            {photo_display ? (
              <img
                src={photo_display}
                alt='avatar'
                style={{ width: '100%', borderRadius: '100px' }}
              />
            ) : (
              uploadButton
            )}
            <span className='profile-edit-icon'>
              <EditOutlined />
            </span>
          </Upload>
        </Modal>
      </div>
    )
  }
  return render()
}

export default SchoolsUpdatePhotoModal
