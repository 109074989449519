import actionType from '../actionTypes';
import _ from "lodash";
const reducerName = actionType.reduxUpdateMvpsState;

export default {

    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/mvps/list`
                },
                actionType: actionType.mvps.list,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    create: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/mvps/create`
                },
                actionType: actionType.mvps.create,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/mvps/view`
                },
                actionType: actionType.mvps.view,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    changeStatus: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/mvps/change-status/${_.get(componentRequest, 'params.id')}`
                },
                actionType: actionType.mvps.changeStatus,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

}
