import React, {useEffect} from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import { Form, Input, Button } from 'antd';
import "./schools.css";

const SchoolView = (props) => {
 
   // Creating form instance, which we'll call "form"
   const [form] = Form.useForm();
 
   useEffect(() => {
       fetchSchoolViewData();
   }, []);
 
   const fetchSchoolViewData = () => {
       const params = new URLSearchParams(window.location.search);
       let school_id = params.get('id');
 
       props.getSchoolViewData({
           params: {id: school_id},
           callback: (res, responseData) => {
               setFormFieldsData(_.chain(responseData).get('data.details', {}).pick(['name', 'email', 'city', 'state', 'country', 'zip_code']).value());
               console.log('getSchoolViewData: ', res, responseData);
			}
       });
   };
 
   const setFormFieldsData = (formFieldsData) => {
       form.setFieldsValue(formFieldsData);
   };
 
   return (<Form form={form}>
        <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your school name!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your school email!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please input your school city!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please input your school state!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Please input your school country!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
        label="Zip-Code"
        name="zip_code"
        rules={[{ required: true, message: 'Please input your school zip-code!' }]}
        >
        <Input />
        </Form.Item>
        </Form>
    );
};
 
const mapDispatchToProps = dispatch => ({
   getSchoolViewData: details => dispatch(global.redux.action.schools.view(details))
});
 
export default connect(null, mapDispatchToProps)(SchoolView);