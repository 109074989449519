import React, { useState } from "react";
import { Switch } from "antd";
import "./express.css";
import continueArrow from "../../assets/img/arrow-right.svg";

function Plan(props) {
  const [planType, setPlanType] = useState(
    sessionStorage.getItem("planType") || "engagement"
  );

  const engagementPriceList = [
    {
      size: 20,
      price: 290,
    },
    {
      size: 25,
      price: 370,
    },
    {
      size: 35,
      price: 520,
    },
    {
      size: 45,
      price: 670,
    },
    {
      size: 55,
      price: 820,
    },
    {
      size: 65,
      price: 970,
    },
  ];

  const advantagePriceList = [
    {
      size: 20,
      price: 547,
    },
    {
      size: 25,
      price: 687,
    },
    {
      size: 35,
      price: 957,
    },
    {
      size: 45,
      price: 1237,
    },
    {
      size: 55,
      price: 1507,
    },
    {
      size: 65,
      price: 1787,
    },
  ];

  const handlePlanChange = (checked) => {
    const newPlan = checked ? "advantage" : "engagement";
    const plan = checked ? props?.plansList[0] : props?.plansList[1];

    setPlanType(newPlan);
    props.setPlan(plan);
    sessionStorage.setItem("planType", newPlan);
    sessionStorage.setItem("plan", JSON.stringify(plan));
    // console.log('plan', )
  };

  //   const planPriceList = {
  //     1: {
  //       20: 290,
  //       25: 370,
  //       35: 520,
  //       45: 670,
  //       55: 820,
  //       65: 970
  //     },
  //     0: {
  //       20: 547,
  //       25: 687,
  //       35: 957,
  //       45: 1237,
  //       55: 1507,
  //       65: 1787
  //     }
  //   }

  const isChecked = () => {
    const planType = sessionStorage.getItem("planType");
    if (planType === "advantage") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="w-[100%] text-center flex flex-col">
      <div className="w-[90%] mx-auto">
        <span className="text-[18px] font-bold text-center font-poppins text-[#0F1A24]">
          CHOOSE YOUR ANNUAL PLAN
        </span>
        <div className="my-7 flex items-center text-center text-sm font-bold font-poppins express-payment-method">
          <span
            className={`${
              planType === "engagement" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            }`}
          >
            {props?.plansList[1]?.name || "Engagement Plan"}
          </span>
          {isChecked() === true ? (
            <Switch
              className="mx-4"
              checked={true}
              onChange={handlePlanChange}
            />
          ) : (
            <Switch
              checked={false}
              onChange={handlePlanChange}
              className="mx-4"
            />
          )}
          <span
            className={`${
              planType === "advantage" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            }`}
          >
            {props?.plansList[0]?.name || "Advantage Plan"}
          </span>
        </div>
      </div>
      <div className="flex flex-col font-poppins text-[#0F1A24]">
        <span className="text-[18px] font-bold text-center ">
          Feedback + Mobile apps for Players
        </span>
        {planType === "advantage" && (
          <span className="text-[#A40E17] text-[18px] font-bold text-center mb-1">
            + Game Analytics
          </span>
        )}
        <span className="text-[12px] mb-7">
          {planType === "engagement"
            ? `Ideal for coaches striving to boost player engagement by bridging the
          communication divide!`
            : `The ultimate choice for coaches aiming to secure a comprehensive 
          Competitive Learning Advantage over their rivals!`}
        </span>
      </div>
      {planType === "engagement"
        ? engagementPriceList.map((item, index) => {
            const isSelected =
              JSON.parse(sessionStorage.getItem("selectedSize"))?.price ===
              item?.price;
            return (
              <div
                className={`flex items-center ${
                  index === engagementPriceList.length - 1 ? "mb-0" : "mb-4 "
                } justify-between p-3 rounded-[100px] ${
                  isSelected || item.price === props.selectedSize?.price
                    ? "bg-[#A40E17] text-[#FFFFFF]"
                    : "bg-[#EAEDF0] text-[#8698A9]"
                }  font-bold cursor-pointer`}
                onClick={() => {
                  props.setSelectedSize(item);
                  sessionStorage.setItem("selectedSize", JSON.stringify(item));
                }}
                key={index}
              >
                <span>
                  Varsity Roster Size{" "}
                  {`${item.size} ${item.size === 65 ? "+" : ""}`}
                </span>
                <span>${item.price}</span>
              </div>
            );
          })
        : advantagePriceList.map((item, index) => {
            const isSelected =
              JSON.parse(sessionStorage.getItem("selectedSize"))?.price ===
              item?.price;
            return (
              <div
                className={`flex items-center ${
                  index === advantagePriceList.length - 1 ? "mb-0" : "mb-4 "
                } justify-between p-3 rounded-[100px] ${
                  item.price === props.selectedSize?.price || isSelected
                    ? "bg-[#A40E17] text-[#FFFFFF]"
                    : "bg-[#EAEDF0] text-[#8698A9]"
                }  font-bold cursor-pointer`}
                onClick={() => {
                  props.setSelectedSize(item);
                  sessionStorage.setItem("selectedSize", JSON.stringify(item));
                }}
                key={index}
              >
                <span>
                  Varsity Roster Size{" "}
                  {`${item.size} ${item.size === 65 ? "+" : ""}`}
                </span>
                <span>${item.price}</span>
              </div>
            );
          })}
      <div className="mt-4 ">
        <button
          className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
          onClick={() => {
            if (props.page == 4) {
            } else {
              props.setPage(props.page + 1);
            }
          }}
          // disabled={page == 1 && regDetails.roles.length == 0}
        >
          <span>Continue</span>
          <img src={continueArrow} />
        </button>
      </div>
    </div>
  );
}

export default Plan;
