import { UserData } from "./user-data.jsx";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Form, Input, Button, Radio, Tooltip, Space, Modal } from "antd";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import { Collapse } from "antd";
import PlayerInviteFAQ from "./PlayerInviteFAQ";
import _ from "lodash";
import InvitePlayerModalContent from "./InvitePlayerModalContent.js";

const HomePageComponent = (props) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const url = `https://coach.wardbord.com/join-wardbord?school=${props?.coachProfile?.school?.id}`;
  const handlePrint = () => {
    setIsPrinting(true);

    setTimeout(() => {
      const printContents = document.getElementById("print-content").innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      setIsPrinting(false);
    }, 500);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => alert("URL copied to clipboard"))
      .catch(() => alert("Failed to copy URL"));
  };

  const render = () => {
    return (
      <div style={{ width: "100%", backgroundColor: "#fff" }}>
        <div
          id="print-content"
          className="flex flex-col"
          style={{
            width: "98%",
            backgroundColor: "#fff",
            marginTop: "2%",
            padding: "2%",
          }}
        >
          <div
            className="banner"
            style={{
              display: "flex",
              padding: "70px",
              margin: "30px 0 12px 0",
              backgroundColor: "hsla(240,83.3%,66.3%,0.15)",
              borderRadius: "1.5rem",
            }}
          >
            <div
              className="title"
              style={{
                width: "55%",
                marginTop: isPrinting ? "30px" : "3px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h3
                style={{
                  fontSize: "2rem",
                  lineHeight: "1.2",
                  fontWeight: "700",
                }}
              >
                Have your players add themselves using the following QR Code!
              </h3>
              <p
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  lineHeight: "1.6",
                  fontWeight: "400",
                  color: "hsla(252,13%,46%,1)",
                }}
              >
                Scan the QR Code! Complete the player intake form with your full
                name, cell number, email, and create your password. <br />
                When your done, you’re ready to go download the “Wardbord App”
                from the app store! (Available in both Apple and Android stores)
              </p>
              <div className="actions" style={{ marginTop: "2%" }}>
                {!isPrinting && (
                  <>
                    <button
                      onClick={copyToClipboard}
                      style={{
                        border: "1px solid hsla(252, 13%, 46%, 0.25)",
                        padding: "5px 30px",
                        marginRight: "10px",
                        fontSize: "12px",
                        borderRadius: "20px",
                      }}
                    >
                      Copy invite Link
                    </button>
                    <button
                      onClick={handlePrint}
                      style={{
                        backgroundColor: "#625df5",
                        color: "#fff",
                        padding: "5px 30px",
                        marginRight: "10px",
                        fontSize: "12px",
                        borderRadius: "20px",
                      }}
                    >
                      Print QR Code
                    </button>
                  </>
                )}{" "}
              </div>
            </div>
            <div className="qr" style={{ width: "45%", margin: "0 auto" }}>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 255,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={url}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => setshowModal(true)}
            className=" text-[#625df5] underline text-center self-end font-bold mr-4 focus:outline-none"
          >
            Manually add player(s)
          </button>
        </div>

        {showModal === true && (
          <Modal
            closable={true}
            open={showModal}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => setshowModal(false)}
            onCancel={() => setshowModal(false)}
            width={500}
            className="w-[485px] rounded-[8px]"
          >
            <InvitePlayerModalContent
              onCancel={() => setshowModal(false)}
              gridironDetails={props.gridironDetails}
            />
          </Modal>
        )}
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({
  coachProfile: state?.app?.account?.profile?.data?.details,
  gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
