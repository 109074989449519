import React, { useEffect, useState } from 'react'

import {
  Form,
  Button,
  InputNumber,
  Input,
  TimePicker,
  notification,
  Select
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import './plays.css'

import momentTimezone from 'moment-timezone'
import { useNavigate } from 'react-router'
const { Option } = Select
const axios = require('axios').default
const PlaysCreateComponent = props => {
  const [schoolsList, setSchoolsList] = useState([])
  const [schoolsListMeta, setSchoolsListMeta] = useState({})
  const [timeZone, setTimeZone] = useState({})
  const [form] = Form.useForm()
  let history = useNavigate()

  useEffect(() => {
    loadSchoolsList()
    getCurrentLocation()
  }, [])
  const getCurrentLocation = async (limit = 1000, page = 1) => {
    const { data, status } = await axios.get('https://ipapi.co/json')
    console.log('data, status', data, status)
    setTimeZone(data)
  }
  const loadSchoolsList = (limit = 1000, page = 1) => {
    // setSchoolsListLoading(true)
    props.schoolsList({
      callback: (res, data) => {
        if (res === true) {
          setSchoolsListMeta(_.get(data, 'data.meta', {}))
          setSchoolsList(_.get(data, 'data.items', []))
        }
        // setSchoolsListLoading(false)
      }
    })
  }
  const handleSubmit = e => {
    const params = new URLSearchParams(window.location.search)
    let game_id = params.get('id')
    let values = { ...e }
    console.log('The progress is correct', values)
    values.start_time = addTimeZone(_.get(e, 'start_time'))
    values.end_time = addTimeZone(_.get(e, 'end_time'))
    values.game = game_id
    // console.log('details', details);
    props.playsCreate({
      body: values,
      callback: (res, responseData) => {
        if (res == true) {
          notification.success({
            message: 'Success',
            description: _.get(responseData, 'message')
          })
          history(`/plays-list?id=${game_id}`)
        } 
      }
    })
  }

  const timeChange = (event, fieldName) => {
    console.log('event,fieldName', event, fieldName)
    form.setFieldsValue({ [`${fieldName}`]: event })
  }

  const dateTimezone = (date, time) => {
    const timeConvert = momentTimezone(`${date}T${time}`)
      .tz(_.get(timeZone, 'timezone'))
      .format('hh:mm a')
    var dt = moment(timeConvert, ['hh:mm a']).format('HH:mm')
    let date1 = momentTimezone(`${date}T${dt}`)
      .tz(_.get(timeZone, 'timezone'))
      .toISOString()
    return date1
  }

  const addTimeZone = time => {
    var dt = moment(time, ['hh:mm a']).format('HH:mm')
    // var b = moment.utc("2013-11-18 11:55").tz("America/Toronto");

    const date = momentTimezone()
      .tz(_.get(timeZone, 'timezone'))
      .format('YYYY-MM-DD')
    let date1 = moment.tz(`${date} ${dt}`, _.get(timeZone, 'timezone')).format()
    let final_time = _.chain(date1)
      .split('T')
      .last()
      .value()

    return final_time
  }

  const render = () => {
    const params = new URLSearchParams(window.location.search)
    let game_id = params.get('id')
    return (
      <div className='CreatePlaysForm'>
        <div className='container'>
          <div className='PlayerprimaryCard'>
            <Form name='basicform' onFinish={handleSubmit} form={form}>
              <Form.Item
                className='col-12 col-md-6'
                label='Attacking Team'
                name='attacking_team'
                rules={[
                  { required: true, message: 'Please enter attacking team' },
                  () => ({
                    validator (_, value) {
                      if (!value) {
                        return Promise.reject()
                      }
                      if (value == form.getFieldValue('defending_team')) {
                        return Promise.reject('Please select different team.')
                      }

                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <Select>
                  {schoolsList.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Defending Team'
                name='defending_team'
                rules={[
                  { required: true, message: 'Please enter defending team' },
                  () => ({
                    validator (_, value) {
                      if (!value) {
                        return Promise.reject()
                      }
                      if (value == form.getFieldValue('attacking_team')) {
                        return Promise.reject('Please select different team.')
                      }

                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <Select>
                  {schoolsList.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item
                    className="col-12 col-md-6"
                    label="Game"
                    name="game"
                    rules={[{ required: true, message: 'Please enter game' }]}
                    >
                        <InputNumber style={{width: 150}} />
                    </Form.Item> */}
              <Form.Item
                className='col-12 col-md-6'
                label='Number'
                name='number'
                rules={[{ required: true, message: 'Please enter number' }]}
              >
                <InputNumber style={{ width: 150 }} />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Type'
                name='type'
                rules={[{ required: true, message: 'Please enter type' }]}
              >
                <Select>
                  {[
                    { name: 'Friendlies', value: 'friendlies' },
                    { name: 'Group Stage', value: 'group stage' },
                    { name: 'Play Offs', value: 'play offs' }
                  ].map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Start Time'
                name='start_time'
                rules={[{ required: true, message: 'Please enter start time' }]}
              >
                <TimePicker
                  format='hh:mm A'
                  onSelect={e => timeChange(e, 'start_time')}
                  onChange={e => timeChange(e, 'start_time')}
                />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='End Time'
                name='end_time'
                rules={[{ required: true, message: 'Please enter end time' }]}
              >
                <TimePicker
                  format='hh:mm A'
                  onSelect={e => timeChange(e, 'end_time')}
                  onChange={e => timeChange(e, 'end_time')}
                />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Home Team Score'
                name='home_team_score'
                rules={[
                  { required: true, message: 'Please enter home team score' }
                ]}
              >
                <InputNumber style={{ width: 150 }} />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Away Team Score'
                name='away_team_score'
                rules={[
                  { required: true, message: 'Please enter away team score' }
                ]}
              >
                <InputNumber style={{ width: 150 }} />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Duration(minutes)'
                name='duration'
                rules={[{ required: true, message: 'Please enter duration' }]}
              >
                <InputNumber style={{ width: 150 }} />
              </Form.Item>
              <Form.Item className='col-12 col-md-12 text-left'>
                <Button className='mr-2' type='success' htmlType='submit'>
                  Submit
                </Button>
                <Button
                  type='cancel'
                  htmlType='reset'
                  onClick={() => {
                    history(`/plays-list?id=${game_id}`)
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
  return render()
}

const mapDispatchToProps = dispatch => ({
  playsCreate: details => dispatch(global.redux.action.plays.create(details)),
  getCurrentLocation: details =>
    dispatch(global.redux.action.coaches.getCurrentLocation(details)),
  schoolsList: details => dispatch(global.redux.action.schools.list(details))
})

export default connect(null, mapDispatchToProps)(PlaysCreateComponent)
