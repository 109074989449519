import actionType from "../actionTypes";
import _ from "lodash";
const reducerName = actionType.reduxUpdateGamesState;

export default {
  create: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/create`,
        },
        actionType: actionType.games.create,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  update: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/update`,
        },
        actionType: actionType.games.update,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  changeStatus: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/change-status`,
        },
        actionType: actionType.games.changeStatus,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  list: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/list`,
        },
        actionType: actionType.games.list,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  indicator: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/indicator`,
        },
        actionType: actionType.games.indicator,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  indicatorMax: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/indicator-max`,
        },
        actionType: actionType.games.indicator,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  analytics: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/analytics`,
        },
        actionType: actionType.games.analytics,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  view: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/view`,
        },
        actionType: actionType.games.view,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  updatesponsorlogo: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/update-sponsor-logo`,
        },
        actionType: actionType.games.updateSponsorLogo,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  updateopponentlogo: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/update-opponent-logo`,
        },
        actionType: actionType.games.updateOpponentLogo,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  createStats: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/custom-stat/create`,
        },
        actionType: actionType.games.createStats,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  getStats: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/custom-stat/find-by-school/${_.get(componentRequest, "params.school")}`,
        },
        actionType: actionType.games.getStats,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  updateStats: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/custom-stat/update/${_.get(componentRequest, "params.id")}`,
        },
        actionType: actionType.games.updateStats,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  deleteStats: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "delete",
          url: `/warboard/custom-stat/delete/${_.get(componentRequest, "params.id")}`,
        },
        actionType: actionType.games.deleteStats,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  uploadScoutingReport: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/games/upload-scouting-report`,
        },
        actionType: actionType.games.uploadScoutingReport,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  generateSignedUrl: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/generate-signed-url`,
        },
        actionType: actionType.games.generateSignedUrl,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  fetchScoutingReport: (componentRequest) => {
    return (dispatch) => {
      const gameId = _.get(componentRequest, "params.game");
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/games/fetch-scouting-report/${gameId}`,
        },
        actionType: actionType.games.fetchScoutingReport,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

};
