export const scoringColumnsPrint = [
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          #
        </div>
      ),
      children: [
        {
          title: "Jersey",
          dataIndex: "kit_number",
          width: "1",
          fixed: "left",
          align: "center",
          render: (text, row) => <div>{row.kit_number || 0}</div>,
        },
      ],
    },
    // {
    //   title: (
    //     <div
    //       className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
    //       style={{ borderRight: "1px solid #F3F5F7" }}
    //     >
    //       First Name
    //     </div>
    //   ),
    //   children: [
    //     {
    //       title: "First Name",
    //       dataIndex: "first_name",
    //       excludeFromExport: true,
    //       fixed: "left",
    //       width: "2",
    //       align: "center",
    //       render: (text) => <div>{text || 0}</div>,
    //     },
    //   ],
    // },
    // {
    //   title: (
    //     <div
    //       className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
    //       style={{ borderRight: "1px solid #F3F5F7" }}
    //     >
    //       Last Name
    //     </div>
    //   ),
    //   children: [
    //     {
    //       title: "Last Name",
    //       dataIndex: "last_name",
    //       excludeFromExport: true,
    //       fixed: "left",
    //       width: "2",
    //       align: "center",
    //       render: (text) => <div>{text || 0}</div>,
    //     },
    //   ],
    // },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
         Execution Score
        </div>
      ),
      children: [
        {
          title: "Execution Score",
          dataIndex: "total_assignment_executed",
          excludeFromExport: true,
          fixed: "left",
          width: "2",
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Effort Score
        </div>
      ),
      children: [
        {
          title: "Effort Score",
          dataIndex: "total_great_effort",
          excludeFromExport: true,
          fixed: "left",
          width: "2",
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Grade
        </div>
      ),
      children: [
        {
          title: "Grade",
          dataIndex: "total_great_effort",
          excludeFromExport: true,
          fixed: "left",
          width: "2",
          align: "center",
          render: (text, row) => (
            <div>
              {(Number(text) + Number(row?.total_assignment_executed)) / 2?.toFixed(2) || 0}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          TouchDowns
        </div>
      ),
      children: [
        {
          title: "Rushing TD Num",
          dataIndex: "total_rbtd",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Receiving TD Num",
          dataIndex: "total_wrtd",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Fumble Returned TD Num",
          dataIndex: "total_fum_ret_td",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "INT Returned TD Num",
          dataIndex: "total_int_ret_td",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Punt Returned TD Num",
          dataIndex: "total_punt_return_td",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Kickoff Returned TD Num",
          dataIndex: "total_kickoff_return_td",
          width: "1",
  
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Total TD Num",
          dataIndex: "calculate",
          width: "1",
  
          align: "center",
          render: (text, row) => (
            <div>
              {Number(row.total_rbtd) +
                Number(row.total_wrtd) +
                Number(row.total_fum_ret_td) +
                Number(row.total_int_ret_td) +
                Number(row.total_punt_return_td) +
                Number(row.total_kickoff_return_td) || 0}
            </div>
          ),
        },
        {
          title: `TD Points`,
          dataIndex: "calculate",
          width: "1",
          excludeFromExport: true,
          className: "p-0",
          align: "center",
          render: (text, row) => (
            <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">
              {(Number(row.total_rbtd) +
                Number(row.total_wrtd) +
                Number(row.total_fum_ret_td) +
                Number(row.total_int_ret_td) +
                Number(row.total_punt_return_td) +
                Number(row.total_kickoff_return_td)) *
                6 || 0}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          PAT Kicks
        </div>
      ),
      children: [
        {
          title: "PAT Kicking Made",
          dataIndex: "total_pat",
  
          align: "center",
          render: (text, row) => <div>{Number(text) || 0}</div>,
        },
        {
          title: "PAT Kicking Att",
          dataIndex: "total_positive_impact",
          align: "center",
          render: (text, row) => (
            <div>
              {Number(row.total_pat) +
                Number(row.total_missed_pat) +
                Number(row.total_block_pat) || 0}
            </div>
          ),
        },
        {
          title: "PAT Kicking Points",
          highlight: true,
          dataIndex: "total_pat",
          className: "p-0",
          align: "center",
          render: (text, row) => (
            <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">
              {Number(text) * 1 || 0}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Conversions
        </div>
      ),
      children: [
        {
          title: "2 Pt Conversions",
          dataIndex: "total_two_part_conversion",
          exportName: "Rushing Num",
          align: "center",
          render: (text) => <div>{Number(text) || 0}</div>,
        },
        {
          title: "Total Conversion Points",
          highlight: true,
          dataIndex: "total_two_part_conversion",
          exportName: "RushingYards",
          className: "p-0",
          align: "center",
          render: (text) => (
            <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">
              {Number(text) * 2 || 0}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Field Goals
        </div>
      ),
      children: [
        {
          title: "FG Made",
          dataIndex: "total_field_goals",
          align: "center",
          exportName: "Receiving Num",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "FG Attempted",
          dataIndex: "calculate",
          align: "center",
          exportName: "Receiving Num",
          render: (text, row) => (
            <div>
              {Number(row.total_field_goals) +
                Number(row.total_block_field_goals) +
                Number(row.total_missed_field_goals) || 0}
            </div>
          ),
        },
        {
          title: "FG Long",
          dataIndex: "max_field_goal_distance",
          exportName: "ReceivingYards",
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "FG Points",
          highlight: true,
          dataIndex: "calculate",
          excludeFromExport: true,
          align: "center",
          className: "p-0",
          render: (text, row) => (
            <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">
              {Number(row?.total_field_goals) * 3 || 0}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Sfts
        </div>
      ),
      children: [
        {
          title: "Safeties",
          dataIndex: "total_safety",
          exportName: "PassingComp",
          align: "center",
          render: (text) => <div>{text || 0}</div>,
        },
        {
          title: "Sfts Points",
          highlight: true,
          dataIndex: "total_safety",
          excludeFromExport: true,
          exportName: "PassingComp",
          className: "p-0",
          align: "center",
          render: (text) => <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">{text * 2 || 0}</div>,
        },
      ],
    },
    {
      title: (
        <div
          className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
          style={{ borderRight: "1px solid #F3F5F7" }}
        >
          Pts
        </div>
      ),
      children: [
        {
          title: "Total Points",
          highlight: true,
          dataIndex: "calculate",
          exportName: "OffensiveFumbles",
          align: "center",
          className: "p-0",
          render: (text, row) => (
            <div className="bg-[#eaedf0] w-full h-full flex items-center justify-center">
              {(Number(row.total_rbtd) +
                Number(row.total_wrtd) +
                Number(row.total_fum_ret_td) +
                Number(row.total_int_ret_td) +
                Number(row.total_punt_return_td) +
                Number(row.total_kickoff_return_td)) *
                6 +
                Number(row.total_pat) * 1 +
                Number(row.total_safety) * 2 +
                Number(row.total_two_part_conversion) * 2 +
                Number(row.total_field_goals) * 3 || "0"}
            </div>
          ),
        },
      ],
    },
  ];