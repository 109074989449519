export const player_positions = {
    defense: [
      {
        name: "Defensive Unit",
        play_type: "d",
        variables: [
          { name: "Defensive Line", code: "DL" },
          { name: "Linebackers", code: "LB" },
          { name: "Corners", code: "C" },
          { name: "Safeties", code: "S" },
        ],
      },
    ],
    offense: [
      {
        name: "Offensive Unit",
        play_type: "o",
        variables: [
          { name: "Quarterback", code: "QB" },
          { name: "Offensive lineman", code: "OL" },
          { name: "Running Backs", code: "RB" },
          { name: "Tight end", code: "TE" },
          { name: "Wide Receiver", code: "WR" },
        ],
      },
    ],
    special_teams: [
      {
        name: "Special Teams",
        play_type: "k",
        variables: [
          { name: "Kicker", code: "K" },
          { name: "Special Teams", code: "ST" }
        ]
      }
    ]
  };
  