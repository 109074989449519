import React from "react";
import addplayer from '../../../assets/img/addplayer.png'



const AddPlayer = (props) => {
    return(
        <div className="addplayerdiv" onClick={props.onClick}>
            <img src={addplayer} />
            <span>Add Player(s)</span>
		</div>
    )
}


export default AddPlayer;