import {
  Table,
  Card,
  List,
  Result,
  Modal,
  Button,
  Avatar,
  Space,
  Skeleton,
  Select,
} from "antd";
import DepthChart from "./DepthChart";
import React, { useEffect, useState } from "react";
import "./home-page.css";
import MyDepthChartMain from "./MyDepthChartMain";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import _, { filter, isEmpty } from "lodash";

import {
  MoreOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const capitalizeString = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const MyDepthChart = ({
  coachDepthChart,
  coachDepthChartLoading,
  depth_chart_options = [],
  positions,
  createDepthChart,
  handleAddPlayerDepthChart,
  addToDepthChart,
  handleUpdateDepthChart,
  coachDepthChartId,
  setCoachDepthChartId,
  coachProfile,
  athletesListLoading,
  athletesList,
  allAthletesList,
  loadAthletesList,
  handleDeleteDepthChart,
  coachesListApi,
  belongsToEveryone,
}) => {
  useEffect(() => {
    loadAthletesList(1000, 1, positions);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDepthChart, setSelectedDepthChart] = useState(
    depth_chart_options[0]
  );
  const [coachesList, setCoachesList] = useState([]);

  const loadCoachesList = (limit = 90, page = 1) => {
    coachesListApi({
      params: { limit, page, school: coachProfile?.school?.id },
      callback: (res, data) => {
        if (res) {
          setCoachesList(data.data.items);
        }
      },
    });
  };

  useEffect(() => {
    loadCoachesList();
  }, []);
  const showModal = (id = null, name = null, players = [], positions = "") => {
    loadAthletesList(1000, 1, positions);
    setCoachDepthChartId(id);
    if (name) {
      handleChartTypeChange(name);
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setCoachDepthChartId(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCoachDepthChartId(null);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    // addToDepthChart(value)
  };
  const handleCreateDepthChart = () => {
    let odk = "o";
    if (selectedDepthChart?.positions?.includes("ST")) {
      odk = "k";
    }
    if (selectedDepthChart?.positions?.includes("LB")) {
      odk = "d";
    }
    createDepthChart(selectedDepthChart.name, selectedDepthChart.code, odk);
    handleCancel();
  };

  const handleChartTypeChange = (value) => {
    const result = depth_chart_options?.filter(({ code, name }) => {
      console.log(
        "`${code} (${name})`",
        `${code} (${name})`.toLowerCase(),
        "    ff",
        value.toLowerCase()
      );
      return `${code} (${name})`.toLowerCase() == value.toLowerCase();
    });
    setSelectedDepthChart(result[0]);
    if (result[0]) loadAthletesList(1000, 1, result[0]?.positions);
  };
  //start
  const categoryMap = {
    Defensive: [],
    Offensive: [],
    "Special Teams": [],
  };

  depth_chart_options?.forEach(({ positions, name, code }) => {
    let category = "";
    if (positions.startsWith("LB")) {
      category = "Defensive";
    } else if (positions.startsWith("OL")) {
      category = "Offensive";
    } else if (positions.startsWith("K")) {
      category = "Special Teams";
    }
    if (!coachDepthChart?.own?.some((chart) => chart.name === name)) {
      // Check if the name is not present in coachDepthChart
      categoryMap[category]?.push({
        value: `${code} (${capitalizeString(`${name}`)})`,
        label: (
          <span className="font-inter">
            <span style={{ fontWeight: "bold" }}>{code}</span> (
            {capitalizeString(`${name}`)})
          </span>
        ),
      });
    }
  });

  const options = Object.keys(categoryMap)
    .filter((category) => categoryMap[category].length > 0)
    .map((category, index) => ({
      key: `category_${index}`,
      label: (
        <span className="font-inter font-bold text-[#000000] text-[14px]">
          {category} Charts
        </span>
      ),
      options: categoryMap[category],
      groupHeading: (base) => ({
        ...base,
        fontSize: "16px",
        color: "#000000",
      }),
    }));

  //stop
  return (
    <div className="depth-charts px-4">
      {coachDepthChartLoading ? (
        ""
      ) : (
        <div className="row">
          <div style={{ marginLeft: "auto" }} className="col-md-4 ">
            <Button
              type="primary"
              className="m-1 text-white py-5  text-[20px]  superlarge-btn"
              onClick={() => {
                showModal(null, null, []);
              }}
            >
              <PlusOutlined style={{ position: "relative", bottom: "2px" }} />{" "}
              Create A New Depth Chart
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        {coachDepthChartLoading ? (
          <>
            <div className="col-md-4 mt-3 ">
              {" "}
              <Skeleton active />
            </div>
            <div className="col-md-4 mt-3">
              {" "}
              <Skeleton active />
            </div>
            <div className="col-md-4 mt-3">
              {" "}
              <Skeleton active />
            </div>
          </>
        ) : (
          coachDepthChart?.own?.map((items, index) => {
            return (
              <MyDepthChartMain
                coachesList={coachesList}
                key={index}
                handleUpdateDepthChart={handleUpdateDepthChart}
                showPlayersModal={showModal}
                athletesListLoading={athletesListLoading}
                athletesList={athletesList}
                loadAthletesList={loadAthletesList}
                allAthletesList={allAthletesList}
                setCoachDepthChartId={setCoachDepthChartId}
                data={items}
                handleDeleteDepthChart={handleDeleteDepthChart}
              />
            );
          })
        )}
      </div>
      <br /> <hr />
      <h4>Shared Depth Chart(s)</h4>
      {coachDepthChart?.shared?.map((items, index) => {
        return (
          <MyDepthChartMain
            key={index}
            isShared={true}
            coachesList={coachesList}
            handleUpdateDepthChart={handleUpdateDepthChart}
            showPlayersModal={showModal}
            athletesListLoading={athletesListLoading}
            athletesList={athletesList}
            loadAthletesList={loadAthletesList}
            allAthletesList={allAthletesList}
            setCoachDepthChartId={setCoachDepthChartId}
            data={items}
            handleDeleteDepthChart={handleDeleteDepthChart}
          />
        );
      })}
      <Modal
        title={null}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="players-depth-chart">
          <h4>Create a New Depth Chart</h4>
          <label>Depth Chart Name</label>

          <Select
            styles={{
              option: (provided) => ({ ...provided, whiteSpace: "pre" }),
            }}
            options={options}
            onChange={handleChartTypeChange}
            formatgrouplabel={(data) => (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  marginTop: "10px",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                {data.label}
              </span>
            )}
            isSearchable={false}
          />
          <hr />
          <Button onClick={handleCreateDepthChart}>Create</Button>
        </div>
      </Modal>
    </div>
  );
};

export default MyDepthChart;
