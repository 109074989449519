import React, { useState, useEffect } from "react";
import { Select } from "antd";
import greenCheck from "../../../assets/img/Check.svg";
const { Option } = Select;

const PricePlanCard = ({ plan, index, setSelectedPlan, setPaymentModal, schoolSubscriptionDetails}) => {
  const [roasterSize, setRoasterSize] = useState(25);
  const [price, setPrice] = useState(null);
  const [seeFeatures, setSeeFeatures] = useState(false);

  useEffect(() => {
    if (!price) {
      setPrice(calculatePrice(plan?.id - 1, 20));
    }
  }, []);

  const teamSizes = [20, 25, 35, 45, 55, 65];

  const planPriceList = {
    0: {},
    1: {
      20: 290,
      25: 370,
      35: 520,
      45: 670,
      55: 820,
      65: 970,
    },
    2: {
      20: 547,
      25: 687,
      35: 957,
      45: 1237,
      55: 1507,
      65: 1787,
    },
  };

  const calculatePrice = (selectedPlan, selectedSize) => {
    console.log({ selectedPlan, selectedSize }, planPriceList[selectedPlan]);
    const path = planPriceList[selectedPlan] || {};
    return path[selectedSize] || 0;
  };

  const handleSizeChange = (value) => {
    setRoasterSize(value);
    setPrice(calculatePrice(plan?.id - 1, value));
  };



  return (
    <div
      className="flex flex-col col-span-1 rounded-[10px] relative cursor-pointer"
      style={{
        border: plan?.currentPlan ? "2px solid #1A91FF" : "2px solid #EAEDF0",
      }}
      key={index}
    >
      <div
        className="flex justify-between pb-2"
        style={{
          borderBottom: plan?.currentPlan
            ? "2px solid #1A91FF"
            : "2px solid #EAEDF0",
        }}
      >
        <div className="p-2">
          <span className="text-sm font-bold font-poppins">{plan?.name}</span>
        </div>
        {plan?.name === schoolSubscriptionDetails?.plan?.name  && (
          <span className="flex w-[102px] h-[27px] items-center justify-center text-xs  font-bold bg-[#1A91FF] text-[#ffffff] rounded-bl-[8px]">
            <span>Current Plan</span>
          </span>
        )}
      </div>
      <div className="p-3">
        <div className="flex items-center justify-between">
          <div className="text-base w-[65%] flex items-center font-bold font-poppins">
            {plan?.numberOfPlayers ? (
              <span>{roasterSize}</span>
            ) : (
              <div className="varsity-player">
                <Select defaultValue={roasterSize} onChange={handleSizeChange}>
                  {teamSizes.map((num) => (
                    <Option value={num} key={num} className="font-bold">
                      {num === 65 ? "65+" : num}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            <span className="ml-[3%] text-[10px] font-normal">
              Varsity Players{" "}
            </span>
          </div>
          <span className="text-[20px] font-bold">
            <sup className="text-[10px] font-normal">US$</sup>
            {price || "Free"}
            <span className="text-[10px] font-normal">/Season</span>
          </span>
        </div>
        <div className="flex flex-col justify-between mt-3 italic font-inter text-sm font-normal">
          <span className="leading-[150%]">{plan?.description}</span>
          {plan?.expirationDate ? (
            <span className="mt-4">will expire on {plan?.expirationDate}</span>
          ) : (
            <>
              {price ? (
                <button
                  className="text-[#ffffff] bg-[#1A91FF] font-bold px-4 py-2 mt-4 xl:w-[80%] 2xl:w-[70%] rounded-md"
                  onClick={() => {
                    setSelectedPlan({ ...plan, price });
                    setPaymentModal(true);
                  }}
                >
                  Upgrade subscription
                </button>
              ) : (
                ""
              )}{" "}
            </>
          )}
        </div>
        {seeFeatures && (
          <div className="flex flex-col flex-grow mb-[8%]">
            {plan?.features?.map((feature, index) => (
              <div className="flex items-center mt-4" key={index}>
                <img src={greenCheck} alt="Green Check" />
                <span className="ml-[1%]">{feature}</span>
              </div>
            ))}
          </div>
        )}

        <button
          className={`underline text-[#1A91FF] focus:outline-none text-sm  mt-4 ${
            seeFeatures && "absolute bottom-[0.5%] left-[2%]"
          }`}
          onClick={() => setSeeFeatures(!seeFeatures)}
        >
          {seeFeatures ? "See Less" : "See Features"}
        </button>
      </div>
    </div>
  );
};

export default PricePlanCard;
