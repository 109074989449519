import React, { useEffect, useState } from 'react'

import {
  Form,
  Button,
  Input,
  InputNumber,
  notification,
  Select,
  Tag,
  Modal,
  TimePicker
} from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'
import './mvps.css'
import TextArea from 'antd/lib/input/TextArea'
import { useNavigate } from 'react-router'

const { Option } = Select
const axios = require('axios').default

const MvpsCreate = props => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState(false)

  const [athletesList, setAthletesList] = useState([])
  const [titleList, setTitleList] = useState([
    { value: 1, name: 'Defensive Lineman of the week' },
    { value: 2, name: 'Offensive Lineman of the week' },
    { value: 3, name: 'Hit Man of the Week' },
    { value: 4, name: 'Special Teams Player of the week' },
    { value: 5, name: 'Defensive Skills Player of the week' },
    { value: 6, name: 'Offensive Skills Player of the week' }
  ])
  const [athletesListMeta, setAthletesListMeta] = useState({})

  const [seasonsList, setSeasonsList] = useState([])
  const [seasonsListMeta, setSeasonsListMeta] = useState({})
  const [weekList, setWeekList] = useState([
    { name: '0', value: '0' },
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' }
  ])

  let history = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (_.get(props, 'userProfile.school')) {
      loadList()
    }
    loadSeasonsList()
  }, [])

  const loadList = (limit = 1000, page = 1) => {
    props.list({
      params: {
        limit,
        page,
        expand: 'athlete',
        athlete_profile_school: _.get(props, 'userProfile.school.id')
      },
      callback: (res, data) => {
        if (res == true && _.size(_.get(data, 'data.items', [])) > 0) {
          setAthletesList(_.get(data, 'data.items', []))
          setAthletesListMeta(_.get(data, 'data.meta', {}))
        }
      }
    })
  }

  const loadSeasonsList = (limit = 1000, page = 1) => {
    props.seasonsList({
      callback: (res, data) => {
        if (res === true) {
          setSeasonsListMeta(_.get(data, 'data.meta', {}))
          setSeasonsList(_.get(data, 'data.items', []))
        }
      }
    })
  }

  const handleSubmit = e => {
    const params = new URLSearchParams(window.location.search)
    let details = {
      athlete: _.get(e, 'athlete'),
      coach: _.get(e, 'coach'),
      title: _.get(e, 'title'),
      description: _.get(e, 'description'),
      season: params.get('season'),
      week: _.get(e, 'week'),
      game: params.get('game')
    } 
    setButtonLoading(true)
    props.mvpsCreate({
      body: details,
      callback: (res, responseData) => {
        setButtonLoading(false)
        if (res == true) {
          notification.success({
            message: 'Success',
            description: _.get(responseData, 'message')
          })
          history('/wardbord')
        }
      }
    })
  }

  const onSearch = value => {
    console.log('search:', value)
  }

  const render = () => {
    return (
      <div className='CreateMvpsForm'>
        <div className='container'>
          <div className='PlayerprimaryCard'>
            <Form
              name='basic'
              initialValues={{ remember: true }}
              autoComplete='off'
              layout='vertical'
              className='row'
              onFinish={handleSubmit}
              form={form}
            >
              <Form.Item
                className='col-12 col-md-6'
                label='Title'
                name='title'
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Select
                  style={{ width: 250 }}
                  showSearch={true}
                  onSearch={onSearch}
                  optionFilterProp='children'
                >
                  {titleList.map((item, index) => {
                    return (
                      <Option key={index} value={_.get(item, 'value')}>
                        {_.get(item, 'name')}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Athlete'
                name='athlete'
                rules={[{ required: true, message: 'Please enter athlete' }]}
              >
                <Select
                  style={{ width: 250 }}
                  showSearch={true}
                  onSearch={onSearch}
                  optionFilterProp='children'
                >
                  {athletesList.map((item, index) => {
                    return (
                      <Option key={index} value={_.get(item, 'athlete.id')}>
                        {_.get(item, 'athlete.first_name')}{' '}
                        {_.get(item, 'athlete.last_name')}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Description'
                name='description'
                rules={[
                  { required: true, message: 'Please enter description' }
                ]}
              >
                <TextArea style={{ width: 250 }} />
              </Form.Item>
              <Form.Item
                className='col-12 col-md-6'
                label='Week'
                name='week'
                rules={[{ required: true, message: 'Please enter week' }]}
              >
                <Select
                  style={{ width: 250 }}
                  showSearch={true}
                  onSearch={onSearch}
                  optionFilterProp='children'
                >
                  {weekList.map((item, index) => {
                    return (
                      <Option key={index} value={_.get(item, 'value')}>
                        {_.get(item, 'name')}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item className='col-12 col-md-12 text-right'>
                <Button
                  loading={buttonLoading}
                  className='mr-2'
                  type='success'
                  htmlType='submit'
                >
                  Submit
                </Button>
                <Button
                  disabled={buttonLoading}
                  type='cancel'
                  htmlType='reset'
                  onClick={() => history('/wardbord')}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = state => { 
  return { userProfile: _.get(state, 'app.account.profile.data.details') }
}

const mapDispatchToProps = dispatch => ({
  mvpsCreate: details => dispatch(global.redux.action.mvps.create(details)),
  list: details => dispatch(global.redux.action.athletes.infoList(details)),
  seasonsList: details => dispatch(global.redux.action.seasons.list(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(MvpsCreate)
