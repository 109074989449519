import React, { useState, useEffect } from "react";
import { Modal, Input, Tooltip, Spin } from "antd";
import ButtonUI from "../../../components/ButtonUI";
import { LoadingOutlined } from "@ant-design/icons";

function WeeklyAward({
  awardTitles,
  setAwardTitles,
  handleUpdateWeeklyAwardTitle,
  loading,
  editMode,
  setEditMode,
}) {
  const [initialAwardTitles, setInitialAwardTitles] = useState(awardTitles);

  useEffect(() => {
    if (!editMode) {
      setInitialAwardTitles(awardTitles);
    }
  }, [editMode, awardTitles]);

  const handleInputChange = (key, event) => {
    setAwardTitles({
      ...awardTitles,
      [key]: event.target.value,
    });
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase()) || '';
  };

  return (
    <div className="flex flex-col gap-6 mt-4 p-4 bg-white rounded-lg shadow-md">
      {!editMode && (
        <span
          className="self-end cursor-pointer text-[#1A91FF] text-[18px] 2xl:text-[1.25vw] font-inter font-bold"
          onClick={() => setEditMode(true)}
        >
          Edit
        </span>
      )}
      {Object.entries(awardTitles).map(([key, value]) => {
        return (
          <Tooltip title={`Edit ${key.replace(/_/g, " ")}`} key={key}>
            <Input
              value={capitalizeWords(value)}
              size="large"
              onChange={(event) => handleInputChange(key, event)}
              className=" focus:border-[#1A91FF] hover:border-[#1A91FF]"
              readOnly={!editMode}
            />
          </Tooltip>
        );
      })}

      {editMode && (
        <div className="mt-4">
          <div>
            <ButtonUI
              text="Discard Changes"
              textColor="#000000"
              background="transparent"
              buttonFunction={() => {
                setEditMode(false);
                setAwardTitles(initialAwardTitles);
              }}
              type={"button"}
            />
          </div>
          <div className="mt-2">
            <ButtonUI
              text={loading ? "Saving..." : "Save Changes"}
              background={"#1A91FF"}
              buttonFunction={() => {
                handleUpdateWeeklyAwardTitle();
              }}
              disabled={loading}
              type={"submit"}
              loading={loading}
            />
          </div>
        </div>
      )}
      {/* <div className="flex justify-center items-center mt-4">
        <ButtonUI
          text={loading ? "Saving..." : "Edit"}
          background={"#1A91FF"}
          buttonFunction={handleUpdateWeeklyAwardTitle}
          disabled={loading}
        />
        {loading && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24, marginLeft: 10 }} spin />} />
        )}
      </div> */}
    </div>
  );
}

export default WeeklyAward;
