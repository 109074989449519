import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  InputNumber,
  Select,
  Switch,
  Checkbox,
  Modal
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import _ from 'lodash';
import "./home-page.css";
// import UserImg from "../../../assets/img/Profile-Pic.png";
import footBall from "../../../assets/img/football.png";
import smileyDefault from "../../../assets/img/smiley-default.png";
import smileyactive from "../../../assets/img/smiley-active.png";
const { Option } = Select;
const { TextArea } = Input;
class CardModal extends React.Component {
    // handleOk (...)
    // handleCancel (...)
   state = {
   
    disabled1: true,
     showModal: true,
  };
   constructor(props) {
    super(props);
    this.state = {value: '', disabled: true,};
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(value) {
  this.setState({value: value});
}
 CardActive = () => {
    this.setState({
      disabled1: !this.state.disabled1,
    });
  };

    handleCancel = () => {

        // because the client controls the visivlity state of the component
        this.props.hideModal();
    }
     joinPositions =(value)=>{
      if(_.isArray(value)==true && _.size(value)>0){
       let datas =[]
        _.map(value,(v,i)=>{
        datas.push(v=='WR'?"WR/TE":v)
        })
        return _.join(datas,', ')
      }
    
      }
    render() {
         /// this.props.isvisible is required, and it will be a boolean true or false.
        const shouldBeVisible = this.props.isvisible;
        const cardData = _.get(this, 'props.data', {});

        return <Modal
            title="Basic Modal"
            visible={shouldBeVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={1000}
            className="PlayerCardModal"
        >
            <div className= { !this.state.disabled1 ? "primaryCard active" : "primaryCard" } style={{cursor:'not-allowed'}}>
            <div className="row">
              <div className="col-12 col-sm-4 col-lg-4">
                <div className="userInfoHeader">
                  <span className="cardCheckUncheckContainer" onClick={this.CardActive}>
                    <i className="fas fa-check-square cardCheck"></i>
                    <i className="far fa-square cardUncheck"></i>
                  </span>
                  <img
                    src={cardData.image}
                    className="CardUserImg"
                  />
                  <div className="UserNameLine">
                    <div className="CardName">
                      <span>{_.startCase(_.get(cardData, 'athlete.first_name'))}</span>
                      <span className="CardNameBold">
                        {_.startCase(_.get(cardData, 'athlete.last_name'))}
                      </span>
                    </div>
                    <div className="CardNameLine">
                      <img
                        src={footBall}
                        className="mr-1"
                      />
                      <span>{this.joinPositions(_.get(cardData,'positions'))}</span>
                    </div>
                  </div>
                  <div className="Soph">
                    <span className="SophNo">{_.get(cardData,'kit_number')}</span>
                    <span>soph</span>
                  </div>
                </div>
                <div className="ExecutedSwitchContainer">
                  <div className="ExecutedSwitch">
                    <span>Assignments Executed</span>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={
                        <span>No</span>
                      }
                       disabled={this.state.disabled}
                    />
                  </div>
                  <div className="ExecutedSwitch">
                    <span>Is the efforts are great?</span>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={
                        <span>No</span>
                      }
                       disabled={this.state.disabled}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8 col-lg-8">
                <Form  className="PrimaryCardForm">
                  <Form.Item 
                    className="col-12 col-sm-6 col-lg-4"
                    label="Pancakes"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       disabled={this.state.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Reception"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       disabled={this.state.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="TD"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       disabled={this.state.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Cut Block"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width"
                       disabled={this.state.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Negative Play"
                    
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      initialvalues={3}
                      className="full-width ml-2"
                       disabled={this.state.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-4"
                    label="Penalty Flags"
                    
                  >
                    <Checkbox
                      defaultChecked
                      className="PrimaryCardFormEmojiCheck"
                       disabled={this.state.disabled}
                    >
                      <img
                        src={smileyDefault}
                        className="SmileEmoji"
                      />
                      <img
                        src={smileyactive}
                        className="CryEmoji"
                      />
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-12 col-lg-12 mb-0"
                    label="Feedback to player"
                    
                  >
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-5 text-success"
                    label="Positive"
                    
                  >
                    <Select
                      initialvalues="Unselfish Block"
                      className="full-width"
                       disabled={this.state.disabled}
                       onChange={this.handleChange}
                    >
                      <Option value="Unselfish Block">
                        Unselfish Block
                      </Option>
                      <Option value="Unselfish Block 1">
                        Unselfish Block 1
                      </Option>
                      <Option value="Unselfish Block 2">
                        Unselfish Block 2
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="col-12 col-sm-6 col-lg-5 text-danger"
                    label="Negative"
                    
                  >
                    <Select
                      initialvalues="Line"
                      className="full-width"
                       disabled={this.state.disabled}
                    >
                      <Option value="Line">
                        Busted Block
                      </Option>
                      <Option value="Line1">
                        Busted Block
                      </Option>
                      <Option value="Line2">
                        Busted Block
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item 
                    className="col-12 col-sm-12 col-lg-12 flex-column mb-0">
                    <TextArea rows={2} disabled={this.state.disabled} value={this.state.value}/>
                  </Form.Item>
                  <Form.Item 
                    className="col-12 col-sm-12 col-lg-12 flex-column mb-0">
                    <div className="SubmitCancelBtnContainer ant-modal-confirm-btns">
                      {/* <Button className="ant-btn ant-btn-primary" disabled={this.state.disabled} onClick={this.handleCancel}>Submit</Button> */}
                      <Button className="ant-btn" 
                      // disabled={this.state.disabled} 
                      onClick={this.handleCancel}>Cancel</Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
    }
}
export default CardModal;