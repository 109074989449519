import React from "react";

function FormationTableData({col1, col2a,  col2b, col3, col4, col5, col6}) {
  return (
    <div className="grid grid-cols-6 bg-[#ffffff] text-[#0F1A24] font-normal text-[10px]">
      <span className="col-span-1 border border-solid border-[#EAEDF0] text-left px-2 text-[12px] py-[12px] bg-[#F3F5F7] font-bold">
       {col1}
      </span>
      <span className="col-span-1 flex items-center  border border-solid border-[#EAEDF0] text-center py-[12px]">
        <span className=" text-[12px] font-bold text-[#1A91FF]">{ parseInt((col2a / col2b) * 100) }%</span>
        <span className="ml-1">{col2a}/{col2b}</span>
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
      {parseFloat(col3) || 0}
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
      {parseFloat(col4).toFixed(2) || 0}
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
      {parseInt(col5) || 0}%
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
      {parseInt(col6) || 0}%
      </span>
    </div> 
  );
}

export default FormationTableData;
