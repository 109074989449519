import accountAction from './account';
import coachesAction from './coaches';
import athletesAction from './athletes';
import schoolsAction from './schools';
import playsAction from './plays';
import depthChartAction from './depth-chart';
import gamesAction from './games';
import gradesAction from './grades';
import siteAction from './site';
import seasonsAction from './seasons';
import mvpsAction from './mvps';
import super_admin from './super_admin';

export const reduxAction = {
  account: accountAction,
  schools: schoolsAction,
  coaches: coachesAction,
  athletes: athletesAction,
  plays: playsAction,
  depth_chart: depthChartAction,
  games: gamesAction,
  grades:gradesAction,
  site:siteAction,
  seasons:seasonsAction,
  super_admin:super_admin,
  mvps: mvpsAction
}
export default reduxAction;