import React, { useEffect, useState } from "react";
import {
  Card,
  Dropdown,
  Menu,
  Spin,
  Progress,
  Select,
  Table,
  Button,
  Tooltip,
  Checkbox,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { Gauge } from "../../components/charts/guage";
import TotalNumberBox from "./TotalNumberBox";
import PieChartComponent from "../../components/charts/PieChartTwo";
import "./seasondashboard.css";
import multiplePages from "../../assets/img/multiple-pages.svg";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import game from "../../assets/img/grouppeople.svg";
import stats from "../../assets/img/stats-square-up.svg";
import crystal from "../../assets/img/codepen.svg";
import userSquare from "../../assets/img/user-square-alt.svg";
import clipimg from "../../assets/img/clipboard-check.svg";
import MultiBarChartTwo from "../../components/charts/BarChart2";
import moment from "moment";
import SpecificPostionsCard from "./SpecificPostionsCard";
import IndividualSpecific from "./IndividualSpecific";
import ComingSoon from "../../components/Modals/ComingSoon";
import people from "../../assets/img/group.svg";
import PlayerAnalyticCell from "../../components/misc/PlayerAnalyticCell";
import { TotalNumberCard } from "./SeasonAnalyticDashboard";
import PieChartCel from "../../components/misc/PieChartCel";
import KMultiLineChart from "../../components/charts/GameAnalyticsKChart";

import RunPassComponent from "./RunPassComponent";
import RunPassGameComponent from "./RunPassGameComponent";

function SpecificScreen(props) {
  const [gamesList, setGamesList] = useState([]);
  const [gamesData, setGamesData] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [seasonsList, setSeasonsList] = useState([]);
  const [gamesIds, setGamesIdsList] = useState([]);
  const [odk, setOdk] = useState("o");
  const [statsnotification, setStatsNotification] = useState(false);
  const [playernotification, setPlayerNotification] = useState(false);
  const [specificnotification, setSpecificNotification] = useState(false);
  const [positions, setPositions] = useState("QB");
  const [kchartGameData, setKChartGameData] = useState([]);
  const [kchartPieData, setKChartPieData] = useState([]);
  const [playerInfo, setPlayerInfo] = useState([{}]);
  const instances = [
    "Kicker",
    "Kickoff",
    "Kick Return",
    "Punt",
    "Punt Return",
    "PAT",
    "PAT Block",
    "FG Attempt",
    "FG Blocked",
  ];

  const instanceMappings = {
    Kicker: "Kicker",
    Kickoff: "Kickoff/Return",
    "PAT Block": "PAT/Block",
    Punt: "Punt/Return",
    "FG Attempt": "FG Attempt/Block",
  };
  const history = useNavigate();

  const Option = Select.Option;
  const openStatsNotification = () => {
    setStatsNotification(true);
  };
  const openPlayerNotification = () => {
    setPlayerNotification(true);
  };

  const closeStatsNotification = () => {
    setStatsNotification(false);
  };
  const closePlayerNotification = () => {
    setPlayerNotification(false);
  };
  const closeSpecificNotification = () => {
    setSpecificNotification(false);
  };

  useEffect(() => {
    loadSeasonsList();
  }, []);

  useEffect(() => {
    if (seasonsList[0]) {
      loadGamesList();
    }
  }, [seasonsList]);
  useEffect(() => {
    if (gamesList[0]) {
      loadGamesAnalytics();
    }
  }, [gamesList]);

  const formatNum = (val) => {
    if (isNaN(val)) return 0;
    return Math.round(val);
  };

  const loadGamesAnalytics = () => {
    let games = gamesList.map(({ id }) => id);

    setGamesIdsList(games);

    games = JSON.stringify(games);
    fetchGamesAnalytics(games, odk, JSON.stringify([positions]));
  };

  useEffect(() => {
    fetchPlayerGradesList(
      JSON.stringify(gamesIds),
      JSON.stringify([positions])
    );
  }, [positions]);

  const handleGamesAnalytics = () => {
    fetchGamesAnalytics(
      JSON.stringify(gamesIds),
      odk,
      JSON.stringify([positions])
    );
  };

  const toggleGameID = (id) => {
    let newList = [];
    const isPresent = gamesIds.find((i) => i == id);

    if (isPresent) {
      newList = gamesIds.filter((i) => i != id);
    } else {
      newList = gamesIds;
      newList.push(id);
    }

    setGamesIdsList(newList);
  };

  const fetchGamesAnalytics = (games, odk, position) => {
    setPageLoading(true);
    props.gamesAnalytics({
      params: { games, odk, position },
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows[0]);
          const result = {
            ...blob[0],
            ...blob[1],
            ...blob[2],
            ...blob[3],
            ...blob[4],
            ...blob[5],
            ...blob[6],
            ...blob[7],
            ...blob[8],
            ...blob[9],
          };
          console.log("result", result);
          setGamesData(result);
        }
        setPageLoading(false);
      },
    });
  };
  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));

    if (list) {
      setSeasonsList(list);
    } else {
      props.seasonsList({
        params: { sort: "id.desc", limit: 1000 },
        callback: (res, data) => {
          if (res === true) {
            const result = data.data.items;
            setSeasonsList(result);
            sessionStorage.setItem("seasonsList", JSON.stringify(result));
          }
        },
      });
    }
  };

  const fetchMatchAnalyticsByDepthChart = (game, odk, positions) => {
    props.matchAnalyticsByDepthChart({
      params: { game, odk, positions },
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows);
          console.log("blob for depth chart is", blob);
          const mapInstances = () => {
            return instances.map((instance) => {
              const blobItem = blob[0].find(({ name }) => name === instance);
              const execution = blobItem
                ? formatNum(
                    (blobItem.total_assignment_executed /
                      blobItem.total_count) *
                      100
                  )
                : 0;
              const effort = blobItem
                ? formatNum(
                    (blobItem.total_great_effort / blobItem.total_count) * 100
                  )
                : 0;
              const totalFlags = blobItem ? formatNum(blobItem.total_flags) : 0;

              return {
                instance: instance,
                Execution: execution,
                Effort: effort,
                totalFlags,
              };
            });
          };
          const mappedInstances = mapInstances();
          setKChartGameData(mappedInstances);
          const piechartdata = Object.keys(instanceMappings).map((instance) => {
            const name = instanceMappings[instance];
            const mappedInstance = mappedInstances.find(
              (inst) => inst.instance === instance
            );
            const value = mappedInstance ? mappedInstance.totalFlags : 0;
            return {
              name: name,
              value: value,
            };
          });
          setKChartPieData(piechartdata);
        }
      },
    });
  };

  const loadGamesList = (seasonID = null) => {
    const season = seasonID ?? seasonsList[0]?.id;
    console.log(
      "season",
      season,
      "seasonID ?? seasonsList[0]?.id",
      seasonID ?? seasonsList[0]?.id
    );
    let params = {
      limit: 995,
      home_team: _.get(props, "coachProfile.school.id"),
      sort: "week.asc",
      season,
    };
    const list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));

    if (list) {
      setGamesList(list);
      setGamesListLoading(false);
    } else {
      setGamesListLoading(true);
    }

    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          sessionStorage.setItem(
            `${seasonID}-gamesList`,
            JSON.stringify(_.get(data, "data.items", []))
          );
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const preCol = [
    {
      title: "Player Name",
      width: "15%",
      align: "center",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, item) => (
        <span className="text-[10px] md:text-[10.5px] tabledata font-bold text-[#0F1A24]">
          {item.first_name} {item.last_name}
        </span>
      ),
    },
    {
      title: "Execution",
      width: "6%",
      align: "center",
      dataIndex: "total_assignment_executed",
      key: "total_assignment_executed",
      render: (text, item) => (
        <span className="text-[10px] md:text-[10.5px] tabledata font-normal text-[#0F1A24]">
          {((item.total_assignment_executed / item.total_grades) * 100).toFixed(
            2
          )}
          %
        </span>
      ),
    },
    {
      title: "Assignments Executed",
      align: "center",
      width: "13%",

      render: (item, row) => item || "--",
      dataIndex: "total_assignment_executed",
      key: "total_assignment_executed",
    },
    {
      title: "Great Effort Plays",

      render: (item, row) => item || "--",
      dataIndex: "total_great_effort",
      key: "total_great_effort",
      align: "center",
      width: "12%",
    },
  ];
  function aggregateJsonKeys(jsonStrings) {
    // Initialize an empty object to store the aggregated results
    const aggregatedResult = {};
  
    // Iterate over each JSON string
    jsonStrings.forEach(jsonString => {
      // Parse the JSON string into an object
      const jsonObject = JSON.parse(jsonString);
  
      // Iterate over each key-value pair in the object
      for (const [key, value] of Object.entries(jsonObject)) {
        // Convert the value to a number and add it to the corresponding key in the aggregated result
        aggregatedResult[key] = (aggregatedResult[key] || 0) + Number(value);
      }
    });
  
    return aggregatedResult;
  }
  
  const postCol = [
    {
      title: "TD",
      dataIndex: "total_td",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_td",
    },
    {
      title: "Custom Stats",
      dataIndex: "custom_stats_array",
      width: "6%",

      render: (item, row) =>{
        console.log('item,,', item);
        const aggregatedResult = aggregateJsonKeys(item || []);

        // Output the aggregated result
        console.log('long;time', aggregatedResult);
        return 0;
      },
      align: "center",
      key: "total_td",
    },
  ];

  const qbColumn = [
    ...preCol,
    {
      title: "Flags",
      dataIndex: "total_penalties",
      key: "total_penalties",

      render: (item, row) => item || "--",

      align: "center",
      width: "6%",
    },
    {
      title: "Completions",

      render: (item, row) => item || "--",
      dataIndex: "total_completions",
      key: "total_completions",
      width: "6%",
      align: "center",
    },
    {
      title: "Bad Balls",

      render: (item, row) => item || "--",
      dataIndex: "total_bad_balls",
      key: "total_bad_balls",
      width: "10%",
      align: "center",
    },
    {
      title: "Carries",

      render: (item, row) => item || "--",
      dataIndex: "total_carries",
      key: "total_carries",
      width: "6%",
      align: "center",
    },
    {
      title: "Fumbles",
      dataIndex: "total_fumbles",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_fumbles",
    },
    ...postCol,
  ];

  const olCol = [
    ...preCol,
    {
      title: "Pancakes",
      render: (item, row) => item || "--",
      dataIndex: "total_pancakes",
      key: "total_pancakes",
      align: "center",
      width: "6%",
    },
    {
      title: "Big Hits",

      render: (item, row) => item || "--",
      dataIndex: "total_catchable_balls",
      key: "total_catchable_balls",
      width: "6%",
      align: "center",
    },
    {
      title: "Knockdowns",

      render: (item, row) => item || "--",
      dataIndex: "total_knockdowns",
      key: "total_knockdowns",
      width: "8%",
      align: "center",
    },
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      width: "6%",
      align: "center",
    },
    {
      title: "Pass Pro Win %",
      dataIndex: "total_pass_pro_sacks_won",
      width: "8%",

      render: (item, row) =>  (
        <span
        >
          {formatNum(parseInt(row?.total_pass_pro_sacks_won) / (parseInt(row?.total_pass_pro_sacks_won) + parseInt(row?.total_pass_pro_sacks_lost) )) * 100 + '%' || "--"}
        </span>
      ) || "--",
      align: "center",
      key: "total_pass_pro_sacks_won",
    },
  ];

  const teCol = [
    ...preCol,
    {
      title: "Flags",

      dataIndex: "total_penalties",
      key: "total_penalties",

      render: (item, row) => item || "--",

      align: "center",
      width: "6%",
    },
    {
      title: "Carries",

      dataIndex: "total_carries",
      key: "total_carries",
      render: (item, row) => item || "--",

      width: "6%",
      align: "center",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "10%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_receptions",
      key: "total_receptions",
      width: "6%",
      align: "center",
    },
    {
      title: "Drops",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
    },
    {
      title: "Pancakes",
      dataIndex: "total_pancakes",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_pancakes",
    },
    ...postCol,
  ];

  const wrCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "5%",
    },
    {
      title: "Carries",

      render: (item, row) => item || "--",
      dataIndex: "total_carries",
      key: "total_carries",
      width: "4%",
      align: "center",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "4%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_receptions",
      key: "total_receptions",
      width: "5%",
      align: "center",
    },
    {
      title: "Drops",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "4%",

      render: (item, row) => item || "--",
      align: "center",
    },
    {
      title: "Pancakes",

      render: (item, row) => item || "--",

      dataIndex: "total_pancakes",
      key: "total_pancakes",
      align: "center",
      width: "4%",
    },
    ...postCol,
  ];

  const DCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_tackles",
      key: "total_tackles",
      width: "6%",
      align: "center",
    },
    {
      title: "Missed Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_missed_tackles",
      key: "total_missed_tackles",
      width: "10%",
      align: "center",
    },
    {
      title: "Hurries",

      render: (item, row) => item || "--",
      dataIndex: "total_hurries",
      key: "total_hurries",
      width: "6%",
      align: "center",
    },
    {
      title: "Sacks",
      dataIndex: "total_sacks",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_sacks",
    },
    {
      title: "Turnovers Created",
      dataIndex: "total_turnovers",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_turnovers",
    },
    ...postCol,
  ];

  const kCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "PAT",

      render: (item, row) => item || "--",
      dataIndex: "total_pat",
      key: "total_pat",
      width: "6%",
      align: "center",
    },
    {
      title: "FG",

      render: (item, row) => item || "--",
      dataIndex: "total_field_goals",
      key: "total_field_goals",
      width: "10%",
      align: "center",
    },
    {
      title: "Punt",

      render: (item, row) => item || "--",
      dataIndex: "total_punts",
      key: "total_punts",
      width: "6%",
      align: "center",
    },
    ...postCol,
  ];

  const stCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",
      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_tackles",
      key: "total_tackles",
      width: "6%",
      align: "center",
    },
    {
      title: "Missed Tackles",

      render: (item, row) => item || "--",
      dataIndex: "total_missed_tackles",
      key: "total_missed_tackles",
      width: "10%",
      align: "center",
    },
    {
      title: "Big Time Blocks",

      render: (item, row) => item || "--",
      dataIndex: "total_catchable_balls",
      key: "total_catchable_balls",
      width: "10%",
      align: "center",
    },
    {
      title: "Turnovers Created",
      dataIndex: "total_turnovers",
      width: "13%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_turnovers",
    },
    ...postCol,
  ];

  const rbCol = [
    ...preCol,
    {
      title: "Flags",

      render: (item, row) => item || "--",

      dataIndex: "total_penalties",
      key: "total_penalties",
      align: "center",
      width: "6%",
    },
    {
      title: "Carries",
      dataIndex: "total_carries",
      key: "total_carries",
      align: "center",
      width: "6%",
    },
    {
      title: "Fumbles",

      render: (item, row) => item || "--",
      dataIndex: "total_fumbles",
      key: "total_fumbles",
      width: "6%",
      align: "center",
    },
    {
      title: "Receptions",

      render: (item, row) => item || "--",
      dataIndex: "total_receptions",
      key: "total_receptions",
      width: "8%",
      align: "center",
    },
    {
      title: "Drops",

      render: (item, row) => item || "--",
      key: "total_drops",
      dataIndex: "total_drops",
      width: "6%",
      align: "center",
    },
    {
      title: "Pancakes",
      dataIndex: "total_pancakes",
      width: "6%",

      render: (item, row) => item || "--",
      align: "center",
      key: "total_pancakes",
    },
    ...postCol,
  ];

  const [col, setCol] = useState(qbColumn);

  const handleSortPlay = (type, positions, colName) => {
    setOdk(type);
    setPositions(positions);
    fetchGamesAnalytics(
      JSON.stringify(gamesIds),
      type,
      JSON.stringify([positions])
    );
    setCol(colName);
  };

  const columns = [
    {
      title: () => <Checkbox disabled />,
      dataIndex: "checked",
      width: "5%",
      align: "center",
      key: "id",
      render: (text, item) => (
        <Checkbox
          onChange={(e) => toggleGameID(item.id)}
          defaultChecked={text}
        />
      ),
    },
    {
      title: "Week #",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      dataIndex: "week",
      align: "center",
      width: "7%",
      key: "week",
    },

    {
      title: "Opponent",
      dataIndex: "title",
      align: "center",
      width: "15%",
      key: "title",
      render: (text, item) => (
        <div className="text-[10px] lg:text-[12px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#a40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "type",
      align: "center",
      width: "12%",
      key: "type",
    },
    {
      title: "Results",
      dataIndex: "start_time",
      align: "center",
      key: "results",
      width: "10%",
      className: "p-0",
      render: (text, item) => (
        <div
          className={
            item.home_team_score - item.away_team_score <= 0
              ? "bg-[#a40E171a] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
              : "bg-[#12CF521a] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
          }
        >
          <span className="font-inter font-bold text-[10px] lg:text-[11.5px]">
            {item.home_team_score - item.away_team_score <= 0 ? "L:" : "W:"}{" "}
          </span>

          <span className="ml-[2%] text-[10px] lg:text-[11.5px]">
            {item.home_team_score}
          </span>
          <span>-</span>

          <span className="text-[10px] lg:text-[11.5px]">
            {item.away_team_score}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "13%",
      align: "center",
      className: "p-0",
      key: "created_at",
      render: (text, item) => {
        return (
          <div className="gameanalytics-table-action w-[90%] mx-auto">
            <Tooltip title="View Game Analytics">
              <Button
                icon={<img src={game} />}
                onClick={() => {
                  console.log(item);
                  history(`/game-analytic-dashboard?id=${_.get(item, "id")}`, {
                    state: { week: item.week },
                  });
                }}
              />
            </Tooltip>

            <Tooltip title="View Player Stats">
              <Button
                icon={
                  <img
                    src={userSquare}
                    onClick={() => {
                      console.log(item);
                      history(
                        `/player-analytic-dashboard?game=${_.get(item, "id")}`,
                        {
                          state: { position: positions },
                        }
                      );
                    }}
                  />
                }
              />
            </Tooltip>

            <Tooltip title="View Coaches Notes">
              <Button
                onClick={() =>
                  history(
                    `/coaches-note?game_id=${_.get(item, "id")}&opponent=${
                      item.title
                    }&team=${item?.team}&week=${item.week}&t=${
                      item.feedback_type
                    }`
                  )
                }
                icon={<img src={clipimg} />}
              />
            </Tooltip>

            <Tooltip title="View Statistics">
              <Button
                icon={<img src={stats} onClick={openStatsNotification} />}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const fetchPlayerGradesList = (game, position) => {
    if (JSON.parse(game)[0]) {
      setPageLoading(true);
      props.playerGradesList({
        params: {
          position,
          game,
        },
        callback: (res, data) => {
          if (res === true) {
            setPageLoading(false);
            console.log("fetch for player table", data);
            setPlayerInfo(data?.data?.items[0]?.rows);
          }
          {
            console.log("error fetch for player table", data);
          }
          setPageLoading(false);
        },
      });
    }
  };

  return (
    <div className="flex flex-col w-full">
   

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {/* aLL, O,D,K BUTTONS */}
          <div className="w-[148px] bg-[black] h-[48px] font-poppins flex items-center rounded-[4px] px-[4px] justify-around mr-[16px]">
            <button
              onClick={() => handleSortPlay("o", "QB", qbColumn)}
              className={
                (odk == "o" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              O
            </button>
            <button
              onClick={() => handleSortPlay("d", "DL", DCol)}
              className={
                (odk == "d" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              D
            </button>
            <button
              onClick={() => {
                handleSortPlay("k", "K", kCol);
                fetchMatchAnalyticsByDepthChart(
                  JSON.stringify(gamesIds),
                  "k",
                  JSON.stringify("K")
                );
              }}
              className={
                (odk == "k" ? " bg-[#A40E17]" : " ") +
                " w-[32px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
              }
            >
              K
            </button>
          </div>
          {odk == "o" && (
            <div className="w-[215px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => handleSortPlay("o", "QB", qbColumn)}
                className={
                  (positions == "QB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
                }
              >
                QB
              </button>
              <button
                onClick={() => handleSortPlay("o", "OL", olCol)}
                className={
                  (positions == "OL"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                OL
              </button>
              <button
                onClick={() => handleSortPlay("o", "WR", wrCol)}
                className={
                  (positions == "WR"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                WR
              </button>
              <button
                onClick={() => handleSortPlay("o", "TE", teCol)}
                className={
                  (positions == "TE"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                TE
              </button>
              <button
                onClick={() => handleSortPlay("o", "RB", rbCol)}
                className={
                  (positions == "RB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                RB
              </button>
            </div>
          )}
          {odk == "d" && (
            <div className="w-[172px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => handleSortPlay("d", "DL", DCol)}
                className={
                  (positions == "DL"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-l-[4px]"
                }
              >
                DL
              </button>
              <button
                onClick={() => handleSortPlay("d", "LB", DCol)}
                className={
                  (positions == "LB"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                LB
              </button>
              <button
                onClick={() => handleSortPlay("d", "S", DCol)}
                className={
                  (positions == "S"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                S
              </button>
              <button
                onClick={() => handleSortPlay("d", "C", DCol)}
                className={
                  (positions == "C"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                C
              </button>
            </div>
          )}
          {odk == "k" && (
            <div className="w-[86px] bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
              {" "}
              <button
                onClick={() => {
                  handleSortPlay("k", "K", kCol);
                  fetchMatchAnalyticsByDepthChart(
                    JSON.stringify(gamesIds),
                    "k",
                    JSON.stringify("K")
                  );
                }}
                className={
                  (positions == "K"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") + " w-[43px] focus:outline-0 h-[48px]"
                }
              >
                K
              </button>
              <button
                onClick={() => {
                  handleSortPlay("k", "ST", stCol);
                  fetchMatchAnalyticsByDepthChart(
                    JSON.stringify(gamesIds),
                    "k",
                    JSON.stringify("ST")
                  );
                }}
                className={
                  (positions == "ST"
                    ? "bg-[#1A91FF] text-[#ffffff]"
                    : "text-[#1A91FF] ") +
                  " w-[43px] focus:outline-0 h-[48px] rounded-r-[4px]"
                }
              >
                ST
              </button>
            </div>
          )}
          <div className="w-[200px] h-[43px] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
            <Select
              bordered={false}
              defaultValue={seasonsList[0]?.title}
              placeholder={seasonsList[0]?.title}
              onChange={(value) => {
                loadGamesList(value);
              }}
              className="w-[100%] text-[14px]"
            >
              {_.map(seasonsList, ({ id, title }, index) => {
                return (
                  <Option
                    value={id}
                    key={id}
                    className="text-[14px] font-bold font-inter"
                  >
                    {title}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        {/* print and effort div */}
        <div className="flex items-center">
          {/* win or loss record */}
          <div className="flex items-center font-bold">
            {/* win or loss div */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">Win/Loss Record</span>
              <span className="text-[18px] text-[#1790FF]">
                {formatNum(gamesData.total_wins)} -{" "}
                {formatNum(gamesData.total_loss)}
              </span>
            </div>
            {/* winning percent */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">Winning %</span>
              <span className="text-[18px] text-[#ED780B]">
                {formatNum(
                  (gamesData.total_wins /
                    (Number(gamesData.total_loss) +
                      Number(gamesData.total_wins))) *
                    100
                )}
                %
              </span>
            </div>
            {/* total assignment */}
            <div className="flex flex-col mr-6 text-center">
              <span className="text-[8px] text-[#526B84]">
                Overall Execution
              </span>
              <span className="text-[18px] text-[#00B21E]">
                {formatNum(
                  (gamesData.total_assignment_executed /
                    gamesData.total_grades) *
                    100
                )}
                %
              </span>
            </div>
            {/* Effort Score */}
            <div className="flex flex-col text-center">
              <span className="text-[8px] text-[#526B84]">Overall Effort</span>{" "}
              <span className="text-[18px] text-[#00B21E]">
                {formatNum(
                  (gamesData.total_great_effort / gamesData.total_grades) * 100
                )}
                %
              </span>
            </div>
          </div>
          {/* print and share div removed*/}
        </div>
      </div>

      <Spin spinning={pageLoading}>
        {/* guages div */}
        {/* effort and execution report div */}
        {/* performance total */}
        <div
          className="w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-[20px]"
          
        >
          <div className="flex items-center justify-center text-[#0F1a24] text-[1rem] h-[47px] p-3 font-[700] bg-[#EaEDF0]">
            <span>Performance Totals</span>
          </div>
          <div className="flex flex-col mt-3 p-3 w-full">
            {/* table */}
            <div className="w-full gameanalyticstablediv">
              <Table
                className="w-full"
                dataSource={playerInfo}
                columns={col}
                bordered
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mt-[20px] w-full">
          {/* exec */}
          <PlayerAnalyticCell
            Title={"Execution Report"}
            total_games={parseInt(gamesData.total_wins) + parseInt(gamesData?.total_loss)}
            TotalAssignmentsEvaluated={formatNum(
              gamesData.total_assignment_executed
            )}
            formatNum={formatNum}
            Avg={"Execution"}
            winValue={formatNum(
              (gamesData.total_assignment_executed_in_won_games /
                gamesData.total_grades_in_won_games) *
                100
            )}
            lossValue={formatNum(
              (gamesData.total_assignment_executed_in_lost_games /
                gamesData.total_grades_in_lost_games) *
                100
            )}
            calcParam={"Assignments Failed"}
            calcParamValue={formatNum(
              gamesData.total_grades - gamesData.total_assignment_executed
            )}
          />
          {/* effort */}
          <PlayerAnalyticCell
            Title={"Effort Report"}
            TotalAssignmentsEvaluated={formatNum(gamesData.total_great_effort)}
            Avg={"Effort"}
            total_games={parseInt(gamesData.total_wins) + parseInt(gamesData?.total_loss)}
            formatNum={formatNum}
            winValue={formatNum(
              (gamesData.total_great_effort_in_won_games /
                gamesData.total_grades_in_won_games) *
                100
            )}
            lossValue={formatNum(
              (gamesData.total_great_effort_in_lost_games /
                gamesData.total_grades_in_lost_games) *
                100
            )}
            calcParam={"Bad Effort Plays"}
            calcParamValue={formatNum(
              gamesData.total_grades - gamesData.total_great_effort
            )}
          />
        </div>

        {/* line charts div */}
        {odk !== "k" ? (
          <div class="flex justify-between items-center mt-[20px]">
            {/* exec */}
            <div
              className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
              
            >
              <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                {" "}
                <span>Execution by Down </span>
              </div>
              <div className="flex flex-col mt-[11px] w-full p-[12px]">
                <div className="flex items-center  w-full font-inter text-[12px]">
                  <span className="w-[100%]">
                    <span className=" w-[100%] flex items-center ">
                      <MultiBarChartTwo
                        title="Win"
                        titleTwo="Loss"
                        data={[
                          {
                            instance: "1st down",
                            Executed: formatNum(
                              gamesData.total_assignment_executed_won_dn_one
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_one -
                                gamesData.total_assignment_executed_won_dn_one
                            ),
                          },
                          {
                            instance: "2nd down",
                            Executed: formatNum(
                              gamesData.total_assignment_executed_won_dn_two
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_two -
                                gamesData.total_assignment_executed_won_dn_two
                            ),
                          },
                          {
                            instance: "3rd down",
                            Executed: formatNum(
                              gamesData.total_assignment_executed_won_dn_three
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_three -
                                gamesData.total_assignment_executed_won_dn_three
                            ),
                          },
                          {
                            instance: "4th down",
                            Executed: formatNum(
                              gamesData.total_assignment_executed_won_dn_four
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_four -
                                gamesData.total_assignment_executed_won_dn_four
                            ),
                          },
                        ]}
                        dataTwo={[
                          {
                            instance: "1st down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_one -
                                gamesData.total_assignment_executed_lost_dn_one
                            ),
                            Executed: formatNum(
                              gamesData.total_assignment_executed_lost_dn_one
                            ),
                          },
                          {
                            instance: "2nd down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_two -
                                gamesData.total_assignment_executed_lost_dn_two
                            ),
                            Executed: formatNum(
                              gamesData.total_assignment_executed_lost_dn_two
                            ),
                          },
                          {
                            instance: "3rd down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_three -
                                gamesData.total_assignment_executed_lost_dn_three
                            ),
                            Executed: formatNum(
                              gamesData.total_assignment_executed_lost_dn_three
                            ),
                          },
                          {
                            instance: "4th down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_four -
                                gamesData.total_assignment_executed_lost_dn_four
                            ),
                            Executed: formatNum(
                              gamesData.total_assignment_executed_lost_dn_four
                            ),
                          },
                        ]}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* effort */}
            <div
              className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
              
            >
              <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                {" "}
                <span>Effort by Down </span>
              </div>
              <div className="flex flex-col mt-[11px] w-full p-[12px]">
                <div className="flex justify-between items-center  w-full font-inter text-[12px]">
                  <span className="w-[100%]">
                    <span className=" w-[100%] flex items-center">
                      <MultiBarChartTwo
                        title="Win"
                        titleTwo="Loss"
                        data={[
                          // total_grades_dn_one
                          {
                            instance: "1st down",
                            Executed: formatNum(
                              gamesData.total_great_effort_won_dn_one
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_one -
                                gamesData.total_great_effort_won_dn_one
                            ),
                          },
                          {
                            instance: "2nd down",
                            Executed: formatNum(
                              gamesData.total_great_effort_won_dn_two
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_two -
                                gamesData.total_great_effort_won_dn_two
                            ),
                          },
                          {
                            instance: "3rd down",
                            Executed: formatNum(
                              gamesData.total_great_effort_won_dn_three
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_three -
                                gamesData.total_great_effort_won_dn_three
                            ),
                          },
                          {
                            instance: "4th down",
                            Executed: formatNum(
                              gamesData.total_great_effort_won_dn_four
                            ),
                            Failed: formatNum(
                              gamesData.total_grades_won_dn_four -
                                gamesData.total_great_effort_won_dn_four
                            ),
                          },
                        ]}
                        dataTwo={[
                          // total_grades_dn_one
                          {
                            instance: "1st down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_one -
                                gamesData.total_great_effort_lost_dn_one
                            ),
                            Executed: formatNum(
                              gamesData.total_great_effort_lost_dn_one
                            ),
                          },
                          {
                            instance: "2nd down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_two -
                                gamesData.total_great_effort_lost_dn_two
                            ),
                            Executed: formatNum(
                              gamesData.total_great_effort_lost_dn_two
                            ),
                          },
                          {
                            instance: "3rd down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_three -
                                gamesData.total_great_effort_lost_dn_three
                            ),
                            Executed: formatNum(
                              gamesData.total_great_effort_lost_dn_three
                            ),
                          },
                          {
                            instance: "4th down",
                            Failed: formatNum(
                              gamesData.total_grades_lost_dn_four -
                                gamesData.total_great_effort_lost_dn_four
                            ),
                            Executed: formatNum(
                              gamesData.total_great_effort_lost_dn_four
                            ),
                          },
                        ]}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-6"
            
          >
            <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
              {" "}
              <span>Special Teams Units</span>
            </div>
            <div className="flex flex-col mt-[11px] w-full p-[12px]">
              <div className="flex items-center  w-full font-inter text-[12px]">
                <span className="w-[100%]">
                  <span className=" w-[100%] flex items-center ">
                    <KMultiLineChart
                      title="Execution"
                      titleTwo="Effort"
                      data={kchartGameData}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}

        {/* progress bars */}
        <div className="flex flex-col w-full mt-12">
          <div className="flex w-full justify-between items-stretch">
            {/* flag over view and flag win/loss breakdown */}

            <div className="flex flex-col w-[49%]">
              <div
                className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                style={{ border: "1.5px solid #EaEDF0" }}
              >
                <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                  <span>Flag Overview</span>
                </div>
                <div className="h-[184px] w-full justify-between p-3 bg-[#FFFFFF] font-poppins flex flex-wrap items-center font-bold text-[12px]">
                  <TotalNumberCard
                    title="Total Number of Plays"
                    number={formatNum(gamesData.total_plays)}
                  />
                  <TotalNumberCard
                    title="Total Number of Flags"
                    number={formatNum(gamesData.total_flags)}
                  />
                  <TotalNumberCard
                    title="Assignments Executed"
                    number={formatNum(gamesData.total_assignment_executed)}
                  />
                  <TotalNumberCard
                    title="Flags Per Game"
                    number={formatNum(
                      gamesData.total_flags /
                        (Number(gamesData.total_wins) +
                          Number(gamesData.total_loss))
                    )}
                  />
                </div>
              </div>
              <div
                className="flex flex-col mt-4 rounded-[8px] font-inter w-[100%] h-[231px] mr-[24px]"
                style={{ border: "1.5px solid #EaEDF0" }}
              >
                <div className="flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full">
                  <span>Flag Win/Loss Breakdown</span>
                </div>
                <div className="h-[184px] w-full text-center bg-[#FFFFFF] font-poppins flex flex-col items-center justify-center font-bold text-[#526B84] text-[12px]">
                  {/* win progress bar */}
                  <div className="flex justify-between items-center mt-[13px] flagWinBreakdown">
                    <span className="text-[10px] mr-[12px] mt-[4px]">
                      {" "}
                      Wins
                    </span>
                    <div className="progress-container">
                      <Progress
                        percent={formatNum(
                          (gamesData.total_flags_in_won_games /
                            gamesData.total_flags) *
                            100
                        )}
                        style={{ width: 358, margin: 0, padding: 0 }}
                        strokeColor="#E2B517"
                        showInfo={false}
                      />
                      <div className="progress-text1">
                        {formatNum(gamesData.total_flags_in_won_games)}
                      </div>
                    </div>
                  </div>
                  {/* losses progress bar */}
                  <div className="flex justify-between items-center mt-[16px] flagWinBreakdown">
                    <span className="text-[10px] mr-[5px] mt-[4px]">
                      {" "}
                      Losses
                    </span>
                    <div className="progress-container">
                      <Progress
                        percent={formatNum(
                          (gamesData.total_flags_in_lost_games /
                            gamesData.total_flags) *
                            100
                        )}
                        style={{ width: 358, margin: 0, padding: 0 }}
                        strokeColor="#a40E17"
                        showInfo={false}
                      />
                      <div className="progress-text">
                        {formatNum(gamesData.total_flags_in_lost_games)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* flag breakdown pie chart */}
            <div
              className="mt-4 flex flex-col rounded-[8px] w-[49%]"
              style={{ border: "1.5px solid #EaEDF0" }}
            >
              {odk == "k" ? '' : (
                <PieChartCel
                  title="Flag Down Breakdown"
                  data={[
                    {
                      name: "1st Down",
                      value: formatNum(gamesData.total_flags_dn_one),
                    },
                    {
                      name: "2nd Down",
                      value: formatNum(gamesData.total_flags_dn_two),
                    },
                    {
                      name: "3rd Down",
                      value: formatNum(gamesData.total_flags_dn_three),
                    },
                    {
                      name: "4th Down",
                      value: formatNum(gamesData.total_flags_dn_four),
                    },
                  ]}
                  width={300}
                />
              )}
            </div>
          </div>
        </div>

        {/* statistics section */}
         

        {/* generate custom reports */}
        <button
          onClick={() => handleGamesAnalytics()}
          className="mt-[24px] bg-[#E8F4FF] hover:w-[180px] w-[184px] h-[36px] flex items-center text-[#2E4D6B] focus:outline-0 px-[8px] font-normal rounded-[4px] text-[12px]"
        >
          <img src={multiplePages} className="w-[20px] mr-[8px]" />
          <span>Generate Custom Report</span>
        </button>

        {/* table div */}
        <div className="gameanalytictablediv mt-[8px]">
          <Table
            key={1}
            bordered={true}
            loading={gamesListLoading}
            columns={columns}
            dataSource={gamesList.map((item) => {
              item.checked = true;
              return item;
            })}
            size="middle"
            pagination={false}
            className="w-[100%] h-full border-[1px] border-[#F3F5F7] border-[solid]"
          />
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  playerGradesList: (details) =>
    dispatch(global.redux.action.grades.playerAnalytics(details)),
  matchAnalyticsByDepthChart: (details) =>
    dispatch(global.redux.action.grades.matchAnalyticsByDepthChart(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecificScreen);
