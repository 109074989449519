import React, { useState, useEffect } from "react";
import { Form, Input, message, Button } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/img/arrow-leftblue.svg";
import continueArrow from "../../assets/img/arrow-right.svg";

function PersonalDetails(props) {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const history = useNavigate();

  useEffect(() => {
    props.form.setFieldsValue({ first_name: props?.regDetails?.first_name });
    props.form.setFieldsValue({ last_name: props?.regDetails?.last_name });
    props.form.setFieldsValue({ email: props?.regDetails?.email });
    props.form.setFieldsValue({ school: props?.regDetails?.school });
    props.form.setFieldsValue({ password: props?.regDetails?.password });
  }, [props.form, props?.regDetails]);

  const errorRegister = (text) => {
    console.error("texxt", text);
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const handleInputChange = (name, value) => {
    props.setRegDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    sessionStorage.setItem("regDetails", JSON.stringify({
      ...props.regDetails,
      [name]: value,
    }));
  };

  return (
    <div className="w-[100%] mb-[12%] xl:mb-[6vw]">
      {contextHolder}
      <div
        className="w-[100%] mx-auto"
      >
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
          className="w-[60%]"
          // initialValue={props.regDetails?.first_name}
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              First Name
            </span>
            <Input
              size="large"
              className="py-2"
              placeholder="Enter your first name"
              defaultValue={props.regDetails?.first_name?.toString()}
              onChange={(e) => handleInputChange("first_name", e.target.value)}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              Last Name
            </span>
            <Input
              size="large"
              className="py-2"
              placeholder="Enter your last name"
              defaultValue={props.regDetails?.last_name?.toString()}
              onChange={(e) => handleInputChange("last_name", e.target.value)}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="school"
          rules={[
            {
              required: true,
              message: "Please enter your school name",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              School Name
            </span>
            <Input
              size="large"
              className="py-2"
              placeholder="Enter your school name"
              defaultValue={props.regDetails?.school?.toString()}
              onChange={(e) => handleInputChange("school", e.target.value)}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              Email
            </span>
            <Input
              size="large"
              className="py-2"
              placeholder="Example@gmail.com"
              defaultValue={props.regDetails?.email?.toString()}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              Password
            </span>
            <Input.Password
              size="large"
              className="py-2"
              placeholder="Enter your password"
              defaultValue={props.regDetails?.password?.toString()}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
          </div>
        </Form.Item>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(global.redux.action.account.login(details)),
  register: (details) =>
    dispatch(global.redux.action.account.register(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
