import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import Title from './components/title.js'

function DefensiveModal({state, dispatch, close, update, item, cutomStat,  play, handleUpdatePlay, updateInputGrade, updateGrade }) {
    const [modalWidth, setModalWidth] = useState(235);
    useEffect(() => {
      if (window.innerWidth <= 768) {
        setModalWidth(200);
      } else {
        setModalWidth(235);
      }
    }, [window.innerWidth]);
  

  return state.qbcompletion && (
    <Modal
      open={state.qbcompletion}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
      title={<><Title  player={`${item?.first_name} ${item?.last_name}`} /></>}
      
    >
      <>
        <div className="flex flex-col  font-inter text-[14px] text-[#0F1A24]">
          <span className="mb-2">Label</span>
          <Input onChange={({target: { value }}) => handleUpdatePlay(play.id, { gn: value})} defaultValue={play.gn} size="large" />
        </div>
        <div className="flex items-center justify-between text-md mt-4">
          <button
          onClick={close}
            className="text-[#1790FF] w-[49%] flex items-center justify-center h-11  focus:outline-0 rounded-[4px]"
            style={{ border: "1px solid #1790FF" }}
          >
            Cancel
          </button>
          <button onClick={close} className="flex items-center focus:outline-0 justify-center w-[49%] bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </>
    </Modal>
  );
}

export default DefensiveModal;
