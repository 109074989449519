import React, { useRef } from "react";
import {
  Card,
  Dropdown,
  Menu,
  Progress,
  Table,
  Button,
  Tooltip,
  Checkbox,
  Breadcrumb,
  Select,
} from "antd";
import printer from "../../assets/img/printer.svg";
import share from "../../assets/img/share-android.svg";
import download from "../../assets/img/download.svg";
import mail from "../../assets/img/mail-out.svg";
import "./seasondashboard.css";
import _ from "lodash";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import GameAnalyticChart from "../../components/charts/GameAnalyticChart";
import arrowLeft from "../../assets/img/long-arrow-up-left.svg";
import pageStar from "./assets/page-star.svg";
import clipboard from "./assets/clipboard-check.svg";
import emojiSad from "./assets/emoji-sad.svg";
import emojiSatisfied from "./assets/emoji-satisfied.svg";
import thumbsDown from "./assets/thumbs-down.svg";
import triangleFlag from "./assets/triangle-flag-full.svg";
import note from "../../assets/img/notes.svg";
import { useState, useEffect } from "react";
import { capitalizeString } from "../../reusable-func";
import KMultiLineChart from "../../components/charts/GameAnalyticsKChart";
import AdvantageOnly from "../../components/permissions/AdvantageOnly";
import GotIdea from "./components/got-idea";
import { columns } from "./gameAnalyticsColumn";

const { Option } = Select;
export const Tab = ({ icon, title, value, color }) => {
  return (
    <div className="flex flex-col rounded-md items-center m-1 md:m-2 lg:m-0 justify-around p-1 pb-2 md:pb-4 w-[30%] lg:w-[14%] h-[9rem] md:h-[11rem] border border-solid border-[#EAEDF0] text-center">
      <span className="p-2 rounded-full" style={{ backgroundColor: color }}>
        <img src={icon} className="w-[2rem]" />
      </span>
      <span className="text-[#2E4D6B] md:text-[28px] text-[18px] lg:text-[28px] xl:text-[2.2rem] font-[700]">
        {value}
      </span>
      <span className="text-[12px] xl:text-[0.8rem] text-[#526B84] font-[400]">
        {title}
      </span>
    </div>
  );
};

function GameAnalyticsDashboard(props) {
  const location = useLocation();
  const { week } = location.state || {};

  const paramss = new URLSearchParams(window.location.search);
  let game = paramss.get("id");

  const [gameId, setGameId] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("All Positions");
  const [selectedWeek, setSelectedWeek] = useState("All Games");
  const [gamesList, setGamesList] = useState([]);
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [seasonsList, setSeasonsList] = useState([]);
  const [gamesIds, setGamesIdsList] = useState([]);
  const [odk, setOdk] = useState("all");
  const history = useNavigate();
  const [players, setPlayers] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [gamesData, setGamesData] = useState([]);
  const [positionscharts, setPositionscharts] = useState([]);
  const [filterValue, setFilterValue] = useState("All position");
  const [kchartGameData, setKChartGameData] = useState([]);
  const instances = [
    "Kicker",
    "Kickoff",
    "Kick Return",
    "Punt",
    "Punt Return",
    "PAT",
    "PAT Block",
    "FG Attempt",
    "FG Blocked",
  ];

  useEffect(() => {
    loadSeasonsList();
    gridironDetails();
  }, []);

  useEffect(async () => {
    if (seasonsList[0]) {
      loadGamesList();
    }
  }, [seasonsList]);

  const formatNum = (val) => {
    if (isNaN(val) || val === null) return 0;
    return Math.round(val);
  };

  const handleMatchAnalytics = () => {
    fetchMatchAnalytics(JSON.stringify(gamesIds));
  };

  const toggleGameID = (id) => {
    let newList = [];
    const isPresent = gamesIds.find((i) => i == id);

    if (isPresent) {
      newList = gamesIds.filter((i) => i != id);
    } else {
      newList = gamesIds;
      newList.push(id);
    }

    setGamesIdsList(newList);
  };

  const fetchMatchAnalytics = (game, odk) => {
    console.log("game for fetching analytics", game);
    if (gamesList.length) {
      setGamesListLoading(true);

      let games = gamesList
        ?.filter((game) => game.type == "regular season")
        .map(({ id }) => id);

      props.matchAnalytics({
        params: { game, games: JSON.stringify(games), odk },
        callback: (res, data) => {
          if (res === true) {
            const playerList = data?.data.items[0].rows;
            console.log(`player list is  for ${game}`, playerList[0].overall_executed);
            setPlayers(playerList);
            setTableData(playerList);
          }
          setGamesListLoading(false);
        },
      });
    }
  };

  const fetchMatchAnalyticsByDepthChart = (game, odk, blobName) => {
    props.matchAnalyticsByDepthChart({
      params: { game, odk },
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows);
          console.log("blob for depth chart is", blob);
          const mapInstances = () => {
            return instances.map((instance) => {
              const blobItem = blob[0].find(({ name }) => name === instance);
              const execution = blobItem
                ? formatNum(
                    (blobItem.total_assignment_executed /
                      blobItem.total_count) *
                      100
                  )
                : 0;
              const effort = blobItem
                ? formatNum(
                    (blobItem.total_great_effort / blobItem.total_count) * 100
                  )
                : 0;

              return {
                instance: instance,
                Execution: execution,
                Effort: effort,
              };
            });
          };
          const mappedInstances = mapInstances();
          console.log("mapped instance", mappedInstances);
          setKChartGameData(mappedInstances);
        }
      },
    });
  };

  const fetchMatchAnalyticsByDn = (game) => {
    props.matchAnalyticsByDn({
      params: { game },
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows[0]);
          console.log("blob for odk is", blob);
          const result = {
            ...blob[0],
            ...blob[1],
            ...blob[2],
          };
          setGamesData(result);
        }
      },
    });
  };

  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));
    if (list) {
      setSeasonsList(list);
    } else {
      props.seasonsList({
        params: { limit: 1000, sort: "id.desc" },
        callback: (res, data) => {
          if (res === true) {
            const result = data.data.items;
            setSeasonsList(result);
            sessionStorage.setItem("seasonsList", JSON.stringify(result));
          }
        },
      });
    }
  };

  const handleSortPlay = (type) => {
    if (type == "o" || type == "s" || type == "k" || type == "d") {
      fetchMatchAnalytics(JSON.stringify(gamesIds), type);
    } else {
      fetchMatchAnalytics(JSON.stringify(gamesIds));
    }
    setOdk(type);
  };

  useEffect(() => {
    if (gamesList?.length > 0) {
      const firstGame = gamesList?.filter(
        (game) => game.type == "regular season"
      )?.[0];

      if (firstGame) {
        setSelectedWeek(`Week ${firstGame?.week}`);
        fetchMatchAnalytics(firstGame?.id, odk);
        fetchMatchAnalyticsByDn(firstGame?.id);
        fetchMatchAnalyticsByDepthChart(JSON.stringify([firstGame?.id]), "k");
      }
    }
  }, [gamesList]);

  const loadGamesList = (seasonID = null) => {
    setGamesListLoading(true);
    const season = seasonID ?? seasonsList[0]?.id;
    let params = {
      limit: 995,
      home_team: _.get(props, "coachProfile.school.id"),
      sort: "week.asc",
      season,
    };
    let list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));

    if (list?.length > 0) {
      setGamesList(list);
      // setSelectedWeek(
      //   `Week ${
      //     week
      //       ? week
      //       : list?.filter((game) => game.type == "regular season")?.[0]?.week
      //   }`
      // );
      setGameId(game ? game : list[0].id);

      setGamesListLoading(false);
    }

    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          sessionStorage.setItem(
            `${seasonID}-gamesList`,
            JSON.stringify(_.get(data, "data.items", []))
          );
          setGamesList(_.get(data, "data.items", []));
        }
        setGamesListLoading(false);
      },
    });
  };

  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = [];

          let stock = data.data.data.positions;
          setPositionscharts(data?.data?.data);

          let offense = stock.offense.map(({ code }) => code);
          let defense = stock.defense.map(({ code }) => code);
          let special_teams = stock.special_teams.map(({ code }) => code);

          final_data = [
            {
              value: special_teams.toString(),
              title: "Special Unit (" + special_teams.toString() + ")",
            },
            {
              value: defense.toString(),
              title: "Defense Unit (" + defense.toString() + ")",
            },
            {
              value: offense.toString(),
              title: "Offense Unit (" + offense.toString() + ")",
            },
          ];

          _.chain(data)
            .get("data.data.positions")
            .pick(["offense", "defense", "special_teams"])
            .values()
            .flatten()
            .map("code")
            .value()
            .map((val) => {
              final_data.push({
                value: val,
                title: val,
              });
            });

          setPositionsList(final_data);
        }
      },
    });
  };

  function getPositionCodesByCategory(categoryName) {
    switch (categoryName) {
      case "Offense":
        return positionscharts?.player_positions?.offense[0]?.variables?.map(
          (val) => {
            return val.code;
          }
        );
      case "Defense":
        return (
          positionscharts?.player_positions?.defense[0]?.variables?.map(
            (val) => {
              return val.code;
            }
          ) || []
        );
      case "Special Teams":
        return (
          positionscharts?.player_positions?.special_teams[0]?.variables?.map(
            (val) => {
              return val.code;
            }
          ) || []
        );
      default:
        return [];
    }
  }

  const firstDownTotalEffort =
    parseInt(gamesData.total_great_effort_dn_one_o) +
    parseInt(gamesData.total_great_effort_dn_one_d) +
    parseInt(gamesData.total_great_effort_dn_one_k);

  const secondDownTotalEffort =
    parseInt(gamesData.total_great_effort_dn_two_o) +
    parseInt(gamesData.total_great_effort_dn_two_d) +
    parseInt(gamesData.total_great_effort_dn_two_k);

  const thirdDownTotalEffort =
    parseInt(gamesData.total_great_effort_dn_three_o) +
    parseInt(gamesData.total_great_effort_dn_three_d) +
    parseInt(gamesData.total_great_effort_dn_three_k);

  const fourthDownTotalEffort =
    parseInt(gamesData.total_great_effort_dn_four_o) +
    parseInt(gamesData.total_great_effort_dn_four_d) +
    parseInt(gamesData.total_great_effort_dn_four_k);

  const firstDownTotalAssignment =
    parseInt(gamesData.total_assignment_executed_dn_one_o) +
    parseInt(gamesData.total_assignment_executed_dn_one_d) +
    parseInt(+gamesData.total_assignment_executed_dn_one_k);

  const secondDownTotalAssignment =
    parseInt(gamesData.total_assignment_executed_dn_two_o) +
    parseInt(gamesData.total_assignment_executed_dn_two_d) +
    parseInt(+gamesData.total_assignment_executed_dn_two_k);

  const thirdDownTotalAssignment =
    parseInt(gamesData.total_assignment_executed_dn_two_o) +
    parseInt(gamesData.total_assignment_executed_dn_two_d) +
    parseInt(+gamesData.total_assignment_executed_dn_two_k);

  const fourthDownTotalAssignment =
    parseInt(gamesData.total_assignment_executed_dn_four_o) +
    parseInt(gamesData.total_assignment_executed_dn_four_d) +
    parseInt(gamesData.total_assignment_executed_dn_four_k);

  const shareMenu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<img src={mail} alt="image" />}
        style={{ color: "#8698A9", fontSize: "14px" }}
      >
        Send via email
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<img src={download} alt="image" />}
        style={{ color: "#8698A9", fontSize: "14px" }}
      >
        Download
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <div className="flex items-center text-[14px] text-[#000000D9] xl:text-[0.9rem] w-full">
        <div
          className="cursor-pointer flex items-center "
          onClick={() => history(-1)}
        >
          <img src={arrowLeft} className="mr-[4px] xl:mr-[0.3vw]" />
          <span>Back</span>
        </div>
        <span
          className="ml-[14px] xl:ml-[1vw] text-[#737373] cursor-pointer"
          onClick={() => history("/season-analytic-dashboard")}
        >
          {" "}
          Analytics Dashboard{" "}
          <span className="ml-[5px] xl:ml-[0.3vw]">{">"}</span>
        </span>
        <span className="ml-[5px] xl:ml-[0.3vw]">Game Analytics</span>
      </div>
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Game Analytics
      </h2>
      <div className="mt-[10px]">
        <AdvantageOnly>
          <Card
            bodyStyle={{ padding: "12px" }}
            className="w-full lg:overflow-scroll"
          >
            <div className="w-full mt-[12px] gameanalyticsweekselector flex items-center justify-between">
              <div className="flex items-center">
                {/*
              TODO
               <Select
                bordered={false}
                style={{ width: "100%" }}
                value={selectedWeek}
                dropdownMatchSelectWidth={true}
                onSelect={(value, option) => {
                 
                  setSelectedWeek(value);
                
                  setGameId(option.key);
                 
                }}>
                {gamesList
                  .slice()
                  .reverse()
                  .map(({ value, week, id }, index) => {
               
                    return (
                      <Option value={value} key={id}>
                        {`Week ${week}`}
                      </Option>
                    );
                  })}
              </Select> */}
                <Select
                  bordered={false}
                  defaultValue={seasonsList[0]?.title}
                  placeholder={seasonsList[0]?.title}
                  onChange={(value) => {
                    loadGamesList(value);
                  }}
                  className="w-[100%] mr-[10px] text-[14px]"
                >
                  {_.map(seasonsList, ({ id, title }, index) => {
                    return (
                      <Option
                        value={id}
                        key={id}
                        className="text-[14px] font-bold font-inter"
                      >
                        {title}
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  bordered={false}
                  style={{ width: "100%" }}
                  value={selectedWeek}
                  dropdownMatchSelectWidth={true}
                  onSelect={(value, option) => {
                    // if(value == 'all'){
                    //   setSelectedWeek(`Week ${gamesList[0].week}`);
                    //   setGameId(gamesList[0].id);
                    //   fetchMatchAnalytics(gamesList[0].id, odk);
                    //   fetchMatchAnalyticsByDn(gamesList[0].id);
                    //   fetchMatchAnalyticsByDepthChart(
                    //     JSON.stringify([gamesList[0].id]),
                    //     "k"
                    //   );
                    // }else {
                    setSelectedWeek(value);
                    setGameId(option?.key);
                    fetchMatchAnalytics(option?.key, odk);
                    fetchMatchAnalyticsByDn(option?.key);
                    fetchMatchAnalyticsByDepthChart(
                      JSON.stringify([option?.key]),
                      "k"
                    );
                  }}
                >
                  {gamesList
                    .filter((game) => game.type == "regular season")
                    .map(({ id, week }) => (
                      <Option key={id} value={`Week ${week}`}>
                        {`Week ${week}`}
                      </Option>
                    ))}
                </Select>
                {/* <span className='text-[14px] text-[#0F1A24] font-normal ml-[16px]'>
                Bixby Spartans <span className='text-[#A40E17]'> vs </span>{" "}
                Allen Eagles
              </span> */}
              </div>
              {/* <Dropdown
                overlay={shareMenu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <img src={printer} className="cursor-pointer w-[20px]" />
              </Dropdown> */}
              <div>
                <GotIdea />
              </div>
            </div>
            <div className="w-full flex flex-wrap justify-between mt-[32px]">
              {[
                {
                  icon: pageStar,
                  value: players.reduce(
                    (partialSum, { total_count }) =>
                      Number(partialSum) + Number(total_count),
                    0
                  ),
                  title: "Assignments Graded",
                  color: "#1790FF",
                },
                {
                  icon: clipboard,
                  value:
                    formatNum(
                      parseInt(
                        (players.reduce(
                          (partialSum, { total_executed }) =>
                            Number(partialSum) + Number(total_executed),
                          0
                        ) /
                          players.reduce(
                            (partialSum, { total_count }) =>
                              Number(partialSum) + Number(total_count),
                            0
                          )) *
                          100
                      )
                    ) + "%",
                  title: "Execution Score",
                  color: "#12CF52",
                },
                {
                  icon: emojiSatisfied,
                  value:
                    formatNum(
                      parseInt(
                        (players.reduce(
                          (partialSum, { total_great_effort }) =>
                            Number(partialSum) + Number(total_great_effort),
                          0
                        ) /
                          players.reduce(
                            (partialSum, { total_count }) =>
                              Number(partialSum) + Number(total_count),
                            0
                          )) *
                          100
                      )
                    ) + "%",
                  title: "Effort Score",
                  color: "#F96B04",
                },
                {
                  icon: triangleFlag,
                  value: players.reduce(
                    (partialSum, { total_flags }) =>
                      Number(partialSum) + Number(total_flags),
                    0
                  ),
                  title: "Penalty Flags",
                  color: "#F0BB00",
                },
                {
                  icon: thumbsDown,
                  value:
                    players.reduce(
                      (partialSum, { total_count }) =>
                        Number(partialSum) + Number(total_count),
                      0
                    ) -
                    players.reduce(
                      (partialSum, { total_executed }) =>
                        Number(partialSum) + Number(total_executed),
                      0
                    ),
                  title: "Assignments Failed",
                  color: "#A40E17",
                },
                {
                  icon: emojiSad,
                  value:
                    players.reduce(
                      (partialSum, { total_count }) =>
                        Number(partialSum) + Number(total_count),
                      0
                    ) -
                    players.reduce(
                      (partialSum, { total_great_effort }) =>
                        Number(partialSum) + Number(total_great_effort),
                      0
                    ),
                  title: "Poor Effort Plays",
                  color: "#A40E17",
                },
              ].map((item) => {
                return <Tab key={item.title} {...item} />;
              })}
            </div>
            {/* select position div */}
            <div className="w-full mt-[12px] gameanalyticspositionselector flex items-center justify-between">
              <div className="flex items-center text-[14px]">
                <span className="font-bold text-[24px] text-[#0F1A24] mr-[8px]">
                  Player Grades
                </span>
                <span className="font-bold text-[11px] text-[#0F1A24] mr-[8px]">
                  Sort Players by
                </span>
                <Select
                  bordered={false}
                  value={selectedPosition}
                  dropdownMatchSelectWidth={true}
                  onSelect={(value) => {
                    setSelectedPosition(value);
                    if (value === "All Positions") {
                      setTableData(players);
                    } else {
                      const filteredData = players.filter((item) =>
                        item.positions.includes(value)
                      );
                      setTableData(filteredData);
                    }
                  }}
                  optionLabelProp="label"
                  options={[
                    {
                      label: "All Positions",
                      value: "All Positions",
                    },
                    {
                      label: (
                        <span
                          className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer"
                          onClick={() => {
                            setSelectedPosition("Offensive unit");
                            let codes = getPositionCodesByCategory("Offense");
                            const filteredData = players.filter((item) => {
                              return item?.positions?.some((position) =>
                                codes?.includes(position)
                              );
                            });
                            setTableData(filteredData);
                          }}
                        >
                          Offensive unit
                        </span>
                      ),
                      options:
                        positionscharts?.player_positions?.offense[0]?.variables?.map(
                          ({ code, name }) => {
                            return {
                              label: (
                                <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                  <span className="font-bold">{code}</span>{" "}
                                  {`(${capitalizeString(name)})`}
                                </span>
                              ),
                              value: code,
                            };
                          }
                        ) || [],
                    },
                    {
                      label: (
                        <span
                          className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer"
                          onClick={() => {
                            setSelectedPosition("Defensive unit");
                            let codes = getPositionCodesByCategory("Defense");
                            const filteredData = players.filter((item) => {
                              return item?.positions?.some((position) =>
                                codes?.includes(position)
                              );
                            });
                            setTableData(filteredData);
                          }}
                        >
                          Defensive unit
                        </span>
                      ),
                      options:
                        positionscharts?.player_positions?.defense[0]?.variables?.map(
                          ({ code, name }) => {
                            return {
                              label: (
                                <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                  <span className="font-bold">{code}</span>{" "}
                                  {`(${capitalizeString(name)})`}
                                </span>
                              ),
                              value: code,
                            };
                          }
                        ) || [],
                    },
                    {
                      label: (
                        <span
                          className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer"
                          onClick={() => {
                            setSelectedPosition("Special Teams");
                            let codes =
                              getPositionCodesByCategory("Special Teams");
                            const filteredData = players.filter((item) => {
                              return item?.positions?.some((position) =>
                                codes?.includes(position)
                              );
                            });
                            setTableData(filteredData);
                          }}
                        >
                          Special Teams
                        </span>
                      ),
                      value: "Special Teams",
                      options:
                        positionscharts?.player_positions?.special_teams[0]?.variables?.map(
                          ({ code, name }) => {
                            return {
                              label: (
                                <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                  <span className="font-bold">{code}</span>{" "}
                                  {`(${capitalizeString(name)})`}
                                </span>
                              ),
                              value: code,
                            };
                          }
                        ) || [],
                    },
                  ]}
                ></Select>

                <span
                  className="font-normal ml-[16px] text-[12px] text-[#A40E17] underline cursor-pointer"
                  onClick={() => {
                    setSelectedPosition("All Positions");
                    setTableData(players);
                  }}
                >
                  Clear Filter
                </span>
              </div>
              {/* <Dropdown
                overlay={shareMenu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <img src={printer} className="cursor-pointer w-[20px]" />
              </Dropdown> */}
            </div>
            {/* table div */}
            <div className="w-full mt-[24px] stats-table scoring-table">
              <Table
                className="w-full"
                columns={columns(selectedWeek)}
                key={1}
                dataSource={tableData}
                loading={gamesListLoading}
                bordered={true}
                scroll={{ x: 1200 }}
              />
            </div>
            {/* score by down div */}
            <div class="flex justify-between items-center mt-[20px]">
              {/* exec */}
              <div
                className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
                style={{ border: "1px solid #EaEDF0" }}
              >
                <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                  {" "}
                  <span>Execution by Down </span>
                </div>
                <div className="flex flex-col mt-[11px] w-full p-[12px]">
                  <div className="flex items-center  w-full font-inter text-[12px]">
                    <span className="w-[100%]">
                      <span className=" w-[100%] flex items-center ">
                        <GameAnalyticChart
                          data={[
                            {
                              instace: "1st-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_one_o
                                ) /
                                  gamesData.total_grades_dn_one_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_one_d
                                ) /
                                  gamesData.total_grades_dn_one_d) *
                                  100
                              ),
                            },
                            {
                              instace: "2nd-down",
                              O: formatNum(
                                (gamesData.total_assignment_executed_dn_two_o /
                                  gamesData.total_grades_dn_two_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_two_d
                                ) /
                                  gamesData.total_grades_dn_two_d) *
                                  100
                              ),
                            },
                            {
                              instace: "3rd-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_three_o
                                ) /
                                  gamesData.total_grades_dn_three_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_three_d
                                ) /
                                  gamesData.total_grades_dn_three_d) *
                                  100
                              ),
                            },
                            {
                              instace: "4th-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_four_o
                                ) /
                                  gamesData.total_grades_dn_four_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_assignment_executed_dn_four_d
                                ) /
                                  gamesData.total_grades_dn_four_d) *
                                  100
                              ),
                            },
                          ]}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* effort */}
              <div
                className=" w-[49.5%] rounded-[8px] flex flex-col executionreportdiv"
                style={{ border: "1px solid #EaEDF0" }}
              >
                <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                  {" "}
                  <span>Effort by Down </span>
                </div>
                <div className="flex flex-col mt-[11px] w-full p-[12px]">
                  <div className="flex justify-between items-center  w-full font-inter text-[12px]">
                    <span className="w-[100%]">
                      <span className=" w-[100%] flex items-center">
                        {" "}
                        <GameAnalyticChart
                          data={[
                            {
                              instace: "1st-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_one_o
                                ) /
                                  gamesData.total_grades_dn_one_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_one_d
                                ) /
                                  gamesData.total_grades_dn_one_d) *
                                  100
                              ),
                            },
                            {
                              instace: "2nd-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_two_o
                                ) /
                                  gamesData.total_grades_dn_two_o) *
                                  100
                              ),
                              D: formatNum(
                                formatNum(
                                  (parseInt(
                                    gamesData.total_great_effort_dn_two_d
                                  ) /
                                    gamesData.total_grades_dn_two_d) *
                                    100
                                )
                              ),
                            },
                            {
                              instace: "3rd-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_three_o
                                ) /
                                  gamesData.total_grades_dn_three_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_three_d
                                ) /
                                  gamesData.total_grades_dn_three_d) *
                                  100
                              ),
                            },
                            {
                              instace: "4th-down",
                              O: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_four_o
                                ) /
                                  gamesData.total_grades_dn_four_o) *
                                  100
                              ),
                              D: formatNum(
                                (parseInt(
                                  gamesData.total_great_effort_dn_four_d
                                ) /
                                  gamesData.total_grades_dn_four_d) *
                                  100
                              ),
                            },
                          ]}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* special team chart */}
            <div className="w-full mt-[20px] mb-4 flex flex-col font-inter">
              <span className="text-[23px] font-bold">Special Teams </span>
              <div
                className=" w-[100%] rounded-[8px] flex flex-col executionreportdiv mt-[10px]"
                style={{ border: "1px solid #EaEDF0" }}
              >
                <div className="flex items-center justify-center text-[#0F1a24] text-[14px] h-[47px] p-[12px] font-[700] bg-[#EaEDF0]">
                  {" "}
                  <span>Special Teams Units </span>
                </div>
                <div className="flex flex-col mt-[11px] w-full p-[12px]">
                  <div className="flex justify-between items-center  w-full font-inter text-[12px]">
                    <span className="w-[100%]">
                      <span className=" w-[100%] flex items-center">
                        <KMultiLineChart
                          title="Execution"
                          titleTwo="Effort"
                          data={kchartGameData}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </AdvantageOnly>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  MatchAnalytics: (details) =>
    dispatch(global.redux.action.games.analytics(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  matchAnalytics: (details) =>
    dispatch(global.redux.action.grades.matchAnalytics(details)),
  matchAnalyticsByDn: (details) =>
    dispatch(global.redux.action.grades.matchAnalyticsByDn(details)),
  gridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  matchAnalyticsByDepthChart: (details) =>
    dispatch(global.redux.action.grades.matchAnalyticsByDepthChart(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameAnalyticsDashboard);
