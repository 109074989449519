import { 
    Spin,
  } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const IndicatorMax = ({ data, indicatorMaxLoading, gamesList }) => {
    const [ rate, setRate ] = useState({})
 

    const updateWinRateData = () => {
        let win = 0;
        let loss =  0;
        let total = 0;

        gamesList.filter((game) => game.type == "regular season").map((games) =>{
            if (games.home_team_score){
            total +=1;
            const score = games.home_team_score - games.away_team_score
             
            if (score != 0) score <= 0 ? loss += 1 : win += 1
            }
        })

        setRate({ win, loss, total })
    }
    useEffect(
        updateWinRateData
    , [gamesList])

    // item.home_team_score - item.away_team_score <= 0 ? "L:" : "W:"


    return  (
        <div className="flex justify-end">
            <div className="flex datas-center font-bold">
                <div className="flex flex-col mr-6 text-center">
                    { indicatorMaxLoading ? <Spin indicator={<LoadingOutlined spin />} /> : '' }
                </div>
                <div className="flex flex-col mr-6 text-center">
                    <span className="text-[8px] text-[#526B84]">Win/Loss Record</span>
                    <span className="text-[18px] text-[#1790FF]">{rate?.win} - {rate?.loss}</span>
                </div>
                <div className="flex flex-col mr-6 text-center">
                    <span className="text-[8px] text-[#526B84]">Winning %</span>
                    <span className="text-[18px] text-[#ED780B]">{ (((rate?.win / (rate?.total)) || 0) * 100).toFixed() }%</span>
                </div>
                <div className="flex flex-col mr-6 text-center">
                    <span className="text-[8px] text-[#526B84]">Overall Execution</span>
                    <span className="text-[18px] text-[#00B21E]">{(((Number(data?.total_assignment_executed || 0) / Number(data?.total_grades || 0)) * 100) || 0)?.toFixed()}% <br /></span>
                </div>
                <div className="flex flex-col text-center mr-2">
                    <span className="text-[8px] text-[#526B84]">Overall Effort</span>
                    <span className="text-[18px] text-[#00B21E]">{(((Number(data?.total_great_effort || 0) / Number(data?.total_grades || 0)) * 100) || 0)?.toFixed()}% <br /></span>
                </div>
                <div className="flex flex-col text-center mr-2">
                    <span className="text-[8px] text-[#526B84] mb-1">Player Assignments</span>
                    <div className=" bg-green-500 text-white rounded-full text-[10px] flex datas-center justify-center  p-1 px-2">                    
                        {data?.total_marked_as_read} of {data?.total_grades} ({(((Number(data?.total_marked_as_read || 0)/ Number(data?.total_grades || 0)) || 0) * 100).toFixed()}%) 
                        Marked As Read
                    </div>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-[8px] text-[#526B84] mb-1"> Player Responses</span>
                    <div className=" bg-blue-500 text-white rounded-full text-[10px] flex datas-center justify-center  p-1 px-2">
                        {data?.total_player_responses} Comments Submitted
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndicatorMax 