import actionType from '../actionTypes';
import _ from 'lodash';
const reducerName = actionType.reduxUpdatePlaysState;

export default {

    create: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/plays/create`
                },
                actionType: actionType.plays.create,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
        update: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/plays/update/${_.get(componentRequest, 'params.id')}`
                },
                actionType: actionType.plays.update,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    uploadPlaylist: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/plays/upload-playlist`
                },
                actionType: actionType.plays.uploadPlaylist,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/plays/list`
                },
                actionType: actionType.plays.list,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    getCommentsForPlay: (componentRequest) => {
        console.log('componentRequest.params.id',componentRequest.params.id)
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/collaboration/play/${componentRequest.params.id}`
                },
                actionType: actionType.plays.getCommentsForPlay,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    addComment: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/warboard/collaboration/add`
                },
                actionType: actionType.plays.addComment,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    editComment: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'put',
                    url: `/warboard/collaboration/edit`
                },
                actionType: actionType.plays.editComment,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

    archiveComment: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'put',
                    url: `/warboard/collaboration/archive`
                },
                actionType: actionType.plays.archiveComment,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

};