import React, { useEffect, useState } from "react";
import { Card, Select, Table } from "antd";
import addIcon from "../../assets/img/plus.svg";
import { connect } from "react-redux";
import _ from "lodash";
import NewPlanModal from "./NewPlanModal";
import SmallButtonUI from "../../components/SmallButton";
import ButtonUI from "../../components/ButtonUI";

const { Option } = Select;
function Plans(props) {
  const [plansList, setPlansList] = useState([]);
  const [plansListLoading, setPlansListLoading] = useState(false);
  const [newPlan, setNewPlan] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [plan, setPlan] = useState({});

  const getPlansList = () => {
    setPlansListLoading(true);
    props.plansList({
      params: {},
      callback: (res, data) => {
        if (res === true) {
          console.log("data from plansList", data?.data);
          setPlansList(data?.data || []);
        }
        setPlansListLoading(false);
      },
    });
  };

  const handleCreatePlan = (name, description, price, features) => {
    props.createPlan({
      body: {
        name,
        description,
        price,
        features,
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from createPlan", data?.data);
          getPlansList();
        }
        setNewPlan(false);
      },
    });
  };

  const handleUpdatePlan = (name, description, price, features, planId) => {
    props.modifyPlan({
      body: {
        name,
        description,
        price,
        features,
        planId,
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from updatePlan", data?.data);
          getPlansList();
        }
        setEditPlan(false);
      },
    });
  };

  useEffect(() => {
    getPlansList();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      className: "font-medium",
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      align: "center",
      className: "font-medium",
      width: "20%",
    },
    {
      title: "Features Key",
      dataIndex: "features",
      key: "featuresKey",
      align: "center",
      className: "font-bold",
      width: "20%",
      render: (text) => {
        return (
          <>
            <div>
              {text.split(",").map((i, k) => (
                <div
                  key={k}
                  className="text-[#2E4D68] w-[60%] bg-[#2E4D68] bg-opacity-10 mt-2 py-2 mx-auto rounded-lg text-[10px] xl:text-[0.7vw] font-bold "
                >
                  {i}
                </div>
              ))}
            </div>
          </>
        );
      },
    },

    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: "20%",
      render: (text) => (
        <div className="flex gap-1 flex-col items-center justify-center">
          <SmallButtonUI
            text={text === 1 ? "Active" : "Cancel"}
            background={text === 1 ? "#00B21E" : "#2E4D68"}
            textColor={text === 1 ? "#00B21E" : "#2E4D68"}
            opacity={10}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      align: "center",
      width: "20%",
      render: (text, record) => (
        <>
          <div className="flex gap-1 flex-col items-center justify-center">
            <SmallButtonUI
              text="Edit"
              background={"#1A91FF"}
              buttonFunction={() => {
                setPlan(record);
                setEditPlan(true);
                console.log("record", record);
                console.log("plan", plan);
              }}
            />
          </div>
          <NewPlanModal
            visible={editPlan}
            setVisible={setEditPlan}
            plan={record}
            handleCreatePlan={handleUpdatePlan}
            title="Edit Plan"
          />
        </>
      ),
    },
  ];
  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Plans
      </h2>
      <Card bodyStyle={{ padding: "24px" }}>
        <div className="flex items-center justify-between">
          <div className="w-[200px] h-[43px] ml-[4px] border-[1px] border-solid border-[#EAEDF0] font-inter flex items-center justify-center xl:w-[20%] rounded-md">
            <Select
              bordered={false}
              placeholder={"Status"}
              // onChange={(value) => {
              //   setSeasonID(value);
              //   loadGamesList(value);
              // }}
              className="w-[100%] text-[14px]"
            >
              {/* {_.map(seasonsList, ({ id, title }, index) => {
                      return (
                        <Option
                          value={id}
                          key={id}
                          className="text-[14px] font-bold font-inter"
                        >
                          {title}
                        </Option>
                      );
                    })} */}
            </Select>
          </div>
          <ButtonUI
            text="New Plan"
            rightIcon={addIcon}
            background={"#1A91FF"}
            buttonFunction={() => setNewPlan(true)}
            width="120px"
            xlWidth="18%"
          />
        </div>
        <div className="mt-[20px]">
          <Table
            columns={columns}
            dataSource={plansList}
            bordered
            style={{ fontSize: "10px", color: "#0F1A24" }}
            loading={plansListLoading}
          />
        </div>
        <NewPlanModal
          visible={newPlan}
          setVisible={setNewPlan}
          handleCreatePlan={handleCreatePlan}
        />
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  plansList: (details) =>
    dispatch(global.redux.action.super_admin.plansList(details)),
  createPlan: (componentRequest) =>
    dispatch(global.redux.action.super_admin.createPlan(componentRequest)),
  modifyPlan: (componentRequest) =>
    dispatch(global.redux.action.super_admin.modifyPlan(componentRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
