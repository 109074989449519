import React, { useEffect, useState } from "react";
import { Modal, Checkbox , Spin} from "antd";
import SeasonsListSelect from "../../../components/seasonsListSelect";
import ExportToCSVButton from "../ExportToCSVButton";
import { capitalizeString } from "../../../reusable-func";

function ExportCsvModal({
  visible,
  setVisible,
  dataSource,
  columns,
  setSeasonsList,
  setSeasonID,
  seasonID,
  seasonsLists,
  game,
  setGame,
  loading
}) {
  const [modalWidth, setModalWidth] = useState(504);
  const [gamesList, setGamesList] = useState([]);

  const handleSelectAllGames = (event) => {
    if (event.target.checked) {
      const allGameIds = game?.map((game) => game.id);
      setGamesList(allGameIds);
    } else {
      setGamesList([]);
    }
  };

  const handleGameCheckboxChange = (gameId) => {
    // setGamesList([gameId])
    const gameIndex = gamesList.indexOf(gameId);
    let ids;
    if (gameIndex === -1) {
      ids = [...gamesList, gameId];
      setGamesList(ids);
    } else {
      const updatedGamesList = [...gamesList];
      updatedGamesList.splice(gameIndex, 1);
      ids = updatedGamesList
      setGamesList(ids);
    }

    console.log('gamesList --101',gamesList)
    setGame(ids);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(304);
    } else {
      setModalWidth(504);
    }
  }, [window.innerWidth]);
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={modalWidth}
      title="Export file for Maxpreps"
      closable={true}
      className="hide-in-print"
    >
      <div className="flex flex-col mt-4">
        <SeasonsListSelect
          setSeasonsList={setSeasonsList}
          onChange={(e) => {
            setSeasonID(e);
          }}
          seasonsLists={seasonsLists}
          defaultValue={seasonID}
        />
        <div className="flex flex-col text-black gap-4 mt-4">
          <span className="text-black text-sm font-bold mb-2">
            Select game to export
          </span>
          <div className="flex flex-col item-center font-inter mb-2">
            {/* <Checkbox onChange={handleSelectAllGames} /> */}
            <span className="text-black text-sm ml-2 font-semibold">
              {" "}
              Selecting a game below will create an export that includes all stats for that particular game including offense, defense, special teams and scoring
            </span>

            <span className="text-[#ff0000] ml-2 mt-4">
            Please ensure all players have updated jersey number and that there are no duplicates before exporting or importing stats into MaxPreps
            </span>
          </div>
          {game?.map((game, index) => {
            if (game.type == "regular season" || game.type == "playoffs")
              return (
                <div className="flex item-center font-inter" key={index}>
                  <Checkbox
                    checked={gamesList?.includes(game.id)} // Check if the game is in the gamesList
                    onChange={() => handleGameCheckboxChange(game.id)}
                  />
                  <span className="text-black text-sm ml-2">
                    {" "}
                    {game?.week}
                  </span>
                  <span className="text-black text-sm ml-6 font-medium">
                    {" "}
                    {capitalizeString(game?.title)}
                  </span>
                </div>
              );
          })}
        </div>
        {console.log('dataSource',dataSource)}
        <div className="mt-4  w-[50%]   flex flex-col">
       <ExportToCSVButton
            dataSource={dataSource}
            loading={loading}
            columns={columns}
            disableDownload={gamesList.length == 0}
           
          /> 
        </div>
      </div>
    </Modal>
  );
}

export default ExportCsvModal;
