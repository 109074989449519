import React, { useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import backArrow from "../../assets/img/arrow-leftblue.svg";
import continueArrow from "../../assets/img/arrow-right.svg";

function SchoolDetails(props) {
  useEffect(() => {
    props.form.setFieldsValue({ state: props?.regDetails?.state });
    props.form.setFieldsValue({
      school_district: props?.regDetails?.school_district,
    });
    props.form.setFieldsValue({ num_coaches: props?.regDetails?.num_coaches });
  }, [props?.regDetails?.state, props.form]);

  const handleInputChange = (name, value) => {
    props.setRegDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    sessionStorage.setItem(
      "regDetails",
      JSON.stringify({
        ...props.regDetails,
        [name]: value,
      })
    );
  };

  return (
    <div className="w-[100%]">
      <div className="w-[100%]">
        <Form.Item
          name="school_district"
          rules={[
            {
              required: true,
              message: "Please enter your school district",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              School District
            </span>
            <Input
              className="py-2"
              placeholder="Enter your school district"
              defaultValue={props?.regDetails?.school_district?.toString()}
              onChange={(e) =>
                handleInputChange("school_district", e.target.value)
              }
            />
          </div>
        </Form.Item>

        <Form.Item
          name="num_coaches"
          rules={[
            {
              required: true,
              message:
                "Please enter the number of varsity coaches on the staff",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              # of Varsity Coaches on the Staff
            </span>
            <Input
              className="py-2"
              placeholder="Enter the number of varsity coaches"
              defaultValue={props?.regDetails?.num_coaches?.toString()}
              onChange={(e) => handleInputChange("num_coaches", e.target.value)}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="state"
          rules={[
            {
              required: true,
              message: "Please select a state",
            },
          ]}
          className="w-[60%]"
        >
          <div>
            <span className="text-[#0F1A24] mb-3 text-[14px] 2xl:text-[0.97vw] font-medium ">
              State
            </span>
            <div className="w-full border-[1px] py-2 rounded-lg bg-white">
              <Select
                bordered={false}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ border: "none" }}
                placeholder="Select a state"
                onChange={(value) => {
                  sessionStorage.setItem(
                    "regDetails",
                    JSON.stringify({
                      ...props.regDetails,
                      state: value,
                    })
                  );
                  props.setRegDetails((prev) => {
                    return {
                      ...prev,
                      state: value,
                    };
                  });
                }}
                defaultValue={props.regDetails.state?.toString()}
              >
                {[
                  "Alabama",
                  "Alaska",
                  "Arizona",
                  "Alabama",
                  "Alaska",
                  "Arizona",
                  "Arkansas",
                  "California",
                  "Colorado",
                  "Connecticut",
                  "Delaware",
                  "Florida",
                  "Georgia",
                  "Hawaii",
                  "Idaho",
                  "Illinois",
                  "Indiana",
                  "Iowa",
                  "Kansas",
                  "Kentucky",
                  "Louisiana",
                  "Maine",
                  "Maryland",
                  "Massachusetts",
                  "Michigan",
                  "Minnesota",
                  "Mississippi",
                  "Missouri",
                  "Montana",
                  "Nebraska",
                  "Nevada",
                  "New Hampshire",
                  "New Jersey",
                  "New Mexico",
                  "New York",
                  "North Carolina",
                  "North Dakota",
                  "Ohio",
                  "Oklahoma",
                  "Oregon",
                  "Pennsylvania",
                  "Rhode Island",
                  "South Carolina",
                  "South Dakota",
                  "Tennessee",
                  "Texas",
                  "Utah",
                  "Vermont",
                  "Virginia",
                  "Washington",
                  "West Virginia",
                  "Wisconsin",
                  "Wyoming",
                ].map((state, index) => (
                  <Select.Option key={index} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </Form.Item>
        {/* <Form.Item className="xl:absolute xl:right-0 xl:left-0 xl:bottom-2">
          <div className={`flex justify-between items-center w-full mt-8`}>
            <button
              className="px-6 py-2 flex  items-center justify-center gap-2 text-[#1A91FF] text-[14px] bg-white border disabled:opacity-50"
              onClick={() => {
                props.setPage(props.page - 1);
              }}
            >
              <img src={backArrow} />
              <span>Back</span>
            </button>
            <button
              className={`px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white `}
              onClick={() => {}}
              type="submit"
            >
              <span>Continue</span>
              <img src={continueArrow} />
            </button>
          </div>
        </Form.Item> */}
      </div>
    </div>
  );
}

export default SchoolDetails;
