import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Collapse,
  Checkbox,
  Divider,
  Breadcrumb,
  Row,
  Skeleton,
  Result,
  Col,
  Form,
  Progress,
  Tooltip,
  Spin,
  Card,
  Space,
  Typography,
} from "antd";
import {
  UserOutlined,
  LoadingOutlined,
  CloseOutlined,
  CheckOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CheckCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import {
  CommentOutlined,
  LikeOutlined,
  DislikeOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import Collaboration from "../plays-atheletes/Collaboration";
import evalimg from "../../../assets/img/clipboard-check.svg";

import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import "./coaches-note.css";
import PrintModal from "./PrintModal";
import bell from "../../../assets/img/bell-notification.svg";
import angel from "../../../assets/img/angel 2.svg";
import clip from "../../../assets/img/navbar/eval.svg";
import AngelPopUp from "../../../components/Modals/AngelPopUp";
import { useNavigate } from "react-router-dom";
import readIcon from "../../../assets/img/double-checkblue.svg";
const { Text } = Typography;
const Option = Select.Option;
const { Panel } = Collapse;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const renderContent = (value, row, column_name = "") => {
  const obj = {
    children: value,
    props: {},
  };
  if (_.get(row, "partition", false)) {
    if (_.eq(column_name, "name")) {
      obj.props.colSpan = 10;
      obj.children = <div className="PlaysNameTitle">{value}</div>;
    } else {
      obj.props.colSpan = 0;
    }
  }
  return obj;
};

const CoachesNoteScreen = (props) => {
  const [title, setTitle] = useState("");
  const [gradesListLoading, setGradesListLoading] = useState(false);
  const [gradesList, setGradesList] = useState({});
  const [positionsList, setPositionsList] = useState([]);
  const [gradesData, setGradesData] = useState([]);
  const [playCount, setPlayCount] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [print, setPrint] = useState(false);
  const history = useNavigate();
  const [positionscharts, setPositionscharts] = useState([]);
  const [filterValue, setFilterValue] = useState("QB");

  const params = new URLSearchParams(window.location.search);

  //  function to print
  const handlePrint = async () => {
    window.print();
  };

  const closePrintModal = () => {
    setPrint(false);
  };

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const columnsFeedback = [
    {
      title: "Player Name",
      dataIndex: "first_name",
      width: "16%",
      align: "center",
      render: (value, row) => `${row.first_name} ${row.last_name}`,
    },

    {
      title: "Position",
      dataIndex: "positions",
      width: "10%",
      align: "center",
      render: (value, row) => value.toString(),
    },

    {
      title: <div style={{ textAlign: "center" }}>Direct Feedback</div>,
      dataIndex: "comments",
      width: "20%",
      render: (value, row) => {
        return (
          <div className="w-full flex flex-col">
            {row.positive_feedback && (
              <div className="flex flex-col items-center gap-1  ">
                <img src={evalimg} className="w-[15px]" />
                <Tooltip title="Feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.positive_feedback}
                  </span>
                </Tooltip>
              </div>
            )}
            {row.negative_feedback && (
              <div className="flex items-center gap-1 text-red-500 animate-blink mt-1">
                <DislikeOutlined />
                <Tooltip title="Negative feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.negative_feedback}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Player Response</div>,
      dataIndex: "comment",
      render: (comment, row) => {
        const formattedDate = moment(row?.comment_date).format("M/D/YY"); // Format the date
        const formattedTime = moment(row?.comment_date).format("h:mma");
        return (
          <div className="w-full flex flex-col">
            {comment && (
              <div className="justify-between flex flex-col">
                <div className="flex flex-col items-left gap-1">
                  <CommentOutlined align="center" />
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row?.comment}
                  </span>
                </div>
                {row?.comment_date && (
                  <div className="flex mx-auto items-center gap-2 text-[#1A91FF] animate-pulse mt-1">
                    <CalendarOutlined align="center" />
                    <Tooltip title="Date of comment">
                      <span className="text-center">{formattedDate}</span>
                    </Tooltip>
                    <ClockCircleOutlined align="center" />
                    <Tooltip title="Time of comment">
                      <span className="text-center">{formattedTime}</span>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "seen",
      align: "center",
      width: "6%",
      render: (seen, row) => {
        return (
          <>
            {seen == 2 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-bounce">
                <CheckCircleOutlined />
                <Tooltip title="Congratulations, it's been read!">
                  <span>Read</span>
                </Tooltip>
              </div>
            )}
            {seen == 1 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-pulse">
                <EyeOutlined />
                <Tooltip title="It's seen but not fully digested!">
                  <span>Seen</span>
                </Tooltip>
              </div>
            )}
            {!seen && (
              <div className="flex items-center w-full gap-1 text-grey animate-blink">
                <EyeInvisibleOutlined />
                <Tooltip title="Still flying under the radar.">
                  <span>Unseen</span>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ];

  const gradingColumn = [
    {
      title: "Player Name",
      dataIndex: "first_name",
      width: "16%",
      align: "center",
      render: (value, row) => `${row.first_name} ${row.last_name}`,
    },

    {
      title: "Position",
      dataIndex: "positions",
      width: "10%",
      align: "center",
      render: (value, row) => value.toString(),
    },
    {
      title: "Execution",
      dataIndex: "assignment_executed",
      width: "10%",
      align: "center",
      render: (value, row) =>
        renderContent(
          _.chain(value).toInteger().eq(1).value() ? (
            <div className="bluebackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ) : (
            <div className="redBackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ),
          row
        ),
    },
    {
      title: "Effort",
      dataIndex: "great_effort",
      width: "10%",
      align: "center",
      render: (value, row) =>
      renderContent(
        _.chain(value).toInteger().eq(1).value() ? (
          <div className="bluebackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ) : (
          <div className="redBackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ),
        row
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Direct Feedback</div>,
      dataIndex: "comments",
      width: "20%",
      render: (value, row) => {
        return (
          <div className="w-full flex flex-col">
            {row.positive_feedback && (
              <div className="flex flex-col items-center gap-1  ">
                <img src={evalimg} className="w-[15px]" />
                <Tooltip title="Feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.positive_feedback}.
                  </span>
                </Tooltip>
              </div>
            )}
            {row.negative_feedback && (
              <div className="flex items-center gap-1 text-red-500 animate-blink mt-1">
                <DislikeOutlined />
                <Tooltip title="Negative feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.negative_feedback}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Player Response</div>,
      dataIndex: "comment",
      render: (comment, row) => {
        const formattedDate = moment(row?.comment_date).format("M/D/YY"); // Format the date
        const formattedTime = moment(row?.comment_date).format("h:mma");
        return (
          <div className="w-full flex flex-col">
            {comment && (
              <div className="justify-between flex flex-col">
                <div className="flex flex-col items-left gap-1">
                  <CommentOutlined align="center" />
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row?.comment}
                  </span>
                </div>
                {row?.comment_date && (
                  <div className="flex mx-auto items-center gap-2 text-[#1A91FF] animate-pulse mt-1">
                    <CalendarOutlined align="center" />
                    <Tooltip title="Date of comment">
                      <span className="text-center">{formattedDate}</span>
                    </Tooltip>
                    <ClockCircleOutlined align="center" />
                    <Tooltip title="Time of comment">
                      <span className="text-center">{formattedTime}</span>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "seen",
      align: "center",
      width: "6%",
      render: (seen, row) => {
        return (
          <>
            {seen == 2 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-bounce">
                <CheckCircleOutlined />
                <Tooltip title="Congratulations, it's been read!">
                  <span>Read</span>
                </Tooltip>
              </div>
            )}
            {seen == 1 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-pulse">
                <EyeOutlined />
                <Tooltip title="It's seen but not fully digested!">
                  <span>Seen</span>
                </Tooltip>
              </div>
            )}
            {!seen && (
              <div className="flex items-center w-full gap-1 text-grey animate-blink">
                <EyeInvisibleOutlined />
                <Tooltip title="Still flying under the radar.">
                  <span>Unseen</span>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ];

  const columns = [
    {
      title: "Player Name",
      dataIndex: "first_name",
      width: "16%",
      align: "center",
      render: (value, row) => `${row.first_name} ${row.last_name}`,
    },

    {
      title: "Position",
      dataIndex: "positions",
      width: "10%",
      align: "center",
      render: (value, row) => value.toString(),
    },
    {
      title: "Execution",
      dataIndex: "assignment_executed",
      width: "6%",
      align: "center",
      render: (value, row) =>
        renderContent(
          _.chain(value).toInteger().eq(1).value() ? (
            <div className="bluebackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ) : (
            <div className="redBackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ),
          row
        ),
    },
    {
      title: "Effort",
      dataIndex: "great_effort",
      width: "6%",
      align: "center",
      render: (value, row) =>
        renderContent(
          _.chain(value).toInteger().eq(1).value() ? (
            <div className="bluebackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ) : (
            <div className="redBackgroundchecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ),
          row
        ),
    },

    {
      title: "Flagged",
      dataIndex: "penalties",
      width: "6%",
      align: "center",
      render: (value, row) =>
        renderContent(
          _.chain(value).toInteger().gt(0).value() ? (
            <div className="yellowBackgroundPChecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ) : (
            <div className="greyBackgroundPChecks">
              <Checkbox checked disabled></Checkbox>
            </div>
          ),
          row
        ),
    },
    {
      title: "Dn",
      dataIndex: "playDn",
      align: "center",
      width: "6%",
    },
    {
      title: "Dist",
      dataIndex: "playDist",
      align: "center",
      width: "6%",
    },
    {
      title: "Result",
      dataIndex: "playResult",
      align: "center",
      width: "6%",
    },

    {
      title: <div style={{ textAlign: "center" }}>Direct Feedback</div>,
      dataIndex: "comments",
      width: "20%",
      render: (value, row) => {
        return (
          <div className="w-full flex flex-col max-w-[100px]">
            {row.positive_feedback && (
              <div className="flex flex-col items-center gap-1   ">
                <img src={evalimg} className="w-[15px]" />
                <Tooltip title="Feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.positive_feedback}.
                  </span>
                </Tooltip>
              </div>
            )}
            {row.negative_feedback && (
              <div className="flex items-center gap-1 text-red-500 animate-blink mt-1">
                <DislikeOutlined />
                <Tooltip title="Negative feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.negative_feedback}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Player Response</div>,
      dataIndex: "comment",
      render: (comment, row) => {
        const formattedDate = moment(row?.comment_date).format("M/D/YY"); // Format the date
        const formattedTime = moment(row?.comment_date).format("h:mma");
        return (
          <div className="w-full flex flex-col">
            {comment && (
              <div className="justify-between flex flex-wrap flex-col">
                <div className="flex flex-col flex-wrap items-left gap-1">
                  <CommentOutlined align="center" />
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row?.comment}
                  </span>
                </div>
                {row?.comment_date && (
                  <div className="flex mx-auto items-center gap-2 text-[#1A91FF] animate-pulse mt-1">
                    <CalendarOutlined align="center" />
                    <Tooltip title="Date of comment">
                      <span className="text-center">{formattedDate}</span>
                    </Tooltip>
                    <ClockCircleOutlined align="center" />
                    <Tooltip title="Time of comment">
                      <span className="text-center">{formattedTime}</span>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "seen",
      align: "center",
      width: "6%",
      render: (seen, row) => {
        return (
          <>
            {seen == 2 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-bounce">
                <CheckCircleOutlined />
                <Tooltip title="Congratulations, it's been read!">
                  <span>Read</span>
                </Tooltip>
              </div>
            )}
            {seen == 1 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-pulse">
                <EyeOutlined />
                <Tooltip title="It's seen but not fully digested!">
                  <span>Seen</span>
                </Tooltip>
              </div>
            )}
            {!seen && (
              <div className="flex items-center w-full gap-1 text-grey animate-blink">
                <EyeInvisibleOutlined />
                <Tooltip title="Still flying under the radar.">
                  <span>Unseen</span>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ];
  const calculateParts = (value) => {
    let parts = [];

    let _sum = value;
    const _valueBreakDown = (_sum, part) => {
      if (_sum >= part) {
        parts.push(part);
        _sum = _sum - part;
      }
      return _sum;
    };
    _sum = _valueBreakDown(_sum, 8);
    _sum = _valueBreakDown(_sum, 4);
    _sum = _valueBreakDown(_sum, 2);
    _sum = _valueBreakDown(_sum, 1);

    return parts;
  };
  const columnsQB = [
    {
      title: "Player Name",
      dataIndex: "first_name",
      width: "11%",
      align: "center",
      render: (value, row) => {
        return (
          <div className="text-[#0F1A24] text-[10px] font-inter">
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },

    {
      title: "Position",
      dataIndex: "positions",
      width: "4%",
      align: "center",
      render: (value, row) => (
        <div className="text-[#0F1A24] text-[10px] font-inter">
          {value.toString()}
        </div>
      ),
    },
    {
      title: "Execution",
      dataIndex: "assignment_executed",
      width: "8%",
      align: "center",
      render: (value, row) => {
        return (
          <div className="flex flex-col gap-1">
            {" "}
            {value ? (
              <span className="text-[#00B21E] text-[10px]">Good Job</span>
            ) : (
              <span className="text-[#A40E17] text-[10px]">
                Needs Attention
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Effort",
      dataIndex: "great_effort",
      width: "8%",
      align: "center",
      render: (value, row) => {
        return (
          <div className="flex flex-col gap-1">
            {" "}
            {value ? (
              <span className="text-[#00B21E] text-[10px]">Good Job</span>
            ) : (
              <span className="text-[#A40E17] text-[10px]">
                Needs Attention
              </span>
            )}
          </div>
        );
      },
    },

    {
      title: "Flagged",
      dataIndex: "penalties",
      width: "8%",
      align: "center",
      render: (value, row) => {
        return (
          <div className="flex flex-col gap-1">
            {" "}
            {!value ? (
              <span className="text-[#00B21E] text-[10px]">No Flag</span>
            ) : (
              <span className="text-[#A40E17] text-[10px]">Flagged</span>
            )}
          </div>
        );
      },
    },

    {
      title: "Discipline",
      dataIndex: "playDn",
      dataIndex: "missed_pat",

      key: "discipline",
      align: "center",
      width: "12%",
      render: (value, row) => {
        const parts = calculateParts(value);
        return (
          <div className="flex flex-col gap-1">
            {parts.includes(1) && (
              <span className="text-[#A40E17] text-[10px]">Check Footwork</span>
            )}
            {parts.includes(2) && (
              <span className="text-[#A40E17] text-[10px]">Check Eyes</span>
            )}
            {parts.includes(4) && (
              <span className="text-[#A40E17] text-[10px]">Check Read</span>
            )}
            {parts.includes(8) && (
              <span className="text-[#A40E17] text-[10px]">Check Timing</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Completion",
      dataIndex: "completions",
      align: "center",
      key: "completions",
      width: "8%",
      render: (value, row) => {
        const parts = calculateParts(value);

        return (
          <div className="flex flex-col gap-1">
            {parts.includes(1) && (
              <span className="text-[#A40E17] text-[10px]">Incomplete</span>
            )}
            {parts.includes(2) && (
              <span className="text-[#A40E17] text-[10px]">Bad ball</span>
            )}
            {parts.includes(4) && (
              <span className="text-[#00B21E] text-[10px]">Complete</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Carry",
      dataIndex: "playDist",
      align: "center",
      width: "8%",
      render: (value, row) => {
        return (
          <div className="flex flex-col gap-1">
            {value == "1" ? (
              <span className="text-[#00B21E] text-[10px]">Succeed</span>
            ) : (
              ""
            )}

            {value == "0" || value == "2" ? (
              <span className="text-[#A40E17] text-[10px]">Fumble</span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "TD",
      dataIndex: "td",
      align: "center",
      width: "8%",
      render: (value, row) => {
        return (
          <div className="flex flex-col gap-1">
            {" "}
            {value == "1" && (
              <span className="text-[#00B21E] text-[10px]">TD</span>
            )}
          </div>
        );
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>Direct Feedback</div>,
      dataIndex: "comments",
      width: "20%",
      render: (value, row) => {
        return (
          <div className="w-full flex flex-col">
            {row.positive_feedback && (
              <div className="flex flex-col items-center gap-1  ">
                <img src={evalimg} className="w-[15px]" />
                <Tooltip title="Feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.positive_feedback}.
                  </span>
                </Tooltip>
              </div>
            )}
            {row.negative_feedback && (
              <div className="flex items-center gap-1 text-red-500 animate-blink mt-1">
                <DislikeOutlined />
                <Tooltip title="Negative feedback from the coach">
                  <span
                    className="text-center  max-w-[100px]"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row.negative_feedback}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Player Response</div>,
      dataIndex: "comment",
      render: (comment, row) => {
        const formattedDate = moment(row?.comment_date).format("M/D/YY"); // Format the date
        const formattedTime = moment(row?.comment_date).format("h:mma");
        return (
          <div className="w-full flex flex-col">
            {comment && (
              <div className="justify-between flex flex-col">
                <div className="flex flex-col items-left gap-1">
                  <CommentOutlined align="center" />
                  <span
                    className="text-center"
                    style={{ wordBreak: "break-word" }}
                  >
                    {row?.comment}
                  </span>
                </div>
                {row?.comment_date && (
                  <div className="flex mx-auto items-center gap-2 text-[#1A91FF] animate-pulse mt-1">
                    <CalendarOutlined align="center" />
                    <Tooltip title="Date of comment">
                      <span className="text-center">{formattedDate}</span>
                    </Tooltip>
                    <ClockCircleOutlined align="center" />
                    <Tooltip title="Time of comment">
                      <span className="text-center">{formattedTime}</span>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "seen",
      align: "center",
      width: "6%",
      render: (seen, row) => {
        return (
          <>
            {seen == 2 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-bounce">
                <CheckCircleOutlined />
                <Tooltip title="Congratulations, it's been read!">
                  <span>Read</span>
                </Tooltip>
              </div>
            )}
            {seen == 1 && (
              <div className="flex items-center w-full gap-1 text-[#1A91FF] animate-pulse">
                <EyeOutlined />
                <Tooltip title="It's seen but not fully digested!">
                  <span>Seen</span>
                </Tooltip>
              </div>
            )}
            {!seen && (
              <div className="flex items-center w-full gap-1 text-grey animate-blink">
                <EyeInvisibleOutlined />
                <Tooltip title="Still flying under the radar.">
                  <span>Unseen</span>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ];

  useEffect(() => {
    fetchGradesList();
    gridironDetails();
    fetchListFeedback();
  }, []);

  const fetchListFeedback = () => {
    const paramss = new URLSearchParams(window.location.search);
    let game_id = paramss.get("game_id");
    let params = {
      game_id: game_id,
    };
    props.listFeedback({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setFeedbackList(data.data);
        }
      },
    });
  };
  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = [];
          setPositionscharts(data?.data?.data);

          let stock = data.data.data.positions;

          let offense = stock.offense.map(({ code }) => code);
          let defense = stock.defense.map(({ code }) => code);
          let special_teams = stock.special_teams.map(({ code }) => code);

          final_data = [
            {
              value: special_teams.toString(),
              title: "Special Unit (" + special_teams.toString() + ")",
            },
            {
              value: defense.toString(),
              title: "Defense Unit (" + defense.toString() + ")",
            },
            {
              value: offense.toString(),
              title: "Offense Unit (" + offense.toString() + ")",
            },
          ];

          _.chain(data)
            .get("data.data.positions")
            .pick(["offense", "defense", "special_teams"])
            .values()
            .flatten()
            .map("code")
            .value()
            .map((val) => {
              final_data.push({
                value: val,
                title: val,
              });
            });

          setPositionsList(final_data);
        }
      },
    });
  };

  const fetchGradesList = () => {
    let queryParams = {
      // positions: [params.get("position_code") || "QB"],
      position: params.get("position_code") || "QB",
      game: params.get("game_id"),
      limit: 2000,
      status: "1,2",
      page: 1,
    };
    setGradesListLoading(true);
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items);

          processGradeListItems(data?.data?.items);
        }
        setGradesListLoading(false);
      },
    });
  };

  const processGradeListItems = (items) => {
    let playsCount = {};
    let gradesMap = {};

    items.forEach((item) => {
      if (!playsCount[item.playNumber]) {
        playsCount[item.playNumber] = 0;
      }
      playsCount[item.playNumber]++;
      if (!gradesMap[item.playNumber]) {
        gradesMap[item.playNumber] = [];
      }
      gradesMap[item.playNumber].push({ ...item });
    });

    setGradesList(gradesMap);
    setPlayCount(Object.keys(playsCount));
  };

  const refreshGradesList = (position) => {
    let queryParams = {
      position,
      game: params.get("game_id"),
      limit: 2000,
      status: "1,2",
      page: 1,
    };
    setGradesListLoading(true);
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items);

          processGradeListItems(data?.data?.items);
        }
        setGradesListLoading(false);
      },
    });
  };
  // useEffect to open print modal once printing is sucessful
  useEffect(() => {
    window.onafterprint = () => {
      // setPrint(true);
    };
  }, []);

  const paramsss = new URLSearchParams(window.location.search);

  function getPositionCodesByCategory(categoryName) {
    switch (categoryName) {
      case "Offense":
        return (
          positionscharts?.player_positions?.offense[0]?.variables?.map(
            ({ code }) => code
          ) || []
        );
      case "Defense":
        return (
          positionscharts?.player_positions?.defense[0]?.variables?.map(
            ({ code }) => code
          ) || []
        );
      case "Special Teams":
        return (
          positionscharts?.player_positions?.special_teams[0]?.variables?.map(
            ({ code }) => code
          ) || []
        );
      default:
        return [];
    }
  }

  const PositionSelect = () => {
    return (
      <>
        <Select
          value={filterValue}
          onChange={(value) => {
            setFilterValue(value);
            refreshGradesList([value.toString()]);
          }}
          optionLabelProp="label"
          options={[
            {
              label: (
                <span
                  className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer hover:bg-[#DEDEDE] w-full p-1"
                  onClick={() => {
                    setFilterValue("Offensive unit");
                    refreshGradesList(getPositionCodesByCategory("Offense"));
                  }}
                >
                  Offensive unit
                </span>
              ),
              options:
                positionscharts?.player_positions?.offense[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },
            {
              label: (
                <span
                  className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer hover:bg-[#DEDEDE] w-full p-1"
                  onClick={() => {
                    setFilterValue(" Defensive unit");
                    refreshGradesList(getPositionCodesByCategory("Defense"));
                  }}
                >
                  Defensive unit
                </span>
              ),
              options:
                positionscharts?.player_positions?.defense[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },
            {
              label: (
                <span
                  className="font-inter text-[14px] font-bold text-[#0F1A24] cursor-pointer hover:bg-[#DEDEDE] w-full p-1"
                  onClick={() => {
                    setFilterValue("Special Teams");
                    refreshGradesList(
                      getPositionCodesByCategory("Special Teams")
                    );
                  }}
                >
                  Special Teams
                </span>
              ),
              value: "Special Teams",
              options:
                positionscharts?.player_positions?.special_teams[0]?.variables?.map(
                  ({ code, name }) => {
                    return {
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    };
                  }
                ) || [],
            },
          ]}
        />
      </>
    );
  };
  const render = () => {
    const paramsss = new URLSearchParams(window.location.search);
    let feedback_type = paramsss.get("t");
    let feedback_content = [];

    return (
      <div className="evaluvationPage w-[98%]">
        <div className="container w-full">
          <div className="full-width w-full flex flex-col">
            <h3 className="title hide-in-print">Coaches Notes</h3>
            <Card className="w-[100%]" id="coachesNoteSection">
              <div className="TableContainer evaluvationReportTable ">
                <div className="flex items-center justify-between mb-[20px] margin-non">
                  <div className="flex items-center text-[14px] font-inter text-[#0F1A24]">
                    <span className="mr-[10px]">
                      <span className="text-[#A40E17] text-[12px]"> vs</span>{" "}
                      {paramsss.get("opponent")} ({paramsss.get("week")})
                    </span>
                    <img src={bell} className="w-[20px] mr-[10px]" />
                    <AngelPopUp />
                  </div>
                  <div
                    className="d-flex "
                    style={{ cursor: "pointer" }}
                    onClick={handlePrint}
                  >
                    <span
                      className="text-right flex items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.73584 11.7188H15.2358M3.98584 6.46875V1.66875C3.98584 1.42022 4.18731 1.21875 4.43584 1.21875H12.5358C12.7844 1.21875 12.9858 1.42022 12.9858 1.66875V6.46875M15.2358 14.1188V10.4688C15.2358 8.25961 13.445 6.46875 11.2358 6.46875H5.73584C3.5267 6.46875 1.73584 8.25961 1.73584 10.4688V14.1187C1.73584 14.4501 2.00447 14.7188 2.33584 14.7188H14.6358C14.9672 14.7188 15.2358 14.4501 15.2358 14.1188Z"
                          stroke="#0F1A24"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span>&nbsp;&nbsp;Print notes</span>
                    </span>
                  </div>
                  {print && (
                    <PrintModal onOpen={print} onClose={closePrintModal} />
                  )}
                </div>
                <div className="flex text-[14px] font-inter font-normal items-center hide-in-print positiondiv">
                  <div className="flex items-center w-[100%] justify-between mb-[20px]">
                    <div className="d-flex items-center">
                      <span className="text-[14px] font-inter font-normal mr-[8px]">
                        Filter Players by
                      </span>

                      <PositionSelect />
                    </div>
                    {/* // [TODO] */}
                    {/* <span
                      style={{
                        color: "",
                        textDecoration: "underline",
                      }}
                      className='text-[#A40E17] cursor-pointer underline'>
                      Clear filter
                    </span> */}
                  </div>
                </div>
                <div className="row">
                  {gradesListLoading ? (
                    <div style={{ margin: "auto" }} className="col-11 mt-5">
                      <Skeleton active />
                      <br />
                      <Skeleton active />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div style={{ margin: "auto" }} className="col-6">
                    {!gradesListLoading ? (
                      playCount.length < 1 && feedback_content < 1 ? (
                        <Result
                          title={`We found no grades for ${filterValue} position. Please select another position`}
                          extra={<PositionSelect />}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {!gradesListLoading
                  ? playCount.map((y, i) => {
                      if (gradesList[y]) {
                        if (gradesList[y][0]) {
                          const gradePlayID = gradesList[y][0]?.play;
                          const coach_depth_chart_id =
                            gradesList[y][0]?.coach_depth_chart_id;

                          if (gradePlayID) {
                            const filteredFeedbackList = feedbackList?.filter(
                              (feedback) =>
                                feedback?.play_id == gradePlayID &&
                                feedback?.position == filterValue &&
                                feedback?.coach_depth_chart_id ==
                                  coach_depth_chart_id
                            );

                            feedback_content = filteredFeedbackList;
                          } else {
                            feedback_content = [];
                          }
                        }
                      }

                      return (
                        <div className="coachesNotesPlays">
                          <div className="playNum">
                            Play
                            <span>{y}</span>
                          </div>{" "}
                          <Divider
                            className="margin-none"
                            style={{ marginTop: "16px", marginBottom: "12px" }}
                          />
                          <div className="mb-2">
                            <h5>Collaboration: </h5>
                            {gradesList[y][0]?.id && (
                              <Collaboration
                                play={{
                                  number: gradesList[y][0]?.playNumber,
                                  id: gradesList[y][0]?.play,
                                }}
                                game={gradesList[y][0]?.game}
                              />
                            )}
                          </div>
                          {feedback_content.length ? (
                            <div className="playFeedBack">
                              <h5>Position Group Feedback:</h5>
                              <p>
                                {
                                  feedback_content[feedback_content.length - 1]
                                    ?.feedback
                                }
                              </p>{" "}
                              <hr />
                              {/* ------
                                {feedback_content.map((feedbackItem) => (
                                <>
                                  {" "}
                                  <p>{feedbackItem.feedback}</p> <hr />
                                </>
                              ))}  */}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="w-[100%] coachnotestablediv">
                            {feedback_type == "analytical" && (
                              <Table
                                key={i}
                                columns={
                                  gradesList[y][0] &&
                                  gradesList[y][0]?.position == "QB"
                                    ? columnsQB
                                    : columns
                                }
                                dataSource={gradesList[y]}
                                loading={gradesListLoading}
                                size="middle"
                                pagination={false}
                                className="w-[100%]"
                                bordered={true}
                              />
                            )}
                            {feedback_type == "feedback" && (
                              <Table
                                key={i}
                                columns={columnsFeedback}
                                dataSource={gradesList[y]}
                                loading={gradesListLoading}
                                size="middle"
                                pagination={false}
                                className="w-[100%]"
                                bordered={true}
                              />
                            )}
                            {feedback_type == "grading" && (
                              <Table
                                key={i}
                                columns={gradingColumn}
                                dataSource={gradesList[y]}
                                loading={gradesListLoading}
                                size="middle"
                                pagination={false}
                                className="w-[100%]"
                                bordered={true}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </Card>
            {/* back and view analytics button divs */}
            <div className="mt-[16px] hide-in-print self-end flex items-center text-[14px] font-inter font-bold">
              <div
                className="h-[48px] border-solid border-[#2E4D6B] border-[1px] rounded-[4px] w-[88px] flex items-center justify-center text-[#2E4D6B] mr-[10px] cursor-pointer"
                onClick={() => history(-1)}
              >
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    profileDetails: _.get(state, "app.account.profile.data.details"),
    gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesView: (details) => dispatch(global.redux.action.games.view(details)),
  gradesList: (details) =>
    dispatch(global.redux.action.grades.listSort(details)),
  gridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
  listFeedback: (details) =>
    dispatch(global.redux.action.depth_chart.listFeedback(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachesNoteScreen);
