import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Radio, Select, Tooltip, Checkbox } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import puzzle from "../../../../assets/img/bl.svg";
import { ReceptionRadioButtons } from "../buttons/reception-button.js";
import Title from "./components/title.js";
import CustomSwitchButtons from '../CustomSwitchButtons';

function KickerModal({
  state,
  dispatch,
  close,
  update,
  item,
  school_id,
  loadAllPlayerList,
  play,
  handleUpdatePlay,
  updateInputGrade,
  updateGrade,
}) {
  const [modalWidth, setModalWidth] = useState(680);
  const [showMoreOnside, setShowMoreOnside] = useState(false);
  const [showMoreKickoff, setShowMoreKickoff] = useState(false);
 

  useEffect(() => {
    if (showMoreKickoff || showMoreOnside) {
      setModalWidth(800);
    }
  }, [showMoreKickoff, showMoreOnside]);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setModalWidth(680);
    } else {
      setModalWidth(800);
    }
  }, [window.innerWidth]);

  return (
    state.return && (
      <Modal
        open={state.return}
        onOk={close}
        onCancel={close}
        closable={true}
        footer={null}
        width={modalWidth}
        title={
          <Title
            player={`${item?.first_name} ${item?.last_name}`}
            title={"Return"}
          />
        }
      >
        <div
          style={{ border: "1px solid #F3F5F7" }}
          className="mt-4 text-xs text-[#0F1A24]"
        >
          <div className="flex">
            <div
              className="w-[20%] font-bold bg-[#F3F5F7] p-3"
              style={{ border: "1px solid #F3F5F7" }}
            >
              <span>Kickoff Return</span>
            </div>{" "}
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              <Switch
                defaultChecked={item.kickoff_return}
                onChange={(e) => {
                  setShowMoreKickoff(e); 
                  updateGrade("kickoff_return", e ? 1 : 0);
                }}
              />{" "}
            </div>
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              <Input
                onChange={({ target: { value } }) =>
                  handleUpdatePlay(play.id, { gn: value })
                }
                defaultValue={play.gn}
                size="large"
                placeholder="Gn/ls"
              />
            </div>
            {showMoreKickoff || item.kickoff_return ? (
              <>
                <div className="p-3 w-[40%] turn-over actor flex items-center justify-center">

                <Checkbox
                defaultChecked={item.kickoff_fair_catch}
                onChange={({ target: { checked: e }}) =>{ console.log(e, 'e'); updateGrade("kickoff_fair_catch", e ? 1 : 0)}}
              >
                <span className="text-[#2E4D6B]">Fair Catch</span>
              </Checkbox>
                   
                </div>
              </>
            ) : null}
          </div>

          <div className="flex w-full">
            <div
              className="w-[20%] p-3 font-bold bg-[#F3F5F7]"
              style={{ border: "1px solid #F3F5F7" }}
            >
              <span>Punt Return</span>
            </div>{" "}
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              <Switch
                defaultChecked={item.punt_return}
                onChange={(e) => {
                  setShowMoreOnside(e); 
                  updateGrade("punt_return", e ? 1 : 0);
                }}
              />{" "}
            </div>
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              <Input
                onChange={({ target: { value } }) =>
                  handleUpdatePlay(play.id, { gn: value })
                }
                defaultValue={play.gn}
                size="large"
                placeholder="Gn/ls"
              />
            </div>
            {showMoreOnside || item.punt_return ? (
              <>
                <div className="p-3 w-[40%] turn-over actor flex items-center justify-center">
                  <Radio.Group
                    name="radiogroup"
                    onChange={({ target: { value } }) =>
                      updateGrade("punt_return_fair_catch", value)
                    }
                    defaultValue={item.punt_return_fair_catch}
                    className="flex items-center"
                  >
                    <Radio value={1}>
                      {" "}
                      <span className="text-[#2E4D6B]">Fair Catch </span>
                    </Radio>
                    <Radio value={0}>
                      {" "}
                      <span className="text-[#2E4D6B]">No Catch</span>
                    </Radio>
                  </Radio.Group>
                </div>
              </>
            ) : null}
          </div>

          <div
            className="flex w-full"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[20%] p-3 font-bold bg-[#F3F5F7]"
              style={{ border: "1px solid #F3F5F7" }}
            >
              <span>Fumble</span>
            </div>{" "}
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] fumble flex items-center justify-center"
            >
              <Switch
                defaultChecked={item.fumble}
                onChange={(e) => updateGrade("fumble", e ? 1 : 0)}
              />{" "}
            </div>
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              <Checkbox
                defaultChecked={item.fumble_lost}
                onChange={({ target: { checked: e }}) => updateGrade("fumble_lost", e ? 1 : 0)}
              >
                <span className="text-[#2E4D6B]">Fumble Lost</span>
              </Checkbox>
            </div>
          </div>

          <div
            className="flex w-full"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[20%] p-3 font-bold bg-[#F3F5F7]"
              style={{ border: "1px solid #F3F5F7" }}
            >
              <span>Onside Recovered</span>
            </div>{" "}
            <div className="p-3 w-[20%] turn-over actor flex items-center justify-center">
              
            <Switch
                defaultChecked={item.onsidekick_recovered}
                onChange={(e) => updateGrade("onsidekick_recovered", e ? 1 : 0)}
              />{" "} 
            
            </div>
          </div>

          <div
            className="flex w-full"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[20%] p-3 font-bold bg-[#F3F5F7]"
              style={{ border: "1px solid #F3F5F7" }}
            >
              <span>TD</span>
            </div>{" "}
            <div
              style={{ border: "1px solid #f3f5f7" }}
              className="p-3 w-[20%] turn-over actor flex items-center justify-center"
            >
              {console.log('item.return_td', typeof item.return_td)}
              <Switch
                defaultChecked={Number(item.return_td)}
                onChange={(e) => updateGrade("return_td", e ? 1 : 0)}
              />{" "}
            </div>
          </div>
          <br />
          <br />

          <div className="flex flex-col items-center justify-between mt-2">
            <button
              onClick={close}
              className="flex self-end w-[30%] text-xs items-center focus:outline-0 justify-center bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]"
            >
              <img src={floppyDiskImg} className="w-4" />
              <span className="ml-2">Save</span>
            </button>
          </div>
        </div>
      </Modal>
    )
  );
}

export default KickerModal;
