import React from 'react';
import { InputNumber, Checkbox, Switch } from "antd";

const CustomSwitchButtons = ({
    defaultState,
    handleQuickUpdate,
    title,
    item,
  }) => {
    const handleButtonClick = () => {
      handleQuickUpdate(
        item.id,
        item.athlete,
        title,
        Number(defaultState) === 1 ? 0 : 1
      );
    };
  
    return (
       <div className="turn-over actor flex w-[100%] items-center justify-center">
        {Number(defaultState) ? (
         
            <Switch
              disabled={item.disabled}
              checked={true}
              onChange={() => handleButtonClick()}
            />
         
        ) : (
          <Switch
              disabled={item.disabled}
              checked={false}
              onChange={() => handleButtonClick()}
            />
          
        )}
        </div>
      
    );
  };


  export default CustomSwitchButtons;