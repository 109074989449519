import React, { useState, useEffect} from "react";
import { Modal, Tooltip } from "antd";
import bell from "../../assets/img/bell-notification.svg";
import bulb from "../../assets/img/light-bulb-on.svg";
import warning from "../../assets/img/warning-triangle-outline.svg";
import "./modal.css";

function NotificationModal({ week, team, opponent }) {
  const [notification, setNotification] = useState(false);
  const [dot, setDot] = useState(true);

    useEffect(() => {
      const storedDot = localStorage.getItem("notificationDot");
      if (storedDot !== null) {
        setDot(JSON.parse(storedDot));
      }
    }, []);

    const openNotification = () => {
      setNotification(true);
      setDot(false);
      localStorage.setItem("notificationDot", JSON.stringify(false));
    };

  const closeNotification = () => {
    setNotification(false);
  };
  return (
    <div className='notificationmodal'>
      <div className='w-[24px] relative mr-[8px]'>
        <img
          src={bell}
          className='w-[24px] cursor-pointer'
          onClick={openNotification}
        />
        {dot && (
          <div
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              backgroundColor: "#A40E17",
              borderRadius: "50%",
              width: "8px",
              height: "8px",
            }}></div>
        )}
      </div>
      {notification && (
        <Modal
          width={330}
          open={notification}
          onCancel={closeNotification}
          closable={false}
          footer={null}>
          <div className='flex flex-col w-full'>
            <div className='flex font-inter text-[14px]'>
              <span className='text-[#0F1A24]'>
                <span className='font-bold'> {week} </span>
                <span className='font-normal'>
                  | {team} <span className='text-[#A40E17]'> vs </span>
                  {opponent}
                </span>
              </span>
            </div>
            <span className='font-bold font-inter mt-[8px] text-[14px] text-[#0F1A24]'>
              Notifications:
            </span>
            <div className='w-full h-[62px] rounded-[4px] text-[12px] text-[#ED780B] font-bold bg-[#f0decd] p-[12px] flex mt-[8px]'>
              <img src={warning} className='w-[30px] h-[30px] mr-[9px]' />
              <span>
                This week's push notifications have not been sent to players.
              </span>
            </div>
            <div className='flex bg-[#EAEDF0] w-[full] mt-[8px] h-[119px] justify-between font-inter text-[12px] font-bold text-[#2E4D6B] rounded-[4px] px-[15px] py-[10px]'>
              <img
                src={bulb}
                className='w-[24px] h-[24px] justify-self-start mr-[8px]'
              />
              <span className='w-[191px]'>
                Feedback is most likely to provide a Competitive Learning
                Advantage when received within 12 hours after games end.
              </span>
              <Tooltip title='Close'>
                <i
                  className='fas fa-xmark cursor-pointer'
                  onClick={closeNotification}></i>
              </Tooltip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default NotificationModal;
