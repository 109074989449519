import React, { useEffect, useState } from "react";

import {
  Form,
  Modal,
  Button,
  Input,
  Spin,
  InputNumber,
  TimePicker,
  DatePicker,
  notification,
  Select,
  Upload,
  Radio,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { connect } from "react-redux";
import "./games.css";
import TextArea from "antd/lib/input/TextArea";
import { geocodeByAddress } from "react-places-autocomplete";
import { useNavigate } from "react-router";
import momentTimezone from "moment-timezone";
import moment from "moment-timezone";

const { Option } = Select;
const axios = require("axios").default;

const GamesCreateComponent = (props) => {
  const [address_results, setaddress_results] = useState([]);
  const [showOpponentsDropdown, setShowOpponentsDropdown] = useState(true);
  const [match_results, setmatch_results] = useState([
    { name: "Home Team Win", value: 0 },
    { name: "Away Team Win", value: 1 },
    { name: "Tie", value: 2 },
    { name: "N/A", value: 3 },
  ]);
  const [address_fetching, setaddress_fetching] = useState(false);
  const [form] = Form.useForm();
  const [schoolsList, setSchoolsList] = useState([]);
  const [schoolsListMeta, setSchoolsListMeta] = useState({});
  const [timeZone, setTimeZone] = useState({});
  const [gameTitle, setGameTitle] = useState();
  const [gameViewDetails, setGameViewDetails] = useState({});
  const defaultTime = moment("19:00", "HH:mm");
  const [gameType, setGameType] = useState("");
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonsListMeta, setSeasonsListMeta] = useState({});
  const [weekList, setWeekList] = useState([
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
  ]);

  const [opponent_logo_display, setopponent_logo_display] = useState(null);
  const [sponsor_logo_display, setsponsor_logo_display] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  let history = useNavigate();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    let game_id = params.get("id");
    loadSchoolsList();
    getCurrentLocation();
    if (game_id) {
      loadGameView(game_id);
    }
    loadSeasonsList();
  }, []);
  useEffect(() => {
    if (props.coachProfile?.school) {
      fetchSchoolSubscription(props.coachProfile.school);
    }
  }, [props.coachProfile]);

  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {},
    });
  };
  const fetchSchoolSubscription = (id) => {
    props.getSchoolSubscription({
      params: { id },
      callback: (res, responseData) => {},
    });
  };
  const dateTimezone = (time) => {
    //     const timeConvert = momentTimezone(`${date}T${time}`).tz(_.get(timeZone,'timezone')).format('hh:mm a')
    //    console.log('timeConvert',timeConvert,date,time,`${date}T${time}`);
    //     var dt = moment(timeConvert, ["hh:mm a"])
    let date1 = `${time}`.includes("T")
      ? moment(time).format("hh:mm A")
      : moment(`${moment().format("YYYY-MM-DD")} ${time}`).format("hh:mm A");
    let date2 = moment(date1, ["hh:mm A"]);

    return date2;
  };
  const datezone = (time) => {
    //     const timeConvert = momentTimezone(`${date}T${time}`).tz(_.get(timeZone,'timezone')).format('hh:mm a')
    //    console.log('timeConvert',timeConvert,date,time,`${date}T${time}`);
    //     var dt = moment(timeConvert, ["hh:mm a"])
    let date1 = moment(time).format("MM/DD/YYYY");
    let date2 = moment(date1, "MM/DD/YYYY");
    return date2;
  };

  const loadGameView = (id) => {
    let game_id = params.get("id");
    props.gamesView({
      params: { id: game_id },
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details")) {
          setGameViewDetails(_.get(data, "data.details"));
          let details = _.get(data, "data.details");
          if (`${_.get(details, "start_time")}`.includes("T") == true) {
            details.start_date = datezone(_.get(details, "start_time"));
          }
          if (_.get(details, "start_time")) {
            let times1 = dateTimezone(_.get(details, "start_time"));
            // let times2 =dateTimezone(_.get(details,'end_time'))
            details.start_time = times1;
          }

          // details.end_time = times2

          await form.setFieldsValue({ ..._.get(data, "data.details") });
          if (
            _.get(data, "data.details.address") &&
            _.get(data, "data.details.location")
          ) {
            const address_results = [
              {
                text: _.get(data, "data.details.address"),
                value: JSON.stringify({
                  text: _.get(data, "data.details.address"),
                  lat: _.get(data, "data.details.location.x"),
                  lng: _.get(data, "data.details.location.y"),
                  postal_code: "",
                }),
              },
            ];
            form.setFieldsValue({
              address: JSON.stringify({
                text: _.get(data, "data.details.address"),
                lat: _.get(data, "data.details.location.x"),
                lng: _.get(data, "data.details.location.y"),
                postal_code: "",
              }),
            });
            setaddress_results(address_results);
          } else {
            const address_results = [
              {
                text: "Dallas,Texas",
                value: JSON.stringify({
                  text: "Dallas,Texas",
                  lat: "32.7767",
                  lng: "96.7970",
                  postal_code: "",
                }),
              },
            ];
            form.setFieldsValue({
              address: JSON.stringify({
                text: "Dallas,Texas",
                lat: "32.7767",
                lng: "96.7970",
                postal_code: "",
              }),
            });
            setaddress_results(address_results);
          }
          form.setFieldsValue({
            title: _.startCase(_.get(data, "data.details.title")),
          });
          form.setFieldsValue({
            description: _.startCase(_.get(data, "data.details.description")),
          });
        }
        let games_sponsor_logo_filename = _.chain(data)
          .get("data.details.sponsor_logo", "")
          .trim()
          .value();
        if (!_.isEmpty(games_sponsor_logo_filename)) {
          setsponsor_logo_display(
            `${_.get(data, "data.meta.photo.path")}/${_.get(
              data,
              "data.meta.photo.folders.sponsor_logo"
            )}/original/${games_sponsor_logo_filename}`
          );
        }
        let games_opponent_logo_filename = _.chain(data)
          .get("data.details.opponent_logo", "")
          .trim()
          .value();
        if (!_.isEmpty(games_opponent_logo_filename)) {
          setopponent_logo_display(
            `${_.get(data, "data.meta.photo.path")}/${_.get(
              data,
              "data.meta.photo.folders.opponent_logo"
            )}/512/${games_opponent_logo_filename}`
          );
        }
      },
    });
  };

  const getCurrentLocation = async (limit = 1000, page = 1) => {
    const { data, status } = await axios.get("https://ipapi.co/json");
    setTimeZone(data);
  };

  const loadSchoolsList = (limit = 10000, page = 1) => {
    // setSchoolsListLoading(true)
    props.schoolsList({
      params: { limit },
      callback: (res, data) => {
        if (res === true) {
          let push_data = [];
          if (
            _.get(props, "coachProfile.school.id") &&
            _.get(data, "data.items[0].id")
          ) {
            setSchoolsListMeta(_.get(data, "data.meta", {}));
            _.map(_.get(data, "data.items", []), (value, index) => {
              if (
                _.get(value, "school_admin") !=
                _.get(props, "coachProfile.school.school_admin")
              ) {
                push_data.push(value);
              }
            });
            push_data.sort((a, b) => a.name.localeCompare(b.name));
            setSchoolsList(push_data);
          }
        }
        // setSchoolsListLoading(false)
      },
    });
  };

  const loadSeasonsList = (limit = 1000, page = 1) => {
    props.seasonsList({
      callback: (res, data) => {
        if (res === true) {
          setSeasonsListMeta(_.get(data, "data.meta", {}));
          setSeasonsList(_.get(data, "data.items", []));
        }
      },
    });
  };

  //Address search
  const fetchAddress = (value) => {
    setaddress_results([]);
    setaddress_fetching(true);
    geocodeByAddress(value)
      .then((body) => {
        const address_results = _.map(body, (location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            text: location.formatted_address,
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
            postal_code: fetchPostalCode(
              _.get(location, "address_components", [])
            ),
          }),
        }));

        setaddress_results(address_results);
        setaddress_fetching(false);
      })
      .catch((error) => {
        setaddress_results([]);
        setaddress_fetching(true);
      });
  };

  //Initializing debounce for address
  const addressDelayedQuery = _.debounce((q) => fetchAddress(q), 1200);

  const fetchPostalCode = (address_components) => {
    let postal_code = null;
    address_components.forEach((value) => {
      value.types.forEach((type) => {
        if (type === "postal_code") {
          postal_code = value.long_name;
        }
      });
    });
    return postal_code;
  };

  const addTimeZone = (time, form_date) => {
    var dt = moment(time, ["hh:mm a"]).format("HH:mm");
    // var b = moment.utc("2013-11-18 11:55").tz("America/Toronto");

    const date = momentTimezone(form_date)
      .tz(_.get(timeZone, "timezone"))
      .format("YYYY-MM-DD");
    let date1 = moment
      .tz(`${date} ${dt}`, _.get(timeZone, "timezone"))
      .format();
    let final_time = _.chain(date1).split("T").last().value();
    return date1;
  };

  const handleSubmit = (e) => {
    setButtonLoading(true);
    let game_id = params.get("id");
    let values = { ...e };

    values.home_team = _.get(props, "coachProfile.school.id");

    //  values.start_date;
    if (game_id) {
      // values.id =game_id
      let formData = {
        description: "Game",
        ...values,

        season: params.get("season"),
      };
      props.gamesUpdate({
        body: formData,
        params: { id: parseInt(game_id) },
        callback: async (res, responseData) => {
          if (res == true) {
            if (
              _.get(values, "opponent_logo") ||
              _.get(values, "sponsor_logo")
            ) {
              await updateOpponentPhoto(
                values,
                game_id,
                _.get(responseData, "message")
              );
              await updateSponsorPhoto(
                values,
                game_id,
                _.get(responseData, "message")
              );
              history("/coaches-evaluation");
            } else {
              notification.success({
                message: "Success",
                description: _.get(responseData, "message"),
              });
              history("/coaches-evaluation");
            }
          } else {
            setButtonLoading(false);
          }
        },
      });
    } else {
      let formData = {
        description: `Game day today`,
        ...values,
        title: gameTitle,
        season: params.get("season"),
      };

      const _correct_vals = {
        "regular season": 1,
        playoffs: 1,
      };

      formData.away_team = formData.home_team;
      formData.end_time = formData.start_date;
      // 1240
      props.gamesCreate({
        body: formData,
        callback: (res, responseData) => {
          if (res) {
            notification.success({
              message: "Success",
              description: _.get(responseData, "message"),
            });
            history("/coaches-evaluation");
          }
          setButtonLoading(false);
        },
      });
    }
  };
  const formatTime = (date) => {
    return date.toLocaleString("en", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const updateOpponentPhoto = (data, game_id) => {
    return new Promise((resolve) => {
      if (_.get(data, "opponent_logo.file.originFileObj")) {
        let formData = new FormData();
        formData.append("id", game_id);
        formData.append(
          "photo",
          _.get(data, "opponent_logo.file.originFileObj")
        );
        props.updateOpponentData({
          body: formData,
          callback: (res, responseData) => {
            props.gamesView({
              params: { id: game_id },
              callback: (res, responseData) => {},
            });
            if (res == true) {
              notification.success({
                message: "Success",
                description: _.get(responseData, "message"),
              });
            }
            setButtonLoading(false);
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const removeImage = (info) => {};

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onChangePhoto = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setopponent_logo_display(imageUrl);
    });
  };

  const updateSponsorPhoto = (data, game_id) => {
    return new Promise((resolve) => {
      if (_.get(data, "sponsor_logo.file.originFileObj")) {
        let formData = new FormData();
        formData.append("id", game_id);
        formData.append(
          "photo",
          _.get(data, "sponsor_logo.file.originFileObj")
        );
        props.updateSponsorData({
          body: formData,
          callback: (res, responseData) => {
            props.gamesView({
              params: { id: game_id },
              callback: (res, responseData) => {},
            });
            if (res == true) {
              notification.success({
                message: "Success",
                description: _.get(responseData, "message"),
              });
            }
            setButtonLoading(false);
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  };

  const onChangePic = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setsponsor_logo_display(imageUrl);
    });
  };

  const timeChange = (event, fieldName) => {
    form.setFieldsValue({ [`${fieldName}`]: event });
  };

  const dateChange = (event, fieldName) => {
    form.setFieldsValue({ [`${fieldName}`]: event });
  };
  const onFinish = (details) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "If you delete this event, it will be removed from the system and unrecoverable. Do you want to delete?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {},
    });
  };

  const deleteItem = () => {
    props.changeStatus({
      body: { id: params.get("id"), status: 3, status_glossary: "Deleted" },
      callback: (res, data) => {
        if (res == true && _.get(data, "details.id")) {
          notification.success({
            message: "Success",
            description: _.get(data, "message"),
          });
          history("/coaches-evaluation");
        }
      },
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onOpponentSearch = (value) => {
    console.log("search:", value);
  };

  const render = () => {
    const { editMode, post } = props;
    let game_id = params.get("id");
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const capitalizeString = (str) => {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    return (
      <div className="w-[98%] p-[2%] eventscreen ">
        <div className="container">
          <div className="PlayerprimaryCard">
            <Form
              name="basicform"
              className=" p-4"
              layout="vertical"
              size={"large"}
              onFinish={handleSubmit}
              initialValues={{ remember: true, feedback_type: 'grading' }}
              form={form}
            >
              {params.get("id") ? (
                <Form.Item
                  className="col-12 col-md-12"
                  label={
                    <span className="font-inter text-[14px] font-bold">
                      This Event's Sponsor Logo
                    </span>
                  }
                  name="sponsor_logo"
                  rules={[
                    {
                      required: sponsor_logo_display ? false : true,
                      message: "Please input your photo!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onRemove={removeImage}
                    onChange={onChangePic}
                    customRequest={dummyRequest}
                  >
                    {sponsor_logo_display ? (
                      <img
                        src={sponsor_logo_display}
                        alt="avatar"
                        style={{ width: "100%", borderRadius: "100px" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              ) : null}

              {params.get("id") ? (
                <Form.Item
                  className="col-12 col-md-6"
                  label={
                    <span className="font-inter text-[14px] font-bold">
                      Opponent Logo
                    </span>
                  }
                  name="opponent_logo"
                  rules={[
                    {
                      required: opponent_logo_display ? false : true,
                      message: "Please input Opponent Logo!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onRemove={removeImage}
                    onChange={onChangePhoto}
                    customRequest={dummyRequest}
                  >
                    {opponent_logo_display ? (
                      <img
                        src={opponent_logo_display}
                        alt="avatar"
                        style={{ width: "100%", borderRadius: "100px" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              ) : null}
              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span className="font-inter text-[14px] font-bold">Type</span>
                }
                name="type"
                rules={[
                  { required: true, message: "Please enter type" },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (value == form.getFieldValue("away_team")) {
                        return Promise.reject("Please select different team.");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  style={{ width: 400 }}
                  showSearch={true}
                  onSearch={onSearch}
                  onChange={(val) => {
                    setGameType(val);
                    const _correct_vals = {
                      "regular season": 1,
                      playoffs: 1,
                    };
                    if (_correct_vals[val]) {
                      setShowOpponentsDropdown(true);
                    } else {
                      setShowOpponentsDropdown(false);
                    }
                  }}
                  filterOption="children"
                >
                  {[
                    { name: "Scrimmage", value: "scrimmage" },
                    { name: "Practice", value: "practice" },
                    { name: "Regular Season", value: "regular season" },
                    { name: "Playoffs", value: "playoffs" },
                  ].map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {gameType === "regular season" && (
                <Form.Item className="col-12 col-md-6">
                  <Radio>District Game</Radio>
                  <Radio>Non-District Game</Radio>
                </Form.Item>
              )}
              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span className="font-inter text-[14px] font-bold">Week</span>
                }
                name="week"
                rules={[{ required: true, message: "Please enter week" }]}
              >
                <Select
                  style={{ width: 400 }}
                  showSearch={true}
                  onSearch={onSearch}
                  filterOption="children"
                >
                  {weekList.map((item, index) => {
                    return (
                      <Option key={index} value={_.get(item, "value")}>
                        {_.get(item, "name")}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span className="font-inter text-[14px] font-bold">
                    Evaluation Type
                  </span>
                }
                name="feedback_type"
                rules={[
                  { required: true, message: "Please enter feedback type" },
                ]}
              >
                <Select
                  style={{ width: 400 }}
                  showSearch={true}
                  onSearch={onSearch}
                  filterOption="children"
                  defaultValue={"grading"}
                >
                  <Option value="feedback" disabled={false}>
                    Level 1: Feedback Only (No Grades, Just Feedback)
                  </Option>
                  <Option
                    value="grading"
                    disabled={
                      props.schoolSubscriptionDetails?.subscription?.plan == 1
                    }
                  >
                    Level 2: Grade Mode (Grades + Feedback)
                  </Option>
                  <Option
                    value="analytical"
                    disabled={
                      props.schoolSubscriptionDetails?.subscription?.plan == 1
                    }
                  >
                    <div className="flex flex-col">
                      <span>
                        {" "}
                        Level 3: Stat Mode (Grades + Feedback + Stats)
                      </span>
                      <span className="font-inter text-[12px] text-[red] cursor-none mt-2">
                        Wardbord is a registered stat partner with Maxpreps
                      </span>
                    </div>
                  </Option>
                  <Option disabled={true}>
                    <Tooltip title="Production mode will be ready in spring of 2025">
                      Level 4: Production Mode (Analytical mode + production)
                    </Tooltip>
                  </Option>
                </Select>
              </Form.Item>
              {/* <Form.Item
                        className="col-12 col-md-6"
                        label="Duration(minutes)"
                        name="duration"
                        rules={[{ required: true, message: 'Please enter duration' }]}
                    >
                        <Input style={{width: 400}} />
                    </Form.Item> */}

              {/* <Form.Item
                        className="col-12 col-md-6"
                        label="End Time"
                        name="end_time"
                        rules={[{ required: true, message: 'Please enter end time' }]}
                    >
                        <TimePicker style={{width: 400}} format='hh:mm a' onSelect= {(e) => timeChange(e,'end_time')} onChange= {(e) => timeChange(e,'end_time')} />
                    </Form.Item> */}
              {/* <Form.Item
                        className="col-12 col-md-6"
                        label="Home Team"
                        name="home_team"
                        rules={[{ required: true, message: 'Please enter home team' }, () => ({
                            validator(_, value) {
                            if (!value) {
                                return Promise.reject();
                            }
                            if (value == form.getFieldValue('away_team')) {
                                return Promise.reject("Please select different team.");
                            }
                            
                            return Promise.resolve();
                            },
                        })]}
                    >
                        <Select style={{width: 400}}>
                            {schoolsList.map((item, index) => {
                                return (
                                    <Option key={index} value={item.id}>{_.chain(item).get('name').startCase().value()}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}
              {params.get("id") ? (
                <Form.Item
                  className="col-12 col-md-2"
                  label={
                    <span className="font-inter text-[14px] font-bold">
                      Home Team Score
                    </span>
                  }
                  name="home_team_score"
                  rules={[
                    {
                      required: false,
                      message: "Please enter home team score",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} maxLength={3} />
                </Form.Item>
              ) : null}
              {params.get("id") ? (
                <Form.Item
                  className="col-12 col-md-2"
                  label={
                    <span className="font-inter text-[14px] font-bold">
                      Away Team Score
                    </span>
                  }
                  name="away_team_score"
                  rules={[
                    {
                      required: false,
                      message: "Please enter away team score",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} maxLength={3} />
                </Form.Item>
              ) : null}

              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span className="font-inter text-[14px] font-bold">
                    Start Date
                  </span>
                }
                name="start_date"
                rules={[{ required: true, message: "Please enter start date" }]}
              >
                <DatePicker
                  style={{ width: 400 }}
                  format="MM/DD/YYYY"
                  onChange={(e) => dateChange(e, "start_date")}
                />
              </Form.Item>
              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span className="font-inter text-[14px] font-bold">
                    Start Time
                  </span>
                }
                name="start_time"
                rules={[{ required: true, message: "Please enter start time" }]}
              >
                <TimePicker
                  style={{ width: 400 }}
                  format="hh:mm a"
                  onChange={(e) => {
                    timeChange(e, "start_time");
                    console.log("e", e);
                  }}
                  // defaultValue={defaultTime}
                  // showNow={false}
                />
                {/* <Select
                  id="time"
                  style={{ width: 400 }}
                  onChange={(e) => timeChange(e, "start_time")}
                  value={defaultTime}
                >
                  {Array.from(Array(24), (_, i) => {
                    const hour = (i % 12 || 12).toString().padStart(2, "0");
                    const period = i < 12 ? "AM" : "PM";
                    const timeValue = new Date();
                    timeValue.setHours(i, 0, 0, 0);
                    return (
                      <Option key={i} value={formatTime(timeValue)}>
                        {`${hour}:00 ${period}`}
                      </Option>
                    );
                  })}
                </Select> */}
              </Form.Item>

              {game_id && (
                <Form.Item
                  className="col-12 col-md-6"
                  label={
                    <span className="font-inter text-[14px] font-bold">
                      Game Result
                    </span>
                  }
                  name="match_result"
                  rules={[
                    { required: false, message: "Please enter Game Result" },
                  ]}
                >
                  <Select
                    style={{ width: 400 }}
                    showSearch={true}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {match_results.map((item, index) => {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                className="col-12 col-md-6"
                label={
                  <span>
                    <b className="font-bold"> Opponent </b>
                    <br />
                    <span style={{ fontSize: "10px", color: "#A40E17" }}>
                      Type opponent's name just like it should read on your
                      schedule. (Ex: Ennis Lions)
                    </span>
                  </span>
                }
                name="opponent"
                rules={[{ required: true, message: "Enter opponent's name" }]}
              >
                <Input
                  onChange={({ target: { value } }) => {
                    console.log("setGameTitle", value);
                    setGameTitle(value);
                  }}
                  style={{ width: 400 }}
                />
              </Form.Item>

              {params.get("id") && (
                <div className="col-12 col-md-12 CategoriesViewList">
                  <div className="mt-2">
                    <Button
                      onClick={() => onFinish()}
                      className="btn-deactive"
                      color="red"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
              <Form.Item className="col-12 col-md-12 text-left">
                <Button
                  className="mr-2 bg-[#1A91FF] text-[#ffffff]"
                  type="success"
                  loading={buttonLoading}
                  htmlType="submit"
                >
                  {game_id ? "Update" : "Create"}
                </Button>
                <Button
                  disabled={buttonLoading}
                  type="cancel"
                  htmlType="reset"
                  onClick={() => history("/coaches-evaluation")}
                  className="border-[1px] border-[#A40E17] border-solid focus:outline-0 text-[#A40E17]"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolSubscriptionDetails: state.app.schools?.subscription?.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesCreate: (details) => dispatch(global.redux.action.games.create(details)),
  gamesUpdate: (details) => dispatch(global.redux.action.games.update(details)),
  gamesView: (details) => dispatch(global.redux.action.games.view(details)),
  schoolsList: (details) => dispatch(global.redux.action.schools.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  updateOpponentData: (details) =>
    dispatch(global.redux.action.games.updateopponentlogo(details)),
  updateSponsorData: (details) =>
    dispatch(global.redux.action.games.updatesponsorlogo(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.games.changeStatus(details)),
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  getSchoolSubscription: (details) =>
    dispatch(global.redux.action.schools.subscription(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GamesCreateComponent);
