import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
	DatePicker,
} from "antd";
import GamesListViewModalComponent from "./games-list-view.js";
import { connect } from "react-redux";
import moment from 'moment'
import _ from 'lodash';
import { BrowserRouter as Router, Link, useHistory, useNavigate } from "react-router-dom";

import "./games.css";
const Option = Select.Option;

const GamesListComponent = (props) => {
	const [TableData, setTableData] = useState('');
	const [showModal, setshowModal] = useState(false)
	const [gamesList, setGamesList] = useState([])
	const [gamesListMeta, setGamesListMeta] = useState({})
	const [gamesListLoading, setGamesListLoading] = useState(true)
	const [page,setPage]=useState(1)
	let history = useNavigate();

	const showModalView = (item) => {
		setshowModal(true)
		setTableData(item)
	}
	const columns = [
		{
			title: 'Team',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Season',
			dataIndex: 'season',
			align: 'center',
			key: 'season',
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			align: 'center',
			key: 'created_at',
		},
		{
			title: 'Week',
			dataIndex: 'week',
			align: 'center',
			key: 'week',
		},
		{
			title: 'Start Time',
			dataIndex: 'start_time',
			key: 'start_time',
		},
		// {
		// 	title: 'End Time',
		// 	dataIndex: 'end_time',
		// 	key: 'end_time',
		// },
		// {
		// 	title: 'Duration(minutes)',
		// 	dataIndex: 'duration',
		// 	key: 'duration',
		// },
		{
			title: 'Action',
			dataIndex: 'action',
			width: '20%',
			align: 'center',
			key: 'action',
			render: (text, item) => (
				<div className="games-list-action-btns">
					<Button className="TableActionBtn edit" style={{marginRight:"8px"}} onClick={() => showModalView(item)}>View</Button>
					{/* <Button className="TableActionBtn edit" onClick={() =>history(`/create-games?id=${_.get(item,'game_id')}`)}>Edit</Button> */}
					<Button onClick={()=>history(`/season-plays-list?id=${_.get(item,'game_id')}`)} className="TableActionBtn ml-2" >Evaluations</Button>
				</div>
			),
		},
	];
	useEffect(() => {
		if (_.get(props,'userProfile.school')) {
			loadGamesList()
		}
		
	},[])

	const loadGamesList = (limit = 9, page = 1,season="") => {
		let params ={limit, page,home_team:_.get(props,'userProfile.school.id')}
		if (season) {
			params.season=season
		}
		setGamesListLoading(true)
		props.gamesList({
			params: params,
			callback: (res, data) => {
				if (res === true) { 
					setGamesListMeta(_.get(data, 'data.meta', {}))
					setAllGamesList(_.get(data, 'data.items', []))
				}
				setGamesListLoading(false)
			}
		})
	}

	const setAllGamesList = (data) => {
		
		let list = []
		if (_.size(data) > 0) {
			_.map(data, (value, index) => {
				list.push({
					key: index,
					title:_.startCase(_.get(value, 'title')),
					season: _.get(value, 'season'),
					created_at: _.get(value, 'start_time')? moment(`${_.get(value, 'start_time')}`).format('MM/DD/YYYY'):'---' ,
					week: _.get(value, 'week'),
					start_time: _.get(value, 'start_time')? moment(`${_.get(value, 'start_time')}`).format('h:mm A'):'---',
					// end_time: moment(`${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`).format('h:mm A'),
					// duration: _.get(value, 'duration'),
					game_id:_.get(value, 'id')
				})
			})

			setGamesList(list)
		} else {
			setGamesList([])
		}
	}

	const hideModal = () => {
		setshowModal(false)
	};
	const onChangePage = (page, size) => {
		setPage(page)
		loadGamesList(size, page)
	}
	const getDropList = () => {
		const year = new Date().getFullYear();
		const start_year = year -2
		const end_year = year +8
		const start_year_split =parseInt(`${start_year}`.slice(2))

	  return (
		  Array.from( new Array(30), (v,i) =>
			<Option key={i} value={`${start_year+i}-${start_year_split+i+1}`}>{`${start_year+i}-${start_year_split+i+1}`}</Option>
		)
	  );
	};
	function onChange(value) {
		console.log(`selected ${value}`);
	  }
	  const onchangeFilter =(value,e)=>{
		  console.log('date month',value,e);
	  }
	  const onselectFilter =(e)=>{
		loadGamesList(9,page,e)
	  }
	
	return (
		<div className="HomePageContainer">
			<div className="container">
				<div className="full-width">
					<div className="TableFilter">
						<div className="row"><span className="TableFilterText">Filter</span>
							<Select
								showSearch
								style={{ width: 150 }}
								placeholder="Select a year"
								onSelect={(e)=>onselectFilter(e)}
							>
								{getDropList()}
							</Select>
						{/* <div style={{marginLeft:"72%"}} className="plays-list-action-btns">
								<Link to={`/create-games`} className="ant-btn TableActionBtn edit">Create Game</Link>
							</div> */}
							</div>
					</div>
					<div className="TableContainer">
					<Table bordered key={gamesList.id} loading={gamesListLoading} columns={columns} dataSource={gamesList} size="middle" pagination={false} />
					</div>
					{gamesListLoading == false && _.get(gamesListMeta, 'total') > 10 && <div className="PaginationContainer">
							<span className="PaginationContainerInner">
								<span className="PaginationLabel">Total {_.get(gamesListMeta, 'total') > 1 ? `${_.get(gamesListMeta, 'total')} items` : `${_.get(gamesListMeta, 'total')} item`}</span>
								<Pagination defaultCurrent={1}
								current={page}
									hideOnSinglePage={true}
									onChange={(page, size) => onChangePage(page, size)}
									total={_.get(gamesListMeta, 'total')}
								// total={500}
								/>
							</span>
							<span className="PaginationContainerInner">
								<span className="PaginationLabel ml-3">Go to</span>
								<InputNumber min={1} max={_.get(gamesListMeta, 'total') > 10 ? _.get(gamesListMeta, 'total') / 9 : 2} onPressEnter={(e) => onChangePage(_.get(e, 'target.value'), 9)} style={{ width: '70px' }} />
							</span>
						</div>}
				</div>
			</div>
			<GamesListViewModalComponent isvisible={showModal} data={TableData} hideModal={hideModal} />
		</div>
	);
	}
const mapStateToProps = state => {
	return {
userProfile:_.get(state,'app.account.profile.data.details')

	}
};

const mapDispatchToProps = dispatch => ({
	gamesList: details => dispatch(global.redux.action.games.list(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(GamesListComponent)