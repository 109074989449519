import React from "react";
import { Progress } from "antd";

const StepsBar = ({ percent, color, title }) => {
  const format = () => (
    <span style={{ color: "#E42D2D", fontSize: "12px" }}>{`${percent}%`}</span>
  );
  return (
    <div
      className="flex items-center rounded-[5px] w-full text-[#526B84] font-bold text-[12px] h-[48px] justify-between px-[12px] mb-[12px]"
      style={{ border: "1px solid #EAEDF0" }}
    >
      <div className="w-15% flex flex-col text-[10px] font-[700] ">
        <span> {title}</span>
      </div>
      <div className="stepsbar w-[75%] flex items-center flex-row px-[1%] ">
        <div className="w-full bg-blue-300] relative">
          <Progress
            percent={percent}
            steps={10}
            strokeColor={color}
            format={() => `${percent}%`}
            className="custom-progress"
          />
        </div>
      </div>
    </div>
  );
};
export default StepsBar;
