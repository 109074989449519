import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Dropdown,
  Input,
  Card,
  Checkbox,
  message,
  Tooltip,
  Modal,
  Collapse,
  Space,
  Upload,
} from "antd";
import { read, utils, writeFile } from "xlsx";
import bell from "../../../assets/img/bell-notification.svg";
import analytics from "../../../assets/img/analytics.png";
import curveArrowLeft from "../../../assets/img/curve-arrow-left.png";
import chevronLeft from "../../../assets/img/whiteChevronLeft.png";
import chevronRight from "../../../assets/img/whiteChevronRight.png";
import managePlays from "../../../assets/img/managePlay.png";
import pencil from "../../../assets/img/pencil.svg";
import arrowdown from "../../../assets/img/arrowdown.png";
import evaluate from "../../../assets/img/clipboard-check.png";
import cnImg from "../../../assets/img/whiteicons/import.svg";
import erase from "../../../assets/img/erase.svg";
import trashicon from "../../../assets/img/trash.svg";
import EnterPlayModal from "./EnterPlayModal";
import angel from "../../../assets/img/angel 2.svg";
import MessageModal from "../plays-atheletes/MessageModal";

import {
  UserOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import CoachesNote from "./coaches-note";
import "./managelist.css";
import importImg from "../../../assets/img/evaluation-actions/import.svg";
import DeleteModals from "./DeletesModal";
import AngelPopUp from "../../../components/Modals/AngelPopUp";
import NotificationModal from "../../../components/Modals/NotificationModal";

const Option = Select.Option;
const { Panel } = Collapse;
const { confirm } = Modal;

const ManagePlaylist = (props) => {
  const [playsList, setPlaysList] = useState([]);
  const [playsListStock, setPlaysListStock] = useState([]);
  const [playsListLoading, setPlaysListLoading] = useState(true);
  const [playsModal, setPlaysModal] = useState(false);
  const [deletePlays, setDeletePlays] = useState(false);
  const [clearEvaluation, setClearEvaluation] = useState(false);
  const [started, setStarted] = useState(false);
  const [columnTitles, setColumnTitles] = useState({
    number: "PLAY #",
    play_type: "PLAY TYPE",
    result: "RESULT",
    gn: "GN/LS",
    dn: "DN",
    dist: "DIST",
    odk: "ODK",
    def_front: "DEF FRONT",
    blitz: "BLITZ",
    coverage: "COVERAGE",
    off_off_format: "OFF FORMATION",
    off_off_play: "OFF PLAY",
    qtr: "QTR",
    stunt: "STUNT",
  });
  const [showColumnTitles, setShowColumnTitles] = useState(true);
  const [messageModal, setMessageModal] = useState(false);
  const [headerText, setHeaderText] = useState("Header Text");
  const [playId, setPlayId] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const handleHeaderTextChange = (e, name) => {
    const _columnTitles = columnTitles;
    _columnTitles[name] = e.target.value;

    setColumnTitles(_columnTitles);
  };
  const toggleShowColumnTitles = () => {
    setShowColumnTitles(!showColumnTitles);
  };
  const handleHeaderEditComplete = () => {
    // Perform desired action with the updated header text
    console.log("Header text:", headerText);
  };
  // function to open enter play list manually's modal
  const openPlaysModal = () => {
    setPlaysModal(true);
  };

  // function to close the modal
  const closePlaysModal = () => {
    setPlaysModal(false);
  };

  // function to open deletePlays modal
  const openDeletePlays = () => {
    setDeletePlays(true);
  };

  // function to close deletePlays modal
  const closeDeletePlays = () => {
    setDeletePlays(false);
  };

  // function to open clear evaluation modal
  const openClearEvaluation = () => {
    setClearEvaluation(true);
  };

  // function to close clear evaluation modal
  const closeClearEvaluation = () => {
    setClearEvaluation(false);
  };

  const capitalizeString = (str) => {
    return str
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  function showConfirm() {
    confirm({
      title: "",
      content: (
        <div className="font-inter text-[14px] font-medium">
          For the best functionality and the most accurate analytics please be
          sure to import your play info before evaluating games
        </div>
      ),
      okButtonProps: {
        className: "bg-[#1A91FF] border-[2px] border-[#1A91FF] border-solid",
      },
      onOk() {},
      onCancel() {},
    });
  }

  const [seasonsList, setSeasonsList] = useState([]);
  const params = new URLSearchParams(window.location.search);
  let history = useNavigate();
  const isCSVOrExcelFile = (file) => {
    if (file) {
      // Define allowed file types
      const allowedTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];

      return allowedTypes.includes(file?.type);
    } else {
      return false;
    }
  };
  const handleChange = (info) => {
    // Check if the upload process has already started
    if (!started) {
      setStarted(true);
      // Display a loading message
      messageApi.open({
        type: "loading",
        content: "Playlist import in progress...",
        duration: 0,
      });

      // Get the uploaded file
      const file = info.file.originFileObj;

      // Check the file type
      if (!isCSVOrExcelFile(file)) {
        // Display an error message and stop processing
        message.error("Only CSV and Excel files are allowed for upload.");
        setPlaysListLoading(false);
        return;
      } else {
        // Set loading state as the file is valid
        setPlaysListLoading(true);

        // Read the file using FileReader
        const reader = new FileReader();
        reader.onload = (e) => {
          // Read the file data into a Uint8Array
          const data = new Uint8Array(e.target.result);
          // Parse the data as an Excel workbook
          const workbook = read(data, { type: "array" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          // Convert the Excel sheet data to JSON
          const jsonData = utils.sheet_to_json(sheet);
          const game = params.get("id");

          // Helper function to find a value in the object keys using a regex
          const findValueByRegex = (obj, regex) => {
            const keys = Object.keys(obj);
            const matchedKey = keys.find((key) => regex.test(key.toLowerCase()));

            if (matchedKey) {
              return obj[matchedKey];
            } else {
              // No matching key found in the object
              return undefined;
            }
          };

          // Define a regex pattern to match keys containing "form"
          const regex = /form/i;
          const regexQTR = /\bq(uarter|r|tr)?\b/i;
          const regexStunt = /\bstunt\w*\b/i;

          // Map and transform the JSON data
          const _playlist = jsonData.map((row, id) => {
            return {
              number: row[columnTitles["number"]],
              play_type: row[columnTitles["play_type"]],
              result: row[columnTitles["result"]],
              gn: row[columnTitles["gn"]],
              dn: row[columnTitles["dn"]],
              dist: row[columnTitles["dist"]],
              odk: row[columnTitles["odk"]],
              def_front: row[columnTitles["def_front"]],
              blitz: row[columnTitles["blitz"]],
              coverage: row[columnTitles["coverage"]],
              off_off_format: findValueByRegex(row, regex),
              off_off_play: row[columnTitles["off_off_play"]],
              qtr: findValueByRegex(row, regexQTR),
              stunt: findValueByRegex(row, regexStunt),
              game,
            };
          });

          // Create a Set to store unique numbers
          const uniqueNumbers = new Set();

          // Filter the array to remove duplicates based on the 'number' field
          const deduplicatedPlaylist = _playlist.filter((item) => {
            if (!uniqueNumbers.has(item.number)) {
              uniqueNumbers.add(item.number);
              return true;
            }
            return false;
          });

          // Set the deduplicated playlist in state and store it in sessionStorage
          setPlaysList(deduplicatedPlaylist);
          sessionStorage.setItem(
            `${game}-PlaysList`,
            JSON.stringify(deduplicatedPlaylist)
          );

          // Upload the playlist to the server
          props.uploadPlaylist({
            body: {
              game,
              plays: deduplicatedPlaylist,
            },
            callback: (res, data) => {
              setPlaysListLoading(false);
              messageApi.destroy();
              setTimeout(() => {
                setStarted(false);
              }, 10000);
            },
          });
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const handleUpdatePlay = (id, info) => {
    setPlaysListLoading(true);
    props.updatePlay({
      params: {
        id,
      },
      body: {
        id,
        ...info,
      },
      callback: (res, data) => {
        if (res) {
          console.log("update play res", res, data);
          loadPlaysList();
        }
        setPlaysListLoading(false);
      },
    });
  };

  const handleSortPlay = (type) => {
    if (type == "o" || type == "s" || type == "k" || type == "d") {
      const plays = playsListStock.filter(({ odk }) => {
        return odk?.toLowerCase() === type.toLowerCase();
      });

      setPlaysList(plays);
    } else {
      setPlaysList(playsListStock);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={playId.length > 0 && playId.length < playsList.length}
          checked={playId.length === playsList.length}
          onChange={(e) => {
            const allIds = playsList.map((record) => record.id);
            if (e.target.checked) {
              setPlayId(allIds);
            } else {
              setPlayId([]);
            }
          }}
        />
      ),
      dataIndex: "checkbox",
      width: "4%",
      align: "center",
      render: (text, record) => (
        <Checkbox
          checked={playId.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setPlayId((prevIds) => [...prevIds, record.id]);
            } else {
              setPlayId((prevIds) => prevIds.filter((id) => id !== record.id));
            }
          }}
        />
      ),
    },

    {
      title: showColumnTitles ? (
        columnTitles["number"]
      ) : (
        <Input
          defaultValue={columnTitles["number"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "number")}
        />
      ),
      dataIndex: "number",
      align: "center",
      width: "6.5%",
      key: "play",
      render: (item, row) => item || "--",
    },

    {
      title: showColumnTitles ? (
        columnTitles["play_type"]
      ) : (
        <Input
          defaultValue={columnTitles["play_type"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "play_type")}
        />
      ),
      dataIndex: "play_type",
      width: "8%",
      align: "center",
      key: "play_type",
      render: (item, row) => {
        return showColumnTitles ? (
          item
        ) : (
          <Select
            dropdownMatchSelectWidth={true}
            style={{ width: "100%", border: "none" }}
            bordered={false}
            defaultValue={item}
            onChange={(value) => handleUpdatePlay(row.id, { play_type: value })}
            className="w-full h-full  border-none outline-none manage-listtabledataselect"
          >
            <Option value="pass">Pass</Option>
            <Option value="run">Run</Option>
          </Select>
        );
      },
    },

    {
      title: showColumnTitles ? (
        columnTitles["dn"]
      ) : (
        <Input
          defaultValue={columnTitles["dn"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "dn")}
        />
      ),
      dataIndex: "dn",
      width: "5%",
      align: "center",
      key: "dn",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["dist"]
      ) : (
        <Input
          defaultValue={columnTitles["dist"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "dist")}
        />
      ),
      dataIndex: "dist",
      width: "6%",
      align: "center",
      key: "dist",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["result"]
      ) : (
        <Input
          defaultValue={columnTitles["result"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "result")}
        />
      ),
      dataIndex: "result",
      align: "center",
      width: "10%",
      key: "result",
      render: (item, row) => {
        return showColumnTitles ? (
          item
        ) : (
          <Select
            dropdownMatchSelectWidth={true}
            style={{ width: "100%" }}
            bordered={false}
            onChange={(value) => handleUpdatePlay(row.id, { result: value })}
            defaultValue={item}
            className="w-full h-full  border-none outline-none text-[10px] manage-listtabledataselect"
          >
            <Option value="incomplete">Incomplete</Option>
            <Option value="rush">Rush</Option>
            <Option value="complete">Complete</Option>
          </Select>
        );
      },
    },
    {
      title: showColumnTitles ? (
        columnTitles["gn"]
      ) : (
        <Input
          defaultValue={columnTitles["gn"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "gn")}
        />
      ),
      dataIndex: "gn",
      width: "6%",
      align: "center",
      key: "gn",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["odk"]
      ) : (
        <Input
          defaultValue={columnTitles["odk"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "odk")}
        />
      ),
      dataIndex: "odk",
      align: "center",
      width: "6%",
      key: "odk",
      render: (text, row) => {
        return showColumnTitles ? (
          text
        ) : (
          <>
            <Select
              dropdownMatchSelectWidth={true}
              style={{ width: "100%" }}
              bordered={false}
              defaultValue={text?.toLowerCase()}
              onChange={(value) => handleUpdatePlay(row.id, { odk: value })}
              className="w-[100%] mange-listtabledataselect"
            >
              <Option value="">--</Option>
              <Option value="o">O</Option>
              <Option value="d">D</Option>
              <Option value="k">K</Option>
            </Select>
          </>
        );
      },
    },
    {
      title: showColumnTitles ? (
        columnTitles["def_front"]
      ) : (
        <Input
          defaultValue={columnTitles["def_front"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "def_front")}
        />
      ),
      dataIndex: "def_front",
      align: "center",
      width: "5.4%",
      key: "def_front",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["stunt"]
      ) : (
        <Input
          defaultValue={columnTitles["stunt"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "stunt")}
        />
      ),
      dataIndex: "stunt",
      width: "7%",
      align: "center",
      key: "stunt",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["blitz"]
      ) : (
        <Input
          defaultValue={columnTitles["blitz"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "blitz")}
        />
      ),
      dataIndex: "blitz",
      width: "7%",
      align: "center",
      key: "blitz",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["coverage"]
      ) : (
        <Input
          defaultValue={columnTitles["coverage"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "coverage")}
        />
      ),
      dataIndex: "coverage",
      width: "5.4%",
      align: "center",
      key: "coverage",
      render: (item, row) => item || "--",
    },

    {
      title: showColumnTitles ? (
        columnTitles["off_off_format"]
      ) : (
        <Input
          defaultValue={columnTitles["off_off_format"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "off_off_format")}
        />
      ),
      dataIndex: "off_off_format",
      align: "center",
      width: "9%",
      key: "off_off_format",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["off_off_play"]
      ) : (
        <Input
          defaultValue={columnTitles["off_off_play"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "off_off_play")}
        />
      ),
      dataIndex: "off_off_play",
      width: "9%",
      align: "center",
      key: "off_off_play",
      render: (item, row) => item || "--",
    },
    {
      title: showColumnTitles ? (
        columnTitles["qtr"]
      ) : (
        <Input
          defaultValue={columnTitles["qtr"]}
          className="text-[9px]"
          onChange={(e) => handleHeaderTextChange(e, "qtr")}
        />
      ),
      dataIndex: "qtr",
      width: "9%",
      align: "center",
      key: "qtr",
      render: (item, row) => item || "--",
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   width: "4%",
    //   align: "center",
    //   key: "id",

    //   render: (text, item) => (
    //     <div className='flex items-center justify-center'>
    //       <img src={pencil} className='w-[45%]' />
    //     </div>
    //   ),
    // },
  ];
  useEffect(() => {
    loadPlaysList();
    const hasShownConfirmation = sessionStorage.getItem("hasShownConfirmation");
    if (!hasShownConfirmation) {
      showConfirm();
      sessionStorage.setItem("hasShownConfirmation", true);
      setTimeout(() => {
        sessionStorage.removeItem("hasShownConfirmation");
      }, 24 * 60 * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    console.log("play id", playId);
  }, [playId]);

  const loadPlaysList = (limit = 1000, page = 1) => {
    const params = new URLSearchParams(window.location.search);
    let game_id = params.get("id");
    setPlaysListLoading(true);

    let list = JSON.parse(sessionStorage.getItem(`${game_id}-PlaysList`));

    if (list) {
      setPlaysListStock(list);
      setPlaysList(list);
      sessionStorage.setItem(`${game_id}-PlaysList`, JSON.stringify(list));
      setPlaysListLoading(false);
    }

    props.playsList({
      params: { limit, page, game: game_id },
      callback: (res, data) => {
        if (res === true) {
          list = data.data.items;
          setPlaysList(list);
          setPlaysListStock(list);
          sessionStorage.setItem(`${game_id}-PlaysList`, JSON.stringify(list));
        }
        setPlaysListLoading(false);
      },
    });
  };

  const handleAddPlaysManually = (quantity) => {
    setPlaysListLoading(true);
    const game = params.get("id");
    const start = playsList.length < 1 ? 1 : playsList.length;
    const plays = new Array(Number(quantity)).fill("plays");

    const _playlist = plays.map((row, key) => {
      return {
        number: start + key,
        game,
      };
    });
    props.uploadPlaylist({
      body: {
        game,
        plays: _playlist,
      },
      callback: (res, data) => {
        loadPlaysList();
        closePlaysModal();
      },
    });
  };

  const items = seasonsList.map((data) => {
    return {
      label: data.title,
      key: data.id,
    };
  });
  const paramsss = new URLSearchParams(window.location.search);
  const render = () => {
    return (
      <div className="w-[98%] px-[0.5%] mt-[20px] flex flex-col manageplaylist">
        {contextHolder}
        <div className="w-full">
          <div
            className="flex w-[100%] items-center cursor-pointer mb-[1.5vh]"
            onClick={() => {
              history(
                `/season-plays-list?id=${paramsss.get(
                  "id"
                )}&opponent=${paramsss.get("opponent")}&team=${paramsss.get(
                  "team"
                )}&week=${paramsss.get("week")}&t=${paramsss.get("t")}`
              );
            }}
          >
            <img src={curveArrowLeft} className="cursor-pointer" />
            <span className="font-lato text-[14px] font-normal cursor-pointer">
              Back to {paramsss.get("week")} Evaluation
            </span>
          </div>
          <div className="w-full flex items-center justify-between mb-[2.2vh]">
            <div className="w-[100%] flex items-center text-[#0F1A24] ">
              <span className="font-poppins text-[28px] font-bold mr-2">
                Manage {paramsss.get("week")} Plays
              </span>
              <span className="font-inter text-[14px] font-normal mr-2">
                {capitalizeString(paramsss.get("team"))}{" "}
                <span className="text-[#A40E17]"> vs</span>{" "}
                {capitalizeString(paramsss.get("opponent"))}
              </span>
              {/* added the week, team and oppponent to the notification modal */}
              <NotificationModal
                week={
                  paramsss.get("week").slice(0, 5) +
                  "#" +
                  paramsss.get("week").slice(5)
                }
                team={capitalizeString(paramsss.get("team"))}
                opponent={capitalizeString(paramsss.get("opponent"))}
              />
              <AngelPopUp />
            </div>
          </div>
        </div>
        <Card className="w-[100%]" bodyStyle={{ padding: "12px" }}>
          <div className="flex justify-between items-center w-full">
            <div className="w-[440px] flex  items-center">
              <span className="text-[14px] font-inter font-bold mr-2">
                {" "}
                Filter
              </span>
              <Select
                defaultValue={"All"}
                style={{ width: "250px" }}
                onChange={(value) => {
                  handleSortPlay(value);
                }}
              >
                {_.map(
                  [
                    { title: "All Plays", value: "all" },
                    { title: "Offensive Plays", value: "o" },
                    { title: "Defensive Plays", value: "d" },
                    { title: "Special Teams Plays", value: "k" },
                  ],
                  ({ value, title }, index) => {
                    return (
                      <Option value={value} key={value}>
                        {title}
                      </Option>
                    );
                  }
                )}
              </Select>
            </div>
            <div>
              <Upload
                name="file"
                listType="text"
                beforeUpload={(file) => {
                  // Check the file type and return false to prevent the upload
                  if (!isCSVOrExcelFile(file)) {
                    message.error(
                      "Only CSV and Excel files are allowed for upload."
                    );
                    return false;
                  } else {
                    return true; // Allow the upload for CSV and Excel files
                  }
                }}
                onChange={handleChange}
                style={{ margin: "auto" }}
              >
                <button className="bg-[#FF5A1D] text-white rounded py-2 px-3 flex items-center">
                  <img src={cnImg} className="mr-1" />
                  Import Playlist
                </button>
              </Upload>
            </div>
          </div>
          <div
            className="cursor-pointer flex justify-end items-end mt-[2px] 
           font-[700] text-[#2E4D6B]"
            onClick={openPlaysModal}
          >
            <span className="underline">Enter play(s) manually</span>
          </div>
          {/* add plays manually's modal */}
          {playsModal === true && (
            <Modal
              onCancel={closePlaysModal}
              closable={true}
              open={playsModal}
              title={
                <span className="text-[#0F1A24] text-[14px] font-bold font-inter">
                  Enter play(s) manually
                </span>
              }
              style={{
                borderRadius: "8px",
                top: "30%",
              }}
              width={441}
              height={500}
              footer={null}
            >
              {" "}
              <EnterPlayModal
                handleAddPlaysManually={handleAddPlaysManually}
                onCancel={closePlaysModal}
              />
            </Modal>
          )}
          <br />
          <Space wrap>
            <div
              className="bg-[#F6E7E8] text-[12px] font-[bold] cursor-pointer w-[128px] h-[38px] flex items-center justify-center rounded-[4px]"
              onClick={() => {
                if (playId.length == 0) {
                  return message.error(
                    "Please select atleast one play to be deleted"
                  );
                }
                playId.forEach((id) => {
                  handleUpdatePlay(id, { status: 0 });
                });
              }}
            >
              <div className="flex items-center text-[12px] text-[#A40E17] justify-between">
                <img src={trashicon} className="mr-1" />
                <span>Delete Plays</span>
              </div>
            </div>
            <div
              className="bg-[#000] text-[13px]   font-[bold] cursor-pointer w-[120px] h-[36px] flex items-center justify-center rounded-[4px]"
              onClick={toggleShowColumnTitles}
            >
              <div className="flex items-center text-[13px] text-[#fff] justify-between text-bold">
                <span>Edit Plays</span>
              </div>
            </div>
            {/* <div
              className='bg-[#E8F4FF] text-[12px] font-[bold] cursor-pointer w-[170px] h-[36px] flex items-center justify-center rounded-[4px]'
              onClick={openClearEvaluation}>
              <div className='flex items-center text-[12px] text-[#2E4D6B] justify-between w-[80%]'>
                <img src={erase} />
                <span>Clear Evaluation Data</span>
              </div>
            </div> */}

            {/* clear evaluation data modal */}
            {clearEvaluation === true && (
              <DeleteModals
                onOpen={clearEvaluation}
                onCancel={closeClearEvaluation}
                title="Clear Evaluation Data"
                text="You are about to clear multiple evaluations. This action cannot be undone."
              />
            )}
          </Space>

          <div className="manageplaylisttablediv mt-[6px] w-full">
            <Table
              key={1}
              loading={playsListLoading}
              columns={columns}
              pagination={false}
              dataSource={playsList}
              bordered
              size="middle"
              headerRowStyle={{
                backgroundColor: "black !important",
                height: "50px",
                fontSize: "16px",
              }}
            />
          </div>
        </Card>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  playsList: (details) => dispatch(global.redux.action.plays.list(details)),
  updatePlay: (details) => dispatch(global.redux.action.plays.update(details)),
  playsCreate: (details) => dispatch(global.redux.action.plays.create(details)),
  uploadPlaylist: (details) =>
    dispatch(global.redux.action.plays.uploadPlaylist(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlaylist);
