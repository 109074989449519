import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import greenCheck from "../../../assets/img/Check.svg";
import eye from "../../../assets/img/eye-empty.svg";
import download from "../../../assets/img/download.svg";
import mailOut from "../../../assets/img/mail-out.svg";



  const columns = [
    // {
    //   title: "Plan",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "30%",
    //   align: "center",
    // },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "30%",
      align: "center",
      render: (text) => 
        new Date(parseInt(text)).toLocaleDateString('en-US', { 
  weekday: 'long', 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric' 


})
    },  {
      title: "Roster size",
      dataIndex: "roster_size",
      key: "roster_size",
      width: "30%",
      align: "center",

    },

        {
      title: "Is Free Plan",
      dataIndex: "isFreePlan",
      key: "isFreePlan",
      width: "30%",
          align: "center",
          render: (text) => text ? "Yes" : "No"
      
    },
            {
      title: "Is Paid Plan",
      dataIndex: "isPaidPlan",
      key: "isPaidPlan",
      width: "30%",
              align: "center",
          render: (text) => text ? "Yes" : "No"
    },
            {
      title: "Is Trial",
      dataIndex: "isTrial",
      key: "isTrial",
      width: "30%",
              align: "center",
          render: (text) => text ? "Yes" : "No"
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "20%",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "endDate",
      key: "endDate",
      width: "20%",
      align: "center",
      render: (_, record) => (
        <div className="flex items-center justify-between w-[80px] mx-auto">
          <img
            src={eye}
            className="w-[30%] lg:w-[25%] cursor-pointer xl:w-[20px]"
          />
          <img
            src={download}
            className="w-[30%] lg:w-[25%] cursor-pointer xl:w-[20px]"
          />
          <img
            src={mailOut}
            className="w-[30%] lg:w-[25%] cursor-pointer xl:w-[20px]"
          />
        </div>
      ),
    },
  ];

const SubscriptionHistoryComponent = (props) => {
  const [subscriptionData, setSubscriptionData] = useState([]);

  const getSubscriptionHistory = () => {
    props.subscriptionHistory({
      params: { id: props.coachProfile?.school?.id },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from getSubscriptionHistory", data?.data?.subscriptions);
          setSubscriptionData(data?.data?.subscriptions || []);
        }
      },
    });
  };

  useEffect(() => {
    getSubscriptionHistory();
  }, []);


  return (
    <Card className="w-[100%] flex flex-col mt-2" bodyStyle={{ padding: "16px" }}>
      <div className="flex items-center">
        <span className="text-[23px] xl:text-[1.5vw] font-bold">
          Subscription History
        </span>
      </div>
      <br />
      <Table columns={columns} dataSource={subscriptionData} />
    </Card>
  );
};

export default SubscriptionHistoryComponent;

