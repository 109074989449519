import React, { useState } from "react";
import photo from "../../../assets/img/Union.svg";

function FeedbackModal({ sendFeedback, positive_feedback, groupFeedback }) {
  const [feed, setFeed] = useState("");
  const [groupFeed, setGroupFeed] = useState("");
  return (
    <div className="flex flex-col mt-[10px] text-[#0F1A24] font-normsl text-[14px] font-iner">
      <span className="font-bold">Group:</span>
      <textarea
        placeholder={groupFeedback || "Enter Feedback here 😊"}
        defaultValue={groupFeedback || ""}
        disabled={true}
        rows="5"
        cols="40"
        className="h-[108px] border-[1px] border-solid border-[#EAEDF0] p-[10px] mt-[6px] text-[14px]"
      ></textarea>
      <span className="font-bold mt-[16px]">Individualized:</span>
      <textarea
        onChange={(e) => setFeed(e.target.value)}
        placeholder="Enter Feedback here 😊"
        defaultValue={positive_feedback}
        rows="5"
        cols="40"
        className="h-[108px] border-[1px] border-solid border-[#EAEDF0] p-[10px] mt-[6px] text-[14px]"
      ></textarea>
      <button
        className={`w-[100%] mt-[10px] h-[43px] ${
          feed === ""
            ? "bg-[#A9B6C2] text-[#8698A9]"
            : "bg-[#1A91FF] text-[#ffffff]"
        } flex items-center justify-center font-bold`}
        onClick={() => {
          sendFeedback(feed);
        }}
      >
        Submit
      </button>
    </div>
  );
}

export default FeedbackModal;
