import React, { useState } from "react";
import { BrowserRouter as Router, Link, useNavigate  } from "react-router-dom";
import {
	Form,
	Input,
	Button,
	notification
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import _ from "lodash";

import "./login.css";
import loginBG from "../../assets/img/login-bg.png";
import logo from "../../assets/img/logowhite.svg";

const ForgotPasswordComponent = (props) => {

	const [inputEmail, setInputEmail] = useState("");

	const getEmailValue =(e) => {
		setInputEmail(e.target.value);
		// console.log ("Event", e);
	};

	let history = useNavigate();
	const [form] = Form.useForm();
	const lowercaseFirstLetter = (str) =>{

		// converting first letter to lowerCase
		const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());
	
		return lowerCase;
	}
	const submit = (e) => {
		const params = new URLSearchParams(window.location.search);
        let details = {
            email: lowercaseFirstLetter(inputEmail),
        } 
        props.forgotPassword({
            body: details,
            callback: (res, responseData) => {
                if (res == true) {
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    );
                }
				// history( "/login") 
            }
        });
	};

	const render = () => {
		return (
			<div className="LoginContainer">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-sm-4 col-lg-4 pr-0">
							<div className="LoginBG" style={{ backgroundImage: "url("+loginBG+")" }}></div>
						</div>
						<div className="col-12 col-sm-5 col-lg-5 pl-0">
							<div className="LoginpageContainer">
								<div className="LoginLogoContainer">
									<img src={logo} className="PageMainLogo" />
									<span className="LoginPageLoginText text-[#fff]">Forgot your password</span>
									<span className="forgotPasswordInstruction !text-[#fff]">
										Enter your registered email below to receive <br/>
 										password reset instruction
									</span>
								</div>
								<Form
								className="LoginForm"
							      name="basic"
							      initialValues={{ remember: true }}
							      autoComplete="off"
							      layout="vertical"
								  form={form}
							    >
							      <Form.Item
							        label="Email address"
							        name="email"
							        rules={[{ required: true, message: 'Please input your Email address!' }]}
							      >
							        <Input placeholder="eg: name@example.com" onChange={(e)=>getEmailValue(e)}/>
							      </Form.Item>

							      <Form.Item name="remember" valuePropName="checked">
								  <span className="loginFormForgot">Remember password? </span>
							        <Link to="/login" className="loginFormForgot ml-1 text-danger" href="">
								           Login
								        </Link>
								        
							      </Form.Item>

							      <Form.Item>
							        <Button type="primary" htmlType="submit" onClick={submit}>
							          	Send Email
							        </Button>
							      </Form.Item>
							    </Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return render();
};

const mapStateToProps = state => (
    {
        

    });

const mapDispatchToProps = dispatch => ({
    forgotPassword: details => dispatch(global.redux.action.account.requestResetPassword(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent);