import React from "react";
import { Table } from "antd";
import { oColumns } from "./statsColumns";
import { oColumnsPrint } from "./StatsColumnPrint";

function findHighestValue(arr) {
  if (arr.length === 0) {
    return undefined; // Return undefined for empty array
  }
  let max = arr[0]; // Assume first element as maximum
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]; // Update max if current element is greater
    }
  }
  return max;
}
function OStatsTable({ dataSource, pageLoading, print }) {
  return (
    <div className="stats-table print:landscape">
      <Table
        dataSource={dataSource}
        columns={print ? oColumnsPrint : oColumns}
        bordered
        className="w-full"
        loading={pageLoading}
        pagination={false}
        summary={(pageData) => {
          let plays = 0;
          let total_positive_impact = 0;
          let total_carries = 0;

          let total_gn_carries_yds = 0;
          let total_gn_carries_yds_avg = 0;
          let total_max_gn_carries_yds = [];
          // (Number(text) / Number(row.total_carries) || 0).toFixed(2)
          let total_yards_after_contact = 0;
          let total_yards_after_contact_avg = 0;
          let total_rbtd = 0;
          let total_completions = 0;
          let total_drops = 0;
          let total_complete_receptions_yds = 0;
          let total_complete_receptions_yds_avg = 0;
          let total_completions_carries_yds_avg = 0;
          let total_max_completions_carries_yds = [];
          let total_yards_after_catch = 0;
          let total_yards_after_catch_avg = 0;
          let total_wrtd = 0;
          let total_passing_cmp = 0;
          let total_passing_att = 0;
          let total_passing_yds = 0;
          let total_passing_yds_avg = 0;
          let total_passing_lng = [];
          let total_qbtd = 0;
          let total_int = 0;
          let total_caused_fumble = 0;
          let total_fumble_lost = 0;
          let total_TD = 0;
          let total_2pt = 0;
          let total_completions_carries_yds = 0;
          let total_completions_carries_yds_yds = 0;

          pageData?.forEach((data) => {
            plays += Number(data?.plays);
            total_positive_impact += Number(data?.total_positive_impact);
            total_carries += Number(data?.total_carries);
            total_gn_carries_yds += Number(data?.total_gn_carries_yds);

            if (
              !isNaN(data?.total_gn_carries_yds) &&
              !isNaN(data?.total_carries) &&
              Number(data?.total_carries) !== 0
            ) {
              total_gn_carries_yds_avg +=
                Number(data?.total_gn_carries_yds) /
                Number(data?.total_carries);
            }

            total_max_gn_carries_yds.push(Number(data?.max_gn_carries_yds));

            total_yards_after_contact += Number(
              data?.total_yards_after_contact
            );

            if (
              !isNaN(total_yards_after_contact) &&
              !isNaN(data?.total_carries) &&
              Number(data?.total_carries) !== 0
            ) {
              total_yards_after_contact_avg +=
                Number(data?.total_yards_after_contact) /
                Number(data?.total_carries);
            }

            total_rbtd += Number(data?.total_rbtd);

            total_completions += Number(data?.total_completions);

            total_drops += Number(data?.total_drops);

            total_completions_carries_yds += Number(data.total_completions_carries_yds);

            if (
              !isNaN(total_completions_carries_yds) &&
              !isNaN(data?.total_completions) &&
              Number(data?.total_completions) !== 0
            ) {
              total_completions_carries_yds_avg +=
                Number(data?.total_completions_carries_yds) /
                Number(data?.total_completions);
            }

            total_complete_receptions_yds += Number(
              data?.total_complete_receptions_yds
            );

            if (
              !isNaN(total_complete_receptions_yds) &&
              !isNaN(data?.total_complete_receptions) &&
              Number(data?.total_complete_receptions) !== 0
            ) {
              total_complete_receptions_yds_avg +=
                Number(data?.total_complete_receptions_yds) /
                Number(data?.total_complete_receptions);
            }

            total_max_completions_carries_yds.push(
              Number(data?.total_complete_receptions_lng)
            );

            total_yards_after_catch += Number(data?.total_yards_after_catch);
            if (
              !isNaN(data?.total_yards_after_catch) &&
              !isNaN(data?.total_completions) &&
              Number(data?.total_completions) !== 0
            ) {
              total_yards_after_catch_avg +=
                Number(data?.total_yards_after_catch) /
                Number(data?.total_completions);
            }
            total_wrtd += Number(data?.total_wrtd);

            total_passing_cmp += Number(data?.total_complete_receptions);
            total_passing_att +=
              Number(data?.total_complete_receptions) +
              Number(data?.total_incomplete_receptions) +
              Number(data?.total_int);
              
            total_passing_yds += Number(data?.total_completions_carries_yds);

            if (
              !isNaN(data?.total_completions_carries_yds) &&
              !isNaN(data?.total_completions) &&
              Number(data?.total_completions) !== 0
            ) {
              total_passing_yds_avg +=
                Number(data?.total_completions_carries_yds) /
                Number(data?.total_completions);
            }
            total_passing_lng.push(Number(data?.max_completions_carries_yds));
            total_qbtd += Number(data?.total_qbtd);
            total_int += Number(data?.total_int);
            total_caused_fumble += Number(data?.total_caused_fumble);
            total_fumble_lost += Number(data?.total_fumble_lost);
            total_TD +=
              Number(data?.total_wrtd) +
              Number(data?.total_rbtd) +
              Number(data?.total_qbtd);
            total_2pt += Number(data?.total_two_part_conversion);
            total_completions_carries_yds_yds +=
              Number(data?.total_completions_carries_yds) +
              Number(data?.total_gn_carries_yds) +
              Number(data?.total_complete_receptions_yds);
          });
          return (
            <>
              <Table.Summary.Row
                align="center"
                style={{
                  background: "#F3F5F7",
                  fontWeight: "bold",
                }}
              >
                <Table.Summary.Cell index={0} colSpan={print ? 1 : 3}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {plays}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_positive_impact}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_carries}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_gn_carries_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(total_gn_carries_yds_avg / Number(total_carries))?.toFixed(2) }
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {findHighestValue(total_max_gn_carries_yds)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_yards_after_contact}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(
                    Number(total_yards_after_contact) / Number(total_carries)
                  )?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_rbtd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_completions}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_drops}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_completions_carries_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(total_completions_carries_yds_avg / Number(total_completions)) ?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                {findHighestValue(total_passing_lng)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_yards_after_catch}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(
                    Number(total_yards_after_catch) / Number(total_completions)
                  )?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_wrtd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_passing_cmp}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_passing_att}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_complete_receptions_yds}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {(total_complete_receptions_yds_avg / Number(total_passing_cmp))?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  
                  {findHighestValue(total_max_completions_carries_yds)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_qbtd}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_int}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_caused_fumble}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_fumble_lost}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_2pt}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_TD}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {total_completions_carries_yds_yds}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default OStatsTable;
