import React, { useEffect } from 'react'
import { Provider } from 'react-redux';
import RoutesPage from './core_units/routes';
import configStore from './core_units/redux/config/store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/fonts/font-awesome/css/font-awesome.css';
import './assets/fonts/lte-font/lte-ie7.css';
import './assets/css/bootstrap.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

export default function App(props) {
  return (
    <Provider store={configStore()}>
      <BrowserRouter >
        <Routes>
          <Route path='*' element={<RoutesPage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}
 