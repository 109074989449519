import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Label,
} from "recharts";
import { LineChart, Line, ResponsiveContainer } from "recharts";

import { Divider } from "antd";
const formatNum = (val) => {
  if (isNaN(val)) return 0;
  return Math.round(val);
};

function findMinMax(array) {
  let min = Infinity;
  let max = -Infinity;

  array?.forEach((obj) => {
    const combinedValue = obj.Executed;

    if (combinedValue < min) {
      min = combinedValue;
    }
    if (combinedValue > max) {
      max = combinedValue;
    }
  });

  if (max > 95) {
    max = 95;
  }
  return { min, max };
}
function MultiBarChart({ data, title, dataTwo, titleTwo }) {
  // const CustomTooltip = (e) => {
  //   const { active, payload, label } = e;
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className='bg-[#ffffff] p-2 border-2 border-solid border-red-700'>
  //         <p className='text-[12px]'>{title}</p>
  //         <p className='text-[10px] mb-1'>
  //           {label}:{" "}
  //           {formatNum(
  //             (payload[0].value / (payload[1].value + payload[0].value)) * 100
  //           )}
  //           %
  //         </p>
  //         <p className='text-[10px] mb-0'>{`${payload[0].dataKey} ${payload[0].value}`}</p>
  //         <p className='text-[10px] mb-0'>{`${payload[1].dataKey} ${payload[1].value}`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  const data2 = data?.map((d, i) => {
    const d2 = dataTwo[i];
    const total = d.Executed + d.Failed;
    const total2 = d2.Executed + d2.Failed;

    return {
      name: d.instance,
      Executed: isNaN(Math.round((d.Executed / total) * 100))
        ? 0
        : Math.round((d.Executed / total) * 100),
      Failed: isNaN(Math.round((d2.Executed / total2) * 100))
        ? 0
        : Math.round((d2.Executed / total2) * 100),
    };
  });

  const { min: minY, max: maxY } = findMinMax(data2);

  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={10}
        fontWeight="700"
        fill="#526B84"
        fontSize="10px"
        textAnchor="middle"
      >
        {payload.value}
      </text>
    );
  };

  const Legend = ({
    fill,
    title,
    firstDownPercent,
    secondDownPercent,
    thirdDownPercent,
    fourthDownPerct,
  }) => {
    return (
      <div
        className={`w-full h-[44px] rounded-[5px] p-[12px] flex items-center text-[12px] font-bold text-[${fill}]`}
        style={{ border: "1px solid #EAEDF0" }}
      >
        <div className="flex items-center w-full">
          <div className="flex items-center w-[25%]">
            <div
              className={`w-[12px] h-[12px] rounded-full bg-[${fill}] mr-[4px]`}
            ></div>
            <span className="mt-[3px] mr-[4px]">{title}</span>
            <Divider type="vertical" style={{ height: "30px" }} />
          </div>
          <div className="w-[70%] flex items-center justify-around">
            <div className="flex flex-col">
              <span className="text-[8px] text-[#526B84]">1st down</span>
              <span>{isNaN(firstDownPercent) ? 0 : firstDownPercent}%</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[8px] text-[#526B84]">2nd down</span>
              <span>{isNaN(secondDownPercent) ? 0 : secondDownPercent}%</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[8px] text-[#526B84]">3rd down</span>
              <span>{isNaN(thirdDownPercent) ? 0 : thirdDownPercent}%</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[8px] text-[#526B84]">4th down</span>
              <span>{isNaN(fourthDownPerct) ? 0 : fourthDownPerct}%</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-[100%] px-2 mx-auto flex font-inter flex-col justify-between items-center p-0 pb-[24px]">
      {/* legend div */}
      <div className="flex flex-col w-[100%] mx-auto mb-[24px] text-[12px] font-inter">
        <Legend
          title={title}
          firstDownPercent={Math.round(
            (data[0].Executed / (data[0].Failed + data[0].Executed)) * 100
          )}
          secondDownPercent={Math.round(
            (data[1].Executed / (data[1].Failed + data[1].Executed)) * 100
          )}
          thirdDownPercent={Math.round(
            (data[2].Executed / (data[2].Failed + data[2].Executed)) * 100
          )}
          fourthDownPerct={Math.round(
            (data[3].Executed / (data[3].Failed + data[3].Executed)) * 100
          )}
          fill="#1A91FF"
        />
        <div className="mt-[8px] mb-[16px]">
          <Legend
            title={titleTwo}
            firstDownPercent={Math.round(
              (dataTwo[0].Executed /
                (dataTwo[0].Failed + dataTwo[0].Executed)) *
                100
            )}
            secondDownPercent={Math.round(
              (dataTwo[1].Executed /
                (dataTwo[1].Failed + dataTwo[1].Executed)) *
                100
            )}
            thirdDownPercent={Math.round(
              (dataTwo[2].Executed /
                (dataTwo[2].Failed + dataTwo[2].Executed)) *
                100
            )}
            fourthDownPerct={Math.round(
              (dataTwo[3].Executed /
                (dataTwo[3].Failed + dataTwo[3].Executed)) *
                100
            )}
            fill="#A40E17"
          />
        </div>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data2}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              padding={{ left: 30, right: 30 }}
              tickLine={false}
            />
            <YAxis
              domain={[minY - 10, maxY + 5]}
              padding={{ bottom: 10, top: 10 }}
              tickLine={false}
            />
            <Tooltip />
            <Line
              type="linear"
              dataKey="Executed"
              stroke="#1A91FF"
              activeDot={{ r: 16 }}
              strokeWidth={4}
            />
            <Line
              strokeWidth={4}
              type="linear"
              dataKey="Failed"
              stroke="#A40E17"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
export default MultiBarChart;
