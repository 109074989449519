//for modal
import {
  Switch,
  Card,
  Row,
  Col,
  Tooltip,
  Button,
  Form,
  Input,
  Checkbox,
} from "antd";
import {
  QuestionCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Modal } from "antd";
import StripePayment from './StripePayment'
import greenCheck from "../../../assets/img/Check.svg";
import { useState } from "react";
import visa from "../../../assets/img/Visa.svg";
import masterCard from "../../../assets/img/Mastercard.svg";
import stripe from "../../../assets/img/Stripe.svg";
import discover from "../../../assets/img/Discover.svg";
import cardSecurity from "../../../assets/img/card-security.svg";

const ChequeMethod = ({sendInvoice,coachProfile,subscription}) => {
  const { Item } = Form;
  const onFinish = (values) => {
    console.log("Received values:", values);
    handleSendInvoice(values.email)
  };

  const handleSendInvoice = (email) => {
    console.log('subscription',subscription)
    sendInvoice({
      body: {
        school: coachProfile?.school?.id,
        plan: subscription?.id,
        amount: parseInt(subscription?.price),
        email,
        others: {
        currency: 'usd',
        payment_terms: 'Billed Via Purchase Order',
        items: [{name: `Subscription to ${subscription?.name}`, quantity: 1, unit_cost: subscription?.price}],
        notes: 'Thanks for being an awesome customer!',
        terms: 'You will be billed for this invoice once a year.'
    }
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from send invoice", data?.data);
          // setPlansList(data?.data || []);
        }
        // setPlansListLoading(false);
      },
    });  
  };

  return (
    <div className="">
      <div>{/* card images go here */}</div>
      <Form name="paymentForm" onFinish={onFinish}>
       
        <Item
          name="email"
          placeholder="Authorized Email Address"
          rules={[
            { required: true, message: "Authorized Email Address is required" },
          ]}
        >
          <div>
            <span className="text-[#0F1A24] text-[14px] font-normal">
              Authorized Email Address
            </span>
            <Input size="large" />
          </div>
        </Item>
      
        <Item>
          <div className="w-full">
            <button
              className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
              type="submit"
            >
              <span>Submit Your Request</span>
            </button>
          </div>
        </Item>
      </Form>
    </div>
  );
};

const CardMethod = ({coachProfile, subscription}) => {
  const { Item } = Form;
  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <div className="">
      <StripePayment coachProfile={coachProfile} subscription={subscription} />
    </div>
  );
};

const PaymentModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedPlan,
  roasterSize,
  coachProfile,
  sendInvoice,
  subscription
}) => {
  const [paymentMethod, setPaymentMethod] = useState("card");

  const handleChecked = (checked) => {
    const value = checked ? "check" : "card";
    setPaymentMethod(value);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalContent = (
    <div className="flex justify-between pt-[5%]">
      <div
        className="py-3 px-4 w-[49%] rounded-[10px]"
        style={{
          border: "2px solid #EAEDF0",
        }}
      >
        <h6 className="text-center text-[18px] font-bold font-poppins">
          CHOOSE YOUR PAYMENT METHOD
        </h6>
        <div className="flex justify-around text-sm w-[80%] mx-auto font-bold mt-8 payment-method">
          <p
            className={`${
              paymentMethod === "card" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            }`}
          >
            PAY VIA CARD
          </p>
          <Switch size="sm" className="rounded-full" onChange={handleChecked} />
          <p
            className={`${
              paymentMethod === "check" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            }`}
          >
            VIA PAY CHECK
          </p>
        </div>
        <span className="mt-12 text-center text-base">
          Annual charge after 30 day trial is{" "}
          <span className="font-bold ml-1"> ${selectedPlan?.price}</span>
        </span>
        <div className="flex items-center justify-between mt-2 w-[50%] mx-auto">
          <img src={discover} alt="discover" className="w-[22%]" />
          <img src={stripe} alt="stripe" className="w-[22%]" />
          <img src={visa} alt="visa" className="w-[22%]" />
          <img src={masterCard} alt="masterCard" className="w-[22%]" />
        </div>
        <div className="mt-4">
          {paymentMethod === "check" ? <ChequeMethod coachProfile={coachProfile} sendInvoice={sendInvoice} subscription={subscription} /> : <CardMethod coachProfile={coachProfile} sendInvoice={sendInvoice} subscription={subscription} />}
          {/* <CardMethod /> */}
        </div>
      </div>
      <div
        className="w-[49%] rounded-[10px]"
        style={{
          border: "2px solid #EAEDF0",
        }}
      >
        {/* Content for the second half of the modal */}
        <div className="flex flex-col col-span-1 ">
          <div
            className="flex justify-between pb-2"
            style={{
              borderBottom: "2px solid #EAEDF0",
            }}
          >
            <div className="p-2">
              <span className="text-sm font-bold font-poppins">
                {selectedPlan?.name}
              </span>
            </div>
            {selectedPlan?.currentPlan && (
              <span className="flex w-[102px] h-[27px] items-center justify-center text-xs  font-bold bg-[#1A91FF] text-[#ffffff] rounded-bl-[8px]">
                <span>Current Plan</span>
              </span>
            )}
          </div>
          <div className="p-3">
            <div className="flex items-center justify-between">
              <div className="text-base w-[60%] flex items-center font-bold font-poppins">
                <span>
                  {selectedPlan.numberOfPlayers
                    ? selectedPlan.numberOfPlayers
                    : roasterSize}
                </span>
                <span className="ml-[3%] text-[10px] font-normal">
                  Varsity Players{" "}
                </span>
              </div>
              <span className="text-[20px] font-bold">
                <sup className="text-[10px] font-normal">US$</sup>
                {selectedPlan?.price}
                <span className="text-[10px] font-normal">/Season</span>
              </span>
            </div>
            <div className="flex flex-col justify-between mt-3 italic font-inter text-sm font-normal">
              <span className="leading-[150%]">
                {selectedPlan?.description}
              </span>
            </div>
            <div className="mt-4">
              <span className="font-bold" style={{ fontStyle: "normal" }}>
                Everything with Free Productivity PLUS!
              </span>
            </div>
            <div className="flex flex-col">
              {selectedPlan?.features?.map((feature, index) => (
                <div className="flex items-center mt-4" key={index}>
                  <img src={greenCheck} />
                  <span className="ml-[1%]">{feature}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        title=""
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="min-w-[700px] xl:min-w-[900px]"
        style={{ top: 20 }}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default PaymentModal;
