import React from "react";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function DeleteModals({
  onOpen,
  onCancel,
  title,
  text,
  buttonText,
  onDelete,
  loading,
}) {
  return (
    <Modal
      onCancel={onCancel}
      closable={true}
      open={onOpen}
      title={
        <span className="text-[#0F1A24] text-[14px] font-bold font-inter">
          {title}
        </span>
      }
      width={420}
      footer={null}
      style={{
        borderRadius: "8px",
        top: "30%",
      }}
    >
      <div className="flex flex-col mt-[12.5px] font-inter">
        <span className="text-[14px] font-normal">{text}</span>
        <div className="mt-[32px] flex items-center justify-between">
          <div
            className="h-[43px] w-[48%] flex items-center justify-center rounded-[4px] cursor-pointer text-[14px] font-bold border-[1px] border-solid border-[black]"
            onClick={onCancel}
          >
            <span>Cancel</span>
          </div>
          {buttonText ? (
            <button
              className="h-[43px] w-[48%] flex items-center justify-center rounded-[4px] cursor-pointer bg-[#A40E17] text-white text-[14px] font-bold"
              onClick={() => onDelete()}
              disa
            >
              {loading && <LoadingOutlined className="mr-1" />}
              <span>{buttonText}</span>
            </button>
          ) : (
            <button
              className="h-[43px] w-[48%] flex items-center justify-center rounded-[4px] cursor-pointer bg-[#A40E17] text-white text-[14px] font-bold"
              onClick={() => onDelete()}
            >
              {loading && <LoadingOutlined className="mr-1" />}
              <span>Confirm Delete</span>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModals;
