import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom'
import { Table, Button, Select, Pagination, InputNumber, Collapse } from 'antd'
import {
  UserOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import './coaches.css'

const Option = Select.Option
const { Panel } = Collapse

const CoachSchedule = props => {
  const [TableData, setTableData] = useState('')
  const [showModal, setshowModal] = useState(false)
  const [gamesList, setGamesList] = useState([])
  const [gamesListMeta, setGamesListMeta] = useState({})
  const [gamesListLoading, setGamesListLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [seasonsList, setSeasonsList] = useState([])

  let history = useNavigate()

  const columns = [
    {
      title: 'Team',
      dataIndex: 'title',
      align: 'center',
      key: 'title'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      align: 'center',
      key: 'created_at'
    },
    {
      title: 'Week',
      dataIndex: 'week',
      align: 'center',
      key: 'week'
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      align: 'center',
      key: 'start_time'
    }
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center',
    // 	key: 'action',
    // 	render: (text, item) => (
    // 		<div className="games-list-action-btns">
    // 			<Button onClick={()=>history(`/season-plays-list?id=${_.get(item,'game_id')}`)} className="TableActionBtn ml-2" >Evaluations</Button>
    // 		</div>
    // 	),
    // },
  ]

  useEffect(() => {
    if (_.get(props, 'coachProfile.id')) {
      loadGamesList()
    }
  }, [])

  useEffect(() => {
    loadSeasonsList()
  }, [])

  const loadSeasonsList = () => {
    let params = { limit: 100 }
    props.seasonsList({
      params,
      callback: (res, data) => {
        if (res === true) {
          setSeasonsList(_.get(data, 'data.items', []))
        }
      }
    })
  }

  const loadGamesList = seasonID => {
    let params = {
      limit: 999999,
      home_team: _.get(props, 'coachProfile.school.id'),
      sort: 'week.asc,created_at.desc',
      season: seasonID
    }
    setGamesListLoading(true)
    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setAllGamesList(_.get(data, 'data.items', []))
        }
        setGamesListLoading(false)
      }
    })
  }

  const setAllGamesList = data => {
    let weekFormat = 'Week'
    let list = []
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          title: _.startCase(_.get(value, 'title')),
          season: _.get(value, 'season'),
          created_at: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('MM/DD/YYYY')
            : '---',
          week: weekFormat.concat(' ', _.get(value, 'week')),
          start_time: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('h:mm A')
            : '---',
          end_time: moment(
            `${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`
          ).format('h:mm A'),
          duration: _.get(value, 'duration'),
          game_id: _.get(value, 'id')
        })
      })

      setGamesList(list)
    } else {
      setGamesList([])
    }
  }

  const hideModal = () => {
    setshowModal(false)
  }

  const onChangePage = (page, size) => {
    setPage(page)
    loadGamesList(size, page)
  }

  const getDropList = () => {
    const year = new Date().getFullYear()
    const start_year = year - 2
    const end_year = year + 8
    const start_year_split = parseInt(`${start_year}`.slice(2))

    return Array.from(new Array(30), (v, i) => (
      <Option
        key={i}
        value={`${start_year + i}-${start_year_split + i + 1}`}
      >{`${start_year + i}-${start_year_split + i + 1}`}</Option>
    ))
  }

  function onChange (value) {
    
  }

  const onchangeFilter = (value, e) => {
    
  }

  const onselectFilter = e => {
    loadGamesList(10, page, e)
  }

  const onCollapseChange = value => {
    if (!_.isEmpty(value)) {
      loadGamesList(value)
    } else {
      setAllGamesList([])
    }
  }

  const render = () => {
    return (
      <div className='HomePageContainer evaluvationPage'>
        <div className='container'>
          <div className='pageTitle'>Schedule</div>
          <div className='full-width'>
            <Collapse accordion onChange={value => onCollapseChange(value)}>
              {_.map(seasonsList, seasonData => (
                <Panel
                  header={_.get(seasonData, 'title')}
                  key={_.get(seasonData, 'id')}
                >
                  <div className='TableContainer'>
                    <Table
                      bordered
                      key={_.get(seasonData, 'id')}
                      loading={gamesListLoading}
                      columns={columns}
                      dataSource={gamesList}
                      size='middle'
                      pagination={false}
                    />
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = state => {
  return {
    coachProfile: _.get(state, 'app.account.profile.data.details')
  }
}

const mapDispatchToProps = dispatch => ({
  gamesList: details => dispatch(global.redux.action.games.list(details)),
  seasonsList: details => dispatch(global.redux.action.seasons.list(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoachSchedule)
