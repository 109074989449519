import React, { useState, useEffect } from "react";
import { Card, Table, Select } from "antd";

import eye from "../../../assets/img/eye-empty.svg";
import download from "../../../assets/img/download.svg";
import mailOut from "../../../assets/img/mail-out.svg";
import PaymentModal from "./PaymentModal";
import SubscriptionHistoryComponent from "./SubscriptionHistory";
import PricePlanCard from "./PricePlanCard";
import _, { filter, isEmpty } from "lodash";
import { connect } from "react-redux";
import "./payment.css";

const { Option } = Select;

const _plans = {
  1: [
    "Supports Coaches with Consistent Systems",
    "Customizeable Depth Charts",
    "Group Feedback",
    "Individualized Feedback",
    "Easily Import Play Info",
    "Sorts by O, D, K",
    "Printable Coaches Notes",
    "Emphasizes Special Teams",
    "Saves and Organizes Feedback",
  ],
  2: [
    "Liberates Coaches to Work More Fluently",
    "Removes Time Constraints",
    "Supports Players with Consistent Systems",
    "Free Player Mobile Download",
    "24/7 Player Access",
    "Delivers Feedback to Players Instantly",
    "Tracks Player Logins",
    "Play by Play Player Accountability",
    "Creates Coach to Player Worklfow",
    "Bridges the Learning Gap for Players",
    "Increased Player Awareness",
    "Increased Player Inititiave",
  ],
  3: [
    "Execution Analytics Sortable by ODK and Down/Dist",
    "Effort Analytics Sortable by ODK and Down/Dist",
    "Formation/Play Performance Analytics",
    "Rejuvenates Timeless Coaching Practices",
    `Detailed 'Unit Specific' Special Teams Analysis`,
    `Flag Reporting by Down and Position Groups`,
    `Primary Evaluation Metrics: Execution and Effort`,
    `Coach Driven Performance Data`,
    `Customizeable Reports`,
    `Exposes Never Before Seen Trends`,
    `Promotes Performance over Statistics with Meaningful Analytics`,
    `Position Specific Evaluation Criteria`,
    `Multiplies the Work of Coaches`,
    `Provides Opportunities to Refine Coaching Resources`,
    `Ensures that Players Peak Mentally at the Right Time!`,
    `Eliminates Complicated Spreadsheets`,
    `Installs Trust through Healthy Transparency`,
  ],
};

function SubscriptionPage(props) {
  const [seeFeatures, setSeeFeatures] = useState(false);
  const [biling, setBiling] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [roasterSize, setRoasterSize] = useState(45);
  const [plansList, setPlansList] = useState([]);
  const [plansListLoading, setPlansListLoading] = useState(false);

  const toggleFeatures = (index) => {
    setSeeFeatures((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const getPlansList = () => {
    setPlansListLoading(true);
    props.plansList({
      params: {},
      callback: (res, data) => {
        if (res === true) {
          const plans = data?.data.map((plan) => {
            plan.features = _plans[plan.id] || [];
            return plan;
          });
          setPlansList(plans);
        }
        setPlansListLoading(false);
      },
    });
  };

  useEffect(() => {
    if (props?.coachProfile?.id) {
      fetchSchoolSubscription(props.coachProfile.school.id);
    }
  }, [props.coachProfile]);

  const fetchSchoolSubscription = (id) => {
    props.getSchoolSubscription({
      params: { id },
      callback: (res, responseData) => {},
    });
  };

  useEffect(() => {
    getPlansList();
  }, []);
  useEffect(() => {
    if (props.coachProfile) {
      getProfile();
    }
  }, []);
  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        console.log("getProfile", data);
      },
    });
  };

 

 

  return (
    <div className="w-[98%] flex-col flex p-2">
      <div className="flex flex-col">
        <span className="text-[23px] xl:text-[1.5vw] font-bold">
          Subcriptions
        </span>
        <span className="text-sm font-inter">
          Add your card details to continue or start enjoying premium features.
        </span>
      </div>
      <div className="w-full mt-[20px] mb-[20px]">
        <Card
          className="w-[100%] flex flex-col"
          bodyStyle={{ padding: "16px" }}
        >
          <div className="flex items-center">
            <span className="text-[23px] xl:text-[1.5vw] font-bold">Plans</span>
            <div className="bg-[#D9D9D9] h-[35px] w-[1px] mx-4"></div>
            {props?.schoolSubscriptionDetails?.plan?.name ? (<span className="text-sm font-bold">
 
              You are currently on the {props?.schoolSubscriptionDetails?.plan?.name}{' '} 
              {
                props?.schoolSubscriptionDetails?.subscription?.isTrial ?
                  (<span className="text-[#ED780B]"> Your trial plan will expire on{' '}
                    {new Date(new Date(props?.schoolSubscriptionDetails?.subscription.startDate).setDate(new Date(props?.schoolSubscriptionDetails?.subscription.startDate).getDate() + 30))
                      .toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                  </span>
                  ) :
                  (<span className="text-[#ED780B]">that will expire on {new Date(parseInt(props?.schoolSubscriptionDetails?.subscription?.endDate)).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</span>)}
 
            </span>) : ''}
          </div>
          <div className="grid grid-cols-3 mt-8 gap-4">
            {plansList.map((plan, index) => (
              <PricePlanCard
                plan={plan}
                index={index}
                setSelectedPlan={setSelectedPlan}
                setPaymentModal={setPaymentModal}
                schoolSubscriptionDetails={
                  props?.schoolSubscriptionDetails || {}
                }
               
              />
            ))}
          </div>
        </Card>
        {/* <Card
          className="w-[100%] flex flex-col mt-2"
          bodyStyle={{ padding: "16px" }}
        >
          <div className="flex flex-col">
            <div className="flex items-center">
              <span className="text-[23px] xl:text-[1.5vw] font-bold">
                Billing and payment
              </span>
              <div className="bg-[#D9D9D9] h-[35px] w-[1px] mx-4"></div>
              <span className="text-sm font-normal">
                Add your card details to continue or start enjoying premium
                features.
              </span>
            </div>
            <div className="mt-4 flex items-center text-sm font-inter font-bold">
              <div>
                <button
                  style={{ border: "1px solid #1A91FF" }}
                  className="px-4 py-2 rounded-md text-[#1A91FF]"
                  onClick={() => {
                    setBiling(!biling);
                  }}
                >
                  {biling ? "Edit Billing Payment" : " Add your Card"}
                </button>
                {biling && (
                  <div className="mt-3 flex flex-col text-[#0F1A24] font-inter text-sm font-normal">
                    <span>Visa Ending ****424242</span>
                    <span className="mt-3">US$ 0.00/year </span>{" "}
                    <span>Next payment on Jan 5, 2024</span>{" "}
                    <span>Annual plan, </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-[#D9D9D9] h-[35px] w-[1px] mx-4"></div>
                <span className="font-normal">or</span>
                <div className="bg-[#D9D9D9] h-[35px] w-[1px] mx-4"></div>
              </div>
              <button
                style={{ border: "1px solid #1A91FF" }}
                className="px-4 py-2 rounded-md text-[#1A91FF]"
              >
                Request Purchase Order
              </button>
            </div>
          </div>
        </Card> */}

        <SubscriptionHistoryComponent
          subscriptionHistory={props.subscriptionHistory}
          coachProfile={props.coachProfile}
        />
      </div>
      {paymentModal && (
        <PaymentModal
          isModalVisible={paymentModal}
          setIsModalVisible={setPaymentModal}
          selectedPlan={selectedPlan}
          roasterSize={roasterSize}
          sendInvoice={props.sendInvoice}
          coachProfile={props.coachProfile}
          subscription={selectedPlan}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
    schoolSubscriptionDetails: _.get(state, "app.schools.subscription.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
  plansList: (details) =>
    dispatch(global.redux.action.super_admin.plansList(details)),
  sendInvoice: (details) =>
    dispatch(global.redux.action.schools.send_invoice(details)),
  subscriptionHistory: (componentRequest) =>
    dispatch(global.redux.action.schools.subscriptionHistory(componentRequest)),
  getSchoolSubscription: (details) =>
    dispatch(global.redux.action.schools.subscription(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
