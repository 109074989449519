import React from "react";
import { Modal } from "antd";
import warning from "../../assets/img/warning-triangle-outline.svg";

function ComingSoon({
  open,
  onClose,
  title,
  bg,
  textColor,
  imgOne,
  imgTwo,
  pOne,
  pTwo,
}) {
  
  return (
    <Modal
      width={416}
      open={open}
      onCancel={onClose}
      closable={true}
      footer={null}
      height={182}>
      <div className='flex flex-col w-full'>
        <span className='font-bold font-inter mt-[8px] text-[14px] text-[#0F1A24]'>
          {title}:
        </span>
        <div
          className={`flex bg-[${
            bg ? bg : "#f0decd"
          }] w-[full] mt-[8px] justify-between font-inter text-[12px] font-bold text-[${
            textColor ? textColor : "#ED780B"
          }] rounded-[4px] px-[15px] py-[10px]`}>
          <img
            src={imgOne ? imgOne : warning}
            className='w-[7%] mr-[9px]'
          />
          <span className={`w-[90%]`}>
            {pOne
              ? pOne
              : "Currently Under Construction: This will be ready for the 2023 season."}
          </span>
        </div>
        <div className='flex bg-[#EAEDF0] w-[full] mt-[8px] justify-between font-inter text-[12px] font-bold text-[#2E4D6B] rounded-[4px] px-[15px] py-[10px]'>
          <img src={imgTwo} className='w-[7%] mr-[9px]' />
          <span className='w-[90%]'>{pTwo}</span>
        </div>
      </div>
    </Modal>
  );
}

export default ComingSoon;
