import React, { useEffect, useState } from "react";
import { Modal, Input, Table, Checkbox, Button, Switch } from "antd";
import { AreaChartOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { columnsK, columnsO, columnsD, columnsST } from "./VerifyStatsCols";

const { TextArea } = Input;

const CheckboxWithBackground = ({ value, color }) => (
  <div className={color}>
    <Checkbox checked={value} disabled />
  </div>
);

const renderBooleanResult = (value, row, colorClass) => ({
  children: <CheckboxWithBackground value={!!value} color={colorClass} />,
  props: {},
});

const PlayerTable = ({ grades, play, athletesList }) => {
  const columns = {
    k: columnsK(play),
    o: columnsO(play),
    d: columnsD(play),
    dt: columnsST(play),
  };

  const result = grades.map((grade) => {
    const athlete = athletesList.filter(
      ({ athlete }) => athlete.id == grade.athlete
    )[0];

    if (athlete) {
      let found = grade;
      found.first_name = athlete?.athlete?.first_name;
      found.last_name = athlete?.athlete?.last_name;

      return found;
    }

    return grade;
  });
  return (
    <Table
      columns={columns[play?.odk?.toLowerCase()]}
      dataSource={result}
      size="middle"
      pagination={false}
      bordered
    />
  );
};

const VerifyStats = ({
  play,
  playInfoColumns,
  grades,
  athletesList,
  toggleEditorMode,
  editorMode: eMode,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [editorMode, setEditorMode] = useState(false);

  useEffect(() => {
    setEditorMode(eMode);
  }, [eMode]);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const handleEditorMode = () => {
    if (!editorMode) handleCancel();
    toggleEditorMode();
  };
  return (
    <div className="flex w-[150px]">
      <button className="btn flex " onClick={() => showModal()}>
        <span className="btn px-1 py-0  btn-danger  ">
          <AreaChartOutlined className="relative bottom-[2px]" />
        </span>
        <span className="text-danger font-[600] relative top-[2px] ">
          {" "}
          Play Report
        </span>
      </button>

      {isModalVisible && (
        <Modal
          title={`Play Report`}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={1100}
        >
          <div className="flex justify-between">
            <div className="flex items-center">
              <div className="mr-[12px]  bg-[#dee2e6] rounded-[8px] h-[25px] ">
                {editorMode ? (
                  <Switch
                    onChange={handleEditorMode}
                    defaultChecked
                    value={true}
                  />
                ) : (
                  ""
                )}
                {editorMode ? (
                  ""
                ) : (
                  <Switch onChange={handleEditorMode} value={false} />
                )}
              </div>
              <p className="m-[auto]">Enable editor Mode to Edit Stats</p>
            </div>

            <div className="flex justify-end mb-3">
              <div className="flex datas-center font-bold">
                <div className="flex flex-col mr-6 text-center">
                  <span className="text-[8px] text-[#526B84]">Execution</span>
                  <span className="text-[18px] text-[#00B21E]">
                    {(
                      (grades.reduce((a, b) => a + b?.assignment_executed, 0) /
                        grades.length) *
                      100
                    ).toFixed(2)}
                    % <br />
                  </span>
                </div>
                <div className="flex flex-col text-center mr-2">
                  <span className="text-[8px] text-[#526B84]">Effort</span>
                  <span className="text-[18px] text-[#00B21E]">
                    {(
                      (grades.reduce((a, b) => a + b?.great_effort, 0) /
                        grades.length) *
                      100
                    ).toFixed(2)}
                    % <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Table
            pagination={false}
            columns={playInfoColumns}
            dataSource={[play]}
            bordered
            className="mb-3"
          />

          <PlayerTable
            athletesList={athletesList}
            grades={grades}
            play={play}
          />
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  coachProfile: state?.app?.account?.profile?.data?.details,
});

const mapDispatchToProps = (dispatch) => ({
  addComment: (details) =>
    dispatch(global.redux.action.plays.addComment(details)),
  archiveComment: (details) =>
    dispatch(global.redux.action.plays.archiveComment(details)),
  getCommentsForPlay: (details) =>
    dispatch(global.redux.action.plays.getCommentsForPlay(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyStats);
