import React from "react";
import questionMark from "../../assets/img/question-mark-circle.svg";
import { Table } from "antd";
import FormationTableHead from "./FormationTableHead";
import FormationTableData from "./FormationTableData";

function FormationTripTable({ formation, data, total, datax, count }) {
  // const yards = data?.forma?.filter((item) => {
  //   if (item.formation == formation) {
  //     count = count + parseInt(item.count_play)
  //     return true;
  //   } return false
  // })
  // const percentile = data?.formax?.filter((item) => item.formation == formation)

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between text-[#FFFFFF] bg-[#0F1A24] rounded-t-[8px] p-[12px]">
        <div className="flex items-center text-[14px] font-bold">
          <span>{formation}</span>
          <span className="ml-[16px]">
            {" "}
            ({parseInt((count / total) * 100)}%) {count} of {total}
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <FormationTableHead />

        {data?.map((yard) => {
          const x = datax?.filter(
            (item) => item.play_type == yard.play_type
          )[0];

          return (
            <FormationTableData
              col1={yard.play_type}
              col2a={yard?.count}
              col2b={count}
              col3={yard?.total_yards || 0}
              col4={yard?.total_yards / yard?.count || 0}
              col5={x?.executed || "--"}
              col6={x?.effort || "--"}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FormationTripTable;
