import React, { useState, useEffect } from "react";

const AggregatedResults = ({ jsonStrings }) => {
  const [aggregatedResult, setAggregatedResult] = useState({});

  useEffect(() => {
    // Function to aggregate the keys and sum their values
    function aggregateJsonKeys(jsonStrings) {
      const aggregatedResult = {};
      jsonStrings.forEach((jsonString) => {
        const jsonObject = JSON.parse(jsonString);
        for (const [key, value] of Object.entries(jsonObject)) {
          aggregatedResult[key] = (aggregatedResult[key] || 0) + Number(value);
        }
      });
      return aggregatedResult;
    }

    const result = aggregateJsonKeys(jsonStrings);
    setAggregatedResult(result);
  }, [jsonStrings]);

  return (
    <div className="flex flex-col w-[100%] px-2">
      {Object.entries(aggregatedResult).map(([key, value]) => (
        <ul key={key} className="flex text-start flex-col">
          <li className="font-bold ">{key}:</li>
          <li>{value}</li>
        </ul>
      ))}
    </div>
  );
};

export default AggregatedResults;
