import { Icon, Dropdown, Badge, Menu, Input, AutoComplete, Button } from "antd";
import { Alert, Space, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import logo from "../../assets/img/logo.png";
import teamLogo from "../../assets/img/team-logo.png";
import UserImg from "../../assets/img/user-img1.png";
import { connect } from "react-redux";
import _ from "lodash";
import AngelPopUp from "../Modals/AngelPopUp";
import NotificationModal from "../Modals/NotificationModal";
import {
  FreePlanAlert,
  TrialPlanAlert,
  WarningEmailVerificationAlert,
  PlanExpiredAlert,
} from "./Alerts";
import profileIcon from "../../assets/img/profile-circled.svg";
import card from "../../assets/img/mastercard-card.svg";
import settings from "../../assets/img/settings.svg";
import logoutIcon from "../../assets/img/log-out.svg";

const PageHeader = (props) => {
  let history = useNavigate();
  const [menuVisible, setmenuVisible] = useState(false);
  const [userProfile, setuserProfile] = useState({});
  const [schoolDetailsView, setschoolDetailsView] = useState({});
  const [userProfileMeta, setuserProfileMeta] = useState({});
  const [school_photo, setschool_photo] = useState(null);
  const paramsss = new URLSearchParams(window.location.search);

  const capitalizeString = (str) => {
    return str
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };


  useEffect(() => {
    //  loadSchoolsList();
    if (_.get(props, "coachProfile.id")) {
      // if (_.get(props,'schoolDetails.details.id')) {
      // 	setSchoolsListMeta(_.get(props, 'schoolDetails.meta', {}))
      // 		setAllSchoolsList([_.get(props, 'schoolDetails.details',{})])
      // }else{
      fetchSchoolViewData(_.get(props, "coachProfile.school.id"));
      fetchSchoolSubscription(_.get(props, "coachProfile.school.id"));
      // }
    }
  }, [_.get(props, "coachProfile")]);

  const fetchSchoolSubscription = (id) => {
    props.getSchoolSubscription({
      params: { id },
      callback: (res, responseData) => {
        if (res === true) {
        }
      },
    });
  };
  const fetchSchoolViewData = (id) => {
    props.getSchoolViewData({
      params: { id: id },
      callback: (res, responseData) => {
        if (res === true) {
        }
      },
    });
  };

  const ToggleMenu = () => {
    setmenuVisible(!menuVisible);
  };

  const logout = () => {
    props.logout({
      callback: (response, data) => {
        // setmenuVisible(!menuVisible);
        history("/continue-registration");
        window.location.reload(false);
      },
    });
  };

  useEffect(() => {
    if (_.get(props, "checkUpdate") == true) {
      if (_.get(props, "islogindata.isLoggedIn") === true) {
        setuserProfile({});
        setschoolDetailsView({});
        if (_.includes(_.get(props, "islogindata.types"), 2) == true) {
          adminProfile();
        } else {
          getProfile();
        }
      }
    }
  }, [_.get(props, "checkUpdate")]);

  const adminProfile = () => {
    props.adminProfileData({
      // callback: (res, data) => {
      // if (res == true && _.get(data, 'data.details.id')) {
      //    navigate(_.get(data, 'data.details.type') == 0 ? '/coaches-evaluation' : _.get(data, 'data.details.type') == 2 ? "/manage-school" : "/schools-list")
      //    _.get(data, 'data.details.type') == 0 ? setAppRouteConfigs(coachRouteConfigs) : _.get(data, 'data.details.type') == 2 ? setAppRouteConfigs(adminRouteConfigs) : setAppRouteConfigs(routeConfigs)
      //    fetchSchoolViewData(_.get(data, 'data.details.school'))
      // }
      // }
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          await setuserProfile(_.get(data, "data.details"));
          await setuserProfileMeta(_.get(data, "data.meta"));
          setmenuVisible(true);
        }
      },
    });
  };

  useEffect(() => {
    if (_.get(props, "schoolDetails")) {
      setschoolDetailsView(_.get(props, "schoolDetails"));
      setschool_photo(
        `${_.get(props, "schoolDetails.meta.photo.path")}/${_.get(
          props,
          "schoolDetails.meta.photo.folders.school"
        )}/512/${_.get(props, "schoolDetails.details.photo")}?${Math.random()}`
      );
    }
  }, [_.get(props, "viewLoadingInProgress")]);

  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          await setuserProfile(_.get(data, "data.details"));
          await setuserProfileMeta(_.get(data, "data.meta"));
          setmenuVisible(true);
        }
      },
    });
  };

  const headerTitle = (details) => {
    return `${_.startCase(_.get(details, "first_name"))} ${_.startCase(
      _.get(details, "last_name")
    )}`;
  };
  const render = () => {
    const { schoolDetails } = props;
    let photo =
      _.get(userProfile, "photo") == null
        ? require("../../assets/img/default-admin.png")
        : `${_.get(userProfileMeta, "photo.path")}/${_.get(
            userProfileMeta,
            "photo.folder"
          )}/512/${_.get(userProfile, "photo")}`;

    return (
      <>
        <div className=" w-screen  bg-[#FFFFFF] h-[80px] xl:h-[5vw] pl-[1.5%] lg:pl-[2.8%] flex items-center hide-in-print">
          <div className="h-[90%] xl:h-[85%] w-[98%] lg:w-[95%] flex">
            <div className="containerx flex w-full h-full ">
              <div className=" w-full h-full flex items-center justify-between">
                <div className="flex items-center justify-between w-[50%] md:w-[350px] lg:w-[35%] xl:w-[30%] h-full">
                  <Link
                    to={
                      _.get(props, "checkUpdate") == true
                        ? "/athletes"
                        : "/login"
                    }
                    className="w-[45%] flex items-center justify-start"
                  >
                    <img src={logo} className="w-full" />
                  </Link>

                  <div className="h-[70%] w-[1px] bg-[#D9D9D9]"></div>
                  <div
                    id="newsfeed-launcher"
                    className="w-[50%] md:w-[45%] items-center flex"
                  >
                    {_.get(schoolDetailsView, "details.photo") && (
                      <img src={school_photo} className="w-[25%]" />
                    )}
                    {_.get(schoolDetailsView, "details.name") && (
                      <div className="text-[12px] font-bold font-lato ml-[5%]">
                        {_.startCase(_.get(schoolDetailsView, "details.name"))}
                      </div>
                    )}
                  </div>
                  {/* {_.get(props, "checkUpdate") == false && 
                  _.get(schoolDetailsView, "details.id") &&
                  (_.get(schoolDetailsView, "details.photo") ||
                    _.get(schoolDetailsView, "details.name")) && (
                    <div className='w-[50%] md:w-[45%] items-center flex justify-between'>
                      {_.get(schoolDetailsView, "details.photo") && (
                        <img src={school_photo} className='w-[25%]' />
                      )}
                      {_.get(schoolDetailsView, "details.name") && (
                        <div className='text-[12px] font-bold font-lato'>
                          {_.startCase(
                            _.get(schoolDetailsView, "details.name")
                          )}
                        </div>
                      )}
                    </div>
                  )} */}
                </div>
                <div className="w-[50%] md:w-[25%] lg:w-[40%] xl:w-[30%] h-full flex items-center justify-between">
                  {window.location.pathname == "/season-plays-list" && (
                    <div className="flex w-[20%] items-center justify-between">
                      <NotificationModal
                        week={
                          paramsss.get("week").slice(0, 5) +
                          "#" +
                          paramsss.get("week").slice(5)
                        }
                        team={capitalizeString(paramsss.get("team"))}
                        opponent={capitalizeString(paramsss.get("opponent"))}
                      />
                      <AngelPopUp />
                    </div>
                  )}

                  {_.get(props, "checkUpdate") == true &&
                    _.get(userProfile, "id") && (
                      <div
                        className={`w-[70%] bg-[#F3F5F7] py-[1%] h-[100%] rounded-[0.4vw] flex items-center justify-center ${
                          window.location.pathname !== "/season-plays-list" &&
                          "ml-[40%]"
                        }`}
                      >
                        <img
                          src={photo}
                          className="w-[18%] rounded-full border-[0.2vw] border-solid border-[#A40E17]"
                        />
                        <div className="font-inter flex flex-col items-start justify-center xl:w-[50%] ml-[3%]">
                          <Link to="/subscription">
                            <span className=" justify-self-center text-[10px] lg:text-[12px] font-bold ">
                              {headerTitle(userProfile)}
                            </span>
                          </Link>
                          <span className="text-[#2E4D6B] justify-self-center text-[10px] lg:text-[12px] font-normal">
                            {_.get(userProfile, "type") == 1
                              ? "School Admin"
                              : `Coach - ${userProfile.positions}`}
                          </span>
                        </div>
                        <Popover
                          content={
                            <div className="w-[169px] text-sm text-[#2E4D6B] flex flex-col gap-4">
                              <div
                                className="w-[100%] cursor-pointer flex items-center gap-2"
                                onClick={() => history("/add-stats")}
                              >
                                <img src={profileIcon} />
                                <span>Profile</span>
                              </div>
                              <div
                                className="w-[100%] cursor-pointer flex items-center gap-2"
                                onClick={() => history("/subscription")}
                              >
                                <img src={card} />
                                <span>Subcriptions</span>
                              </div>
                              <div
                                className="w-[100%] cursor-pointer flex items-center gap-2"
                                onClick={() => history("/add-stats")}
                              >
                                <img src={settings} />
                                <span>Settings</span>
                              </div>
                              {/* <div className="w-[100%] cursor-pointer flex items-center gap-2">
                                <img src={logoutIcon} />
                                <span>Logout</span>
                              </div> */}
                            </div>
                          }
                          trigger="click"
                          placement="bottomLeft"
                          showArrow={false}
                        >
                          <div className="HeaderMenuIcons w-[10%] ml-[3%] flex items-center justify-center cursor-pointer">
                            <i className="fas fa-chevron-down font-extralight text-[12px]"></i>
                          </div>
                        </Popover>
                      </div>
                    )}
                </div>
                <span
                  className="MobileOverLay text-[20px] md:hidden hide-in-print cursor-pointer"
                  onClick={() => ToggleMenu()}
                >
                  {" "}
                  <i className="fas fa-bars"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <AlertsComponent
          user={props.coachProfile?.user}
          school={schoolDetailsView?.details}
          subscription={props.schoolSubscriptionDetails?.subscription}
        />
      </>
    );
  };
  const AlertsComponent = ({ school, user = null, subscription = {} }) => {
    const {
      isFreePlan,
      isPaidPlan,
      isTrial,
      plan,
      hasTrialExpired,
      hasPlanExpired,
    } = subscription;

    const isEmailVerificationPending = user ? !user?.verified : false;
    const onClose = (e) => {
      console.log("I was closed.");
    };
    return (
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        {plan == 1 && (
          <FreePlanAlert
            hasPlanExpired={hasPlanExpired}
            hasTrialExpired={hasTrialExpired}
            onClose={onClose}
          />
        )}

        {hasPlanExpired && (
          <PlanExpiredAlert
            hasPlanExpired={hasPlanExpired}
            hasTrialExpired={hasTrialExpired}
            onClose={onClose}
          />
        )}
        {/* Your other components and logic */}
        {user
          ? isEmailVerificationPending && (
              <WarningEmailVerificationAlert
                onClose={onClose}
                onLogout={logout}
              />
            )
          : ""}
      </Space>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {
    checkUpdate: _.get(state, "app.account.isLogin.isLoggedIn"),
    coachProfile: _.get(state, "app.account.profile.data.details"),
    islogindata: _.get(state, "app.account.isLogin"),
    schoolDetails: _.get(state, "app.schools.view.data"),
    schoolSubscriptionDetails: _.get(state, "app.schools.subscription.data"),
    viewLoadingInProgress: _.get(state, "app.schools.viewLoadingInProgress"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLogin: (details) => dispatch(global.redux.action.account.isLogin(details)),
  adminProfileData: (details) =>
    dispatch(global.redux.action.account.adminProfile(details)),
  logout: (details) => dispatch(global.redux.action.account.logout(details)),
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
  getSchoolSubscription: (details) =>
    dispatch(global.redux.action.schools.subscription(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
