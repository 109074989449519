import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

function ButtonUI({
  text,
  disabled,
  textColor,
  outlinedColor,
  leftIcon,
  rightIcon,
  buttonFunction,
  loading,
  background,
  width,
  xlWidth,
  opacity,
}) {
  return (
    <button
      className={` py-2 2xl:py-[0.67vw]  bg-[${
        background ? background : "transparent"
      }] text-[${textColor ? textColor : "#ffffff"}] ${
        width ? `w-[${width}] xl:w-[${xlWidth}]` : "w-[83px] xl:w-[5.7vw]"
      } text-[10px] xl:text-[0.7vw] rounded-md flex items-center justify-center focus:outline-none font-inter font-bold ${
        opacity && `bg-opacity-${opacity}`
      }`}
      onClick={buttonFunction}
      style={
        background
          ? null
          : {
              border: `1px solid ${outlinedColor ? outlinedColor : "black"}`,
            }
      }
      disabled={disabled ? disabled : loading}
    >
      {loading && <LoadingOutlined />}
      {leftIcon && <img src={leftIcon} className="w-[20px] mr-2" />}
      {text}
      {rightIcon && <img src={rightIcon} className="w-[20px] ml-2" />}
    </button>
  );
}

export default ButtonUI;
