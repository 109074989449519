import React, { useState, useEffect } from 'react';
import { Modal, Button, List, Card, Input, Form, Typography, message } from 'antd';
import { connect } from 'react-redux';
import { EyeOutlined    } from "@ant-design/icons";
import moment from 'moment';

const { TextArea } = Input;
const { Title } = Typography;

const Collaboration = (props) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [editingComment, setEditingComment] = useState(null);
  const [loading, setLoading] = useState(false);
 
  // Fetch comments for the play
  const handleGetComments = () => {
    props.getCommentsForPlay({
      params: { id: props.play.id },
      callback: (res, data) => {
        if (res === true) {

          const sortedItems = data?.data?.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
          })
          setComments( sortedItems || []);
        }
      },
    });
  };

  useEffect(handleGetComments, [props.play]);

  // Add a new comment
  const handleAddComment = () => {
    if (!newComment) return message.warning("Comment cannot be empty");
    setLoading(true)
    props.addComment({
      body: { comment: newComment, play: props.play.id, user: props.coachProfile?.user?.id, game: props.game },
      callback: (res, data) => {
        if (res) { 
          setNewComment('');
          handleGetComments()
          message.success("Comment added successfully");
         
        }
        setLoading(false)
      },
    });
  };

  // Open/Close modal
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  // Edit comment
  const handleEditComment = (commentId) => {
    // Handle edit logic (open form to edit comment)
    setEditingComment(commentId);
  };

  // Archive comment
  const handleArchiveComment = (commentId) => {
    
    props.archiveComment({
      body: { id: commentId },
      callback: (res, data) => {
        if (res) {  
          handleGetComments()
          message.info(`Comment archived!`);
        }
        setLoading(false)
      },
    }); 
  };

  return (
    <div className="collaboration-section w-full">

<div className='flex gap-3 w-full' style={{    alignItems: 'center'}} >
<button style={{
      fontSize: comments.length > 0 ? '14px': '24px',
      padding: comments.length > 0 ? '3px 0px 3px 5px' : '0px 0px 3px 4px', 
      width: '27px',
      height: '26px',
      borderRadius: '40px', 
      display: 'flex',
      alignItems: 'center',
}}  className='btn btn-primary' onClick={showModal}>
 {comments.length > 0 ?  <EyeOutlined /> :  '+'   }
      </button>

      <div className='w-full' style={{ fontSize: '14px', lineHeight: '1.5' }}>

      
        {/* Display the first comment or show "Add a collaboration" */}
        {comments.length > 0 ? (
          <div onClick={showModal} className='cursor-pointer flex px-2 py-[4px] border-2 rounded-md border-[#f0f0f0] w-full'>
          <p className='text-capitalize mb-0'>  {comments[0].first_name || 'Coach'} {comments[0].last_name || ' '} - {comments[0]?.comment?.slice(0,110)}</p>
          </div>
        ) : (
          <div  onClick={showModal} className='cursor-pointer flex px-2 py-[4px] border-2 rounded-md border-[#f0f0f0] w-full'>
          <p className=' mb-0 text-[#6c757d] '>Collaborate with other coaches here</p>
          </div>
        )}
      </div>

      {/* Button to open modal */}
     
      </div>
      {/* Modal to display all comments */}
      <Modal
        title={`Collaborations on play: ${props.play?.number}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        {comments.map((comment,i) =>
            <Card
              key={i}
              className='mb-2'
            >
                <div className='flex justify-between h-[21px]'>
                <p className='text-capitalize mb-0 leading-0 text-[14px]'>{comment.first_name || 'Coach'} {comment.last_name || ' '} - <small>{moment(comment.created_at).fromNow()}</small></p>
<div>
{/* {
                comment.coach_id != props.coachProfile?.user?.id
                  ? (<div className='relative bottom-[5px]'>
                      {/* <Button type="link" className='text-[13px]' onClick={() => handleEditComment(comment.id)}>
                        Edit
                      </Button> 
                      <Button type="link" className='text-[13px] text-red'  onClick={() => handleArchiveComment(comment.id)}>
                        Remove
                      </Button>
                      </div>
                  )
                  : ''
              } */}
</div>
                </div>
                
                <p className='mb-0'>{comment.comment}</p>
             
                
            </Card>
          )}
        
        {/* Add new comment section */}
        <Form layout="vertical" onFinish={handleAddComment}>
          <Form.Item label="Add a new comment"  >
            <TextArea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Collaborate with other coaches here..."
              rows={3}
              
            />
          </Form.Item>
          <Button loading={loading}  type="primary" htmlType="submit">
            Submit
          </Button>
          <p className='text-danger text-bold'>Players can not see these comments, this is for coaches eyes only</p>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coachProfile: state?.app?.account?.profile?.data?.details,
});

const mapDispatchToProps = (dispatch) => ({
  addComment: (details) => dispatch(global.redux.action.plays.addComment(details)),
  archiveComment: (details) => dispatch(global.redux.action.plays.archiveComment(details)),
  getCommentsForPlay: (details) => dispatch(global.redux.action.plays.getCommentsForPlay(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collaboration);
