import appPages from "./app-pages";

export default [
  {
    key: "athletes",
    element: <appPages.homePage />,
    path: "/athletes",
    exact: true,
  },
  {
    key: "playerList",
    element: <appPages.PlayerListIndex />,
    path: "/player-list",
    exact: true,
  },
  {
    key: "playerInvite",
    element: <appPages.PlayerInvite />,
    path: "/player-invite",
    exact: true,
  },
  {
    key: "CoachEvaluation",
    element: <appPages.CoachEvaluation />,
    path: "/coaches-evaluation",
  },
    {
    key: "PaymentPage",
    element: <appPages.PaymentPage />,
    path: "/payment",
  },
  {
    key: "ManagePlaylist",
    element: <appPages.ManagePlaylist />,
    path: "/manage-playlist",
  },
  {
    key: "CoachesNoteScreen",
    element: <appPages.CoachesNoteScreen />,
    path: "/coaches-note",
  },
  {
    key: "library",
    element: <appPages.LibraryPageComponent />,
    path: "/library",
    exact: true,
  },
  {
    key: "SchoolsList",
    element: <appPages.SchoolsListComponent />,
    path: "/schools-list",
    exact: true,
  },
  {
    key: "seasonsList",
    element: <appPages.CoachAtheletesListComponent />,
    path: "/season-plays-list",
    exact: true,
  },
  {
    key: "SchoolsView",
    element: <appPages.SchoolsViewComponent />,
    path: "/schools-view",
    exact: true,
  },
  {
    key: "SchoolsUpdate",
    element: <appPages.SchoolUpdateComponent />,
    path: "/schools-update",
    exact: true,
  },
  {
    key: "SchoolsUpdatePhone",
    element: <appPages.SchoolsUpdatePhoneComponent />,
    path: "/schools-update-phone",
    exact: true,
  },
  {
    key: "CoachList",
    element: <appPages.CoachesListComponent />,

    path: "/coach-list",
    exact: true,
  },
  {
    key: "SchoolsUpdatePhoto",
    element: <appPages.SchoolsUpdatePhotoModal />,
    path: "/schools-update-photo",
    exact: true,
  },
  {
    key: "CreateCoach",
    element: <appPages.CreateCoachComponent />,
    path: "/create-coach",
    exact: true,
  },
  {
    key: "CoachList",
    element: <appPages.CoachesListComponent />,
    path: "/coach-list",
    exact: true,
  },
  {
    key: "ChangeStatus",
    element: <appPages.ChangeStatus />,
    path: "/change-status",
    key: "coachesUpdate",
    element: <appPages.CoachesUpdateComponent />,
    path: "/coaches-update",
    exact: true,
  },
  {
    key: "coachesUpdatePhone",
    element: <appPages.CoachesUpdatePhoneComponent />,
    path: "/coaches-update-phone",
    exact: true,
  },
  {
    key: "coachesUpdatePhoto",
    element: <appPages.CoachesUpdatePhotoComponent />,
    path: "/coaches-update-photo",
    exact: true,
  },
  {
    key: "CreatePlays",
    element: <appPages.PlaysCreateComponent />,
    path: "/create-plays",
    exact: true,
  },
  {
    key: "CreateGames",
    element: <appPages.GamesCreateComponent />,
    path: "/create-games",
    exact: true,
  },
  {
    key: "playsList",
    element: <appPages.PlaysListComponent />,
    path: "/plays-list",
    exact: true,
  },
  {
    key: "gamesList",
    element: <appPages.GamesListComponent />,
    path: "/games-list",
    exact: true,
  },
  {
    key: "ScheduleScreen",
    element: <appPages.ScheduleScreen />,
    path: "/schedule",
    exact: true,
  },
  {
    key: "wardbordscreen",
    element: <appPages.WardbordScreen />,
    path: "/wardbord",
  },
  {
    key: "mvpsList",
    element: <appPages.MvpsList />,
    path: "/mvps-list",
    exact: true,
  },
  {
    key: "CreateMvps",
    element: <appPages.MvpsCreate />,
    path: "/create-mvps",
    exact: true,
  },
  {
    key: "MvpsView",
    element: <appPages.MvpsViewModalComponent />,
    path: "/mvps-view",
    exact: true,
  },
  {
    key: "ReportViewScreen",
    element: <appPages.ReportViewScreen />,
    path: "/report-view",
    exact: true,
  },
  {
    key: "EvaluvationReportViewScreen",
    element: <appPages.EvaluvationReportViewScreen />,
    path: "/evaluvation-report",
    exact: true,
  },
  {
    key: "OverallGradeViewScreen",
    element: <appPages.OverallGradeViewScreen />,
    path: "/overall-grade",
    exact: true,
  },

  //   dashboard component
  {
    key: "SeasonAnalyticDashboard",
    element: <appPages.SeasonAnalyticDashboard />,
    path: "/season-analytic-dashboard",
    exact: true,
  },
  {
    key: "GameAnalyticsDashboard",
    element: <appPages.GameAnalyticsDashboard />,
    path: "/game-analytic-dashboard",
    exact: true,
  },
  {
    key: "stats-view",
    element: <appPages.Stats />,
    path: "/stats-view",
    exact: true,
  },
  {
    key: "add-stats",
    element: <appPages.CustomStats />,
    path: "/add-stats",
    exact: true,
  },
  {
    key: "PlayerStats",
    element: <appPages.PlayerStats />,
    path: "/player-stats-view",
    exact: true,
  },
  {
    key: "Scoring",
    element: <appPages.Scoring />,
    path: "/scoring",
    exact: true,
  },
  {
    key: "stats-to-print",
    element: <appPages.StatsToPrint />,
    path: "/stats-to-print",
    // exact: true,
  },
  {
    key: "Leaderboard",
    element: <appPages.Leaderboard />,
    path: "/leaderboard",
    exact: true,
  },
  {
    key: "LeaderboardPrint",
    element: <appPages.LeaderboardPrint />,
    path: "/leaderboard-print",
    exact: true,
  },
  {
    key: "FormationAnalytics",
    element: <appPages.FormationAnalytics />,
    path: "/formation-analytic-dashboard",
    exact: true,
  },

  {
    key: "SubscriptionPage",
    element: <appPages.SubscriptionPage />,
    path: "/subscription",
  },
  {
    key:"ScoutingReport",
    element: <appPages.ScoutingReport />,
    path: "/scouting-report",
    exact: true
  }

];
