import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Modal,
  Form
} from 'antd'
import { connect } from 'react-redux'
import { UserOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'

import ChangeStatus from './change-status'
import './mvps.css'

const MvpsViewModalComponent = props => {
  const [disabled, setDisabled] = useState(true)
  const [showModal, setShowModal] = useState(true)
  const [mvpsList, setMvpsList] = useState([])
  const [mvpsListMeta, setMvpsListMeta] = useState({})
  const [mvpsListLoading, setMvpsListLoading] = useState(true)
  const [seasonsList, setSeasonsList] = useState([])
  const [gamesList, setGamesList] = useState([])
  const [gamesListMeta, setGamesListMeta] = useState({})
  const [gamesListLoading, setGamesListLoading] = useState(true)
  const [title, setTitle] = useState('')

  const columns = [
    {
      title: 'Athlete',
      dataIndex: 'athlete',
      align: 'center',
      key: 'athlete'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
      key: 'title',
      render: value => getMVPTitle(value)
    },
    // {
    // 	title: 'Coach',
    // 	dataIndex: 'coach',
    //     align: 'center',
    // 	key: 'coach',
    // },
    {
      title: 'Week',
      dataIndex: 'week',
      align: 'center',
      key: 'week'
    }
  ]

  useEffect(() => {
    loadSeasonsList()
    if (_.get(props, 'profileDetails.id')) {
      loadMvpsList()
    }
    getGridironDetails()
    setPageTitle()
  }, [])

  const getMVPTitle = title_no => {
    return _.chain(props)
      .get('gridironDetails.mvps', [])
      .find({ id: title_no })
      .get('name', '')
      .value()
  }

  const getGridironDetails = () => {
    props.getGridironDetails({})
  }

  const handleChange = value => {
    setShowModal({ value: value })
  }

  const CardActive = () => {
    setDisabled({
      disabled: !disabled
    })
  }

  const handleCancel = () => {
    // because the client controls the visibility state of the component
    props.hideModal()
  }

  const loadSeasonsList = () => {
    let params = { limit: 100 }
    props.seasonsList({
      params,
      callback: (res, data) => {
        if (res === true) {
          setSeasonsList(_.get(data, 'data.items', []))
        }
      }
    })
  }

  const loadGameView = (params, callback) => {
    props.gamesView({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          callback(_.get(data, 'data.details', {}))
        }
      }
    })
  }

  const loadMvpsList = () => {
    const urlParams = new URLSearchParams(window.location.search)
    let gameID = urlParams.get('game')
      ? urlParams.get('game')
      : _.get(props, 'gameId')
    let params = {
      limit: 15,
      expand: 'athlete,coach',
      coach: _.get(props, 'profileDetails.school.school_admin'),
      status: '0,1',
      game: gameID
    }
    setMvpsListLoading(true)
    props.mvpsList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setMvpsListMeta(_.get(data, 'data.meta', {}))
          setAllMvpsList(_.get(data, 'data.items', []))
        }
        setMvpsListLoading(false)
      }
    })
  }

  const setAllMvpsList = data => {
    let weekFormat = 'Week'
    let list = []
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          athlete:
            _.startCase(_.get(value, 'athlete.first_name')) +
            ' ' +
            _.startCase(_.get(value, 'athlete.last_name')),
          coach: _.get(value, 'coach.first_name'),
          title: _.get(value, 'title'),
          season: _.get(value, 'season'),
          week: weekFormat.concat(' ', _.get(value, 'week')),
          id: _.get(value, 'id'),
          game: _.get(value, 'game'),
          status: _.get(value, 'status'),
          status_glossary: _.get(value, 'status_glossary')
        })
      })

      setMvpsList(list)
    } else {
      setMvpsList([])
    }
  }

  const setPageTitle = () => {
    const params = new URLSearchParams(window.location.search)
    let id = params.get('game') ? params.get('game') : _.get(props, 'gameId')
    loadGameView({ id, expand: 'season' }, gameDetails => {
      setTitle(
        `${_.get(gameDetails, 'season.title')} ${_.startCase(
          _.get(gameDetails, 'title')
        )}`
      )
    })
  }

  const render = () => {
    return (
      <div className='HomePageContainer'>
        <div className='container'>
          <div className='pageTitle'>{title}</div>
          <div className='full-width'>
            <div className='TableContainer'>
              <Table
                bordered
                key={_.get(gamesList, 'id')}
                loading={mvpsListLoading}
                columns={columns}
                dataSource={mvpsList}
                size='middle'
                pagination={false}
              />
            </div>
            {/* <ChangeStatus
							{..._.pick(mvpsList, ['id', 'status','status_glossary'])}
						/> */}
          </div>
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = state => {
  return {
    profileDetails: _.get(state, 'app.account.profile.data.details'),
    gridironDetails: _.get(state, 'app.site.gridironDetails.data.data', {})
  }
}

const mapDispatchToProps = dispatch => ({
  mvpsList: details => dispatch(global.redux.action.mvps.list(details)),
  getGridironDetails: details =>
    dispatch(global.redux.action.site.gridironDetails(details)),
  seasonsList: details => dispatch(global.redux.action.seasons.list(details)),
  gamesView: details => dispatch(global.redux.action.games.view(details))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MvpsViewModalComponent)
