import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import statisticsImg from "../../../assets/img/stats-square-up.svg";
import floppyDiskImg from "../../../assets/img/floppy-disk.svg";
import printer from "../../../assets/img/printing-page.svg";

import ExportToCSVButton from '../ExportToCSVButton';

function Statistics({ visible, setVisible, dataSource, odk }) {
  const [modalWidth, setModalWidth] = useState(304);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(304);
    } else {
      setModalWidth(290);
    }
  }, [window.innerWidth]);
  
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={modalWidth}
      title="Statistics"
      closable={true}
      className="hide-in-print"
    >
      <div className="flex flex-col ">
        <div className="flex items-start bg-[#E8F4FF] p-2 text-[#1A91FF] text-sm font-bold">
          <img src={statisticsImg} />
          <span className="ml-2"> 
            We're making progress, but there's more to come! Don't forget to
            request a Wardbord integration when sharing your stats with others.
          </span>
        </div>
        <div className="flex  text-md mt-4  w-full items-center justify-between">
          <button
            className="flex items-center focus:outline-0 self-end justify-center w-[48%] bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]"
          >
            <img src={printer} className="w-6" />
            <span className="ml-2">Print</span>
          </button>
          <ExportToCSVButton  dataSource={dataSource} odk={odk} />
        </div>
      </div>
    </Modal>
  );
}

export default Statistics;
