import React from "react";
import { Form, Input } from "antd";

function TrustingWardbord(props) {
  return (
    <div className="w-[90%] mx-auto">
      <div className="flex flex-col text-center">
        <span className="text-[18px] font-bold text-center font-poppins text-[#0F1A24]">
          Thank you for trusting Wardbord!
        </span>
        <span className="text-[12px] my-7">Set up your log in Credentials</span>
      </div>
      <div className="">
        <Form layout="vertical" requiredMark={false}>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Email
              </span>
            }
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              //   autoComplete="new-password"
              placeholder="eg: name@example.com"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Password
              </span>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              //   autoComplete="new-password"
              placeholder="password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="font-inter text-[14px] text-[#0F1A24]">
                Confirm Password
              </span>
            }
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input your Password again!",
              },
            ]}
          >
            <Input.Password
              //   autoComplete="new-password"
              placeholder="Confirm Password"
              size="large"
            />
          </Form.Item>
          <div className="mt-6 w-[90%] mx-auto">
            <button
              className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
              onClick={() => {
                if (props.page == 4) {
                } else {
                  props.setPage(props.page + 1);
                }
              }}
            >
              <span>Create Account Now</span>
            </button>
            <button
              className="border-[1px] border-solid border-[#ED780B] mx-auto px-6 py-2 flex items-center justify-center gap-2  disabled:opacity-50 text-[14px] text-[#ED780B] rounded-md font-bold mt-32"
              onClick={() => props.setPage(1)}
            >
              Restart Prototype
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default TrustingWardbord;
