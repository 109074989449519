import React, { useEffect, useState } from "react";
import { Input, Modal } from "antd";

function EnterPlayModal({ onCancel, handleAddPlaysManually }) {

  const [ quantity, setQuantity] = useState(0)
  
  return (
    <div className='flex flex-col font-inter w-[100%] mt-[10px] pb-[10px]'>
      <div className='flex flex-col'>
        <span className='text-[16px] font-normal text-[#0F1A24] '>
          How many plays do you want to add?
        </span>
        <div className='h-[43px] w-[100%] border-[1px] border-solid border-[#EAEDF0] mt-[8px] flex items-center justify-center text-[14px] text-[#0F1A24] font-normal rounded-[4px]'>
          <input
            onChange={({ target: { value } }) => {
              
              setQuantity(value)
            }}
            className='outline-none focus:outline-none border-none p-[12px] h-full w-full bg-[transparent]'
            placeholder='1'
          />
        </div>
      </div>
      <div className='mt-[32px] flex item-center justify-between'>
        <div
          className='h-[43px] w-[48%] flex items-center justify-center rounded-[4px] cursor-pointer text-[14px] font-bold border-[1px] border-solid border-[black]'
          onClick={onCancel}>
          <span>Cancel</span>
        </div>
        <div
        onClick={() =>handleAddPlaysManually(quantity)}
          className='h-[43px] w-[48%] flex items-center justify-center rounded-[4px] cursor-pointer bg-[#1A91FF] text-white text-[14px] font-bold'>
          <span>Create</span>
        </div>
      </div>
    </div>
  );
}

export default EnterPlayModal;
