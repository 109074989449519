import greenClip from "./assets/clipboard-checkgreen.svg";
import orangeEmoji from "./assets/emoji-satisfiedorange.svg";
import redEmoji from "./assets/emoji-sadred.svg";
import redThumb from "./assets/thumbs-downred.svg";
import yellowFlag from "./assets/triangle-flag-fullyellow.svg";

const formatNum = (val) => {
  if (isNaN(val) || val === null) return 0;
  return Math.round(val);
};

const convertArrayToString = (array) => array.join(", ");

export const columns = (week) => [
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] xl:h-[50px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Player
      </div>
    ),

    children: [
      {
        title: "Player Name",
        width: "10%",
        align: "center",
        dataIndex: "first_name",
        key: "first_name",
        render: (text, item) => (
          <span className="text-[10px] md:text-[10.5px] tabledata font-bold text-[#0F1A24]">
            {item.first_name} {item.last_name}
          </span>
        ),
      },
      {
        title: "Position",
        align: "center",
        width: "7%",
        dataIndex: "positions",
        key: "positions",
        render: (text, item) => (
          <span className="text-[10px] md:text-[10.5px] tabledata font-bold text-[#0F1A24]">
            {convertArrayToString(item.positions)}
          </span>
        ),
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] xl:h-[50px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Total Assignments
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_count",
        key: "plays",
        align: "center",
        width: "10%",
        render: (text, item) => (
          <span className="text-[10px] md:text-[10.5px] tabledata font-bold text-[#0F1A24]">
            {text}  ({item.overall_count})
          </span>
        ),
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] xl:h-[50px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Execution Comparison
      </div>
    ),
    children: [
      {
        title: "Overall",
        dataIndex: "overall_executed",
        key: "total_executed",
        align: "center",
        width: "10%",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#12CF52]">

            <span> {formatNum((Number(item.overall_executed) / Number(item.overall_count)) * 100)}%</span>
          </div>
        ),
      },
      {
        title: `${week}`,
        dataIndex: "total_executed",
        key: "total_executed",
        align: "center",
        width: "10%",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#12CF52]">

            <span> {formatNum((Number(item.total_executed) / Number(item.total_count)) * 100)}%</span>
          </div>
        ),
      },
      {
        title: "Differential",
        dataIndex: "total_executed",
        key: "total_executed",
        align: "center",
        width: "10%",
        render: (text, item) => {

          const diff = (Number(item.total_executed) / Number(item.total_count)) - (Number(item.overall_executed) / Number(item.overall_count))

          return (
            <div className={`w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold ${diff >= 0 ? ' text-[#12CF52]' : ' text-[#F96B04]'}`}>

              <span> {formatNum((diff) * 100)}% ({(item.total_count * diff).toFixed(2)} plays)</span>
            </div>
          )
        },
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] xl:h-[50px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Effort Comparison
      </div>
    ),
    children: [
      {
        title: "Overall",
        dataIndex: "overall_great_effort",
        key: "total_great_effort",
        width: "8%",
        align: "center",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#12CF52]">

            <span> {formatNum((Number(item.overall_great_effort) / Number(item.overall_count)) * 100)}%</span>
          </div>
        ),
      },
      {
        title: `${week}`,
        dataIndex: "total_great_effort",
        key: "total_great_effort",
        width: "8%",
        align: "center",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#12CF52]">

            <span> {formatNum((Number(item.total_great_effort) / Number(item.total_count)) * 100)}%</span>
          </div>
        ),
      },
      {
        title: "Differential",
        dataIndex: "total_great_effort",
        key: "total_great_effort",
        width: "8%",
        align: "center",


        render: (text, item) => {

          const diff = (Number(item.total_great_effort) / Number(item.total_count)) - (Number(item.overall_great_effort) / Number(item.overall_count))

          return (
            <div className={`w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold ${diff >= 0 ? ' text-[#12CF52]' : ' text-[#F96B04]'}`}>

              <span>  {formatNum((diff) * 100)}% ({(item.total_count * diff).toFixed(2)} plays)</span>
            </div>
          )
        },
      },
    ],
  },

  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] xl:h-[50px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Opportunities for Improvement
      </div>
    ),
    children: [
      {
        title: "Poor Effort Plays",
        dataIndex: "total_great_effort",
        key: "poorEffortPlays",
        width: "10%",
        align: "center",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#A40E17]">
            <img src={redEmoji} className="w-[24px] mr-[8px]" />
            <span> {(Number(item.overall_great_effort) - Number(item.overall_count))}</span>
          </div>
        ),
      },
      {
        title: "Assignments Failed",
        dataIndex: "total_executed",
        key: "assignmentFailed",
        width: "13%",
        align: "center",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#A40E17]">
            <img src={redThumb} className="w-[24px] mr-[8px]" />
            <span> {(Number(item.overall_executed) - Number(item.overall_count))}</span>
          </div>
        ),
      },
      {
        title: "Penalty Flags",
        dataIndex: "total_flags",
        width: "8%",
        align: "center",
        className: "p-0",
        key: "total_flags",
        render: (text, item) => (
          <div className="w-[90%] mx-auto flex items-center justify-center text-[10px] md:text-[10.5px] tabledata font-bold text-[#F0BB00]">
            <img src={yellowFlag} className="w-[24px] mr-[8px]" />
            <span> {text}</span>
          </div>
        ),
      },
    ],
  },
];
