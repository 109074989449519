import React, { useState } from "react";
import { Table, Button, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { oColumns, dColumns, stColumns, kickerColumns } from "./statsColumns";
import download from "../../assets/img/downloadw.svg";


const calculateParams = (row, title) => {

  const convoluted = {
    'Total Points': () => {
     return (
        ((Number(row.total_rbtd) + Number(row.total_wrtd) +
      Number(row.total_fum_ret_td) + Number(row.total_int_ret_td)
       + Number(row.total_punt_return_td) + Number(row.total_kickoff_return_td)) * 6)
       
       +

       ((Number(row.total_pat)) * 1)

        +
        (Number(row.total_safety) * 2)
        +

        (Number(row.total_two_part_conversion) * 2)
+

((Number(row.total_field_goals)) * 3)
       ) 
    },
    'FG Points': () => {
      return Number(row?.total_field_goals) * 3
     },
     'FG Attempted': () => {
      return (Number(row.total_field_goals) +
      Number(row.total_block_field_goals) +
      Number(row.total_missed_field_goals) )
     },
     'PATKickingAtt': () => {
      return (Number(row.total_pat) +
      Number(row.total_missed_pat) +
      Number(row.total_block_pat)) 
 
     },
     'TD Points': () => {
      return (Number(row.total_rbtd) + Number(row.total_wrtd) +
      Number(row.total_fum_ret_td) + Number(row.total_int_ret_td)
       + Number(row.total_punt_return_td) + Number(row.total_kickoff_return_td))  * 6
     },
     'Total TD Num': () => {
      return (Number(row.total_rbtd) + Number(row.total_wrtd) +
      Number(row.total_fum_ret_td) + Number(row.total_int_ret_td)
       + Number(row.total_punt_return_td) + Number(row.total_kickoff_return_td))
     },
     'PassingAtt': () =>{
      return (  Number(row?.total_complete_receptions) +
        Number(row?.total_incomplete_receptions) +
        Number(row?.total_int) || 0)
     },
     'TotalTackles': () =>{
      return (Number(row.total_tackles_solo) +
      Number(row.total_tackles_ast_halved))
     },
    'Total Conversion Points': () => {
      return ((Number(row?.total_two_part_conversion) * 2) || 0)
    },
    'PuntReturnNum': () => {
      return ((Number(row?.total_punt_return) + Number(row.total_punt_return_fair_catch)) || 0)
    },
    'TotalReturnYards': () => {
      return ((Number(row?.total_punt_return_yds) + Number(row.total_kickoff_return_yds)) || 0)
    },
  
    'PuntNum': () => {
      return ((  Number(row?.total_punts) + Number(row.total_blocked_punts)) || 0)
    },
  }

  return convoluted[title]()
}

const ExportToCSVButton = ({
  dataSource,
  columns,
  disableDownload,
  buttonFunc,
  loading,
}) => {


  const [downloadLoading, setDownloadLoading] = useState(loading);
  const newcolumns = [
    ...columns,
    { children: [ 
  { 
    dataIndex: "total_carries",
    title: "RushingNum", 
  },
  { 
    dataIndex: "total_gn_carries_yds",
    title: "RushingYards", 
  }, 
  { 
    dataIndex: "max_gn_carries_yds",
    title: "RushingLong", 
  },
  { 
    dataIndex: "total_completions",
    title: "ReceivingNum",
  },
  { 
    dataIndex: "total_completions_carries_yds",
    title: "ReceivingYards", 
  },
  {
    dataIndex: "max_completions_carries_yds",
    title: "ReceivingLong",
  },
  {
    dataIndex: "total_complete_receptions",
    title: "PassingComp",
  },
  { 
    dataIndex: "calculate",
    title: "PassingAtt", },
  { 
    dataIndex: "total_complete_receptions_lng",
    title: "PassingLong",
  },
  { 
    dataIndex: "total_complete_receptions_yds",
    title: "PassingYards",
  },
  {
    title: "QBTD",
    dataIndex: "total_qbtd",
    title: "PassingTD",
  },
  {
    dataIndex: "total_int",
    title: "PassingInt",
  },
  { 
    dataIndex: "total_caused_fumble",
    title: "OffensiveFumbles", 
  },
  { 
    dataIndex: "total_fumble_lost",
    title: "OffensiveFumblesLost", 
  },
  { 
    dataIndex: "total_tackles_tfl", 
    title: "TacklesForLoss", 
  },
  { 
    dataIndex: "total_tackles_solo", 
    title: "Tackles", 
  },
  { 
    dataIndex: "total_tackles_ast",
    title: "Assists", 
  },
  { 
    dataIndex: "calculate", 
    title: "TotalTackles", 
  },
  { 
    dataIndex: "total_caused_fumble", 
    title: "CausedFumbles", 
  },
  { 
    dataIndex: "total_recovered_fumble",
    title: "FumbleRecoveries", 
  },
  { 
    dataIndex: "total_yards_after_recovery",
    title: "FumbleRecoveryYards", 
  }, 
  { 
    dataIndex: "total_tackles_sak", 
    title: "Sacks", 
  },
  { 
    dataIndex: "total_sack_yds", 
    title: "SacksYardsLost", 
  },
  { 
    dataIndex: "total_hurries",
    title: "QBHurries", 
  },
  { 
    dataIndex: "total_int",
    title: "INTs", 
  },
  { 
    dataIndex: "total_tackles_int_yds", 
    title: "INTYards", 
  },
  { 
    dataIndex: "total_pd", 
    title: "PassesDefensed", 
  },
  { 
    dataIndex: "total_punt_blocks",
    title: "BlockedPunts", 
  },
  { 
    dataIndex: "total_fg_blocks",
    title: "BlockedFG", 
  },
  { 
    dataIndex: "calculate",
    title: "PuntReturnNum", 
  },
  { 
    dataIndex: "total_punt_return_yds",
    title: "PuntReturnYards", 
  }, 
  { 
    dataIndex: "max_punt_return_yds",
    title: "PuntReturnLong", 
  },
  { 
    dataIndex: "total_punt_return_fair_catch",
    title: "PuntReturnFairCatches", 
  },
  { 
    dataIndex: "total_kickoff_return",
    title: "KickOffReturnNum", 
  },
  { 
    dataIndex: "total_kickoff_return_yds",
    title: "KickOffReturnYards", 
  }, 
  { 
    dataIndex: "max_kickoff_return_yds",
    title: "KickOffReturnLong", 
  },
  { 
    dataIndex: "calculate",
    title: "TotalReturnYards", 
  },

  { 
    dataIndex: "calculate",
    title: "PuntNum", 
     
  },  
  { 
    dataIndex: "max_punts_distance",
    title: "PuntLong", 
  },
 
  { 
    dataIndex: "total_ins_20",
    title: "PuntInside20", 
  },

  { 
    dataIndex: "total_kickoff",
    title: "KickoffNum", 
  },

  { 
    dataIndex: "total_kickoff_touchback",
    title: "KickoffTouchbacks", 
  },
  {
    title:'PancakeBlocks',
    dataIndex: 'total_pancakes'
  }
]}
]

  const handleExportToCSV = async () => {
    
    setDownloadLoading(true);
    const setTimeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        const csvData = [];
        // Add header row
       
        const rawDataRow = newcolumns.map((column) => column.children);

       
        let headerRow = rawDataRow.flatMap((innerArray) =>
          innerArray.map((obj) => { 
             if(!obj.excludeFromExport) return  [obj.title,obj.dataIndex];
          })
        );

       
        headerRow = headerRow.filter(function (element) {
          return element !== undefined;
        });

        csvData.push("1b863292-e319-4696-b2d4-04d09e09f92b");

        csvData.push(headerRow.map((row)=>row[0]?.replace(/\s/g, '')).join("|"));
         
        // Add data rows
        dataSource?.forEach((item) => {
          const rowData = headerRow.map((column) => {
            const data = column[1] == 'calculate' ? calculateParams(item,column[0]) : item[column[1]];
            return Number(data);
          });
          csvData.push(rowData.join("|"));
        });

        // Convert CSV data to blob
        const csvBlob = new Blob([csvData.join("\n")], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(csvBlob, "table_data.txt");

        resolve(); // Resolve the promise once the setTimeout function completes
      }, 3000);
    });

    // Await the promise and then set the loading state to false
    await setTimeoutPromise;
    setDownloadLoading(false);
  };

  return (
   <Button
      className="flex items-center   self-end  w-full justify-center  bg-[#1A91FF] !text-[#ffffff] h-11 rounded-[4px]"
      onClick={() => handleExportToCSV()}
      disabled={disableDownload || downloadLoading}
      loading={loading}

    >
      <img src={download} className="w-6" />
      <span className="ml-2">{downloadLoading ? "Exporting" : "Export"}</span>
    </Button>  
  );
};

export default ExportToCSVButton;
