import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select, Tooltip, Checkbox } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import puzzle from "../../../../assets/img/bl.svg";
import { ReceptionRadioButtons } from "../buttons/reception-button";
import { Link, useNavigate } from "react-router-dom";
import Title from './components/title.js'

function TargetModal({
  state,
  dispatch,
  close,
  schoolCustomStats,
  update,
  item,
  school_id,
  loadAllPlayerList,
  getStats,
  play,
  handleUpdatePlay,
  updateInputGrade,
  updateGrade,
  
}) {
  const [modalWidth, setModalWidth] = useState(425);
  let history = useNavigate();
  const [customStats, setCustomStats] = useState([]);
  const [customStatValues, setCustomStatValues] = useState(JSON.parse(item.custom_stats || '{}'));
const [ storage, setStorage ] = useState({})

const handleSaveCustomStat = (stat, value) => {

  const _customStatValues = {...customStatValues}
  _customStatValues[stat.label] =  value;
  setCustomStatValues(_customStatValues)
 
updateGrade('custom_stats', JSON.stringify(_customStatValues))

}
useEffect(() => {
    
  setStorage(item)
  
}, [item]); 

const updateStorage = (path, value) =>{ 
  const store = storage;
  store[path] = value;

  setStorage(store)
  updateGrade(path, value)
}

const handleCStats = () => {
  setCustomStatValues(JSON.parse(item.custom_stats || '[]')) 
  const _customStats = schoolCustomStats?.filter(
    (stat) => stat.odk == "o"
  );
  setCustomStats(_customStats?.length ? _customStats?.filter((stat)=> stat.index == '3') : []);
}
useEffect(handleCStats, [item.custom_stats, schoolCustomStats]); 
useEffect(handleCStats, []); 
 
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(352);
    } else {
      setModalWidth(425);
    }
  }, [window.innerWidth]);
  
  return state.target && (
    <Modal
      open={state.target}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
    
      title={
        <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
          <span className=" font-bold">Target</span>
          <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
          <span className="font-[400]">
            {item?.first_name} {item?.last_name}
          </span>
        </div>
      }
    >
      <div
        style={{ border: "1px solid #F3F5F7" }}
        className="mt-4 text-[12px] text-[#0F1A24]"
      >
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>GN/LS</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor flex items-center justify-center">
          <Input onChange={({target: { value }}) =>
          // console.log({ gn: value})
           handleUpdatePlay(play.id, { gn: value})
           } defaultValue={play.gn} size="large" />
        </div>
        </div>
{
play.play_type.toLowerCase()  != 'pass' ?

        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Carry</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor flex items-center justify-center">
          <Switch defaultChecked={item.total_carries} onChange={(e) => updateStorage('total_carries', e ? 1 : 0)} />{" "}
        
          </div>
        </div> :  ''
}
{
play.play_type.toLowerCase()  != 'pass' ?

    
      
      storage.total_carries ? <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Contact</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor flex items-center justify-center">
          <Input defaultValue={item.yards_after_contact} onChange={(e)=>updateInputGrade('yards_after_contact',e )} size="small" className="w-[40%] p-1" />{" "}
       
          </div>
        </div> : ''

    

:  ''
}
{
play.play_type.toLowerCase()  != 'run' ?    <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Reception</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor  flex items-center justify-center">
            <ReceptionRadioButtons
              item={item}
              defaultState={item.completions}
                handleQuickUpdate={(a, b, c, d, e, f) => {
                  console.log(c,d)
                  updateStorage(c,d)
                  update(a, b, c, d, e, f)
                }
                }
              title="completions"
              img={puzzle}
            //   value="1"
              tooltipTitle="Drop"
              tooltipTitleTwo="Incomplete"
              text={item.completions}
            />
          </div>
        </div>
:  ''
}
{
  play.play_type.toLowerCase()  != 'run' ?
      storage?.completions > 3 ?  <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Catch</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor flex items-center justify-center">
          <Input defaultValue={item.yards_after_catch} onChange={(e)=>updateInputGrade('yards_after_catch',e )} size="small" className="w-[40%] p-1" />{" "}
       
          </div>
        </div> : ''
        :  ''
      }
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Forced Missed Tackle</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor  flex items-center justify-center">
          <Switch defaultChecked={item.missed_tackles} onChange={(e) => updateGrade('missed_tackles', e ? 1 : 0)} />{" "}
        
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>TD</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor  flex items-center justify-center">
          <Switch defaultChecked={item.td} onChange={(e) => updateGrade('td', e ? 1 : 0)} />{" "}
        
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>2 Pt. Conversion</span>
          </div>{" "}
          <div className="p-3 w-[50%] turn-over actor flex items-center justify-center">
          <Switch defaultChecked={item?.two_part_conversion} onChange={(e) => updateGrade('two_part_conversion', e ? 1 : 0)} />{" "}
        
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[50%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Fumble</span>
          </div>{" "}
          <div className="p-3 w-[50%] fumble flex items-center justify-center">
          <Switch defaultChecked={item.caused_fumble} onChange={(e) => updateGrade('caused_fumble', e ? 1 : 0)} />{" "}
        
          </div>
        </div>
      <div className="px-3 pb-4 flex items-center justify-between text-md mt-4">
        <Checkbox defaultChecked={item.fumble_lost} onChange={({ target: { checked: e }}) => updateGrade('fumble_lost', e ? 1 : 0)} > <span className="text-[#2E4D6B]">Fumble Lost</span></Checkbox>
      </div>
      <div className="mt-4 px-2">
            <span className=" font-inter text-[14px] font-bold">
              Custom Stats{" "}
            </span>
            <div
              style={{ border: "1px solid #F3F5F7" }}
              className="mt-4 text-[12px] text-[#0F1A24] mb-2"
            >

              {
                  customStats?.map((stat, index) => {

                    return(
              <div
                className="flex items-center"
                style={{ borderBottom: "1px solid #F3F5F7" }}
              >
                <div
                  className="w-[70%] p-3 font-bold"
                  style={{ borderRight: "1px solid #F3F5F7" }}
                >
                  <span>{stat.label}</span>
                </div>{" "}
                <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
                 {/* {console.log(JSON.parse(item.custom_stats || '{}'),'customStatValues-----', item.custom_stats, customStatValues)} */}
                 { stat.input_type == "numeric" ?
                 
   (<Input defaultValue={JSON.parse(item.custom_stats || '{}')[stat.label]} onChange={(e)=>handleSaveCustomStat(stat,e.target.value)} size="small" className="w-[40%] p-1" />)
   :
   (<Switch
    defaultChecked={JSON.parse(item.custom_stats || '{}')[stat.label]}
    onChange={(e) => handleSaveCustomStat(stat, e ? 1 : 0)}
  />)}
        
                </div>
              </div>
                    )

                  })
              }

             
            </div>
            <span className="text-[#8698A9]">
              Edit custom stats in your{" "}
              <span onClick={() => history("/add-stats")} className="underline">Admin Settings</span>
            </span>
          </div>
        <div className="flex items-center justify-between mt-2">
          <button onClick={close} className="flex w-[49%] items-center focus:outline-0 justify-center border-[1px] border-[#1A91FF] text-[#1A91FF] h-11 rounded-[4px]">
            Cancel
          </button>
          <button onClick={close} className="flex w-[49%] items-center focus:outline-0 justify-center bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TargetModal;
