import React, { useState, useEffect, useRef } from "react";
import { Select, Card, Modal } from "antd";
import threeDot from "../../assets/img/Frame 18264.svg";
import SeasonsListSelect from "../../components/seasonsListSelect";
import { connect } from "react-redux";
import "./seasondashboard.css";
import OStatsTable from "./oStatsTable";
import DStatsTable from "./dStatsTable";
import StStatsTable from "./stStats";
import KickerStatsTable from "./kickerStatsTable";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ButtonUI from "../../components/ButtonUI";
import send from "../../assets/img/send.svg";
import arrowLeft from "../../assets/img/long-arrow-up-leftwhite.svg";
import Scoring from "./Scoring";


const { Option } = Select;

function StatsToPrint(props) {
  const location = useLocation();
  const history = useNavigate();
  const {
    dataSource = [],
    season = "",
    odk = "",
    theirTeam = "",
    type = "",
  } = location.state;
  const [openModal, setOpenModal] = useState(true);

  return (
    <>
      <div
        className="print-modal w-full h-screen absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 z-50"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
      >
        <div className=" lg:w-[50%] xl:w-[30%] bg-[white] p-4 rounded-lg shadow-2xl">
          <div>
            <div className="font-medium text-lg">Continue Printing?</div>
            <div className="flex items-center justify-between w-full mt-3">
              <button
                onClick={() => {
                  window.print();
                  setTimeout(() => {
                    // history("/stats-view");
                    window?.location.reload();
                  }, 1000);
                }}
                className="bg-[#1A91FF] w-[40%] text-[#FFFFFF] p-2 rounded-[4px]"
              >
                Yes
              </button>{" "}
              <button
                onClick={() => window?.location.reload()}
                className="bg-[#A40E17] w-[40%] text-[#FFFFFF] p-2 rounded-[4px]"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {type === "stats" ? (
        <div className="flex flex-col font-inter lg:w-[99%] lg:p-[1%] stats-table-con">
          {type === "scoring" ? (
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center w-[80%]">
                <div className="px-2 bg-[black] w-[242px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
                  <button
                    onClick={() => {
                      history("/stats-view");
                    }}
                    className={
                      "bg-[#A40E17] w-full h-[31px] cursor-pointer flex items-center rounded-[4px]  text-[#FFFFFF]"
                    }
                  >
                    <img src={arrowLeft} />
                    <span className="font-bold">Return to Season Stats</span>
                  </button>
                </div>
                <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
                  <Select
                    bordered={false}
                    placeholder={"All Games"}
                    className="w-[100%] text-[14px]"
                  ></Select>
                </div>
                <SeasonsListSelect defaultValue={season} />
                <span className="text-[14px] font-normal mx-2">
                  <span className="text-[#A40E17]"> vs</span> {theirTeam}
                </span>
              </div>
              <div className=" w-[167px] lg:w-[18%] 2xl:w-[15%]">
                <ButtonUI
                  text={"Send to Maxpreps"}
                  background={"#1A91FF"}
                  leftIcon={send}
                  buttonFunction={() => {}}
                />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center w-[95%]">
                <div className="px-2 bg-[black] w-[200px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
                  <button
                    className={
                      (odk == "o" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer  rounded-[4px] mr-[10px] text-[#FFFFFF]"
                    }
                  >
                    O
                  </button>
                  <button
                    className={
                      (odk == "d" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer mr-[10px]   rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    D
                  </button>
                  <button
                    className={
                      (odk == "st" ? " bg-[#A40E17]" : " ") +
                      " w-[32px] h-[31px] cursor-pointer mr-[10px]  rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    ST
                  </button>
                  <button
                    className={
                      (odk == "kicker" ? " bg-[#A40E17]" : " ") +
                      " w-[70px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF]"
                    }
                  >
                    Kicker
                  </button>
                </div>
                <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
                  <Select
                    bordered={false}
                    placeholder={"All Games"}
                    className="w-[100%] text-[14px]"
                  ></Select>
                </div>
                <SeasonsListSelect defaultValue={season} />
                <span className="text-[14px] font-normal mx-2">
                  <span className="text-[#A40E17]"> vs</span> {theirTeam}
                </span>
              </div>
              <img
                src={threeDot}
                className="bg-[#E8F4FF] rounded-lg py-2 px-3 mx-auto cursor-pointer"
              />
            </div>
          )}

          <div className="mt-2 w-full flex flex-col" id="stats-table-con">
            <Card
              bodyStyle={{ padding: 0, width: "100%" }}
              className="stats-table-con-two"
            >
              <Card
                bodyStyle={{ padding: 0, width: "100%" }}
                className="w-full"
              >
                <div
                  style={{ borderBottom: "1px solid #F3F5F7" }}
                  className="w-full flex flex-col"
                >
                  {odk === "o" && (
                    <OStatsTable dataSource={dataSource} print={true} />
                  )}
                  {odk === "d" && (
                    <DStatsTable dataSource={dataSource} print={true} />
                  )}
                  {odk === "st" && (
                    <StStatsTable dataSource={dataSource} print={true} />
                  )}
                  {odk === "kicker" && (
                    <KickerStatsTable dataSource={dataSource} print={true} />
                  )}
                </div>
              </Card>
            </Card>
          </div>
        </div>
      ) : (
        <Scoring print={true}/>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatsToPrint);
