import React, { useState } from "react";
import { Modal, Select, Option } from "antd";

function ResultModal({
  onOpen,
  onCancel,
  title,
  buttonText,
  handleUpdateEvent,
  game,
  setIsNotificationModalVisible,
}) {
  const [formValues, setFormValues] = useState({});
  const handleFormValuesUpdate = ({ target: { name, value } }) => {
    const values = formValues;
    values[name] = value;

    setFormValues(values);
  };

  const handleUpdateEv = async () => {
    await handleUpdateEvent({
      ...formValues,
    });
    setIsNotificationModalVisible && setIsNotificationModalVisible(game);
  };

  return (
    <Modal
      open={onOpen}
      closable={true}
      onCancel={onCancel}
      title={
        <span className="text-[14px] font-inter font-bold text-[#0F1A24]">
          {title}
        </span>
      }
      footer={null}
      width={470}
    >
      <div className="flex flex-col font-inter text-[14px] text-[#0F1A24]">
        <span className="mt-[10.5px] font-normal">Game Result</span>

        <span className="mt-[16px] font-bold">Final Score</span>
        <div className="mt-[16px] flex items-center justify-between">
          <div className="w-[48%] flex flex-col">
            <span className="font-normal">Our Score</span>
            <input
              placeholder={game?.home_team_score}
              onChange={(e) => handleFormValuesUpdate(e)}
              name="home_team_score"
              type="number"
              className="mt-[4px] w-full h-[47px] border-[1px] border-solid border-[#EAEDF0] outline-none px-[16px]"
            />
          </div>
          <div className="w-[48%] flex flex-col">
            <span className="font-normal">Opponent Score</span>
            <input
              placeholder={game?.away_team_score}
              onChange={(e) => handleFormValuesUpdate(e)}
              name="away_team_score"
              type="number"
              // value={game?.away_team_score || ""} 
              className="mt-[4px] w-full h-[47px] border-[1px] border-solid border-[#EAEDF0] outline-none px-[16px]"
            />
          </div>
        </div>
        <div className="flex self-end mt-[12px]">
          <button
            className="w-[98px] h-[43px] flex items-center justify-center mr-[8px] rounded-[4px] text-[#1A91FF] border-[2px] border-solid border-[#1A91FF] outline-none"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="w-[98px] h-[43px] flex items-center justify-center rounded-[4px] bg-[#1A91FF] text-[#ffffff]"
            onClick={() => handleUpdateEv()}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ResultModal;
