import React, { useState, useEffect } from "react";
import { Select } from "antd";
import _ from "lodash";
import { connect } from "react-redux";

const { Option } = Select;
function WeekListSelect(props) {
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonID, setSeasonID] = useState(null);

  useEffect(() => {
    loadSeasonsList();
  }, []);
  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList"));
    if (list) {
      setSeasonsList(list);
    }
    props.seasonsList({
      params: {
        sort: "id.desc",
        limit: 1000,
      },
      callback: (res, data) => {
        if (res === true) {
          const result = data.data.items;
          setSeasonsList(result);
          sessionStorage.setItem("seasonsList", JSON.stringify(result));
        }
      },
    });
  };

  useEffect(() => {
    if (props?.coachProfile?.school?.id) {
      loadGamesList();
    }
  }, [seasonsList, seasonID]);

  const loadGamesList = () => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;

      if (season) {
        setSeasonID(season);
        let params = {
          limit: 995,
          home_team: props?.coachProfile?.school?.id,
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(
          sessionStorage.getItem(`${seasonID}-gamesList`)
        );

        if (list) {
          setAllGamesList(list);
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }

        props?.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              setAllGamesList(data?.data?.items);
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setAllGamesList = (data) => {
    const list = []; 

    if (data?.length > 0) {
      data?.map((value, index) => {
        list.push({
          key: index,
          id: parseInt(value?.id),
          title: value.title,
          week: `Week ${value.week}`,
          season: value?.season,

          game_id: value?.id,
          opponent_logo: value?.opponent_logo,
          away_team_score: value.away_team_score ?? 0,
          home_team_score: value.home_team_score ?? 0,
          team: props.coachProfile?.school?.name,
          school: props.coachProfile?.school?.id,
          type: value?.type,
          feedback_type: value?.feedback_type,
        });
      });
 
      setGamesList(list);
    } else {
      setGamesList([]);
    }
  };

  return (
    <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
      <Select
        bordered={false}
        placeholder={"All Games"}
        className="w-[100%] text-[14px]"
        onChange={props.onChange}
      >
        <Option
          value={"all"}
          key={"all"}
          className="text-[14px] font-bold font-inter"
        >
          All Games
        </Option>
        {gamesList?.map(({ id, title, week }, i) => {
          return (
            <Option
              value={id}
              key={title}
              className="text-[14px] font-bold font-inter"
            >
              Week {week}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeekListSelect);
