import React from "react";

function FormationTableHead() {
  return (
    <div className="grid grid-cols-6 bg-[#F3F5F7] text-[#0F1A24]  font-bold text-[12px]">
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]"></span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]"></span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
        Total Yards
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
        Avg Yards
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
        Execution
      </span>
      <span className="col-span-1  border border-solid border-[#EAEDF0] text-center py-[12px]">
        Effort
      </span>
    </div>
  );
}

export default FormationTableHead;
