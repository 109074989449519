import actionType from "../actionTypes";
import _ from "lodash";
const reducerName = actionType.reduxUpdatePlaysState;

export default {
  creates: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/depth-chart/create`,
        },
        actionType: actionType.depth_chart.creates,
        reducerName,
        dispatch,
        componentRequest,
      };

      global.api.requestAPI(requestOptions);
    };
  },
  delete: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "delete",
          url: `/warboard/depth-chart/${_.get(componentRequest, "params.id")}`,
        },
        actionType: actionType.depth_chart.delete,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  deletePlayer: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "delete",
          url: `/warboard/depth-chart/player/${_.get(
            componentRequest,
            "params.id"
          )}`,
        },
        actionType: actionType.depth_chart.deletePlayer,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  addPlayer: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/depth-chart/add/player`,
        },
        actionType: actionType.depth_chart.addPlayer,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  updatePlayer: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/depth-chart/update/players`,
        },
        actionType: actionType.depth_chart.updatePlayer,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  list: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/list`,
        },
        actionType: actionType.depth_chart.list,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  grades: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/grades`,
        },
        actionType: actionType.depth_chart.grades,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  players: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/players`,
        },
        actionType: actionType.depth_chart.players,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  shared: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/shared`,
        },
        actionType: actionType.depth_chart.shared,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  share: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/share`,
        },
        actionType: actionType.depth_chart.share,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  school: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/school`,
        },
        actionType: actionType.depth_chart.school,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  getFeedback: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/feedback`,
        },
        actionType: actionType.depth_chart.feedback,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  listFeedback: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/warboard/depth-chart/feedback/play`,
        },
        actionType: actionType.depth_chart.feedbackPlay,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  sendFeedback: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/warboard/depth-chart/feedback`,
        },
        actionType: actionType.depth_chart.feedbackPlay,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
};
