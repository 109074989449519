import LeaderboardComponent from "./LeaderboardComponent";

export const generateLeaderboard = (dataSource, keys, title) => {
    return dataSource?.map((data) => ({
      ...data,
      [title]: keys.reduce((acc, key) => acc + (Number(data?.[key]) ?? 0), 0),
    }));
  };

  export const generateTeamLeaderboard = (dataSource) => {
    const generateAssignmentExecuted = () => {
      const assignmentsExecuted = dataSource?.map((data) => ({
        ...data,
        assignmentsExecuted: Number(data?.total_assignment_executed) || 0,
        greatEffort: Number(data?.total_great_effort) || 0,
        positiveImpact: Number(data?.total_positive_impact) || 0,
        pointsScored:
          (Number(data?.total_rbtd) +
            Number(data?.total_wrtd) +
            Number(data?.total_fum_ret_td) +
            Number(data?.total_int_ret_td) +
            Number(data?.total_punt_return_td) +
            Number(data?.total_kickoff_return_td)) *
            6 +
            Number(data?.total_pat) * 1 +
            Number(data?.total_safety) * 2 +
            Number(data?.total_two_part_conversion) * 2 +
            Number(data?.total_field_goals) * 3 || 0,
      }));
      return assignmentsExecuted;
    };

    return (
      <div className="w-full flex gap-4">
        <LeaderboardComponent
          key="assignmentsExecuted"
          title="Assignments Executed"
          playerStats={generateAssignmentExecuted()}
          statistics={["assignmentsExecuted"]}
        />
        <LeaderboardComponent
          key="greatEffort"
          title="Great Effort Plays"
          playerStats={generateAssignmentExecuted()}
          statistics={["greatEffort"]}
        />
        <LeaderboardComponent
          key="positiveImpact"
          title="Positive Impact Plays"
          playerStats={generateAssignmentExecuted()}
          statistics={["positiveImpact"]}
        />
        <LeaderboardComponent
          key="pointsScored"
          title="Points Scored"
          playerStats={generateAssignmentExecuted()}
          statistics={["pointsScored"]}
        />
      </div>
    );
  };

 export const generateOffenseLeaderboard = (dataSource, rawDataSource) => {
    const offenseStatisticsConfig = {
      totalYards: {
        title: "Total Yards",
        keys: [
          "total_gn_carries_yds",
          "total_completions_carries_yds",
          "total_complete_receptions_yds",
        ],
      },
      rushingYards: {
        title: "Rushing Yards",
        keys: ["total_gn_carries_yds"],
      },
      receptionYards: {
        title: "Reception Yards",
        keys: ["total_completions_carries_yds"],
      },
      totalOffenseTD: {
        title: "Offensive TD’s",
        keys: ["total_wrtd", "total_rbtd", "total_qbtd"],
      },
    };

    const generateStatistics = () => {
      return Object.entries(offenseStatisticsConfig)?.map(
        ([statisticKey, { title, keys }]) => (
          <LeaderboardComponent
            key={statisticKey}
            title={title}
            playerStats={generateLeaderboard(dataSource, keys, statisticKey)}
            statistics={[statisticKey]}
          />
        )
      );
    };

    const generatePassProPancakes = (position) => {
      const players = rawDataSource?.filter(
        (player) => player.position === position
      );
      const playerTotalPassPro = players?.map((player) => {
        const totalPassPro =
          player?.total_pass_pro_sacks_won !== undefined &&
          player?.total_pass_pro_sacks_lost !== undefined &&
          (Number(player?.total_pass_pro_sacks_won) !== 0 ||
            Number(player?.total_pass_pro_sacks_lost) !== 0)
            ? Math.round(
                (Number(player?.total_pass_pro_sacks_won) /
                  (Number(player?.total_pass_pro_sacks_won) +
                    Number(player?.total_pass_pro_sacks_lost))) *
                  100
              ) + "%"
            : 0;
        const totalPancakes = Number(player.total_pancakes) || 0;
        return {
          ...player,
          totalPassPro,
          totalPancakes,
        };
      });
      return playerTotalPassPro;
    };

    return generateStatistics().concat(
      <LeaderboardComponent
        key="oLinePancakes"
        title="O-Line Pancakes"
        playerStats={generatePassProPancakes("OL")}
        statistics={["totalPancakes"]}
      />,
      <LeaderboardComponent
        key="wrLinePancakes"
        title="WR Pancakes"
        playerStats={generatePassProPancakes("WR")}
        statistics={["totalPancakes"]}
      />,
      <LeaderboardComponent
        key="olPassPro"
        title="OL Pass Pro %"
        playerStats={generatePassProPancakes("OL")}
        statistics={["totalPassPro"]}
      />,
      <LeaderboardComponent
        key="rbPassPro"
        title="RB Pass Pro %"
        playerStats={generatePassProPancakes("RB")}
        statistics={["totalPassPro"]}
      />
    );
  };

export  const generateDefenseLeaderboard = (dataSource) => {
    const defenseStatisticsConfig = {
      totalTackles: {
        title: "Tackles",
        keys: [
          "total_tackles_solo", 
          "total_tackles_ast_halved",
        ],
      },
      totalMissedTackles: {
        title: "Missed Tackles",
        keys: ["total_missed_tackles"],
      },
      totalTacklesLoss: {
        title: "Tackles for a Loss",
        keys: ["total_tackles_tfl"],
      },
      totalTacklesSacks: {
        title: "Sacks",
        keys: ["total_tackles_sak"],
      },
      totalPBU: {
        title: "PBU",
        keys: ["total_pd"],
      },
      totalInterception: {
        title: "Interceptions",
        keys: ["total_int"],
      },
      totalTurnOverCreated: {
        title: "Turnovers Created",
        keys: ["total_turnovers", "total_recovered_fumble"],
      },
      totalDefensiveTD: {
        title: "Defensive TD’s",
        keys: ["total_td"],
      },
    };

    return Object.entries(defenseStatisticsConfig).map(
      ([statisticKey, { title, keys }]) => (
        <LeaderboardComponent
          key={statisticKey}
          title={title}
          playerStats={generateLeaderboard(dataSource, keys, statisticKey)}
          statistics={[statisticKey]}
        />
      )
    );
  };

 export const generateSpecialTeamsLeaderboard = (dataSource) => {
    const specialTeamsStatisticsConfig = {
      totalPlays: {
        title: "Special Teams Plays",
        keys: ["plays"],
      },
      totalSpecialTeamsTackles: {
        title: "Special Teams Tackles",
        keys: [
          "total_tackles_solo",
          "total_tackles_ast_halved",
        ],
      },
      speciialTeamsBlocks: {
        title: "Special Teams Blocks",
        keys: ["total_punt_blocks", "total_fg_blocks"],
      },
    };

    const generateSpecialTeamsValues = () => {
      const playerFGMPercentage = dataSource?.map((data) => ({
        ...data,
        FGMPercentage:
          (
            (Number(data?.total_field_goals) /
              (Number(data?.total_field_goals) +
                Number(data?.total_block_field_goals) +
                Number(data?.total_missed_field_goals))) *
              100 || 0
          )?.toFixed(1) + "%",
        specialTeamsPoints:
          (Number(data?.total_punt_return_td) +
            Number(data?.total_kickoff_return_td)) *
            6 +
            Number(data?.total_pat) +
            Number(data?.total_field_goals) * 3 || 0,
        kickoffReturnYards: Number(data?.total_kickoff_return_yds) || 0,
        puntReturnYards: Number(data?.total_punt_return_yds) || 0,
        totalReturnYards:
          Number(data?.total_punt_return_yds) +
            Number(data?.total_kickoff_return_yds) || 0,
        totalSpecialTeamsTD:
          Number(data?.total_st_td) +
            Number(data?.total_punt_return_td) +
            Number(data?.total_kickoff_return_td) || 0,
        FGMAvgDistance: Number(data?.avg_field_goal_distance)?.toFixed(2) || 0,
        FGMLong: Number(data?.max_field_goal_distance) || 0,
        PuntAvgDistance:
          data?.total_punts_distance &&
          data?.total_punts &&
          data?.total_punts_distance !== 0 &&
          data?.total_punts !== 0
            ? (
                Number(data.total_punts_distance) / Number(data.total_punts)
              ).toFixed(2)
            : 0,
      }));

   
      return playerFGMPercentage;
    };

    const generateStatistics = () => {
      return Object.entries(specialTeamsStatisticsConfig).map(
        ([statisticKey, { title, keys }]) => (
          <LeaderboardComponent
            key={statisticKey}
            title={title}
            playerStats={generateLeaderboard(dataSource, keys, statisticKey)}
            statistics={[statisticKey]}
          />
        )
      );
    };

    return generateStatistics().concat(
      <LeaderboardComponent
        key="specialTeamsPoints"
        title="Special Teams Points"
        playerStats={generateSpecialTeamsValues()}
        statistics={["specialTeamsPoints"]}
      />,
      <LeaderboardComponent
        key="kickoffReturnYards"
        title="Kickoff Return Yards"
        playerStats={generateSpecialTeamsValues()}
        statistics={["kickoffReturnYards"]}
      />,
      <LeaderboardComponent
        key="puntReturnYards"
        title="Punt Return Yards"
        playerStats={generateSpecialTeamsValues()}
        statistics={["puntReturnYards"]}
      />,
      <LeaderboardComponent
        key="totalReturnYards"
        title="Total Return Yards"
        playerStats={generateSpecialTeamsValues()}
        statistics={["totalReturnYards"]}
      />,
      <LeaderboardComponent
        key="totalSpecialTeamsTD"
        title="Special Teams TD’s"
        playerStats={generateSpecialTeamsValues()}
        statistics={["totalSpecialTeamsTD"]}
      />,
      <LeaderboardComponent
        key="fgmPercentage"
        title="FGM %"
        playerStats={generateSpecialTeamsValues()}
        statistics={["FGMPercentage"]}
      />,
      <LeaderboardComponent
        key={"fgmAvgDistance"}
        title="FGM Avg Distance"
        playerStats={generateSpecialTeamsValues()}
        statistics={["FGMAvgDistance"]}
      />,
      <LeaderboardComponent
        key={"fgmLong"}
        title="FGM Long"
        playerStats={generateSpecialTeamsValues()}
        statistics={["FGMLong"]}
      />,
      <LeaderboardComponent
        key={"puntAvgDistance"}
        title="Punt Avg Distance"
        playerStats={generateSpecialTeamsValues()}
        statistics={["PuntAvgDistance"]}
      />
    );
  };