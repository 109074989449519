import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, message, Button, Alert } from "antd";
import "./login.css";
import logo from "../../assets/img/Logologin.svg";
import line from "../../assets/img/Line.png";
import _ from "lodash";
import { connect } from "react-redux";
import LoginModal from "./LoginModal";
import { useNavigate } from "react-router-dom";
import xLogo from "../../assets/img/xlogo.png";
import instagram from "../../assets/img/icons8-instagram-50.png";
import facebook from "../../assets/img/facebooklogo.png";

const LoginPageComponent = (props) => {
  let history = useNavigate();
  const currentURL = window.location?.search;
  const [loading, setLoading] = useState(false);
  const [invalidPswd, setInvalidPswd] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onLogin = (values) => {
    values.username = lowercaseFirstLetter(_.get(values, "username"));
    setLoading(true);
    props.login({
      body: { ...values },
      callback: (response, data) => {
        setLoading(false);
        if (response === true) {
          history("/coaches-evaluation");
          console.log(values);
          console.log("response, data", response, data);
        } else {
          console.log("response, data", response, data);
          setInvalidPswd(true);
        }
      },
    });
  };
  const error = (content) => {
    messageApi.open({
      type: "error",
      content,
    });
  };
  const lowercaseFirstLetter = (str) => {
    // converting first letter to lowerCase
    const lowerCase = str.toLowerCase();

    return lowerCase;
  };
  const render = () => {
    // const pageHeader = document.getElementsByClassName('PageHeader');
    // pageHeader[0].style.display = 'none';

    return (
      <div className="LoginContainer">
        {contextHolder}
        {/* GRADIENT COLORS  */}
        <div className="rectangle1"></div>
        <div className="rectangle2"></div>
        {/* END GRADIENT COLORS  */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-5 col-lg-5 pl-0  ">
              <div className="LoginpageContainer mt-1">
                <br />
                {currentURL.split("=")[1] === "success" ? (
                  <Alert
                    message="Email Verification Successful"
                    description="Your email verification was successful, please login to start enjoying more productivity!"
                    type="info"
                    showIcon
                    closable
                  />
                ) : (
                  ""
                )}
                <div className="LoginLogoContainer">
                  <div className="text-[white] mb-4 text-base font-bold flex flex-col items-center justify-center">
                    <p>Follow us on social media </p>
                    <div className="flex items-center gap-4 justify-center">
                      <a
                        href="https://x.com/Wardbord_"
                        target="_blank"
                        className="bg-[white] rounded-md w-12  h-12 flex items-center justify-center"
                      >
                        <img src={xLogo} className="w-10" alt="" />
                      </a>
                      <a
                        href="https://www.instagram.com/wardbord/"
                        target="_blank"
                        className="bg-[white] rounded-md w-12  h-12 flex items-center justify-center"
                      >
                        <img src={instagram} className="w-10" alt="" />
                      </a>
                      <a
                        href="https://www.facebook.com/wardbord"
                        target="_blank"
                        className="bg-[white] rounded-md w-12  h-12 flex items-center justify-center"
                      >
                        <img src={facebook} className="w-10" alt="" />
                      </a>
                    </div>
                  </div>
                  <img src={logo} className="PageMainLogo" alt="" />
                  <span className="LoginPageLoginText">
                    Login to your account
                  </span>
                </div>
                <Form
                  onFinish={(e) => onLogin(e)}
                  className="LoginForm"
                  name="basic"
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Email address"
                    name="username"
                    autoComplete="new-password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email address!",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="new-password"
                      placeholder="eg: name@example.com"
                    />
                  </Form.Item>
                  <Form.Item
                    autoComplete="new-password"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="new-password"
                      placeholder="Enter your password"
                    />
                  </Form.Item>
                  <Form.Item name="remember">
                    <p className="afterText">
                      <Link
                        to="/forgot-password"
                        className="loginFormForgot"
                        href=""
                      >
                        Forgot your password?
                      </Link>
                    </p>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>
                  <div className="flex"></div>
                  <p className="afterText bg-black">
                    Dont have an account?
                    <Link to="/register" className="loginFormForgot " href="">
                      <b> Create Account</b>
                      <img src={line} className="scribble" alt="" />
                    </Link>
                  </p>
                  OR
                  <Link to="/express-checkout">Try express Checkout</Link>
                </Form>
              </div>
            </div>
          </div>
          {invalidPswd && (
            <LoginModal
              onOpen={invalidPswd}
              title="Ops! your email address or password is not correct"
              onCancel={() => setInvalidPswd(false)}
            />
          )}
          {invalidEmail && (
            <LoginModal
              onOpen={invalidEmail}
              title="The email address you entered is invalid."
              onCancel={() => setInvalidEmail(false)}
            />
          )}
        </div>
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(global.redux.action.account.login(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
