import React, { useEffect, useState } from "react";
import { Steps, Button, message, Form } from 'antd';
import Step1 from './step1';
import Step2 from './step2';

const { Step } = Steps;
const { Item } = Form;



const SchoolAdminComponent = () => {
  const [current, setCurrent] = React.useState(0);
  const [coachDetails, setCoachDetails] = React.useState([]);
  const steps = [
    {
      title: 'Create Admin',
      content: <Step1 next={(details) => next(details)} />,
    },
    {
      title: 'Create School',
      content: <Step2 coachDetails={coachDetails} />,
    },
  ];
  const next = (details) => {
    setCoachDetails(details)  
    setCurrent(current + 1);
  };

  // const prev = () => {
  //   setCurrent(current - 1);
  // };

  return (
    <div className="w-[98%] p-[2%]">
      <div className="w-full">
        <div className="CreateSchoolAdmin-inner">
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          {/*<div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => message.success('Processing complete!')}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>*/}
        </div>
      </div>
    </div>

  );
};

export default SchoolAdminComponent;