import actionType from '../actionTypes';
import _ from 'lodash';
const reducerName = actionType.reduxUpdateSeasonsState;

export default {
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/seasons/list`
                },
                actionType: actionType.seasons.list,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
};