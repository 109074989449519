export const reduxActionTypes = {
  // please have all account actions types const in the below account property
  account: {
    name: "account",
    isLogin: "isLogin",
    profile: "profile",
    login: "login",
    register: "register",
    logout: "logout",
    requestResetPassword: "requestResetPassword",
    changePassword: "changePassword",
    resetPassword: "resetPassword",
    location: "location",
    resetEmailVerification: "resetEmailVerification",
  },

  coaches: {
    name: "coaches",
    list: "list",
    create: "create",
    view: "view",
    changeStatus: "changeStatus",
  },

  // please have all schools actions types const in the below account property
  schools: {
    name: "schools",
    list: "list",
    view: "view",
    update: "update",
    updatePhone: "updatePhone",
    updatePhoto: "updatePhoto",
    addfeaturedphoto: "addfeaturedphoto",
    subscription: "subscription",
    send_invoice: "send_invoice",
    subscriptionHistory: "subscriptionHistory",
  },

  athletes: {
    name: "athletes",
    list: "list",
    infoList: "infoList",
    create: "create",
    view: "view",
    edit: "edit",
    editProfile: "editProfile",
    trash: "trash",
  },

  plays: {
    name: "plays",
    create: "create",
    update: "update",
    list: "list",
    uploadPlaylist: "upload-playlist",
  },

  depth_chart: {
    name: "depth_chart",
    creates: "creates",
    list: "list",

    updatePlayer: "update-player",
    grades: "grades",
    addPlayer: "add-player",
    delete: "delete",
    deletePlayer: "deletePlayer",
    sendFeedback: "sendFeedback",
    listFeedback: "listFeedback",
    getFeedback: "getFeedback",
    share: "share",
    shared: "shared",
  },

  games: {
    name: "games",
    create: "create",
    list: "list",
    listSort: "listSort",
    analytics: "analytics",
    analyticspergame: "analyticspergame",
    view: "view",
    update: "update",
    changeStatus: "changeStatus",
    updateSponsorLogo: "updateSponsorLogo",
    updateOpponentLogo: "updateOpponentLogo",
    createStats: "createStats",
    getStats: "getStats",
    updateStats: "updateStats",
    deleteStats: "deleteStats",
    uploadScoutingReport: "uploadScoutingReport",
    fetchScoutingReport: "fetchScoutingReport",
    generateSignedUrl: "generateSignedUrl",
  },
  grades: {
    name: "grades",
    create: "create",
    approve: "approve",
    list: "list",
    view: "view",
    update: "update",
    gridironDetails: "gridironDetails",
    changeStatus: "changeStatus",
    matchAnalytics: "matchAnalytics",
    matchAnalyticsbdn: "matchAnalyticsbdn",
    matchAnalyticsByDepthChart: "matchAnalyticsByDepthChart",
    playTypeGrades: "playTypeGrades",
    playTypeGradesAnalytics: "playTypeGradesAnalytics",
    scoutingReport: "scoutingReport",
  },

  site: {
    gridironDetails: "gridironDetails",
    name: "site",
  },

  mvps: {
    name: "mvps",
    create: "create",
    list: "list",
    changeStatus: "changeStatus",
    view: "view",
  },

  seasons: {
    name: "seasons",
    list: "list",
  },

  super_admin: {
    name: "super_admin",
    coachesList: "coachesList",
    teamList: "teamList",
    plansList: "plansList",
    analytics: "analytics",
    createSubscription: "createSubscription",
    createPlan: "createPlan",
    modifyPlan: "modifyPlan",
    upgradeSubscription: "upgradeSubscription",
    createAffiliate: "createAffiliate",
    getAffiliates: "getAffiliates",
    playAnalytics: "playAnalytics",
    schoolAnalytics: "schoolAnalytics",
    subscriptionAnalytics: "subscriptionAnalytics",
    gameAnalytics: "gameAnalytics",
  },

  // update specific action state
  reduxUpdateAccountState: "reduxUpdateAccountState",
  reduxUpdateCoachesState: "reduxUpdateCoachesState",
  reduxUpdateSchoolsState: "reduxUpdateSchoolsState",
  reduxUpdateAthletesState: "reduxUpdateAthletesState",
  reduxUpdatePlaysState: "reduxUpdatePlaysState",
  reduxUpdateGamesState: "reduxUpdateGamesState",
  reduxUpdateGradesState: "reduxUpdateGradesState",
  reduxUpdateMvpsState: "reduxUpdateMvpsState",
  reduxUpdateSiteState: "reduxUpdateSiteState",
  reduxUpdateSeasonsState: "reduxUpdateSeasonsState",
  reduxUpdateSuperAdminState: "reduxUpdateSuperAdminState",
};
export default reduxActionTypes;
