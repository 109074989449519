import {
  Table,
  Card,
  message,
  Modal,
  Select,
  Avatar,
  Space,
  Button,
  Dropdown,
  List,
  Skeleton,
} from "antd";
import DepthChart from "./DepthChart";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./home-page.css";
import {
  MoreOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  LoadingOutlined,
    DeleteOutlined, ShareAltOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _, { filter, isEmpty } from "lodash";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ManageSharingModal from "./ManageSharingModal"; // Import the ManageSharingModal component

const { Option } = Select;
const { confirm } = Modal;
function checkCommonValue(arr1, arr2) {
  for (let i = 0; i < arr1?.length; i++) {
    if (arr2.includes(arr1[i])) {
      return true; // Found a common value
    }
  }
  return false; // No common value found
}
const allowedPosition = {
  QB: ["QB"],
  OL: ["OL"],
  WR: ["WR"],
  TE: ["TE"],
  RB: ["RB"],
  DL: ["DL"],
  LB: ["LB"],
  S: ["S"],
  C: ["C"],
  DB: ["C", "S"],
  DF: ["DL","LB"],
  IB: ["LB"],
  OB: ["LB"],
  K: ["K"],
  ST: ["ST"],
};

const MyDepthChartMain = ({
  getDepthChartPlayers,
  handleUpdateDepthChart,
  setCoachDepthChartId,
  showPlayersModal,
  loadAthletesList,
  data,
  deleteDepthChartPlayer,
  deleteDepthChart,
  athletesListLoading,
  selectedDepthChartName,
  athletesList,
  addPlayerDepthChart,
  allAthletesList,
  coachProfile,
  editPlayer,
  handleDeleteDepthChart,
  coachesList,
 isShared,
 coach,
}) => {
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [playerList, setPlayerList] = useState([]);
  const [playerListLoading, setPlayerListLoading] = useState(false);
  const [isManageSharingModalVisible, setIsManageSharingModalVisible] = useState(false);

 

  const handleShareChart = (selectedCoaches) => {
    // Implement the logic to share the chart with selected coaches here
  };

  const showConfirm = (
    id,
    rank,
    depth_chart_position,
    athlete_position,
    pid
  ) => {
    confirm({
      title: `Would you like to add ${depth_chart_position} to this player's position`,
      icon: <ExclamationCircleFilled />,
      okType: "success",
      content:
      "Do you want to add this player to this position? This player plays a different position!",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          updatePlayer(id, rank, depth_chart_position, athlete_position, pid);
        }).catch((error) => console.log("Oops errors!", error));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updatePlayer = (
    id,
    rank,
    depth_chart_position,
    athlete_position,
    pid
  ) => {
    if(athlete_position?.length){ 
      athlete_position.push(depth_chart_position);
    }else {
      athlete_position = [depth_chart_position]
    }

    let body = {
      positions: athlete_position,
      athlete: pid,
      id: pid,
    };

    editPlayer({
      body,
      callback: (res, responseData) => {
        if (res == true) {
          handleAddPlayerDepthChart(
            id,
            rank,
            [depth_chart_position],
            athlete_position,
            pid
          );
        }
      },
    });
  };

  const filteredAthletesList = athletesList?.filter((player) => {
    const playerIdList = playerList?.map((item) => item.id);
    return !playerIdList?.includes(player.id);
  });

  const handleAddPlayerDepthChart = (
    id,
    rank,
    depth_chart_position,
    athlete_position,
    pid
  ) => {
    if (!coachProfile?.id && !coachProfile?.school?.id) {
      handlePopMessage(
        "error",
        "Error adding player to depth chart, please reload your page and try again..."
      );
      return;
    }

    const hasCommonValue = checkCommonValue(
      athlete_position,
      depth_chart_position
    ); 

    if (hasCommonValue) {
      const depth_chart_id = data.id;
      const coach_id = coachProfile.id;
      const player_id = id;
      const school_id = coachProfile.school.id;

      // Check if the player is already in the depth chart
      const isPlayerInDepthChart = checkPlayerInDepthChart(player_id);

      if (isPlayerInDepthChart) {
        handlePopMessage("error", "Player is already in the depth chart.");
        return;
      }

      handlePopMessage("loading", "Adding player to depth chart...");

      addPlayerDepthChart({
        body: {
          depth_chart_id,
          coach_id,
          rank,
          player_id,
          school_id,
        },
        callback: (res, data) => {
          if (res == true) {
            sessionStorage.removeItem(`${depth_chart_id}-DepthChartPlayers`);
            handlePopMessage("success", "Player added...");
            fetchDepthChartPlayers();
          }
        },
      });
    } else {
      showConfirm(id, rank, depth_chart_position[0], athlete_position, pid);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePopMessage = (
    type = "loading",
    content = "loading...",
    duration = 2
  ) =>
    messageApi.open({
      key: "updatable",
      type,
      content,
      duration,
    });
  const fetchDepthChartPlayers = () => {
    setPlayerListLoading(true);

    getDepthChartPlayers({
      params: { depth_chart_id: data.id },
      callback: (res, data) => {
        if (res == true && data) {
          setPlayerList(data?.data?.rows);
        }
        setPlayerListLoading(false);
      },
    });
  };

  const moveUp = (id, key) => {
    if (key >= 1) {
      playerList[key - 1].rank = playerList[key].rank;
      playerList[key].rank = key;

      swapItems(playerList, key, key - 1);

      setPlayerList(playerList);
      setValue((value) => value + 1);
      handleUpdateDepthChart(
        id,
        playerList[key].player_id,
        playerList[key - 1].player_id,
        playerList[key].rank,
        key
      );
    }
  };
  const showModal = (id = null, name = null, players = [], positions = "") => {
    loadAthletesList(1000, 1, positions);

    setIsModalOpen(true);
  };
  const moveDown = (id, key) => {
    if (key < playerList.length - 1) {
      playerList[key + 1].rank = playerList[key].rank;
      playerList[key].rank = key + 2;

      swapItems(playerList, key, key + 1);
      setPlayerList(playerList);

      setValue((value) => value + 1);
      handleUpdateDepthChart(
        id,
        playerList[key].player_id,
        playerList[key + 1].player_id,
        playerList[key].rank,
        key + 2
      );
    }
  };
  const removePlayer = (depth_chart_id, id) => {
    try {
      handlePopMessage("loading", "Removing player from depth chart...");
      deleteDepthChartPlayer({
        params: { player_id: id, depth_chart_id: depth_chart_id, id: id },
        callback: (res, data) => {
          const _playerList = playerList?.filter((item) => {
            return item.player_id != id;
          });
          setPlayerList([]);
          setPlayerList(_playerList);
          handlePopMessage("success", "Success!");
        },
      });
    } catch (error) {
      console.error(error.message);
      handlePopMessage("error", error.message);
    }
  };

  const addToPlayerList = (player) => {
    const _playerList = playerList || [];

    _playerList.push(player);
    setPlayerList([]);
    setPlayerList(_playerList);
  };

  useEffect(() => {
    fetchDepthChartPlayers();
   
  }, []);

  // const handleDeleteDepthChart = (depth_chart_id) => {
  //   try {
  //     handlePopMessage("loading", "Deleting depth chart...");
  //     deleteDepthChart({
  //       params: { id:depth_chart_id },
  //       callback: (res, data) => {
  //         console.log("response from deleting depth chart is", res);
  //         console.log('recieved for delete depth chart',data)
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error.message);
  //     handlePopMessage("error", error.message);
  //   }
  // };
const items = [

  {
    key: "1",
    icon: <ShareAltOutlined />,
    label: "Manage Sharing",
    onClick: () => setIsManageSharingModalVisible(true),
  },  {
    key: "2",
    danger: true,
    icon: <DeleteOutlined />,
    label: "Delete",
    onClick: () => {
      handleDeleteDepthChart(parseInt(data.id));
    },
  },
];

  const checkPlayerInDepthChart = (playerId) => {
    // Assuming depthChartPlayers is an array containing player objects in the depth chart
    const isPlayerInDepthChart = playerList?.some(
      (player) => player.player_id === playerId
    );
    return isPlayerInDepthChart;
  };
   
     
  return (
    <div className="col-md-4 p-0">
      {contextHolder}
      <div className="m-1 depth-chart-main"> <ManageSharingModal
        visible={isManageSharingModalVisible}
        onClose={() => setIsManageSharingModalVisible(false)}
        coachesList={coachesList}
        onShareChart={handleShareChart}
        chartId={data.id}
        title={`${data.code} (${data.name})`}
      />
        <div className="header">
          <h4>{data.code} ({data.name}) <small>{coach}</small>  
          </h4>
          {!isShared ? <Dropdown menu={{ items }}>
            <a
              onClick={(e) => {
                e.preventDefault();
                 
              }}
            >
              <MoreOutlined />
            </a>
          </Dropdown> : ''}
        </div>
        <Card>
          <div className="helper">
            <p>
              Arrange the players by clicking{" "}
              <span>
                <ArrowUpOutlined />
                <ArrowDownOutlined />
              </span>{" "}
              icons
            </p>
          </div>
          <div className="depth-chart">
            {playerListLoading ? (
              <div className="col-md-4 px-2">
                <Skeleton active />
              </div>
            ) : (
              playerList
                ?.sort((a, b) => a.rank - b.rank)
                .map((item, key) => (
                  <DepthChart
                  isShared={isShared}
                    key={item.id}
                    data={item}
                    index={key}
                    id={item.id}
                    depth_chart_id={data.id}
                    pointer={key}
                    moveUp={moveUp}
                    moveDown={moveDown}
                    removePlayer={removePlayer}
                  />
                ))
            )}
          </div>
          {!isShared ?
            <Button
              className="add-player"
              type="link"
              onClick={() =>
                showModal(
                  data.id,
                  `${data.code} (${data.name})`,
                  playerList,
                  data.code
                )
              }
            >
              {" "}
              + Add player(s)
            </Button>
            : ''}
        </Card>
      </div>
      <Modal
        title={null}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
      >
        <div className="players-depth-chart">
          <h4>
            Add Players to the ({data.code} ({data.name}))
          </h4>
          <label>Depth Chart Name</label>
          <Select
            disabled={true}
            defaultValue={`${data.code} (${data.name})`}
            style={{ width: 120 }}
          />
          <hr />

          <>
            <label>Start typing player(s) name here:</label>

            <div className="border border-solid border-[#dedede] rounded-lg pb-[10px] overflow-y-scroll">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                bordered={false}
                value={playerList?.map(
                  (player) => `${player.first_name} ${player.last_name}`
                )}
                onDeselect={(deselectedValue) => {
                  const deselectedObject = playerList?.find(
                    (option) =>
                      `${option.first_name} ${option?.last_name}` ===
                      deselectedValue
                  );
                  if (deselectedObject) {
                    removePlayer(data.id, deselectedObject.player_id);
                  }
                }}
                // onChange={handlePlayerSelection}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                showSearch
                className="multi"
              >
                {allAthletesList?.map((player) => {
                  return (
                    <Option
                      key={player.id}
                      value={player.id}
                      label={`${player?.athlete?.first_name} ${player?.athlete?.last_name}`}
                    >
                      <div
                        onClick={() => {
                          // handleCancel();
                         
                          handleAddPlayerDepthChart(
                            player?.athlete?.user,
                            (playerList?.length || 0) + 1,
                            allowedPosition[data.code],
                            player?.positions,
                            player?.id
                          );
                        }}
                      >
                        {player?.athlete?.first_name}{" "}
                        {player?.athlete?.last_name} (
                        {player?.positions?.toString()})
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="players">
              <p>Suggestions for this type of chart</p>

              {athletesListLoading ? (
                <div className="px-2">
                  {" "}
                  <Skeleton active /> <Skeleton active />{" "}
                </div>
              ) : (
                filteredAthletesList?.map((player) => {
                  const {
                    id,
                    positions,
                    athlete: { first_name, last_name, user },
                  } = player;
                  return (
                    <div
                      key={id}
                      className="player"
                      onClick={() => {
                        // handleCancel();
                      
                        // addToPlayerList(player);
                        handleAddPlayerDepthChart(
                          user,
                          (playerList?.length || 0) + 1,
                          allowedPosition[data.code],
                          positions,
                          id
                        );
                      }}
                    >
                      <div className="avatar">
                        <Avatar style={{ backgroundColor: "red" }} size="small">
                          {first_name[0]?.toUpperCase()}
                        </Avatar>
                      </div>
                      <div className="name">
                        <p>
                          {first_name} {last_name}{" "}
                        </p>{" "}
                        <span className="text-[11px]">
                          {" "}
                          ({positions?.toString()})
                        </span>
                      </div>
                      <div className="control">
                        <div className="up">
                          <PlusOutlined />
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </>

          <Button onClick={handleCancel}>Save</Button>
        </div>
      </Modal>
    </div>
  );
};
function swapItems(list, index1, index2) {
  // Check if the indexes are valid
  if (
    index1 < 0 ||
    index1 >= list.length ||
    index2 < 0 ||
    index2 >= list.length
  ) {
    console.log("Invalid indexes");
  }

  [list[index1], list[index2]] = [list[index2], list[index1]];
}

const mapStateToProps = (state) => ({
  coachProfile: _.get(state, "app.account.profile.data.details"),
  gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
});

const mapDispatchToProps = (dispatch) => ({
   
  getDepthChartPlayers: (details) =>
    dispatch(global.redux.action.depth_chart.players(details)),
  addPlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.addPlayer(details)),
  deleteDepthChartPlayer: (details) =>
    dispatch(global.redux.action.depth_chart.deletePlayer(details)),
  deleteDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.delete(details)),
  editPlayer: (details) => dispatch(global.redux.action.athletes.edit(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDepthChartMain);
