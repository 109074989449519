import React from "react";
import questionMark from "../../assets/img/question-mark-circle.svg";
import FormationTableHead from "./FormationTableHead";
import FormationTableData from "./FormationTableData";

function FormationPlayTable({
  formation,
  side,
  which,
  path,
  data,
  portion,
  datax,
  total,
}) {
  //  const yards = data?.play?.filter((item) => {
  //   if (item.formation == formation) {
  //     count = count + parseInt(item.count_play)
  //     return true;
  //   } return false
  // })
  const count = data?.reduce((total, num) => {
    return total + parseInt(num.count);
  }, 0);
  const runCount = portion?.filter((item) => item.play_type == "run")[0]?.count;
  const passCount = portion?.filter((item) => item.play_type == "pass")[0]
    ?.count;
  return (
    <div className="w-full">
      <div className="flex items-center justify-between text-[#FFFFFF] bg-[#0F1A24] rounded-t-[8px] px-[12px] py-[8px] w-full">
        <div className="flex items-center text-[14px] font-bold">
          {/* <span>{which}</span> */}
        </div>
      </div>
      {/* table div */}
      <div className="w-full">
        {/* table head */}
        {/* <FormationTableHead /> */}

        <div className="w-full px-[12px] py-[8px] bg-[#2E4D6B] text-[#ffffff] font-bold text-[12px]">
          <span>
            Pass ({passCount ? parseInt((passCount / total) * 100) : 0}%){" "}
            {passCount} of {total} {formation}
          </span>
        </div>
        <FormationTableHead />
        {data?.map((item, index) => {
          if (item.play_type.toLowerCase() == "pass") {
            const y = datax?.filter(
              (itemx) => itemx.play_type == "pass" && itemx[path] == item[path]
            )[0];
            return (
              <>
                <FormationTableData
                  key={index}
                  col1={item[path]}
                  col2b={passCount}
                  col2a={item.count}
                  col3={item.total_yards}
                  col4={item.total_yards / item.count}
                  col5={y?.executed || "--"}
                  col6={y?.effort || "--"}
                />
              </>
            );
          }
          return null;
        })}

        <div className="w-full px-[12px] py-[8px] bg-[#2E4D6B] text-[#ffffff] font-bold text-[12px]">
          <span>
            Run ({runCount ? parseInt((runCount / total) * 100) : 0}%){" "}
            {runCount} of {total} {formation}
          </span>
        </div>
        <FormationTableHead />
        {data?.map((item, index) => {
          if (item.play_type.toLowerCase() == "run") {
            const x = datax?.filter(
              (itemx) => itemx.play_type == "run" && itemx[path] == item[path]
            )[0];
            return (
              <FormationTableData
                key={index}
                col1={item[path]}
                col2a={item.count}
                col2b={runCount}
                col3={item.total_yards}
                col4={item.total_yards / item.count}
                col5={x?.executed || "--"}
                col6={x?.effort || "--"}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default FormationPlayTable;
