import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Input, message, Button } from "antd";
import "./register.css";
import "../login/login.css";
import logo from "../../assets/img/logo.png";
import line from "../../assets/img/Line.png";
import _ from "lodash";
import { connect } from "react-redux";
import LoginModal from "../login/LoginModal";
import { useNavigate } from "react-router-dom";
import FewQuestions from "./few-questions";
import registerImage from "../../assets/img/register-image.svg";
import PersonalDetails from "./personal-details";
import SchoolDetails from "./school-details";
import backArrow from "../../assets/img/arrow-leftblue.svg";
import continueArrow from "../../assets/img/arrow-right.svg";
import PlayersDetails from "./players-details";
import SolutionsSeeked from "./solutions-seeked";
import HowDoYouHearAboutUs from "./how-do-you-hear-about-us";
import { LoadingOutlined } from "@ant-design/icons";

const RegisterPageComponent = (props) => {
  let history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [invalidPswd, setInvalidPswd] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const [page, setPage] = useState(() => {
    const storedPage = sessionStorage.getItem("page");
    return storedPage ? Number(storedPage) : 1;
  });
  const [regDetails, setRegDetails] = useState(() => {
    const storedRegDetails = sessionStorage.getItem("regDetails");
    return storedRegDetails
      ? JSON.parse(storedRegDetails)
      : {
          first_name: "",
          last_name: "",
          school: "",
          email: "",
          password: "",
          roles: "",
          state: "",
          num_coaches: "",
          school_district: "",
          roaster_size: 20,
        };
  });

  useEffect(() => {
    const params = location?.search;

    if (params.includes("via")) {
      console.log("location", params.split("=")[1]);
      localStorage.setItem("rewardful", params.split("=")[1]);
    }
  }, []);

  useEffect(() => {
    const storedRegDetails = sessionStorage.getItem("regDetails");
    if (storedRegDetails) {
      setRegDetails(JSON.parse(storedRegDetails));
    }
  }, [page]);

  useEffect(() => {
    sessionStorage.setItem("page", page.toString());
    const storedPage = sessionStorage.getItem("page");
    storedPage && setPage(Number(storedPage));
  }, [page]);

  const error = (content) => {
    messageApi.open({
      type: "error",
      content,
    });
  };
  const lowercaseFirstLetter = (str) => {
    // converting first letter to lowerCase
    const lowerCase = str.toLowerCase();

    return lowerCase;
  };
  const handleRegister = () => {
    setLoading(true);
    const referred_by = localStorage.getItem("rewardful");

    props.register({
      body: { ...regDetails, referred_by },
      callback: (response, data) => {
        if (response === true) {
          console.log(regDetails);
          console.log("response, data", response, data);
          message.success(data.data?.message);
          sessionStorage.removeItem("regDetails");
          sessionStorage.removeItem("page");
          sessionStorage.setItem("registered-email", data?.data?.school?.email);
          history("/continue-registration");
        } else {
          console.log(regDetails);
          console.log("response, data", response, data);
          message.error(data?.error);
        }
        setLoading(false);
      },
    });
  };

  const render = () => {
    return (
      <div className="h-screen grid grid-cols-6 xl:grid-cols-5">
        <Form
          name="registeration-form"
          // autoComplete="off"
          // autoCorrect="off"
          form={form}
          className={`col-span-6 relative justify-between h-screen lg:col-span-3 xl:col-span-3 p-4 flex flex-col items-start overflow-y-scroll`}
          onFinish={() => {
            if (!backButtonClicked) {
              if (page === 3) {
                handleRegister();
              } else {
                setPage(page + 1);
              }
            }
          }}
        >
          <img src={logo} className="w-[150px]" />
          <div className="mt-8 md:mt-16 xl:mt-[5vw] w-[100%] md:px-[10%] mx-auto">
            {page == 1 && (
              <FewQuestions
                regDetails={regDetails}
                setRegDetails={setRegDetails}
              />
            )}
            {page == 2 && (
              <PersonalDetails
                regDetails={regDetails}
                setRegDetails={setRegDetails}
                page={page}
                setPage={setPage}
                form={form}
              />
            )}
            {page == 3 && (
              <SchoolDetails
                regDetails={regDetails}
                setRegDetails={setRegDetails}
                page={page}
                setPage={setPage}
                form={form}
              />
            )}
            {page == 4 && (
              <PlayersDetails
                regDetails={regDetails}
                setRegDetails={setRegDetails}
                page={page}
                setPage={setPage}
              />
            )}
            {page == 5 && <SolutionsSeeked page={page} setPage={setPage} />}
            {page == 6 && <HowDoYouHearAboutUs />}
          </div>
          <Form.Item className={` w-[100%] self-end`}>
            <div
              className={`flex items-center lg:w-[50%] xl:w-full pb-[1%] xl:pb-[2vw] ${
                page !== 1 && "px-[8%] justify-between"
              }`}
            >
              {page > 1 && (
                <button
                  className="px-6 py-2 flex items-center justify-center gap-2 text-[#1A91FF] text-[14px] bg-white disabled:opacity-50"
                  onClick={() => {
                    setBackButtonClicked(true);
                    setPage((prevPage) => prevPage - 1);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "2px solid #1A91FF",
                  }}
                >
                  <img src={backArrow} />
                  <span>Back</span>
                </button>
              )}
              <button
                className={`px-6 py-2 ${
                  page === 2
                } flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px]  text-white ${
                  page === 1 ? "mx-auto" : "md:ml-[10%] xl:ml-auto"
                }`}
                onClick={() => {
                  setBackButtonClicked(false);
                }}
                disabled={page == 1 && regDetails.roles === ""}
                type="submit"
              >
                {loading && <LoadingOutlined />}
                <span>Continue</span>
                <img src={continueArrow} />
              </button>
            </div>
          </Form.Item>
        </Form>
        <div className="hidden lg:flex lg:col-span-3 xl:col-span-2 h-full register-container overflow-hidden"></div>
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(global.redux.action.account.login(details)),
  register: (details) =>
    dispatch(global.redux.action.account.register(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPageComponent);

{
  /* <Form.Item name='remember'>
                  <p className='afterText'>
                    <Link
                      to='/forgot-password'
                      className='loginFormForgot'
                      href=''>
                      Forgot your password?
                    </Link>
                  </p>
                </Form.Item> */
}
{
  /* <Form.Item name="remember">
                  <Link to="/password-verification" className="loginFormForgot" href="">
                    Reset password?
                    </Link>
                </Form.Item> */
}
{
  /* GRADIENT COLORS  */
}
{
  /* END GRADIENT COLORS  */
}
{
  /* <div className='register-container'>
        {contextHolder}
        <div className='rectangle1'></div>
        <div className='rectangle2'></div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-5 col-lg-5 pl-0 '>
              <div className='LoginpageContainer'>
                <div className='LoginLogoContainer'>
                  <img src={logo} className='PageMainLogo' alt='' />
                  <span className='LoginPageLoginText'>
                    Login to your account
                  </span>
                </div>
                <Form
                  onFinish={(e) => onRegister(e)}
                  className='LoginForm'
                  name='basic'
                  autoComplete='off'
                  layout='vertical'>
                  <Form.Item
                    label='Email address'
                    name='email'
                    autoComplete='email'
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email address!",
                      },
                    ]}>
                    <Input
                      autoComplete='email'
                      placeholder='eg: name@example.com'
                    />
                  </Form.Item>
                                   
                  <Form.Item
                    label='last Name'
                    name='last_name' 
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name ",
                      },
                    ]}>
                    <Input
                      autoComplete='last_name' 
                    />
                  </Form.Item>
                                    <Form.Item
                    label='First Name'
                    name='first_name' 
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name ",
                      },
                    ]}>
                    <Input
                      autoComplete='first_name' 
                    />
                  </Form.Item>
                                    
                  <Form.Item
                    label='School Name'
                    name='school' 
                    rules={[
                      {
                        required: true,
                        message: "Please input your school  name ",
                      },
                    ]}>
                    <Input
                      autoComplete='school' 
                    />
                  </Form.Item>

                  <Form.Item
                    autoComplete='new-password'
                    label='Password'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}>
                    <Input.Password
                      autoComplete='new-password'
                      placeholder='Enter your password'
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      type='primary'
                      htmlType='submit'>
                      Sign up now
                    </Button>
                  </Form.Item>

                  <div className='flex'></div>
                  <p className='afterText bg-black'>
                    Already have an account?
                    <Link to='/login' className='loginFormForgot ' href=''>
                      <b> Login to continue</b>
                      <img src={line} className='scribble' alt='' />
                    </Link>
                  </p>
                </Form>
              </div>
            </div>
          </div>
          {invalidPswd && (
            <LoginModal
              onOpen={invalidPswd}
              title='Ops! your email address or password is not correct'
              onCancel={() => setInvalidPswd(false)}
            />
          )}
          {invalidEmail && (
            <LoginModal
              onOpen={invalidEmail}
              title='The email address you entered is invalid.'
              onCancel={() => setInvalidEmail(false)}
            />
          )}
        </div>
      </div> */
}
