import React,{useEffect} from 'react'
import logo from "../assets/img/Logologin.svg";
import { useNavigate } from "react-router-dom";

function Landing() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/coaches-evaluation')
    },[])
  return (
    <div className="w-screen h-screen flex items-center justify-cebter">
      <img src={logo} alt="landing" />
    </div>
  )
}

export default Landing
