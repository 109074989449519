import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const SubscriptionBarChart = ({ data }) => {
  const formattedData = data.reduce((acc, item) => {
    const year = new Date(item.year).toISOString().split("-")[0];
    const existingYear = acc.find((entry) => entry.Year === year);

    if (existingYear) {
      existingYear[`Plan${item.plan}`] = item.total_subscriptions;
    } else {
      acc.push({
        Year: year,
        [`Plan${item.plan}`]: item.total_subscriptions,
      });
    }
    return acc;
  }, []);
  const planKeys =
    formattedData.length > 0
      ? Object.keys(formattedData[0]).filter((key) => key.includes("Plan"))
      : [];
const plans = {
  'Plan1': 'Starter',
  'Plan2': 'Engagement',
  'Plan3': 'Advantage'
}
  return (
    <ResponsiveContainer  width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend />
        <XAxis
          dataKey="Year"
          label={{
            value: "Year",
            position: "insideBottom",
            offset: 0,
          }}
        />
        <YAxis
          label={{
            value: "Total Subscriptions",
            angle: -90,
            position: "left",
            offset: -5,
          }}
        />
        <Tooltip />
 
        {planKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            fill={index === 0 ? "#A40E17" : index === 1 ? "#1A91FF" : "#00B21E"}
            name={`${plans[key]} Plan`}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SubscriptionBarChart;
