import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Form, Input, Button, Checkbox, AutoComplete, Select, notification, Spin } from 'antd';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { geocodeByAddress } from 'react-places-autocomplete';
import _ from 'lodash';
import "./coaches.css";
const axios = require("axios");
const { Option } = Select;

const options = [
    { value: 'Burns Bay Road' },
    { value: 'Downing Street' },
    { value: 'Wall Street' },
];

const CreateCoachComponent = (props) => {
    let history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [address_results, setaddress_results] = useState([])
    const [address_fetching, setaddress_fetching] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false);
    const [positionsList, setPositionsList] = useState([])

    useEffect(() => {
        getGridironDetails()
    }, []);

 
    const lowercaseFirstLetter = (str) =>{

		// converting first letter to lowerCase
		const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());
	
		return lowerCase;
	}
    const onFinish = e => {
        
        const params = new URLSearchParams(window.location.search);

        // let address_parse = JSON.parse(_.get(e, 'location', {}))
        let details = {
            type:_.get(props,'coachProfile.type'),
            school:_.get(props,'coachProfile.school.id'),
            email:lowercaseFirstLetter(_.get(e, 'email')),
            first_name: _.get(e, 'first_name'),
            last_name: _.get(e, 'last_name'),
            password: _.get(e, 'password'),
            positions: _.join(_.get(e, 'positions'),','),
            // location: `${_.get(address_parse, 'lat')}, ${_.get(address_parse, 'lng')}`,
            // address:_.get(address_parse,'text')
        } 
        setButtonLoading(true)
        props.coachCreate({
            body: details,
            callback: (res, responseData) => {
                setButtonLoading(false)
                if (res == true) {
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    )
                    history('/coach-list')
                }
            }
        });
    };
    //Address search
    const fetchAddress = value => {
        setaddress_results([])
        setaddress_fetching(true);
        geocodeByAddress(value).then(body => {
            const address_results = _.map(body, location => ({
                text: location.formatted_address,
                value: JSON.stringify({ text: location.formatted_address, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
            }));

            setaddress_results(address_results)
            setaddress_fetching(false);

        }).catch(error => {
            setaddress_results([])
            setaddress_fetching(true);
        });
    };
    //Initializing debounce for address
    const addressDelayedQuery = _.debounce(q => fetchAddress(q), 1200);

    const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        return postal_code;
    };

    const getGridironDetails = () => {
		props.getGridironDetails({
			callback: (res, data) => {
				if (res === true) {
					let final_data =[] 
					
					setPositionsList(_.chain(data) .get('data.data.positions') .pick(['offense', 'defense', 'special_teams']) .values().flatten() .map('code') .value())
                }
            }
		})
	};

    return (
        <div className="CreateCoachForm">
            <div className="container">
                <div className="PlayerprimaryCard">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        layout="vertical"
                        className="row"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            className="col-12 col-md-6"
                            label="First name"
                            name="first_name"
                            rules={[{ required: true, message: 'Please input your First name!' }]}

                        >
                            <Input style={{width: 250}}/>
                        </Form.Item>
                        <Form.Item
                            className="col-12 col-md-6"
                            label="Last name"
                            name="last_name"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input style={{width: 250}}/>
                        </Form.Item>
                        <Form.Item
                            className="col-12 col-md-6"
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input style={{width: 250}} />
                        </Form.Item>
                        <Form.Item
                            className="col-12 col-md-6"
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}

                        >
                            <Input style={{width: 250}}/>
                        </Form.Item>
                        {/* <Form.Item
                            className="col-12 col-md-6"
                            label="Location"
                            name="location"
                            rules={[{ required: true, message: 'Please input your location!' }]}
                        >
                            <Select getPopupContainer={trigger => trigger.parentNode}
                                showSearch
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                onSearch={search => search ? addressDelayedQuery(search) : ''}
                                //   onChange={(e) => onChangeForm(e, 'address')}
                                placeholder={'Search address...'}
                                notFoundContent={
                                    address_fetching ? (
                                        <Spin size="small" />
                                    ) : null
                                }
                            >
                                {address_results && address_results.length > 0 && address_results.map(d => (
                                    <Select.Option key={d.value}>{d.text}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item> */}
                        <Form.Item
                            className="col-12 col-md-6"
                            label="Positions"
                            name="positions"
                            rules={[{ required: true, message: 'Please input your position!' }]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{width: 250}}
                            >
                               {_.map(positionsList,(value,index)=>{
									return <Option value={value} key={value}>
									    {value=="WR"?"WR/TE":value}
									</Option>
									}
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item className="col-12 col-md-12 text-right">
                            <Button loading={buttonLoading} style={{marginRight:"21px"}} type="primary" htmlType="submit">
                                Create Coach
                            </Button>
                            <Button disabled={buttonLoading} className="ant-btn " type="ghost" onClick={()=>{history('/coach-list')}}>
									Cancel
								</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return{
        coachProfile: _.get(state, 'app.account.profile.data.details'),
    }
};

const mapDispatchToProps = dispatch => ({
    coachCreate: details => dispatch(global.redux.action.coaches.create(details)),
    schoolsList: details => dispatch(global.redux.action.schools.list(details)),
    getGridironDetails: details => dispatch(global.redux.action.grades.gridironDetails(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoachComponent);