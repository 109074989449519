import React from "react";
import check from "../../assets/img/checkwhite.svg";
import { Form } from "antd";

function FewQuestions({ regDetails, setRegDetails }) {
  const roles = [
    {
      id: 1,
      name: "Head Coach",
    },
    {
      id: 2,
      name: "Offensive Coordinator",
    },
    {
      id: 3,
      name: "Defensive Coordinator",
    },
    {
      id: 4,
      name: "Special Team Coordinator",
    },
    {
      id: 5,
      name: "Athletic Director",
    },
    {
      id: 6,
      name: "Video Coordinator",
    },
  ];
  const handleSelectRole = (role) => {
    setRegDetails((prev) => {
      const updatedDetails = {
        ...prev,
        roles: prev.roles === role ? "" : role,
      };
      sessionStorage.setItem("regDetails", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  return (
    <div className="w-[100%]">

       <p className="text-[#2E4D6B] text-lg xl:text-[22px] 2xl:text-[1.5vw] text-bold font-normal">
        Start Your 30-day Premium Trial. No Credit Card Required.
      </p>

      
      <p className="text-[#2E4D6B] text-lg xl:text-[18px] 2xl:text-[1.25vw] font-normal">
        Let's start with a few questions to better understand your coaching
        needs.
      </p>
      <p className="text-[#0F1A24] text-lg xl:text-[18px] 2xl:text-[1.25vw] mt-3 2xl:mt-[0.83vw] font-bold">
        I am a :
      </p>
      <div className="flex flex-wrap md:gap-4  gap-3 md:mb-20">
        {roles?.map((role) => {
          const storedRegDetails = sessionStorage.getItem("regDetails");
          const parsedRegDetails = regDetails
            ? JSON.parse(storedRegDetails)
            : {};
          const selectedRole = parsedRegDetails?.roles;
          return (
            <div
              className="px-4 md:px-8 2xl:px-[2.22vw] flex items-center py-3 2xl:py-[0.83vw] bg-[#EAEDF0] text-sm md:text-lg xl:text-[18px]  2xl:text-[1.25vw] font-bold   rounded-full"
              key={role.id}
              style={{
                cursor: "pointer",
                backgroundColor:
                  regDetails?.roles === role.name || selectedRole === role.name
                    ? "#A40E17"
                    : "#EAEDF0",
                color:
                  regDetails?.roles === role.name || selectedRole === role.name
                    ? "#fff"
                    : "#8698A9",
              }}
              onClick={() => handleSelectRole(role.name)}
            >
              {regDetails?.roles == role.name || selectedRole === role.name ? (
                <img src={check} alt="" className="mr-2" />
              ) : (
                ""
              )}
              {role.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FewQuestions;
