import React from "react";
import { Modal } from "antd";
import printer from "../../../assets/img/Rectangle.svg";

function PrintModal({ onOpen, onClose }) {
  return (
    <Modal
      open={onOpen}
      onCancel={onClose}
      closable={true}
      width={270}
      title={
        <span className='text-[14px] font-inter font-bold text-center'>
          Print Coaches Notes{" "}
        </span>
      }
      footer={null}>
      <div className='flex flex-col mt-[34.5px] text-center font-inter'>
        <img src={printer} className='w-[36px] self-center' />
        <span className='text-[14px] text-[#0F1A24] font-normal w-[70%] text-center self-center mt-[14px]'>
          Coaches Notes Printed Successfully
        </span>
        <div
          className='self-center h-[36px] flex items-center justify-center border-[1px] border-solid border-[#0F1A24] cursor-pointer mt-[24px] text-[14px] text-[#0F1A24] w-[40%] rounded-[4px]'
          onClick={onClose}>
          <span> Close</span>
        </div>
      </div>
    </Modal>
  );
}

export default PrintModal;
