import React from "react";
import PieChartComponent from "../charts/PieChartTwo";

function PieChartCel({ data, width, title }) { 
  return (
    <div
      className='w-full font-inter'
      >
      <div className='flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full'>
        <span>{title}</span>
      </div>
      {/* pie chart */}
      <div className='h-full w-full text-center font-poppins flex flex-col items-center font-bold text-[#526B84] text-[12px] rounded-b-[8px]'>
        {/* Flag Pie */}
        <PieChartComponent data={data} width={width} />
      </div>
    </div>
  );
}

export default PieChartCel;
