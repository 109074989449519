import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import {
    Table,
    Button,
    Select,
    Pagination,
    InputNumber,
    notification,
    Modal,
    Form,
    Input
} from "antd";
import { connect } from "react-redux";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const CoachesUpdatePhoneComponent = (props) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            phone: props.phonedata
        });
    }, [props.phonedata]);

    const handleSubmit = () => {
        const params = new URLSearchParams(window.location.search);
        let coaches_id = params.get('id');
        let details = {
            id: coaches_id,
            phone: form.getFieldValue('phone'),
        } 

        props.UpdatePhoneData({
            body: details,
            callback: (res, responseData) => {
                if (res == true) {
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    );
                    props.setIsModalVisible(false);
                } 
            }
        });
    };

    const render = () => {
        return (
            <div className="CoachesViewModalContainer">
                <Modal className="CoachesViewModal" visible={props.isModalVisible}
                    onOk={() => {
                        handleSubmit();
                    }}
                    onCancel={() => {
                        form.setFieldsValue({
                            phone: props.phonedata
                        });
                        props.setIsModalVisible(false);
                    }}
                    okText='Update'
                >
                    <Form
                        form={form}>
                        <Form.Item
                            className="col-12 col-md-6"
                            label="Update Phone"
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
    return render();
}

const mapDispatchToProps = dispatch => ({
    UpdatePhoneData: details => dispatch(global.redux.action.coachess.updatephone(details))
});

export default connect(null, mapDispatchToProps)(CoachesUpdatePhoneComponent);