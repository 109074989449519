import React, { Component, useState } from 'react';
import { Form, Input, Button, Checkbox, notification,Modal } from 'antd'
import { SafetyCertificateOutlined, DollarOutlined, EnvironmentOutlined, PhoneOutlined, GlobalOutlined, CheckCircleTwoTone, RightOutlined, LockFilled } from '@ant-design/icons';
import { BrowserRouter as Router, Switch, Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import _ from 'lodash';

import "./login.css";
import loginBG from "../../assets/img/login-bg.png";
import logo from "../../assets/img/logowhite.svg";

const FormItem = Form.Item
const ResetPasswordComponent = (props) => {

	let history = useNavigate();
	const [form] = Form.useForm();
	const [confirmDirty, setConfirmDirty] = useState(false);

	const onFinish = (credentials, signInType)=> {
		const query = new URLSearchParams(window.location.search);
		const urlParams =  new URLSearchParams(window.location.search);
		const token = urlParams.get("token");
		const email = query.get("email");

		console.log('credentials---', credentials)
		if(credentials.password === credentials.confirm){
				let requestParams = {};
				requestParams.email =email;
				requestParams.token =token;
				requestParams.password = credentials.password

				
			props.resetPassword({
				body: requestParams,
				callback: (res, responseData) => {
					if (res == true) {
						notification.success(
							{ message: 'Success', description: _.get(responseData, 'message') }
						);
							history( "/login") 
					}else {
						notification.success(
							{ message: 'Error', description: 'Sorry an error occurred, please try again' }
						);
					}
				
				}
			});
		}
	};

  const render = () => {
    let url = window.location.search;
		let params = _.get(url);
		return (
			<div className="LoginContainer">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-sm-4 col-lg-4 pr-0">
							<div className="LoginBG" style={{ backgroundImage: "url("+loginBG+")" }}></div>
						</div>
						<div className="col-12 col-sm-5 col-lg-5 pl-0">
							<div className="LoginpageContainer">
								<div className="LoginLogoContainer">
									<img src={logo} className="PageMainLogo" />
									<span className="LoginPageLoginText">Change your password</span>
									<span className="forgotPasswordInstruction !text-[#fff]">
										Enter your new password below to <br/>
										reset your password
									</span>
								</div>
								<Form
									className="LoginForm"
									name="basic"
									initialValues={{ remember: true }}
									autoComplete="off"
									layout="vertical"
									onFinish={onFinish}
									form={form}
							    >
									<Form.Item
										label="New Password"
										name="password" rules={[{
										required: true, message: 
											"authentication required password validation message"
											}]}
										hasFeedback
									>
										<Input
											size="large"
											prefix={<LockFilled />}
											type='password'
											placeholder="Password"
										/>
									</Form.Item>
								  	<FormItem
                                        label="Confirm New Password"  
                                        name="confirm" 
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[{
                                            required: true, message:
                                                "authentication required password validation message"
                                            
                                            },
                                            ({ getFieldValue }) => ({
                                            	validator(rule, value) {
													if (!value || getFieldValue('password') === value) {
													return Promise.resolve();
													}
													return Promise.reject('The two passwords that you entered do not match!');
                                            	},
                                            })
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            prefix={<LockFilled />}
                                            type='password'
                                            placeholder="Confirm Password"
                                        />
									</FormItem>
									<Form.Item>
										<Button type="primary" htmlType="submit"  >
											Create new password
										</Button>
							      	</Form.Item>
							    </Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
  return render();
};

const mapStateToProps = state => {
  return {
    loading: state,
    changePasswordResponse: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: details => dispatch(global.redux.action.account.resetPassword(details))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ResetPasswordComponent);