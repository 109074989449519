import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { Button } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { Table, Card, Row, Col, Select, Modal, Input } from "antd";
import _, { filter, isEmpty } from "lodash";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import check from "../../../assets/img/images2.png";
import atmCard from "../../../assets/img/ATMCARD.png";
import arrowRight from "../../../assets/img/arrow-right.svg";


const { Option } = Select;
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => { 
    if (props.coachProfile) { 
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (_.get(props, "schoolDetails")) {
      fetchSchoolViewData(props.coachProfile.school.id);
    }
  }, [props.coachProfile]);

  const fetchSchoolViewData = (id) => {
    props.getSchoolViewData({
      params: { id: id },
      callback: (res, responseData) => {
        if (res === true) {
        }
      },
    });
  };
  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        console.log("getProfile", data);
      },
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    // 'https://api.wardbord.com/stripe/create-intent'
    // 'http://localhost:1337/stripe/create-intent'

    console.log("props.coachProfile", props.coachProfile);

    if (props.coachProfile?.email) {
      const res = await axios.post(
        "https://api.wardbord.com/stripe/create-intent",
        {
          metadata: {
            coach_id: props.coachProfile.id,
            school_id: props.coachProfile.school.id,
            school_name: props.coachProfile.school.name,
            email: props.coachProfile.email,
            first_name: props.coachProfile.first_name,
            last_name: props.coachProfile.last_name,
          },
          receipt_email: props.coachProfile?.email,
          amount: props?.amount,
        }
      );

      if (res.status == 200) { 
        const { client_secret: clientSecret } = res.data;

        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: "https://example.com/order/123/complete",
          },
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          setErrorMessage(error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      }
    }
  };

  return (
    <div
      
    >
      <h4>Please insert your payment information</h4>
      <p>Payment will be made securely and encrypted via stripe</p>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          className="btn btn-primary mt-3 px-4 h-[auto] py-2"
          type="submit"
          disabled={!stripe || !elements}
        >
          Pay
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};

const stripePromise = loadStripe(
  "pk_live_51K1ukJJlrM6wBFlOye8rJG7ObZDcD6KJhBturlqKoVsbV8tc5t4opIrwbW2h8vRdXYJvJAoBmcrqW40V2j5SeHHq00r2qDWfXu"
);

const SubscriptionPage = ({ plansList, sendInvoice, coachProfile, subscriptionHistory }) => {
  const [showPlans, setShowPlans] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedTeamSize, setSelectedTeamSize] = useState(null);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showStripeModal, setShowStripeModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedSubscription, setSubscription] = useState(null);
  const [email, setEmail] = useState("");
 

  const handleClick = () => {
    setIsSelected(!isSelected);
  };

  const startSubscription = () => {
    // Add any logic here for transitioning between sections
    // For simplicity, I'm setting the selected plan and showing payment options directly
    setSelectedPlan(null);
    setShowPaymentModal(true);
  };

  const handleTeamSizeSelect = (size) => {
    setSelectedTeamSize(size);
  };

  const handleSubscriptionSelect = (subscription) => {
    if (selectedSubscription?.name === subscription?.name) {
      setSubscription(null);
    } else {
      setSubscription(subscription);
      setShowPaymentOptions(true)
    }
  };

  const handlePaymentOptionSelect = (option) => {
    setPaymentOption(option);
    if (option === "sendCheck") {
      setShowPaymentModal(true);
    }else if (option === "stripe") {
      setShowStripeModal(true);
    }
  };

 

  const handleSendInvoice = () => {
    console.log('selectedSubscription',selectedSubscription)
    sendInvoice({
      body: {
        school: coachProfile?.school?.id,
        plan: selectedSubscription?.id,
        amount: parseInt(selectedSubscription?.price),
        email: email,
        others: {
        currency: 'usd',
        payment_terms: 'Billed Via Purchase Order',
        items: [{name: `Subscription to ${selectedSubscription?.name}`, quantity: 1, unit_cost: selectedSubscription?.price}],
        notes: 'Thanks for being an awesome customer!',
        terms: 'You will be billed for this invoice once a year.'
    }
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("data from send invoice", data?.data);
          // setPlansList(data?.data || []);
        }
        // setPlansListLoading(false);
      },
    });
    // Implement logic to send the invoice
    // setShowPaymentModal(false);
  };

  const getSubscriptionHistory = () => {
    subscriptionHistory({
      params: {id: coachProfile?.school?.id},
      callback: (res, data) => {
        if (res === true) {
          console.log("data from getSubscriptionHistory", data?.data);
        }
      },
    });
  };

  useEffect(() => {
    getSubscriptionHistory();
  }, []);

  const columns = [
    {
      title: "Subscription ID",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
    },
    { title: "Plan", dataIndex: "plan", key: "plan" },
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
  ];

  const data = [
    {
      subscriptionId: 897,
      plan: "Premium",
      startDate: "2023-01-01",
      endDate: "2023-12-31",
    },
    {
      subscriptionId: 198,
      plan: "Free",
      startDate: "2023-01-01",
      endDate: "2023-12-31",
    },
  ];

  const teamSizes = [
   20,
   25,
   35,
   45,
   55,
   65,
  ];

  const planPriceList = {
    1: {
      20: 290,
      25: 370,
      35: 520,
      45: 670,
      55: 820,
      65: 970
    },
    0: {
      20: 547,
      25: 687,
      35: 957,
      45: 1237,
      55: 1507,
      65: 1787
    }
  }
 const options = {
    mode: "payment",
    currency: "usd",
    amount: 1000,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  
  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-inter font-bold">
        Create New Subscription
      </h2>
      <span className="text-sm font-inter mb-2">
        Add your card details to continue or start enjoying premium features.
      </span>
      <Button
        type="primary"
        onClick={() => setShowPlans(!showPlans)}
        className="w-[40%] lg:w-[30%] xl:w-[20%] mb-2"
      >
        Start Subscription
      </Button>
      <Card bodyStyle={{ padding: "20px" }}>
        {/* Section One */}

        {/* Transition Section */}
        {showPlans && (
          <div style={{ borderBottom: "1px solid #EAEDF0" }}>
            {/* Section with Plans */}
            <span className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-inter  font-bold">
              Plans
            </span>
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 w-full mt-2">
              {teamSizes.map((size, index, array) => (
                <div
                  onClick={() => handleTeamSizeSelect(size)}
                  style={{
                    marginBottom: "30px",
                    border:
                      selectedPlan === size
                        ? "2px solid #1790FF"
                        : "2px solid #EAEDF0",
                    backgroundColor:
                      selectedPlan === size ? "#87CEEB30" : "#ffffff",
                  }}
                  className=" col-span-1 px-2 py-2 text-sm h-[100px] flex text-center flex-col items-center justify-center rounded-md cursor-pointer font-inter relative"
                >
                  {selectedPlan === size && (
                    <img
                      src={check}
                      className="w-[20px] absolute -right-[5%] -top-[11%]"
                    />
                  )}
                  <div className="flex flex-col ">
                    <span className="font-semibold text-[#2E4D6B] text-[14px]">
                      Number Of Players
                    </span>
                    <span className="mt-1 text-[#0F1A24] text-[12px]">
                      {" "}
                      {index === array.length - 1
                        ? ` ${size}+ Players`
                        : `${size} Players`}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            {plansList.length ? '' : <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> }
            {selectedTeamSize ?
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 w-full mb-4">
                {/* Example Plans */}
                {plansList?.map((plan, key) => {
                  const pricelist = planPriceList[key] || [];

                  
                  return (
                    <div
                      key={key}
                      className={`col-span-full lg:col-span-2 relative rounded-md cursor-pointer p-4 text-[#0F1A24] ${selectedSubscription?.name === plan.name
                        ? "bg-[#e8f6fb]"
                        : "bg-[#6a77f0] text-[#ffffff]"
                        }`}
                      style={{
                        border:
                          selectedSubscription?.name === plan.name
                            ? "2px solid #039ae5"
                            : "2px solid #6a77f0",
                      }}
                      onClick={() => handleSubscriptionSelect({...plan, price: pricelist[selectedTeamSize]})}
                    >
                      <div className=" ">
                        {selectedSubscription?.name === plan.name && (
                          <img
                            src={check}
                            className="w-[24px] xl:w-[1.3vw] absolute -right-[3%] -top-[5%]"
                          />
                        )}
                        <div className="flex items-center justify-between">
                          <h2 className="text-base font-bold">
                            {plan.name
                              ? plan.name.charAt(0).toUpperCase() +
                              plan.name.slice(1)
                              : ""}
                          </h2>
                          <h2 className="text-base font-bold">
                            ${pricelist[selectedTeamSize] || 'Free' }
                            <small className="font-normal"> /season</small>
                          </h2>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <span className="text-xs font-semibold">
                            {plan?.description?.charAt(0).toUpperCase() +
                              plan.description.slice(1)}
                          </span>
                        </div>
                      </div>
                      <button
                        // disabled={selectedSubscription?.name === plan.name}
                        className={` mt-4 ${selectedSubscription?.name === plan.name
                          ? "text-[#039ae5]"
                          : " text-[#6a77f0]"
                          } bg-white font-bold py-2 px-4 rounded-md`}
                        onClick={() =>   handleSubscriptionSelect({...plan, price: pricelist[selectedTeamSize]})}
                        style={{
                          border:
                            selectedSubscription?.name === plan.name
                              ? "2px solid #039ae5"
                              : "2px solid #6a77f0",
                        }}
                      >
                        {selectedSubscription?.name === plan.name
                          ? "Plan Selected"
                          : "Select Plan"}
                      </button>
                      {/* <div className="px-4 pt-4 pb-2">
                    <button
                    onClick={handleClick}
                    className={`w-full py-2 text-white font-semibold ${
                      isSelected ? 'bg-blue-500' : 'bg-gray-500'
                    } hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800`}
                  >
                    {isSelected ? 'Selected' : 'Select Plan'}
                  </button>
                  </div> */}
                    </div>
                  )
                })}
              </div> : ''}

            {/* Transition to Payment Options */}
            {showPaymentOptions && (
              <div>
                <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-inter  font-bold">
                  How would you like to pay
                </h2>
                <div className="grid grid-cols-3 lg:grid-cols-3 gap-4 w-full my-4">
                  <div className=" col-span-1 px-4 flex flex-col py-2 text-sm bg-[#ffffff] rounded-md font-inter relative shadow-md">
                    <div className=" flex items-center w-full">
                      <img src={atmCard} className="w-[40%]" />
                      <span className="text-[#2E4D6B]">
                        Pay Online with Card
                      </span>
                    </div>
                    <div className="bg-[#2E4D6B70] hover:animate-bounce h-9 w-9 rounded-full flex items-center justify-center self-end"
                    onClick={() => handlePaymentOptionSelect("stripe")}
                    >
                      <img src={arrowRight} className="cursor-pointer" />
                    </div>
                  </div>
                  <div className=" col-span-1 px-2 py-2 text-sm flex flex-col bg-[#ffffff] rounded-md font-inter relative shadow-md">
                    <div className="flex items-center w-full">
                      <img src={atmCard} className="w-[40%]" />
                      <span className="text-[#2E4D6B]">Request Purchase Order</span>
                    </div>
                    <div
                      className="bg-[#2E4D6B70] hover:animate-bounce h-9 w-9 rounded-full flex items-center justify-center self-end"
                      onClick={() => handlePaymentOptionSelect("sendCheck")}
                    >
                      <img src={arrowRight} className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Payment Modal */}
             <Modal 
              visible={showStripeModal}
              onCancel={() => setShowStripeModal(false)}
          footer={null}
            >

              <Elements stripe={stripePromise} options={options}>
        <CheckoutForm coachProfile={coachProfile} amount={selectedSubscription?.price} />
      </Elements> 
            </Modal>
            <Modal
              title="Please enter email address for invoice"
              visible={showPaymentModal}
              onCancel={() => setShowPaymentModal(false)}
              onOk={handleSendInvoice}
            >
              <p>
                Please type below an authorized email for us to email the
                invoice to.
              </p>
              <Input
                placeholder="Email address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Modal>
          </div>
        )}

        {/* Section Two */}
        <div className="mt-3 xl:mt-[0.5vw]">
          <h2 className="text-[23px] xl:text-[1.7vw] font-inter font-bold">
            Subscription History
          </h2>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </div>
  );
};

const CheckoutPage = (props) => {
  const [plansList, setPlansList] = useState([]);
  const [plansListLoading, setPlansListLoading] = useState(false);

  const getPlansList = () => {
    setPlansListLoading(true);
    props.plansList({
      params: {},
      callback: (res, data) => {
        if (res === true) { 
          setPlansList(data?.data || []);
        }
        setPlansListLoading(false);
      },
    });
  };

  useEffect(() => {
    getPlansList();
  }, []);

 

  return (
    <>
      <SubscriptionPage
        plansList={plansList}
        sendInvoice={props.sendInvoice}
        coachProfile={props.coachProfile}
        subscriptionHistory={props.subscriptionHistory}
      />
     
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
  plansList: (details) =>
    dispatch(global.redux.action.super_admin.plansList(details)),
  sendInvoice: (details) =>
    dispatch(global.redux.action.schools.send_invoice(details)),
  subscriptionHistory: (componentRequest) =>
    dispatch(global.redux.action.schools.subscriptionHistory(componentRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
