import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
    notification,
	Modal,
    Form,
    Input, 
    Tag, 
} from "antd";
import { connect } from "react-redux";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

const { Option } = Select;

const ChangeStatus = (props) => {

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);
    const [statusType, setStatusType] = useState('');
	const [statusValue, setStatusValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [changeStatusButtonFlag, setChangeStatusButtonFlag] = useState(0);
    const [coachDetails, setCoachDetails] = useState({});

    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setChangeStatusButtonFlag(props.status);
    }, [props.status]); 

    const  onFinish = (e, coaches_id) => {
        const params = new URLSearchParams(window.location.search);
        let details = {
            // id: school_id,
            status: _.get(e, 'status'),
            status_glossary: _.get(e, 'status_glossary')
        } 

        props.changeStatus({
            body: details,
            params: { id: _.get(props, 'id')},
            callback: (res, responseData) => {
                if (res == true) {
                    notification.success(
                        { message: 'Success', description: _.get(responseData, 'message') }
                    );
                    setChangeStatusButtonFlag(details.status);
                    setVisible(false)
                } 
            }
        });
    };

    const showModal = (type, status) => {
        setVisible(true);
        setStatusType(type);
        setStatusValue(status);
        
        setTimeout(() => {
        let changeStatusFormData = _.pick(props, ['status', 'status_glossary']);
        changeStatusFormData.status = { key: changeStatusFormData.status, label: changeStatusFormData.status == 1 ? "Active": "Deactivate"};
        form.setFieldsValue(changeStatusFormData);
        }, 1000);
    };

    const  onFinishFailed = (e) => {

    };

    const handleOk = () => {
    setVisible(false);
    };

    const  handleCancel = () => {
        setVisible(false);
    };
 
    const render= () => {
		return (
			<div className="SchoolsViewModalContainer">
				 <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4 text-left">
                                <div className="CategoriesViewList">
                                    <div className="TextRegular mb-2">Status</div>
                                    <div className="TextSemiBold">
                                        { changeStatusButtonFlag == 1 ? (
                                            <Tag color="green">Active</Tag>
                                        ) : (
                                            <Tag color="red">In Active</Tag>
                                        )}
                                        </div>
                                </div>
                                <div>
                                    <div className="CategoriesViewList">
                                        <div className="mt-2">
                                            {changeStatusButtonFlag == 0 ? (
                                                <Button style={{ backgroundColor: 'green'}}
                                                    onClick={() => showModal("status", 1)}
                                                >
                                                    Active
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary" danger onClick={() => showModal("status", 0)}
                                                >
                                                    Deactivate
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal
                                    visible={visible}
                                    title="Change Status"
                                    //   onOk={this.handleOk}
                                    onCancel={handleCancel}
                                    footer={null}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="CategoriesViewList">
                                                <Form
                                                    scrollToFirstError={true}
                                                    onFinish={onFinish}
                                                    onFinishFailed={onFinishFailed}
                                                    form={form}
                                                >
                                                    <Form.Item
                                                        label="Status"
                                                        name="status"
                                                        rules={[
                                                            {
                                                            required: true,
                                                            message: "This field required!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select">
                                                            <Option value="1">Active</Option>
                                                            <Option value="0">Deactivate</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Reason for changing status"
                                                        name="status_glossary"
                                                        rules={[
                                                            {
                                                            required: true,
                                                            message: "This field required!",
                                                            },
                                                        ]}
                                                        >
                                                            <Input.TextArea />
                                                    </Form.Item>

                                                    <div
                                                        className="text-align-end"
                                                        style={{ textAlign: "end" }}
                                                    >
                                                        <Button
                                                            className="mr-2"
                                                            onClick={handleCancel}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            htmlType="submit"
                                                            type="primary"

                                                        // onClick={this.handleOk}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
			</div>
		)
	}
    return render();
}

const mapStateToProps = state => (
    {
        CoachesViewData: state,

    });

const mapDispatchToProps = dispatch => ({
    changeStatus: details => dispatch(global.redux.action.mvps.changeStatus(details))
 });
  
 export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);