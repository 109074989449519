import React, { useState, useEffect } from "react";
import { Select, Card, message } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import SeasonsListSelect from "../../../components/seasonsListSelect";
import threeDot from "../../../assets/img/Frame 18264.svg";
import { scoringColumns } from "./scoringColumn";
import {scoringColumnsPrint} from "./scoringColumnPrint";
import arrowLeft from "../../../assets/img/long-arrow-up-leftwhite.svg";
import { useNavigate } from "react-router-dom";
import ScoringTable from "./ScoringTable";
import ExportCsvModal from "../modal/exportCsvModal";
import ButtonUI from "../../../components/ButtonUI";
import send from "../../../assets/img/send.svg";
import "../seasondashboard.css";

function combineObjects(objArray) {
  const combined = {};

  // Loop through each object in the array
  objArray.forEach((obj) => {
    // Loop through properties of each object
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Check if the property value can be converted to a number
        const val = Number(obj[key]);

        // If the value is a valid number or is the kit_number, add it to the combined object
       
        if (!isNaN(val) && key != 'kit_number') {
          combined[key] = (combined[key] || 0) + val;
        } else {
          // Otherwise, use the value from the first object and ignore others
          console.log('key', key)
          if (!combined.hasOwnProperty(key)) {
            combined[key] = obj[key];
          }
        }
      }
    }
  });

  return { ...combined, athlete: combined.athlete / objArray.length };
}
const { Option } = Select;
function Scoring(props) {
  
 
  let positionsMap = [
     "QB",
     "OL",
     "WR",
     "TE",
     "RB",
     "OL",
     "DL",
     "LB",
   "S",
   "C",
     "C",
     "DL",
     "LB",
     "LB",
   "K",
     "ST",
  ]; 

  const [positionsArray, setPositionsArray] = useState(positionsMap);
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonID, setSeasonID] = useState(null);
  const [game, setGame] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [theirTeam, setTheirTeam] = useState("All");
  const [dataSource, setDataSource] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [rawdDataSource, setRawDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortedData, setSortedData] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    if (props?.coachProfile?.school?.id) {
      loadGamesList();
    }
  }, [seasonsList, seasonID]);

  const loadGamesList = () => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;

      if (season) {
        setSeasonID(season);

        let params = {
          limit: 995,
          home_team: props?.coachProfile?.school?.id,
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(
          sessionStorage.getItem(`${seasonID}-gamesList`)
        );

        if (list) {
          setAllGamesList(list);
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }

        props?.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              setAllGamesList(data?.data?.items);
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setAllGamesList = (data) => {
    const list = []; 
    if (data?.length > 0) {
      data?.map((value, index) => {
        list.push({
          key: index,
          id: parseInt(value?.id),
          title: value.title,
          week: `Week ${value.week}`,
          season: value?.season,
          game_id: value?.id,
          opponent_logo: value?.opponent_logo,
          away_team_score: value.away_team_score ?? 0,
          home_team_score: value.home_team_score ?? 0,
          team: props.coachProfile?.school?.name,
          school: props.coachProfile?.school?.id,
          type: value?.type,
          feedback_type: value?.feedback_type,
        });
      });
      setGamesList(list);
      const game = list.filter(
        (game) => game.type == "regular season" || game.type == "playoffs"
      );
      setGame(game.map(({ id }) => id));
      setTheirTeam("Season Stats");
    } else {
      setGamesList([]);
      setGame([]);
    }
  };

  useEffect(() => {
    loadGradesStats(JSON.stringify(game), JSON.stringify(positionsArray));
  }, [game, positionsArray]);

  const handleData = (data) => {
    const sumValues = (obj) => {
      return scoringColumns.reduce((acc, column) => {
        if (column.children) {
          column.children.forEach((child) => {
            if (child.dataIndex !== "athlete") {
              acc += Number(obj[child.dataIndex]) || 0;
            }
          });
        } else {
          if (column.dataIndex !== "athlete") {
            acc += Number(obj[column.dataIndex]) || 0;
          }
        }
        return acc;
      }, 0);
    };
    data.sort((a, b) => {
      const sumA = sumValues(a);
      const sumB = sumValues(b);
      if (sumA !== sumB) {
        return sumB - sumA;
      } else {
        return a.athlete - b.athlete;  
      }
    });

    setRawDataSource(data);
  };

  const sumColumns = (row) => {
    let sum = 0;
    sum += Number(row.total_rbtd) || 0;
    sum += Number(row.total_wrtd) || 0;
    sum +=
      Number(row.total_rbtd) +
        Number(row.total_wrtd) +
        Number(row.total_fum_ret_td) +
        Number(row.total_int_ret_td) +
        Number(row.total_punt_return_td) +
        Number(row.total_kickoff_return_td) || 0;
    sum +=
      (Number(row.total_rbtd) +
        Number(row.total_wrtd) +
        Number(row.total_fum_ret_td) +
        Number(row.total_int_ret_td) +
        Number(row.total_punt_return_td) +
        Number(row.total_kickoff_return_td)) *
        6 || 0;
    sum += Number(row.total_safety) * 2 || 0;
    sum +=
      Number(row.total_field_goals) +
        Number(row.total_block_field_goals) +
        Number(row.total_missed_field_goals) || 0;
    sum += Number(row?.total_field_goals) * 3 || 0;
    sum += Number(row.total_fum_ret_td) || 0;
    sum += Number(row.total_int_ret_td) || 0;
    sum += Number(row.total_punt_return_td) || 0;
    sum += Number(row.total_kickoff_return_td) || 0;
    sum += Number(row.total_pat) || 0;
    sum += Number(row.total_safety) || 0;
    sum += Number(row.total_two_part_conversion) || 0;
    sum += Number(row.total_field_goals) || 0;
    sum +=
      (Number(row.total_rbtd) +
        Number(row.total_wrtd) +
        Number(row.total_fum_ret_td) +
        Number(row.total_int_ret_td) +
        Number(row.total_punt_return_td) +
        Number(row.total_kickoff_return_td)) *
        6 +
        Number(row.total_pat) * 1 +
        Number(row.total_safety) * 2 +
        Number(row.total_two_part_conversion) * 2 +
        Number(row.total_field_goals) * 3 || 0;
    return sum;
  };

  useEffect(() => {
    // const sorted = [...rawdDataSource].sort(
    //   (a, b) => sumColumns(b) - sumColumns(a)
    // );
    setSortedData(rawdDataSource);
  }, [rawdDataSource]);

  const loadGradesStats = (game, position) => {
     
    try {
      if (game && position) { 
        setPageLoading(true);
        props.gradesStats({
          params: {
            game,
            position,
          },
          callback: (res, data) => {
            if (res) {
              const dupChecker = {};
              const duppies = new Set();
              let theDataStore = [];
              const theData = data?.data?.items?.rows;
              theData.map((data) => {
                if (!dupChecker[data.athlete]) {
                  dupChecker[data.athlete] = 1;
                } else {
                  dupChecker[data.athlete] += 1;
                  duppies.add(data.athlete);
                }
              });
              if (duppies.size) {
                duppies.forEach((duppy) => {
                  const duplicates = theData.filter(
                    (d) => d.athlete == duppy
                  );
                  theDataStore.push(combineObjects(duplicates));
                });

                theData.map((d) => {
                  if (!duppies.has(d.athlete)) {
                    theDataStore.push(d);
                  }
                });
              } else {
                theDataStore = theData;
              } 
              setRawDataSource(theDataStore || []);
              setDataSource(theDataStore || []);
            }
            setPageLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrint = () => {
    if (!dataSource.length) {
      message.error("No data to print");
      return;
    }
    history("/stats-to-print", {
      state: {
        dataSource: dataSource,
        season: seasonID,
        // odk: odk,
        theirTeam: theirTeam,
        type:"scoring"
      },
    });
  };

  return (
    <div className="flex flex-col font-inter lg:w-[99%] lg:p-[1%] stats-table-con">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center w-[80%]">
          <div className="px-2 bg-[black] w-[242px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
            <button
              onClick={() => {
                history("/stats-view");
              }}
              className={
                "bg-[#A40E17] w-full h-[31px] cursor-pointer flex items-center rounded-[4px]  text-[#FFFFFF]"
              }
            >
              <img src={arrowLeft} />
              <span className="font-bold">Return to Season Stats</span>
            </button>
          </div>
          <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
            <Select
              bordered={false}
              placeholder={"All Games"}
              className="w-[100%] text-[14px]"
              onChange={(id) => {
                if (id === "all") {
                  const game = gamesList.filter(
                    (game) =>
                      game.type == "regular season" || game.type == "playoffs"
                  );
                  setGame(game.map(({ id }) => id));
                  setTheirTeam("Season Stats");
                } else if (id === "scrimmages") {
                  const game = gamesList.filter(
                    (game) => game.type == "scrimmage"
                  ); 
                  setGame(game.map(({ id }) => id));

                  setTheirTeam("Scrimmages stats");
                } else if (id === "practice") {
                  const game = gamesList.filter(
                    (game) => game.type == "practice"
                  ); 
                  setGame(game.map(({ id }) => id));

                  setTheirTeam("Practices stats ");
                } else {
                  const game = gamesList.filter((game) => game.id == id)[0];
                  setGame([id]);

                  setTheirTeam(game.title);
                }
              }}
            >
              <Option
                value={"all"}
                key={"all"}
                className="text-[14px] font-bold font-inter"
              >
                All Games
              </Option>
              <Option
                value={"practice"}
                key={"practice"}
                className="text-[14px] font-bold font-inter"
              >
                Practices
              </Option>

              <Option
                value={"scrimmages"}
                key={"scrimmages"}
                className="text-[14px] font-bold font-inter"
              >
                Scrimmages
              </Option>

              {gamesList?.map(({ id, title, week, type }, i) => {
                if (type == "regular season" || type == "playoffs")
                  return (
                    <Option
                      value={id}
                      key={title}
                      className="text-[14px] font-bold font-inter"
                    >
                     {week}
                    </Option>
                  );
              })}
            </Select>
          </div>
          <SeasonsListSelect
            setSeasonsList={setSeasonsList}
            onChange={(e) => { 
              setSeasonID(e);
            }}
          />
          <span className="text-[14px] font-normal mx-2">
            <span className="text-[#A40E17]"> vs</span> {theirTeam}
          </span>
        </div>
        <div className=" w-[167px] lg:w-[18%] 2xl:w-[15%]">
          <ButtonUI
            text={"Send to Maxpreps"}
            background={"#1A91FF"}
            leftIcon={send}
            buttonFunction={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <div className="mt-2 w-full flex flex-col" id="stats-table-con">
        <Card
          bodyStyle={{ padding: "12px", width: "100%" }}
          className="stats-table-con-two"
        >           <div
        className="flex items-center justify-between w-full mb-2 hide-in-print"
        style={{ cursor: "pointer" }}
      >
        <span
          className="text-right flex items-center"
          style={{ fontSize: "14px" }}
          onClick={handlePrint}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.73584 11.7188H15.2358M3.98584 6.46875V1.66875C3.98584 1.42022 4.18731 1.21875 4.43584 1.21875H12.5358C12.7844 1.21875 12.9858 1.42022 12.9858 1.66875V6.46875M15.2358 14.1188V10.4688C15.2358 8.25961 13.445 6.46875 11.2358 6.46875H5.73584C3.5267 6.46875 1.73584 8.25961 1.73584 10.4688V14.1187C1.73584 14.4501 2.00447 14.7188 2.33584 14.7188H14.6358C14.9672 14.7188 15.2358 14.4501 15.2358 14.1188Z"
              stroke="#0F1A24"
              strokeWidth="1.5"
            />
          </svg>
          <span>&nbsp;&nbsp;Print Stats</span>
        </span>
      </div>

          <Card
            bodyStyle={{ padding: 0, width: "100%" }}
            className="w-full lg:overflow-scroll"
          >
            <div
              style={{ borderBottom: "1px solid #F3F5F7" }}
              className={`w-full lg:min-w-[2000px]`}
            > 
              <ScoringTable
                columns={scoringColumns}
                dataSource={sortedData}
                loading={pageLoading}
                print={props?.print || null}
                printColumns={scoringColumnsPrint}
              />
            </div>
          </Card>
        </Card>
  
        {/* <Statistics
          visible={showModal}
          setVisible={setShowModal}
          dataSource={dataSource}
          odk={odk}
        /> */}
        <ExportCsvModal
          visible={showModal}
          setVisible={setShowModal}
          dataSource={rawdDataSource}
           columns={scoringColumns}
          seasonID={seasonID}
          setSeasonID={setSeasonID}
          setSeasonsList={setSeasonsList}
          seasonsLists={seasonsList}
          setGame={setGame}
          game={gamesList}
          loading={pageLoading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scoring);
