import React from "react";

function TotalNumberBox({ title, number }) {
  return (
    <div
      className='flex flex-col rounded-[8px] overflow-hidden font-inter w-[240px] h-[123px]'
      style={{ border: "1.5px solid #EAEDF0" }}>
      <div className='flex text-center items-center justify-center bg-[#F3F5F7] font-bold text-[#2E4D6B] text-[14px] h-[47px] w-full'>
        <span>{title}</span>
      </div>
      <div className='h-[76px] w-full text-center bg-[#FFFFFF] text-[24px] font-poppins flex items-center justify-center font-bold text-[#0F1A24]'>
        <span>{number}</span>
      </div>
    </div>
  );
}

export default TotalNumberBox;
