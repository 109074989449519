import {
  Table,
  Tooltip,
  Select,
  Checkbox,
  Modal,
  Popover,
  message,
} from "antd";
import { useState, useEffect } from "react";
import searchicon from "../../../assets/img/search.png";
import trashicon from "../../../assets/img/trash.png";
import pencil from "../../../assets/img/pencil.svg";
import eye from "../../../assets/img/eye-empty.svg";
import PlayerInfoModalContent from "./PlayerInfoModalContent";
import EditPlayerInfoModal from "./EditPlayerInfoModal";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import {
  LoadingOutlined,
  MoreOutlined,
  ReloadOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import ChangePasswordModal from "./ResetPassword";
import DeleteModals from "../../coach/evaluations/DeletesModal";

const LastSeen = ({ updatedAt }) => {
  const now = moment();
  const lastSeen = moment(updatedAt);
  const diffMinutes = now.diff(lastSeen, "minutes");
  let showInfo = false;

  let lastSeenText = "";

  if (diffMinutes >= 500000) {
    lastSeenText = "More than a year ago";
    showInfo = true;
  } else if (diffMinutes <= 5) {
    lastSeenText = "Online";
  } else if (diffMinutes <= 60) {
    lastSeenText = `${diffMinutes} min ago`;
  } else if (diffMinutes <= 1440) {
    // 24 hours
    const diffHours = Math.floor(diffMinutes / 60);
    lastSeenText = `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
  } else if (diffMinutes <= 10080) {
    // 7 days
    const diffDays = Math.floor(diffMinutes / 1440);
    lastSeenText = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  } else if (diffMinutes <= 120960) {
    const diffWeeks = Math.floor(diffMinutes / 10080);
    lastSeenText = `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
  } else if (diffMinutes <= 500000) {
    const diffMonths = Math.floor(diffMinutes / 43200);
    lastSeenText = `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
    showInfo = true;
  }
  return (
    <span>
      {lastSeenText}{" "}
      {showInfo ? <InfoCircleFilled style={{ color: "red" }} /> : ""}
    </span>
  );
};

const PlayerList = ({
  athletesListLoading,
  athletesList,
  athletesListMeta,
  loadAthletesList,
  allAthletesList,
  position,
  depth_chart_options = [],
  searchAthletesList,
  trashPlayer,
  gridironDetails,
  changePassword,
}) => {
  const [page, setPage] = useState(1);
  const [player, setPlayer] = useState({});
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);
  const [playerModal, setPlayerModal] = useState(false);
  const [editPlayerModal, setEditPlayerModal] = useState(false);
  const [deletePlayerLoading, setDeletePlayerLoading] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("wardbord");
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [deletePlayerModal, setDeletePlayerModal] = useState(false);

  const handleCloseModal = () => {
    setPasswordModalVisible(false);
  };

  const handleSavePassword = () => {
    setPasswordResetLoading(true);
    changePassword({
      body: {
        email: player?.email,
        isCoach: true,
        password: newPassword,
      },
      callback: (res, data) => {
        if (res == true) {
          console.log("update password true", data);
          message.success("Password updated sucessfully");
        }
        setPasswordResetLoading(false);
      },
    });
  };

  const handleUpdateSelectedPlayerIds = (id) => {
    let _selectedPlayerIds = [...selectedPlayerIds];

    if (_selectedPlayerIds.includes(id)) {
      _selectedPlayerIds = _selectedPlayerIds.filter((i) => i !== id);
    } else {
      _selectedPlayerIds.push(id);
    }
    setSelectedPlayerIds(_selectedPlayerIds);
  };

  const columns = [
    {
      title: () => <Checkbox disabled />,
      width: "5%",
      align: "center",
      dataIndex: "id",
      render: (text, record) => (
        <Checkbox
          onChange={() => handleUpdateSelectedPlayerIds(text)}
          checked={selectedPlayerIds?.includes(text)}
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "athlete",
      align: "center",

      width: "10%",
      key: "first_name",
      render: (text, item) => text.first_name,
    },

    {
      title: "Last Name",
      dataIndex: "athlete",
      align: "center",

      width: "13.3%",
      key: "last_name",
      render: (text, item) => <span>{text.last_name}</span>,
      filtersIcon: (
        <span style={{ fontSize: 2, backgroundColor: "red" }}>
          &#9660;&#9650;
        </span>
      ),
    },
    {
      title: "Classification",
      dataIndex: "classification",
      align: "center",

      width: "14.3%",
      key: "classification",
      render: (text, item) => <span>{item.classification || "--"}</span>,
    },
    {
      title: "Uniform Number",
      dataIndex: "kit_number",

      align: "center",
      width: "12.3%",
      key: "kit_number",
      render: (text, item) => <span>{item.kit_number}</span>,
    },
    {
      title: "Email",
      dataIndex: "athlete",
      align: "center",
      width: "18.5%",
      key: "email",
      render: (text, item) => <span>{text?.email}</span>,
    },

    {
      title: "Position",
      dataIndex: "positions",
      width: "10%",
      align: "center",
      key: "positions",
      render: (text, item) => (
        <div className="flex items-center text-[12px] text-[#0F1A24] text-center font-inter  mx-auto justify-between">
          {text?.toString()}
        </div>
      ),
    },
    {
      title: "Last Seen",
      dataIndex: "positions",
      width: "10%",
      align: "center",
      key: "positions",
      render: (text, item) => <LastSeen updatedAt={item?.updated_at} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "6.9%",
      align: "center",
      key: "action",
      render: (text, item) => (
        <>
          <section className="flex items-center justify-between text-center ">
            <Tooltip title="View Profile">
              <img
                src={eye}
                className="w-[40%] cursor-pointer"
                onClick={() => {
                  setPlayerModal(true);
                  setPlayer(item);
                }}
              />
            </Tooltip>
            <Tooltip title="More">
              <Popover
                arrow={false}
                trigger="click"
                placement="bottom"
                content={
                  <div className="flex flex-col w-[200px]">
                    <div
                      className="flex cursor-pointer items-center w-full text-[12px] font-inter"
                      onClick={() => {
                        setPlayer(item);
                        setEditPlayerModal(true);
                        console.log(player);
                      }}
                    >
                      <img src={pencil} className=" w-[8%]" />
                      <span className="ml-[3%]">Edit player</span>
                    </div>
                    <div
                      className="flex mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                      onClick={() => {
                        handleUpdateSelectedPlayerIds(item.id);
                        setDeletePlayerModal(true);
                        console.log(player);
                      }}
                    >
                      <img src={trashicon} className=" w-[8%]" />
                      <span className="ml-[3%]">Delete/Archive Player</span>
                    </div>
                    <div
                      className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                      onClick={() => {
                        setPlayer(item);
                        setPasswordModalVisible(true);
                        console.log(player);
                      }}
                    >
                      <ReloadOutlined style={{ fontSize: "15px" }} />
                      <span className="ml-[3%]">Reset Player Password</span>
                    </div>
                  </div>
                }
              >
                <MoreOutlined style={{ fontSize: "20px" }} />
              </Popover>
            </Tooltip>
          </section>
        </>
      ),
    },
  ];

  const handleCancel = () => {
    setPlayerModal(false);
  };
  const handleEditCancel = () => {
    setEditPlayerModal(false);
  };

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleTrashPlayers = () => {
    console.log("deleting player");
    setDeletePlayerLoading(true);
    let promises = selectedPlayerIds?.map((selectedPlayerId) => {
      return new Promise((resolve, reject) => {
        trashPlayer({
          params: {
            id: selectedPlayerId,
          },
          callback: (res, data) => {
            if (res == true) {
              resolve(data);
              message.success("Player deleted sucessfully");
            } else {
              message.error(
                "Error trashing player with id " + selectedPlayerId
              );
            }
            setDeletePlayerLoading(false);
          },
        });
      });
    });

    Promise.all(promises)
      .then((results) => {
        setSelectedPlayerIds([]);
        loadAthletesList(5555, 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChartTypeChange = (value) => {
    console.log("value", value);
    loadAthletesList(555, 1, value);
  };

  const athlete_positions = [
    ...([{ name: "All positions", code: "" }] || []),
    ...(gridironDetails?.player_positions?.defense[0]?.variables || []),
    ...(gridironDetails?.player_positions?.offense[0]?.variables || []),
    ...(gridironDetails?.player_positions?.special_teams[0]?.variables || []),
  ]
    ?.map(({ positions, name, code }) => {
      let label = (
        <span className="font-inter">
          <span style={{ fontWeight: "bold" }}>{code || "All"}</span> (
          {capitalizeString(`${name}`)})
        </span>
      );

      if (code === "ST") {
        label = (
          <span className="font-inter">
            <span style={{ fontWeight: "bold" }}>ST</span> (Special Teams)
          </span>
        );
        code = "ST";
      }

      return {
        value: code,
        label: label,
      };
    })
    .filter(({ value }) => value !== "ST")
    .concat([
      {
        value: "ST",
        label: (
          <span className="font-inter">
            <span style={{ fontWeight: "bold" }}>ST</span> (Special Teams)
          </span>
        ),
      },
    ])
    .concat([
      {
        value: "DB",
        label: (
          <span className="font-inter">
            <span style={{ fontWeight: "bold" }}>DB</span> (Defensive Back)
          </span>
        ),
      },
    ]);

  return (
    <>
      <div className="searchandtotalplayerdiv">
        {/* search players container */}
        <div className="playerlistsearchdiv w-[30%] md:w-[200px]">
          <Select
            style={{ width: "100%" }}
            defaultValue={{ value: { position }, label: "All Positions" }}
            onChange={(value) => handleChartTypeChange(value)}
            options={athlete_positions}
            size="large"
          />
          {/* <input
            type='text'
            placeholder='Search Player'
            className='playerlistsearchbox'
            onChange={({ target: { value } }) => searchAthletesList(value)}
          />
          <img src={searchicon} className='playerlistsearchicon' /> */}
        </div>

        {/* total number of player display  */}
        <div className="totalplayerdiv">
          <span>
            You have total of{" "}
            <span className="numberofplayer">
              {athletesList?.length} Players
            </span>
          </span>
        </div>
      </div>
      {/* delete players container    */}
      <span
        onClick={() => {
          if (selectedPlayerIds?.length > 0) {
            setDeletePlayerModal(true);
          } else {
            message.warning("Please select at least one player to delete");
          }
        }}
        className="h-[43px] flex bg-[#F6E7E8] text-[#A40E17] items-center justify-center px-[0.5%] rounded-[0.2vw] mb-[2%] cursor-pointer w-[200px] xl:w-[20%]"
      >
        {deletePlayerLoading ? (
          <LoadingOutlined />
        ) : (
          <img src={trashicon} className="w-[20px] mr-1" />
        )}

        <span className="text-[14px]">Delete Player(s)</span>
      </span>
      <div className="w-full playerListTable">
        <Table
          key={1}
          loading={athletesListLoading}
          columns={columns}
          size="middle"
          bordered
          pagination={false}
          dataSource={athletesList}
          className="border-solid border-[1px] border-[#F3F5F7] w-[100%]"
        />
      </div>

      {/* modal for viewing player info */}
      {playerModal === true && (
        <div>
          <Modal
            onCancel={handleCancel}
            closable={true}
            mask={true}
            open={playerModal}
            title={
              <span className="text-[#0F1A24] text-[14px] font-bold font-inter">
                Player Info
              </span>
            }
            style={{ top: 20, borderRadius: "8px" }}
            top={0}
            width={485}
            height={863}
            footer={null}
          >
            <PlayerInfoModalContent
              handleEditModal={() => {
                console.log(player);
                setPlayerModal(false);
                setEditPlayerModal(true);
              }}
              kitNumber={player.kit_number}
              classification={player.classification}
              firstName={player.athlete.first_name}
              lastName={player.athlete.last_name}
              email={player.athlete.email}
              height={player.height}
              weight={player.weight}
              position={player.positions[0]}
              benchMax={player.bench_max}
              squatMax={player.squat_max}
              speed={player.sprint_covering_40_yards}
            />
          </Modal>
        </div>
      )}

      {/* modal for editing players info */}
      {editPlayerModal && (
        <div>
          <Modal
            onCancel={handleEditCancel}
            closable={true}
            open={editPlayerModal}
            title={
              <span className="text-[#0F1A24] text-[14px] font-bold font-inter">
                Edit Player Info
              </span>
            }
            style={{ top: 20, borderRadius: "8px" }}
            top={0}
            width={485}
            height={863}
            footer={null}
          >
            <EditPlayerInfoModal
              onCancel={handleEditCancel}
              kitNumber={player.kit_number}
              classification={player.classification}
              firstName={player.athlete.first_name}
              athlete={player.athlete.id}
              lastName={player.athlete.last_name}
              email={player.athlete.email}
              height={player.height}
              weight={player.weight}
              gridironDetails={gridironDetails}
              position={player.positions}
              benchMax={player.bench_max}
              squatMax={player.squat_max}
              speed={player.sprint_covering_40_yards}
              playerId={player.id}
              loadAthletesList={loadAthletesList}
            />
          </Modal>
        </div>
      )}
      {passwordModalVisible && (
        <ChangePasswordModal
          visible={passwordModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePassword}
          currentPassword={currentPassword}
          setCurrentPassword={setCurrentPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          passwordResetLoading={passwordResetLoading}
          setVisible={setPasswordModalVisible}
        />
      )}

      {deletePlayerModal && (
        <DeleteModals
          text={`Are you sure you want to delete ${
            selectedPlayerIds.length > 1 ? "these players ?" : "this player ?"
          }`}
          onOpen={deletePlayerModal}
          onCancel={() => {
            setDeletePlayerModal(false);
            setSelectedPlayerIds([]);
          }}
          onDelete={() => {
            handleTrashPlayers();
            setDeletePlayerModal(false);
            setSelectedPlayerIds([]);
          }}
        />
      )}

      {/* {athletesListLoading == false && athletesListMeta.total > 10 && (
        <div className='PaginationContainer'>
          <span className='PaginationContainerInner'>
            <span className='PaginationLabel'>
              Total{" "}
              {athletesListMeta.total > 1
                ? `${athletesListMeta.total} items`
                : `${athletesListMeta.total} item`}
            </span>
            <Pagination
              defaultCurrent={1}
              hideOnSinglePage={true}
              onChange={(page, size) => onChangePage(page, size)}
              total={athletesListMeta.total}
              // total={500}
              current={page}
            />
          </span>
          <span className='PaginationContainerInner'>
            <span className='PaginationLabel ml-3'>Go to</span>
            <InputNumber
              min={1}
              max={athletesListMeta.total > 10 ? athletesListMeta.total / 9 : 2}
              onPressEnter={(e) => onChangePage(e.target.value || 9)}
              style={{ width: "70px" }}
            />
          </span>
        </div>
      )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  coachProfile: _.get(state, "app.account.profile.data.details"),
  gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  getDepthCharts: (details) =>
    dispatch(global.redux.action.depth_chart.list(details)),
  createDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.creates(details)),
  updatePlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.updatePlayer(details)),
  addPlayerDepthChart: (details) =>
    dispatch(global.redux.action.depth_chart.addPlayer(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.site.gridironDetails(details)),
  editPlayer: (details) => dispatch(global.redux.action.athletes.edit(details)),
  trashPlayer: (details) =>
    dispatch(global.redux.action.athletes.trash(details)),
  changePassword: (details) =>
    dispatch(global.redux.action.account.changePassword(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerList);
