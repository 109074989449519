import React, { useState } from "react";
import { Switch, Input, Form, Button, Checkbox, Tooltip, Row, Col } from "antd";
import visa from "../../assets/img/Visa.svg";
import masterCard from "../../assets/img/Mastercard.svg";
import StripePayment from '../school-admin/settings/StripePayment'
import stripe from "../../assets/img/Stripe.svg";
import discover from "../../assets/img/Discover.svg";
import { usePaymentInputs } from "react-payment-inputs";
import cardSecurity from "../../assets/img/card-security.svg";
import {
  QuestionCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

const ChequeMethod = ({ page, setPage, email, handleSendInvoice }) => {
 
  const { Item } = Form;
  const onFinish = (values) => {
    console.log("Received values:", values);
    handleSendInvoice();
  };

  return (
    <div className="">
      <div>{/* card images go here */}</div>
      <Form
        name="paymentForm"
        onFinish={(values) =>onFinish(values)}
        fields={[
          {
            name: ["email"],
            value: email,
          },
        ]}
      >
         
        <Item
          name="email"
          placeholder="Email Address"
          rules={[
            {
              required: true,
              message: "Email Address is required",
            },
          ]}
          // initialValue={email}
        >
          <div className="flex flex-col">
            <span className="text-[#0F1A24] text-[14px] font-normal text-left mb-2">
              Email Address
            </span>
            <Input size="large" defaultValue={email} />
          </div>
        </Item>

        {/* <Item
          name="School Address"
          placeholder="School Address"
          rules={[
            {
              required: true,
              message: "School Address is required",
            },
          ]}
        >
          <div className="flex flex-col">
            <span className="text-[#0F1A24] text-[14px] font-normal text-left mb-2">
              School Address
            </span>
            <Input size="large" />
          </div>
        </Item>
        <Item
          name="School Address"
          placeholder="School Address"
          rules={[
            {
              required: true,
              message: "School Address is required",
            },
          ]}
        >
          <div className="flex flex-col">
            <span className="text-[#0F1A24] text-[14px] font-normal text-left mb-2">
              School Address
            </span>
            <Input size="large" />
          </div>
        </Item> */}
        <Item>
          <div className="w-full">
            <button
              className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
              type="submit"
            >
              <span>Submit Your Request</span>
            </button>
          </div>
        </Item>
      </Form>
    </div>
  );
};

const CardMethod = ({ page, setPage,coachProfile, subscription }) => {
  const { Item } = Form;
  const onFinish = (values) => {
    console.log("Received values:", values);
    if (page == 4) {
    } else {
      setPage(page + 1);
    }
  };

  return (
    <div className="">
     
      <StripePayment coachProfile={coachProfile} subscription={subscription} />
      <div>{/* card images go here */}</div>
      <Form name="paymentForm" onFinish={onFinish}>
       
        <Item>
          <div className="w-full">
            <button
              className={` mx-auto px-6 py-2 flex items-center justify-center gap-2 bg-[#1A91FF] disabled:opacity-50 text-[14px] text-white rounded-md font-bold`}
              type="submit"
            >
              <img src={cardSecurity} />
              <span>COMPLETE ORDER</span>
            </button>
          </div>
        </Item>
      </Form>
    </div>
  );
};

function PaymentMethod(props) {
 
  const [paymentMethod, setPaymentMethod] = useState(() => {
    const paymentMethod = sessionStorage.getItem("newPaymentMethod");
    return paymentMethod ? paymentMethod : "card";
  });

  const handleChangePaymentMethod = (check) => {
    const newPaymentMethod = check ? "cheque" : "card";
    setPaymentMethod(newPaymentMethod);
    sessionStorage.setItem("newPaymentMethod", newPaymentMethod);
  };

  const isChecked = () => {
    const paymentMethod = sessionStorage.getItem("newPaymentMethod");
    if (paymentMethod === "cheque") {
      return true;
    } else {
      return false;
    }
  };

  //   const handleCardNumberChange = (e) => {
  //     const formattedCardNumber = formatCreditCardNumber(e.target.value);
  //     setCardNumber(formattedCardNumber);
  //     const cardType = creditCardType(e.target.value)[0];
  //     // Access card type information, e.g., cardType.type
  //   };

  //   const handleExpiryChange = (e) => {
  //     setExpiry(formatExpirationDate(e.target.value));
  //   };

  //   const handleCvcChange = (e) => {
  //     setCvc(formatCVC(e.target.value));
  //   };
console.log('props.selectedSize', props.selectedSize)
  return (
    <div className="w-[100%] text-center flex flex-col">
      <div className="w-[90%] mx-auto">
        <span className="text-[18px] font-bold text-center font-poppins text-[#0F1A24]">
          CHOOSE YOUR PAYMENT METHOD
        </span>
        <div className="my-4 flex items-center text-center text-sm font-bold font-poppins express-payment-method">
          <span
            className={`${
              paymentMethod === "card" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            } `}
          >
            PAY VIA CARD
          </span>
          {isChecked() === true ? (
            <Switch
              className="mx-4"
              checked={true}
              onChange={handleChangePaymentMethod}
            />
          ) : (
            <Switch
              checked={false}
              onChange={handleChangePaymentMethod}
              className="mx-4"
            />
          )}

          <span
            className={`${
              paymentMethod === "cheque" ? "text-[#1A91FF]" : "text-[#A9B6C2]"
            }`}
          >
            VIA PAY CHECK
          </span>
        </div>
      </div>
      <div className="flex flex-col font-poppins text-[#0F1A24] mt-8">
        {paymentMethod === "card" ? (
          <CardMethod page={props.page} setPage={props.setPage}
            coachProfile={{ 
              
          school_id: props?.school?.id,
          school_name: props?.school?.name,
          email: props?.email,
          first_name: props?.user?.first_name,
          last_name: props?.user?.last_name
        }}
        subscription={props.selectedSize} />
        ) : (
          <ChequeMethod
            page={props.page}
            setPage={props.setPage}
            email={
               props.email
            }
            handleSendInvoice={props.handleSendInvoice}
          />
        )}
      </div>
    </div>
  );
}

export default PaymentMethod;

{
  /* <span className="text-[12px]">
          Annual charge after 30 day trial is{" "}
          <span className="font-bold">$350</span>
        </span>
        <div className="flex items-center justify-between mt-2 w-[50%] mx-auto">
          <img src={discover} alt="discover" className="w-[22%]" />
          <img src={stripe} alt="stripe" className="w-[22%]" />
          <img src={visa} alt="visa" className="w-[22%]" />
          <img src={masterCard} alt="masterCard" className="w-[22%]" />
        </div>
        <div className="w-full mt-8 flex flex-col">
          <span className="text-left font-inter text-[14px] text-[#0F1A24] font-normal mb-2">
            Card Holder Name
          </span>
          <input
            {...getCardNumberProps({
              onChange: (e) => setCardNumber(e.target.value),
            })}
            value={cardNumber}
            className="w-full border border-[#E5E5E5] rounded-md text-[#0F1A24] p-2  mb-2"
          />
          <span className="text-left font-inter text-[14px] text-[#0F1A24] font-normal mb-2">
            Card Number
          </span>
          <input
            {...getCardNumberProps({
              onChange: (e) => setCardNumber(e.target.value),
            })}
            value={cardNumber}
            className="w-full border border-[#E5E5E5] rounded-md text-[#0F1A24] p-2  mb-2"
          />
          <div className="flex items-center justify-between mb-2">
            <div className="w-[48%] flex flex-col">
              <span className="text-left font-inter text-[14px] text-[#0F1A24] font-normal mb-2">
                Expiry Date
              </span>
              <input
                {...getExpiryDateProps({
                  onChange: (e) => setExpiry(e.target.value),
                })}
                value={expiry}
                className="w-full border border-[#E5E5E5] rounded-md text-[#0F1A24] p-2 "
              />
            </div>
            <div className="w-[48%] flex flex-col">
              <span className="text-left font-inter text-[14px] text-[#0F1A24] font-normal mb-2">
                Security Code
              </span>
              <input
                {...getCVCProps({ onChange: (e) => setCvc(e.target.value) })}
                value={cvc}
                className="w-full border border-[#E5E5E5] rounded-md text-[#0F1A24] p-2 "
              />
            </div>
          </div>
          <span className="text-left font-inter text-[14px] text-[#0F1A24] font-normal mb-2">
            Zip Code
          </span>
          <input
            {...getCardNumberProps({
              onChange: (e) => setCardNumber(e.target.value),
            })}
            value={cardNumber}
            className="w-full border border-[#E5E5E5] rounded-md text-[#0F1A24] p-2  mb-2"
          />
          {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
        </div> */
}
