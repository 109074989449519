import React from "react";
import { Menu, Checkbox } from "antd";

function PositionDropdown({ handleUpdatePosition }) { 
  return (
    <Menu
      style={{
        width: 437,
        height: 575,
        backgroundColor: "white",
        padding: "16px",
      }}
      className='positiondropdown'>
      <div className='flex flex-col font-inter'>
        <span className='font-bold text-[14px] text-[#000000] mb-[14px]'>
          Defensive Positions
        </span>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('DL') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>DL</span> (Defensive
            Linemen)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('LB') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>LB</span> (Linebackers)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('S') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>S</span> (Safeties)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('C') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>C</span> (Cornerback)
          </span>
        </div>
      </div>
      <div className='flex flex-col'>
        <span className='font-bold text-[14px] text-[#000000] mb-[14px]'>
          Offensive Positions
        </span>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('QB') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>QB</span> (Quarterback)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('OL') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>OL</span> (Offensive
            lineman)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('WR') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>WR</span> (Wide Receiver)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('TE') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>TE</span> (Tight end)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('RB') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>RB</span> (Running Back)
          </span>
        </div>
      </div>
      <div className='flex flex-col'>
        <span className='font-bold text-[14px] text-[#000000] mb-[14px]'>
          Special Teams Position
        </span>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('K') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>K</span> (Kicker)
          </span>
        </div>
        <div className='flex flex-row mb-[15px] text-[14px] items-center font-inter'>
          <Checkbox onChange={() => handleUpdatePosition('ST') }/>
          <span className=' ml-[8px] text-[#8698A9]'>
            {" "}
            <span className='font-bold text-[#000000]'>ST</span> (Special Teams)
          </span>
        </div>
      </div>
    </Menu>
  );
}

export default PositionDropdown;
