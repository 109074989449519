import React from "react";
import { Gauge } from "../charts/guage";

function PlayerAnalyticCell({
  Title,
  TotalAssignmentsEvaluated,
  Avg,
  winValue,
  lossValue,
  totalPlays,
  calcParam,
  calcParamValue,
  differential,
  formatNum,
  total_games
}) {

  const percentageDiff = winValue > lossValue ? winValue  - lossValue :   lossValue - winValue || 0
  const Xdifferential = formatNum((percentageDiff / 100) * totalPlays) / total_games || 0; 
  return (
    <div className='w-[49%] border-solid border-[#EaEDF0] border-[1px] rounded-lg '>
      <div className='flex items-center justify-between p-3 bg-[#F3F5F7]'>
        <span className=' text-sm font-bold'>{Title}</span>
        <span className=' text-sm font-bold'>
          Total Assignments Evaluated - {TotalAssignmentsEvaluated}
        </span>
      </div>
      <h3 className='mt-2 text-[0.7rem] text-center font-bold'>
        Avg {Avg} Score{" "}
      </h3>
      <div className='flex justify-between my-3'>
        <Gauge value={formatNum(winValue)} color='#1A91FF' text='per Win' />
        <div className='h-[6rem] w-[1px] bg-[#EaEDF0] my-3'></div>
        <Gauge value={formatNum(lossValue)} color='#A40E17' text='per Loss' />
      </div>
      <hr className='my-0' />
      <div className='flex items-center justify-between px-2 font-bold my-1'>
        <span className='text-black text-[0.7rem]'>Total Plays :</span>
        <span className='text-black text-[0.7rem]'>{totalPlays}</span>
      </div>
      <hr className='my-0' />
      <div className='flex items-center justify-between px-2 font-bold my-1'>
        <span className='text-black text-[0.7rem]'>Total {calcParam?.toString()}:</span>
        <span className='text-black text-[0.7rem]'>{calcParamValue}</span>
      </div>
      <hr className='my-0' />
      <div className='flex items-center justify-between px-2 font-bold mt-1 mb-3'>
        <span className='text-black text-[0.7rem]'>
          W/L Performance Differential:
        </span>
        <span className='text-black text-[0.7rem]'>{Xdifferential.toFixed(2)} Plays ({formatNum(percentageDiff)}%) </span>
      </div>
    </div>
  );
}

export default PlayerAnalyticCell;
