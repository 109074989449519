import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, Spin, notification, Upload } from 'antd';
import axios from 'axios';
import { geocodeByAddress } from 'react-places-autocomplete';
import { EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './manage-school.css';
import PhoneInput from 'react-phone-number-input';

const SchoolAdminUpdateComponent = (props) => {
  const [address_results, setaddress_results] = useState([]);
  const [address_fetching, setaddress_fetching] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [photo_display, setphoto_display] = useState(null);
  const [loading, setLoading] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [add_featured_photo, setadd_featured_photo] = useState(null);
  const [fileList, setFileList] = useState([]);

  let history = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchSchoolViewData();
  }, []);

 
  function normalize(phone) {
    //normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, '');

    //check if number length equals to 10
    if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    return null;
  }

  const getPhotos = (photos_details, meta_details) => {
    let photos = [];
    let details_photos = _.last(photos_details);
    if (details_photos) {
      let image_url = `${_.get(meta_details, 'photo.path')}/${_.get(
        meta_details,
        'photo.folders.school'
      )}/512/${details_photos}`;
      // photos.push({
      //     uid: index+22323,
      //     name: values,
      //     status: 'done',
      //     url: image_url,
      //   })
      return image_url;
    } else {
      return null;
    }
  };

  const fetchSchoolViewData = () => {
    const params = new URLSearchParams(window.location.search);
    let school_id = params.get('id');

    props.getSchoolViewData({
      params: { id: school_id },
      callback: (res, responseData) => { 
        setFormFieldsData(
          _.chain(responseData)
            .get('data.details', {})
            .pick(['phone', 'zip_code', 'color_theme'])
            .value()
        );
        if (
          _.get(responseData, 'data.details.address') &&
          _.get(responseData, 'data.details.location')
        ) {
          let split_location = _.split(
            _.get(responseData, 'data.details.location.lat'),
            ', '
          ); 
          const address_results = [
            {
              text: _.get(responseData, 'data.details.address'),
              value: JSON.stringify({
                text: _.get(responseData, 'data.details.address'),
                lat: _.get(responseData, 'data.details.location.x'),
                lng: _.get(responseData, 'data.details.location.y'),
                postal_code: '',
              }),
            },
          ];
          form.setFieldsValue({
            location: JSON.stringify({
              text: _.get(responseData, 'data.details.address'),
              lat: _.get(responseData, 'data.details.location.x'),
              lng: _.get(responseData, 'data.details.location.y'),
              postal_code: '',
            }),
          });

          setaddress_results(address_results);
        }
        // let photos_details = getPhotos(_.get(responseData, 'data.details.featured_photos'),_.get(responseData, 'data.meta'))
        // setFileList(photos_details)
        form.setFieldsValue({
          name: _.startCase(_.get(responseData, 'data.details.name')),
        });
        form.setFieldsValue({
          city: _.startCase(_.get(responseData, 'data.details.city')),
        });
        form.setFieldsValue({
          bio: _.startCase(_.get(responseData, 'data.details.bio')),
        });
        let update_photo_filename = _.chain(responseData)
          .get('data.details.photo', '')
          .trim()
          .value();
 
        if (!_.isEmpty(update_photo_filename)) {
          setphoto_display(
            `${_.get(responseData, 'data.meta.photo.path')}/${_.get(
              responseData,
              'data.meta.photo.folders.school'
            )}/512/${update_photo_filename}?time=+ ${new Date().getTime()}`
          );
        }
        let add_featured_photo_filename = _.last(
          _.get(responseData, 'data.details.featured_photos')
        );
        let photos_details = getPhotos(
          _.get(responseData, 'data.details.featured_photos'),
          _.get(responseData, 'data.meta')
        ); 
        if (photos_details && photos_details != null) {
          setadd_featured_photo(photos_details);
        }
      },
    });
  };

  const setFormFieldsData = (formFieldsData) => {
    form.setFieldsValue(formFieldsData);
  };

  const handleSubmit = (e) => {
    setButtonLoading(true);
    const params = new URLSearchParams(window.location.search);
    let school_id = params.get('id'); 
    let address_parse = _.get(e, 'location')
      ? JSON.parse(_.get(e, 'location', {}))
      : {};
    let details = {
      id: school_id,
      name: _.get(e, 'name'),
      city: _.get(e, 'city'),
      bio: _.get(e, 'bio'),
      // zip_code: _.get(e, 'zip_code'),
      color_theme: _.get(e, 'color_theme'),
      location: `${_.get(address_parse, 'lat')}, ${_.get(
        address_parse,
        'lng'
      )}`,
      address: _.get(address_parse, 'text'),
    }; 
    props.getSchoolUpdateData({
      body: details,
      callback: async (res, responseData) => {
        if (res == true) {
          history('/manage-school');
          // updatePhone(e, school_id, _.get(responseData, 'message'))
          if (_.get(e, 'photo') || _.get(e, 'add_featured_photo')) {
            await updatePhoto(e, school_id, _.get(responseData, 'message'));
            await addFeaturedPhoto(
              e,
              school_id,
              _.get(responseData, 'message')
            );
            history('/schools-list');
          } else {
            notification.success({
              message: 'Success',
              description: _.get(responseData, 'message'),
            });
            history('/schools-list');
          }

          // School has been updated successfully.
          // history('/schools-list')
        } 
      },
    });
  };

  const updatePhone = (e, school_id, message) => {
    let details_phone = {
      id: school_id,
      // phone: _.get(e, 'phone'),
      // normalize(_.get(e, 'phone')),
    };
    props.UpdatePhoneData({
      body: details_phone,
      callback: (res, responseData) => {
        if (res == true) {
          setButtonLoading(false);
          notification.success({ message: 'Success', description: message });
          history('/schools-list');
          // updatePhoto(e, school_id)
        }
      },
    });
  };
  const checkImageWidth = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          resolve({ width: image.width, height: image.height });
          return { width: image.width, height: image.height };
        };
        reader.onerror = (err) => reject(err);
      };
    });
  };

  const updatePhoto = (data, school_id) => {
    return new Promise((resolve) => {
      if (_.get(data, 'photo.file.originFileObj')) {
        let formData = new FormData();
        formData.append('id', school_id);
        formData.append('photo', _.get(data, 'photo.file.originFileObj'));
        props.UpdatePhotoData({
          body: formData,
          callback: (res, responseData) => {
            // const params = new URLSearchParams(window.location.search);
            // let school_id = params.get('id');
            props.getSchoolViewData({
              params: { id: school_id },
              callback: (res, responseData) => {},
            });
            if (res == true) {
              notification.success({
                message: 'Success',
                description: _.get(responseData, 'message'),
              });
            }
            setButtonLoading(false);
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  };

  const addFeaturedPhoto = (data, school_id) => {
    return new Promise((resolve) => {
      let add_featured_photo = add_featured_photo; 
      if (_.get(add_featured_photo, 'originFileObj')) {
        let formData = new FormData();
        formData.append('id', school_id);
        formData.append('photo', add_featured_photo);
        props.AddFeaturedPhotoData({
          body: formData,
          callback: (res, responseData) => {
            props.getSchoolViewData({
              params: { id: school_id },
              callback: (res, responseData) => {},
            });
            if (res == true) {
              notification.success({
                message: 'Success',
                description: _.get(responseData, 'message'),
              });
            }
            setButtonLoading(false);
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  };

  const onChangePic = async (info) => {
    let details = await checkImageWidth(_.get(info, 'file.originFileObj'));
    if (_.get(details, 'width') > 345 && _.get(details, 'height') > 165) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setadd_featured_photo(imageUrl);
      });
      // await setFileList(fileList) 
      // await form.setFieldsValue({add_featured_photo:fileList}) 
    } else if (
      _.get(details, 'width') < 345 ||
      _.get(details, 'height') < 165
    ) {
      notification.warning({
        message: 'Feature Photo',
        description: 'Please select the 345x165 dimensions image.',
      });
    }
  };

  //Address search
  const fetchAddress = (value) => {
    setaddress_results([]);
    setaddress_fetching(true);
    geocodeByAddress(value)
      .then((body) => { 
        const address_results = _.map(body, (location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            text: location.formatted_address,
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
            postal_code: fetchPostalCode(
              _.get(location, 'address_components', [])
            ),
          }),
        }));

        setaddress_results(address_results);
        setaddress_fetching(false);
      })
      .catch((error) => {
        setaddress_results([]);
        setaddress_fetching(true);
      });
  };
  //Initializing debounce for address
  const addressDelayedQuery = _.debounce((q) => fetchAddress(q), 1200);

  const fetchPostalCode = (address_components) => {
    let postal_code = null;
    address_components.forEach((value) => {
      value.types.forEach((type) => {
        if (type === 'postal_code') {
          postal_code = value.long_name;
        }
      });
    });
    return postal_code;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 50);
  };

  const removeImage = (info) => {};

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onChangePhoto = async (info) => { 
    let details = await checkImageWidth(info.file.originFileObj);
    if (
      _.chain(details).get('width').eq(512).value() &&
      _.chain(details).get('height').eq(512).value()
    ) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setphoto_display(imageUrl);
      });
    } else {
      notification.warning({
        message: 'Team Logo',
        description: 'Please select the 512x512 dimensions image.',
      });
    }
  };

  const render = () => {
    const { editMode, post } = props;
    const params = new URLSearchParams(window.location.search);
    let school_id = params.get('id');
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <div className="UpdateSchoolForm">
        <div className="container">
          <div className="PlayerprimaryCard px-5 py-4">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              className="row"
              onFinish={handleSubmit}
              form={form}
            >
              {params.get('id') ? (
                <Form.Item
                  className="col-12 col-md-12"
                  label="Add Team Logo"
                  name="photo"
                  rules={[
                    {
                      required: photo_display ? false : true,
                      message: 'Please input your photo!',
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onRemove={removeImage}
                    onChange={onChangePhoto}
                    customRequest={dummyRequest}
                  >
                    {photo_display ? (
                      <img
                        src={photo_display}
                        alt="avatar"
                        style={{ width: '100%', borderRadius: '100px' }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              ) : null}
              {params.get('id') ? (
                <Form.Item
                  className="col-12 col-md-6"
                  label="Add Team Photo"
                  name="add_featured_photo"
                  rules={[
                    {
                      required: add_featured_photo ? false : true,
                      message: 'Please input your photo!',
                    },
                  ]}
                >
                  <Upload
                    // name="avatar"
                    listType="picture-card"
                    className="avatar-uploader add_featured_photo"
                    showUploadList={false}
                    // onRemove={removeImage}
                    onChange={onChangePic}
                    customRequest={dummyRequest}
                  >
                    {/* {_.size(fileList)>= 20 ? null : uploadButton} */}
                    {add_featured_photo ? (
                      <img
                        src={add_featured_photo}
                        alt="avatar"
                        style={{
                          width: '100%',
                          borderRadius: '10px',
                          height: '80px',
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              ) : null}
              <Form.Item
                className="col-12 col-md-6"
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="col-12 col-md-6"
                label="Bio"
                name="bio"
                rules={[{ required: true, message: 'Please input your bio!' }]}
              >
                <Input />
              </Form.Item>
              {/*<Form.Item
                                className="col-12 col-md-6"
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please input your phone!' }]}
                            >
                                <Input style={{ width: 200 }} />
                            </Form.Item>*/}
              <Form.Item
                className="col-12 col-md-6"
                label="City"
                name="city"
                rules={[{ required: true, message: 'Please input your city!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="col-12 col-md-6"
                label="Location"
                name="location"
                rules={[
                  { required: true, message: 'Please input your location!' },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showSearch
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={(search) =>
                    search ? addressDelayedQuery(search) : ''
                  }
                  //   onChange={(e) => onChangeForm(e, 'address')}
                  placeholder={'Search address...'}
                  notFoundContent={
                    address_fetching ? <Spin size="small" /> : null
                  }
                >
                  {address_results &&
                    address_results.length > 0 &&
                    address_results.map((d) => (
                      <Select.Option key={d.value}>{d.text}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {/* <Form.Item
                                className="col-12 col-md-6"
                                label="Team Slogan"
                                name="Team Slogan"
                            // rules={[{ required: true, message: 'Please input your team slogan!' }]}
                            >
                                <Input.TextArea />
                            </Form.Item> */}
              <Form.Item
                className="col-12 col-md-6"
                label="Color Theme"
                name="color_theme"

                // rules={[{ required: true, message: 'Please input your First name!' }]}
              >
                <Input type="color" id="colorpicker" value="#000" />
              </Form.Item>

              <Form.Item className="col-12 col-md-12 text-right">
                <Button
                  loading={buttonLoading}
                  className="ant-btn mr-4"
                  type="primary"
                  htmlType="submit"
                >
                  Update
                </Button>
                <Button
                  disabled={buttonLoading}
                  className="ant-btn "
                  type="ghost"
                  onClick={() => {
                    history('/manage-school');
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <br />
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  SchoolsViewData: state,
});

const mapDispatchToProps = (dispatch) => ({
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
  getSchoolUpdateData: (details) =>
    dispatch(global.redux.action.schools.update(details)),
  UpdatePhoneData: (details) =>
    dispatch(global.redux.action.schools.updatephone(details)),
  UpdatePhotoData: (details) =>
    dispatch(global.redux.action.schools.updatephoto(details)),
  AddFeaturedPhotoData: (details) =>
    dispatch(global.redux.action.schools.addfeaturedphoto(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolAdminUpdateComponent);
