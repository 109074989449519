import { Tooltip } from "antd";

const postCol = [
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        #
      </div>
    ),
    children: [
      {
        title: "",
        fixed: "left",
        dataIndex: "kit_number",
        exportName: "Jersey",
        width: "1",
        align: "center",
        render: (text, row) => <div>{row.kit_number || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center hide bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        First Name
      </div>
    ),
    children: [
      {
        title: "",
        fixed: "left",
        dataIndex: "first_name",
        excludeFromExport: true,
        width: "2",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Last Name
      </div>
    ),
    children: [
      {
        title: "",
        fixed: "left",
        dataIndex: "last_name",
        excludeFromExport: true,
        width: "2",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Plays
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "plays",
        width: "1",
        excludeFromExport: true,
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Pstv Impct Plays
      </div>
    ),
    children: [
      {
        title: "Tot",
        dataIndex: "total_positive_impact",
        excludeFromExport: true,
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
];

const oColumns = [
  ...postCol,
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Rushing
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_carries",
        exportName: "RushingNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_gn_carries_yds",
        exportName: "RushingYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg",
        dataIndex: "total_gn_carries_yds",
        exportName: "RushingNum",
        align: "center",
        render: (text, row) => (
          <div>
            {(Number(text) / Number(row.total_carries) || 0).toFixed(2) || 0}
          </div>
        ),
      },
      {
        title: "Lng",
        dataIndex: "max_gn_carries_yds",
        exportName: "RushingLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Yards after Contact">
            <div>YACO</div>
          </Tooltip>
        ),
        dataIndex: "total_yards_after_contact",
        exportName: "RushingNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Average Yards after Contact">
            <div>Avg YACO</div>
          </Tooltip>
        ),
        dataIndex: "total_yards_after_contact",
        exportName: "RushingNum",
        align: "center",
        render: (text, row) => (
          <div>
            {(Number(text) / Number(row.total_carries) || 0).toFixed(2) || 0}
          </div>
        ),
      },
      {
        title: "RB TD",
        dataIndex: "total_rbtd",
        align: "center",
        exportName: "RushingNum",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Receiving
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_completions",
        align: "center",
        exportName: "ReceivingNum",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Drops",
        dataIndex: "total_drops",
        align: "center",
        exportName: "ReceivingNum",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_completions_carries_yds",
        exportName: "ReceivingYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg",
        dataIndex: "total_completions_carries_yds",
        exportName: "ReceivingNum",
        align: "center",
        render: (text, row) => (
          <div>
            {(Number(text) / Number(row.total_completions) || 0).toFixed(2) ||
              0}
          </div>
        ),
      },
      {
        title: "Lng",
        dataIndex: "max_completions_carries_yds",
        exportName: "ReceivingLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Yards after Catch">
            <div>YACA</div>
          </Tooltip>
        ),
        dataIndex: "total_yards_after_catch",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Average Yards after Catch">
            <div>Avg YACA</div>
          </Tooltip>
        ),
        dataIndex: "total_yards_after_catch",
        align: "center",
        render: (text, row) => (
          <div>
            {row.total_completions
              ? (Number(text) / Number(row.total_completions) || 0).toFixed(
                  2
                ) || 0
              : 0}
          </div>
        ),
      },
      {
        title: "WR TD",
        dataIndex: "total_wrtd",
        exportName: "ReceivingNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Passing
      </div>
    ),
    children: [
      {
        title: "Cmp",
        dataIndex: "total_complete_receptions",
        exportName: "PassingComp",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Att",
        dataIndex: "total_complete_receptions",
        align: "center",
        exportName: "PassingAtt",
        render: (text, row) => (
          <div>
            {Number(text) +
              Number(row?.total_incomplete_receptions) +
              Number(row?.total_int) || 0}
          </div>
        ),
      },
      {
        title: "Yds",
        dataIndex: "total_complete_receptions_yds",
        exportName: "PassingYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg",
        dataIndex: "total_complete_receptions_yds",
        align: "center",
        render: (text, row) => (
          <div>
            {(
              Number(text) / Number(row.total_complete_receptions) || 0
            ).toFixed(2) || 0}
          </div>
        ),
      },
      {
        title: "Lng",
        dataIndex: "total_complete_receptions_lng",
        exportName: "PassingLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "QBTD",
        dataIndex: "total_qbtd",
        exportName: "PassingTD",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Int",
        dataIndex: "total_int",
        exportName: "PassingInt",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Fumbles
      </div>
    ),
    children: [
      {
        title: "Fb",
        dataIndex: "total_caused_fumble",
        exportName: "OffensiveFumbles",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "FbL",
        dataIndex: "total_fumble_lost",
        exportName: "OffensiveFumblesLost",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Totals
      </div>
    ),
    children: [
      {
        title: "2Pt",
        dataIndex: "total_two_part_conversion",
        align: "center",
        excludeFromExport: true,
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "TD",
        dataIndex: "total_wrtd",
        align: "center",
        excludeFromExport: true,
        render: (text, row) => (
          <div>
            {Number(text) + Number(row.total_rbtd) + Number(row.total_qbtd) ||
              0}
          </div>
        ),
      },
      {
        title: "Yds",
        dataIndex: "total_gn_carries_yds",
        align: "center",
        excludeFromExport: true,
        render: (text, row) => (
          <div>
            {Number(text) +
              Number(row.total_completions_carries_yds) +
              Number(row.total_complete_receptions_yds) || 0}
          </div>
        ),
      },
    ],
  },
];

const dColumns = [
  ...postCol,
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Tackles
      </div>
    ),
    children: [
      {
        title: "Solo",
        dataIndex: "total_tackles_solo",
        align: "center",
        exportName: "Tackles",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Ast",
        dataIndex: "total_tackles_ast",
        exportName: "Assists",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Tot",
        dataIndex: "total_tackles_ast",
        align: "center",
        exportName: "Assists",
        render: (text, row) => (
          <div>
            {Number(row.total_tackles_solo) +
              Number(row.total_tackles_ast_halved)}
          </div>
        ),
      },
      {
        title: "Missed Tackles",
        dataIndex: "total_missed_tackles",
        align: "center",
        exportName: "Tackles",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Net Tackles",
        dataIndex: "total_tackles_ast_halved",
        align: "center",
        exportName: "TotalTackles",
        render: (text, row) => (
          <div>
            {Number(row.total_tackles_solo) +
              Number(text) -
              Number(row.total_missed_tackles)}
          </div>
        ),
      },
      {
        title: "TFL",
        dataIndex: "total_tackles_tfl",
        align: "center",
        exportName: "TacklesForLoss",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_tackles_yds",
        align: "center",
        exportName: "Tackles",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Sacks
      </div>
    ),
    children: [
      {
        title: "Sak",
        dataIndex: "total_tackles_sak",
        align: "center",
        exportName: "Sacks",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_sack_yds",
        align: "center",
        exportName: "SacksYardsLost",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Press",
        dataIndex: "total_hurries",
        exportName: "QBHurries",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_hurries_yds",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Pass Defense
      </div>
    ),
    children: [
      {
        title: "Int",
        dataIndex: "total_int",
        exportName: "INTs",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_tackles_int_yds",
        align: "center",
        exportName: "INTYards",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "PBU",
        dataIndex: "total_pd",
        align: "center",
        exportName: "PassesDefensed",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "INT RET TD",
        dataIndex: "total_int_ret_td",
        align: "center",
        exportName: "PassesDefensed",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },

  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Fumbles
      </div>
    ),
    children: [
      {
        title: "Caus",
        dataIndex: "total_caused_fumble",
        align: "center",
        exportName: "CausedFumbles",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Rcd",
        dataIndex: "total_recovered_fumble",
        exportName: "FumbleRecoveries",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },

      {
        title: "Yds",
        dataIndex: "total_yards_after_recovery",
        exportName: "FumbleRecoveryYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "FUM RET TD",
        dataIndex: "total_fum_ret_td",
        align: "center",
        exportName: "PassesDefensed",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Turnovers Created
      </div>
    ),
    children: [
      {
        title: "Tot",
        dataIndex: "total_turnovers",
        excludeFromExport: true,
        align: "center",
        render: (text, row) => (
          <div>
            {Number(row.total_int) + Number(row.total_recovered_fumble)}
          </div>
        ),
      },
      {
        title: "Yds",
        dataIndex: "total_tackles_int_yds",
        excludeFromExport: true,
        align: "center",
        render: (text, row) => (
          <div>{Number(text) + Number(row.total_yards_after_recovery)}</div>
        ),
      },
      {
        title: "Safety",
        dataIndex: "total_safety",
        align: "center",
        excludeFromExport: true,
        render: (text) => <div>{text || 0}</div>,
      },

      {
        title: "TD",
        dataIndex: "total_td",
        excludeFromExport: true,
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
];

const stColumns = [
  ...postCol,
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Punt Returns
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_punt_return",
        align: "center",
        exportName: "PuntReturnNum",
        render: (text, row) => (
          <div>
            {Number(text) + Number(row.total_punt_return_fair_catch) || 0}
          </div>
        ),
      },
      {
        title: "Yds",
        dataIndex: "total_punt_return_yds",
        exportName: "PuntReturnYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg",
        dataIndex: "total_punt_return_yds",
        exportName: "PuntReturnNum",
        align: "center",
        render: (text, row) => (
          <div>
            {Number(row.total_punt_return) === 0
              ? 0
              : (Number(text) / Number(row.total_punt_return) || 0).toFixed(2)}
          </div>
        ),
      },
      {
        title: "Lng",
        dataIndex: "max_punt_return_yds",
        exportName: "PuntReturnLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Fair",
        dataIndex: "total_punt_return_fair_catch",
        exportName: "PuntReturnFairCatches",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "No Catch",
        dataIndex: "total_punt_return_fair_catch_touchback",
        exportName: "PuntReturnNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "TD",
        dataIndex: "total_punt_return_td",
        exportName: "PuntReturnNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Kickoff Returns
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_kickoff_return",
        exportName: "KickOffReturnNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Yds",
        dataIndex: "total_kickoff_return_yds",
        exportName: "KickOffReturnYards",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg",
        dataIndex: "total_kickoff_return_yds",
        exportName: "KickOffReturnNum",
        align: "center",
        render: (text, row) => (
          <div>
            {(Number(text) / Number(row.total_kickoff_return) || 0).toFixed(
              2
            ) || 0}
          </div>
        ),
      },
      {
        title: "Lng",
        dataIndex: "max_kickoff_return_yds",
        exportName: "KickOffReturnLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Fair",
        dataIndex: "total_kickoff_touchback_fair_catch",
        exportName: "KickOffReturnNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "TD",
        dataIndex: "total_kickoff_return_td",
        exportName: "KickOffReturnNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Tackles
      </div>
    ),
    children: [
      {
        title: "Solo",
        dataIndex: "total_tackles_solo",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Ast",
        dataIndex: "total_tackles_ast",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Tot",
        dataIndex: "total_tackles_ast",
        align: "center",
        render: (text, row) => (
          <div>
            {Number(row.total_tackles_solo) +
              Number(row.total_tackles_ast_halved)}
          </div>
        ),
      },
      {
        title: "Missed Tackles",
        dataIndex: "total_missed_tackles",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Net Tackles",
        dataIndex: "total_tackles_ast_halved",
        align: "center",
        render: (text, row) => (
          <div>
            {Number(row.total_tackles_solo) +
              Number(row.total_tackles_ast_halved) -
              Number(row.total_missed_tackles)}
          </div>
        ),
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        ST Defense
      </div>
    ),
    children: [
      {
        title: "Punt Block",
        dataIndex: "total_punt_blocks",
        exportName: "BlockedPunts",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "FG Block",
        dataIndex: "total_fg_blocks",
        exportName: "BlockedFG",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Caused Fumble",
        dataIndex: "total_caused_fumble",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Fumble Recovered">
            <span>F-Rcv</span>
          </Tooltip>
        ),
        dataIndex: "total_recovered_fumble",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "YAR",
        dataIndex: "total_yards_after_recovery",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: (
          <Tooltip title="Onside Recovered">
            <span>O-Rcv</span>
          </Tooltip>
        ),
        dataIndex: "total_onsidekick_recovered",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },

      {
        title: "TD",
        dataIndex: "total_st_td",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Fumbles
      </div>
    ),
    children: [
      {
        title: "Fb",
        dataIndex: "total_fumble",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "FbL",
        dataIndex: "total_fumble_lost",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Totals
      </div>
    ),
    children: [
      {
        title: "2Pt",
        dataIndex: "total_two_part_conversion",
        align: "center",
        excludeFromExport: true,
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "TD",
        dataIndex: "total_st_td",
        align: "center",
        render: (text, row) => (
          <div>
            {Number(text) +
              Number(row.total_punt_return_td) +
              Number(row.total_kickoff_return_td) || 0}
          </div>
        ),
      },
      {
        title: "Yds",
        dataIndex: "total_punt_return_yds",
        align: "center",
        render: (text, row) => (
          <div>{Number(text) + Number(row.total_kickoff_return_yds) || 0}</div>
        ),
      },
    ],
  },
];

const kickerColumns = [
  ...postCol,
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Field Goal
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_field_goals",
        align: "center",
        exportName: "FGAttempted",
        render: (text, row) => (
          <div>
            {Number(text) +
              Number(row.total_block_field_goals) +
              Number(row.total_missed_field_goals) || 0}
          </div>
        ),
      },
      {
        title: "FGM",
        dataIndex: "total_field_goals",
        align: "center",
        exportName: "FGMade",
        render: (text, row) => <div>{text || 0}</div>,
      },
      {
        title: "FGM %",
        dataIndex: "total_field_goals",
        exportName: "",
        align: "center",
        render: (text, row) => (
          <div>
            {(
              (Number(text) /
                (Number(text) +
                  Number(row.total_block_field_goals) +
                  Number(row.total_missed_field_goals))) *
                100 || 0
            ).toFixed(2) || 0}{" "}
            %
          </div>
        ),
      },
      {
        title: "Avg Made",
        dataIndex: "avg_field_goal_distance",
        exportName: "",
        align: "center",
        render: (text, row) => <div>{Number(text)?.toFixed(2) || 0}</div>,
      },
      {
        title: "Long",
        dataIndex: "max_field_goal_distance",
        exportName: "FGLong",
        align: "center",
        render: (text, row) => <div>{text || 0}</div>,
      },
      {
        title: "Blocked",
        dataIndex: "total_block_field_goals",
        align: "center",
        exportName: "",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Missed",
        dataIndex: "total_missed_field_goals",
        exportName: "",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg Missed",
        dataIndex: "avg_missed_field_goal_distance",
        exportName: "",
        align: "center",
        render: (text, row) => <div>{Number(text).toFixed(2) || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Punt
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_punts",
        exportName: "PuntNum",
        align: "center",
        render: (text, row) => (
          <div>{Number(text) + Number(row.total_blocked_punts) || 0}</div>
        ),
      },
      {
        title: "Blocked",
        dataIndex: "total_blocked_punts",
        exportName: "PuntNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Avg Dis",
        dataIndex: "total_punts_distance",
        exportName: "PuntNum",
        align: "center",
        render: (text, row) => (
          <div>{(Number(text) / Number(row.total_punts) || 0)?.toFixed(2)}</div>
        ),
      },
      {
        title: "Long",
        dataIndex: "max_punts_distance",
        exportName: "PuntLong",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Fair C",
        dataIndex: "total_fair_c",
        exportName: "PuntNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Ins 20",
        dataIndex: "total_ins_20",
        exportName: "PuntInside20",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        PAT
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_block_pat",
        align: "center",
        exportName: "PATKickingMade",
        render: (text, row) => (
          <div>
            {Number(row.total_pat) +
              Number(row.total_missed_pat) +
              Number(text) || 0}
          </div>
        ),
      },
      {
        title: "Missed",
        dataIndex: "total_missed_pat",
        exportName: "PATKickingMade",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Blocked",
        dataIndex: "total_block_pat",
        exportName: "PATKickingMade",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Made",
        dataIndex: "total_pat",
        exportName: "PATKickingMade",
        exportName: "",
        align: "center",
        render: (text, row) => <div>{Number(text) || 0}</div>,
      },
    ],
  },
  // to do in ttl
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Kickoff
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_kickoff",
        exportName: "KickoffNum",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },

      {
        title: "Tch B",
        dataIndex: "total_kickoff_touchback",
        exportName: "KickoffTouchbacks",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Fair C",
        dataIndex: "total_kickoff_fair_catch",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
        exportName: "KickoffNum",
      },
    ],
  },
  {
    title: (
      <div
        className="w-full text-center bg-black text-[#ffffff] h-[90px] xl:h-[80px] flex items-center justify-center"
        style={{ borderRight: "1px solid #F3F5F7" }}
      >
        Onside Kicks
      </div>
    ),
    children: [
      {
        title: "#",
        dataIndex: "total_onsidekick",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "Rcv",
        dataIndex: "total_recovered_fumble",
        align: "center",
        render: (text) => <div>{text || 0}</div>,
      },
      {
        title: "No Rcv",
        dataIndex: "total_onsidekick",
        align: "center",
        render: (text, row) => (
          <div>{Number(text) - Number(row.total_recovered_fumble) || 0}</div>
        ),
      },
    ],
  },
];

export { oColumns, dColumns, kickerColumns, stColumns };