import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
	Table,
	Button,
	Select,
	Pagination,
	InputNumber,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import TableData from "./table-data.js";
import "./coaches.css";

const Option = Select.Option;

const columns = [
	{
		title: 'First Name',
		dataIndex: 'first_name',
	},
	{
		title: 'Last Name',
		dataIndex: 'last_name',
	},
	{
		title: 'Email',
		dataIndex: 'email',
	},
	{
		title: 'Location',
		dataIndex: 'location',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		width: '20%',
		align: 'center'
	},
];

const CoachesList = () => {
		const render = () => {

		return (
			<div className="HomePageContainer">
				<div className="container">
					<div className="full-width">
						<div className="TableContainer">
							<Table bordered columns={columns} dataSource={TableData} size="middle" pagination={false} />
						</div>
						<div className="PaginationContainer">
							<span className="PaginationContainerInner">
								<span className="PaginationLabel">Total 15 items</span>
								<Pagination defaultCurrent={1} total={500} />
							</span>
							<span className="PaginationContainerInner">
								<span className="PaginationLabel ml-3">Go to</span>
								<InputNumber min={1} max={10} initialvalues={3} style={{ width: '70px' }} />
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return render();
};

export default CoachesList;