import React, { useEffect, useState } from "react";
import { Table, Input, Tooltip, Select, Checkbox, message } from "antd";
import "./wardbord.css";
import pencil from "../../assets/img/pencil.svg";
import pnImg from "../../assets/img/schedule/push_notification.svg";
import _ from "lodash";
import { connect } from "react-redux";
import PushNotificationContent from "../../components/Modals/PushNotificationModal";
import moment from "moment";

function WardboardScreen(props) {
  const keys = [
    "offensive_skills_of_the_week",
    "offensive_lineman_of_the_week",
    "defensive_skills_of_the_week",
    "defensive_lineman_of_the_week",
    "special_teams_of_the_week",
    "hit_man_of_the_week",
  ];

  const defaultAwardTitles = {
    offensive_skills_of_the_week: "Offensive Skills Player of the week",
    offensive_lineman_of_the_week: "Offensive Lineman of the week",
    defensive_skills_of_the_week: "Defensive Skills Player of the week",
    defensive_lineman_of_the_week: "Defensive Lineman of the week",
    special_teams_of_the_week: "Special Teams Player of the week",
    hit_man_of_the_week: "Hit Man of the week",
  };

  const getParsedTitles = (titles) => {
    if (!titles) return [];
    try {
      return JSON.parse(titles);
    } catch (error) {
      return [];
    }
  };

  let awardTitlesArray = getParsedTitles(
    props?.schoolDetails?.details?.wardbord_column_titles || "[]"
  );

  let initialAwardTitles = keys.reduce((acc, key, index) => {
    acc[key] = awardTitlesArray[index] || defaultAwardTitles[key];
    return acc;
  }, {});

  useEffect(() => {
    console.log("props?.schoolDetails reloads");

    awardTitlesArray = getParsedTitles(
      props?.schoolDetails?.details?.wardbord_column_titles || "[]"
    );

    initialAwardTitles = keys.reduce((acc, key, index) => {
      acc[key] = awardTitlesArray[index] || defaultAwardTitles[key];
      return acc;
    }, {});

    setColumnTitles(initialAwardTitles);
  }, [props?.schoolDetails]);

  const [notification, setNotification] = useState(false);
  const [selectedGame, setSelectedGame] = useState(false);
  const [allAthletesList, setAllAthletesList] = useState([]);
  const [rawMvpList, setRawMvpList] = useState([]);
  const [mvpList, setMvpList] = useState({});
  const [gamesList, setGamesList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [currentSeasonID, setCurrentSeasonID] = useState(null);
  const [columnTitles, setColumnTitles] = useState(initialAwardTitles);
  const [showColumnTitles, setShowColumnTitles] = useState(true);

  const handleHeaderTextChange = (e, name) => {
    const _columnTitles = columnTitles;
    _columnTitles[name] = e.target.value;
    setColumnTitles(_columnTitles);
  };

  const fetchSchoolViewData = () => {
    props.getSchoolViewData({
      params: { id: props?.schoolDetails?.details?.id },
      callback: (res, responseData) => {
        if (res === true) {
        }
      },
    });
  };

  const openNotification = (game) => {
    const allData = sortSkills(game.game_id);
    console.log("game.key", game);

    console.log("allData", allData);
    setMvpList(allData);
    setSelectedGame(game);
    setNotification(true);
  };

  const handleGamesGrading = (game) => {
    console.log("=-=====game-=-===", game);
    props.approveGameGrading({
      body: {
        status: 1,
        id: _.get(props, "coachProfile.school.id"),
        title: "Wardbord Notifications",
        body: `Check it out, Player of the Week winners have been awarded!`,
      },
      callback: (res, data) => {
        if (res) {
          console.log("data---===", data);
          // notification.success({
          //   message: 'Success',
          //   description: _.get(data, 'message'),
          // });
          // setShowUpdateModal(false);
        }
      },
    });
  };
  const closeNotification = () => {
    setNotification(false);
  };

  const customColumn = [
    {
      title: "Week #",
      dataIndex: "week",
      align: "center",
      width: "7%",
      key: "week",
    },

    {
      title: "Team",
      dataIndex: "title",
      align: "center",
      width: "9%",
      key: "title",
      render: (text, item) => (
        <div className="text-[11px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#A40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: showColumnTitles ? (
        <div className="cursor-pointer w-full">
          {columnTitles["offensive_skills_of_the_week"]}
        </div>
      ) : (
        <Input
          defaultValue={columnTitles["offensive_skills_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) =>
            handleHeaderTextChange(e, "offensive_skills_of_the_week")
          }
        />
      ),
      dataIndex: "game_id",
      align: "center",
      width: "12%",
      key: "offensive_skills_of_the_week",
      render: (text, item) => {
        const rowList = sortMvps(text, "Offensive Skills Player of the week");
        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name + " "
        );
      },
    },
    {
      title: showColumnTitles ? (
        <div>{columnTitles["offensive_lineman_of_the_week"]}</div>
      ) : (
        <Input
          defaultValue={columnTitles["offensive_lineman_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) =>
            handleHeaderTextChange(e, "offensive_lineman_of_the_week")
          }
        />
      ),
      dataIndex: "game_id",
      align: "center",
      width: "12%",
      key: "offensive_lineman_of_the_week",
      render: (text, item) => {
        const rowList = sortMvps(text, "Offensive Lineman of the week");

        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name
        );
      },
    },
    {
      title: showColumnTitles ? (
        <div>{columnTitles["defensive_skills_of_the_week"]}</div>
      ) : (
        <Input
          defaultValue={columnTitles["defensive_skills_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) =>
            handleHeaderTextChange(e, "defensive_skills_of_the_week")
          }
        />
      ),
      dataIndex: "game_id",
      align: "center",
      key: "defensive_skills_of_the_week",
      width: "11%",
      render: (text, item) => {
        const rowList = sortMvps(text, "Defensive Skills Player of the week");

        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name
        );
      },
    },
    {
      title: showColumnTitles ? (
        <div>{columnTitles["defensive_lineman_of_the_week"]}</div>
      ) : (
        <Input
          defaultValue={columnTitles["defensive_lineman_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) =>
            handleHeaderTextChange(e, "defensive_lineman_of_the_week")
          }
        />
      ),
      dataIndex: "id",
      align: "center",
      key: "defensive_lineman_of_the_week",
      width: "11%",
      render: (text, item) => {
        const rowList = sortMvps(text, "Defensive Lineman of the week");

        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name
        );
      },
    },
    {
      title: showColumnTitles ? (
        <div>{columnTitles["special_teams_of_the_week"]}</div>
      ) : (
        <Input
          defaultValue={columnTitles["special_teams_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) =>
            handleHeaderTextChange(e, "special_teams_of_the_week")
          }
        />
      ),
      dataIndex: "game_id",
      align: "center",
      key: "special_teams_of_the_week",
      width: "11%",
      render: (text, item) => {
        const rowList = sortMvps(text, "Special Teams Player of the week");

        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name
        );
      },
    },
    {
      title: showColumnTitles ? (
        <div>{columnTitles["hit_man_of_the_week"]}</div>
      ) : (
        <Input
          defaultValue={columnTitles["hit_man_of_the_week"]}
          className="text-[9px] w-full"
          onChange={(e) => handleHeaderTextChange(e, "hit_man_of_the_week")}
        />
      ),
      dataIndex: "game_id",
      align: "center",
      key: "hit_man_of_the_week",
      width: "12%",
      render: (text, item) => {
        const rowList = sortMvps(text, "Hit Man of the week");

        return rowList.map(
          (item) => item.athlete.first_name + " " + item.athlete.last_name
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      align: "center",
      key: "created_at",
      render: (text, item) => {
        return (
          <div className="wardbordscreen-table-action w-[98%] mx-auto">
            <Tooltip
              title="Edit"
              onClick={() => {
                openNotification(item);
                console.log("open notification", item);
              }}
            >
              <div className="w-[90%] mr-1 mb-1 cursor-pointer hover:bg-[#F3F5F7] flex items-center justify-center hover:rounded-md">
                <img src={pencil} className="w-[15px] " />
              </div>
            </Tooltip>

            {/* <Tooltip title="Push Notification">
              <Button
                onClick={() =>
                  alert("Notification Sent to players Mobile Apps")
                }
                icon={<img src={pnImg} className="w-[50px]" />}
              />
            </Tooltip> */}
          </div>
        );
      },
    },
  ];

  const sortMvps = (game, title) => {
    const list = rawMvpList?.filter((item) => {
      if (item.game == game) {
        if (item.title == title) return item;
      }
    });
    // console.log("list", list);
    return list;
  };

  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      loadAllAthletesList();
    }
  }, []);

  const loadAllAthletesList = () => {
    props.list({
      params: {
        limit: 1000,
        page: 1,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res == true && _.size(_.get(data, "data.items", [])) > 0) {
          setAllAthletesList(data.data.items);
        }
      },
    });
  };

  const fetchMvplist = () => {
    props.MvpList({
      params: {
        expand: "athlete",
        season: currentSeasonID,
        limit: 1000,
        school: _.get(props, "coachProfile.school.id"),
      },

      callback: (res, data) => {
        if (res === true) {
          setRawMvpList(data?.data?.items);
        }
      },
    });
  };

  const sortSkills = (game) => {
    const eachSkillsInaweek = rawMvpList?.filter((item) => item.game == game);
    console.log("eachSkillsInaweek", eachSkillsInaweek);
    const checkExists = (description) => {
      const filteredItems = eachSkillsInaweek?.filter(
        (item) => item.description.toLowerCase() == description.toLowerCase()
      );

      return filteredItems.length > 0 ? filteredItems : null;
    };

    let defaultToBeMapped = {
      Special_Teams_Player_of_the_week:
        checkExists("Special Teams Player of the week") || [],
      Hit_Man_of_the_week:
        checkExists("Hit Man of the week" || "hit man award") || [],
      Defensive_Lineman_of_the_week:
        checkExists("Defensive Lineman of the week") || [],
      Offensive_Skills_Player_of_the_week:
        checkExists("Offensive Skills Player of the week") || [],
      Defensive_Skills_Player_of_the_week:
        checkExists("Defensive Skills Player of the week") || [],
      Offensive_Lineman_of_the_week:
        checkExists("Offensive Lineman of the week") || [],
    };

    return defaultToBeMapped;
  };

  // const loadSeasonsList = () => {
  //   const list = JSON.parse(sessionStorage.getItem("seasonsList"));
  //   if (list) {
  //     loadGamesList(list[0].id);
  //   }
  //   props.seasonsList({
  //     params: { sort: "id.desc", limit: 1000 },
  //     callback: (res, data) => {
  //       if (res === true) {
  //         const result = data?.data?.items;
  //         sessionStorage.setItem("seasonsList", JSON.stringify(result));
  //         setSeasonsList(result);
  //         loadGamesList(result[0]?.id);
  //       }
  //     },
  //   });
  // };
  const loadGamesList = (seasonID = props?.seasonID) => {
    setCurrentSeasonID(seasonID);
    try {
      const season = seasonID;
      let params = {
        limit: 995,
        home_team: _.get(props, "coachProfile.school.id"),
        sort: "week.asc",
        season,
      };
      const list = JSON.parse(sessionStorage.getItem(`${seasonID}-gamesList`));
      if (list) {
        setAllGamesList(list);
      }
      props.gamesList({
        params: params,
        callback: (res, data) => {
          if (res === true) {
            sessionStorage.setItem(
              `${seasonID}-gamesList`,
              JSON.stringify(_.get(data, "data.items", []))
            );
            setAllGamesList(_.get(data, "data.items", []));
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setAllGamesList = (data) => {
    let weekFormat = "Week";
    let list = [];
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          id: parseInt(value.id),
          title: _.startCase(_.get(value, "title")),
          season: _.get(value, "season"),
          created_at: _.get(value, "start_time")
            ? moment(`${_.get(value, "start_time")}`).format("MM/DD/YYYY")
            : "---",
          week: weekFormat.concat(" ", _.get(value, "week")),
          start_time: _.get(value, "start_time")
            ? moment(`${_.get(value, "start_time")}`).format("h:mm A")
            : "---",

          duration: _.get(value, "duration"),
          game_id: _.get(value, "id"),
          opponent_logo: value.opponent_logo,
          team: props.coachProfile?.school?.name,
          type: value.type,
          feedback_type: value.feedback_type,
        });
      });
      console.log("list", list);
      setGamesList(list);
    } else {
      setGamesList([]);
    }
  };

  const createAnMvp = (title, week, athlete, game) => {
    props.CreateMvp({
      body: {
        athlete,
        coach: _.get(props, "coachProfile.id"),
        school: _.get(props, "coachProfile.school.id"),
        game,
        title,
        description: title,
        season: currentSeasonID,
        week,
      },
      callback: (res, data) => {
        if (res === true) {
          fetchMvplist();
          message.success("Mvp created successfully");
        }
      },
    });
  };

  const removeAnMvp = (id) => {
    props.RemoveMvp({
      params: {
        status: "deleted",
        id,
      },
      body: {
        status: 0,
      },
      callback: (res, data) => {
        if (res === true) {
          fetchMvplist();
        }
      },
    });
  };

  useEffect(() => {
    fetchMvplist();
  }, [currentSeasonID, notification]);

  // useEffect(() => {
  //   loadSeasonsList();
  // }, []);

  useEffect(() => {
    console.log("props.games", props.games);
  }, [props.games]);

  useEffect(() => {
    loadGamesList(props?.seasonID);
  }, [props?.seasonID]);

  useEffect(() => {
    console.log(
      "gamelist",
      gamesList?.filter((game) => props?.games.includes(game))
    );
    console.log("props.games", props.games);
  }, [props.games]);

  const Option = Select.Option;

  return (
    <div className="flex w-[98%] py-2 font-inter">
      <div className="flex flex-col w-[100%]">
        <div className="w-[100%] mt-[0px]">
          <div className="flex w-[100%] flex-col">
            <div className="w-[100%] wardbordscreentablediv  ">
              {/* <small onClick={() => {
fetchSchoolViewData()
              }}>Refresh</small> */}
              <Table
                pagination={false}
                className="w-[100%]"
                columns={customColumn}
                dataSource={
                  props?.games?.length > 1
                    ? gamesList
                    : gamesList?.filter((game) => props?.games.includes(game))
                }
                key={1}
                bordered
                loading={props?.pageLoading}
              />
            </div>
          </div>
        </div>
      </div>
      {notification ? (
        <PushNotificationContent
          open={notification}
          allAthletesList={allAthletesList}
          buttonText="Save"
          onCancel={closeNotification}
          onOk={closeNotification}
          selectedGame={selectedGame}
          createAnMvp={createAnMvp}
          removeAnMvp={removeAnMvp}
          mvpList={mvpList}
          handleGamesGrading={handleGamesGrading}
          columnTitles={columnTitles}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  MvpList: (details) => dispatch(global.redux.action.mvps.list(details)),
  MvpView: (details) => dispatch(global.redux.action.mvps.view(details)),
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  RemoveMvp: (details) =>
    dispatch(global.redux.action.mvps.changeStatus(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  approveGameGrading: (details) =>
    dispatch(global.redux.action.grades.approve(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WardboardScreen);
