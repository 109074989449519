import UserImg from "../../../assets/img/Profile-Pic.png";
import UserImg2 from "../../../assets/img/pp2.png";
import UserImg3 from "../../../assets/img/pp3.png";
import UserImg4 from "../../../assets/img/pp4.png";
import UserImg5 from "../../../assets/img/pp5.png";
import UserImg6 from "../../../assets/img/pp6.png";
import UserImg7 from "../../../assets/img/pp7.png";
import UserImg8 from "../../../assets/img/pp8.png";
import UserImg9 from "../../../assets/img/pp9.png";
export const UserData = {
    userInfo : [
        { name1: 'Kenneth',name2:'Henderson',club:'OL, WR/TE',soph:'05',image: UserImg},
        { name1: 'Clyde',name2:'Andrews',club:'WR/TE, LB',soph:'12',image:UserImg2},
        { name1: 'Alan',name2:'Harper',club:'QB, DB',soph:'10',image:UserImg3},
        { name1: 'Rafael',name2:'Garcia',club:'OL, DL',soph:'02',image:UserImg4},
        { name1: 'Adam',name2:'Brewer',club:'OL, QB',soph:'56',image:UserImg5},
        { name1: 'Stanley',name2:'Larson',club:'WR/TE, DB',soph:'38',image:UserImg6},
        { name1: 'Michael',name2:'Banks',club:'LB, DB',soph:'22',image:UserImg7},
        { name1: 'Leo',name2:'Weaver',club:'RB, DB',soph:'11',image:UserImg8},
        { name1: 'Arron',name2:'Garza',club:'OL, QB',soph:'87',image:UserImg9},
    ]
}