import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select, Tooltip, Checkbox } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import puzzle from "../../../../assets/img/bl.svg";
import { ReceptionRadioButtons } from "../buttons/reception-button.js";
import CustomSwitchButtons from '../CustomSwitchButtons';
import { Link, useNavigate } from "react-router-dom";
import Title from './components/title.js'

function KickerModal({
  state,
  dispatch,
  close,
  
  update,
  item,
  school_id,
  loadAllPlayerList,
  schoolCustomStats,
  getStats,
  play,
  handleUpdatePlay,
  updateInputGrade,
  updateGrade,
}) {
  let history = useNavigate();
  const [modalWidth, setModalWidth] = useState(450); 
  const [customStats, setCustomStats] = useState([]);
  const [customStatValues, setCustomStatValues] = useState({});
const [ storage, setStorage ] = useState({})

const handleSaveCustomStat = (stat, value) => {

  const _customStatValues = {...customStatValues}
  _customStatValues[stat.label] =  value;
  setCustomStatValues(_customStatValues)
 
updateGrade('custom_stats', JSON.stringify(_customStatValues))

}
useEffect(() => {
    
  setStorage(item)
  
}, [item]); 
const updateStorage = (path, value) =>{ 
  const store = storage;
  store[path] = value;

  setStorage(store)
  updateGrade(path, value)
}


  const handleCStats = () => {
    setCustomStatValues(JSON.parse(item.custom_stats || '{}')) 
    const _customStats = schoolCustomStats?.filter(stat => stat.odk == 'k') 
    setCustomStats(_customStats || []);
  }
  useEffect(handleCStats, [item.custom_stats, schoolCustomStats]); 
  useEffect(handleCStats, []); 

 
  useEffect(() => {
    if (window.innerWidth >= 768) {
      setModalWidth(450);
    } else {
      setModalWidth(345);
    }
  }, [window.innerWidth]);

  return state.special_teams && (
    <Modal
      open={state.special_teams}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
    
      title={
        <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
          <span className=" font-bold">ST Stat</span>
          <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
          <span className="font-[400]">
            {item?.first_name} {item?.last_name}
          </span>
        </div>
      }
    >
       <div
        style={{ border: "1px solid #F3F5F7" }}
        className="mt-4 text-[12px] text-[#0F1A24]"
      >
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Caused Fumble</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
          <Switch defaultChecked={Number(item.caused_fumble)} onChange={(e) =>  updateGrade('caused_fumble', e ? 1 : 0)} />{" "}
           </div>
          
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Punt Block</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
          
          <Switch defaultChecked={Number(item.punt_blocks)} onChange={(e) =>  updateGrade('punt_blocks', e ? 1 : 0)} />{" "}
        
          </div>
       
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>FG Block</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
           
          <Switch defaultChecked={Number(item.fg_blocks)} onChange={(e) =>  updateGrade('fg_blocks', e ? 1 : 0)} />{" "}
       
          </div>
       
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Fumble/Block Recovery</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
         
          <Switch defaultChecked={Number(item.recovered_fumble)} onChange={(e) =>  updateStorage('recovered_fumble', e ? 1 : 0)} />{" "}
        
          </div>
       
        </div>
        
      { storage.recovered_fumble ?   <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Recovery</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
          <Input defaultValue={item.yards_after_recovery} onChange={(e)=>updateInputGrade('yards_after_recovery',e )} size="small" className="w-[40%] p-1" />{" "}
      
          </div>
       
        </div> : ''}

        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>
              TD
            </span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
      
          <Switch defaultChecked={Number(item.st_td)} onChange={(e) =>  updateGrade('st_td', e ? 1 : 0)} />{" "}
        
          </div>
       
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[40%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>2 Pt. Conversion</span>
          </div>{" "}
          <div className="p-3 w-[60%] turn-over actor flex items-center justify-center">
          <Switch defaultChecked={item?.two_part_conversion} onChange={(e) => updateGrade('two_part_conversion', e ? 1 : 0)} />{" "}
        
          </div>
        </div>
        <div className="mt-4 px-2">
            <span className=" font-inter text-[14px] font-bold">
              Custom Stats{" "}
            </span>
            <div
              style={{ border: "1px solid #F3F5F7" }}
              className="mt-4 text-[12px] text-[#0F1A24] mb-2"
            >

              {
                  customStats?.map((stat, index) => {

                    return(
              <div
                className="flex items-center"
                style={{ borderBottom: "1px solid #F3F5F7" }}
              >
                <div
                  className="w-[70%] p-3 font-bold"
                  style={{ borderRight: "1px solid #F3F5F7" }}
                >
                  <span>{stat.label}</span>
                </div>{" "}
                <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
                 {console.log('customStatValues-----', item.custom_stats, customStatValues)}
                 { stat.input_type == "numeric" ?
                 
   (<Input defaultValue={JSON.parse(item.custom_stats || '{}')[stat.label]} onChange={(e)=>handleSaveCustomStat(stat,e.target.value)} size="small" className="w-[40%] p-1" />)
   :
   (<Switch
    defaultChecked={JSON.parse(item.custom_stats || '{}')[stat.label]}
    onChange={(e) => handleSaveCustomStat(stat, e ? 1 : 0)}
  />)}
        
                </div>
              </div>
                    )

                  })
              }

             
            </div>
            <span className="text-[#8698A9]">
              Edit custom stats in your{" "}
              <span onClick={() => history("/add-stats")} className="underline">Admin Settings</span>
            </span>
          </div>
       <br />
       <br />
     
        <div className="flex items-center justify-between mt-2">
          
          <button onClick={close} className="flex w-[30%] items-center focus:outline-0 justify-center bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default KickerModal;
