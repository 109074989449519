import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom'
import { Table, Button, Select, Pagination, InputNumber, Collapse } from 'antd'
import {
  UserOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import './coaches.css'
import MvpsViewModalComponent from '../../coach/mvps/mvps-view'

const Option = Select.Option
const { Panel } = Collapse

const CoachEvaluation = props => {
  const [TableData, setTableData] = useState('')
  const [showModal, setshowModal] = useState(false)
  const [gamesList, setGamesList] = useState([])
  const [gamesListMeta, setGamesListMeta] = useState({})
  const [gamesListLoading, setGamesListLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [seasonsList, setSeasonsList] = useState([])
  const [currentSeasonID, setCurrentSeasonID] = useState(null)

  let history = useNavigate()

  const showModalView = item => {
    setshowModal(true)
    setTableData(item)
  }

  const columns = [
    {
      title: 'Game',
      dataIndex: 'title',
      align: 'center',
      key: 'title'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      align: 'center',
      key: 'created_at'
    },
    {
      title: 'Week',
      dataIndex: 'week',
      align: 'center',
      key: 'week'
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      align: 'center',
      key: 'start_time'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
      align: 'center',
      key: 'action',
      render: (text, item) => (
        <div className='games-list-action-btns'>
          <Button
            className='TableActionBtn'
            onClick={() =>
              history(
                `/create-mvps?season=${currentSeasonID}&game=${_.get(
                  item,
                  'game_id'
                )}`
              )
            }
          >
            Create MVP
          </Button>
          {/* <Button className="TableActionBtn ml-2 edit" onClick={() =>history(`/mvps-view?game=${_.get(item,'game_id')}`)}>View</Button> */}
        </div>
      )
    }
  ]

  const data = [
    {
      key: 1,
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      description:
        'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.'
    },
    {
      key: 2,
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      description:
        'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.'
    },
    {
      key: 3,
      name: 'Not Expandable',
      age: 29,
      address: 'Jiangsu No. 1 Lake Park',
      description: 'This not expandable'
    },
    {
      key: 4,
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      description:
        'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.'
    }
  ]

  useEffect(() => {
    if (_.get(props, 'coachProfile.id')) {
      loadGamesList()
    }
  }, [])

  useEffect(() => {
    loadSeasonsList()
  }, [])

  const loadSeasonsList = () => {
    let params = { limit: 100 }
    props.seasonsList({
      params,
      callback: (res, data) => {
        if (res === true) {
          setSeasonsList(_.get(data, 'data.items', []))
        }
      }
    })
  }

  const loadGamesList = seasonID => {
    let params = {
      limit: 999999,
      home_team: _.get(props, 'coachProfile.school.id'),
      sort: 'week.asc,created_at.desc',
      season: seasonID
    }
    setGamesListLoading(true)
    props.gamesList({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          setAllGamesList(_.get(data, 'data.items', []))
        }
        setGamesListLoading(false)
      }
    })
  }

  const setAllGamesList = data => {
    let weekFormat = 'Week'
    let list = []
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          title: _.startCase(_.get(value, 'title')),
          season: _.get(value, 'season'),
          created_at: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('MM/DD/YYYY')
            : '---',
          week: weekFormat.concat(' ', _.get(value, 'week')),
          start_time: _.get(value, 'start_time')
            ? moment(`${_.get(value, 'start_time')}`).format('h:mm A')
            : '---',
          end_time: moment(
            `${moment().format('YYYY-MM-DD')} ${_.get(value, 'end_time')}`
          ).format('h:mm A'),
          duration: _.get(value, 'duration'),
          game_id: _.get(value, 'id')
        })
      })

      setGamesList(list)
    } else {
      setGamesList([])
    }
  }

  const hideModal = () => {
    setshowModal(false)
  }

  const onChangePage = (page, size) => {
    setPage(page)
    loadGamesList(size, page)
  }

  const getDropList = () => {
    const year = new Date().getFullYear()
    const start_year = year - 2
    const end_year = year + 8
    const start_year_split = parseInt(`${start_year}`.slice(2))

    return Array.from(new Array(30), (v, i) => (
      <Option
        key={i}
        value={`${start_year + i}-${start_year_split + i + 1}`}
      >{`${start_year + i}-${start_year_split + i + 1}`}</Option>
    ))
  }

  function onChange (value) {
    console.log(`selected ${value}`)
  }

  const onchangeFilter = (value, e) => {
    console.log('date month', value, e)
  }

  const onselectFilter = e => {
    loadGamesList(10, page, e)
  }

  const onCollapseChange = value => {
    if (!_.isEmpty(value)) {
      loadGamesList(value)
      setCurrentSeasonID(value)
    } else {
      setAllGamesList([])
    }
  }

  const render = () => {
    return (
      <div className='HomePageContainer evaluvationPage'>
        <div className='container'>
          <div className='pageTitle'>Wardbord</div>
          <div className='full-width'>
            <Collapse accordion onChange={value => onCollapseChange(value)}>
              {_.map(seasonsList, seasonData => (
                <Panel
                  header={_.get(seasonData, 'title')}
                  key={_.get(seasonData, 'id')}
                >
                  <div className='TableContainer'>
                    <Table
                      bordered
                      key={_.get(seasonData, 'id')}
                      loading={gamesListLoading}
                      columns={columns}
                      dataSource={gamesList}
                      size='middle'
                      pagination={false}
                      expandable={{
                        expandedRowRender: record => {
                          console.log('record_log', record)
                          return (
                            <p
                              style={{
                                margin: 0
                              }}
                            >
                              <div className='weeklyAwards'>
                                <MvpsViewModalComponent
                                  gameId={_.get(record, 'game_id')}
                                />
                              </div>
                            </p>
                          )
                        },
                        rowExpandable: record =>
                          record.name !== 'Not Expandable'
                      }}
                    />
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = state => {
  return {
    coachProfile: _.get(state, 'app.account.profile.data.details')
  }
}

const mapDispatchToProps = dispatch => ({
  gamesList: details => dispatch(global.redux.action.games.list(details)),
  seasonsList: details => dispatch(global.redux.action.seasons.list(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoachEvaluation)
