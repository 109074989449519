import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg"; 
import { set } from "lodash";
import Title from './components/title.js'

const { Option } = Select;
function TurnoverModal({ state, dispatch, close, update, item, school_id, loadAllPlayerList, updateInputGrade, updateGrade  }) {
  const [modalWidth, setModalWidth] = useState(425);
    const [athletesList, setAthletesList] = useState([]); 
  const [athletesListLoading, setAthletesListLoading] = useState(true); 
 

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(352);
    } else {
      setModalWidth(425);
    }
  }, [window.innerWidth]);

  useEffect(() => {  
      loadAthletesList(555, 1);
  }, []);
  
  const handleUpdatePlayers = (players) => updateGrade('fumble_recovered', JSON.stringify(players))
 

  const loadAthletesList = (limit = 25, page = 1, positions = null) => {
    setAthletesListLoading(true);
    const params = {
      limit,
      page,
      expand: "athlete",

      athlete_profile_school: school_id,
    };
 
    loadAllPlayerList({
      params,

      callback: (res, data) => {
        if (res) {
           
          setAthletesList(data?.data?.items?.sort((a, b) => a?.athlete?.last_name?.localeCompare(b?.athlete?.last_name)));
       
        }
        setAthletesListLoading(false);
      },
    });
  };

  return state.turnover &&  (
    <Modal
      open={state.turnover}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
      title={
        <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
          <span className=" font-bold">Turnover Created</span>
          <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
          <span className="font-[400]">{item?.first_name} {item?.last_name}</span>
        </div>
      }
    >
      <div
        style={{ border: "1px solid #F3F5F7" }}
        className="mt-4 text-[12px] text-[#0F1A24]"
      >
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Caused Fumble</span>
          </div>{" "}
          <div className="p-3 w-[70%] turn-over actor flex items-center justify-center">
            <Switch defaultChecked={item.caused_fumble} onChange={(e) => updateGrade('caused_fumble', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Fumble Recovered</span>
          </div>{" "}
          <div className="p-3 w-[70%] flex items-center justify-center">
            <div
              className="w-[90%] p-1 flex items-center justify-center rounded-[4px]"
              style={{ border: "1px solid #EAEDF0" }}
            >

              { athletesListLoading ? 'Loading player list...' :
             <Select
                mode="multiple"
                style={{ width: "100%" }}
                bordered={false}
                defaultValue={JSON.parse(item.fumble_recovered ? item.fumble_recovered : '[]')}
                
               
                
                // onChange={handlePlayerSelection}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                showSearch
                className="multi"
                onChange={handleUpdatePlayers}
              >
                {athletesList?.map((player) => {
                  return (
                    <Option
                      key={player?.athlete?.id}
                      value={player?.athlete?.id}
                      label={`${player?.athlete?.first_name} ${player?.athlete?.last_name}`}
                    >
                      <div
                       
                      >
                        {player?.athlete?.first_name}{" "}
                        {player?.athlete?.last_name} (
                        {player?.positions?.toString()})
                      </div>
                    </Option>
                  );
                })}
              </Select> }

            </div>
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Recovery</span>
          </div>{" "}
          <div className="p-3 w-[70%] turn-over actor flex items-center justify-center">
            <Input defaultValue={item.yards_after_recovery} onChange={(e)=>updateInputGrade('yards_after_recovery',e )} size="small" className="w-[40%] p-1" />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Interception</span>
          </div>{" "}
          <div className="p-3 w-[70%] turn-over actor flex items-center justify-center">
            <Switch defaultChecked={item.interception} onChange={(e) => updateGrade('interception', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Interception</span>
          </div>{" "}
          <div className="p-3 w-[70%] turn-over actor flex items-center justify-center">
            <Input defaultValue={item.yards_after_interception} onChange={(e)=>updateInputGrade('yards_after_interception',e)} size="small" className="w-[40%] p-1" />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[30%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>TD</span>
          </div>{" "}
          <div className="p-3 w-[70%] turn-over actor flex items-center justify-center">
            <Switch defaultChecked={item.td} onChange={(e) => updateGrade('td', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div className="flex items-center justify-between text-md mt-4">
          <button onClick={close} className="flex items-center focus:outline-0 justify-center w-full bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TurnoverModal;
