import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Card,
  Tooltip,
} from "antd";
import { UserOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./manage-school.css";
import _ from "lodash";
import pencil from "../../assets/img/pencil.svg";

const Option = Select.Option;

const ManageSchoolComponent = (props) => {
  const [TableData, setTableData] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [schoolsList, setSchoolsList] = useState([]);
  const [schoolsListMeta, setSchoolsListMeta] = useState({});
  const [schoolsListLoading, setSchoolsListLoading] = useState(false);
  const [page1, setPage1] = useState(1);
  let history = useNavigate();
  let { page } = useParams();

  const showModalView = (item) => {
    setshowModal(true);
    setTableData(item);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "25%",
    },
    {
      title: "City",
      dataIndex: "city",
      width: "15%",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "15%",
    },
    // {
    // 	title: 'Country',
    // 	dataIndex: 'country',
    // },
    {
      title: "Zip-Code",
      dataIndex: "zip_code",
      width: "15%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      align: "center",
      render: (text, item) => (
        <div className='w-[90%] mx-auto flex items-center justify-center'>
          <Tooltip title='Edit School'>
            <img
              src={pencil}
              onClick={() => history(`/schools-update?id=${_.get(item, "id")}`)}
              className='w-[20%] cursor-pointer'
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (page) {
      loadSchoolsList(9, page);
    } else {
      loadSchoolsList();
    }

    if (_.get(props, "coachProfile.id")) {
      // if (_.get(props,'schoolDetails.details.id')) {
      // 	setSchoolsListMeta(_.get(props, 'schoolDetails.meta', {}))
      // 		setAllSchoolsList([_.get(props, 'schoolDetails.details',{})])
      // }else{
      // fetchSchoolViewData(_.get(props, 'coachProfile.school.id'))
      // }
    }
  }, [_.get(props, "coachProfile")]);

  const fetchSchoolViewData = (id) => {
    setSchoolsListLoading(true);
    props.getSchoolViewData({
      params: { id: id },
      callback: (res, responseData) => {
        setSchoolsListLoading(false);
        if (res === true) {
          setSchoolsListMeta(_.get(responseData, "data.meta", {}));
          setAllSchoolsList(_.get(responseData, "data", {}));
        }
      },
    });
  };
  const loadSchoolsList = (limit = 9, page = 1) => {
    setSchoolsListLoading(true);
    props.schoolsList({
      params: { page, sort: "created_at.desc" },
      callback: (res, data) => {
        if (res === true) {
          setSchoolsListMeta(_.get(data, "data.meta", {}));
          setAllSchoolsList(_.get(data, "data", {}));
        }
        setSchoolsListLoading(false);
      },
    });
  };

  const setAllSchoolsList = (data) => {
    let items = _.get(data, "items", []);
    let meta = _.get(data, "meta", {});
    let list = [];
    if (_.size(items) > 0) {
      _.map(items, (value, index) => {
        // let photo = `${_.get(meta, 'photo.path')}/${_.get(meta, 'photo.folders.school')}/512/${_.get(value, 'photo')}`
        list.push({
          key: index,
          name: _.startCase(_.get(value, "name")),
          email: _.get(value, "email"),
          city: _.get(value, "city") ? _.startCase(_.get(value, "city")) : "--",
          state: _.get(value, "state")
            ? _.startCase(_.get(value, "state"))
            : "--",
          country: _.get(value, "country")
            ? _.startCase(_.get(value, "country"))
            : "--",
          zip_code: _.get(value, "zip_code")
            ? _.startCase(_.get(value, "zip_code"))
            : "--",
          id: _.get(value, "id"),
          address: _.get(value, "address"),
          // photo: photo
        });
      });

      setSchoolsList(list);
    } else {
      setSchoolsList([]);
    }
  };

  const hideModal = () => {
    setshowModal(false);
  };

  const onChangePage = (page, size) => {
    setPage1(page);
    history(`/manage-school?page=${page}`);
    loadSchoolsList(size, page);
  };

  const render = () => {
    return (
      <div className='w-[98%] p-[2%]'>
        <div className='w-full'>
          <div className='full-width'>
            <div className='SchoolsListTitleContainer'>
              <span className='text-[24px] font-bold'>Manage Schools</span>
              <div className='coaches-list-action-btns'>
                <Link
                  to='/create-school-admin'
                  className=' TableActionBtn edit bg-[#1A91FF] text-white py-2 px-3 text-[14px] rounded-md'>
                  {/* <PlusOutlined /> */}
                  Create a School
                </Link>
              </div>
            </div>
            <Card bodyStyle={{ padding: "24px" }} className='mt-4'>
              <div className='w-full'>
                <Table
                  loading={schoolsListLoading}
                  bordered
                  columns={columns}
                  key={schoolsList.id}
                  dataSource={schoolsList}
                  size='middle'
                  pagination={false}
                  className='w-full'
                />
              </div>
              {_.get(schoolsListMeta, "total") > 10 && (
                <div className='PaginationContainer'>
                  <span className='PaginationContainerInner'>
                    <span className='PaginationLabel'>
                      Total{" "}
                      {_.get(schoolsListMeta, "total") > 1
                        ? `${_.get(schoolsListMeta, "total")} items`
                        : `${_.get(schoolsListMeta, "total")} item`}
                    </span>
                    <Pagination
                      defaultCurrent={1}
                      current={page1}
                      hideOnSinglePage={true}
                      onChange={(page, size) => onChangePage(page, size)}
                      total={_.get(schoolsListMeta, "total")}
                      // total={500}
                    />
                  </span>
                  <span className='PaginationContainerInner'>
                    <span className='PaginationLabel ml-3'>Go to</span>
                    <InputNumber
                      min={1}
                      max={
                        _.get(schoolsListMeta, "total") > 10
                          ? _.get(schoolsListMeta, "total") / 9
                          : 2
                      }
                      onPressEnter={(e) =>
                        onChangePage(_.get(e, "target.value"), 9)
                      }
                      style={{ width: "70px" }}
                    />
                  </span>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {
    checkUpdate: _.get(state, "app.account.isLogin.isLoggedIn"),
    islogindata: _.get(state, "app.account.isLogin"),
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};
const mapDispatchToProps = (dispatch) => ({
  schoolsList: (details) => dispatch(global.redux.action.schools.list(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSchoolComponent);
