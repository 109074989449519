import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Collapse,
  Checkbox,
  Row,
  Col,
  Form,
  Progress,
  Card
} from 'antd'
import { UserOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import _ from 'lodash'

import './report-view.css'

const Option = Select.Option
const { Panel } = Collapse

const EvaluvationReportViewScreen = props => {
  const [title, setTitle] = useState('')
  const [positionTitle, setPositionTitle] = useState(null)

  const [gradesListLoading, setGradesListLoading] = useState(false)
  const [gradesList, setGradesList] = useState([])
  const [gradesData, setGradesData] = useState([])
  const [playerData, setPlayerData] = useState([])
  const [positionsList, setPositionsList] = useState([])

  const params = new URLSearchParams(window.location.search)

  const columns = [
    {
      title: 'Player Name',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: 'Position',
      dataIndex: 'position',
      align: 'center'
    },
    {
      title: 'Total Plays',
      dataIndex: 'plays',
      align: 'center'
    },
    {
      title: 'Assignments Score',
      dataIndex: 'assignment_executed',
      align: 'center',
      render: (value, row) => {
        return `${Math.round((value / row.plays) * 100 || 0.0)}%`
      }
    },
    {
      title: 'Total Assignments Failed',
      dataIndex: 'assignment_executed',
      align: 'center',
      render: (value, row) => {
        return `${row.plays - value}`
      }
    },
    {
      title: 'Effort Score',
      dataIndex: 'great_effort',
      align: 'center',
      render: (value, row) => {
        return `${Math.round((value / row.plays) * 100 || 0.0)}%`
      }
    },
    {
      title: 'Total Poor Effort Plays',
      dataIndex: 'great_effort',
      align: 'center',
      render: (value, row) => {
        return `${row.plays - value}`
      }
    },
    // {
    //   title: 'Pancakes',
    //   dataIndex: 'pancakes',
    //   align: 'center',
    //   render: (value, row) => {
    //     return `${value} - ${Math.round((value / row.plays) * 100 || 0.0)}%`
    //   }
    // },
    // {
    //   title: 'Cut Blocks',
    //   dataIndex: 'cut_blocks',
    //   align: 'center',
    //   render: (value, row) => {
    //     return `${value} - ${Math.round((value / row.plays) * 100 || 0.0)}%`
    //   }
    // },
    // {
    // 	title: 'Big Hits',
    // 	dataIndex: 'name',
    // 	align: 'center',
    // 		render: (value, row) => {
    // 	return  `${value} - ${Math.round(((value / row.plays ) * 100) || 0.0)}%`
    // }
    // },

    // {
    //   title: 'Negative Play',
    //   dataIndex: 'negative',
    //   align: 'center',
    //   render: (value, row) => {
    //     return `${value} - ${Math.round((value / row.plays) * 100 || 0.0)}%`
    //   }
    // },
    {
      title: 'Penalty Flags',
      dataIndex: 'penalties',
      align: 'center',
      render: (value, row) => {
        return `${value || 0}`
      }
    }
    // {
    // 	title: 'Action',
    // 	dataIndex: 'action',
    // 	width: '20%',
    // 	align: 'center'
    // },
  ]

  useEffect(() => {
    setPageTitle()
    fetchGradesList()
    gridironDetails()
  }, [])
  const gridironDetails = () => {
    props.gridironDetails({
      callback: (res, data) => {
        if (res === true) {
          let final_data = []

          let stock = data.data.data.positions

          let offense = stock.offense.map(({ code }) => code)
          let defense = stock.defense.map(({ code }) => code)
          let special_teams = stock.special_teams.map(({ code }) => code)

          final_data = [
            {
              value: special_teams.toString(),
              title: 'Special Unit (' + special_teams.toString() + ')'
            },
            {
              value: defense.toString(),
              title: 'Defense Unit (' + defense.toString() + ')'
            },
            {
              value: offense.toString(),
              title: 'Offense Unit (' + offense.toString() + ')'
            }
          ]

          _.chain(data)
            .get('data.data.positions')
            .pick(['offense', 'defense', 'special_teams'])
            .values()
            .flatten()
            .map('code')
            .value()
            .map(val => {
              final_data.push({
                value: val,
                title: val
              })
            })

          setPositionsList(final_data)
        }
      }
    })
  }
  const loadGameView = (params, callback) => {
    props.gamesView({
      params: params,
      callback: (res, data) => {
        if (res === true) {
          callback(_.get(data, 'data.details', {}))
        }
      }
    })
  }
  const filterPlayerData = data => {
    let players = []

    for (let index = 0; index < data.length; index++) {
      const element = data[index]

      const name = element.athlete.first_name + ' ' + element.athlete.last_name
      const position = element.position

      const key = position + name
      const stockPlayer = players[key] || {
        plays: 0,
        assignment_executed: 0,
        pancakes: 0,
        pancakes: 0,
        great_effort: 0
      }
      const plays = stockPlayer.plays + 1
      const assignment_executed =
        (element.assignment_executed ? 1 : 0) + stockPlayer.assignment_executed
      const cut_blocks = (element.cut_blocks ? 1 : 0) + stockPlayer.cut_blocks
      const great_effort =
        (element.great_effort ? 1 : 0) + stockPlayer.great_effort
      const pancakes = (element.pancakes ? 1 : 0) + stockPlayer.pancakes
      const negative = (element.negative ? 1 : 0) + stockPlayer.negative
      const penalties = (element.penalties ? 1 : 0) + stockPlayer.penalties

      const filteration = {
        key,
        name,
        position,
        plays,
        assignment_executed,
        pancakes,
        cut_blocks,
        negative,
        great_effort,
        penalties
      }

      players[key] = filteration
    }

    const formatedPlayerData = Object.entries(players).map(i => i[1])

    setPlayerData(formatedPlayerData)
  }

  const fetchGradesList = () => {
    let queryParams = {
      positions: params.get('position_code'),
      game: params.get('game_id'),
      expand: 'athlete,play',
      athlete: params.get('athlete'),
      // sort: 'play.asc',
      limit: 2000,
      status: '1,2'
    }
    setGradesListLoading(true)
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items)
          filterPlayerData(data.data.items)
        }
        setGradesListLoading(false)
      }
    })
  }

  const refreshGradesList = queryParams => {
    setGradesListLoading(true)
    props.gradesList({
      params: queryParams,
      callback: (res, data) => {
        if (res === true) {
          setGradesData(data?.data?.items)
          filterPlayerData(data.data.items)
        }
        setGradesListLoading(false)
      }
    })
  }

  const setPageTitle = () => {
    let id = params.get('game_id')
    loadGameView({ id, expand: 'home_team,away_team' }, gameDetails => {
      setTitle(
        `${_.startCase(_.get(gameDetails, 'home_team.name'))} vs ${_.startCase(
          _.get(gameDetails, 'away_team.name')
        )}`
      )
    })
  }

  const render = () => {
    return (
      <div className='HomePageContainer evaluvationPage'>
        <div className='container'>
          <div className='full-width'>
            <br />
            <h3>
              Overall {positionTitle ? positionTitle : playerData[0]?.position}{' '}
              Unit Scores
            </h3>
            <div className='TableContainer evaluvationReportTable'>
              <PlayerData data={gradesData} />
              <br />
              <div className='evaluvationTableTitle'>
                <span style={{ marginLeft: 5 }}>{title}</span>
              </div>
              <div>
                <Form.Item
                  className='col-12 col-sm-4 col-lg-4 mt-5 pt-2 '
                  label='Position'
                  name='Position'
                >
                  <Select
                    onChange={value => {
                      setPositionTitle(value.toString())

                      refreshGradesList({
                        positions: value.toString(),
                        game: params.get('game_id'),
                        expand: 'athlete,play',
                        athlete: params.get('athlete'),
                        // sort: 'play.asc',
                        limit: 2000,
                        status: '1,2'
                      })
                      // onChangePostions(value);
                      // playNumberForm.resetFields();
                    }}
                    mode='multiple'
                    className='full-width'
                  >
                    {_.map(positionsList, ({ value, title }, index) => {
                      return (
                        <Option value={value} key={value}>
                          {title}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <Table
                bordered
                key={gradesList.game_id}
                columns={columns}
                dataSource={playerData}
                loading={gradesListLoading}
                size='middle'
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return render()
}
function PlayerData ({ data }) {
  const plays = data.length
  const assignment = data.filter(a => a.assignment_executed).length
  const effort = data.filter(a => a.great_effort).length

  const kicks = data.filter(a => a.kicks).length
  const receptions = data.filter(a => a.receptions).length
  const total_carries = data.filter(a => a.total_carries).length
  const turnovers = data.filter(a => a.turnovers).length
  const tackles = data.filter(a => a.tackles).length
  const pancakes = data.filter(a => a.pancakes).length
  const cut_blocks = data.filter(a => a.cut_blocks).length
  const punts = data.filter(a => a.punts).length

  const td = data.filter(a => a.td).length
  const sacks = data.filter(a => a.sacks).length
  const penalties = data.filter(a => a.penalties).length

  return (
    <Row gutter={24}>
      <Col span={8}>
        <Metric2 progress={plays} title='Total Assignments Graded	' />
      </Col>
      <Col span={8}>
        <Metric
          progress={(assignment / plays) * 100}
          title='Overall Unit Assignment Score	'
        />
      </Col>
      <Col span={8}>
        <Metric
          progress={(effort / plays) * 100}
          up={true}
          title='Overall Unit Effort Score	'
        />
      </Col>
      <Col span={8}>
        <Metric2 progress={penalties} up={true} title='Penalty Flags' />
      </Col>

      <Col span={8}>
        <Metric2
          progress={plays - assignment}
          title='Total Assignments Failed'
        />
      </Col>
      <Col span={8}>
        <Metric2 progress={plays - effort} title='Total Poor Effort Plays' />
      </Col>

      {pancakes ? (
        <Col span={8}>
          <Metric2 progress={pancakes} title='Total Pancakes' />
        </Col>
      ) : (
        ''
      )}

      {cut_blocks ? (
        <Col span={8}>
          <Metric2 progress={cut_blocks} up={true} title='Total Cut Blocks' />
        </Col>
      ) : (
        ''
      )}

      {punts ? (
        <Col span={8}>
          <Metric2 progress={punts} title='Total Punts' />
        </Col>
      ) : (
        ''
      )}

      {tackles ? (
        <Col span={8}>
          <Metric2 progress={tackles} title='Total Tackles' />
        </Col>
      ) : (
        ''
      )}

      {turnovers ? (
        <Col span={8}>
          <Metric2 progress={turnovers} title='Total Turnovers' />
        </Col>
      ) : (
        ''
      )}

      {total_carries ? (
        <Col span={8}>
          <Metric2 progress={total_carries} title='Total Carries' />
        </Col>
      ) : (
        ''
      )}

      {receptions ? (
        <Col span={8}>
          <Metric2 progress={receptions} up={true} title='Receptions' />
        </Col>
      ) : (
        ''
      )}

      {td ? (
        <Col span={8}>
          <Metric2 progress={td} title='Total TD' />
        </Col>
      ) : (
        ''
      )}

      {sacks ? (
        <Col span={8}>
          <Metric2 progress={sacks} up={true} title='sacks' />
        </Col>
      ) : (
        ''
      )}
    </Row>
  )
}
function Metric ({ progress = 0, up = false, title, subtitle }) {
  return (
    <Card title={null} className='metric'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <div className='title'>
          <span className='text'>
            <p style={{ fontSize: '15px' }}>{title}</p>
            <small>{subtitle}</small>
          </span>
        </div>
        <Progress
          width={70}
          strokeColor='#c12730f0'
          type='circle'
          format={() => `${Math.round(progress)}%`}
          percent={Math.round(progress || 0.0)}
        />
      </div>
    </Card>
  )
}
function Metric2 ({ progress = 0, up = false, title, subtitle }) {
  return (
    <Card title={null} className='metric'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <div className='title'>
          <span className='text'>
            <p style={{ fontSize: '15px' }}>{title}</p>
            <small>{subtitle}</small>
          </span>
        </div>
        <div>
          <h2 style={{ fontSize: '50px' }}>{progress || 0}</h2>
        </div>
      </div>
    </Card>
  )
}
const mapStateToProps = state => {
  return {
    profileDetails: _.get(state, 'app.account.profile.data.details')
  }
}

const mapDispatchToProps = dispatch => ({
  gamesView: details => dispatch(global.redux.action.games.view(details)),
  gradesList: details => dispatch(global.redux.action.grades.list(details)),
  gridironDetails: details =>
    dispatch(global.redux.action.grades.gridironDetails(details))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluvationReportViewScreen)
