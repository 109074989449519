import React from "react";

import { Menu, Button } from "antd";
import LayoutContent from "../Content";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./footer.css";

class PageFooter extends React.Component {
  render() {
    return (
      <div className="flex h-[60px] hide-in-print  bg-[#FFFFFF]  px-[5%] lg:px-[10%] items-center justify-between font-inter font-medium text-[12px] text-[#0F1A24] xl:text-[1vw]  hover:text-[#1A91FF] cursor-pointer w-full">
        <span className="underline ">
          Terms & Conditions | Privacy Policy | Support
        </span>
        <span>© 2021 Wardbord. All rights reserved</span>
      </div>

      // <div className="Footer">
      //     <div className="container">
      //         <div className="footerLeft">
      //             <Link to="/">Terms & Conditions | </Link>
      //             <Link to="/">Privacy Policy | </Link>
      //             <Link to="/">Support</Link>
      //         </div>
      //         <div className="copyRights">
      //             © 2021 Wardbord. All rights reserved.
      //         </div>
      //     </div>
      // </div>
    );
  }
}

export default PageFooter;
