import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LabelList,
  LineChart,
  Label,
  ResponsiveContainer,
} from "recharts";
import { Divider } from "antd";
const formatNum = (val) => {
  if (isNaN(val)) return 0;
  return Math.round(val);
};

function findMinMax(array) {
  let min = Infinity;
  let max = -Infinity;

  array?.forEach((obj) => {
    const combinedValue = obj.O <  obj.D ? obj.D : obj.O;

    if (combinedValue < min) {
      min = combinedValue;
    }
    if (combinedValue > max) {
      max = combinedValue;
    }
  });

  if (max > 95) {
    max = 95;
  }
  return { min, max };
}
function GameAnalyticChart({ data }) {
  const totals = data.map(({ O, D }) => O + D);

  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y + 15}
        dy={5}
        fontWeight='700'
        fill='#526B84'
        fontSize='10px'
        textAnchor='middle'>
        {payload.value}
      </text>
    );
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'>{`${payload.value.toFixed(0)}%`}</text>
      </g>
    );
  };
  const organizer = {
    o: [],
    d: [], 
  };

  data.map(({ instance, O, D }) => {
    organizer["o"]?.push(O);
    organizer["d"]?.push(D); 
  });
 
    const { min: minY, max: maxY } = findMinMax(data);
  const Legend = ({
    fill,
    title,
    firstInstance,
    secondtInstance,
    thirdInstance,
    fourthInstance,
  }) => {
    return (
      <div
        className={`w-full h-[44px] rounded-[5px] p-[12px] flex items-center text-[12px] font-bold text-[${
          fill ? fill : "#ED780B"
        }]`}
        style={{ border: "1px solid #EAEDF0" }}>
        <div className='flex items-center w-full'>
          <div className='flex items-center w-[25%]'>
            {fill ? (
              <div
                className={`w-[12px] h-[12px] rounded-full bg-[${fill}] mr-[4px]`}></div>
            ) : (
              <div
                className={`w-[12px] h-[12px] rounded-full bg-[#ED780B] mr-[4px]`}></div>
            )}

            <span className='mt-[3px] mr-[4px]'>{title}</span>
            <Divider type='vertical' style={{ height: "30px" }} />
          </div>
          <div className='w-[70%] flex items-center justify-around'>
            <div className='flex flex-col'>
              <span className='text-[8px] text-[#526B84]'>1st down</span>
              <span>{isNaN(firstInstance) ? 0 : firstInstance}%</span>
            </div>
            <div className='flex flex-col'>
              <span className='text-[8px] text-[#526B84]'>2nd down</span>
              <span>{isNaN(secondtInstance) ? 0 : secondtInstance}%</span>
            </div>
            <div className='flex flex-col'>
              <span className='text-[8px] text-[#526B84]'>3rd down</span>
              <span>{isNaN(thirdInstance) ? 0 : thirdInstance}%</span>
            </div>
            <div className='flex flex-col'>
              <span className='text-[8px] text-[#526B84]'>4th down</span>
              <span>{isNaN(fourthInstance) ? 0 : fourthInstance}%</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
 
  return (
    <div className='w-[100%] px-[10%] mx-auto flex font-inter flex-col justify-between items-center p-0 pb-[24px]'>
      {/* legend div */}
      <div class='flex flex-col w-[75%] mx-auto mb-[24px] text-[12px] font-inter'>
        <Legend
          title='O'
          fill='#1A91FF'
          firstInstance={organizer["o"][0]}
          secondtInstance={organizer["o"][1]}
          thirdInstance={organizer["o"][2]}
          fourthInstance={organizer["o"][3]}
        />
        <div className='mt-[8px]'>
          <Legend
            title='D'
            fill='#A40E17'
            firstInstance={organizer["d"][0]}
            secondtInstance={organizer["d"][1]}
            thirdInstance={organizer["d"][2]}
            fourthInstance={organizer["d"][3]}
          />
        </div>
         
      </div>

      {/* <BarChart
        data={data}
        width={380}
        height={200}
        barGap={0}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        padding={{ top: 0, right: 0, left: 0, bottom: 0 }}
        label={{ position: "top" }}>
        <XAxis dataKey='instance' axisLine={false} tickLine={false} />
        <YAxis tickLine={false} axisLine={false} tickCount={10} />
        <CartesianGrid vertical={false} />
        <Line type="linear" dataKey='uv' stroke='#EAEDF0' strokeWidth={0.5} />
        <Line type="linear" dataKey='pv' stroke='#EAEDF0' strokeWidth={0.5} />

        <Bar dataKey='O' fill='#1A91FF' barSize={15} stackId='a1'></Bar>
        <Bar dataKey='D' fill='#A40E17' barSize={15} stackId='b1'></Bar>
        <Bar dataKey='K' fill='#ED780B' barSize={15} stackId='c1'></Bar>

        <XAxis dataKey='instance' />
      </BarChart> */}

      <ResponsiveContainer width='100%' height={220}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}>
          <XAxis
            dataKey='instance'
            axisLine={false}
            tickLine={false}
            tick={renderXAxisTick}
            padding={{ left: 30, right: 30 }} 
          />
          <YAxis padding={{ bottom: 10, top: 10 }}  tickLine={false} tickCount={10} domain={[minY - 10, maxY + 5]} />
          <Tooltip />
          <CartesianGrid vertical={false} />
          <Line type='linear' dataKey='uv' stroke='#EAEDF0' strokeWidth={0.5} />
          <Line type='linear' dataKey='pv' stroke='#EAEDF0' strokeWidth={0.5} />
          <Line type='linear' dataKey='O' stroke='#1A91FF' strokeWidth={4} />
          <Line type='linear' dataKey='D' stroke='#A40E17' strokeWidth={4} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
export default GameAnalyticChart;
