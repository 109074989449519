import React from 'react'

function RegisterSuccessful() {
  return (
    <div className='flex items-center justify-center flex-col text-center h-[90vh]'>
      <h1>Thank you for trying Wardbord!</h1>
      <p>Please check your email inbox for email confirmation</p>
    </div>
  )
}

export default RegisterSuccessful